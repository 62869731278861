import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import MediaGallery from './MediaGallery';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import OrgTeamImageHeader from '../../../r-components/OrgTeamImageHeader/OrgTeamImageHeader';
import Breadcrumbs from '../../../pages/component/Breadcrumbs';
import { CCard, CCardBody, CCardHeader, CCol } from '@coreui/react';

const MediaGalleryPage = () => {
  const media = useSelector((state) => state?.DataMediaState?.data || []);

  return (
    <>
      <Breadcrumbs />
      <div className="container-fluid space-1">
        <div className="row">
          <div className="col">
            <div className="pr-lg-4">
              <CCol xl={12}>
                <CCard>
                  <CCardHeader className="d-flex justify-content-between">
                    <OrgTeamImageHeader title="Media" />
                  </CCardHeader>
                  <CCardBody>
                    <MediaGallery
                      media={media}
                      mediaWidth={300}
                      types={['image', 'video']}
                    />
                  </CCardBody>
                </CCard>
              </CCol>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MediaGalleryPage;
