export const ADD_NEW_QUIZ = 'ADD_NEW_QUIZ';
export const QUIZ_DETAILS = 'QUIZ_DETAILS';
export const QUIZ_TAKEN_DETAILS = 'QUIZ_TAKEN_DETAILS';
export const QUIZZES = 'QUIZZES';
export const CLEAR_QUIZZES = 'CLEAR_QUIZZES';

export const ADD_NEW_QUESTION = 'ADD_NEW_QUESTION';
export const QUESTION_DETAILS = 'QUESTION_DETAILS';
export const CREATE_NEW_QUIZ = 'CREATE_NEW_QUIZ';
export const QUESTIONS = 'QUESTIONS';
export const CLEAR_QUESTIONS = 'CLEAR_QUESTIONS';
export const EDIT_QUESTION = 'EDIT_QUESTION';
export const SHOW_CONFIRM_EDIT_QUESTION_MODAL =
  'SHOW_CONFIRM_EDIT_QUESTION_MODAL';
export const SHOW_CONFIRM_DUPLICATE_QUESTION_MODAL =
  'SHOW_CONFIRM_DUPLICATE_QUESTION_MODAL';
export const SHOW_CONFIRM_DELETE_QUESTION_MODAL =
  'SHOW_CONFIRM_DELETE_QUESTION_MODAL';
export const CLOSE_CONFIRM_EDIT_QUESTION_MODAL =
  'CLOSE_CONFIRM_EDIT_QUESTION_MODAL';
export const CLOSE_CONFIRM_DUPLICATE_QUESTION_MODAL =
  'CLOSE_CONFIRM_DUPLICATE_QUESTION_MODAL';
export const CLOSE_CONFIRM_DELETE_QUESTION_MODAL =
  'CLOSE_CONFIRM_DELETE_QUESTION_MODAL';
export const QUESTION_ADDED = 'QUESTION_ADDED';
export const QUESTION_REMOVED = 'QUESTION_REMOVED';
export const CLEAR_QUESTION_ADDED = 'CLEAR_QUESTION_ADDED';
export const CLEAR_QUESTION_REMOVED = 'CLEAR_QUESTION_REMOVED';

export const ADD_NEW_PLAYER = 'ADD_NEW_PLAYER';
export const PLAYER_DETAILS = 'PLAYER_DETAILS';
export const EDIT_PLAYER = 'EDIT_PLAYER';
export const PLAYERS = 'PLAYERS';
export const CLEAR_PLAYERS = 'CLEAR_PLAYERS';

export const ADD_NEW_GROUP = 'ADD_NEW_GROUP';
export const GROUP_DETAILS = 'GROUP_DETAILS';
export const EDIT_GROUP = 'EDIT_GROUP';
export const GROUPS = 'GROUPS';
export const CLEAR_GROUPS = 'CLEAR_GROUPS';

export const DATA_QUESTION_CATEGORIES = 'DATA_QUESTION_CATEGORIES';

export const EXPAND_FIRST_QUESTION = 'EXPAND_FIRST_QUESTION';
export const CLEAR_EXPAND_FIRST_QUESTION = 'CLEAR_EXPAND_FIRST_QUESTION';
export const PREVIEW_FIRST_QUESTION = 'PREVIEW_FIRST_QUESTION';
export const CLEAR_PREVIEW_FIRST_QUESTION = 'CLEAR_PREVIEW_FIRST_QUESTION';
export const SELECT_FIRST_QUESTION = 'SELECT_FIRST_QUESTION';
export const CLEAR_SELECT_FIRST_QUESTION = 'CLEAR_SELECT_FIRST_QUESTION';
export const MOVE_SELECTED_QUESTION_RIGHT = 'MOVE_SELECTED_QUESTION_RIGHT';
export const CLEAR_MOVE_SELECTED_QUESTION_RIGHT =
  'CLEAR_MOVE_SELECTED_QUESTION_RIGHT';
export const TOGGLE_ON_SHOW_SCOREBOARD = 'TOGGLE_ON_SHOW_SCOREBOARD';
export const TOGGLE_ON_SHOW_FIELD = 'TOGGLE_ON_SHOW_FIELD';
