import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import React, { Component } from 'react';
import { FaArrowLeft, FaInfoCircle } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SimpleReactValidator from 'simple-react-validator';
import Spinner from '../component/Spinner';

toast.configure();

class CreateTeam extends Component {
  constructor(props) {
    super(props);
    const year = new Date('1/1/2018').getFullYear();
    this.years = Array.from(new Array(5), (val, index) => index + year);
    this.validator = new SimpleReactValidator();
    this.state = {
      isLoading: false,
      file: '',
      coverImageFile: '',
      imageData: '',
      coverImageData: '',
      teamName: '',
      teamNameError: '',
      zipcode: '',
      zipcodeError: '',
      teamDesc: '',
      descError: '',
      teamGender: '',
      sport: 'Baseball',
      isGender: false,
      ageGroup: '',
      isAgeGroup: false,
      imgSrc: null,
      isActive: false,
      coverImage: null,
      isCoverActive: false,
      isDisabled: false,
      teamDetailSave: true,
      gameDetailSave: false,
      scrimmageSave: false,
      dateSelect: false,
      isAvail: false,
      isMiles: 'yes',
      strengthValue: 1,
      travel: 0,
      travelError: '',
      availability: [],
      isMon: false,
      isTue: false,
      isWed: false,
      isThu: false,
      isFri: false,
      isSat: false,
      isSun: false,
      leagueName: '',
      leagueNameError: '',
      leagueYear: '',
      leagueYearError: '',
      season: '',
      seasonError: '',
      additionalDesc: '',
      result: '',
      resultError: '',
      leagueDataArr: [],
      gameHistory: [],
      gameHistoryId: '',
      leagueSeason: '',
      leagueDetails: [],
    };
  }

  handleUpload = (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState(
        {
          imgSrc: URL.createObjectURL(event.target.files[0]),
          isActive: true,
          file: event.target.files[0],
        },
        () => {
          if (this.state.file) {
            // this.props.requestUploadImage(this.state.file);
          }
        }
      );
    } else {
      this.setState({
        imgSrc: '',
        isActive: false,
      });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
  };

  handleClickTeamDetails = () => {
    const { teamName, zipcode, teamDesc, teamGender, sport, ageGroup } =
      this.state;
    if (
      teamName &&
      zipcode &&
      zipcode.length == 5 &&
      teamGender &&
      sport &&
      ageGroup
    ) {
      this.setState({
        teamDetailSave: false,
        // gameDetailSave: true,
        gameDetailSave: false,
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }

    this.createTeam();
  };

  createTeam = () => {
    const { ageGroup } = this.state;
  };

  handleType = () => {
    var total = document.getElementById('char').value;
    document.getElementById('charcount').innerHTML = total.length + '/300';
  };
  handleDesc = () => {
    var totalChar = document.getElementById('desc').value;
    document.getElementById('charcountvalidate').innerHTML =
      totalChar.length + '/300';
  };

  handleRadioChange = (e) => {
    this.setState({
      isMiles: e.target.value,
    });
  };

  getStrength = (e) => {
    this.setState({
      strengthValue: e,
    });
  };
  getTravelDistance = (e) => {
    this.setState({
      travel: e,
    });
  };

  handleDeleteLeague = (gameid) => {
    let teamId = window.location.pathname.split('/')[2];
    // this.props.requestDeleteLeague(gameid, teamId);
  };

  handleLeagueId = (data) => {
    this.setState({
      leagueDetails: data,
    });
  };

  handleRemoveLeague = (data) => {
    const items = this.state.gameHistory.filter((item) => {
      return item !== data;
    });
    this.setState({
      gameHistory: items,
    });
  };

  handleGetLeagueData = (data) => {
    this.setState({
      leagueName: data.name,
      leagueYear: data.year,
      leagueSeason: data.season,
      result: data.result,
      additionalDesc: data.description,
      gameHistoryId: data.id,
    });
  };

  render() {
    const agegroup = [
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
    ];
    const { leagueName, leagueSeason, leagueYear, result } = this.state;
    let data = this.props.GetTeamEditData;
    return (
      <div className="container space-2">
        <div className="row">
          <div className="col">
            <Spinner display={this.state.isLoading}>
              <form
                className="js-validate js-step-form pr-lg-4"
                data-progress-id="#stepFormProgress"
                data-steps-id="#stepFormContent"
                noValidate="noValidate"
              >
                <ul
                  id="stepFormProgress"
                  className="js-step-progress list-inline u-shopping-cart-step-form mb-4"
                >
                  <li
                    className={
                      this.state.teamDetailSave
                        ? 'list-inline-item u-shopping-cart-step-form__item mb-5 u-checked active'
                        : 'list-inline-item u-shopping-cart-step-form__item mb-5 u-checked'
                    }
                  >
                    <img
                      className="max-width-10 mx-auto mb-2"
                      src={'/img/create-team-form/teambw.png'}
                      alt="Coaches"
                    />
                    <span className="u-shopping-cart-step-form__title">
                      Team Details
                    </span>
                  </li>
                </ul>
                <div id="stepFormContent">
                  <div
                    id="customerInfoStep"
                    className={this.state.teamDetailSave ? 'block' : 'none'}
                  >
                    <div className="row">
                      <div className="col-md-2">
                        <label
                          className="upload d-flex m-auto cursor-pointer"
                          style={
                            this.state.imgSrc
                              ? { flexDirection: 'column' }
                              : {
                                  backgroundColor: '#cdcdcd',
                                  flexDirection: 'column',
                                }
                          }
                        >
                          <input
                            type="file"
                            accept="image/*"
                            onChange={this.handleUpload}
                          />
                          <div
                            className={
                              this.state.imgSrc ? 'm-auto hide' : 'm-auto'
                            }
                          >
                            <div>
                              <div className="d-flex">
                                <img
                                  src="/img/create-team-form/upload.png"
                                  className="m-auto"
                                  alt="upload"
                                />
                              </div>
                            </div>
                          </div>
                          <img
                            src={this.state.imgSrc}
                            className={
                              this.state.isActive
                                ? 'img-fluid upload-image'
                                : 'img-fluid'
                            }
                          />
                        </label>
                        <p className="font-size-13 text-center">
                          Upload Team Logo
                        </p>
                      </div>

                      <div className="col-md-10">
                        <div className="row">
                          <div className="w-100"></div>
                          <div className="col-md-6">
                            <div className="js-form-message mb-6 u-has-success">
                              <label className="d-block text-uppercase">
                                Team Name
                                <span className="text-danger">*</span>
                              </label>
                              <div className="js-focus-state input-group form">
                                <input
                                  className="form-control form__input"
                                  name="teamName"
                                  value={this.state.teamName}
                                  type="text"
                                  required=""
                                  placeholder="Your team name"
                                  onChange={this.handleChange}
                                />
                              </div>
                              {this.validator.message(
                                '',
                                this.state.teamName,
                                'required'
                              )}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <label className="d-block text-uppercase d-flex">
                              My Team Level/Age Group
                              <span className="text-danger">*</span>
                              <div className="custom-p"></div>
                            </label>
                            <select
                              className={
                                this.state.ageGroup === ''
                                  ? 'select-color custom-select'
                                  : 'custom-select'
                              }
                              aria-invalid="false"
                              onChange={this.handleChange}
                              name="ageGroup"
                              value={this.state.ageGroup}
                              id="agegroup"
                            >
                              <option value="">
                                Select Team Level/Age Group
                              </option>
                              <option disabled>_________</option>
                              <option disabled>SCHOOL TEAMS</option>
                              <option value="V" style={{ color: '#000' }}>
                                Varsity
                              </option>
                              <option value="JV" style={{ color: '#000' }}>
                                Junior Varsity
                              </option>
                              <option disabled>_________</option>
                              <option disabled>TRAVELLING TEAMS</option>
                              {agegroup &&
                                agegroup.map((data, key) => {
                                  return (
                                    <option
                                      value={`${data} & under`}
                                      style={{ color: '#000' }}
                                    >
                                      {data} &amp; under
                                    </option>
                                  );
                                })}
                            </select>
                            {this.validator.message(
                              '',
                              this.state.ageGroup,
                              'required'
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr className="clearfix" />
                    <div className="d-sm-flex justify-content-sm-between align-items-sm-center mt-5">
                      <Link
                        to={
                          this.props.teamDetailsData.teamData &&
                          this.props.teamDetailsData.teamData.length === 0
                            ? '/addteam'
                            : '/yourteam'
                        }
                        className="d-md-block mb-3 mb-sm-0 back"
                      ></Link>
                      <button
                        type="button"
                        className="btn btn-primary float-right float-md-left custom-btn-color font-weight-bold"
                        onClick={this.handleClickTeamDetails}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </Spinner>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    image: state,
    teamDetailsData: state.DataTeamState,
    TeamCreate: state.CreateTeamState,
    GetTeamEditData: state.CreateTeamState.getTeamEditData,
    LeagueData: state.LeagueDataState.getLeagueData,
    LeagueStatus: state.LeagueDataState.status,
    isLoading: state.CreateTeamState.isLoading,
    imageStatus: state.ImageState.status,
    coverImageStatus: state.ImageState.coverImageStatus,
    user: state.UserState,
  };
};

const mapDispatchToProps = {
  // requestUploadImage,
};

CreateTeam.propTypes = {
  GetTeamEditData: PropTypes.shape({
    ageGroup: PropTypes.string,
    availability: PropTypes.array,
    coverImage: PropTypes.string,
    description: PropTypes.string,
    distance: PropTypes.string,
    gender: PropTypes.string,
    imageUrl: PropTypes.string,
    length: PropTypes.number,
    location: PropTypes.shape({
      zipCode: PropTypes.string,
    }),
    name: PropTypes.string,
    result: PropTypes.string,
    season: PropTypes.string,
    skillLevel: PropTypes.number,
    sport: PropTypes.string,
    year: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  image: PropTypes.shape({
    ImageState: PropTypes.shape({
      image: PropTypes.string,
    }),
  }),
  imageStatus: PropTypes.number,
  LeagueData: PropTypes.array,
  LeagueStatus: PropTypes.node,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  TeamCreate: PropTypes.shape({
    status: PropTypes.number,
  }),
  teamDetailsData: PropTypes.shape({
    teamData: PropTypes.array,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTeam);
