import styled from 'styled-components';


export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 35px;
`

export const Content = styled.div`

  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  p {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #151b26;
    line-height: 28px;
    padding-bottom: 24px;
  }

  button {
    background: #f6d30d;
    border: solid 1px #f6d30d;
    color: #151b26;
    padding: 19px 2px;
    border-radius: 10px;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    min-width: 260px;
  }
`;

export const RightContent = styled.div`
  width: 660px;
  display: flex;
  flex: 1;

  img {
    width: 100%;
    height: auto;
  }
`;

export const H1 = styled.div`
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 40px;
  color: #0e0e0e;
  line-height: 46px;
  padding-bottom: 24px;

  span {
    color: #f6d30d;
  }
`;
