import React, { Component, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import TourView, { showTour } from '../../r-components/Tours/TourView';
import _ from 'lodash';
import { setCurrentTour, setCurrentTourStep } from '../../r-store/tour/actions';
import Breadcrumbs from './Breadcrumbs';

const GroupWrapper = ({ userData, children }) => {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   showTour(userData, 'groups', dispatch);
  // }, [userData]);

  return (
    <>
      <Breadcrumbs />
      <div className="container-fluid space-1">
        <div className="row">
          <div className="col">
            <div className="pr-lg-4">{children}</div>
          </div>
        </div>
      </div>
      <TourView name="groups" />
    </>
  );
  // }
};

const mapStateToProps = (state) => {
  return {
    tourData: state.TourState,
    userData: state.UserState,
  };
};

const mapDispatchToProps = {
  setCurrentTour,
  setCurrentTourStep,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupWrapper);
