import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CLink,
  CRow,
  CTooltip,
} from '@coreui/react';
import React, { useEffect, useRef, useState } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { FaCircle, FaEdit, FaInfoCircle, FaUserPlus } from 'react-icons/fa';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { triggerTourNavigation } from '../../../actions/tourActionCreator';
import { Loading } from '../../../r-reusable';
import _ from 'lodash';
import PlayerWrapper from '../../../pages/component/PlayerWrapper';
import { useHistory } from 'react-router-dom';
import { TABS } from '../../../AppTabs';
import { orgAndTeamData } from '../../../helpers/data';
import {
  ACCESS_ACL,
  ACCESS_ACTIONS,
  canDoAction,
  checkAccess,
} from '../../../helpers/access';
import OrgTeamImageHeader from '../../../r-components/OrgTeamImageHeader/OrgTeamImageHeader';
import TeamCodeExplanationModal from '../teams/TeamCodeExplanationModal';
import useAccountSelectors from '../../../hooks/useAccountSelectors';
import { EMAIL_ADDRESSES } from '../../../config';

// import { currentTeamAndOrgData } from '../../../AppReducer';

const getBadge = (status) => {
  return status ? 'success' : 'warning';
};

const getStatus = (status) => {
  return status ? 'Active' : 'Pending';
};

const Players = () => {
  const history = useHistory();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, '');
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const dispatch = useDispatch();
  const users = useSelector((state) => state.DataPlayerState?.data || []);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(currentPage);
  const currentUser = useSelector((state) => state?.UserState);
  const orgData = useSelector((state) => state.DataOrganizationState);
  const teamData = useSelector((state) => state.DataTeamState);
  const [orgTeamData, setOrgTeamData] = useState({});
  const [tableFields, setTableFields] = useState([]);
  const [players, setPlayers] = useState([]);
  const [showTeamCodeExplanationModal, setShowTeamCodeExplanationModal] =
    useState(false);
  const playerRequests = useSelector(
    (state) => state.DataPlayerState?.teamRequests || []
  );

  useEffect(() => {
    currentPage !== page && setPage(currentPage);
  }, [currentPage, page]);

  const { tier, teamCounts, type, showSubscriptionModal, goToUrl } =
    useAccountSelectors();

  // useEffect(async () => {
  //   const createNewPlayerUserCallable = functions.httpsCallable(
  //     'createNewPlayerUserCallable'
  //   );
  //   let org_team = {};
  //   let team_requests = [
  //     {
  //       id: '0a08d209-01e8-496f-8e87-11c17f0e6c1e',
  //       name: 'Deacons',
  //       organization_id: '42bee8cb-95c6-4643-bfcb-7d27bc3bacf1',
  //       location: { city: 'Cromwell', state: 'CT' },
  //     },
  //   ];
  //   let phoneNumber = `+1${Math.round(10000000000 * Math.random())}`;
  //   const createNewPlayerUserResult = await createNewPlayerUserCallable({
  //     name: 'Test',
  //     email: `test${Math.round(100000 * Math.random())}@email.com`,
  //     phoneNumber,
  //     organization_id: '42bee8cb-95c6-4643-bfcb-7d27bc3bacf1',
  //     team_requests,
  //     orgName: 'Deacons',
  //   });
  // }, []);

  useEffect(() => {
    let orgTeam = orgAndTeamData({ orgData, teamData });
    setOrgTeamData(orgTeam);
    let tableFields = [
      { key: 'name', label: 'Name', _classes: 'font-weight-bold' },
    ];
    if (orgTeam.hasTeam !== true) {
      tableFields.push({ key: 'team', label: 'Team(s)' });
    }
    tableFields = tableFields.concat([
      { key: 'email', label: 'Email' },
      { key: 'position', label: 'Position(s)' },
      { key: 'show_details', label: '', sorter: false },
    ]);
    setTableFields(tableFields);
  }, [orgData, teamData]);

  useEffect(() => {
    if (_.isString(orgData?.data?.id) && orgData.data.id.length > 0) {
      let newPlayers = [...(users || [])].map((u) => {
        let p = _.pick(u, [
          '_id',
          'name',
          'activated',
          'email',
          'registered',
          '_teams',
          '_positions',
        ]);
        p.id = u._id;
        if (
          _.isString(teamData?.teamData?._id) &&
          teamData.teamData._id.length > 0
        ) {
          delete p._teams;
          p._positions =
            u.org_team?.[orgData.data.id]?.[teamData.teamData._id]?.positions ||
            [];
        }
        return p;
      });
      setPlayers(newPlayers);
    }
  }, [orgData, teamData, users]);

  const canAddPlayer = () => {
    if (
      !(
        _.isString(teamData?.teamData?._id) &&
        teamData.teamData._id.length > 0 &&
        _.isArray(teamData?.teamData?.players)
      )
    ) {
      if (_.isArray(teamData?.data) && teamData.data.length >= 1) {
        let allMaxedOut = true;
        for (let index = 0; index < teamData.data.length; index++) {
          const _teamData = teamData.data[index];
          if (_teamData.maxxedPlayers !== true) {
            allMaxedOut = false;
          }
        }
        return {
          _canAddPlayer: !allMaxedOut,
          _reason: `You have reached the maximum # of players on all teams. Please contact ${EMAIL_ADDRESSES.INFO}.`,
        };
      }
      return { _canAddPlayer: true, _reason: '' };
    }
    if (teamData?.teamData?.maxxedPlayers === true) {
      return {
        _canAddPlayer: false,
        _reason: `You have reached the maximum # of players (${
          teamData?.teamData?.maxPlayersPerTeam ||
          teamCounts[teamData?.teamData._id].maxPlayers ||
          ACCESS_ACL[tier]?.[ACCESS_ACTIONS.CREATE_PLAYER].threshold
        }) for this team. Please contact ${EMAIL_ADDRESSES.INFO}.`,
      };
    }
    return { _canAddPlayer: true, _reason: '' };
  };

  return (
    <PlayerWrapper>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader className="d-flex justify-content-between">
              <OrgTeamImageHeader
                orgTeamData={orgTeamData}
                title="Players"
                rightView={
                  <>
                    {_.isArray(playerRequests) && playerRequests.length > 0 && (
                      <CTooltip
                        content={`There ${
                          playerRequests.length === 1
                            ? 'is 1 player who has'
                            : `are ${playerRequests.length} players who have`
                        } requested to join your team${
                          _.isArray(teamData?.data) &&
                          teamData.data.length > 1 &&
                          orgTeamData.hasTeam === true
                            ? ''
                            : 's'
                        }`}
                        placement="bottom"
                      >
                        <CLink
                          style={{ marginRight: 30, position: 'relative' }}
                          to={TABS.COACH_TEAMS_PLAYERS_REQUESTS.path}
                        >
                          <FaUserPlus
                            style={{ marginTop: -5, marginRight: 4 }}
                          />
                          {`Team join requests`}
                          <FaCircle
                            style={{ position: 'absolute', top: -3 }}
                            color="red"
                          />
                        </CLink>
                      </CTooltip>
                    )}
                    {!!teamData?.teamData?.code && (
                      <CTooltip
                        content="Click to learn how to use this to make adding players to this team easier"
                        placement="bottom"
                      >
                        <CLink
                          onClick={() => setShowTeamCodeExplanationModal(true)}
                          style={{
                            display: 'flex',
                            fontFamily: 'AvinerHeavy',
                            fontWeight: 900,
                            alignItems: 'center',
                            gap: 4,
                          }}
                        >
                          <FaInfoCircle style={{ marginTop: -5 }} />
                          {`Team Code: ${teamData.teamData.code}`}
                        </CLink>
                      </CTooltip>
                    )}
                    {canDoAction({
                      action: ACCESS_ACTIONS.CREATE_PLAYER,
                      tier,
                      type,
                    }).canView && (
                      <CButton
                        color="primary"
                        id="players-add-player-button"
                        onClick={() => {
                          checkAccess({
                            action: ACCESS_ACTIONS.CREATE_PLAYER,
                            tier,
                            type,
                            thresholdCount:
                              _.isString(teamData?.teamData?._id) &&
                              teamData.teamData._id.length > 0
                                ? (teamCounts?.[teamData.teamData._id]
                                    ?.countPlayers || 0) + 1
                                : 1,
                            threshold:
                              _.isString(teamData?.teamData?._id) &&
                              teamData.teamData._id.length > 0
                                ? teamCounts?.[teamData.teamData._id]
                                    ?.maxPlayers
                                : null,
                            showSubscriptionModal,
                            goToUrl,
                          }).navigateWithAccess(TABS.COACH_PLAYERS_CREATE.path);
                        }}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <span className="m-0 mr-2 h3">+</span> Add player
                      </CButton>
                    )}
                  </>
                }
              />
            </CCardHeader>

            <CCardBody>
              {loading ? (
                <Loading />
              ) : (
                <CDataTable
                  striped
                  items={players}
                  itemsPerPageSelect={true}
                  itemsPerPage={50}
                  pagination={true}
                  hover
                  activePage={page}
                  tableFilter={true}
                  sorter
                  sorterValue={{ column: 'name', asc: true }}
                  fields={tableFields}
                  noItemsViewSlot={
                    <p className="d-flex align-content-center justify-content-center pt-4 pb-4">
                      <FiAlertCircle size="30" color="#e55353" />
                      <span className="pl-2 font-xl">No players added.</span>
                    </p>
                  }
                  scopedSlots={{
                    name: (player) => (
                      <td className="font-weight-bold">
                        <Link
                          to={TABS.COACH_PLAYERS_DETAILS.pathVar(
                            _.pick(player, ['id', 'name'])
                          )}
                        >
                          {player.name}
                        </Link>
                      </td>
                    ),
                    team: (player) => (
                      <td>
                        {(player._teams || [])
                          .map((tId) =>
                            _.isObject(teamData.map) &&
                            _.isObject(teamData.map[tId]) &&
                            _.isString(teamData?.map[tId].name)
                              ? teamData.map[tId].name
                              : ''
                          )
                          .filter((name) => name !== '')
                          .join(', ')}
                      </td>
                    ),
                    position: (player) => (
                      <td>
                        {(player._positions || [])
                          .map((p) =>
                            _.isString(p) ? p : _.isObject(p) ? p.label : ''
                          )
                          .join(', ')}
                      </td>
                    ),
                    email: (player) => <td>{player.email}</td>,
                    show_details: (player) => (
                      <td className="text-right">
                        {
                          <Link
                            to={TABS.COACH_PLAYERS_EDIT.pathVar(
                              _.pick(player, ['id', 'name'])
                            )}
                          >
                            <FaEdit />
                          </Link>
                        }
                      </td>
                    ),
                  }}
                />
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      {!!teamData?.teamData?.code && (
        <TeamCodeExplanationModal
          show={showTeamCodeExplanationModal}
          code={teamData.teamData.code}
          onClose={() => {
            setShowTeamCodeExplanationModal(false);
          }}
        />
      )}
    </PlayerWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.DataPlayerState?.data || [],
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Players);
