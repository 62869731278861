import 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { connect, useDispatch } from 'react-redux';
import {
  closeConfirmDeleteModal,
  closeConfirmDuplicateModal,
  closeConfirmEditModal,
  editQuestion,
  questionAdded,
  questionRemoved,
} from '../../actions/questionActionCreator';
import ConfirmModal from '../../r-components/ConfirmModal/ConfirmModal';
import { deleteQuestion, duplicateQuestion } from '../../helpers/data';
import { toast } from 'react-toastify';
import TourView, { showTour } from '../../r-components/Tours/TourView';
import { TABS } from '../../AppTabs';
import { useHistory } from 'react-router-dom';

const QuestionWrapper = ({ questionData, userData, children }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [submittingConfirm, setSubmittingConfirm] = useState(false);

  // useEffect(() => {
  //   showTour(userData, 'questions', dispatch);
  // }, [userData]);

  const confirmEdit6ToolQuestion = async ({ hideOriginal = false } = {}) => {
    setSubmittingConfirm(true);
    const duplicatedQuestion = await duplicateQuestion({
      questionId: questionData.question.id,
      owningOrganization: userData.organizationId || 'a',
      hideOriginal,
    });
    if (
      !!duplicatedQuestion &&
      _.isObject(duplicatedQuestion) &&
      !!duplicatedQuestion.id &&
      _.isString(duplicatedQuestion.id) &&
      duplicatedQuestion.id.length > 0
    ) {
      closeConfirmEditModal();
      questionAdded({
        question: duplicatedQuestion,
      });
      history.push(
        TABS.COACH_QUESTIONS_EDIT.pathVar({
          id: duplicatedQuestion.id,
        })
      );
    } else {
      toast('Error duplicating question for edit', {
        type: 'error',
      });
    }
    closeAllModals();
  };

  const closeAllModals = () => {
    dispatch(closeConfirmDuplicateModal());
    dispatch(closeConfirmEditModal());
    dispatch(closeConfirmDeleteModal());
    setSubmittingConfirm(false);
  };
  return (
    <>
      <div className="container-fluid space-1">
        <div className="row">
          <div className="col">
            <div className="pr-lg-4">
              {children}
              <ConfirmModal
                show={questionData.showConfirmDeleteQuestionModal}
                title="Confirm Delete"
                message="Are you sure you would like to delete this question? This cannot be undone."
                okButtonColor="danger"
                cancelButtonColor="primary"
                okButtonText="Delete"
                onClickCancel={closeAllModals}
                onClickConfirm={async () => {
                  deleteQuestion({
                    questionId: questionData.questionId,
                    owningOrganization: userData.organizationId || 'a',
                  });
                  closeAllModals();
                }}
              />
              <ConfirmModal
                show={questionData.showConfirmDuplicateQuestionModal}
                title="Duplicate Question?"
                message="Are you sure you would like to duplicate this question?"
                okButtonColor="primary"
                cancelButtonColor="danger"
                okButtonText="Duplicate"
                onClickCancel={closeAllModals}
                onClickConfirm={async () => {
                  const dQuestion = await duplicateQuestion({
                    questionId: questionData.questionId,
                    owningOrganization: userData.organizationId || 'a',
                  });
                  questionAdded({ question: dQuestion });
                  if (
                    _.isObject(dQuestion) &&
                    !!dQuestion.id &&
                    _.isString(dQuestion.id) &&
                    dQuestion.id.length > 0
                  ) {
                    toast('Duplicated question', { type: 'success' });
                  } else {
                    toast('Error duplicating question', { type: 'error' });
                  }
                  closeAllModals();
                }}
              />
              <ConfirmModal
                show={questionData.showConfirmEditQuestionModal}
                title="Confirm Edit"
                message="This question is a 6Tool question. To edit the content, a duplicate will be created with you as the owner. Choose if you'd like to keep the original 6Tool question as well, or if you'd rather replace it with your version, removing the 6Tool version from view."
                okButtonColor="primary"
                cancelButtonColor="danger"
                submitting={submittingConfirm}
                buttons={[
                  {
                    variant: 'outline',
                    color: 'primary',
                    text: 'Duplicate & Edit',
                    onClick: async () => {
                      confirmEdit6ToolQuestion({ hideOriginal: false });
                    },
                  },
                ]}
                okButtonText="Replace & Edit"
                onClickCancel={closeAllModals}
                onClickConfirm={async () => {
                  confirmEdit6ToolQuestion({ hideOriginal: true });
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <TourView name="questions" />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    questionData: state.ViewQuestionState,
    userData: state.UserState,
  };
};

const mapDispatchToProps = {
  editQuestion,
  closeConfirmEditModal,
  closeConfirmDeleteModal,
  closeConfirmDuplicateModal,
  questionAdded,
  questionRemoved,
};

QuestionWrapper.propTypes = {
  questionData: PropTypes.shape({
    questions: PropTypes.bool,
    addQuestions: PropTypes.bool,
    questionDetails: PropTypes.bool,
    createNewQuiz: PropTypes.bool,
    newQuizQuestionIds: PropTypes.array,
    question: PropTypes.object,
    setShowConfirmDuplicateModal: PropTypes.bool,
    setShowConfirmEditModal: PropTypes.bool,
    showConfirmDeleteModal: PropTypes.bool,
  }),
  userData: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.string,
      organizationId: PropTypes.string,
      type: PropTypes.array,
    }),
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionWrapper);
