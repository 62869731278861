import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CTooltip,
} from '@coreui/react';
// import style from '../quizzesList/quizzes.scss';
import './playerDetails.scss';
import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import { FiEdit3 } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { editPlayer } from '../../../actions/playerActionCreator';
import { db, functions, storage } from '../../../index';
import { Loading } from '../../../r-reusable';
import _ from 'lodash';
import { Link, withRouter } from 'react-router-dom';
import { paramEncode, TABS } from '../../../AppTabs';
import DoneButton from '../../../r-components/DoneButton/DoneButton';
import PlayerWrapper from '../../../pages/component/PlayerWrapper';
import HeaderWithBackButton from '../../../r-components/HeaderWithBackButton/HeaderWithBackButton';
import BallField from '../../../r-components/BallField/BallField';
import { positionHighlightsAndLabelForPositions } from '../../../r-reusable/Positions';
import { Container } from 'react-bootstrap';
import PlayerExamResults from './PlayerExamResults';
import { format } from 'date-fns';
import { renderToString } from 'react-dom/server';
import { toast } from 'react-toastify';
import Letterhead from '../../../r-components/Letterhead/Letterhead';
import QuizQuestionsModal from '../../../r-components/QuizQuestionsModal/QuizQuestionsModal';
import { handleError } from '../../../helpers/errors';
import { shortDateTime } from '../../../helpers/utils';

const PlayerDetails = ({ match }) => {
  const dispatch = useDispatch();
  const divRef = useRef(null);
  const [userId, setUserId] = useState('a');
  const organizationID = useSelector(
    (state) => state?.DataOrganizationState?.data?.id
  );
  const teamID = useSelector((state) => state?.DataTeamState?.teamData?._id);
  const isMounted = useRef(null);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [positionHighlights, setPositionHighlights] = useState([]);
  const [invitedExams, setInvitedExams] = useState([]);
  const [takenExams, setTakenExams] = useState([]);
  const [showSinglePlayerResultModal, setShowSinglePlayerResultModal] =
    useState(false);
  const [showingQuiz, setShowingQuiz] = useState(null);
  const [showQuestionsResponses, setShowQuestionsResponses] = useState([]);
  const examData = useSelector((state) => state.DataExamState || {});
  const quizDataMap = useSelector((state) => state.DataQuizState?.map || {});
  const quizData = useSelector((state) => state.DataQuizState?.data || []);
  const [uninvitedExams, setUninvitedExams] = useState([]);
  const [pdfLoading, setPDFLoading] = useState(false);
  const [styleSheetContent, setStyleSheetContent] = useState(null);
  const [allQuestionIDs, setAllQuestionIDs] = useState([]);
  const questionMap = useSelector((state) =>
    (state?.DataQuestionState?.data || [])
      .filter((q) => allQuestionIDs.includes(q.id))
      .reduce((result, obj) => {
        result[obj.id] = obj;
        return result;
      }, {})
  );

  useEffect(() => {
    let questions = [];
    takenExams.forEach((exam) => {
      (exam.questions || []).forEach((question) => {
        questions.push(question.questionId);
      });
    });
    questions = Array.from(new Set(questions));
    setAllQuestionIDs(questions);
  }, [takenExams]);

  useEffect(() => {
    setUserId(match.params.id || 'a');
  }, [match]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const groups = [];
        const groupsPromise = [];
        isMounted.current = true;
        setLoading(true);

        const userRef = doc(db, 'users', userId);
        const userResponse = await getDoc(userRef);
        const userData = userResponse.data();

        if (userData?.groups?.length) {
          userData.groups.forEach((groupId) =>
            groupsPromise.push(getDoc(doc(db, 'groups', groupId)))
          );
          const groupsResponse = await Promise.all(groupsPromise);
          groupsResponse.forEach((group) => {
            if (group.exists) {
              groups.push({
                id: group.id,
                ...group.data(),
              });
            }
          });

          if (isMounted.current) {
            setLoading(false);
            setUser({
              ...userData,
              id: userId,
              groups,
            });
          }
        } else {
          if (isMounted.current) {
            setLoading(false);
            setUser({
              ...userData,
              id: userId,
              groups,
            });
            const { positionHighlights } =
              positionHighlightsAndLabelForPositions(userData?.positions);
            setPositionHighlights(positionHighlights);
          }
        }
      } catch (e) {
        handleError({
          error: e,
          internal: 'PlayerDetails.js - loadData',
        });
        if (isMounted.current) {
          setLoading(false);
        }
      }
    };

    loadData();

    return () => {
      isMounted.current = false;
    };
  }, [userId]);

  const _handleSetShowingQuestions = (shouldShow) => {
    if (shouldShow === false) {
      setShowSinglePlayerResultModal(false);
      setShowQuestionsResponses([]);
      setShowingQuiz(null);
    }
    setShowingQuiz(shouldShow);
  };

  useEffect(() => {
    if (divRef.current) {
      console.log(divRef.current.offsetWidth);
    }
  }, [divRef]);

  useEffect(() => {
    let takens = examData?.taken?.filter((tE) => tE.user_id === userId);
    let invites = examData?.invites?.filter((tE) => tE.user_id === userId);
    setTakenExams(
      [...takens].map((t) => {
        let invite = { ...t };
        invite.finished_formatted = _.isObject(t?.finished)
          ? format(
              new Date(t?.finished?.seconds * 1000 || t?.finished),
              'MMM d'
            )
          : '';
        invite.title = quizDataMap?.[t.quiz_id]?.title || 'Quiz';
        return invite;
      })
    );
    setInvitedExams(
      _.chain([...invites])
        .groupBy('quiz_id')
        .map((objs, quizId) => ({
          ..._.maxBy(objs, 'created.seconds'),
          numInvites: objs.length,
        }))
        .value()
        .map((t) => {
          let invite = { ...t };
          invite.created_formatted = _.isObject(t?.created)
            ? format(
                new Date(t?.created?.seconds * 1000 || t?.created),
                'MMM d'
              )
            : '';
          invite.title = quizDataMap[t?.quiz_id]?.title || 'Quiz';
          return invite;
        })
    );
    let invitedQuizzes = Array.from(
      new Set([
        ...(takens || []).map((t) => t.quiz_id),
        ...(invites || []).map((t) => t.quiz_id),
      ])
    );
    setUninvitedExams(
      quizData
        .filter((q) => !invitedQuizzes.includes(q.id))
        .map((q) => {
          return {
            title: q.title,
            quiz_id: q.id,
            exam_id: q.examId,
            user_id: userId,
            team_id: q.teamId,
            organization_id: q.organizationId,
          };
        })
    );
  }, [examData, quizDataMap, quizData, userId]);

  const generatePDF = async () => {
    try {
      setPDFLoading(true);
      const generatePDFCallable = functions.httpsCallable(
        'generatePDFCallable'
      );

      // Render your React component and get the generated HTML
      const component = renderToString(
        <CCard>
          <CCardHeader>
            <Letterhead />
          </CCardHeader>
          <CCardBody>
            <PlayerExamResults
              takenExams={takenExams}
              invitedExams={invitedExams}
              uninvitedExams={uninvitedExams}
              playerName={user.name}
              playerEmail={user.email}
              organization_id={organizationID}
              question_map={questionMap}
              team_id={teamID}
              initialNumberOfQuestionColumns={30}
              forPrint={true}
            />
          </CCardBody>
        </CCard>
      );
      const css = `.quiz-results-grid-container {display: flex;  flex-direction: column;  .quiz-results-grid-row { display: grid;    justify-content: center;    cursor: pointer;    & > .quiz-results-grid-header {cursor: default;} & > .quiz-results-grid-header.quiz-results-question-number-header {text-align: center;cursor: help;} &:hover {& > div:not(.quiz-results-grid-header) {  background-color: #efefef;}.show-on-hover {visibility: visible;} } .show-on-hover {visibility: hidden;}}.quiz-results-separator-header {display: flex;justify-content: space-between;    align-items: center;    border-top: 1px solid black; text-align: center; margin-top: 20px; padding-top: 20px; padding-bottom: 10px; cursor: pointer;  }}`;
      const html = `<html><header><style>${css}</style><body>${component}</body></html>`;

      // Invoke the Cloud Function and pass the HTML content
      const response = await generatePDFCallable({
        html,
        teamID,
        organizationID,
        filename: `org-${organizationID}/reports/player-${paramEncode(
          user.name
        )}-$TIME`,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
      let filename = response.data.filename;
      if (_.isString(filename) && filename.length > 0) {
        let downloadUrl = await storage.ref().child(filename).getDownloadURL();
        window.open(downloadUrl);
        try {
          const addMediaCallable = functions.httpsCallable('addMediaCallable');
          addMediaCallable({
            downloadUrl,
            organizationId: organizationID,
            user_id: userId,
            usage: 'report',
          });
        } catch (error) {
          console.error(error);
          handleError({ internal: 'ExamDetails - generatePDF uploadMedia' });
        }
        toast('PDF Downloaded', { type: 'success' });
      } else {
        handleError({
          error: `Error generating PDF: ${filename}`,
          internal: 'PlayerDetails.js - generatePDF',
          toast: 'There was an error generating PDF',
        });
      }
    } catch (error) {
      console.error(error);
      handleError({
        error: `Error generating PDF`,
        internal: 'PlayerDetails.js - generatePDF catch',
        toast: 'There was an error generating PDF',
      });
    } finally {
      setPDFLoading(false);
    }
  };

  return (
    <PlayerWrapper>
      <CRow>
        <CCol xs={12}>
          <CCard>
            <HeaderWithBackButton>
              <div
                className="d-flex justify-content-between"
                style={{ alignItems: 'flex-start' }}
              >
                <div>
                  <h2 style={{ marginBottom: -10 }}>{user.name}</h2>
                  <div>{user.email}</div>
                </div>
                <BallField
                  style={{ marginTop: -30 }}
                  positionHighlights={positionHighlights}
                  size={100}
                />

                <div style={{ flex: 1 }}></div>
                <span className={`text-right noprint`}>
                  <Link
                    to={TABS.COACH_PLAYERS_EDIT.pathVar(
                      _.pick(user, ['id', 'name'])
                    )}
                  >
                    <FiEdit3 /> Edit Player
                  </Link>
                </span>
                <div className="printonly">{shortDateTime()}</div>
              </div>
            </HeaderWithBackButton>

            <CCardBody>
              {loading ? (
                <Loading />
              ) : (
                <div ref={divRef}>
                  <PlayerExamResults
                    takenExams={takenExams}
                    invitedExams={invitedExams}
                    uninvitedExams={uninvitedExams}
                    showSinglePlayerResultModal={({ quiz, responses }) => {
                      setShowingQuiz(quiz);
                      setShowSinglePlayerResultModal(true);
                      setShowQuestionsResponses(responses);
                    }}
                    playerName={user.name}
                    playerEmail={user.email}
                    generatePDF={generatePDF}
                    loadingPDF={pdfLoading}
                    organization_id={organizationID}
                    question_map={questionMap}
                    team_id={teamID}
                    width={divRef.current ? divRef.current.offsetWidth : 999}
                  />
                </div>
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <QuizQuestionsModal
        show={showSinglePlayerResultModal}
        setShow={_handleSetShowingQuestions}
        quiz={showingQuiz}
        singleResponse={true}
        responses={showQuestionsResponses}
      />
    </PlayerWrapper>
  );
};

export default withRouter(PlayerDetails);
