import styled from 'styled-components';

import mask from '../../assets/mask.png';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 120px 0;
  z-index: 0;
  background: #151b26 url(${mask}) top center no-repeat;
  background-size: cover;

  &:before {
    content: '';
    display: block;
    background: #151b26;
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
`;

export const Content = styled.div`
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const H1 = styled.h1`
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 46px;
  color: #fff;
  line-height: 50px;
  text-align: center;
  margin-bottom: 20px;
`;

export const Steps = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StepItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  color: #fff;

  span {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    display: block;
    border-bottom: solid 4px #fff;
    text-align: center;
    padding: 0px 20px 20px;
    width: 100%;
  }

  strong {
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 50px;
    display: block;
  }

  ${({ current }) =>
    current &&
    `
    color: #f6d30d;
    span {
      border-bottom: solid 4px #f6d30d;
    }
  `}
`;
