import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCardHeader,
  CCol,
  CCollapse,
  CFormGroup,
  CInput,
  CInputCheckbox,
  CLabel,
  CListGroup,
  CListGroupItem,
  CRow,
  CTooltip,
} from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { FiCheckCircle } from 'react-icons/fi';
import ViewScoreBoard from '../ViewScoreBoard/ViewScoreBoard';
import Select from 'react-select';
import { useSelector } from 'react-redux';

import ExamTakenDetails from '../../r-views/pages/quizTakenDetails/QuizTakenDetails';
import {
  defaultExamData,
  groupsForOrganizationIdAndTeamId,
  playersInOrganization,
} from '../../helpers/data';
import {
  FaCheckSquare,
  FaChevronDown,
  FaChevronLeft,
  FaInfoCircle,
  FaRegSquare,
  FaSquare,
} from 'react-icons/fa';
import _ from 'lodash';
import { handleError } from '../../helpers/errors';

const ReviewExamBody = ({
  data,
  editable,
  handleExamDataChange,
  resetData,
}) => {
  const [accordion, setAccordion] = useState(null);
  // const { exam, quiz, questions, groups, inviteAllUser } = data;
  const [examData, setExamData] = useState({});
  const [prefilledQuizName, setPrefilledQuizName] = useState('');
  const [quizTimeLimit, setQuizTimeLimit] = useState(
    defaultExamData.quiz.estTime
  );
  const [dummy, setDummy] = useState(0);
  const [exam, setExam] = useState(defaultExamData.exam);
  const [quiz, setQuiz] = useState(defaultExamData.quiz);
  const [showPlayersInvited, setShowPlayersInvited] = useState(false);
  const [allPlayersForTeamDict, setAllPlayersForTeamsDict] = useState({});
  // const [allPlayers, setAllPlayers] = useState([]);
  // const [groupPlayers, setGroupPlayers] = useState([]);
  const [groupPlayersForTeamDict, setGroupPlayersForTeamDict] = useState({});
  const [questions, setQuestions] = useState(defaultExamData.questions);
  // const [groups, setGroups] = useState(defaultExamData.groups);
  const [groupsForTeamDict, setGroupsForTeamDict] = useState({});
  const [inviteAllUserForTeamDict, setInviteAllUserForTeamDict] = useState({});
  // const [inviteAllUser, setInviteAllUser] = useState(
  //   defaultExamData.inviteAllUsers
  // );
  const [expandQuestionSection, setExpandQuestionSection] = useState(false);
  const [groupOptionsForTeamDict, setGroupOptionsForTeamDict] = useState({});
  // const [groupOptions, setGroupOptions] = useState([]);
  const darkMode = useSelector((state) => state.ThemeState.darkMode);
  const teamData = useSelector((state) => state.DataTeamState);
  const userData = useSelector((state) => state.UserState);
  const organizationId = useSelector(
    (state) => state.DataOrganizationState?.data?.id
  );
  const groupData = useSelector((state) => state.DataGroupState);

  const [_quizTeamID, _setQuizTeamID] = useState(null);
  const [_selectedQuizTeamID, _setSelectedQuizTeamID] = useState(null);
  const [selectedTeamIDs, setSelectedTeamIDs] = useState([]);

  useEffect(() => {
    if (
      _.isString(teamData?.teamData?._id) &&
      teamData.teamData._id.length > 1
    ) {
      _setQuizTeamID(teamData.teamData._id);
      _setSelectedQuizTeamID(teamData.teamData._id);
      setSelectedTeamIDs([teamData.teamData._id]);
    } else {
      if (_.isArray(teamData?.data) && teamData.data.length === 1) {
        if (
          _.isString(teamData.data[0]._id) &&
          teamData.data[0]._id.length > 1
        ) {
          _setQuizTeamID(teamData.data[0]._id);
          _setSelectedQuizTeamID(teamData.data[0]._id);
          setSelectedTeamIDs([teamData.data[0]._id]);
        }
      }
    }
    if (_.isArray(teamData?.data) && teamData.data.length > 0) {
      const _groupPlayersForTeamDict = {};
      const _groupsForTeamDict = {};
      const _inviteAllUserForTeamDict = {};
      for (const item of teamData.data) {
        if (_.isString(item._id) && item._id.length > 0) {
          _inviteAllUserForTeamDict[item._id] = defaultExamData.inviteAllUsers;
        }
      }
      setInviteAllUserForTeamDict(_inviteAllUserForTeamDict);
    }
    try {
      setAllPlayersForTeamsDict(
        _.mapValues(_.keyBy(teamData.data, '_id'), (obj) =>
          _.defaultTo(obj.players, [])
        )
      );
    } catch (error) {
      handleError({
        error,
        internal: 'ReviewExamBody - Setting all players catch',
      });
    }
    try {
      let gO = _.mapValues(_.groupBy(groupData.data, 'teamId'), (arr) =>
        _.map(arr, (obj) => ({ value: obj.id, label: obj.title }))
      );
      setGroupOptionsForTeamDict(gO);
    } catch (error) {
      handleError({
        error,
        internal: 'ReviewExamBody - Setting all groups catch',
      });
    }
  }, [teamData, groupData]);

  useEffect(() => {
    try {
      if (_.isArray(teamData?.data) && teamData.data.length > 0) {
        let _groupPlayersForTeamDict = {};
        for (let index = 0; index < teamData.data.length; index++) {
          const t = teamData.data[index];

          if (!!t._id) {
            if (inviteAllUserForTeamDict[t._id]) {
              _groupPlayersForTeamDict[t._id] = allPlayersForTeamDict[t._id];
              if (_.isFunction(handleExamDataChange)) {
                handleExamDataChange({});
              }
            } else if (
              !!groupsForTeamDict[t._id] &&
              _.isArray(groupsForTeamDict[t._id]) &&
              groupsForTeamDict[t._id].length > 0
            ) {
              let groupIds = groupsForTeamDict[t._id];
              _groupPlayersForTeamDict[t._id] = _.filter(
                allPlayersForTeamDict[t._id],
                function (obj) {
                  return (
                    (_.intersection(obj.groups, groupIds) || []).length > 0
                  );
                }
              );
            }
          }
        }
        setGroupPlayersForTeamDict(_groupPlayersForTeamDict);
      }
    } catch (error) {
      handleError({
        error,
        internal: 'ReviewExamBody - Setting group catch',
      });
    }
  }, [groupsForTeamDict, allPlayersForTeamDict, inviteAllUserForTeamDict]);

  useEffect(() => {
    setPrefilledQuizName(data?.quiz?.title || '');
    setExam(data?.exam || defaultExamData.exam);
    setQuestions(data.questions || defaultExamData.questions);
    setQuiz(data.quiz || defaultExamData.quiz);
    // setSelectedTeamIDs(data.selectedTeamIDs || []);
    setGroupPlayersForTeamDict({});
    // setAllPlayersForTeamsDict({});
    setGroupsForTeamDict({});
    // setGroups(data.groups || defaultExamData.groups);
    setInviteAllUserForTeamDict({});
    // setInviteAllUser([]);
  }, [data]);

  useEffect(() => {
    if (resetData === true) {
      setPrefilledQuizName('');
      setExam(defaultExamData.exam);
      setQuestions(defaultExamData.questions);
      setQuiz(defaultExamData.quiz);
      setSelectedTeamIDs([]);
      setGroupPlayersForTeamDict({});
      // setAllPlayersForTeamsDict({});
      setGroupsForTeamDict({});
      // setGroups(data.groups || defaultExamData.groups);
      setInviteAllUserForTeamDict({});
    }
  }, [resetData]);

  useEffect(() => {
    let teamsWhichInviteAll = Object.keys(inviteAllUserForTeamDict).filter(
      (key) => inviteAllUserForTeamDict[key] === true
    );
    let groups = teamsWhichInviteAll.map((teamId) => {
      return { id: 'all', teamId };
    });
    Object.entries(groupsForTeamDict).forEach(([teamId, groupIds]) => {
      if (!teamsWhichInviteAll.includes(teamId)) {
        groupIds.forEach((id) => {
          groups.push({ id, teamId });
        });
      }
    });
    try {
      let _groupsToInviteForTeamDict = {};
      if (_.isArray(teamData?.data) && teamData.data.length > 0) {
        for (let index = 0; index < teamData.data.length; index++) {
          const t = teamData.data[index];

          if (!!t._id) {
            _groupsToInviteForTeamDict[t._id] = Object.values(
              _.pick(groupData.map, [
                ...(groupsForTeamDict[t._id] || defaultExamData.groups),
              ])
            );
            if (inviteAllUserForTeamDict[t._id]) {
              _groupsToInviteForTeamDict[t._id] = Object.values(
                _.pick(
                  groupData.map,
                  _.union(
                    _groupsToInviteForTeamDict[t._id],
                    groupOptionsForTeamDict[t._id].filter(
                      (g) => g.default === true
                    )
                  )
                )
              );
            }
          }
        }
      }
      if (_.isFunction(handleExamDataChange)) {
        handleExamDataChange({
          exam,
          questions,
          quiz,
          groups,
          organization_id: organizationId,
          teamIDs: selectedTeamIDs,
        });
      }
    } catch (error) {
      handleError({
        error,
        internal: 'ReviewExamBody - Setting all players catch',
      });
    }
  }, [
    exam,
    questions,
    quiz,
    groupsForTeamDict,
    groupData,
    inviteAllUserForTeamDict,
    selectedTeamIDs,
  ]);

  useEffect(() => {
    setQuiz({ ...quiz, ...{ title: prefilledQuizName } });
    setExam({ ...exam, ...{ title: prefilledQuizName } });
  }, [prefilledQuizName]);

  useEffect(() => {
    setQuiz({ ...quiz, ...{ estTime: parseInt(quizTimeLimit) } });
  }, [quizTimeLimit]);

  const renderInviteDisclaimer = () => (
    <p
      style={{
        fontSize: 14,
        lineHeight: '18px',
        textAlign: 'center',
      }}
    >
      Select at least one team to create the quiz for. You can invite players at
      a later time as well.
      <br />
      If you'd like to create this quiz for a team or teams and save it, you do
      not need to invite any players right now.
    </p>
  );
  const thumbs = (files) =>
    files?.map((file) => {
      if (file?.type.includes('image')) {
        return (
          <div className="thumb" key={file?.name}>
            <div className="thumb-inner">
              <img src={file?.preview} alt="" />
            </div>
          </div>
        );
      }

      if (file?.type.includes('video')) {
        return (
          <div className="thumb" key={file?.name}>
            <video width="auto" height="auto" controls>
              <source src={file?.preview} type={file?.type} />
            </video>
          </div>
        );
      }

      return null;
    });

  function toggleIdInArray(idsArray, id) {
    const index = idsArray.indexOf(id);
    if (index !== -1) {
      // The id exists in the array, so remove it
      idsArray.splice(index, 1);
    } else {
      // The id does not exist in the array, so add it
      idsArray.push(id);
    }
  }

  return (
    <>
      <CCard key={dummy}>
        <CCardHeader style={{ fontWeight: 'boldest' }}>
          Create a Quiz
        </CCardHeader>
        <CCardBody>
          {editable ? (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 10,
                  alignItems: 'center',
                }}
              >
                <b>Title:</b>
                <CInput
                  value={prefilledQuizName}
                  onChange={(e) => {
                    setPrefilledQuizName(e.target.value);
                  }}
                  style={{
                    display: 'inline-block',
                    width: 300,
                    maxWidth: 'calc(100% - 60px)',
                    flex: 1,
                  }}
                />
                <b style={{ marginLeft: 40 }}>Time limit (min):</b>
                <CInput
                  type="number"
                  min="1"
                  value={quizTimeLimit}
                  onChange={(e) => {
                    setQuizTimeLimit(e.target.value);
                  }}
                  style={{
                    display: 'inline-block',
                    width: 60,
                    maxWidth: 'calc(100% - 120px)',
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <p>
                <b>Title:</b> {quiz?.title || ''}
              </p>
              <p>
                <b>Time limit (min):</b> {`${quiz?.estTime || 0}`}
              </p>
            </>
          )}
          {quiz?.intros && (
            <p>
              <b>Introduction:</b> {quiz?.intros || ''}
            </p>
          )}
        </CCardBody>
      </CCard>

      <CCard>
        <CCardHeader
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            setExpandQuestionSection(!expandQuestionSection);
          }}
        >
          <>Questions ({questions?.length || 0})</>
          {expandQuestionSection ? <FaChevronDown /> : <FaChevronLeft />}
        </CCardHeader>
        {expandQuestionSection && (
          <CCardBody>
            {(questions || []).map((question, index) => (
              <div id="accordion" key={`${question.text}-${index}`}>
                <CCard className="mb-0">
                  <CCardHeader id={`heading-${index}`}>
                    <CButton
                      block
                      color="link"
                      variant="ghost"
                      className="text-left m-0 p-0"
                      onClick={() =>
                        setAccordion(accordion === index ? null : index)
                      }
                    >
                      <h5 className="m-0 p-0 mb-1">{question.text}</h5>
                      <span className="text-muted">
                        {question.options.length} Options
                      </span>
                    </CButton>
                  </CCardHeader>
                  <CCollapse show={accordion === index}>
                    <CCardBody>
                      <CRow>
                        <CCol>
                          {question.showScoreboard && (
                            <div>
                              <ViewScoreBoard {...question.situation} />
                            </div>
                          )}
                          {question.title && (
                            <p>
                              <b>Title: </b> {question.title}
                            </p>
                          )}

                          <p>
                            <span>
                              <b>Difficulty:</b> {question.difficulty.value}
                            </span>
                          </p>

                          {question.withTimer && (
                            <p>
                              Question will be marked as answered within:
                              {question.timer}s
                            </p>
                          )}

                          <b>Options:</b>

                          <ul className="list-group list-group-accent">
                            {question.options.map((option) => (
                              <li
                                key={option.text}
                                className="list-group-item border-0"
                              >
                                {option.correct && '√'} {option.text}, points:{' '}
                                {option.points}
                              </li>
                            ))}
                          </ul>
                        </CCol>
                        <CCol xs="5" className="text-right">
                          <aside className="thumbs-container">
                            {thumbs(question.files)}
                          </aside>
                        </CCol>
                      </CRow>
                    </CCardBody>
                  </CCollapse>
                </CCard>
              </div>
            ))}
          </CCardBody>
        )}
      </CCard>
      {editable ? (
        <>
          {_.isArray(teamData?.data) && teamData.data.length > 1 ? (
            <>
              <CCard>
                <CCardHeader
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    Quiz Invites - Choose team(s)/group(s) to create this quiz
                    for
                  </div>
                  <CTooltip content="You can invite groups of players from multiple teams at once">
                    <FaInfoCircle />
                  </CTooltip>
                </CCardHeader>
                <CCardBody>
                  <CRow>
                    <CCol sm={12}>
                      <CCardGroup
                        deck={true}
                        style={{
                          display: 'grid',
                          gridTemplateColumns:
                            teamData.data.length === 2 ||
                            teamData.data.length === 4
                              ? '1fr 1fr'
                              : '1fr 1fr 1fr',
                          rowGap: 30,
                        }}
                      >
                        {teamData.data.map((t, index) => {
                          const isSelected = selectedTeamIDs.includes(t._id);
                          return (
                            <CCard
                              key={`team-invites-${t._id}-${index}`}
                              className={isSelected ? 'selected' : ''}
                            >
                              <CCardHeader
                                style={{
                                  flexDirection: 'row',
                                  display: 'flex',
                                  gap: 10,
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                }}
                                onClick={() => {
                                  const _selectedTeamIDs = [...selectedTeamIDs];
                                  const index = _selectedTeamIDs.indexOf(t._id);
                                  if (index !== -1) {
                                    _selectedTeamIDs.splice(index, 1);
                                  } else {
                                    _selectedTeamIDs.push(t._id);
                                  }
                                  setSelectedTeamIDs(_selectedTeamIDs);
                                }}
                              >
                                {_.isString(t.imageUrl) &&
                                  t.imageUrl.length > 0 && (
                                    <img
                                      src={t.imageUrl}
                                      style={{
                                        height: 35,
                                        width: 35,
                                        objectFit: 'contain',
                                      }}
                                    />
                                  )}
                                <div>{t.name}</div>
                                <div style={{ flex: 1 }}></div>
                                {isSelected ? (
                                  <FaCheckSquare />
                                ) : (
                                  <FaRegSquare />
                                )}
                              </CCardHeader>
                              <CCardBody>
                                <CRow>
                                  <CCol sm={12}>
                                    {isSelected ? (
                                      <CFormGroup
                                        variant="custom-checkbox"
                                        className="text-left mb-3"
                                      >
                                        <CInputCheckbox
                                          custom
                                          id={`invite-all-user-${t._id}`}
                                          value={
                                            inviteAllUserForTeamDict[t._id]
                                          }
                                          checked={
                                            inviteAllUserForTeamDict[t._id]
                                          }
                                          onChange={() => {
                                            let _inviteAllUserForTeamDict = {
                                              ...inviteAllUserForTeamDict,
                                            };
                                            _inviteAllUserForTeamDict[t._id] =
                                              !_inviteAllUserForTeamDict[t._id];
                                            setInviteAllUserForTeamDict(
                                              _inviteAllUserForTeamDict
                                            );
                                            // setInviteAllUser(!inviteAllUser);
                                          }}
                                        />
                                        <CLabel
                                          variant="custom-checkbox"
                                          htmlFor={`invite-all-user-${t._id}`}
                                        >
                                          {`Invite all players on ${t.name}`}
                                        </CLabel>
                                      </CFormGroup>
                                    ) : (
                                      <div>
                                        Team not selected. Select team to choose
                                        groups to invite
                                      </div>
                                    )}
                                  </CCol>
                                </CRow>
                                <div
                                  style={{
                                    visibility:
                                      isSelected &&
                                      !inviteAllUserForTeamDict[t._id]
                                        ? 'visible'
                                        : 'hidden',
                                  }}
                                >
                                  <CRow>
                                    <CCol sm={12}>
                                      <Select
                                        isMulti
                                        noOptionsMessage={() => {
                                          return 'No groups for this team';
                                        }}
                                        placeholder="Select group(s)..."
                                        name="groups"
                                        options={groupOptionsForTeamDict[t._id]}
                                        value={(
                                          groupOptionsForTeamDict[t._id] || []
                                        ).filter((a) =>
                                          (
                                            groupsForTeamDict[t._id] || []
                                          ).includes(a.value)
                                        )}
                                        onChange={(gs) => {
                                          let _groupsForTeamDict = {
                                            ...groupsForTeamDict,
                                          };
                                          if (_.isArray(gs) && gs.length > 0) {
                                            _groupsForTeamDict[t._id] = gs.map(
                                              (g) => g.value
                                            );
                                          } else {
                                            _groupsForTeamDict[t._id] = [];
                                          }
                                          setGroupsForTeamDict(
                                            _groupsForTeamDict
                                          );
                                          // setGroups(gs);
                                        }}
                                        theme={(theme) => ({
                                          ...theme,
                                          colors: {
                                            ...theme.colors,
                                            primary: darkMode
                                              ? 'black'
                                              : theme.colors.primary,
                                            primary25: darkMode
                                              ? 'black'
                                              : theme.colors.primary25,
                                            dangerLight: darkMode
                                              ? 'black'
                                              : theme.colors.dangerLight,
                                            neutral0: darkMode
                                              ? '#2a2b36'
                                              : theme.colors.neutral0,
                                          },
                                        })}
                                      />
                                    </CCol>
                                  </CRow>
                                </div>
                              </CCardBody>
                            </CCard>
                          );
                        })}
                      </CCardGroup>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
              {renderInviteDisclaimer()}
            </>
          ) : (
            <CCard>
              <CCardHeader>Invite players (optional)</CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol sm={12}>
                    <CFormGroup
                      variant="custom-checkbox"
                      className="text-left mb-3"
                    >
                      <CInputCheckbox
                        custom
                        id="invite-all-user"
                        value={inviteAllUserForTeamDict[_quizTeamID]}
                        checked={inviteAllUserForTeamDict[_quizTeamID]}
                        onChange={() => {
                          let _inviteAllUserForTeamDict = {
                            ...inviteAllUserForTeamDict,
                          };
                          _inviteAllUserForTeamDict[_quizTeamID] =
                            !_inviteAllUserForTeamDict[_quizTeamID];
                          setInviteAllUserForTeamDict(
                            _inviteAllUserForTeamDict
                          );
                          // setInviteAllUser(!inviteAllUser);
                        }}
                      />
                      <CLabel
                        variant="custom-checkbox"
                        htmlFor="invite-all-user"
                      >
                        Invite all user of organization
                      </CLabel>
                    </CFormGroup>
                  </CCol>
                </CRow>
                {!inviteAllUserForTeamDict[_quizTeamID] && (
                  <CRow>
                    <CCol sm={12}>
                      <Select
                        isMulti
                        name="groups"
                        options={groupOptionsForTeamDict[_quizTeamID]}
                        value={(
                          groupOptionsForTeamDict[_quizTeamID] || []
                        ).filter((a) =>
                          (groupsForTeamDict[_quizTeamID] || []).includes(
                            a.value
                          )
                        )}
                        onChange={(gs) => {
                          let _groupsForTeamDict = {
                            ...groupsForTeamDict,
                          };
                          if (_.isArray(gs) && gs.length > 0) {
                            _groupsForTeamDict[_quizTeamID] = gs.map(
                              (g) => g.value
                            );
                          } else {
                            _groupsForTeamDict[_quizTeamID] = [];
                          }
                          setGroupsForTeamDict(_groupsForTeamDict);
                          // setGroups(gs);
                        }}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: darkMode ? 'black' : theme.colors.primary,
                            primary25: darkMode
                              ? 'black'
                              : theme.colors.primary25,
                            dangerLight: darkMode
                              ? 'black'
                              : theme.colors.dangerLight,
                            neutral0: darkMode
                              ? '#2a2b36'
                              : theme.colors.neutral0,
                          },
                        })}
                      />
                    </CCol>
                  </CRow>
                )}
                <CCardBody>
                  <div
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                    onClick={() => {
                      setShowPlayersInvited(!showPlayersInvited);
                    }}
                  >
                    {groupPlayersForTeamDict?.[_quizTeamID]?.length > 0 ? (
                      <>
                        {`${groupPlayersForTeamDict[_quizTeamID].length} out of ${allPlayersForTeamDict[_quizTeamID].length} players will be invited`}
                        {showPlayersInvited ? (
                          <FaChevronDown />
                        ) : (
                          <FaChevronLeft />
                        )}
                      </>
                    ) : (
                      <>{renderInviteDisclaimer()}</>
                    )}
                  </div>
                  {showPlayersInvited && (
                    <CListGroup>
                      {groupPlayersForTeamDict[_quizTeamID].map(
                        (player, index) => (
                          <CListGroupItem key={`${player?.id}--${index}`}>
                            {player.name}
                          </CListGroupItem>
                        )
                      )}
                    </CListGroup>
                  )}
                </CCardBody>
              </CCardBody>
            </CCard>
          )}
        </>
      ) : (
        <CCard>
          <CCardHeader>Users will be invited to this quiz</CCardHeader>
          <CCardBody>
            {inviteAllUserForTeamDict['FIX'] && (
              <div className="d-flex align-items-center mb-3">
                <FiCheckCircle size={18} color="#2eb85c" />
                <b className="pl-2">
                  All user of organization will be invited to take this quiz
                </b>
              </div>
            )}

            <div>
              <h4>User of group will be invited:</h4>
              {groupsForTeamDict['FIX']?.length ? (
                groupsForTeamDict['FIX'].map((group, index) => (
                  <p key={`${index}--${group.value}`}>{group.label}</p>
                ))
              ) : (
                <p>No group added</p>
              )}
            </div>
          </CCardBody>
        </CCard>
      )}
    </>
  );
};

export default ReviewExamBody;
