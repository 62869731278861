import { CLink, CTooltip } from '@coreui/react';
import React, { useEffect, useRef, useState } from 'react';

import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight,
  FaCheck,
  FaEnvelopeOpenText,
  FaFilePdf,
  FaPrint,
  FaSortDown,
  FaSortUp,
  FaSyncAlt,
  FaTimes,
  FaTrashAlt,
} from 'react-icons/fa';
import '../quizzesList/quizzes.scss';
import ExamReviewQuestionDetails from '../examDetails/ExamReviewQuestionDetails';
import Tooltip from '../../../r-components/Tooltip/Tooltip';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { functions } from '../../..';
import { toast } from 'react-toastify';
import { invitePlayersToQuiz } from '../../../helpers/data';
import { handleError } from '../../../helpers/errors';
import {
  ACCESS_ACTIONS,
  canDoAction,
  checkAccess,
} from '../../../helpers/access';
import useAccountSelectors from '../../../hooks/useAccountSelectors';

const SORT_METHODS = {
  NAME: 'name',
  DATE: 'date',
  SCORE: 'score',
};
const SORT_DIRECTION = {
  ASC: true,
  DESC: false,
};
const DEFAULT_SORT_DIRECTION = {
  name: SORT_DIRECTION.ASC,
  date: SORT_DIRECTION.DESC,
  score: SORT_DIRECTION.DESC,
};

const PlayerExamResults = ({
  takenExams,
  invitedExams,
  uninvitedExams,
  questionResults,
  showSinglePlayerResultModal,
  playerName,
  playerEmail,
  generatePDF,
  loadingPDF,
  organization_id: organizationID,
  question_map: questionMap,
  team_id: teamID,
  initialNumberOfQuestionColumns,
  width,
  forPrint = false,
}) => {
  const divRef = useRef(null);
  const [nameWidth, setNameWidth] = useState(200);
  const [questionsPerPage, setQuestionsPerPage] = useState(20);
  const [questionPage, setQuestionPage] = useState(0);
  const [numPages, setNumPages] = useState(1);
  const [maxQuestions, setMaxQuestions] = useState(10);
  const [showingInvited, setShowingInvited] = useState(false);
  const [showingUninvited, setShowingUninvited] = useState(false);
  const [sortMethod, setSortMethod] = useState(SORT_METHODS.DATE);
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTION.DESC);
  const [_takenExams, _setTakenExams] = useState(takenExams);
  const [sortableData, setSortableData] = useState(false);
  const { tier, type, showSubscriptionModal } = useAccountSelectors();
  const { canDo: canViewResults } = canDoAction({
    action: ACCESS_ACTIONS.QUIZ_RESULTS,
    type,
    tier,
  });

  const [questionColumns, setQuestionColumns] = useState(
    initialNumberOfQuestionColumns || 0
  );

  useEffect(() => {
    setQuestionColumns(
      Math.max(
        5,
        Math.min(
          maxQuestions - questionPage * questionsPerPage,
          questionsPerPage
        )
      )
    );
  }, [maxQuestions, questionPage, questionsPerPage]);
  const handleResize = () => {
    if (divRef.current) {
      const maxQuestionsObj = _.maxBy(takenExams || [], (obj) =>
        _.size(obj.questions)
      );
      setMaxQuestions(_.size((maxQuestionsObj || {}).questions));

      let newNameWidth = nameWidth;
      if (width < 700) {
        newNameWidth = 160;
        setNameWidth(160);
      } else {
        newNameWidth = 200;
        setNameWidth(200);
      }
      let availableWidth = width - newNameWidth * 1.5 - 100;
      let numQuestionsInView = Math.floor(availableWidth / 30);
      setQuestionsPerPage(numQuestionsInView);
      setNumPages(Math.ceil(maxQuestions / numQuestionsInView));
    }
  };

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    handleResize();
  }, [takenExams, width]);

  useEffect(() => {
    let _tE = [...takenExams];
    switch (sortMethod) {
      case SORT_METHODS.NAME:
        _tE.sort((a, b) => {
          if (sortDirection === SORT_DIRECTION.DESC) {
            return a.title.localeCompare(b.title) * -1;
          }
          return a.title.localeCompare(b.title);
        });
        break;
      case SORT_METHODS.DATE:
        _tE.sort((a, b) => {
          if (!isNaN(a.finished?.seconds) && isNaN(b.finished?.seconds)) {
            return -1; // a comes first
          } else if (
            isNaN(a.finished?.seconds) &&
            !isNaN(b.finished?.seconds)
          ) {
            return 1; // b comes first
          } else {
            if (sortDirection === SORT_DIRECTION.DESC) {
              return a.finished?.seconds > b.finished?.seconds ? -1 : 1;
            }
            return a.finished?.seconds > b.finished?.seconds ? 1 : -1;
          }
        });

        break;
      default:
      case SORT_METHODS.SCORE:
        _tE.sort((a, b) => {
          if (!isNaN(a.score) && isNaN(b.score)) {
            return -1; // a comes first
          } else if (isNaN(a.score) && !isNaN(b.score)) {
            return 1; // b comes first
          } else {
            if (sortDirection === SORT_DIRECTION.DESC) {
              return a.score > b.score ? -1 : 1;
            }
            return a.score > b.score ? 1 : -1;
          }
        });
        break;
    }
    _setTakenExams(_tE);
    setSortableData(_tE.length > 1);
  }, [takenExams, sortDirection, sortMethod]);

  return (
    <div className="results-type-player">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <FaFilePdf style={{ visibility: 'hidden' }} />
        <h1 style={{ textAlign: 'center', flex: 1 }}>Results</h1>
        <CTooltip
          style={forPrint === true && { visibility: 'hidden' }}
          content="Print results"
        >
          <FaPrint
            className="noprint"
            style={
              forPrint === true
                ? { visibility: 'hidden' }
                : { marginRight: 4, cursor: 'pointer' }
            }
            onClick={() => {
              checkAccess({
                action: [ACCESS_ACTIONS.QUIZ_RESULTS],
                type,
                tier,
                showSubscriptionModal,
              }).doWithAccess(window.print);
            }}
          />
        </CTooltip>
        {loadingPDF ? (
          <CTooltip content="Creating report...">
            <FaSyncAlt className="spinning-icon noprint" />
          </CTooltip>
        ) : (
          <CTooltip content="Download report">
            <FaFilePdf
              className="noprint"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                checkAccess({
                  action: [ACCESS_ACTIONS.QUIZ_RESULTS],
                  type,
                  tier,
                  showSubscriptionModal,
                }).doWithAccess(generatePDF);
              }}
            />
          </CTooltip>
        )}
      </div>
      <div>
        {numPages > 1 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            {numPages > 2 && (
              <FaAngleDoubleLeft
                style={
                  questionPage > 0 ? { cursor: 'pointer' } : { opacity: 0.3 }
                }
                onClick={() => {
                  if (questionPage > 0) {
                    setQuestionPage(0);
                  }
                }}
              />
            )}
            <FaAngleLeft
              style={
                questionPage > 0 ? { cursor: 'pointer' } : { opacity: 0.3 }
              }
              onClick={() => {
                if (questionPage > 0) {
                  setQuestionPage(questionPage - 1);
                }
              }}
            />
            <span style={{ fontWeight: 'bolder', fontSize: '0.8em' }}>
              {`Questions ${questionPage * questionsPerPage + 1} - ${Math.min(
                maxQuestions,
                (questionPage + 1) * questionsPerPage
              )}`}
            </span>
            <FaAngleRight
              style={
                questionPage < numPages - 1
                  ? { cursor: 'pointer' }
                  : { opacity: 0.3 }
              }
              onClick={() => {
                if (questionPage < numPages - 1) {
                  setQuestionPage(questionPage + 1);
                }
              }}
            />
            {numPages > 2 && (
              <FaAngleDoubleRight
                style={
                  questionPage < numPages - 1
                    ? { cursor: 'pointer' }
                    : { opacity: 0.3 }
                }
                onClick={() => {
                  if (questionPage < numPages - 1) {
                    setQuestionPage(numPages - 1);
                  }
                }}
              />
            )}
          </div>
        )}
        <div ref={divRef} className="quiz-results-grid-container">
          <div
            className={`quiz-results-grid-row header-row`}
            style={{
              gridTemplateColumns: `${nameWidth}px repeat(2, ${
                nameWidth / 2
              }px)${
                questionColumns > 0 ? ` repeat(${questionColumns}, 30px)` : ``
              }`,
            }}
          >
            <div
              onClick={() => {
                if (sortableData) {
                  if (sortMethod === SORT_METHODS.NAME) {
                    setSortDirection(!sortDirection);
                  } else {
                    setSortMethod(SORT_METHODS.NAME);
                    setSortDirection(DEFAULT_SORT_DIRECTION[SORT_METHODS.NAME]);
                  }
                }
              }}
              className="quiz-results-grid-header"
            >
              <span>Quiz</span>
              {sortableData && sortMethod === SORT_METHODS.NAME && (
                <>
                  {sortDirection === SORT_DIRECTION.ASC ? (
                    <FaSortDown />
                  ) : (
                    <FaSortUp />
                  )}
                </>
              )}
            </div>
            <div
              onClick={() => {
                if (sortableData) {
                  if (sortMethod === SORT_METHODS.DATE) {
                    setSortDirection(!sortDirection);
                  } else {
                    setSortMethod(SORT_METHODS.DATE);
                    setSortDirection(DEFAULT_SORT_DIRECTION[SORT_METHODS.DATE]);
                  }
                }
              }}
              className="quiz-results-grid-header"
            >
              <span>Date</span>
              {sortableData && sortMethod === SORT_METHODS.DATE && (
                <>
                  {sortDirection === SORT_DIRECTION.ASC ? (
                    <FaSortUp />
                  ) : (
                    <FaSortDown />
                  )}
                </>
              )}
            </div>
            <div
              className="quiz-results-grid-header"
              onClick={() => {
                if (sortableData) {
                  if (sortMethod === SORT_METHODS.SCORE) {
                    setSortDirection(!sortDirection);
                  } else {
                    setSortMethod(SORT_METHODS.SCORE);
                    setSortDirection(
                      DEFAULT_SORT_DIRECTION[SORT_METHODS.SCORE]
                    );
                  }
                }
              }}
            >
              <span>Score</span>
              {sortableData && sortMethod === SORT_METHODS.SCORE && (
                <>
                  {sortDirection === SORT_DIRECTION.ASC ? (
                    <FaSortUp />
                  ) : (
                    <FaSortDown />
                  )}
                </>
              )}
            </div>

            {[
              ...Array(
                Math.min(
                  maxQuestions - questionPage * questionsPerPage,
                  questionsPerPage
                )
              ).keys(),
            ].map((i) => (
              <div
                key={`results-grid-header-${
                  i + questionsPerPage * questionPage + 1
                }`}
                className="quiz-results-grid-header quiz-results-question-number-header"
              >
                {i + questionsPerPage * questionPage + 1}
              </div>
            ))}
          </div>

          {(_takenExams || []).map((tE, teIdx) => (
            <div
              key={`quiz-results-grid-${teIdx}`}
              className="quiz-results-grid-row"
              ref={divRef}
              style={{
                gridTemplateColumns: `${nameWidth}px repeat(2, ${
                  nameWidth / 2
                }px)${
                  questionColumns > 0 ? ` repeat(${questionColumns}, 30px)` : ``
                }`,
              }}
              onClick={() => {
                checkAccess({
                  action: [ACCESS_ACTIONS.QUIZ_RESULTS],
                  tier,
                  type,
                  showSubscriptionModal,
                }).doWithAccess(() => {
                  showSinglePlayerResultModal({
                    quiz: tE,
                    responses: tE.questions.map((q) => q.default_order),
                  });
                });
              }}
            >
              <div>{tE.title || 'Quiz'}</div>
              <div>{tE.finished_formatted || ''}</div>
              {canViewResults ? (
                <>
                  <div>{`${(tE.score * 100).toFixed(1)}%`}</div>
                  {[
                    ...Array(
                      Math.min(
                        maxQuestions - questionPage * questionsPerPage,
                        questionsPerPage
                      )
                    ).keys(),
                  ].map((qIdx) => {
                    let idx = questionPage * questionsPerPage + qIdx;
                    if (tE?.questions?.length > idx) {
                      let q = tE.questions[idx];
                      return (
                        <Tooltip
                          key={`questions-tooltip-${qIdx}`}
                          delay={400}
                          content={
                            <>
                              {_.isObject(questionMap[q.questionId]) ? (
                                <ExamReviewQuestionDetails
                                  question={questionMap[q.questionId]}
                                  singleResponse={q.default_order}
                                />
                              ) : (
                                <div>
                                  Question not found. May have been deleted
                                </div>
                              )}
                            </>
                          }
                          direction="bottom"
                        >
                          <div style={{ textAlign: 'center', cursor: 'help' }}>
                            {q.correct ? (
                              <FaCheck color="green" />
                            ) : (
                              <FaTimes color="red" />
                            )}
                          </div>
                        </Tooltip>
                      );
                    }
                    return <div key={`questions-blank-${qIdx}`}></div>;
                  })}
                </>
              ) : (
                <div
                  className="quiz-results-grid-row-upgrade-to-view"
                  style={{
                    gridColumn: `span ${questionColumns + 1}`,
                  }}
                >
                  Upgrade to view quiz results
                </div>
              )}
            </div>
          ))}
          <div
            className="quiz-results-separator-header noprint"
            onClick={() => {
              setShowingInvited(!showingInvited);
            }}
          >
            <div style={{ visibility: 'hidden' }}>
              {showingInvited ? 'hide' : 'show'}
            </div>
            <h4 style={{}}>
              Invited quizzes not yet taken ({(invitedExams || []).length})
            </h4>
            <div>{showingInvited ? 'hide' : 'show'}</div>
          </div>
          {showingInvited && (
            <>
              <div
                ref={divRef}
                style={{
                  display: 'grid',
                  margin: 'auto',
                  gridTemplateColumns: `${nameWidth}px repeat(2, ${
                    nameWidth / 2
                  }px)${
                    questionColumns > 0
                      ? ` repeat(${questionColumns}, 30px)`
                      : ``
                  }`,
                }}
              >
                <div>Quiz</div>
                <div>Invited</div>
                <div>Re-send?</div>
                <div
                  style={{
                    gridColumn: `span ${Math.min(
                      maxQuestions - questionPage * questionsPerPage,
                      questionsPerPage
                    )}`,
                  }}
                ></div>
              </div>
              {(invitedExams || []).map((iP, ipIdx) => (
                <div
                  key={`quiz-results-invited-${ipIdx}`}
                  className="quiz-results-grid-row"
                  ref={divRef}
                  style={{
                    gridTemplateColumns: `${nameWidth}px repeat(2, ${
                      nameWidth / 2
                    }px)${
                      questionColumns > 0
                        ? ` repeat(${questionColumns}, 30px)`
                        : ``
                    }`,
                  }}
                  onClick={() => {
                    console.log(`clicked row ${iP.id}`);
                  }}
                >
                  <div style={{}}>{iP.title || 'Quiz'}</div>
                  <div style={{}}>{iP.created_formatted || ''}</div>
                  <div style={{}}>
                    <CTooltip
                      content={`Re-send invite${
                        _.isString(playerEmail) && playerEmail.length > 0
                          ? ` (${playerEmail})`
                          : ''
                      }`}
                    >
                      <CLink
                        onClick={(e) => {
                          invitePlayersToQuiz({
                            organization_id: iP.organization_id,
                            team_id: iP.team_id,
                            quiz_id: iP.quiz_id,
                            exam_id: iP.exam_id,
                            user_ids: [iP.user_id],
                          });
                          e.stopPropagation();
                        }}
                      >
                        <FaEnvelopeOpenText />
                      </CLink>
                    </CTooltip>
                  </div>

                  <div
                    style={{
                      gridColumn: `span ${
                        Math.min(
                          maxQuestions - questionPage * questionsPerPage,
                          questionsPerPage
                        ) - 1
                      }`,
                      color: '#ddd',
                      fontStyle: 'italic',
                      fontWeight: 100,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {`Invited ${iP.numInvites || '1'} time${
                      !isNaN(iP.numInvites) && iP.numInvites !== 1 ? 's' : ''
                    }`}
                  </div>
                  <div className="show-on-hover">
                    <CTooltip content={`Un-invite to ${iP.title || 'quiz'}`}>
                      <CLink
                        onClick={async (e) => {
                          console.log('here');
                          try {
                            toast(
                              'Invite deleted. It may take a minute to remove from view.',
                              { type: 'success' }
                            );
                            const deleteQuizInviteCallable =
                              functions.httpsCallable(
                                'deleteQuizInviteCallable'
                              );
                            let result = await deleteQuizInviteCallable({
                              user_id: iP.user_id,
                              team_id: iP.team_id,
                              quiz_id: iP.quiz_id,
                            });
                          } catch (error) {
                            handleError({
                              error,
                              toast: 'Error deleting invite',
                              internal: 'PlayerExamResults - deleting invite',
                            });
                          }
                          e.stopPropagation();
                        }}
                      >
                        <FaTrashAlt color="red" />
                      </CLink>
                    </CTooltip>
                  </div>
                  {/* {iP.questions.map((q) => (
            <div>
              {q.correct ? <FaCheck color="green" /> : <FaTimes color="red" />}
            </div>
          ))} */}
                </div>
              ))}
            </>
          )}

          <div
            className={`quiz-results-separator-header noprint`}
            onClick={() => {
              setShowingUninvited(!showingUninvited);
            }}
          >
            <div style={{ visibility: 'hidden' }}>
              {showingInvited ? 'hide' : 'show'}
            </div>
            <h4 style={{}}>
              Quizzes you haven't yet invited {playerName || 'player'} to take (
              {(uninvitedExams || []).length})
            </h4>
            <div>{showingInvited ? 'hide' : 'show'}</div>
          </div>
          {showingUninvited && (
            <>
              {(uninvitedExams || []).map((uP, upIdx) => (
                <div
                  key={`uninvited-exams-${upIdx}`}
                  className="quiz-results-grid-row"
                  ref={divRef}
                  style={{
                    gridTemplateColumns: `${nameWidth}px repeat(2, ${
                      nameWidth / 2
                    }px)${
                      questionColumns > 0
                        ? ` repeat(${questionColumns}, 30px)`
                        : ``
                    }`,
                  }}
                >
                  <div style={{ gridColumn: 'span 2' }}>
                    {uP.title || 'Quiz'}
                  </div>
                  <div
                    style={{
                      gridColumn: `span ${
                        Math.min(
                          maxQuestions - questionPage * questionsPerPage,
                          questionsPerPage
                        ) + 1
                      }`,
                    }}
                  >
                    <CTooltip
                      content={`Invite${
                        _.isString(playerEmail) && playerEmail.length > 0
                          ? ` (${playerEmail})`
                          : ''
                      }`}
                    >
                      <CLink
                        onClick={() => {
                          console.log(uP);
                          invitePlayersToQuiz({
                            ..._.pick(uP, [
                              'organization_id',
                              'team_id',
                              'quiz_id',
                              'exam_id',
                            ]),
                            ...{ user_ids: [uP.user_id] },
                          });
                        }}
                      >
                        <FaEnvelopeOpenText />
                      </CLink>
                    </CTooltip>
                  </div>

                  {/* {iP.questions.map((q) => (
            <div>
              {q.correct ? <FaCheck color="green" /> : <FaTimes color="red" />}
            </div>
          ))} */}
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlayerExamResults;
