import _ from 'lodash';

export const POSITIONS = [
  { value: 'C', label: 'C' },
  { value: 'P', label: 'P' },
  { value: '1B', label: '1B' },
  { value: '2B', label: '2B' },
  { value: 'SS', label: 'SS' },
  { value: '3B', label: '3B' },
  { value: 'LF', label: 'LF' },
  { value: 'CF', label: 'CF' },
  { value: 'RF', label: 'RF' },
  { value: 'IF', label: 'IF' },
  { value: 'OF', label: 'OF' },
  { value: 'ALL', label: 'ALL' },
  { value: 'B1', label: 'B1' },
  { value: 'B2', label: 'B2' },
  { value: 'B3', label: 'B3' },
  { value: 'H', label: 'H' },
];

const mapPositionToPOSITIONS = (position) => {
  switch (parseInt(position)) {
    case 1:
      return 1;
    case 2:
      return 0;
    case 5:
      return 5;
    case 6:
      return 4;
    case 21:
      return 12;
    case 22:
      return 13;
    case 23:
      return 14;
    case 30:
      return 15;
    default:
      return position - 1;
  }
};

export const positionValueAndLabelAndPositionForPosition = (position) => {
  return { ...{ position }, ...POSITIONS[mapPositionToPOSITIONS(position)] };
};

export const positionHighlightsAndLabelForPositions = (positions) => {
  let positionHighlights = [];
  let label = '';
  if (!(_.isArray(positions) && positions.length > 0)) {
    return { positionHighlights, label };
  }
  if (_.isString(positions[0])) {
    positionHighlights = positions.map((p) =>
      positionNumberFromLabelOrValue(p)
    );
  } else if (_.isObject(positions[0]) && _.isString(positions[0].value)) {
    positionHighlights = positions.map((p) =>
      positionNumberFromLabelOrValue(p.value)
    );
  } else if (_.isObject(positions[0]) && _.isString(positions[0].label)) {
    positionHighlights = positions.map((p) =>
      positionNumberFromLabelOrValue(p.label)
    );
  }
  if (
    positionHighlights.length === 1 &&
    (isPositionInField(positionHighlights[0]) ||
      isPositionOnBases(positionHighlights[0]) ||
      isPositionHitting(positionHighlights[0]))
  ) {
    label = `You are ${positionLabelFromPositionNumber(positionHighlights[0])}`;
  }
  return { positionHighlights, label };
};

const isPositionInField = (positionNumber) => {
  return positionNumber > 0 && positionNumber <= 10;
};
const isPositionOnBases = (positionNumber) => {
  return positionNumber > 20 && positionNumber <= 23;
};
const isPositionHitting = (positionNumber) => {
  return positionNumber === 30;
};

export const positionLabelFromPositionNumber = (positionNumber) => {
  switch (positionNumber) {
    case 1:
      return 'P';
    case 2:
      return 'C';
    case 3:
      return '1B';
    case 4:
      return '2B';
    case 5:
      return '3B';
    case 6:
      return 'SS';
    case 7:
      return 'LF';
    case 8:
      return 'CF';
    case 9:
      return 'RF';
    case 20:
      return 'a Baserunning';
    case 21:
      return 'on 1st';
    case 22:
      return 'on 2nd';
    case 23:
      return 'on 3rd';
    case 30:
      return 'Batting';
    default:
      break;
  }
};

export const positionNumberFromLabelOrValue = (value) => {
  console.log((value || '').toString().toLowerCase());
  switch ((value || '').toString().toLowerCase()) {
    case 'p':
    case 'pitcher':
    case '1':
      return 1;
    case 'c':
    case 'catcher':
    case '2':
      return 2;
    case '1b':
    case 'first':
    case 'first base':
    case 'first baseman':
    case '3':
      return 3;
    case '2b':
    case 'second':
    case 'second base':
    case 'second baseman':
    case '4':
      return 4;
    case 'ss':
    case 'short':
    case 'shortstop':
    case '6':
      return 6;
    case '3b':
    case 'third':
    case 'third base':
    case 'third baseman':
    case '5':
      return 5;
    case 'lf':
    case 'left':
    case 'left field':
    case '7':
      return 7;
    case 'cf':
    case 'center':
    case 'center field':
    case '8':
      return 8;
    case 'rf':
    case 'right':
    case 'right field':
    case '9':
      return 9;
    case 'b':
      return 20;
    case 'b1':
      return 21;
    case 'b2':
      return 22;
    case 'b3':
      return 23;
    case 'h':
      return 30;
    default:
      break;
  }
  return 0;
};
