import React, { useEffect, useRef, useState } from 'react';
import _, { isString } from 'lodash';
import QuestionCategories from '../../r-views/pages/questionsList/QuestionCategories';
import QuestionWrapper from '../component/QuestionWrapper';

const SuperAdminQuestionCounts = ({ data }) => {
  return (
    <QuestionWrapper>
      <QuestionCategories
        style={{
          paddingBottom: 150,
        }}
        hideCheckBoxes={true}
        showCounts={true}
        preExpandedSections={{ categories: true }}
        handleSelectedCategories={(data) => {
          console.log(data);
        }}
      />
    </QuestionWrapper>
  );
};

export default SuperAdminQuestionCounts;
