import {
  ADD_NEW_PLAYER,
  CLEAR_PLAYERS,
  EDIT_PLAYER,
  PLAYERS,
  PLAYER_DETAILS,
} from '../reducers/constants';

export const viewPlayers = () => {
  return {
    type: PLAYERS,
  };
};

export const addPlayers = () => {
  return {
    type: ADD_NEW_PLAYER,
  };
};

export const editPlayer = (userId) => {
  return {
    type: EDIT_PLAYER,
    payload: userId,
  };
};

export const viewPlayerDetails = (userId) => {
  return {
    type: PLAYER_DETAILS,
    payload: userId,
  };
};

export const clearPlayers = () => {
  return {
    type: CLEAR_PLAYERS,
  };
};
