import React, { useEffect, useState } from 'react';
import {
  CDataTable,
  CButton,
  CLink,
  CSelect,
  CLabel,
  CRow,
} from '@coreui/react';
import { map } from 'lodash';
import { SUBSCRIPTION_TIER } from '../../config';
import Container from '../../public-components/Container/Container';
import { useDispatch } from 'react-redux';
import {
  clearImpersonateTier,
  impersonateTier,
  impersonateUserID,
} from '../../r-store/impersonate/actions';
import { db } from '../..';
import { FaUserSecret } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { TABS } from '../../AppTabs';
const downloadTemplateText = 'Download template';
import { Timestamp } from 'firebase/firestore';
import { format, setMinutes } from 'date-fns';

function SuperAdminDataQuestionCount() {
  const [questions, setQuestions] = useState([]);
  const [questionsPastWeek, setQuestionsPastWeek] = useState(0);
  const [questionsPastMonth, setQuestionsPastMonth] = useState(0);
  const [loadingQuestions, setLoadingQuestions] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
  oneMonthAgo.setHours(0);
  oneMonthAgo.setMinutes(0);
  oneMonthAgo.setSeconds(0);
  const oneMonthAgoTimestamp = Timestamp.fromDate(oneMonthAgo);
  const now = new Date();

  // Subtract 7 days (7 days * 24 hours * 60 minutes * 60 seconds * 1000 ms)
  const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
  oneWeekAgo.setHours(0);
  oneWeekAgo.setMinutes(0);
  oneWeekAgo.setSeconds(0);

  // Convert to Firestore Timestamp
  const oneWeekAgoTimestamp = Timestamp.fromDate(oneWeekAgo);
  // const oneWeekAgo = new Date();
  console.log(oneMonthAgoTimestamp);
  console.log(oneWeekAgoTimestamp);
  useEffect(() => {
    const unsubscribe = db
      .collection('questions')
      .where('owningOrganization', 'in', [
        'all',
        'e07ac4d9-9d2a-44a5-900b-10de0b25ca81',
      ])
      .where('created_at', '>=', oneMonthAgoTimestamp)
      .onSnapshot(
        (snapshot) => {
          const questions = snapshot.docs.map((doc) => ({
            id: doc.id,
            createdFormatted: format(
              new Date(
                doc.data()?.created_at?.seconds * 1000 || doc.data()?.created_at
              ),
              'MMM d'
            ),
            timestamp: doc.data().created_at?.seconds || 0,
            ...doc.data(),
          }));
          console.log(
            questions.filter((q) => q.created_at >= oneMonthAgoTimestamp)
          );
          setQuestions(questions);
          setQuestionsPastWeek(
            questions.filter((q) => q.created_at >= oneWeekAgoTimestamp).length
          );
          setQuestionsPastMonth(questions.length);
          setLoadingQuestions(false);
        },
        (error) => {
          console.error('Error fetching questions: ', error);
          setLoadingQuestions(false);
        }
      );

    return () => unsubscribe();
  }, []);

  return (
    <div className="super-admin-impersonate-wrapper">
      <Container>
        <CLabel>New Questions in Past 7 Days:</CLabel>

        <CLabel> {questionsPastWeek}</CLabel>
        <br />
        <CLabel>New Questions in Past Month:</CLabel>

        <CLabel> {questionsPastMonth}</CLabel>
        <br />

        <CLabel style={{ marginTop: 60 }}>Questions:</CLabel>
        <CDataTable
          items={questions}
          header={false}
          sorterValue={{ column: 'timestamp', asc: false }}
          fields={[
            { key: 'timestamp', label: 'Time' },
            { key: 'createdFormatted', label: 'Created' },
            { key: 'text', label: 'Question' },
          ]}
          scopedSlots={{
            timestamp: (coach) => <td style={{ display: 'none' }}></td>,
          }}
        />
      </Container>
    </div>
  );
}

export default SuperAdminDataQuestionCount;
