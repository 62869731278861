import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CImg,
  CRow,
  CSpinner,
  CTooltip,
} from '@coreui/react';
import './quizzes.scss';
import { format } from 'date-fns';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import QuizUserResponse from './QuizUserResponse';
import QuizWrapper from '../../../pages/component/QuizWrapper';
import { TABS } from '../../../AppTabs';
import {
  FaCheckSquare,
  FaEnvelopeOpenText,
  FaMobile,
  FaRegClock,
} from 'react-icons/fa';
import { orgAndTeamData } from '../../../helpers/data';
import OrgTeamImageHeader from '../../../r-components/OrgTeamImageHeader/OrgTeamImageHeader';
import TeamCellInDataTable from '../../../r-components/TeamCellInDataTable/TeamCellInDataTable';
import AutoQuizButtons from '../autoQuizButtons/AutoQuizButtons';
import { ACCESS_ACTIONS, checkAccess } from '../../../helpers/access';
import useAccountSelectors from '../../../hooks/useAccountSelectors';

// With implementation of 6tool, UI presents exams as quizzes. The data model contains both exams and quizzes
// having migrated over from Gamer-Metrics
const QuizzesListPage = () => {
  const history = useHistory();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, '');
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const orgData = useSelector((state) => state.DataOrganizationState);
  const teamData = useSelector((state) => state.DataTeamState);
  const quizzesRaw = useSelector((state) => state.DataQuizState?.data || []);
  const [quizzes, setQuizzes] = useState([]);
  const [orgTeamData, setOrgTeamData] = useState({});
  const [tableFields, setTableFields] = useState([]);
  const { type, tier, showSubscriptionModal, goToUrl } = useAccountSelectors();

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(currentPage);

  useEffect(() => {
    currentPage !== page && setPage(currentPage);
  }, [currentPage, page]);

  useEffect(() => {
    if (_.isArray(quizzesRaw) && quizzesRaw.length > 0) {
      let newQuizzes = quizzesRaw
        .map((q) => {
          return {
            ...q,
            ...{
              createdFormatted: format(
                new Date(q?.created_at?.seconds * 1000 || q?.created_at),
                'MMM d'
              ),
              _created: q?.created_at?.seconds || q?.created_at || 0,
            },
          };
        })
        .sort((a, b) => b._created - a._created);
      setQuizzes(newQuizzes);
    } else {
      setQuizzes([]);
    }
  }, [quizzesRaw]);

  useEffect(() => {
    let orgTeam = orgAndTeamData({ orgData, teamData });
    setOrgTeamData(orgTeam);
    let tableFields = [];
    if (orgTeam.hasTeam !== true) {
      tableFields.push({ key: 'team', label: 'Team(s)' });
    }
    tableFields = tableFields.concat([
      {
        key: 'title',
        _classes: 'font-weight-bold',
        sortable: true,
        sortIcon: 'fa fa-sort',
      },
      {
        key: 'createdFormatted',
        label: 'Created',
        sortable: true,
        sortIcon: 'fa fa-sort',
      },
      {
        key: 'average',
        label: 'Users response',
        sortable: true,
        sortIcon: 'fa fa-sort',
      },
      { key: 'show_details', label: '' },
    ]);
    setTableFields(tableFields);
  }, [orgData, teamData]);

  return (
    <QuizWrapper>
      <AutoQuizButtons />
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader className="d-flex align-items-center justify-content-between">
              <OrgTeamImageHeader
                title="Quizzes"
                rightView={
                  <>
                    <Link className=" mr-5" to={TABS.COACH_QUIZ_SCHEDULED.path}>
                      <FaRegClock style={{ marginTop: -5, marginRight: 5 }} />
                      Scheduled Quizzes
                    </Link>
                    <Link className=" mr-5" to={TABS.COACH_QUIZ_INVITES.path}>
                      <FaEnvelopeOpenText
                        style={{ marginTop: -5, marginRight: 5 }}
                      />
                      Quiz Invites
                    </Link>
                    <Link className=" mr-5" to={TABS.COACH_QUIZ_TAKENS.path}>
                      <FaMobile style={{ marginTop: -5, marginRight: 5 }} />
                      Quizzes Taken
                    </Link>
                    <CButton
                      color="primary"
                      className="d-flex justify-content-center align-items-center"
                      onClick={() => {
                        checkAccess({
                          action: ACCESS_ACTIONS.VIEW_6TOOL_QUESTIONS,
                          tier,
                          type,
                          showSubscriptionModal,
                          goToUrl,
                        }).navigateWithAccess(TABS.COACH_QUIZZES_CREATE.path);
                      }}
                    >
                      <span className="m-0 mr-2 h3">+</span> Create a Quiz
                    </CButton>
                  </>
                }
              />
            </CCardHeader>

            <CCardBody>
              {!loading ? (
                <CDataTable
                  addTableClasses="quiz-table"
                  items={quizzes}
                  tableFilter
                  itemsPerPageSelect
                  itemsPerPage={50}
                  hover
                  pagination
                  onRowClick={(item) => {
                    history.push(
                      TABS.COACH_QUIZZES_DETAILS.pathVar({
                        name: item.title,
                        id: item.id,
                      })
                    );
                  }}
                  fields={tableFields}
                  noItemsViewSlot={
                    <p className="d-flex align-content-center justify-content-center pt-4 pb-4">
                      <FiAlertCircle size="30" color="#e55353" />
                      <span className="pl-2 font-xl">No quizzes added.</span>
                    </p>
                  }
                  scopedSlots={{
                    created_at: (item) => <td>{item?.createdFormatted}</td>,
                    team: (item) => (
                      <TeamCellInDataTable item={item} teamData={teamData} />
                    ),
                    average: (item) => (
                      <td>
                        <QuizUserResponse
                          quizId={item.id}
                          examId={item.examId}
                          average={item.average}
                          numTaken={item.num_taken}
                        />
                      </td>
                    ),
                    // responses: (item) => (
                    //   <td>
                    //     <ExamUserResponse
                    //       examId={item.id}
                    //       average={item.average}
                    //     />
                    //   </td>
                    // ),
                    show_details: (item) => (
                      <td className="py-2 text-right quiz-details">
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <CTooltip
                            content={
                              item.organizationId === 'all'
                                ? '6Tool made this quiz'
                                : 'You made this quiz'
                            }
                            placement="top"
                          >
                            <CImg
                              className="quiz-owner-icon"
                              src={`/img/${
                                item.organizationId === 'all'
                                  ? 'light-bulb-icon'
                                  : 'user'
                              }.png`}
                              fluid
                              style={{
                                marginLeft: 10,
                                height: 44,
                                objectFit: 'contain',
                                flex: 0,
                                padding: '0.625rem 1.125rem',
                              }}
                            />
                          </CTooltip>
                        </div>
                      </td>
                    ),
                  }}
                />
              ) : (
                <CCol xl={12} className="text-center pt-2 pb-2">
                  <CSpinner variant="grow" size="lg" />
                </CCol>
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </QuizWrapper>
  );
};

export default QuizzesListPage;
