import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import TourView, { showTour } from '../../r-components/Tours/TourView';
import _ from 'lodash';
import Breadcrumbs from './Breadcrumbs';
const CoachWrapper = ({ userData, children }) => {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   showTour(userData, 'coaches', dispatch);
  // }, [userData]);
  return (
    <>
      <Breadcrumbs />
      <div className="container-fluid space-1">
        <div className="row">
          <div className="col">
            <div className="pr-lg-4">{children}</div>
          </div>
        </div>
      </div>
      <TourView name="coaches" />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.UserState,
  };
};

const mapDispatchToProps = {};

CoachWrapper.propTypes = {
  quizData: PropTypes.shape({
    quizzes: PropTypes.bool,
    addQuizzes: PropTypes.bool,
    quizDetails: PropTypes.bool,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(CoachWrapper);
