import {
  FaCheckSquare,
  FaRegSquare,
  FaCaretLeft,
  FaCaretDown,
  FaMinusSquare,
} from 'react-icons/fa';
import './questionCategories.scss';
import { CContainer, CLabel, CRow, CLink } from '@coreui/react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { questionCategories as getQuestionCategories } from '../../../helpers/data';
import _ from 'lodash';
import FlexChildLoadingOverlay from '../../../r-components/FlexChildLoadingOverlay/FlexChildLoadingOverlay';
import { Accordion } from 'react-bootstrap';
import {
  filterFunctionForPlayerTypeLabel,
  filterFunctionForQuestionTypeLabel,
} from '../../../r-reusable/Categories';

const QuestionCategories = ({
  handleSelectedCategories,
  preExpandedSections,
  preSelectedCategories,
  hiddenSections,
  showCounts = false,
  hideCheckBoxes = false,
  ...rest
}) => {
  const isMounted = useRef(null);
  const [filter, setFilter] = useState({});
  const [questions, setQuestions] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [questionCategories, setQuestionCategories] = useState([]);
  const [playerTypes, setPlayerTypes] = useState([]);
  const [questionTypes, setQuestionTypes] = useState([]);
  const [questionTree, setQuestionTree] = useState({});
  const [playerTypeTree, setPlayerTypeTree] = useState({});
  const [questionTypeLabelFlat, setQuestionTypeLabelFlat] = useState({});
  const [playerTypeLabelFlat, setPlayerTypeLabelFlat] = useState({});
  const [questionTypeTree, setQuestionTypeTree] = useState({});
  const [selectedCategories, setSelectedCategories] = useState({
    categories: [],
    playerTypes: [],
    questionTypes: [],
  });
  const [pseudoSelectedCategories, setPseudoSelectedCategories] = useState({
    categories: {},
    playerTypes: {},
    questionTypes: {},
  });
  const [showCategories, setShowCategories] = useState(
    preExpandedSections?.categories === true
  );
  const [showPlayerTypes, setShowPlayerTypes] = useState(
    preExpandedSections?.playerTypes === true
  );
  const [showQuestionTypes, setShowQuestionTypes] = useState(
    preExpandedSections?.questionTypes === true
  );
  const [questionScope, setQuestionScope] = useState('all');
  const user = useSelector((state) => state?.UserState);
  const categories = useSelector(
    (state) => state?.DataQuestionState?.categories || {}
  );
  const categoryData = showCounts
    ? useSelector((state) => state?.DataQuestionState?.data)
    : [];
  const [categoryCountMap, setCategoryCountMap] = useState({});
  const [playerTypeCountMap, setPlayerTypeCountMap] = useState({});
  const [questionTypeCountMap, setQuestionTypeCountMap] = useState({});

  useEffect(() => {
    setQuestionCategories(categories?.categories || []);
    setPlayerTypes(categories?.playerTypes || []);
    setQuestionTypes(categories?.questionTypes || []);
    setQuestionTree(categories?.trees?.categories || {});
    setPlayerTypeTree(categories?.trees?.playerTypes || {});
    setQuestionTypeTree(categories?.trees?.questionTypes || {});
    setPlayerTypeLabelFlat(categories?.flatLabels?.playerTypes || {});
    setQuestionTypeLabelFlat(categories?.flatLabels?.questionTypes || {});
    setIsLoadingCategories(false);
  }, [categories]);

  useEffect(() => {
    if (showCounts === true) {
      let categoryArray = Object.keys(categories?.trees?.categories || {});
      let categoryMap = categoryArray.reduce((acc, c) => {
        acc[c] = (
          categoryData.filter((d) => (d.categories || []).includes(c)) || []
        ).length;
        return acc;
      }, {});
      let playerTypeArray = Object.values(
        categories?.flatLabels?.playerTypes || []
      );
      let playerMap = playerTypeArray.reduce((acc, label) => {
        acc[label] = categoryData.filter((question) => {
          return filterFunctionForPlayerTypeLabel({ label, question });
        }).length;
        return acc;
      }, {});
      let questionTypeArray = Object.values(
        categories?.flatLabels?.questionTypes || []
      );
      let questionMap = questionTypeArray.reduce((acc, label) => {
        acc[label] = categoryData.filter((question) => {
          return filterFunctionForQuestionTypeLabel({ label, question });
        }).length;
        return acc;
      }, {});
      setPlayerTypeCountMap(playerMap);
      setCategoryCountMap(categoryMap);
      setQuestionTypeCountMap(questionMap);
    }
  }, [categories, showCounts, categoryData]);

  useEffect(() => {
    if (_.isArray(preSelectedCategories) && preSelectedCategories.length > 0) {
      let sC = {
        ...selectedCategories,
        ...{
          categories: _.union(
            preSelectedCategories,
            selectedCategories.categories
          ),
        },
      };
      setSelectedCategories(sC);
    }
  }, [preSelectedCategories]);

  useEffect(() => {
    refreshPartiallySelectedCategories({ type: 'all' });
  }, [selectedCategories]);

  const categoryArrayForType = ({ type, tree }) => {
    if (tree === true) {
      switch (type) {
        case 'categories':
        default:
          return questionTree;
        case 'playerTypes':
          return playerTypeTree;
        case 'questionTypes':
          return questionTypeTree;
      }
    }
    switch (type) {
      case 'categories':
      default:
        return questionCategories;
      case 'playerTypes':
        return playerTypes;
      case 'questionTypes':
        return questionTypes;
    }
  };

  const getCategoryLabelById = ({ type = 'category', _id } = {}) => {
    if (type === 'playerTypes') {
      return playerTypeLabelFlat[_id];
    }
    if (type === 'questionTypes') {
      return questionTypeLabelFlat[_id];
    }
    return null;
  };

  const partiallySelectedCategoryForType = ({ type }) => {
    if (
      !(
        _.isObject(selectedCategories) &&
        _.isArray(selectedCategories[type]) &&
        selectedCategories[type].length > 0
      )
    ) {
      return {};
    }
    let categoriesTree = categoryArrayForType({ type, tree: true }) || {};
    let partialSelectedTree = {};
    for (const [categoryId, value] of Object.entries(categoriesTree)) {
      const isSel =
        selectedCategories[type].includes(categoryId) ||
        pseudoSelectedCategories[type][categoryId] === 'full';
      if (_.isArray(value) && value.length > 0) {
        value.forEach((c) => {
          if (c !== categoryId) {
            if (!_.isObject(partialSelectedTree[c])) {
              partialSelectedTree[c] = {};
            }
            partialSelectedTree[c].total =
              (partialSelectedTree[c]?.total || 0) + 1;
            if (isSel) {
              partialSelectedTree[c].selected =
                (partialSelectedTree[c]?.selected || 0) + 1;
            }
          }
        });
      }
    }
    let newPartialSelected = {};
    for (const [k, v] of Object.entries(partialSelectedTree)) {
      if (!!v.selected && !!v.total && v.selected > 0 && v.selected < v.total) {
        newPartialSelected[k] = 'partial';
      } else if (!!v.selected && !!v.total && v.selected >= v.total) {
        newPartialSelected[k] = 'full';
      }
    }
    return { ...newPartialSelected };
  };

  const refreshPartiallySelectedCategories = ({ type }) => {
    let newPseudoSelected = { ...pseudoSelectedCategories };
    if (type === 'all') {
      ['categories', 'questionTypes', 'playerTypes'].forEach((section) => {
        newPseudoSelected[section] = partiallySelectedCategoryForType({
          type: section,
        });
      });
    } else {
      newPseudoSelected[type] = partiallySelectedCategoryForType({ type });
    }
    setPseudoSelectedCategories(newPseudoSelected);
  };

  const categoryNodeForTypeAndIdPath = ({ type, idPathArray }) => {
    let categories = categoryArrayForType({ type, tree: false });
    if (!(!!idPathArray && _.isArray(idPathArray) && idPathArray.length > 0)) {
      return categories;
    }
    return categoryNodeForTreeAndIdPath({ tree: categories, idPathArray });
  };

  const categoryNodeForTreeAndIdPath = ({ tree, idPathArray }) => {
    if (!(!!idPathArray && _.isArray(idPathArray) && idPathArray.length > 0)) {
      return tree;
    }
    const cN = _.filter(tree, { id: _.first(idPathArray) });
    if (!!cN && _.isArray(cN) && cN.length > 0) {
      if (idPathArray.length === 1 && idPathArray[0] === cN[0].id) {
        return cN[0];
      }
      return categoryNodeForTreeAndIdPath({
        tree: cN[0].children,
        idPathArray: _.tail(idPathArray),
      });
    }
    return null;
  };

  const mapSelectedCategoriesToValuesAndLabels = (sC) => {
    if (_.isArray(sC.questionTypes) && sC.questionTypes.length > 0) {
      let newQuestionTypes = [];
      sC.questionTypes.forEach((qT) => {
        let label = getCategoryLabelById({ type: 'questionTypes', _id: qT });
        if (_.isString(label) && label.length > 0) {
          newQuestionTypes.push(label);
        }
      });
      sC.questionTypeLabels = [...newQuestionTypes];
    } else {
      sC.questionTypeLabels = [];
    }
    if (_.isArray(sC.playerTypes) && sC.playerTypes.length > 0) {
      let newPlayerTypes = [];
      sC.playerTypes.forEach((pT) => {
        let label = getCategoryLabelById({ type: 'playerTypes', _id: pT });
        if (_.isString(label) && label.length > 0) {
          newPlayerTypes.push(label);
        }
      });
      sC.playerTypeLabels = [...newPlayerTypes];
    } else {
      sC.playerTypeLabels = [];
    }
    return sC;
  };

  const clickCategory = ({ id, type }) => {
    let update = {};
    let preselected = selectedCategories[type].includes(id);
    if (!preselected) {
      preselected = pseudoSelectedCategories[type][id] === 'full';
    }
    if (preselected) {
      const index = selectedCategories[type].indexOf(id);
      let sC = { ...selectedCategories };
      let newCategoriesDict = { ...selectedCategories };
      let newCategories = newCategoriesDict[type];
      if (index > -1) {
        newCategories.splice(index, 1);
      }
      let categoriesTree = categoryArrayForType({ type, tree: true }) || {};
      for (const [k, v] of Object.entries(categoriesTree)) {
        if (v.includes(id)) {
          let childIndex = newCategories.indexOf(k);
          if (childIndex > -1) {
            newCategories.splice(childIndex, 1);
          }
        }
      }
      update[type] = newCategories;
      sC = {
        ...sC,
        ...update,
      };
      sC = mapSelectedCategoriesToValuesAndLabels(sC);
      setSelectedCategories(sC);
      handleSelectedCategories(sC);
    } else {
      let newCategories = [...selectedCategories[type], id];
      let categoriesTree = categoryArrayForType({ type, tree: true })[id] || [];
      let node = categoryNodeForTypeAndIdPath({
        type,
        idPathArray: categoriesTree,
      });
      newCategories = selectAllDescendents({
        node,
        type,
        selectedArray: newCategories,
      });

      update[type] = newCategories;
      let sC = {
        ...selectedCategories,
        ...update,
      };
      sC = mapSelectedCategoriesToValuesAndLabels(sC);
      setSelectedCategories(sC);
      handleSelectedCategories(sC);
    }
  };

  const selectAllDescendents = ({ node, selectedArray }) => {
    let newCategories = _.union([...selectedArray], [node.id]);
    if (
      !!node &&
      !!node.children &&
      _.isArray(node.children) &&
      node.children.length > 0
    ) {
      node.children.forEach((child) => {
        if (!!child.id && _.isString(child.id) && child.id.length > 0) {
          newCategories = _.union(newCategories, [node.id]);
        }
        newCategories = _.union(
          newCategories,
          selectAllDescendents({ node: child, selectedArray: newCategories })
        );
      });
    }
    return newCategories;
  };

  const renderCategoriesAtLevel = (categories, level, selected, type) =>
    categories.map((category, index) => {
      if (category.disabled === true) {
        return null;
      }
      let children = category.children;
      let isSelected = selectedCategories[type].includes(category.id);
      let pS = pseudoSelectedCategories[type][category.id];
      let pSF = pS === 'full';
      let pSP = pS === 'partial';
      let countMap = showCounts
        ? type === 'playerTypes'
          ? playerTypeCountMap
          : type === 'questionTypes'
          ? questionTypeCountMap
          : categoryCountMap
        : {};

      return (
        <CContainer key={`container-${category.label}-${level}-${index}`}>
          <CRow
            style={{
              cursor: category.selectable !== false ? 'pointer' : 'default',
            }}
            className={`ml-${(level + 1) * 1}`}
            key={`row-${category.label}-${level}-${index}`}
          >
            {category.selectable !== false && hideCheckBoxes !== true && (
              <>
                {(isSelected || pSF) && (
                  <FaCheckSquare
                    key={`check-${category.label}-${level}-${index}`}
                    onClick={() => {
                      clickCategory({ id: category.id, type });
                    }}
                  />
                )}
                {!isSelected && pSP && (
                  <FaMinusSquare
                    key={`check-${category.label}-${level}-${index}`}
                    onClick={() => {
                      clickCategory({ id: category.id, type });
                    }}
                  />
                )}
                {!isSelected && !pSF && !pSP && (
                  <FaRegSquare
                    key={`square-${category.label}-${level}-${index}`}
                    onClick={() => {
                      clickCategory({ id: category.id, type });
                    }}
                  />
                )}
              </>
            )}

            <CLabel
              style={{
                cursor: category.selectable !== false ? 'pointer' : 'default',
              }}
              className="ml-1"
              key={`label-${category.label}-${level}-${index}`}
              onClick={() => {
                clickCategory({ id: category.id, type });
              }}
            >
              {category.label}
              {showCounts === true
                ? ` (${
                    type === 'playerTypes' || type === 'questionTypes'
                      ? countMap?.[category.label] || 0
                      : countMap?.[category.id] || 0
                  })`
                : ''}
            </CLabel>
            {_.isArray(children) &&
              children.length > 0 &&
              renderCategoriesAtLevel(
                children,
                level + 1,
                selectedCategories,
                type
              )}
          </CRow>
        </CContainer>
      );
    });

  const topLevelStyle = {
    fontSize: '1em',
    fontWeight: 700,
    cursor: 'pointer',
  };

  const renderLabelCount = (label) => {
    let selectedArray = [];
    switch (label) {
      case 'Categories':
        selectedArray = selectedCategories?.categories;
        break;
      case 'Player Types':
        selectedArray = selectedCategories?.playerTypes;
        break;
      case 'Question Types':
        selectedArray = selectedCategories?.questionTypes;
        break;
      default:
        break;
    }
    if (_.isArray(selectedArray) && selectedArray.length > 0) {
      return ` (${selectedArray.length})`;
    }
  };

  const renderClearFilters = (label) => {
    let selectedArray = [];
    let update = {};

    switch (label) {
      case 'Categories':
        selectedArray = selectedCategories?.categories;
        update.categories = [];
        break;
      case 'Player Types':
        selectedArray = selectedCategories?.playerTypes;
        update.playerTypes = [];
        update.playerTypeLabels = [];
        break;
      case 'Question Types':
        selectedArray = selectedCategories?.questionTypes;
        update.questionTypes = [];
        update.questionTypeLabels = [];
        break;
      default:
        break;
    }
    if (_.isArray(selectedArray) && selectedArray.length > 0) {
      return (
        <CLink
          style={{ fontSize: 14, marginLeft: 10 }}
          onClick={() => {
            setSelectedCategories({
              ...selectedCategories,
              ...update,
            });
            handleSelectedCategories({
              ...selectedCategories,
              ...update,
            });
          }}
        >
          clear
        </CLink>
      );
    }
  };
  const renderLabelAndCaret = (label, showBool, handleToggle) => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <CLabel
        style={topLevelStyle}
        // onClick={() => {
        //   handleToggle(!showBool);
        // }}
      >
        {label}
        {renderLabelCount(label)}
      </CLabel>
      {renderClearFilters(label)}
      <div
        style={{ flex: 1, height: '100%' }}
        onClick={() => {
          handleToggle(!showBool);
        }}
      ></div>
    </div>
  );

  return (
    <div className="question-categories-section">
      <>
        <Accordion defaultActiveKey="0">
          {!(hiddenSections || []).includes('categories') && (
            <>
              <Accordion.Toggle
                eventKey="0"
                style={{
                  top: 0,
                  bottom: 80,
                }}
              >
                {renderLabelAndCaret(
                  'Categories',
                  showCategories,
                  setShowCategories
                )}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <div style={{ padding: '10px 10px 30px' }}>
                  {_.isArray(questionCategories) &&
                    questionCategories.length > 0 && (
                      <CRow style={{ flexDirection: 'column' }}>
                        {renderCategoriesAtLevel(
                          questionCategories,
                          0,
                          selectedCategories,
                          'categories'
                        )}
                      </CRow>
                    )}
                </div>
              </Accordion.Collapse>
            </>
          )}
          {!(hiddenSections || []).includes('playerTypes') &&
            _.isArray(playerTypes) &&
            playerTypes.length > 0 && (
              <>
                <Accordion.Toggle
                  eventKey="1"
                  style={{
                    top: 40,
                    bottom: 40,
                  }}
                >
                  {renderLabelAndCaret(
                    'Player Types',
                    showPlayerTypes,
                    setShowPlayerTypes
                  )}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <div>
                    {renderCategoriesAtLevel(
                      playerTypes,
                      0,
                      selectedCategories,
                      'playerTypes'
                    )}
                  </div>
                </Accordion.Collapse>
              </>
            )}
          {!(hiddenSections || []).includes('questionTypes') &&
            _.isArray(questionTypes) &&
            questionTypes.length > 0 && (
              <>
                <Accordion.Toggle eventKey="2" style={{ top: 80, bottom: 0 }}>
                  {renderLabelAndCaret(
                    'Question Types',
                    showQuestionTypes,
                    setShowQuestionTypes
                  )}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                  <div>
                    {renderCategoriesAtLevel(
                      questionTypes,
                      0,
                      selectedCategories,
                      'questionTypes'
                    )}
                  </div>
                </Accordion.Collapse>
              </>
            )}
        </Accordion>
        {/* {isLoadingCategories && (
          <FlexChildLoadingOverlay text="Loading Categories..." />
        )}
        <CContainer style={{ ...rest.style }}>
          {!(hiddenSections || []).includes('categories') &&
            _.isArray(questionCategories) &&
            questionCategories.length > 0 && (
              <CRow>
                {renderLabelAndCaret(
                  'Categories',
                  showCategories,
                  setShowCategories
                )}
                {showCategories &&
                  renderCategoriesAtLevel(
                    questionCategories,
                    0,
                    selectedCategories,
                    'categories'
                  )}
              </CRow>
            )}
          {!(hiddenSections || []).includes('playerTypes') &&
            _.isArray(playerTypes) &&
            playerTypes.length > 0 && (
              <CRow>
                {renderLabelAndCaret(
                  'Player Types',
                  showPlayerTypes,
                  setShowPlayerTypes
                )}
                {showPlayerTypes &&
                  renderCategoriesAtLevel(
                    playerTypes,
                    0,
                    selectedCategories,
                    'playerTypes'
                  )}
              </CRow>
            )}
          {!(hiddenSections || []).includes('questionTypes') &&
            _.isArray(questionTypes) &&
            questionTypes.length > 0 && (
              <CRow>
                {renderLabelAndCaret(
                  'Question Types',
                  showQuestionTypes,
                  setShowQuestionTypes
                )}
                {showQuestionTypes &&
                  renderCategoriesAtLevel(
                    questionTypes,
                    0,
                    selectedCategories,
                    'questionTypes'
                  )}
              </CRow>
            )}
        </CContainer> */}
      </>
    </div>
  );
};

export default QuestionCategories;
