import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CLink,
  CRow,
  CSpinner,
  CTooltip,
} from '@coreui/react';
import './quizzesInvites.scss';
import { format } from 'date-fns';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { addQuiz } from '../../../actions/quizActionCreator';
import { db } from '../../../index';
import QuizActions from '../../../r-components/QuizActions/QuizActions';
// import QuizUserResponse from './QuizUserResponse';
import QuizWrapper from '../../../pages/component/QuizWrapper';
import { TABS } from '../../../AppTabs';
import { FaCheckSquare, FaEnvelopeOpenText, FaRegSquare } from 'react-icons/fa';
import { orgAndTeamData } from '../../../helpers/data';
import OrgTeamImageHeader from '../../../r-components/OrgTeamImageHeader/OrgTeamImageHeader';
import PieChart from '../../../r-components/PieChart/PieChart';
import HeaderWithBackButton from '../../../r-components/HeaderWithBackButton/HeaderWithBackButton';
import {
  ACCESS_ACTIONS,
  canDoAction,
  checkAccess,
  filteredACL,
} from '../../../helpers/access';
import useAccountSelectors from '../../../hooks/useAccountSelectors';

// With implementation of 6tool, UI presents exams as quizzes. The data model contains both exams and quizzes
// having migrated over from Gamer-Metrics
const QuizzesInvites = () => {
  const queryPage = useLocation().search.match(/page=([0-9]+)/, '');
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const quizMap = useSelector((state) => state.DataQuizState?.map || {});
  const playerMap = useSelector((state) => state.DataPlayerState?.map || {});
  const invitesData = useSelector(
    (state) => state.DataExamState?.invites || []
  );
  const [invites, setInvites] = useState([]);

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(currentPage);
  const [selectedInvites, setSelectedInvites] = useState([]);
  const [sorterValue, setSorterVapue] = useState({
    column: 'created',
    asc: false,
  });
  const { tier, type, showSubscriptionModal } = useAccountSelectors();
  const { canDo: canViewResults } = canDoAction({
    action: ACCESS_ACTIONS.QUIZ_RESULTS,
    type,
    tier,
  });

  useEffect(() => {
    currentPage !== page && setPage(currentPage);
  }, [currentPage, page]);

  useEffect(() => {
    setInvites(
      invitesData.map((item) => {
        return {
          ...item,
          player: playerMap[item.user_id]?.name || '',
          quiz: quizMap[item.quiz_id]?.title || '',
          created_formatted: format(
            new Date(item?.created?.seconds * 1000 || item?.created),
            'MMM d'
          ),
          score: parseFloat(item.taken?.score),
          taken_formatted: _.isObject(item?.finished)
            ? format(
                new Date(item?.finished?.seconds * 1000 || item?.finished),
                'MMM d'
              )
            : '',
        };
      })
    );
  }, [invitesData, playerMap, quizMap]);

  return (
    <QuizWrapper>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <HeaderWithBackButton className="d-flex align-items-center justify-content-between">
              <OrgTeamImageHeader
                title="Invites"
                rightView={
                  _.isArray(selectedInvites) &&
                  selectedInvites.length > 0 && (
                    <>
                      <div style={{ flex: 1 }}></div>
                      <div>{`With ${selectedInvites.length} selected invite${
                        selectedInvites.length === 1 ? '' : 's'
                      }:`}</div>
                      <CLink
                        onClick={() => {
                          console.log(
                            `re-send invites ${JSON.stringify(selectedInvites)}`
                          );
                        }}
                      >
                        <FaEnvelopeOpenText style={{ marginLeft: 8 }} />{' '}
                        {`Re-send invite${
                          selectedInvites.length === 1 ? '' : 's'
                        }`}
                      </CLink>
                    </>
                  )
                }
              />
            </HeaderWithBackButton>

            <CCardBody>
              {!loading ? (
                <CDataTable
                  addTableClasses="quiz-table condensed"
                  items={invites}
                  tableFilter
                  itemsPerPageSelect
                  itemsPerPage={50}
                  hover
                  sorter
                  sorterValue={sorterValue}
                  pagination
                  onSorterValueChange={(newValue) => {
                    setSorterVapue(newValue);
                  }}
                  fields={[
                    {
                      key: 'select',
                      label: '',
                    },
                    {
                      key: 'created',
                      label: 'Created',
                      sortable: true,
                      sortIcon: 'fa fa-sort',
                    },
                    {
                      key: 'quiz',
                      label: 'Quiz',
                      _classes: 'font-weight-bold',
                      sortable: true,
                      sortIcon: 'fa fa-sort',
                    },
                    {
                      key: 'player',
                      label: 'Player',
                      _classes: 'font-weight-bold',
                      sortable: true,
                      sortIcon: 'fa fa-sort',
                    },
                    {
                      key: 'result',
                      label: 'Completed?',
                      _classes: 'font-weight-bold',
                      sortable: false,
                    },
                    // { key: 'show_details', label: '' },
                  ]}
                  noItemsViewSlot={
                    <p className="d-flex align-content-center justify-content-center pt-4 pb-4">
                      <FiAlertCircle size="30" color="#e55353" />
                      <span className="pl-2 font-xl">No quiz invites.</span>
                    </p>
                  }
                  scopedSlots={{
                    select: (item) => (
                      <td>
                        {selectedInvites.includes(item.id) ? (
                          <FaCheckSquare
                            onClick={() => {
                              let newSelected = [...selectedInvites];
                              _.remove(newSelected, function (i) {
                                return i === item.id;
                              });
                              setSelectedInvites(newSelected);
                            }}
                          />
                        ) : (
                          <FaRegSquare
                            onClick={() => {
                              setSelectedInvites(
                                Array.from(
                                  new Set([...selectedInvites, item.id])
                                )
                              );
                            }}
                          />
                        )}
                      </td>
                    ),
                    created: (item) => <td>{item.created_formatted}</td>,
                    average: (item) => (
                      <td>
                        {/* <QuizUserResponse
                          examId={item.id}
                          average={item.average}
                          numTaken={item.num_taken}
                        /> */}
                      </td>
                    ),
                    quiz: (item) => <td>{item.quiz}</td>,
                    player: (item) => <td>{item.player}</td>,
                    result: (item) => (
                      <td>
                        {!!item.score ? (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 4,
                            }}
                          >
                            {canViewResults ? (
                              <>
                                <PieChart size={20} percent={item.score} />
                                <div>{item.taken_formatted}</div>
                              </>
                            ) : (
                              <div
                                onClick={() => {
                                  checkAccess({
                                    action: [ACCESS_ACTIONS.QUIZ_RESULTS],
                                    type,
                                    tier,
                                    showSubscriptionModal,
                                  });
                                }}
                                className="quiz-results-grid-row-upgrade-to-view"
                              >
                                Upgrade to view quiz results
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="show-on-hover">
                            <CTooltip content="Re-send Invite">
                              <CLink
                                onClick={() => {
                                  console.log(
                                    'clicked invite again ' + item.id
                                  );
                                }}
                              >
                                <FaEnvelopeOpenText />
                              </CLink>
                            </CTooltip>
                          </div>
                        )}
                      </td>
                    ),
                    // responses: (item) => (
                    //   <td>
                    //     <ExamUserResponse
                    //       examId={item.id}
                    //       average={item.average}
                    //     />
                    //   </td>
                    // ),
                    show_details: (item) => (
                      <td className="py-2 text-right quiz-details"></td>
                    ),
                  }}
                />
              ) : (
                <CCol xl={12} className="text-center pt-2 pb-2">
                  <CSpinner variant="grow" size="lg" />
                </CCol>
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </QuizWrapper>
  );
};

export default QuizzesInvites;
