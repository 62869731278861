import PropTypes from 'prop-types';
import React from 'react';
import Footer from '../Footer';
import AppNav from '../../../r-components/Nav/AppNav';
import { Helmet } from 'react-helmet';
class NoFollowLayout extends React.Component {
  render(props) {
    return (
      <>
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <AppNav />
        {this.props.children}
        <Footer />
      </>
    );
  }
}

NoFollowLayout.propTypes = {
  children: PropTypes.node,
};

export default NoFollowLayout;
