import React, { useEffect, useRef, useState } from 'react';
import { db } from '../../../..';
import { doc, getDoc } from 'firebase/firestore';
import { CButton, CButtonGroup, CDataTable } from '@coreui/react';
import { Link, useLocation } from 'react-router-dom';
import { FiAlertCircle } from 'react-icons/fi';
import { filter, isArray, isObject, isString, map, pick } from 'lodash';
import { TABS } from '../../../../AppTabs';
import { FaEdit } from 'react-icons/fa';
import { textTo8DigitHash } from '../../../../helpers/utils';

const fetchEventDetailsById = async (eventID) => {
  // Replace this with your actual database call
  if (eventID === 'a') return null;
  try {
    const docRef = doc(db, 'events', eventID);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return pick(docSnap.data(), ['name', 'city', 'dateRangeText', 'logoUrl']);
    } else {
      console.log('No event with this ID!', eventID);
      return null;
    }
  } catch (error) {
    console.error('Error fetching event name: ', error);
    throw error;
  }
};

export const EVENT_LEADERBOARD_TYPE = {
  INDIVIDUAL: 'individual',
  TEAM: 'team',
};

export const ANONYMOUS_TYPE = {
  NONE: 'none',
  ALL: 'all',
  INDIVIDUALS: EVENT_LEADERBOARD_TYPE.INDIVIDUAL,
  TEAMS: EVENT_LEADERBOARD_TYPE.TEAM,
};

const EventLeaderboardViewOnly = ({
  eventID,
  eventName: initialEventName = null,
  eventDates: initialEventDates = null,
  eventCity: initialEventCity = null,
  eventLogoUrl: initialEventLogoUrl = null,
  initialLeaderboardType = EVENT_LEADERBOARD_TYPE.INDIVIDUAL,
  showTypeChooser = true,
  top = null,
  anonymous = null,
}) => {
  const [eventName, setEventName] = useState(initialEventName);
  const [eventCity, setEventCity] = useState(initialEventCity);
  const [eventDates, setEventDates] = useState(initialEventDates);
  const [eventLogoUrl, setEventLogoUrl] = useState(initialEventLogoUrl);
  const [leaderboardIndividualData, setLeaderboardIndividualData] = useState(
    []
  );
  const [leaderboardTeamData, setLeaderboardTeamData] = useState([]);
  const queryPage = useLocation().search.match(/page=([0-9]+)/, '');
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [currentFilterValue, setCurrentFilterValue] = useState('');
  const [page, setPage] = useState(currentPage);
  const [eventLeaderboardType, setEventLeaderboardType] = useState(
    initialLeaderboardType || EVENT_LEADERBOARD_TYPE.INDIVIDUAL
  );
  const [_dbAnonymous, _setDBAnonymous] = useState(null);
  const [_anonymous, _setAnonymous] = useState(
    !!anonymous && Object.values(ANONYMOUS_TYPE).includes(anonymous)
      ? anonymous
      : ANONYMOUS_TYPE.NONE
  );

  useEffect(() => {
    currentPage !== page && setPage(currentPage);
  }, [currentPage, page]);

  const handleDataFromDB = (
    snapshot,
    type = EVENT_LEADERBOARD_TYPE.INDIVIDUAL
  ) => {
    const placeMap = {};
    let data = snapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
        tied: null,
        total_time: doc.data().total_time || Number.MAX_SAFE_INTEGER,
      };
    });
    data.sort((a, b) => {
      // Sort by score descending
      if (b.score !== a.score) {
        return b.score - a.score;
      }

      // Sort by num_taken descending
      if (b.num_taken !== a.num_taken) {
        return b.num_taken - a.num_taken;
      }

      // Sort by total_time ascending
      if (a.total_time !== b.total_time) {
        return a.total_time - b.total_time;
      }

      // If both objects have total_time undefined or only one does, maintain current order
      return 0;
    });
    let rank = 1;
    let prevScore = null;
    data = data.map((item, index) => {
      if (item.score !== prevScore) {
        rank = index + 1;
      } else {
        item.tied = true;
      }
      item.rank = rank;
      prevScore = item.score;
      return item;
    });
    data = data.map((item, index) => {
      item.tied =
        item.tied === true ||
        (index + 1 < data.length && data[index + 1].score === item.score);
      item.place = `${item.tied === true ? 'T' : ''}${item.rank}`;
      return item;
    });
    if (type === EVENT_LEADERBOARD_TYPE.TEAM) {
      setLeaderboardTeamData(data);
    } else {
      setLeaderboardIndividualData(data);
    }
  };

  useEffect(() => {
    // Function to handle real-time updates for the anonymous field
    const handleAnonymousSnapshot = (snapshot) => {
      if (snapshot.exists) {
        const data = snapshot.data();
        if (data && data.anonymous !== undefined) {
          _setDBAnonymous(data.anonymous);
        }
      }
    };

    // Real-time listener for the events document
    const unsubscribeEvent = db
      .collection('events')
      .doc(eventID)
      .onSnapshot(handleAnonymousSnapshot);

    // Set up the Firestore listener
    const unsubscribeIndividual = db
      .collection('event_leaderboards')
      .doc(eventID)
      .collection('individuals')
      .onSnapshot((snapshot) => {
        handleDataFromDB(snapshot, EVENT_LEADERBOARD_TYPE.INDIVIDUAL);
      });
    const unsubscribeTeam = db
      .collection('event_leaderboards')
      .doc(eventID)
      .collection('teams')
      .onSnapshot((snapshot) => {
        handleDataFromDB(snapshot, EVENT_LEADERBOARD_TYPE.TEAM);
      });

    // Clean up the listener when the component unmounts
    return () => {
      unsubscribeIndividual();
      unsubscribeTeam();
      unsubscribeEvent();
    };
  }, [eventID]);

  useEffect(() => {
    if (!anonymous || !Object.values(ANONYMOUS_TYPE).includes(anonymous)) {
      if (
        !!_dbAnonymous &&
        Object.values(ANONYMOUS_TYPE).includes(_dbAnonymous)
      ) {
        _setAnonymous(_dbAnonymous);
      } else {
        _setAnonymous(ANONYMOUS_TYPE.NONE);
      }
    } else {
      _setAnonymous(
        !!anonymous && Object.values(ANONYMOUS_TYPE).includes(anonymous)
          ? anonymous
          : ANONYMOUS_TYPE.NONE
      );
    }
  }, [anonymous, _dbAnonymous]);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const fetchedEventDetails = await fetchEventDetailsById(eventID);
        if (isObject(fetchEventDetails)) {
          setEventName(fetchedEventDetails.name);
          setEventCity(fetchedEventDetails.city);
          setEventDates(fetchedEventDetails.dateRangeText);
          setEventLogoUrl(fetchedEventDetails.logoUrl);
        }
      } catch (error) {
        console.error('Error fetching event name:', error);
      }
    };
    if (!(initialEventName && initialEventCity && initialEventDates)) {
      fetchEventDetails();
    }
  }, [eventID, initialEventName, initialEventCity, initialEventDates]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 20,
          marginBottom: 0,
        }}
      >
        {isString(eventLogoUrl) && eventLogoUrl.length > 0 && (
          <img
            style={{ height: 60, width: 'auto', objectFit: 'contain' }}
            src={eventLogoUrl}
          />
        )}
        <h1>{eventName}</h1>
      </div>
      {((isString(eventCity) && eventCity.length > 0) ||
        (isString(eventDates) && eventDates.length > 0)) && (
        <h3 style={{ textAlign: 'center' }}>
          {`${isString(eventCity) && eventCity.length > 0 ? eventCity : ''}${
            isString(eventCity) &&
            eventCity.length > 0 &&
            isString(eventDates) &&
            eventDates.length > 0
              ? `  |  `
              : ''
          }${isString(eventDates) && eventDates.length > 0 ? eventDates : ''}`}
        </h3>
      )}
      {showTypeChooser && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CButtonGroup
            style={{
              background: 'hsl(0,0%,90%)',
              padding: 2,
              borderRadius: 12,
              marginBottom: 20,
              marginTop: 12,
            }}
          >
            <CButton
              style={
                eventLeaderboardType === EVENT_LEADERBOARD_TYPE.INDIVIDUAL
                  ? { background: '#162630', color: 'white', cursor: 'default' }
                  : { cursor: 'pointer' }
              }
              onClick={() => {
                setEventLeaderboardType(EVENT_LEADERBOARD_TYPE.INDIVIDUAL);
              }}
            >
              Individuals
            </CButton>
            <CButton
              style={
                eventLeaderboardType === EVENT_LEADERBOARD_TYPE.TEAM
                  ? { background: '#162630', color: 'white', cursor: 'default' }
                  : { cursor: 'pointer' }
              }
              onClick={() => {
                setEventLeaderboardType(EVENT_LEADERBOARD_TYPE.TEAM);
              }}
            >
              Teams
            </CButton>
          </CButtonGroup>
        </div>
      )}
      <CDataTable
        striped
        items={
          eventLeaderboardType === EVENT_LEADERBOARD_TYPE.TEAM
            ? leaderboardTeamData
            : leaderboardIndividualData
        }
        itemsPerPageSelect={false}
        itemsPerPage={
          !isNaN(top) && parseInt(top) > 0
            ? parseInt(top)
            : eventLeaderboardType === EVENT_LEADERBOARD_TYPE.TEAM
            ? (leaderboardTeamData || []).length
            : (leaderboardIndividualData || []).length
        }
        pagination={false}
        hover
        activePage={page}
        tableFilter={false}
        onTableFilterChange={(filterValue) => {
          setCurrentFilterValue(filterValue);
        }}
        sorter
        sorterValue={{ column: 'score', asc: false }}
        fields={[
          { key: 'place', label: 'Place' },
          {
            key: 'participant',
            label: 'Participant',
            _classes: 'font-weight-bold',
          },
          { key: 'score', label: 'Score' },
        ]}
        responsive={true}
        header={false}
        noItemsViewSlot={
          <p className="d-flex align-content-center justify-content-center pt-4 pb-4">
            <FiAlertCircle size="30" color="#e55353" />
            <span className="pl-2 font-xl">
              {isString(currentFilterValue) && currentFilterValue.length > 0
                ? `No results for "${currentFilterValue}"`
                : `No results yet`}
            </span>
          </p>
        }
        scopedSlots={{
          place: (data) => (
            <td
              style={{
                textAlign: 'right',
                fontFamily: '"Courier New", monospace',
                fontWeight: 'bold',
                width: 0,
              }}
            >
              <div>{data.place}</div>
            </td>
          ),
          participant: (data) => (
            <td>
              <div>
                <span
                  className="font-weight-bold"
                  style={
                    _anonymous === ANONYMOUS_TYPE.ALL ||
                    _anonymous === eventLeaderboardType
                      ? { fontFamily: '"Courier New", monospace' }
                      : {}
                  }
                >
                  {_anonymous === ANONYMOUS_TYPE.ALL ||
                  _anonymous === eventLeaderboardType
                    ? textTo8DigitHash(
                        eventLeaderboardType === EVENT_LEADERBOARD_TYPE.TEAM
                          ? `${data.name}${data.city}`
                          : `${data.user_id}${data.name}`
                      )
                    : data.name}
                </span>
                {_anonymous !== ANONYMOUS_TYPE.ALL &&
                  _anonymous !== eventLeaderboardType && (
                    <span
                      style={{ marginLeft: 8, fontSize: '0.8em', opacity: 0.6 }}
                    >
                      {`(${
                        eventLeaderboardType === EVENT_LEADERBOARD_TYPE.TEAM
                          ? `${
                              isString(data.city) && data.city.length > 0
                                ? `${data.city} | `
                                : ''
                            }`
                          : `${
                              isString(data.team_name) &&
                              data.team_name.length > 0
                                ? `${data.team_name} | `
                                : ''
                            }`
                      }${data.num_taken} quiz${
                        data.num_taken === 1 ? '' : 'zes'
                      } taken)`}
                    </span>
                  )}
              </div>
            </td>
          ),
          score: (data) => (
            <td
              style={{
                textAlign: 'right',
                fontFamily: '"Courier New", monospace',
                fontWeight: 'bold',
                width: 0,
              }}
            >
              <div className="font-weight-bold">{`${Math.round(
                data.score * 100
              )}%`}</div>
            </td>
          ),
        }}
      />
    </>
  );
};

export default EventLeaderboardViewOnly;
