export const quizzes = [
  {
    content: `Welcome to 6-Tool!`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}`,
  },
  {
    content: `This page shows you all the quizzes you have available to send to your players. Some may be quizzes you created, and also regularly we add 6Tool quizzes to this page for you to use.`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/quizzes`,
  },
  {
    selector: '.main-content .quiz-table > thead',
    content: `Click on the headers to sort by this column`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/quizzes`,
  },
  {
    selector: '.main-content .card-body input',
    content: `Type a search term to search your quizzes`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/quizzes`,
  },
  {
    selector: '.main-content .quiz-table > tbody > tr:first-child',
    content: `See the name, date, and player response from your quiz`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/quizzes`,
  },
  {
    selector:
      '.main-content .quiz-table > tbody > tr:first-child td.quiz-details img.quiz-owner-icon',
    content: `This icon indicates if the quiz was made by you, your organization, or by 6Tool`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/quizzes`,
  },
  {
    selector:
      '.main-content .quiz-table > tbody > tr:first-child td.quiz-details button.quiz-invite-button',
    content: `Use this button to invite groups of players to your quiz`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/quizzes`,
  },
  {
    selector:
      '.main-content .quiz-table > tbody > tr:first-child td.quiz-details button.quiz-details-button',
    content: `Click here to view the details of your quiz and see the scores and answers of each individual player once they have completed the quiz.`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/quizzes`,
  },
];
