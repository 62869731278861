import React, { useEffect } from 'react';
import { Redirect, withRouter, useLocation } from 'react-router-dom';
import { AMBASSADOR_LINKS } from '../../../AppTabs';
import _ from 'lodash';
import { setUserProperties, analytics } from '../../..';

const AmbassadorRedirect = ({ to = '/' } = {}) => {
  const location = useLocation();
  useEffect(() => {
    if (
      _.isString(location?.pathname) &&
      location.pathname.startsWith('/') &&
      AMBASSADOR_LINKS.includes(location.pathname.substring(1).toLowerCase())
    ) {
      localStorage.setItem(
        'referral_source',
        location.pathname.substring(1).toLowerCase()
      );
      try {
        setUserProperties(analytics, {
          source: location.pathname.substring(1),
          utm_campaign: location.pathname.substring(1),
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [location]);

  return <Redirect to={to} />;
};

export default withRouter(AmbassadorRedirect);
