import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './SignupSuccess.scss';
import { CButton } from '@coreui/react';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { TABS } from '../../AppTabs';
import SEOHead from '../../r-components/SEOHead/SEOHead';
import { useHistory } from 'react-router-dom';
import { EMAIL_ADDRESSES } from '../../config';

const SignupSuccess = () => {
  const [countdown, setCountdown] = useState(15);
  const history = useHistory();

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setCountdown((prevCount) => prevCount - 1);
    }, 1000);

    // Redirect after 15 seconds
    setTimeout(() => {
      clearInterval(countdownInterval);
      history.push(); // Replace '/destination' with your desired destination path
    }, 15000);

    // Cleanup function
    return () => {
      clearInterval(countdownInterval);
    };
  }, [history]);
  useEffect(() => {
    // Add meta tag for refresh redirect
    const metaTag = document.createElement('meta');
    metaTag.setAttribute('http-equiv', 'refresh');
    metaTag.setAttribute('content', `15; URL=${TABS.COACH_TEAMS.path}`);
    document.head.appendChild(metaTag);

    // Cleanup function to remove the added meta tag
    return () => {
      document.head.removeChild(metaTag);
    };
  }, []);

  return (
    <>
      {/* <SEOHead
        title="Top-Notch Baseball Player Development at the Cost of a Single Bat"
        description="Experience revolutionary player development and coaching for your entire team with 6Tool — all at the price of one new baseball bat. Our affordable pricing includes unlimited access to our extensive 6Tool question library, custom quizzes, inclusive team management, and more. Discover our value-added baseball coaching app today."
      /> */}
      <section className="signup-success">
        <div className="container">
          <div className="title">
            <h1>Welcome to 6Tool!</h1>
          </div>
          <p>
            {`We're thrilled to have you on the 6Tool team. Let's get started
            developing your roster of MVPs! We’re building your team portal now.
            You’ll be logged into your account in ${countdown} second${
              countdown === 1 ? '' : 's'
            }.`}
          </p>
          <p>
            Check out our{' '}
            <a href={TABS.FAQ.path} target="_blank" rel="noreferrer">
              FAQ page
            </a>{' '}
            or reach out to{' '}
            <a href={`mailto:${EMAIL_ADDRESSES.INFO}`}>
              {EMAIL_ADDRESSES.INFO}
            </a>{' '}
            if you have any questions.
          </p>
        </div>
      </section>
    </>
  );
};

export default SignupSuccess;
