import React, { useEffect, useState } from 'react';
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import styled from 'styled-components';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { setIn } from 'formik';
import { defaultQuestionSituation } from '../../helpers/data';

const MAX_SCOREBOARD_RUNS = 10;
const US_TEXT = 'Us';
const THEM_TEXT = 'Them';

const DiamondMain = styled.div`
  flex: 0;
  max-width: 300px;
  margin: 0 auto 20px;
  background: #222c58;
  display: flex;
`;

const DiamondTop = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
`;

const DiamondLeft = styled.div`
  flex: 0 0 45%;
  padding: 15px 10px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const DiamondCenter = styled.div`
  flex: 0 0 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  text-align: center;
`;

const DiamondText = styled.div`
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Aviner';
  display: flex;
`;

const DiamondRight = styled.div`
  justify-content: center;
  align-items: center;
  padding: 0 15px;
`;

const DiamondLeftRow = styled.div`
  display: flex;
  padding: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const DiamondLeftCol = styled.div`
  flex: 2;
  cursor: pointer;
`;

const DiamondRightCol = styled.div`
  flex: 1;
  text-align: right;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
`;

// const DiamondBottom = styled.div`
//   display: flex;
//   height: 40px;
//   flex-wrap: nowrap;
//   flex-direction: row;
//   justify-content: flex-start;
//   padding: 5px 0;
// `;

const DiamondBottomRightInner = styled.div`
  position: relative;
  width: 70px;
  height: 46px;
`;

const DiamondBox = styled.div`
  width: 20px;
  height: 20px;
  background: ${({ background }) => (background ? 'yellow' : '#010300')};
  border: 2px solid rgba(255, 255, 255, 0.3);
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

const DiamondOuts = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background: ${({ background }) => (background ? 'red' : '#222c58')};
  border: 2px solid rgba(255, 255, 255, 0.3);
  transform: rotate(45deg);
  position: absolute;
  top: 40px;
  left: 28px;
  margin: auto;
`;

// const DiamondBottomCol = styled.div`
//   flex: 1;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
// `;

// const DiamBottomText = styled.div`
//   color: #fff;
//   font-size: 18px;
//   line-height: 24px;
//   text-transform: uppercase;
//   font-weight: 600;
// `;
const DiamCenterText = styled.div`
  color: #fff;
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
`;
const DiamRightText = styled.div`
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: -16px;
  margin-top: 12px;
  text-align: center;
`;

// const DiamondBottomLeft = styled.div`
//   flex: 0 0 30%;
//   font-family: 'Aviner', sans-serif;
//   color: #fff;
//   font-size: 18px;
//   line-height: 24px;
//   text-transform: uppercase;
//   font-weight: 600;
//   padding: 0 0 0 20px;
//   margin: 0;
//   text-align: left;
//   display: flex;
//   flex-direction: row;
// `;

// const DiamondBottomLeftIcon = styled.i`
//   color: #959595;
//   padding-top: 6px;
// `;

// const DiamondBottomCenter = styled.div`
//   flex: 0 0 30%;
//   font-family: 'Aviner', sans-serif;
//   color: #fff;
//   font-size: 18px;
//   line-height: 24px;
//   text-transform: uppercase;
//   font-weight: 600;
//   padding: 0 20px 0 0;
//   margin: 0;
//   text-align: right;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;
// `;

// const DiamondBottomCenterSpan = styled.div`
//   color: #646464;
//   font-size: 15px;
// `;

// const DiamondBottomRight = styled.div`
//   flex: 0 0 40%;
//   font-family: 'Aviner', sans-serif;
//   color: #fff;
//   font-size: 18px;
//   line-height: 24px;
//   text-transform: uppercase;
//   font-weight: 600;
//   padding: 0 10px;
//   margin: 0;
//   text-align: center;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

// const DiamondCount = styled.div`
//   cursor: pointer;
//   display: inline;
//   width: 20px;
//   text-align: center;
// `;

const ScoreBoard = ({
  editable = false,
  situation = defaultQuestionSituation,
  setSituation = null,
  size = 145,
} = {}) => {
  const [homeTeam, setHomeTeam] = useState(situation.homeTeam ?? true);
  const [homeScore, setHomeScore] = useState(situation?.homeScore ?? 0);
  const [awayScore, setAwayScore] = useState(situation?.awayScore ?? 0);
  const [runnerAt1, setRunnerAt1] = useState(situation?.runnerAt1 ?? false);
  const [runnerAt2, setRunnerAt2] = useState(situation?.runnerAt2 ?? false);
  const [runnerAt3, setRunnerAt3] = useState(situation?.runnerAt3 ?? false);
  const [outs, setOuts] = useState(situation?.outs ?? 0);
  const [inning, setInning] = useState(situation.inning ?? 1);
  const [inningTopHalf, setInningTopHalf] = useState(
    situation?.inningTopHalf ?? true
  );
  const [countBalls, setCountBalls] = useState(situation?.countBalls ?? 0);
  const [countStrikes, setCountStrikes] = useState(
    situation?.countStrikes ?? 0
  );
  useEffect(() => {
    setHomeTeam(situation?.homeTeam ?? true);
    setHomeScore(situation?.homeScore ?? 0);
    setAwayScore(situation?.awayScore ?? 0);
    setRunnerAt1(situation?.runnerAt1 ?? false);
    setRunnerAt2(situation?.runnerAt2 ?? false);
    setRunnerAt3(situation?.runnerAt3 ?? false);
    setOuts(situation?.outs ?? 0);
    setInning(situation?.inning ?? 1);
    setInningTopHalf(situation?.inningTopHalf ?? true);
    setCountBalls(situation?.countBalls ?? 0);
    setCountStrikes(situation?.countStrikes ?? 0);
    return () => {};
  }, [situation]);

  const renderDropDown = ({ min, max, current, onClick, style }) => {
    return (
      <CDropdown className="border-0">
        <CDropdownToggle
          style={{
            ...{
              color: 'white',
              boxShadow: 'none',
              fontFamily: 'Aviner, sans-serif',
              fontSize: 18,
              fontWeight: 700,
            },
            ...style,
          }}
          caret={false}
        >
          {current}
        </CDropdownToggle>
        {editable && (
          <CDropdownMenu>
            {[...Array(max + 1 - min)].map((x, i) => (
              <CDropdownItem
                key={`run-item-${i}`}
                style={
                  i + min === current
                    ? { fontWeight: 900, backgroundColor: '#efefef' }
                    : {}
                }
                onClick={() => onClick(i)}
              >
                {i + min}
              </CDropdownItem>
            ))}
          </CDropdownMenu>
        )}
      </CDropdown>
    );
  };

  const renderScore = ({ isHome }) => {
    const onClick = (i) => {
      if (!editable) {
        return;
      }
      if (isHome) {
        setHomeScore(i);
        setSituation({ ...situation, homeScore: i });
      } else {
        setAwayScore(i);
        setSituation({ ...situation, awayScore: i });
      }
    };

    return renderDropDown({
      min: 0,
      max: MAX_SCOREBOARD_RUNS,
      current: isHome ? homeScore : awayScore,
      onClick,
    });
  };

  const renderInning = () => {
    const onClick = (i) => {
      if (!editable) {
        return;
      }
      setInning(i + 1);
      setSituation({ ...situation, inning: i + 1 });
    };

    return renderDropDown({
      min: 1,
      max: 10,
      current: inning,
      onClick,
      style: { padding: 0 },
    });
  };

  return (
    <DiamondMain style={{ transform: `scale(${size / 145})` }}>
      <DiamondTop>
        <DiamondLeft>
          <DiamondLeftRow>
            <DiamondLeftCol
              onClick={() => {
                if (!editable) {
                  return;
                }
                setHomeTeam(!homeTeam);
                setSituation({ ...situation, homeTeam: !homeTeam });
              }}
            >
              <DiamondText>{homeTeam ? THEM_TEXT : US_TEXT}</DiamondText>
            </DiamondLeftCol>
            <DiamondRightCol>
              <DiamondText>{renderScore({ isHome: false })}</DiamondText>
            </DiamondRightCol>
          </DiamondLeftRow>
          <DiamondLeftRow>
            <DiamondLeftCol
              onClick={() => {
                if (editable) {
                  setHomeTeam(!homeTeam);
                  setSituation({ ...situation, homeTeam: !homeTeam });
                }
              }}
            >
              <DiamondText>{homeTeam ? US_TEXT : THEM_TEXT}</DiamondText>
            </DiamondLeftCol>
            <DiamondRightCol>
              <DiamondText>{renderScore({ isHome: true })}</DiamondText>
            </DiamondRightCol>
          </DiamondLeftRow>
        </DiamondLeft>
        <DiamondCenter>
          <FaChevronUp
            style={{
              cursor: 'pointer',
              color: inningTopHalf
                ? 'rgba(255,255,255,1)'
                : 'rgba(255,255,255,0.1)',
            }}
            onClick={() => {
              if (editable) {
                setInningTopHalf(true);
                setSituation({ ...situation, inningTopHalf: true });
              }
            }}
          />
          <DiamCenterText>{renderInning()}</DiamCenterText>
          <FaChevronDown
            style={{
              cursor: 'pointer',
              color: inningTopHalf
                ? 'rgba(255,255,255,0.1)'
                : 'rgba(255,255,255,1)',
            }}
            onClick={() => {
              if (editable) {
                setInningTopHalf(false);
                setSituation({ ...situation, inningTopHalf: false });
              }
            }}
          />
        </DiamondCenter>
        <DiamondRight>
          <DiamondBottomRightInner>
            <DiamondBox
              onClick={() => {
                if (!editable) {
                  return;
                }
                setRunnerAt1(!runnerAt1);
                setSituation({ ...situation, runnerAt1: !runnerAt1 });
              }}
              style={{
                top: 20,
                left: 40,
                backgroundColor: runnerAt1 ? 'yellow' : '#010300',
              }}
            ></DiamondBox>
            <DiamondBox
              onClick={() => {
                if (!editable) {
                  return;
                }

                setRunnerAt2(!runnerAt2);
                setSituation({ ...situation, runnerAt2: !runnerAt2 });
              }}
              style={{
                top: 0,
                left: 0,
                backgroundColor: runnerAt2 ? 'yellow' : '#010300',
              }}
            ></DiamondBox>
            <DiamondBox
              onClick={() => {
                if (!editable) {
                  return;
                }

                setRunnerAt3(!runnerAt3);
                setSituation({ ...situation, runnerAt3: !runnerAt3 });
              }}
              style={{
                top: 20,
                left: -40,
                backgroundColor: runnerAt3 ? 'yellow' : '#010300',
              }}
            ></DiamondBox>
            <DiamondOuts
              background={(outs || 0) > 0}
              style={{ left: 21, cursor: 'pointer' }}
              onClick={() => {
                if (!editable) {
                  return;
                }
                if (outs === 1) {
                  setOuts(0);
                  setSituation({ ...situation, outs: 0 });
                } else {
                  setOuts(1);
                  setSituation({ ...situation, outs: 1 });
                }
              }}
            />
            <DiamondOuts
              background={(outs || 0) > 1}
              style={{ left: 37, cursor: 'pointer' }}
              onClick={() => {
                if (!editable) {
                  return;
                }
                if (outs === 2) {
                  setOuts(1);
                  setSituation({ ...situation, outs: 1 });
                } else {
                  setOuts(2);
                  setSituation({ ...situation, outs: 2 });
                }
              }}
            />
          </DiamondBottomRightInner>
          {(true || countBalls || countStrikes) && (
            <DiamRightText>
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (!editable) {
                    return;
                  }
                  if (countBalls >= 3) {
                    setCountBalls(0);
                    setSituation({ ...situation, countBalls: 0 });
                  } else {
                    setSituation({ ...situation, countBalls: countBalls + 1 });
                    setCountBalls(countBalls + 1);
                  }
                }}
              >
                {countBalls}
              </span>{' '}
              -{' '}
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (!editable) {
                    return;
                  }
                  if (countStrikes >= 2) {
                    setCountStrikes(0);
                    setSituation({ ...situation, countStrikes: 0 });
                  } else {
                    setSituation({
                      ...situation,
                      countStrikes: countStrikes + 1,
                    });
                    setCountStrikes(countStrikes + 1);
                  }
                }}
              >
                {countStrikes}
              </span>
            </DiamRightText>
          )}
        </DiamondRight>
      </DiamondTop>
      {/* <DiamondBottom>
        <DiamondBottomLeft>
          <DiamondBottomLeftIcon
            onClick={() => {
              setInningTopHalf(!inningTopHalf);
              setSituation({ ...situation, inningTopHalf: !inningTopHalf });
            }}
          >
            {inningTopHalf ? <FiChevronUp /> : <FiChevronDown />}
          </DiamondBottomLeftIcon>
          {renderDropDown({
            min: 1,
            max: 9,
            current: inning,
            onClick: (i) => {
              setInning(i + 1);
              setSituation({ ...situation, inning: i + 1 });
            },
          })}
        </DiamondBottomLeft>
        <DiamondBottomCenter
          onClick={() => {
            setOuts((outs + 1) % 3);
            setSituation({ ...situation, outs: outs + 1 });
          }}
        >
          <span style={{ marginRight: 4 }}>{outs}</span>
          <DiamondBottomCenterSpan>
            out{outs !== 1 && 's'}
          </DiamondBottomCenterSpan>
        </DiamondBottomCenter>
        <DiamondBottomRight>
          <DiamondCount
            onClick={() => {
              setCountBalls((countBalls + 1) % 4);
              setSituation({ ...situation, countBalls: countBalls + 1 });
            }}
          >
            {countBalls}
          </DiamondCount>
          -
          <DiamondCount
            onClick={() => {
              setCountStrikes((countStrikes + 1) % 3);
              setSituation({ ...situation, countStrikes: countStrikes + 1 });
            }}
          >
            {countStrikes}
          </DiamondCount>
        </DiamondBottomRight>
      </DiamondBottom> */}
    </DiamondMain>
  );
};

export default ScoreBoard;
