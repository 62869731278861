import CIcon from '@coreui/icons-react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CLink,
  CRow,
} from '@coreui/react';
import './signup.scss';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { FaEnvelope, FaLock, FaUser } from 'react-icons/fa';
import Spinner from '../component/Spinner';
import { Formik, Form, Field, ErrorMessage } from 'formik';
// import { Button, FormGroup, FormLabel, Input, BootstrapForm.Control.Feedback } from "react-bootstrap";
import {
  Button,
  Form as BootstrapForm,
  FormGroup,
  FormLabel,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import { auth, functions } from '../..';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const SignupWrapper = ({ children }) => {
  return (
    <div>
      <div className="gradient-overlay-half-dark-v3 bg-img-hero login-background">
        <div className="align-items-lg-center min-height-100vh">
          <div className="container space-2">
            <div className="row justify-content-lg-between align-items-lg-center">
              <div className="col-lg-12 m-auto">
                <div className="bg-white shadow-sm rounded p-6">{children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupWrapper;
