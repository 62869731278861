import React, { useEffect } from 'react';
import _ from 'lodash';
import { mimeTypeFromURL } from '../../helpers/data';
import { CLabel } from '@coreui/react';
import { FaCheck, FaTimes } from 'react-icons/fa';
// import Path, { Svg, Circle, G, Polygon, Line } from 'react-svg-path';
// import Svg, {
//     Circle,
//     G,
//     Path,
//     Polygon,
//     Line,
//   } from 'react-native-svg';

const QuestionOptions = ({ options, responseCount, singleResponse }) => {
  return (
    <div className="question-options-grid">
      {(options || []).map((option, index) => (
        <React.Fragment key={`question-options-${index}`}>
          <div
            style={{
              fontSize: 14,
              color:
                option?.correct === true &&
                (isNaN(singleResponse) ||
                  _.isNil(singleResponse) ||
                  (!(isNaN(singleResponse) || _.isNil(singleResponse)) &&
                    singleResponse === index))
                  ? 'green'
                  : !(isNaN(singleResponse) || _.isNil(singleResponse)) &&
                    singleResponse === index
                  ? 'red'
                  : 'inherit',
              fontWeight:
                option?.correct === true &&
                (isNaN(singleResponse) ||
                  _.isNil(singleResponse) ||
                  (!(isNaN(singleResponse) || _.isNil(singleResponse)) &&
                    singleResponse === index))
                  ? '900'
                  : !(isNaN(singleResponse) || _.isNil(singleResponse)) &&
                    singleResponse === index
                  ? '900'
                  : 'inherit',
            }}
          >
            ({'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.substring(index, index + 1)})
          </div>
          <div
            style={{
              color:
                option?.correct === true &&
                (isNaN(singleResponse) ||
                  _.isNil(singleResponse) ||
                  (!(isNaN(singleResponse) || _.isNil(singleResponse)) &&
                    singleResponse === index))
                  ? 'green'
                  : !(isNaN(singleResponse) || _.isNil(singleResponse)) &&
                    singleResponse === index
                  ? 'red'
                  : 'inherit',
              fontWeight:
                option?.correct === true &&
                (isNaN(singleResponse) ||
                  _.isNil(singleResponse) ||
                  (!(isNaN(singleResponse) || _.isNil(singleResponse)) &&
                    singleResponse === index))
                  ? '900'
                  : !(isNaN(singleResponse) || _.isNil(singleResponse)) &&
                    singleResponse === index
                  ? '900'
                  : 'inherit',
            }}
          >
            <CLabel className="m-0">{option.text}</CLabel>
          </div>
          <div
            style={{
              color:
                option?.correct === true
                  ? 'green'
                  : !(isNaN(singleResponse) || _.isNil(singleResponse)) &&
                    singleResponse === index
                  ? 'red'
                  : 'inherit',
              fontWeight: option?.correct === true ? 900 : 'inherit',
              textAlign: 'center',
            }}
          >
            {option?.correct === true ? (
              <FaCheck />
            ) : (
              <>
                {!(isNaN(singleResponse) || _.isNil(singleResponse)) &&
                  index === parseInt(singleResponse) && (
                    <FaTimes
                      color="red"
                      style={{
                        fontWeight: 900,
                      }}
                    />
                  )}
              </>
            )}
          </div>
          <div
            style={{
              color:
                option?.correct === true
                  ? 'green'
                  : _.isObject(responseCount) && !!responseCount[index]
                  ? 'red'
                  : 'inherit',
              fontWeight: option?.correct === true ? 900 : 'inherit',
            }}
          >
            {_.isObject(responseCount) && (
              <>
                {!!responseCount[index] ? (
                  <span>{responseCount[index]}</span>
                ) : (
                  <span>0</span>
                )}
              </>
            )}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};
export default QuestionOptions;
