import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CLabel,
  CListGroup,
  CListGroupItem,
  CRow,
  CSpinner,
} from '@coreui/react';
import { useStripe } from '@stripe/react-stripe-js';
import { Form, Formik } from 'formik';
import { range } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiEdit2, FiEdit3 } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import * as Yup from 'yup';
import { db, functions } from '../../../index';
import { POSITIONS } from '../../../r-reusable';
import { setCurrentUser } from '../../../r-store/user/actions';
import {
  Button,
  Col,
  Container,
  Nav,
  Row,
  Tab,
  TabContainer,
  TabContent,
} from 'react-bootstrap';
import CIcon from '@coreui/icons-react';
import { cilPuzzle, cilSpeedometer } from '@coreui/icons';
import ProfileDetails from './ProfileDetails';
import ProfileSecurity from './ProfileSecurity';
import ProfileTeams from './ProfileTeams';
import ProfileSubscription from './ProfileSubscription';
import { ROUTE_ELEMENTS, TABS, paramEncode } from '../../../AppTabs';
import _ from 'lodash';
import { ACCESS_ACTIONS, canDoAction } from '../../../helpers/access';

const ProfileWrapper = ({ children, currentProfileTab }) => {
  // const [currentProfileTab, setCurrentProfileTab] = useState('DETAILS');

  const [profileTabs, setProfileTabs] = useState({});
  const userData = useSelector((state) => state.UserState);

  useEffect(() => {
    let PROFILE_TABS;
    const detailsTab = {
      tab: ROUTE_ELEMENTS.DETAILS,
      label: 'Details',
      path: TABS.USER_PROFILE_DETAILS.path,
    };
    // const teamsTab = {
    //   tab: ROUTE_ELEMENTS.TEAMS,
    //   label: 'Teams',
    //   path: TABS.
    //   render: (props) => <ProfileTeams {...props} />,
    // };
    const subscriptionTab = {
      tab: ROUTE_ELEMENTS.SUBSCRIPTION,
      label: 'Subscription',
      path: TABS.OWNER_PROFILE_SUBSCRIPTION.path,
    };
    const securityTab = {
      tab: ROUTE_ELEMENTS.SECURITY,
      label: 'Password & Login',
      path: TABS.USER_PROFILE_SECURITY.path,
    };
    if (
      canDoAction({
        action: ACCESS_ACTIONS.VIEW_SUBSCRIPTION,
        type: userData.data?._roles || [],
      }).canView &&
      canDoAction({
        action: ACCESS_ACTIONS.EDIT_TEAM,
        type: userData?.data?._roles || [],
      }).canView
    ) {
      PROFILE_TABS = {
        DETAILS: detailsTab,
        // TEAMS: teamsTab,
        SUBSCRIPTION: subscriptionTab,
        SECURITY: securityTab,
      };
    } else if (
      canDoAction({
        action: ACCESS_ACTIONS.VIEW_SUBSCRIPTION,
        type: userData?.data?._roles || [],
      }).canDo
    ) {
      PROFILE_TABS = {
        DETAILS: detailsTab,
        SUBSCRIPTION: subscriptionTab,
        SECURITY: securityTab,
      };
    } else if (
      canDoAction({
        action: ACCESS_ACTIONS.EDIT_TEAM,
        type: userData?.data?._roles || [],
      }).canDo
    ) {
      PROFILE_TABS = {
        DETAILS: detailsTab,
        // TEAMS: teamsTab,
        SECURITY: securityTab,
      };
    } else {
      PROFILE_TABS = {
        DETAILS: detailsTab,
        SECURITY: securityTab,
      };
    }
    setProfileTabs(PROFILE_TABS);
  }, [userData]);

  return (
    <Container className="space-1 profile-view">
      <CCard>
        <CCardHeader>
          <h1>User Profile</h1>
        </CCardHeader>
        <Row style={{ margin: 0 }}>
          <Col md={3} style={{ padding: 30, background: '#e3e6f0' }}>
            <Nav className="flex-column profile-view-tabs">
              {Object.entries(profileTabs).map(([tab, { label, path }]) => (
                <Nav.Item>
                  <Nav.Link
                    className={tab === currentProfileTab ? 'active' : ''}
                    eventKey={paramEncode(label)}
                    href={path}
                  >
                    {label}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col
            md={9}
            style={{
              maxHeight: 'calc(100vh - 400px',
              overflow: 'scroll',
              padding: 30,
            }}
          >
            {children}
            {/* {_.isObject(profileTabs) &&
              _.isObject(profileTabs[currentProfileTab]) &&
              _.isFunction(profileTabs[currentProfileTab].render) &&
              profileTabs[currentProfileTab].render()} */}
          </Col>
        </Row>
      </CCard>
    </Container>
  );
};

export default ProfileWrapper;
