import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CLabel,
  CRow,
  CTooltip,
} from '@coreui/react';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import './EventUpdate.scss';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Form, Formik } from 'formik';
import { range } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import * as Yup from 'yup';
import 'yup-phone-lite';
import _ from 'lodash';
import {
  FaAngleDown,
  FaAngleLeft,
  FaCheckCircle,
  FaCheckSquare,
  FaInfoCircle,
  FaRegSquare,
  FaTrashAlt,
} from 'react-icons/fa';
import { FaToggleOff, FaToggleOn } from 'react-icons/fa6';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  desanitizePhoneNumber,
  extractLastName,
  sanitizePhoneNumber,
} from '../../../../helpers/data';
import { db, functions } from '../../../..';
import { POSITIONS } from '../../../../r-reusable';
import EventsWrapper from '../../../../pages/component/EventsWrapper';
import { TABS } from '../../../../AppTabs';
import HeaderWithBackButton from '../../../../r-components/HeaderWithBackButton/HeaderWithBackButton';
import { handleError } from '../../../../helpers/errors';
import LoadingMessage from '../../../../r-components/LoadingMessage/LoadingMessage';
import FlexSpacer from '../../../../r-components/FlexSpacer/FlexSpacer';
import ConfirmModal from '../../../../r-components/ConfirmModal/ConfirmModal';
import { DateRange, DateRangePicker, DefinedRange } from 'react-date-range';
import Toggle from '../../../../r-components/Toggle/Toggle';

const _initialValues = {
  city: '',
  dateRange: [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ],
  hideIndividualLeaderboard: false,
  hideLeaderboardCounts: false,
  hideTeamLeaderboard: false,
  logoUrl: '',
  name: '',
  eventPartnerId: '',
  password: null,
  teams: [],
  visibleDates: [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ],
};

const validationSchema = Yup.object().shape({
  // city: Yup.string().trim().city().required('Email is required'),
  // name: Yup.string().trim().required('Name is required'),
  // phoneNumber: Yup.string()
  //   .trim()
  //   .phone('US', 'Phone number is invalid')
  //   .optional(),
  // age: Yup.object().optional(),
  // city: Yup.string().optional(),
  // positions: Yup.array().optional(),
  // groups: Yup.array().optional(),
  // classYear: Yup.object().optional(),
  // youtube: Yup.string().optional(),
  // baseballReports: Yup.string().optional(),
  // twitter: Yup.string().optional(),
  // instagram: Yup.string().optional(),
  // teams: Yup.object().test(
  //   'notEmptyObject',
  //   'You must select at least one team',
  //   (value) => {
  //     return Object.keys(value).length !== 0;
  //   }
  // ),
});

function EventUpdate({ match }) {
  const history = useHistory();
  const isMounted = useRef(false);
  const [user, setUser] = useState({});
  const [teamGroups, setTeamGroups] = useState({});
  const [groupMap, setGroupMap] = useState({});
  const formikRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(_initialValues);
  const [submitting, setSubmitting] = useState(false);
  const [showingAdvancedDetails, setShowingAdvancedDetails] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const [maxxedOutTeams, setMaxxedOutTeams] = useState([]);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showConfirmAgainDeleteModal, setShowConfirmAgainDeleteModal] =
    useState(false);
  const {
    UserState: currentUser,
    ThemeState: { darkMode },
    DataTeamState: teamData,
    DataOrganizationState: orgData,
    DataGroupState: groupData,
  } = useSelector((state) => state);
  const [userId, setUserId] = useState('a');
  useEffect(() => {
    setUserId(match.params.id || 'a');
  }, [match]);

  useEffect(() => {
    let _maxxedOutTeams = new Set();
    if (_.isArray(teamData?.data) && teamData.data.length > 0) {
      teamData.data.forEach((team) => {
        if (
          _.isString(team?._id) &&
          team._id.length > 0 &&
          team.maxxedPlayers === true &&
          !(Object.keys(initialValues?.teams || {}) || []).includes(team._id)
        ) {
          _maxxedOutTeams.add(team._id);
        }
      });
    }
    setMaxxedOutTeams(Array.from(_maxxedOutTeams));
  }, [orgData, teamData, initialValues]);

  useEffect(() => {
    if (_.isEmpty(initialValues.teams)) {
      setInitialValues({
        ...initialValues,
        ...user,
        ...{
          teams:
            _.isArray(teamData?.data) && teamData.data.length === 1
              ? { [teamData.data[0]._id]: { roles: ['player'] } }
              : {},
        },
      });
    }
  }, [user, teamData]);
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        // const groupsList = [];
        const groupsPromise = [];

        // const organizationId = currentUser?.organizationId || 'a';

        isMounted.current = true;
        const userRef = doc(db, 'users', userId);
        const userResponse = await getDoc(userRef);
        const userData = userResponse.data();

        if (!!userData) {
          if (isMounted.current) {
            const { name, type, phoneNumber, ...rest } = userData;
            const data = _.pickBy(
              {
                ...initialValues,
                ...rest,
                name,
                phoneNumber: desanitizePhoneNumber(phoneNumber),
                teams: _.pickBy(
                  (userData.org_team || {})[orgData.data.id] || {},
                  (value, key) => !key.startsWith('_')
                ),
              },
              (value, key) => !key.startsWith('_')
            );
            setLoading(false);
            setInitialValues(data);
            setUser(data);
          }
        }
      } catch (e) {
        handleError({
          error: e,
          internal: 'EventUpdate.js - loadData',
        });
        if (isMounted.current) {
          setLoading(false);
        }
      }
    };

    if (currentUser && !!userId && userId.length > 1) {
      loadData();
    }
    setIsCreate(!(!!userId && userId.length > 1));
    return () => {
      isMounted.current = false;
    };
  }, [currentUser, userId]);

  useEffect(() => {
    let gs = (groupData.data || []).map((g) => {
      return { id: g.id, name: g.title };
    });
    setGroupMap(_.chain(gs).keyBy('id').mapValues('name').value());
    setTeamGroups(
      _.groupBy(
        _.map(groupData.data || [], (g) =>
          _.pick(g, ['teamId', 'id', 'title', 'default'])
        ),
        'teamId'
      )
    );
  }, [groupData]);

  const onSubmit = async (
    { name, city, groups, phoneNumber, teams, ...rest },
    _
  ) => {
    if (isCreate) {
      return createEvent({
        name,
        city,
        phoneNumber: sanitizePhoneNumber(phoneNumber),
        teams,
        ...rest,
      });
    } else {
      return updateEvent({
        name,
        city,
        phoneNumber: sanitizePhoneNumber(phoneNumber),
        teams,
        ...rest,
      });
    }
  };

  const createEvent = async ({
    name,
    city,
    groups,
    phoneNumber,
    teams,
    ...rest
  }) => {
    try {
      city = city.trim();
      setSubmitting(true);

      const createNewEventCallable = functions.httpsCallable(
        'createNewEventCallable'
      );
      let org_team = {};
      org_team[orgData.data.id] = { _roles: ['player'], ...teams };
      // console.log({
      //   name,
      //   city,
      //   phoneNumber,
      //   org_team,
      //   orgName: orgData.data.title,
      //   ...rest,
      // });
      const createNewEventResult = await createNewEventCallable({
        name,
        city,
        coachLastName: extractLastName(currentUser.data.name),
        phoneNumber,
        org_team,
        orgName: orgData.data.title,
        ...rest,
      });

      setSubmitting(false);
      toast(
        `Event ${
          isCreate === true ? 'created' : 'updated'
        }. Team/group membership for the player will be updated in the background. This may take a minute. You can continue to use the app normally.`,
        { type: 'success' }
      );
      history.push(TABS.COACH_PLAYERS.path);
    } catch (e) {
      handleError({
        error: e,
        internal: 'EventUpdate.js - createEvent',
        toast: e.message.trim(),
      });
      setLoading(false);
    }
  };

  const closeAllModals = () => {
    setShowConfirmDeleteModal(false);
    setShowConfirmAgainDeleteModal(false);
  };

  const deleteEventButton = () => (
    <>
      {!isCreate && _.isString(userId) && userId.length > 1 && (
        <CButton
          size="md"
          color="danger"
          variant="ghost"
          onClick={() => {
            setShowConfirmDeleteModal(true);
          }}
        >
          <FaTrashAlt style={{ marginRight: 6, marginTop: -2 }} size={20} />
          {`Delete Event`}
        </CButton>
      )}
    </>
  );

  const updateEvent = async ({
    name,
    city,
    groups,
    age,
    classYear,
    phoneNumber,
    teams,
    ...rest
  }) => {
    try {
      city = city.trim();
      setSubmitting(true);
      let org_team = {};
      org_team[orgData.data.id] = teams;
      const usersRef = doc(db, 'users', userId);
      // const selectedGroupsIds = groups.map((group) => group.value);
      let update = {
        ...rest,
        city,
        name,
        org_team,
        age: (age || {}).value,
        classYear: (classYear || {}).value,
        // type: [type.value],
        // groups: selectedGroupsIds,
      };
      let updateUserData = { name, city, id: userId };
      phoneNumber = sanitizePhoneNumber(phoneNumber);
      if (!!phoneNumber) {
        update = { ...update, phoneNumber };
        updateUserData = { ...updateUserData, phoneNumber };
      }
      update = _.pickBy(update || {}, (value, key) => !key.startsWith('_'));
      update = _.pickBy(update, _.identity);
      await updateDoc(usersRef, update);
      const updateUser = functions.httpsCallable('updateUser');
      await updateUser(updateUserData);

      setSubmitting(false);
      history.push(TABS.COACH_PLAYERS.path);
    } catch (e) {
      handleError({
        error: e,
        internal: 'EventUpdate.js - updateEvent',
      });
      setLoading(false);
    }
  };

  return (
    <EventsWrapper>
      <ConfirmModal
        show={showConfirmDeleteModal && !showConfirmAgainDeleteModal}
        title="Confirm Delete"
        message="Are you sure you would like to delete this event? THIS CANNOT BE UNDONE."
        okButtonColor="danger"
        cancelButtonColor="primary"
        okButtonText="Delete"
        onClickCancel={closeAllModals}
        onClickConfirm={async () => {
          setShowConfirmDeleteModal(false);
          setShowConfirmAgainDeleteModal(true);
        }}
      />
      <ConfirmModal
        show={showConfirmAgainDeleteModal}
        title="Re-Confirm Delete"
        message="Are you sure you would like to delete this event? THIS IS YOUR LAST WARNING. The event will be deleted and this cannot be undone."
        okButtonColor="danger"
        cancelButtonColor="primary"
        okButtonText="Delete"
        onClickCancel={closeAllModals}
        onClickConfirm={async () => {
          setShowConfirmAgainDeleteModal(false);
          toast(
            'Event being deleted. It may take a minute for the event to be removed.',
            { type: 'success' }
          );
          history.push(TABS.EVENT_EVENTS.path);
          let deleteUserFromOrgCallable = functions.httpsCallable(
            'deleteUserFromOrgCallable'
          );
          let deleteResult = await deleteUserFromOrgCallable({
            userId,
            organizationId: orgData.data.id,
          });
        }}
      />
      <CRow>
        <CCol lg={12}>
          <CCard>
            <HeaderWithBackButton
              rightSide={isCreate ? null : deleteEventButton()}
            >
              <div>{`${isCreate ? 'Create' : 'Edit'} Event`}</div>
            </HeaderWithBackButton>

            <CCardBody>
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div className="sk-bounce">
                    <div className="sk-bounce-dot"></div>
                    <div className="sk-bounce-dot"></div>
                  </div>
                </div>
              ) : (
                <Formik
                  enableReinitialize
                  onSubmit={onSubmit}
                  innerRef={formikRef}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    isValid,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <h2>Event Details</h2>
                      <CFormGroup row>
                        <CCol xs="2">
                          <CLabel htmlFor="title">
                            Name <span className="text-danger">*</span>
                          </CLabel>
                          {touched.name === true && !!errors.name && (
                            <CLabel className="event-update-error-label">
                              {errors.name}
                            </CLabel>
                          )}
                        </CCol>

                        <CCol xs="10">
                          <CInput
                            required
                            id="name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{
                              border:
                                touched.name === true && !!errors.name
                                  ? '1px solid red'
                                  : '',
                            }}
                          />
                        </CCol>
                      </CFormGroup>

                      <CFormGroup row>
                        <CCol xs="2">
                          <CLabel htmlFor="title">
                            City <span className="text-danger">*</span>
                          </CLabel>
                          {touched.city === true && !!errors.city && (
                            <CLabel className="event-update-error-label">
                              {errors.city}
                            </CLabel>
                          )}
                        </CCol>

                        <CCol xs="10">
                          <CInput
                            required
                            id="city"
                            value={values.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{
                              border:
                                touched.city === true && !!errors.city
                                  ? '1px solid red'
                                  : '',
                            }}
                          />
                        </CCol>
                      </CFormGroup>
                      <CFormGroup row>
                        <CCol xs="2">
                          <CLabel htmlFor="password">Code (optional)</CLabel>
                        </CCol>

                        <CCol xs="10">
                          <CInput
                            id="password"
                            value={values.password}
                            onChange={(p) => {
                              setFieldValue(
                                'password',
                                p.target.value.toString().trim().toUpperCase()
                              );
                            }}
                            onBlur={handleBlur}
                          />
                        </CCol>
                      </CFormGroup>
                      <CFormGroup row>
                        <CCol xs="2">
                          <CLabel htmlFor="dateRangeSection">
                            Dates of Event
                          </CLabel>
                        </CCol>
                        <CCol xs="5">
                          <div>
                            <label htmlFor="dateRange">
                              Event Dates (for players to choose correct event)
                            </label>
                          </div>
                          <DateRange
                            editableDateInputs={true}
                            onChange={({ selection }) => {
                              setFieldValue('dateRange', [selection]);
                            }}
                            moveRangeOnFirstSelection={false}
                            ranges={values.dateRange}
                            color="#162630"
                            rangeColors={['#162630']}
                          />
                        </CCol>
                        <CCol xs="5">
                          <div>
                            <label htmlFor="visibleDates">
                              Dates event will be visible on 6Tool app
                            </label>
                          </div>
                          <DateRange
                            editableDateInputs={true}
                            onChange={({ selection }) => {
                              setFieldValue('visibleDates', [selection]);
                            }}
                            moveRangeOnFirstSelection={false}
                            ranges={values.visibleDates}
                            color="#162630"
                            rangeColors={['#162630']}
                          />
                        </CCol>
                      </CFormGroup>
                      <CFormGroup row>
                        <CCol xs="2">
                          <CLabel htmlFor="hideIndividualLeaderboard">
                            Show individual leaderboard in App?
                          </CLabel>
                        </CCol>
                        <CCol xs="10">
                          <Toggle
                            value={values.hideIndividualLeaderboard === false}
                            onToggle={(newValue) => {
                              setFieldValue(
                                'hideIndividualLeaderboard',
                                !newValue
                              );
                            }}
                          />
                        </CCol>
                      </CFormGroup>
                      <CFormGroup row>
                        <CCol xs="2">
                          <CLabel htmlFor="hideTeamLeaderboard">
                            Show team leaderboard in App?
                          </CLabel>
                        </CCol>
                        <CCol xs="10">
                          <Toggle
                            value={values.hideTeamLeaderboard === false}
                            onToggle={(newValue) => {
                              setFieldValue('hideTeamLeaderboard', !newValue);
                            }}
                          />
                        </CCol>
                      </CFormGroup>
                      <CFormGroup row>
                        <CCol xs="2">
                          <CLabel htmlFor="hideLeaderboardCounts">
                            Show leaderboard counts in App?
                          </CLabel>
                        </CCol>
                        <CCol xs="10">
                          <Toggle
                            value={values.hideLeaderboardCounts === false}
                            onToggle={(newValue) => {
                              setFieldValue('hideLeaderboardCounts', !newValue);
                            }}
                          />
                        </CCol>
                      </CFormGroup>

                      <CRow>
                        <CCol xs="12" className="text-right pt-3">
                          {isSubmitting ? (
                            <LoadingMessage
                              firstMessage={`${
                                isCreate ? 'Creating' : 'Updating'
                              } event...`}
                              style={{
                                justifyContent: 'flex-end',
                                height: 53,
                              }}
                            />
                          ) : (
                            <>
                              <CButton
                                className="mr-2"
                                size="md"
                                color="danger"
                                variant="outline"
                                onClick={() => {
                                  history.push(TABS.COACH_PLAYERS.path);
                                }}
                              >
                                Cancel
                              </CButton>
                              <CButton
                                disabled={
                                  (isCreate && !isValid) ||
                                  (!isCreate &&
                                    _.isEqual(initialValues, values))
                                }
                                size="md"
                                type="submit"
                                color="primary"
                              >
                                {`${isCreate ? 'Create' : 'Update'} Event`}
                              </CButton>
                            </>
                          )}
                        </CCol>
                      </CRow>
                    </Form>
                  )}
                </Formik>
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </EventsWrapper>
  );
}

export default EventUpdate;
