import {
  CButton,
  CCard,
  CCardFooter,
  CCol,
  CDataTable,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTooltip,
} from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, Form as BootstrapForm } from 'react-bootstrap';
import { db, functions, storage } from '../../..';
import { useUploadFile } from 'react-firebase-hooks/storage';
import { fileTimestamp, joinWithCommas } from '../../../helpers/utils';
import { extractLastName } from '../../../helpers/data';
import { handleError } from '../../../helpers/errors';
import { TABS } from '../../../AppTabs';
import LoadingMessage from '../../../r-components/LoadingMessage/LoadingMessage';
import HeaderWithBackButton from '../../../r-components/HeaderWithBackButton/HeaderWithBackButton';
import { FiAlertCircle } from 'react-icons/fi';
import { FaCheckSquare, FaClipboard, FaRegSquare } from 'react-icons/fa';
import { GiWhistle } from 'react-icons/gi';
import FlexSpacer from '../../../r-components/FlexSpacer/FlexSpacer';
import ConfirmModal from '../../../r-components/ConfirmModal/ConfirmModal';
import { storageRefForLogo } from '../../../helpers/storage';
import {
  ACCESS_ACL,
  ACCESS_ACTIONS,
  checkAccess,
} from '../../../helpers/access';
import useAccountSelectors from '../../../hooks/useAccountSelectors';

// const storage = getStorage(firebaseApp);

const secondFormValidationSchema = Yup.object().shape({
  newCoachName: Yup.string().required('Name is required'),
  newCoachEmail: Yup.string()
    .trim()
    .email('Invalid email')
    .required('Email is required'),
});
const iValues = {
  name: '',
  image: null,
  state: '',
  city: '',
  coaches: [],
  assistants: [],
  players: [],
};

const iNewCoachDetails = {
  name: '',
  email: '',
  isCreating: false,
};

const NEW_PREFIX = '_new';

const TeamCreate = () => {
  let history = useHistory();
  const [initialValues, setInitialValues] = useState(iValues);
  const [imageUrl, setImageUrl] = useState('');
  const [teamId, setTeamId] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [showingPlayers, setShowingPlayers] = useState(false); //this defaults to showing coaches if players is false. If true, coaches are hidden
  const [newCoachDetails, setNewCoachDetails] = useState(iNewCoachDetails);
  const [newlyAddedCoaches, setNewlyAddedCoaches] = useState([]);
  const [submittingNewCoach, setSubmittingNewCoach] = useState(false);
  const [confirmLoseNewCreatedCoach, setConfirmLoseNewCreatedCoach] =
    useState(0);
  const { type, tier, showSubscriptionModal, goToUrl, numTeams } =
    useAccountSelectors();

  const orgPlayersData = useSelector(
    (state) => state.DataOrganizationState.players
  );
  const orgCoachesData = useSelector(
    (state) => state.DataOrganizationState.coaches
  );

  const organizationData = useSelector(
    (state) => state?.DataOrganizationState?.data || {}
  );
  const userData = useSelector((state) => state?.UserState || {});
  const [uploadFile, uploading, snapshot, error] = useUploadFile();

  const [logoPreview, setLogoPreview] = useState('');

  const handleInputChange = (event, setFieldValue) => {
    const { name, value } = event.target;
    if (name === 'state') {
      setFieldValue(name, value.toUpperCase(), true);
    } else if (name === 'city') {
      setFieldValue(name, value.charAt(0).toUpperCase() + value.slice(1), true);
    } else {
      setFieldValue(name, value, true);
    }
  };

  const submitCreateNewCoach = ({ name, email }) => {
    setNewlyAddedCoaches([
      ...newlyAddedCoaches,
      {
        name: name,
        email: email.trim(),
        _id: `${NEW_PREFIX}${(newlyAddedCoaches || []).length}`,
      },
    ]);
    toast(
      "New coach added to list of options. The user will not be created until you've added them to this team.",
      { type: 'success' }
    );
    setNewCoachDetails(iNewCoachDetails); // this closes modal, too
  };
  /*
  const submitCreateNewCoach = async () => {
    setSubmittingNewCoach(true);
    try {
      const createNewCoachUserCallable = functions.httpsCallable(
        'createNewCoachUserCallable'
      );
      let org_team = {};
      org_team[organizationData.id] = { a: { roles: ['coach'] } };
      const createNewCoachResult = await createNewCoachUserCallable({
        name: newCoachDetails.name,
        email: newCoachDetails.email.trim(),
        organization_id: organizationData.id,
        org_team,
        orgName: organizationData.title,
      });
      if (
        _.isString(createNewCoachResult?.data) &&
        createNewCoachResult.data.length > 0
      ) {
        setSubmittingNewCoach(false);
        setNewCoachDetails(iNewCoachDetails);
        toast(
          'New Coach Created. This may take a moment for the system to load, please be patient for the coach to show up in the team list',
          { type: 'success' }
        );
      } else {
        throw new Error('Error creating coach. Please try again.');
      }
    } catch (e) {
      handleError({
        error: e,
        internal: 'TeamCreate - createCoach',
      });
      toast(e.message, { type: 'error' });
      setSubmittingNewCoach(false);
    }
  };
*/

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required('Name is required'),
    city: Yup.string().trim().required('City is required'),
    state: Yup.string().trim().required('State is required'),
    coaches: Yup.array()
      .min(1, 'A head coach is required')
      .test(
        'sum-of-array-length',
        `Maximum ${
          ACCESS_ACL[tier]?.[ACCESS_ACTIONS.CREATE_COACH].threshold
        } coaches per team`,
        function (value) {
          const { assistants } = this.parent;
          const totalLength = value.length + assistants.length;
          return (
            totalLength <=
            ACCESS_ACL[tier]?.[ACCESS_ACTIONS.CREATE_COACH].threshold
          );
        }
      ),
    assistants: Yup.array().test(
      'sum-of-array-length',
      `Maximum ${
        ACCESS_ACL[tier]?.[ACCESS_ACTIONS.CREATE_COACH].threshold
      } coaches per team`,
      function (value) {
        const { coaches } = this.parent;
        const totalLength = value.length + coaches.length;
        return (
          totalLength <=
          ACCESS_ACL[tier]?.[ACCESS_ACTIONS.CREATE_COACH].threshold
        );
      }
    ),
  });
  const handleImageChange = async (event) => {
    try {
      let now = fileTimestamp();
      const file = event.target.files[0];
      let storageRef = storageRefForLogo({
        organization_id: organizationData._id,
        team_id: teamId,
        fileName: file.name,
      });
      const result = await uploadFile(storageRef, file, {
        contentType: file.type,
      });
      let downloadUrl = await storageRef.getDownloadURL();
      setImageUrl(downloadUrl);
      let _objectURL = URL.createObjectURL(file);
      setLogoPreview(_objectURL);
      try {
        (async () => {
          let width = 0;
          let height = 0;

          // Set up the FileReader onload event
          const element = new Image();

          // Set up the element onload event
          element.onload = () => {
            // Access the dimensions using the element's naturalWidth and naturalHeight properties
            width = element.naturalWidth;
            height = element.naturalHeight;

            // Do something with the dimensions
            const addMediaCallable =
              functions.httpsCallable('addMediaCallable');
            addMediaCallable({
              downloadUrl,
              organizationId: organizationData._id,
              teamId,
              width,
              height,
              usage: 'team_logo',
            });
          };

          // Assign the FileReader result to the element's src
          element.src = _objectURL;
        })();
      } catch (err) {
        handleError({
          error: err,
          internal: 'TeamCreate.js - handleImageChange',
        });
      }
    } catch (uploadErr) {
      handleError({
        error: uploadErr,
        internal: 'TeamCreate.js - uploadErr',
        toast: 'There was an error uploading file',
      });
    }
  };

  useEffect(() => {
    let newDocRef = db.collection('teams').doc();
    setTeamId(newDocRef.id);
  }, []);

  const handleSubmit = async (values) => {
    checkAccess({
      action: ACCESS_ACTIONS.CREATE_TEAM,
      type,
      tier,
      thresholdCount: numTeams + 1,
      showSubscriptionModal,
    }).doWithAccess(async () => {
      if (uploading) {
        toast('File is still uploading, please try again', { type: 'info' });
        return false;
      }
      setSubmitting(true);
      let _newlyCreatedCoaches = [];
      let _newlyCreatedAssistants = [];
      if (_.isArray(newlyAddedCoaches) && newlyAddedCoaches.length > 0) {
        const _newlyAddedAdded = newlyAddedCoaches.filter(
          (c) =>
            (values.coaches || []).includes(c._id) ||
            (values.assistants || []).includes(c._id)
        );
        if ((_newlyAddedAdded || []).length < newlyAddedCoaches.length) {
          setConfirmLoseNewCreatedCoach(
            newlyAddedCoaches.length - (_newlyAddedAdded || []).length
          );
          setSubmitting(false);
          return false;
        }
        // we have all coaches added to team
        try {
          const createNewCoachUserCallable = functions.httpsCallable(
            'createNewCoachUserCallable'
          );
          let _newlyAddedCoaches = [...newlyAddedCoaches];
          for (let index = 0; index < _newlyAddedCoaches.length; index++) {
            const newCoachDetails = _newlyAddedCoaches[index];
            let org_team = {};
            let teamUpdate = {};
            teamUpdate[teamId] = { roles: ['coach'] };
            org_team[organizationData.id] = teamUpdate;
            const createNewCoachResult = await createNewCoachUserCallable({
              name: newCoachDetails.name,
              email: newCoachDetails.email.trim(),
              coachLastName: extractLastName(userData.data.name),
              organization_id: organizationData.id,
              org_team,
              orgName: organizationData.title,
            });
            if (
              _.isString(createNewCoachResult?.data) &&
              createNewCoachResult.data.length > 0
            ) {
              // newCoachDetails._id = createNewCoachResult.data;
              if (values.coaches.includes(newCoachDetails._id)) {
                _newlyCreatedCoaches.push(createNewCoachResult.data);
              } else {
                _newlyCreatedAssistants.push(createNewCoachResult.data);
              }
            }
          }
        } catch (e) {
          handleError({
            error: e,
            internal: 'TeamCreate - createCoach',
          });
          toast(e.message, { type: 'error' });
          setSubmittingNewCoach(false);
        }
      }
      try {
        const createTeamCallable =
          functions.httpsCallable('createTeamCallable');
        const createTeamResponse = await createTeamCallable({
          _id: teamId,
          active: true,
          coaches: [
            ...values.coaches.filter((c_id) => !c_id.startsWith(NEW_PREFIX)),
            ..._newlyCreatedCoaches,
          ],
          assistants: [
            ...values.assistants.filter((c_id) => !c_id.startsWith(NEW_PREFIX)),
            ..._newlyCreatedAssistants,
          ],
          players: values.players,
          imageUrl,
          name: values.name,
          location: { city: values.city, state: values.state },
          organizationId: organizationData._id,
          owner: {
            _id: userData?.data?._id || '',
            email: userData?.data?.email || '',
            name: userData?.data?.name || '',
          },
          sport: 'Baseball',
        });
        if (createTeamResponse.data === true) {
          toast(
            'Team created. Setting up team, this may take a moment before it shows up in your account.',
            {
              type: 'success',
            }
          );
          history.push(TABS.COACH_TEAMS.path);
        } else {
          handleError({
            error,
            internal: 'TeamEdit.js - handleSubmit',
            toast: 'Error creating team',
          });
        }
      } catch (error) {
        handleError({
          error,
          internal: 'TeamEdit.js - handleSubmit',
          toast: `Error creating team - ${error.message}`,
        });
      } finally {
        setSubmitting(false);
      }
    });
  };

  return (
    <Container className="space-1 coach-team-view">
      {userData?.isOwner === true ? (
        <>
          <HeaderWithBackButton>
            <div className="coach-team-view-org-header">
              <h1 className="m-0">Create Team</h1>
            </div>
          </HeaderWithBackButton>

          <CRow style={{ width: '100%', margin: '20px 0 0 0' }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
              validateOnChange
              validateOnBlur
              validateOnMount
            >
              {({
                errors,
                touched,
                setFieldValue,
                values,
                isValid,
                validateField,
              }) => (
                <Form style={{ width: '100%' }}>
                  <CCard style={{ padding: 20 }}>
                    <CRow
                      style={{ minHeight: 400, height: 'calc(100vh - 580px)' }}
                    >
                      <CCol lg="4">
                        <h3>Team Details</h3>
                        <BootstrapForm.Group>
                          <BootstrapForm.Label>Team Name</BootstrapForm.Label>
                          <Field
                            name="name"
                            type="text"
                            as={BootstrapForm.Control}
                            isInvalid={!!errors.name && touched.name}
                          />
                          <ErrorMessage
                            name="name"
                            component={BootstrapForm.Control.Feedback}
                            type="invalid"
                          />
                        </BootstrapForm.Group>
                        <div
                          style={{
                            display: 'flex',
                            gap: 10,
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <BootstrapForm.Group>
                              <BootstrapForm.Label>City</BootstrapForm.Label>
                              <Field
                                name="city"
                                type="text"
                                autocomplete="city"
                                as={BootstrapForm.Control}
                                isInvalid={!!errors.city && touched.city}
                                onChange={(event) =>
                                  handleInputChange(event, setFieldValue)
                                }
                              />
                              <ErrorMessage
                                name="city"
                                component={BootstrapForm.Control.Feedback}
                                type="invalid"
                              />
                            </BootstrapForm.Group>
                          </div>
                          <div style={{ width: 150 }}>
                            <BootstrapForm.Group>
                              <BootstrapForm.Label>State</BootstrapForm.Label>
                              <Field
                                name="state"
                                type="text"
                                autocomplete="state"
                                as={BootstrapForm.Control}
                                isInvalid={!!errors.state && touched.state}
                                onChange={(event) =>
                                  handleInputChange(event, setFieldValue)
                                }
                              />
                              <ErrorMessage
                                name="state"
                                component={BootstrapForm.Control.Feedback}
                                type="invalid"
                              />
                            </BootstrapForm.Group>
                          </div>
                        </div>

                        <BootstrapForm.Group>
                          <BootstrapForm.Label>Image</BootstrapForm.Label>
                          <BootstrapForm.File
                            id="image"
                            name="image"
                            accept="image/*"
                            onChange={(event) => {
                              handleImageChange(event);
                              // event.target.files.length > 0 &&
                              //   event.currentTarget.nextSibling.classList.add(
                              //     'selected'
                              //   );
                              // event.currentTarget.nextSibling.textContent =
                              //   event.target.files[0].name;
                            }}
                            isInvalid={!!errors.image && touched.image}
                            feedback={errors.image}
                            style={{ display: 'block' }}
                          />
                          {uploading && <p>Uploading...</p>}
                          {logoPreview && (
                            <img
                              src={logoPreview}
                              alt="Logo Preview"
                              className="img-fluid mt-2"
                              style={{
                                width: '200px',
                                height: 'auto',
                                objectFit: 'contain',
                              }}
                            />
                          )}
                          <ErrorMessage
                            name="image"
                            component={BootstrapForm.Control.Feedback}
                            type="invalid"
                          />
                        </BootstrapForm.Group>
                      </CCol>
                      <CCol
                        lg="8"
                        style={{
                          height: '100%',
                          overflow: 'scroll',
                          paddingBottom: 30,
                        }}
                      >
                        <div
                          style={{
                            cursor: showingPlayers ? 'pointer' : 'default',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                          onClick={
                            showingPlayers
                              ? () => {
                                  setShowingPlayers(!showingPlayers);
                                }
                              : null
                          }
                        >
                          <h3
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setShowingPlayers(!showingPlayers);
                            }}
                          >
                            Coaches
                          </h3>
                          <div
                            style={{
                              marginLeft: 10,
                              marginTop: -3,
                              fontSize: 14,
                            }}
                          >
                            (a head coach is required to create team)
                          </div>
                          <FlexSpacer />
                          {showingPlayers ? (
                            <div>Click to expand coaches section</div>
                          ) : (
                            <h3>Role on Team</h3>
                          )}
                        </div>
                        {!showingPlayers && (
                          <CDataTable
                            striped
                            items={[
                              ...orgCoachesData,
                              ...newlyAddedCoaches,
                              {
                                name: 'add',
                                _id: 'add',
                                order: 1,
                              },
                            ]}
                            itemsPerPageSelect={false}
                            itemsPerPage={100}
                            pagination={false}
                            header={false}
                            hover
                            sorter
                            sorterValue={[
                              { column: 'name', asc: true },
                              { column: 'order', asc: true },
                            ]}
                            fields={['name', 'actions']}
                            noItemsViewSlot={
                              <p className="d-flex align-content-center justify-content-center pt-4 pb-4">
                                <FiAlertCircle size="30" color="#e55353" />
                                <span className="pl-2 font-xl">
                                  No coaches yet in organization
                                </span>
                              </p>
                            }
                            scopedSlots={{
                              name: (coach) => {
                                if (coach.name === 'add' && coach.order === 1) {
                                  return (
                                    <td>
                                      <CButton
                                        variant="outline"
                                        color="primary"
                                        onClick={() => {
                                          setNewCoachDetails({
                                            ...newCoachDetails,
                                            ...{ isCreating: true },
                                          });
                                        }}
                                      >
                                        Create New Coach
                                      </CButton>
                                    </td>
                                  );
                                }
                                return (
                                  <td className="font-weight-bold">
                                    {coach.name}
                                  </td>
                                );
                              },
                              email: (coach) => {
                                if (coach.name === 'add' && coach.order === 1) {
                                  return <td></td>;
                                }
                                return (
                                  <td className="font-weight-bold">
                                    {coach.email}
                                  </td>
                                );
                              },
                              actions: (coach) => {
                                if (coach.name === 'add' && coach.order === 1) {
                                  return <td></td>;
                                }
                                return (
                                  <td
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      justifyContent: 'flex-end',
                                      gap: 20,
                                    }}
                                  >
                                    <CTooltip
                                      content={`Make ${
                                        coach?.name || ''
                                      } a head coach`}
                                    >
                                      <div>
                                        {values?.coaches?.includes(
                                          coach._id
                                        ) ? (
                                          <div
                                            onClick={() => {
                                              setFieldValue(
                                                'coaches',
                                                _.pullAll(values.coaches, [
                                                  coach._id,
                                                ]),
                                                true
                                              )
                                                .then((e) => {
                                                  validateField('coaches');
                                                })
                                                .catch((err) => {
                                                  console.error(err);
                                                });
                                            }}
                                          >
                                            <FaCheckSquare
                                              size={30}
                                              color="#f6d30d"
                                            />
                                            <GiWhistle
                                              size={34}
                                              color="#f6d30d"
                                            />
                                          </div>
                                        ) : (
                                          <div
                                            onClick={() => {
                                              setFieldValue(
                                                'coaches',
                                                [
                                                  ...new Set([
                                                    ...values.coaches,
                                                    ...[coach._id],
                                                  ]),
                                                ],
                                                true
                                              ).then((e) => {
                                                validateField('coaches');
                                              });
                                              setFieldValue(
                                                'assistants',
                                                _.pullAll(values.assistants, [
                                                  coach._id,
                                                ]),
                                                true
                                              );
                                            }}
                                          >
                                            <FaRegSquare size={30} />
                                            <GiWhistle size={34} />
                                          </div>
                                        )}
                                      </div>
                                    </CTooltip>
                                    <CTooltip
                                      content={`Make ${
                                        coach?.name || ''
                                      } an assistant coach`}
                                    >
                                      <div>
                                        {values?.assistants?.includes(
                                          coach._id
                                        ) ? (
                                          <div
                                            onClick={() => {
                                              setFieldValue(
                                                'assistants',
                                                _.pullAll(values.assistants, [
                                                  coach._id,
                                                ]),
                                                true
                                              );
                                            }}
                                          >
                                            <FaCheckSquare
                                              size={30}
                                              color="#f6d30d"
                                            />
                                            <FaClipboard
                                              size={26}
                                              color="#f6d30d"
                                            />
                                          </div>
                                        ) : (
                                          <div
                                            onClick={() => {
                                              setFieldValue(
                                                'assistants',
                                                [
                                                  ...new Set([
                                                    ...values.assistants,
                                                    ...[coach._id],
                                                  ]),
                                                ],
                                                true
                                              );
                                              setFieldValue(
                                                'coaches',
                                                _.pullAll(values.coaches, [
                                                  coach._id,
                                                ]),
                                                true
                                              ).then((e) => {
                                                validateField('coaches');
                                              });
                                            }}
                                          >
                                            <FaRegSquare size={30} />
                                            <FaClipboard size={26} />
                                          </div>
                                        )}
                                      </div>
                                    </CTooltip>
                                  </td>
                                );
                              },
                            }}
                          />
                        )}
                        <div
                          style={{
                            cursor: 'pointer',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: 30,
                          }}
                          onClick={() => {
                            setShowingPlayers(!showingPlayers);
                          }}
                        >
                          <h3>Players</h3>
                          <div
                            style={{
                              marginLeft: 10,
                              marginTop: -3,
                              fontSize: 14,
                            }}
                          >
                            (optional, can add later)
                          </div>
                          <FlexSpacer />
                          {!showingPlayers ? (
                            <div>Click to expand players section</div>
                          ) : (
                            <h3>On Team?</h3>
                          )}
                        </div>
                        {showingPlayers && (
                          <CDataTable
                            striped
                            items={orgPlayersData}
                            itemsPerPageSelect={false}
                            itemsPerPage={100}
                            pagination={false}
                            header={false}
                            hover
                            sorter
                            sorterValue={[{ column: 'name', asc: true }]}
                            fields={['name', 'actions']}
                            noItemsViewSlot={
                              <p className="d-flex align-content-center justify-content-center pt-4 pb-4">
                                <FiAlertCircle size="30" color="#e55353" />
                                <span className="pl-2 font-xl">
                                  No players yet in organization
                                </span>
                              </p>
                            }
                            scopedSlots={{
                              name: (player) => (
                                <td className="font-weight-bold">
                                  {player.name}
                                </td>
                              ),

                              actions: (player) => (
                                <td
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    gap: 20,
                                  }}
                                >
                                  <CTooltip
                                    content={`Add ${
                                      player?.name || ''
                                    } to team`}
                                  >
                                    <div>
                                      {values?.players?.includes(player._id) ? (
                                        <div
                                          onClick={() => {
                                            setFieldValue(
                                              'players',
                                              _.pullAll(values.players, [
                                                player._id,
                                              ])
                                            );
                                          }}
                                        >
                                          <FaCheckSquare
                                            size={30}
                                            color="#f6d30d"
                                          />
                                        </div>
                                      ) : (
                                        <div
                                          onClick={() => {
                                            setFieldValue('players', [
                                              ...new Set([
                                                ...values.players,
                                                ...[player._id],
                                              ]),
                                            ]);
                                          }}
                                        >
                                          <FaRegSquare size={30} />
                                        </div>
                                      )}
                                    </div>
                                  </CTooltip>
                                </td>
                              ),
                            }}
                          />
                        )}
                      </CCol>
                    </CRow>
                    <CCardFooter
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                      }}
                    >
                      {(Object.keys(errors || {}) || []).length > 0 && (
                        <div
                          className="invalid-feedback"
                          style={{ display: 'block' }}
                        >
                          {Object.values(errors)[0]}
                        </div>
                      )}
                      <FlexSpacer />
                      {submitting ? (
                        <LoadingMessage
                          firstMessage="Creating team..."
                          style={{ height: 53, justifyContent: 'flex-start' }}
                        />
                      ) : (
                        <Button type="submit" disabled={!isValid || submitting}>
                          Submit
                        </Button>
                      )}
                    </CCardFooter>
                  </CCard>
                  <ConfirmModal
                    show={confirmLoseNewCreatedCoach > 0}
                    title={`New coach${
                      confirmLoseNewCreatedCoach === 1 ? '' : 'es'
                    } not going to be created`}
                    message={`You created ${confirmLoseNewCreatedCoach} coach${
                      confirmLoseNewCreatedCoach === 1 ? '' : 'es'
                    } on this page, but did not add them to this team. The coach${
                      confirmLoseNewCreatedCoach === 1 ? '' : 'es'
                    } you created will not get created in the system unless you add them to a team. Would you like to continue creating this team without ${
                      confirmLoseNewCreatedCoach === 1
                        ? 'the coach'
                        : 'those coaches'
                    }?`}
                    okButtonColor="danger"
                    cancelButtonColor="primary"
                    okButtonText={`Create team without new coach${
                      confirmLoseNewCreatedCoach === 1 ? '' : 'es'
                    }`}
                    cancelButtonText="Go back"
                    onClickCancel={() => {
                      setConfirmLoseNewCreatedCoach(false);
                    }}
                    onClickConfirm={handleSubmit}
                  />
                </Form>
              )}
            </Formik>
          </CRow>
        </>
      ) : (
        <CRow className={`coach-team-view-org-section`}>
          You do not have access to this view
        </CRow>
      )}
      <CModal
        show={newCoachDetails.isCreating}
        backdrop={true}
        scrollable={false}
        onClose={() => {
          setNewCoachDetails({ ...newCoachDetails, ...{ isCreating: false } });
        }}
      >
        <Formik
          initialValues={{ newCoachName: '', newCoachEmail: '' }}
          validationSchema={secondFormValidationSchema}
          onSubmit={(newCoachValues) => {
            submitCreateNewCoach({
              name: newCoachValues.newCoachName,
              email: newCoachValues.newCoachEmail,
            });
          }}
          validateOnBlur
          validateOnChange
        >
          {({
            errors: newCoachErrors,
            values: newCoachValues,
            isValid: isNewCoachValid,
            handleChange,
            handleBlur,
          }) => (
            <Form>
              <CModalHeader>
                <CModalTitle>Enter Coach Details</CModalTitle>
              </CModalHeader>

              <CModalBody>
                <div className="form-group">
                  <label htmlFor="newCoachName">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="newCoachName"
                    value={newCoachValues.newCoachName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="newCoachEmail">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="newCoachEmail"
                    value={newCoachValues.newCoachEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {!!newCoachErrors.newCoachEmail && (
                    <div
                      style={{ display: 'block' }}
                      className="invalid-feedback"
                    >
                      {newCoachErrors.newCoachEmail}
                    </div>
                  )}
                </div>
                <div style={{ fontSize: 14 }}>
                  Note: This will add the coach to the list on this page so you
                  can give them a role on this team. If you do not choose to add
                  them to this team, the coach will not get created in 6Tool
                  (you can add them later).
                </div>
                <div style={{ fontSize: 14 }}>
                  The coach account will get created in 6Tool and added to the
                  team only once you've marked them as a Head or Assistant
                  Coach.
                </div>
              </CModalBody>

              <CModalFooter>
                {submittingNewCoach ? (
                  <LoadingMessage firstMessage="Creating coach..." />
                ) : (
                  <>
                    <CButton
                      color={'danger'}
                      variant="outline"
                      disabled={submittingNewCoach}
                      onClick={() => {
                        setNewCoachDetails({
                          ...newCoachDetails,
                          ...{ isCreating: false },
                        });
                      }}
                    >
                      {'Cancel'}
                    </CButton>
                    <CButton
                      type="submit"
                      color={'primary'}
                      disabled={
                        newCoachValues.newCoachEmail.length === 0 ||
                        newCoachValues.newCoachName.length === 0 ||
                        !isNewCoachValid ||
                        submittingNewCoach
                      }
                      className="mr-2"
                      // onClick={submitCreateNewCoach}
                    >
                      Create Coach
                    </CButton>
                  </>
                )}
              </CModalFooter>
            </Form>
          )}
        </Formik>
      </CModal>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    teams: state.DataTeamState?.data || [],
  };
};

export default connect(mapStateToProps)(withRouter(TeamCreate));
