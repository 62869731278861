import styled from 'styled-components';

export const Wrapper = styled.div`
  overflow: hidden;
  min-height: 500px;
`;

export const H3 = styled.h3`
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 40px;
  color: #0e0e0e;
  line-height: 50px;
  text-align: center;
  margin: 80px 0 40px;
`;


export const LogosWrapper = styled.div`
  height: 152px;
  overflow: scroll;

  ol {
    list-style: none;
    display: flex;
    flex-direction: row;
    gap: 10px;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 152px;
      height: 152px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
`;
