import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CListGroup,
  CListGroupItem,
  CRow,
  CSpinner,
} from '@coreui/react';
import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { editGroup } from '../../../../actions/groupActionCreator';
import { db } from '../../../../index';
import GroupWrapper from '../../../../pages/component/GroupWrapper';
import DoneButton from '../../../../r-components/DoneButton/DoneButton';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { TABS } from '../../../../AppTabs';
import HeaderWithBackButton from '../../../../r-components/HeaderWithBackButton/HeaderWithBackButton';
import { handleError } from '../../../../helpers/errors';

const GroupDetails = ({ match }) => {
  const dispatch = useDispatch();
  const isMounted = useRef(false);
  const [loading, setLoading] = useState(false);
  const [group, setGroup] = useState({});
  const [groupId, setGroupId] = useState('a');

  useEffect(() => {
    setGroupId(match.params.id || 'a');
  }, [match]);
  useEffect(() => {
    isMounted.current = true;

    const loadData = async () => {
      try {
        setLoading(true);
        const users = [];
        const usersPromise = [];
        const groupResponse = await getDoc(doc(db, 'groups', groupId));

        let allUsers = [];
        if (groupResponse.exists && _.isObject(groupResponse.data())) {
          allUsers = [
            ...(groupResponse.data().coaches || []),
            ...(groupResponse.data().players || []),
          ];
        }
        if (allUsers.length) {
          allUsers.forEach((userId) => {
            usersPromise.push(getDoc(doc(db, 'users', userId)));
          });

          const usersResponse = await Promise.all(usersPromise);
          usersResponse.forEach((user) => {
            if (user.exists) {
              users.push({
                id: user.id,
                ...user.data(),
              });
            }

            if (isMounted.current && users.length === allUsers.length) {
              setGroup({
                id: groupResponse.id,
                ...groupResponse.data(),
                users,
              });
              setLoading(false);
            }
          });
        } else {
          if (isMounted.current) {
            setGroup({
              id: groupResponse.id,
              ...groupResponse.data(),
              users: [],
            });
            setLoading(false);
          }
        }
      } catch (e) {
        handleError({
          error: e,
          internal: 'groupDetail - loadData',
        });
      }
    };

    loadData();

    return () => {
      isMounted.current = false;
    };
  }, [groupId]);

  return (
    <GroupWrapper>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardBody>
              {!loading ? (
                <CCard>
                  <HeaderWithBackButton className="d-flex justify-content-between">
                    <span>Group name: {group.title}</span>
                    <Link
                      to={TABS.COACH_GROUPS_EDIT.pathVar({
                        name: group.title,
                        id: group.id,
                      })}
                    >
                      <FaEdit />
                    </Link>
                  </HeaderWithBackButton>
                  <CCardBody>
                    <h3>Users:</h3>
                    {_.isArray(group.users) && group.users.length > 0 ? (
                      <CListGroup>
                        {group.users.map((user) => (
                          <CListGroupItem key={user.id}>
                            <b>Name: </b> {user.name} <br />
                            <b>Type: </b>{' '}
                            {_.isArray(user._roles) && user._roles.length > 0
                              ? _.first(user._roles)
                              : ''}
                          </CListGroupItem>
                        ))}
                      </CListGroup>
                    ) : (
                      <p>
                        There are no users for this group, you can add them from
                        group section
                      </p>
                    )}
                  </CCardBody>
                </CCard>
              ) : (
                <CCol xl={12} className="text-center pt-2 pb-2">
                  <CSpinner variant="grow" size="lg" />
                </CCol>
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <DoneButton />
    </GroupWrapper>
  );
};

export default GroupDetails;
