import React, { useEffect, useState } from 'react';
import {
  CDataTable,
  CButton,
  CLink,
  CSelect,
  CLabel,
  CRow,
} from '@coreui/react';
import { map } from 'lodash';
import { SUBSCRIPTION_TIER } from '../../config';
import Container from '../../public-components/Container/Container';
import { useDispatch } from 'react-redux';
import {
  clearImpersonateTier,
  impersonateTier,
  impersonateUserID,
} from '../../r-store/impersonate/actions';
import { db } from '../..';
import { FaUserSecret } from 'react-icons/fa';
import './SuperAdminImpersonate.scss';
import { useHistory } from 'react-router-dom';
import { TABS } from '../../AppTabs';
const downloadTemplateText = 'Download template';

function SuperAdminImpersonate() {
  const [coaches, setCoaches] = useState([]);
  const [loadingCoaches, setLoadingCoaches] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    const unsubscribe = db
      .collection('users')
      .where('_roles', 'array-contains-any', ['owner', 'coach', 'assistant'])
      .onSnapshot(
        (snapshot) => {
          const coaches = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setCoaches(coaches);
          setLoadingCoaches(false);
        },
        (error) => {
          console.error('Error fetching coaches: ', error);
          setLoadingCoaches(false);
        }
      );

    return () => unsubscribe();
  }, []);

  return (
    <div className="super-admin-impersonate-wrapper">
      <Container>
        <CLabel>Impersonate Subscription Tier:</CLabel>
        <CSelect
          onChange={(event) => {
            const newTier = event.target.value;
            if (map(SUBSCRIPTION_TIER, 'key').includes(newTier)) {
              dispatch(impersonateTier(newTier));
            } else {
              dispatch(clearImpersonateTier());
            }
          }}
        >
          <option value="none">None</option>
          {map(SUBSCRIPTION_TIER, 'key').map((tier, index) => (
            <option key={index} value={tier}>
              {tier}
            </option>
          ))}
        </CSelect>
        <CLabel style={{ marginTop: 60 }}>Impersonate Coach:</CLabel>
        <CDataTable
          striped
          hover
          items={coaches}
          sorter
          sorterValue={{ column: 'name', asc: true }}
          fields={[
            { key: 'name', label: 'Name' },
            { key: 'email', label: 'Email' },
            { key: 'impersonate', label: '' },
          ]}
          tableFilter={true}
          scopedSlots={{
            impersonate: (coach) => (
              <td>
                <CButton
                  style={{ padding: 0 }}
                  onClick={() => {
                    console.log(coach);
                    dispatch(impersonateUserID(coach.id));
                    history.push(TABS.COACH_QUIZZES.path);
                  }}
                >
                  <FaUserSecret />
                </CButton>
              </td>
            ),
          }}
        />
      </Container>
    </div>
  );
}

export default SuperAdminImpersonate;
