import { Button } from '@coreui/coreui-pro';
import _ from 'lodash';
import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { mimeTypeFromURL } from '../../../helpers/data';
import BallField from '../../BallField/BallField';
import ScoreBoard from '../../ScoreBoard/ScoreBoard';
import './MobileQuestionViewContent.css';

const MobileQuestionViewContent = ({
  question = null,
  files = [],
  sample = true,
  onClose = null,
} = {}) => {
  const displayQuestionOptionText = (opt) => {
    if (_.isString(opt.text) && opt.text.length > 0) {
      return opt.text;
    }
    if (sample === true) {
      return 'Sample option';
    }
    return '';
  };
  const displayTitle = () => {
    if (_.isString(question.title) && question.title.length > 0) {
      return (
        <div className="mobile-question-view-text mobile-question-view-question-text">
          {question.title}
        </div>
      );
    }
    return null;
  };
  const displayText = () => {
    if (_.isString(question.text) && question.text.length > 0) {
      return question.text;
    }
    if (sample === true) {
      return 'Sample question text';
    }
    return '';
  };

  const renderThumbs = () => {
    if ((files || []).length === 0) {
      if (
        !!question.videoUrl &&
        _.isString(question.videoUrl) &&
        question.videoUrl.length > 0
      ) {
        return (
          <div className="thumb" key="video-preview">
            <video
              width="auto"
              height="auto"
              controls
              style={{ maxWidth: '100%' }}
            >
              <source
                src={question.videoUrl}
                type={mimeTypeFromURL(question.videoUrl)}
              />
            </video>
          </div>
        );
      }
      if (
        !!question.imageUrl &&
        _.isString(question.imageUrl) &&
        question.imageUrl.length > 0
      ) {
        return (
          <div className="thumb" key="image-preview">
            <div className="thumb-inner">
              <img src={question.imageUrl} alt="" />
            </div>
          </div>
        );
      }
    } else {
      return files.map((file, index) => {
        return (
          <aside
            key={`${index}`}
            className="thumbs-container"
            style={{ justifyContent: 'center' }}
          >
            <div
              className="thumb"
              style={{ width: '100%', height: 'auto', border: 'none' }}
            >
              {(file.type || '').startsWith('video') ? (
                <video width="auto" height="auto" controls>
                  <source src={file.preview} type={file.type} />
                </video>
              ) : (
                <img
                  src={file.preview}
                  alt=""
                  style={{ width: '100%', height: 'auto' }}
                />
              )}
            </div>
          </aside>
        );
      });
    }
  };

  return (
    <div className="mobile-question-view-content-wrapper">
      <div className="mobile-question-view-content-scroll">
        <div className="mobile-question-view-info-wrapper">
          <div className="mobile-question-view-info-content">
            <div className="mobile-question-view-info-info">
              <div className="mobile-question-view-info-strong">Questions</div>
              <div className="mobile-question-view-info-small">18 / 20</div>
            </div>
          </div>

          <div className="mobile-question-view-info-content last">
            <div className="mobile-question-view-info-info">
              <div className="mobile-question-view-info-strong">Time Left</div>
              <div className="mobile-question-view-info-small">1:23</div>
            </div>
          </div>
        </div>
        <div className="mobile-question-view-progress-bar">
          <div className="mobile-question-view-progress-bar-done"></div>
        </div>
        {(question.showField || question.showScoreboard) && (
          <div
            className={`mobile-question-view-situation-wrapper${
              question.showField ? ' show-field' : ''
            }${question.showScoreboard ? ' show-scoreboard' : ''}`}
          >
            {question.showField && (
              <div className="mobile-question-view-field-wrapper">
                <BallField
                  positionHighlights={(question.positions || []).map(
                    (p) => p.position
                  )}
                  size={90}
                />
                {_.isArray(question.positions) &&
                  question.positions.length == 1 &&
                  !(
                    !question.positions[0].label ||
                    question.positions[0].label.length == 0
                  ) && (
                    <div className="mobile-question-view-situation-position-text">
                      You are {question.positions[0].label}
                    </div>
                  )}
              </div>
            )}
            {question.showScoreboard && (
              <ScoreBoard situation={{ ...question.situation }} />
            )}
          </div>
        )}
        {!!question.image && (
          <img
            className="mobile-question-view-image"
            src={{ uri: question.image }}
          />
        )}
        {renderThumbs()}
        {/* <VideoComponent uri={question.video} /> */}
        {displayTitle()}
        <div className="mobile-question-view-text mobile-question-view-question-text">
          {displayText()}
        </div>
        <div className="mobile-question-view-list">
          {question.options?.map((opt, index) => (
            <div
              className={`mobile-question-view-list-item${
                opt.correct ? ' correct' : ''
              }`}
              key={`key-${index}`}
            >
              <div className="mobile-question-view-text">
                {displayQuestionOptionText(opt)}
              </div>
              {opt.correct && <FaCheck color="#009336" />}
            </div>
          ))}
        </div>
        {/* {renderQuizTimer()} */}
        <div
          className="mobile-question-view-button mobile-question-view-text"
          onClick={onClose}
          style={{ cursor: 'pointer', marginBottom: 50 }}
        >
          Next
        </div>
      </div>
    </div>
  );
};

export default MobileQuestionViewContent;
