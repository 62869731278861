import Container from '../Container/Container';
import React from 'react';
import { H3, LogosWrapper, Wrapper } from './WinningTeamsStyles';

import team10 from '../../assets/team10.png';
import team13 from '../../assets/team13.png';
import team14 from '../../assets/team14.png';
import team4 from '../../assets/team4.png';
import team5 from '../../assets/team5.png';
import team6 from '../../assets/team6.png';
import team7 from '../../assets/team7.png';
import team8 from '../../assets/team8.png';
import team9 from '../../assets/team9.png';

const WinningTeams = () => {
  return (
    <Wrapper>
      <Container>
        <H3>Winning Teams using 6Tool</H3>
      </Container>

      <LogosWrapper>
        <ol>
          <li>
            <img src={team4} />
          </li>
          <li>
            <img src={team5} />
          </li>
          <li>
            <img src={team6} />
          </li>
          <li>
            <img src={team7} />
          </li>
          <li>
            <img src={team8} />
          </li>
          <li>
            <img src={team9} />
          </li>
          <li>
            <img src={team10} />
          </li>
          <li>
            <img src={team13} />
          </li>
          <li>
            <img src={team14} />
          </li>
          <li>
            <img src={team4} />
          </li>
          <li>
            <img src={team5} />
          </li>
          <li>
            <img src={team6} />
          </li>
          <li>
            <img src={team7} />
          </li>
        </ol>
      </LogosWrapper>
    </Wrapper>
  );
};

export default WinningTeams;
