import { SET_CURRENT_SUBSCRIPTION, CLEAR_SUBSCRIPTION } from './constants';

/**
 * Set current user
 */
export function setCurrentSubscription(payload) {
  return {
    type: SET_CURRENT_SUBSCRIPTION,
    payload,
  };
}
export function clearSubscription() {
  return {
    type: CLEAR_SUBSCRIPTION,
  };
}
