import CIcon from '@coreui/icons-react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CLink,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
} from '@coreui/react';
import { useLocation } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { Form as BootstrapForm } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setCurrentUser } from '../../../r-store/user/actions';
import { auth, db } from '../../..';
// import { auth, db } from 'src/utils/firebase';
import * as Yup from 'yup';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { FaEnvelope, FaLock, FaUserLock } from 'react-icons/fa';
import { useAuthState } from 'react-firebase-hooks/auth';
import { TABS } from '../../../AppTabs';
import { toast } from 'react-toastify';
import { handleError } from '../../../helpers/errors';
import _ from 'lodash';
import {
  _sendPasswordResetEmail,
  _signInWithEmailAndPassword,
} from '../../../helpers/authHelper';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const validationSchema = Yup.object().shape({
  email: Yup.string().trim().email().required('Email is required'),
});

const CreatePassword = () => {
  const query = useQuery();
  const email = query.get('email');
  const passwordFlowType = query.get('t');
  const userType =
    query.get('userType') === 'coach' ||
    query.get('userType') === 'assistant' ||
    query.get('userType') === 'owner'
      ? 'coach'
      : 'player';

  const onSubmit = async ({ email }, _) => {
    try {
      await _sendPasswordResetEmail(email);
      toast(`Password reset email sent to ${email}. Check your junk folder.`, {
        type: 'success',
      });
      if (userType === 'coach') {
        window.location.href = `https://${
          process.env.GCLOUD_PROJECT === 'six-tool-staging'
            ? 'six-tool-staging.web.app'
            : '6-tool.com'
        }/login`;
      } else {
        window.location.href = `https://${
          process.env.GCLOUD_PROJECT === 'six-tool-staging'
            ? 'six-tool-staging.web.app'
            : '6-tool.com'
        }/download`;
      }
    } catch (error) {
      handleError({
        error,
        internal: 'CreatePassword.js - send password create/reset from modal',
        toast:
          'There was an error trying to send your reset password email. Please check that your email is correct.',
      });
    }
  };

  return (
    <div
      className="c-app c-default-layout flex-row align-items-center login-background"
      style={{ minHeight: 'calc(100vh - 464px)' }}
    >
      <CContainer>
        <CRow className="justify-content-center pt-8">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <Formik
                    initialValues={{ email }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <h1 style={{ textAlign: 'center' }}>
                          {passwordFlowType === 'c'
                            ? 'Create'
                            : passwordFlowType === 'r'
                            ? 'Reset'
                            : 'Create / Reset'}{' '}
                          Your Password
                        </h1>
                        <CRow>
                          <CCol xs="12">
                            <p>
                              Enter your email and we will email you with
                              instructions to set your password. Please check
                              your junk mail.
                            </p>
                          </CCol>
                        </CRow>
                        <CInputGroup className="mb-3">
                          <CInputGroupPrepend>
                            <CInputGroupText>
                              <FaEnvelope />
                            </CInputGroupText>
                          </CInputGroupPrepend>
                          <CInput
                            className={
                              touched.email && errors.email ? 'is-invalid' : ''
                            }
                            id="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            type="text"
                            placeholder="Email"
                          />
                          {touched.email && errors.email && (
                            <div className="invalid-feedback">
                              {errors.email}
                            </div>
                          )}
                        </CInputGroup>
                        <CRow>
                          <CCol xs="12">
                            <CButton
                              color="primary"
                              type="submit"
                              className="px-4 btn-block"
                              disabled={!!errors.email}
                            >
                              Send{' '}
                              {passwordFlowType === 'c'
                                ? 'Create'
                                : passwordFlowType === 'r'
                                ? 'Reset'
                                : 'Create / Reset'}{' '}
                              Password Email
                            </CButton>
                          </CCol>
                        </CRow>
                        {/* <CRow className="mt-2">
                          <CCol xs="12" style={{ textAlign: 'center' }}>
                            Don't have an account yet?
                            <CLink
                              to={TABS.SIGNUP_CREATE_ACCOUNT.path}
                              label="Forgot Password?"
                              className="ml-2"
                            >
                              Sign Up
                            </CLink>
                          </CCol>
                        </CRow> */}
                      </Form>
                    )}
                  </Formik>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default CreatePassword;
