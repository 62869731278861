import React, { useEffect, useState } from 'react';
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight,
} from 'react-icons/fa';
import {
  Form as BootstrapForm,
  Pagination as BootstrapPagination,
} from 'react-bootstrap';
import './pagination.scss';
import _ from 'lodash';
import { CTooltip } from '@coreui/react';

const Pagination = ({
  numItems = null,
  numPages = 1,
  currentPage = 0,
  setCurrentPage = null,
  title = null,
  style = {},
  itemsPerPage = null,
  setItemsPerPage = null,
  itemsPerPageOptions = [10, 25, 50, 100],
}) => {
  const [_currentPage, _setCurrentPagePrivate] = useState(0);
  const [paginationPageArray, setPaginationPageArray] = useState([]);

  useEffect(() => {
    _setCurrentPagePrivate(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (numPages <= 5) {
      setPaginationPageArray(
        Array.from({ length: numPages }, (_, index) => index)
      );
    } else {
      let newPaginationArray = ['First', 'Prev'];
      if (_currentPage > 0) {
        newPaginationArray.push(0);
      }
      if (_currentPage > 4) {
        newPaginationArray.push('Ellipsis');
      } else if (_currentPage > 4) {
        newPaginationArray.push(1);
      }
      if (_currentPage > 2) {
        newPaginationArray.push(_currentPage - 2);
      }
      if (_currentPage > 1) {
        newPaginationArray.push(_currentPage - 1);
      }
      newPaginationArray.push(_currentPage);
      if (_currentPage < numPages - 2) {
        newPaginationArray.push(_currentPage + 1);
      }
      if (_currentPage < numPages - 3) {
        newPaginationArray.push(_currentPage + 2);
      }
      if (_currentPage < numPages - 4) {
        newPaginationArray.push('Ellipsis');
      } else if (_currentPage < numPages - 4) {
        newPaginationArray.push(numPages - 2);
      }
      if (_currentPage < numPages - 1) {
        newPaginationArray.push(numPages - 1);
      }

      newPaginationArray.push('Next', 'Last');
      setPaginationPageArray(newPaginationArray);
    }
  }, [_currentPage, numPages]);

  const _setCurrentPage = (p) => {
    if (_.isFunction(setCurrentPage)) {
      setCurrentPage(p);
    } else {
      _setCurrentPage(p);
    }
  };
  const renderOptions = () =>
    itemsPerPageOptions.map((index) => (
      <option key={index} value={index}>
        {index}
      </option>
    ));

  const renderArrow = (type) => {
    let BootstrapPaginationComponent = BootstrapPagination.Prev;
    let enabled = true;
    let newPage = 0;
    let tipContent = '';

    switch (type) {
      case 'First':
        BootstrapPaginationComponent = BootstrapPagination.First;
        enabled = _currentPage > 0;
        newPage = 0;
        tipContent = `Go to first page`;
        break;
      case 'Prev':
        BootstrapPaginationComponent = BootstrapPagination.Prev;
        enabled = _currentPage > 0;
        newPage = _currentPage - 1;
        tipContent = `Go to prev. page (${_currentPage})`;
        break;
      case 'Next':
        BootstrapPaginationComponent = BootstrapPagination.Next;
        enabled = _currentPage < numPages - 1;
        newPage = _currentPage + 1;
        tipContent = `Go to next page (${_currentPage + 2})`;
        break;
      case 'Last':
        BootstrapPaginationComponent = BootstrapPagination.Last;
        enabled = _currentPage < numPages - 1;
        newPage = numPages - 1;
        tipContent = `Go to last page (${numPages})`;
        break;
      default:
        return <BootstrapPagination.Item>{type}</BootstrapPagination.Item>;
    }
    return (
      <>
        <BootstrapPaginationComponent
          onClick={() => {
            if (enabled) {
              setCurrentPage(newPage);
            }
          }}
        />
      </>
    );
  };

  return (
    <>
      <div
        className="custom-pagination"
        style={{
          ...style,
        }}
      >
        {!(
          !isNaN(numItems) &&
          !isNaN(itemsPerPage) &&
          numItems <= itemsPerPage
        ) && (
          <>
            {_.isArray(paginationPageArray) && paginationPageArray.length > 0 && (
              <BootstrapPagination>
                {paginationPageArray.map((p, pIdx) => (
                  <React.Fragment key={`pagination-fragment-pIdx-${pIdx}`}>
                    {_.isString(p) ? (
                      <>
                        {p === 'Ellipsis' ? (
                          <BootstrapPagination.Ellipsis disabled />
                        ) : (
                          <>{renderArrow(p)}</>
                        )}
                      </>
                    ) : (
                      <BootstrapPagination.Item
                        onClick={() => {
                          setCurrentPage(p);
                        }}
                        active={_currentPage === p}
                      >
                        {p + 1}
                      </BootstrapPagination.Item>
                    )}
                  </React.Fragment>
                ))}
              </BootstrapPagination>
            )}
          </>
        )}

        {!isNaN(itemsPerPage) &&
          _.isFunction(setItemsPerPage) &&
          _.isArray(itemsPerPageOptions) && (
            <>
              <BootstrapForm.Control
                style={{
                  width: 'auto',
                  lineHeight: 1.382,
                  padding: '4px 0px 4px 4px',
                  fontSize: '1rem',
                  fontFamily: 'AvinerHeavy',
                  marginRight: 4,
                  marginLeft: 20,
                  height: 40,
                }}
                as="select"
                name="teams"
                value={itemsPerPage}
                onChange={(e) => {
                  setItemsPerPage(e.target.value);
                  _setCurrentPage(0);
                }}
              >
                {renderOptions()}
              </BootstrapForm.Control>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexWrap: 'wrap',
                  height: 40,
                  columnGap: 4,
                  width: 'auto',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    fontSize: '1rem',
                    lineHeight: '1rem',
                    fontFamily: 'AvinerHeavy',
                  }}
                >
                  per
                </div>
                <div
                  style={{
                    fontSize: '1rem',
                    lineHeight: '1rem',
                    fontFamily: 'AvinerHeavy',
                  }}
                >
                  page
                </div>
              </div>
            </>
          )}
      </div>
    </>
  );
};

export default Pagination;
