import produce from 'immer';

import {
  CLEAR_TEAM,
  SET_CURRENT_TEAM,
  SET_TEAMS,
  // SET_PLAYERS,
  // SET_USER_SELECTED_TEAM,
  // SET_USER_TEAMS,
  // UPDATE_PLAYERS,
  // UPDATE_USER_SELECTED_TEAM,
} from './constants';
import _, { isArray, omit } from 'lodash';
import { ACCESS_ACL, ACCESS_ACTIONS } from '../../helpers/access';
import { SUBSCRIPTION_TIER } from '../../config';
import moment from 'moment';
import firebase from 'firebase/compat/app';

const INITIAL_STATE = {
  data: [],
  teamData: {},
  // isLoggedIn: true,
  // isHeadCoach: false,
  // isOwner: false,
};

export const teamReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    let map;
    let teamData;
    let tier;
    switch (action.type) {
      case SET_TEAMS:
        // if (_.isNil(state.teamData)) {
        //   return {
        //     ...state,
        //     ...action.payload,
        //     ...{
        //       teamData:
        //         _.isArray(action?.payload?.data) &&
        //         action.payload.data.length > 0
        //           ? action.payload.data[0]
        //           : null,
        //     },
        //   };
        // }
        teamData = state?.teamData || {};
        tier = action?.payload?.tier || SUBSCRIPTION_TIER.FREE.key;
        if (_.isArray(action.payload.data) && action.payload.data.length > 0) {
          map = action.payload.data.reduce((acc, cur) => {
            const countCoaches = (cur.coaches || []).length;
            const countPlayers = (cur.players || []).length;
            const countGroups = (cur.groups || []).length;
            const maxCoaches =
              cur.maxCoachesPerTeam ||
              ACCESS_ACL[tier]?.[ACCESS_ACTIONS.CREATE_COACH].threshold;
            const maxPlayers =
              cur.maxPlayersPerTeam ||
              ACCESS_ACL[tier]?.[ACCESS_ACTIONS.CREATE_PLAYER].threshold;
            const maxGroups =
              cur.maxGroupsPerTeam ||
              ACCESS_ACL[tier]?.[ACCESS_ACTIONS.CREATE_GROUP].threshold;

            const maxQuizzes =
              ACCESS_ACL[tier]?.[ACCESS_ACTIONS.CREATE_AUTO_QUIZ].threshold;
            acc[cur._id] = {
              ..._.pick(cur, ['name', 'imageUrl']),
              countCoaches,
              countPlayers,
              countGroups,
              maxCoaches,
              maxPlayers,
              maxGroups,
              maxxedCoaches: countCoaches >= maxCoaches,
              maxxedPlayers: countPlayers >= maxPlayers,
              maxxedGroups: countGroups >= maxGroups,
              maxQuizzes,
            };
            if (
              isArray(action.payload.quizData) &&
              action.payload.quizData.length > 0
            ) {
              const teamQuizzes = action.payload.quizData.filter((q) => {
                q.teamId === cur._id;
              });
              const { threshold, thresholdTimeInterval, thresholdTimePeriods } =
                ACCESS_ACL[action.payload.tier || SUBSCRIPTION_TIER.FREE.key][
                  ACCESS_ACTIONS.CREATE_AUTO_QUIZ
                ];
              const thresholdTimeFrame = moment()
                .endOf(thresholdTimeInterval)
                .subtract(thresholdTimePeriods, thresholdTimeInterval);
              const quizzesInThreshold = action.payload.quizData.filter(
                (q) =>
                  q.created_at >
                  firebase.firestore.Timestamp.fromDate(
                    thresholdTimeFrame.toDate()
                  )
              );

              const autoQuizzesInThreshold = action.payload.quizData
                .filter((q) => q.quizType === 'auto')
                .filter(
                  (q) =>
                    q.created_at >
                    firebase.firestore.Timestamp.fromDate(
                      thresholdTimeFrame.toDate()
                    )
                );
              acc[cur._id].countQuizzes = (quizzesInThreshold || []).length;
              acc[cur._id].countAutoQuizzes = (
                autoQuizzesInThreshold || []
              ).length;
              acc[cur._id].maxxedQuizzes =
                (quizzesInThreshold || []).length >= maxQuizzes;
            }
            return acc;
          }, {});
          if (action.payload.data.length === 1) {
            teamData = action.payload.data[0];
          }
        }

        return {
          ...state,
          ...{ map },
          ...{ teamData },
          ...omit(action.payload, [
            'quizData',
            'thresholdTimeInterval',
            'thresholdTimePeriods',
            'tier',
          ]),
        };
      case SET_CURRENT_TEAM:
        if (_.isArray(state.data) && state.data.length === 1) {
          return { ...state, teamData: state.data[0] };
        }
        return {
          ...state,
          ...{
            teamData:
              (state.data || []).find((t) => t._id === action.payload) || null,
          },
        };
      /*
      case SET_USER_TEAMS:
        return {
          ...state,
          team: {
            ...action.payload,
          },
        };

      case SET_USER_SELECTED_TEAM:
        return {
          ...state,
          selectedteam: action.payload,
        };

      case UPDATE_USER_SELECTED_TEAM:
        return {
          ...state,
          selectedteam: action.payload,
        };

      case SET_PLAYERS:
        return {
          ...state,
          players: [...action.payload],
        };

      case UPDATE_PLAYERS:
        return {
          ...state,
          players: [...state.players, action.payload],
        };
*/
      case CLEAR_TEAM:
        return {
          ...INITIAL_STATE,
        };

      default:
        return state;
    }
  });
