import Logo from '../../assets/logo.png';
import { Button, Content, Wrapper } from './HeaderStyles';
import React from 'react';

import Container from '../Container/Container';

const Header = () => {
  return (
    <Wrapper>
      <Container>
        <Content>
          <img src={Logo} />
          <ul>
            <li>
              <a href="#">Team</a>
            </li>
            <li>
              <a href="#">Why 6Tool</a>
            </li>
            <li>
              <a href="#">pricing</a>
            </li>
            <li>
              <a href="#">faqs</a>
            </li>

            <li>
              <Button variant="outline">sign up</Button>
            </li>
            <li>
              <Button variant="filled">login</Button>
            </li>
          </ul>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default Header;
