import styled from 'styled-components';
import JumbotronBackground from '../../assets/bg-jumbotron.png';

export const Wrapper = styled.div`
  background: url(${JumbotronBackground}) top center no-repeat;
  background-size: cover;
  border-radius: 0 0 100px 100px;
  overflow: hidden;
  min-height: 500px;
`;

export const Background = styled.div`
  background: rgba(21, 27, 38, 0.7);
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 90px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  width: 450px;
`;

export const ContentText = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  p {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #fff;
    line-height: 24px;
    padding-right: 50px;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 40px 0;
    gap: 16px;

    span {
      font-family: 'Raleway', sans-serif;
      font-weight: 700;
      font-size: 18px;
      color: #fff;
    }

    img {
      width: 170px;
    }
  }

  button {
    background: #f6d30d;
    border: solid 1px #f6d30d;
    color: #151b26;
    padding: 19px 2px;
    border-radius: 10px;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    max-width: 260px;
  }
`;

export const H1 = styled.h1`
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
  font-size: 60px;
  color: #fff;
  line-height: 70px;
  padding-bottom: 24px;

  span {
    color: #f6d30d;
  }
`;
