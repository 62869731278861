import { auth } from '..';
import { clearCoaches } from '../r-store/coach/actions';
import { clearExams } from '../r-store/exams/actions';
import { clearGroups } from '../r-store/group/actions';
import { clearMedia } from '../r-store/media/actions';
import { clearOrganization } from '../r-store/organization/actions';
import { clearOwners } from '../r-store/owner/actions';
import { clearPlayers } from '../r-store/player/actions';
import { clearQuestions } from '../r-store/questions/actions';
import { clearSubscription } from '../r-store/subscriptions/actions';
import { clearTeam } from '../r-store/team/actions';
import { clearUser } from '../r-store/user/actions';
import { handleError } from './errors';

export const _signInWithEmailAndPassword = async (email, password) => {
  return await auth.signInWithEmailAndPassword(email, password);
};

export const _signOut = async (dispatch) => {
  dispatch(clearCoaches());
  dispatch(clearExams());
  dispatch(clearGroups());
  dispatch(clearMedia());
  dispatch(clearOrganization());
  dispatch(clearOwners());
  dispatch(clearPlayers());
  dispatch(clearQuestions());
  dispatch(clearSubscription());
  dispatch(clearTeam());
  dispatch(clearUser());
  return await auth.signOut();
};

export const _sendPasswordResetEmail = async (email) => {
  return await auth.sendPasswordResetEmail(email);
};
