import PropTypes from 'prop-types';
import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import AppStoreBadges from './component/AppStoreBadges';
import './home.css';
import './how-it-works.scss';
import SEOHead from '../r-components/SEOHead/SEOHead';
import Logos, { LogoList } from './Logos';
import { TABS } from '../AppTabs.js';
import { EMAIL_ADDRESSES } from '../config.js';

toast.configure();

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      class: 'u-video-player',
      emailError: '',
      passwordError: '',
      resetEmail: '',
      resetEmailError: '',
      COVID1: true,
    };
  }

  playVideo = () => {
    this.setState({
      class: 'u-video-player u-video-player__played',
    });
  };

  logoContent = lazy(() => import('./Logos.js'));

  render() {
    return (
      <div id="page-home">
        <SEOHead
          title="Win more games and develop better baseball players with 6Tool"
          description="Improve your baseball player coaching and development with 6Tool. Our revolutionary baseball coaching app uses innovative technology help you develop better baseball players like never before."
        />
        <section id="hero" className="hero">
          <div className="img-box">
            <img src="img/hero-1.png" className="hero-img img-fluid" alt="" />
          </div>
          <div className="container">
            <div
              className="hero-text"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ flex: 1 }}>
                <div className="subtitle" style={{ display: 'none' }}>
                  <p>The Best App</p>
                </div>
                <div className="title">
                  <h1>
                    Level Up Your Players' <span>Baseball Skills</span> with the
                    6Tool App
                  </h1>
                </div>
                <div className="content">
                  <p>
                    Join the ranks of successful coaches using 6Tool to win more
                    games and develop superior baseball players. Take advantage
                    of our easy-to-use app that enhances your team's baseball IQ
                    and assist in your players' development.
                  </p>
                </div>
                <div
                  className="btn-wrap"
                  style={{ marginTop: -30, marginBottom: -10 }}
                >
                  <a
                    href={TABS.SIGNUP_CREATE_ACCOUNT.path}
                    className="btn primary-btn"
                  >
                    <span>Get Started</span>
                  </a>
                </div>
              </div>
              <div style={{ flex: 0 }}>
                <div>
                  <hr
                    style={{ marginTop: 40, maxWidth: '90%', marginLeft: 0 }}
                  />
                  <div style={{ color: 'white' }}>
                    ** Attention players: download the app below! **
                  </div>
                  <AppStoreBadges height={60} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="app-overview" className="app-overview">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 py-2">
                <div className="img-box mb-3">
                  <div
                    style={{
                      padding: '0 0 56.25% 0',
                      position: 'relative',
                      overflow: 'hidden',
                      width: '100%',
                    }}
                  >
                    <iframe
                      src="https://www.youtube.com/embed/KOnoe1DD6Ag"
                      title="6Tool"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowfullscreen
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                      }}
                    ></iframe>
                  </div>
                </div>
              </div>
              <div className="col-md-6 py-2 home-develop-content">
                <div className="title">
                  <h2>
                    Empowering Coaches to <i>Develop</i> Top-notch baseball
                    players
                  </h2>
                </div>
                <div className="content">
                  <p>
                    For baseball coaches like you, every game brings measurable
                    progress. However, game time isn't the time for
                    trial-and-error learning. Instead, have a proactive
                    approach, use 6-Tool—the best baseball player development
                    tool on the market. We provide a platform to level up your
                    coaching strategy by helping you to quickly identify and
                    address your players’ knowledge of the game. By using our
                    easy-to-implement interactive player quizzes, you can assess
                    position-specific issues, overall baseball IQ, and much
                    more! Win more games and develop better overall baseball
                    players with effective off-the-field training.
                  </p>
                </div>
                <div className="btn-wrap">
                  <a
                    href={TABS.SIGNUP_CREATE_ACCOUNT.path}
                    className="btn primary-btn"
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="why-6tool" className="why-6tool pb-2">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-5 pt-2 pb-5">
                <div className="title">
                  <h2>Unleash the 6Tool advantage</h2>
                  <h3 style={{ lineHeight: 1, marginTop: 20 }}>
                    Commit to a comprehensive baseball coaching system that:
                  </h3>
                </div>
                <div className="reasons">
                  <div className="reason-each">
                    <div className="icon">
                      <img src="img/icon-4.png" className="img-fluid" alt="" />
                    </div>
                    <div className="text">
                      <p>Maps out each player's strengths and weaknesses</p>
                    </div>
                  </div>

                  <div className="reason-each">
                    <div className="icon">
                      <img src="img/icon-5.png" className="img-fluid" alt="" />
                    </div>
                    <div className="text">
                      <p>
                        Develops intuitive game strategies, like knowing where
                        to throw and who's the cut-off
                      </p>
                    </div>
                  </div>

                  <div className="reason-each">
                    <div className="icon">
                      <img src="img/icon-6.png" className="img-fluid" alt="" />
                    </div>
                    <div className="text">
                      <p>
                        Helps you win more games by mastering routine and
                        advanced game situations
                      </p>
                    </div>
                  </div>
                </div>
                <div className="btn-wrap">
                  <a
                    href={TABS.SIGNUP_CREATE_ACCOUNT.path}
                    className="btn primary-btn"
                    style={{ marginRight: 8 }}
                  >
                    Sign up
                  </a>
                  <a
                    href={`mailto:${EMAIL_ADDRESSES.INFO}`}
                    className="btn primary-btn"
                  >
                    <span className="material-symbols-outlined">mail</span>
                    <span>Reach out</span>
                  </a>
                </div>
              </div>
              <div className="col-md-7 py-2">
                <div className="img-box">
                  <img src="img/why-6tool.png" className="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="how-it-works" className="how-it-works">
          <div className="container">
            <div className="title text-center">
              <h2>How 6Tool Works</h2>
            </div>
            <div className="row">
              <div className="col-md-4 py-2">
                <div className="how-it-works-content-wrapper">
                  <div className="how-it-works-number">1</div>
                  <div className="how-it-works-image-wrapper">
                    <img src="/img/coach-create-quiz.jpg" />
                  </div>

                  <div className="how-it-works-text">
                    You assign quizzes in your coaching portal
                  </div>
                </div>
              </div>
              <div className="col-md-4 py-2">
                {' '}
                <div className="how-it-works-content-wrapper">
                  <div className="how-it-works-number">2</div>
                  <div className="how-it-works-image-wrapper">
                    <img src="/img/kid-taking-quiz.jpg" />
                  </div>

                  <div className="how-it-works-text">
                    Your team takes quizzes via the mobile app - anytime,
                    anywhere
                  </div>
                </div>
              </div>
              <div className="col-md-4 py-2">
                {' '}
                <div className="how-it-works-content-wrapper">
                  <div className="how-it-works-number">3</div>
                  <div className="how-it-works-image-wrapper">
                    <img src="/img/coach-review-quiz.jpg" />
                  </div>

                  <div className="how-it-works-text">
                    You review quiz results to create the perfect practice plan
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Suspense fallback={<div>Loading...</div>}>
          <this.logoContent />
        </Suspense>

        <section id="feature" className="tool-control">
          <div className="container">
            <div className="title">
              <h2>
                Comprehensive Training for Baseball Players at All Skill Levels
              </h2>
            </div>
            <div className="icon-big">
              <img src="img/logo6-circle.png" className="img-fluid" alt="" />
              <div className="tool-num">
                <p className="a">1</p>
                <p className="b">2</p>
                <p className="c">3</p>
                <p className="d">4</p>
                <p className="e">5</p>
                <p className="f">6</p>
                <p className="g">7</p>
              </div>
              <div className="tool-list">
                <p className="a">
                  Access over 1000 pre-structured baseball IQ questions
                </p>
                <p className="b">
                  Write custom questions reflecting your team's strategy
                </p>
                <p className="c">
                  Give signs for hitters, pitchers, and base runners via
                  recorded videos
                </p>
                <p className="d">
                  Send position-specific questions with various difficulty
                  levels
                </p>
                <p className="e">
                  Set quiz time limits to emulate game-time pressure
                </p>
                <p className="f">Boost your team's overall baseball IQ</p>
                <p className="g">
                  Get an accurate read on what each player knows
                </p>
              </div>

              <div className="num-lists">
                <p className="a">
                  <span className="num">01</span> Access over 1000
                  pre-structured baseball IQ questions
                </p>
                <p className="b">
                  <span className="num">02</span> Write custom questions
                  reflecting your team's strategy
                </p>
                <p className="c">
                  <span className="num">03</span> Give signs for hitters,
                  pitchers, and base runners via recorded videos
                </p>
                <p className="d">
                  <span className="num">04</span> Send position-specific
                  questions with various difficulty levels
                </p>
                <p className="e">
                  <span className="num">05</span> Set quiz time limits to
                  emulate game-time pressure
                </p>
                <p className="f">
                  <span className="num">06</span> Boost your team's overall
                  baseball IQ
                </p>
                {/* <p className="g">
                  <span className="num">07</span> Boost your team's overall
                  baseball IQ
                </p> */}
              </div>
            </div>
          </div>
        </section>
        <section className="ready-to-start text-center">
          <div className="container">
            <div className="bg-layout">
              <div className="icon-box">
                <img src="img/img6.png" className="img-fluid" alt="" />
              </div>
              <div className="heading">
                <h2>Discover why winning coaches trust 6Tool</h2>
              </div>
              <div className="btn-wrap">
                <a
                  href={TABS.SIGNUP_CREATE_ACCOUNT.path}
                  className="btn secondary-btn"
                >
                  Sign Up Today
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

Home.propTypes = {
  teamLength: PropTypes.number,
};

export default connect(mapStateToProps)(Home);
