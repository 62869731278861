import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CLabel,
  CLink,
  CRow,
  CTooltip,
} from '@coreui/react';
import './team.scss';
import React, { useEffect, useRef, useState } from 'react';
import { FaBaseballBall, FaUserMinus } from 'react-icons/fa';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { FiAlertCircle } from 'react-icons/fi';
import { FaEdit, FaInfoCircle, FaUserPlus } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import {
  addPlayers,
  editPlayer,
  viewPlayerDetails,
} from '../../../actions/playerActionCreator';
import { db, functions } from '../../../index';
import { Loading } from '../../../r-reusable';
import PlayerWrapper from '../../../pages/component/PlayerWrapper';
import { useHistory } from 'react-router-dom';
import { TABS } from '../../../AppTabs';
import { orgAndTeamData } from '../../../helpers/data';
import OrgTeamImageHeader from '../../../r-components/OrgTeamImageHeader/OrgTeamImageHeader';
import TeamCodeExplanationModal from '../teams/TeamCodeExplanationModal';
import { formatPhoneNumber } from '../../../helpers/utils';
import { format } from 'date-fns';
import { handleError } from '../../../helpers/errors';
import { toast } from 'react-toastify';
import LoadingMessage from '../../../r-components/LoadingMessage/LoadingMessage';

const TeamPlayerRequests = () => {
  const playerRequests = useSelector(
    (state) => state.DataPlayerState?.teamRequests || []
  );
  const orgData = useSelector((state) => state.DataOrganizationState);
  const teamData = useSelector((state) => state.DataTeamState);
  const [tableFields, setTableFields] = useState([]);
  const [isSubmittingRequestResponse, setIsSubmittingRequestResponse] =
    useState(null);
  const [orgTeamData, setOrgTeamData] = useState({});
  useEffect(() => {
    let orgTeam = orgAndTeamData({ orgData, teamData });
    setOrgTeamData(orgTeam);
    let tableFields = [
      { key: 'name', label: 'Name', _classes: 'font-weight-bold' },
    ];
    if (orgTeam.hasTeam !== true) {
      tableFields.push({ key: 'team', label: 'Team(s)' });
    }
    tableFields = tableFields.concat([
      { key: 'email', label: 'Email' },
      { key: 'phoneNumber', label: 'Phone' },
      { key: 'created_at', label: 'Request Time' },
      { key: 'show_details', label: '', sorter: false },
    ]);
    setTableFields(tableFields);
  }, [orgData, teamData]);

  return (
    <PlayerWrapper className="team-player-requests-page">
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader className="d-flex justify-content-between">
              <OrgTeamImageHeader
                orgTeamData={orgTeamData}
                title="Player Join Requests"
                rightView={
                  <CTooltip content="These are users who have requested to be part of your team or organization. If you recognize them as players on your team, hover over their row in the table and approve their request. Until you approve these requests, they are not on your team, nor are them able to receive any quiz content you create.">
                    <div>
                      <FaInfoCircle /> What is this?
                    </div>
                  </CTooltip>
                }
              />
            </CCardHeader>

            <CCardBody>
              <CDataTable
                addTableClasses="team-player-requests-table"
                striped
                items={playerRequests}
                itemsPerPageSelect={true}
                itemsPerPage={50}
                hover
                tableFilter={true}
                sorter
                sorterValue={{ column: 'name', asc: true }}
                fields={tableFields}
                noItemsViewSlot={
                  <p className="d-flex align-content-center justify-content-center pt-4 pb-4">
                    <FiAlertCircle size="30" color="#e55353" />
                    <span className="pl-2 font-xl">No players added.</span>
                  </p>
                }
                scopedSlots={{
                  name: (player) => (
                    <td className="font-weight-bold">{player.name}</td>
                  ),
                  team: (player) => (
                    <td>
                      {_.isObject(teamData.map) &&
                      _.isObject(teamData.map[player.team_id]) &&
                      _.isString(teamData?.map[player.team_id].name)
                        ? teamData.map[player.team_id].name
                        : ''}
                    </td>
                  ),
                  position: (player) => (
                    <td>
                      {(player._positions || [])
                        .map((p) =>
                          _.isString(p) ? p : _.isObject(p) ? p.label : ''
                        )
                        .join(', ')}
                    </td>
                  ),
                  email: (player) => <td>{player.email}</td>,
                  phoneNumber: (player) => (
                    <td>{formatPhoneNumber(player.phoneNumber)}</td>
                  ),
                  created_at: (player) => (
                    <td>
                      {player?.created_at
                        ? format(
                            new Date(
                              player.created_at?.seconds * 1000 ||
                                player.created_at
                            ),
                            'MMM dd'
                          )
                        : null}
                    </td>
                  ),
                  show_details: (player) => (
                    <td className="text-right">
                      {isSubmittingRequestResponse === player.user_id ? (
                        <LoadingMessage
                          centered={false}
                          firstMessage="Submitting response..."
                        />
                      ) : (
                        <>
                          <CButton
                            className="team-player-requests-action-buttons team-player-requests-action-buttons-first"
                            variant="outline"
                            size="sm"
                            color="success"
                            onClick={async () => {
                              setIsSubmittingRequestResponse(player.user_id);
                              try {
                                const approveTeamRequestsByUserIdAndTeamIdPairsCallable =
                                  functions.httpsCallable(
                                    'approveTeamRequestsByUserIdAndTeamIdPairsCallable'
                                  );
                                let result =
                                  await approveTeamRequestsByUserIdAndTeamIdPairsCallable(
                                    {
                                      requests: [
                                        _.pick(player, [
                                          'user_id',
                                          'team_id',
                                          'organization_id',
                                        ]),
                                      ],
                                    }
                                  );
                                toast(
                                  'Request approved. Player is being added to the team.',
                                  { type: 'success' }
                                );
                              } catch (error) {
                                handleError({
                                  error,
                                  toast: 'Error approving join request',
                                  internal:
                                    'TeamPlayerRequests - declining request',
                                });
                              } finally {
                                setIsSubmittingRequestResponse(null);
                              }
                            }}
                          >
                            <FaUserPlus size={20} style={{ marginRight: 4 }} />{' '}
                            Approve request
                          </CButton>
                          <CButton
                            className="team-player-requests-action-buttons"
                            variant="outline"
                            color="danger"
                            size="sm"
                            onClick={async () => {
                              setIsSubmittingRequestResponse(player.user_id);
                              try {
                                const declineTeamRequestsByUserIdAndTeamIdPairsCallable =
                                  functions.httpsCallable(
                                    'declineTeamRequestsByUserIdAndTeamIdPairsCallable'
                                  );
                                let result =
                                  await declineTeamRequestsByUserIdAndTeamIdPairsCallable(
                                    {
                                      requests: [
                                        _.pick(player, [
                                          'user_id',
                                          'team_id',
                                          'organization_id',
                                        ]),
                                      ],
                                    }
                                  );
                                toast(
                                  'Request declined. Player will not have access to the team.',
                                  { type: 'success' }
                                );
                              } catch (error) {
                                handleError({
                                  error,
                                  toast: 'Error declining join request',
                                  internal:
                                    'TeamPlayerRequests - declining request',
                                });
                              } finally {
                                setIsSubmittingRequestResponse(null);
                              }
                            }}
                          >
                            <FaUserMinus size={20} style={{ marginRight: 4 }} />{' '}
                            Decline request
                          </CButton>
                        </>
                      )}
                    </td>
                  ),
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </PlayerWrapper>
  );
};

export default TeamPlayerRequests;
