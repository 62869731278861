import { CLink, CTooltip } from '@coreui/react';
import React, { useEffect, useRef, useState } from 'react';

import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight,
  FaCheck,
  FaEnvelopeOpenText,
  FaFilePdf,
  FaPrint,
  FaSortDown,
  FaSortUp,
  FaSyncAlt,
  FaTimes,
  FaTrash,
  FaTrashAlt,
} from 'react-icons/fa';
import ExamReviewQuestionDetails from './ExamReviewQuestionDetails';
import Tooltip from '../../../r-components/Tooltip/Tooltip';
import { invitePlayersToQuiz } from '../../../helpers/data';
import { toast } from 'react-toastify';
import { handleError } from '../../../helpers/errors';
import { functions } from '../../..';
import useAccountSelectors from '../../../hooks/useAccountSelectors';
import {
  ACCESS_ACTIONS,
  canDoAction,
  checkAccess,
} from '../../../helpers/access';
import { isFunction } from 'lodash';

const SORT_METHODS = {
  NAME: 'name',
  DATE: 'date',
  SCORE: 'score',
};
const SORT_DIRECTION = {
  ASC: true,
  DESC: false,
};
const DEFAULT_SORT_DIRECTION = {
  name: SORT_DIRECTION.ASC,
  date: SORT_DIRECTION.DESC,
  score: SORT_DIRECTION.DESC,
};

const ExamPlayerResults = ({
  questions,
  takenExams,
  questionResults,
  className = '',
  invitedPlayers = [],
  showSinglePlayerResultModal = null,
  generatePDF = null,
  loadingPDF = false,
  forPrint = false,
  isEmbedded = false,
}) => {
  const divRef = useRef(null);
  const [nameWidth, setNameWidth] = useState(200);
  const [questionsPerPage, setQuestionsPerPage] = useState(20);
  const [questionPage, setQuestionPage] = useState(0);
  const [numPages, setNumPages] = useState(1);
  const [questionColumns, setQuestionColumns] = useState(questions.length || 0);
  const [sortMethod, setSortMethod] = useState(SORT_METHODS.SCORE);
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTION.DESC);
  const [_takenExams, _setTakenExams] = useState(takenExams);
  const [sortableData, setSortableData] = useState(false);
  const { tier, type, showSubscriptionModal } =
    forPrint === true
      ? { tier: '', type: [], showSubscriptionModal: () => {} }
      : useAccountSelectors();
  const { canDo: canViewResults } =
    forPrint === true || isEmbedded === true
      ? { canDo: true }
      : canDoAction({
          action: ACCESS_ACTIONS.QUIZ_RESULTS,
          type,
          tier,
        });

  useEffect(() => {
    setQuestionColumns(
      Math.min(
        questions.length - questionPage * questionsPerPage,
        questionsPerPage
      )
    );
  }, [questions, questionPage, questionsPerPage]);

  const handleResize = () => {
    if (divRef.current) {
      const width = divRef.current.offsetWidth;
      let newNameWidth = nameWidth;
      if (width < 700) {
        newNameWidth = 120;
        setNameWidth(180);
      } else {
        newNameWidth = 200;
        setNameWidth(200);
      }
      let availableWidth = width - newNameWidth * 1.5;
      let numQuestionsInView = Math.floor(availableWidth / 30);
      setQuestionsPerPage(numQuestionsInView);
      setNumPages(Math.ceil(questions.length / numQuestionsInView));
    }
  };

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    handleResize();
  }, [questions]);

  useEffect(() => {
    let _tE = [...takenExams];
    switch (sortMethod) {
      case SORT_METHODS.NAME:
        _tE.sort((a, b) => {
          if (sortDirection === SORT_DIRECTION.DESC) {
            return a.playerName.localeCompare(b.playerName) * -1;
          }
          return a.playerName.localeCompare(b.playerName);
        });
        break;
      case SORT_METHODS.DATE:
        _tE.sort((a, b) => {
          if (!isNaN(a.finished?.seconds) && isNaN(b.finished?.seconds)) {
            return -1; // a comes first
          } else if (
            isNaN(a.finished?.seconds) &&
            !isNaN(b.finished?.seconds)
          ) {
            return 1; // b comes first
          } else {
            if (sortDirection === SORT_DIRECTION.DESC) {
              return a.finished?.seconds > b.finished?.seconds ? -1 : 1;
            }
            return a.finished?.seconds > b.finished?.seconds ? 1 : -1;
          }
        });

        break;
      default:
      case SORT_METHODS.SCORE:
        _tE.sort((a, b) => {
          if (sortDirection === SORT_DIRECTION.DESC) {
            return a.score > b.score ? -1 : 1;
          }
          return a.score > b.score ? 1 : -1;
        });
        break;
    }
    _setTakenExams(_tE);
    setSortableData(_tE.length > 1);
  }, [takenExams, sortDirection, sortMethod]);

  return (
    <div className={`results-type-player ${className}`}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {isEmbedded !== true && <FaFilePdf style={{ visibility: 'hidden' }} />}
        <h1 style={{ textAlign: 'center', flex: 1 }}>Results</h1>
        {isEmbedded !== true && (
          <>
            <CTooltip
              style={forPrint === true && { visibility: 'hidden' }}
              content="Print results"
            >
              <FaPrint
                className="noprint"
                style={
                  forPrint === true
                    ? { visibility: 'hidden' }
                    : { marginRight: 4, cursor: 'pointer' }
                }
                onClick={() => {
                  checkAccess({
                    action: [ACCESS_ACTIONS.QUIZ_RESULTS],
                    type,
                    tier,
                    showSubscriptionModal,
                  }).doWithAccess(() => {
                    window.print();
                  });
                }}
              />
            </CTooltip>

            {loadingPDF ? (
              <CTooltip
                style={forPrint === true && { visibility: 'hidden' }}
                content="Creating report..."
              >
                <FaSyncAlt
                  className="noprint spinning-icon"
                  style={
                    forPrint === true
                      ? { visibility: 'hidden' }
                      : { cursor: 'pointer' }
                  }
                />
              </CTooltip>
            ) : (
              <CTooltip
                style={forPrint === true && { visibility: 'hidden' }}
                content="Download report"
              >
                <FaFilePdf
                  className="noprint"
                  style={
                    forPrint === true
                      ? { visibility: 'hidden' }
                      : { cursor: 'pointer' }
                  }
                  onClick={() => {
                    checkAccess({
                      action: [ACCESS_ACTIONS.QUIZ_RESULTS],
                      type,
                      tier,
                      showSubscriptionModal,
                    }).doWithAccess(generatePDF);
                  }}
                />
              </CTooltip>
            )}
          </>
        )}
      </div>

      <div>
        {numPages > 1 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            {numPages > 2 && (
              <FaAngleDoubleLeft
                style={
                  questionPage > 0 ? { cursor: 'pointer' } : { opacity: 0.3 }
                }
                onClick={() => {
                  if (questionPage > 0) {
                    setQuestionPage(0);
                  }
                }}
              />
            )}
            <FaAngleLeft
              style={
                questionPage > 0 ? { cursor: 'pointer' } : { opacity: 0.3 }
              }
              onClick={() => {
                if (questionPage > 0) {
                  setQuestionPage(questionPage - 1);
                }
              }}
            />
            <span style={{ fontWeight: 'bolder', fontSize: '0.8em' }}>
              {`Questions ${
                questionPage * questionsPerPage + 1
              } - ${questionColumns}`}
            </span>
            <FaAngleRight
              style={
                questionPage < numPages - 1
                  ? { cursor: 'pointer' }
                  : { opacity: 0.3 }
              }
              onClick={() => {
                if (questionPage < numPages - 1) {
                  setQuestionPage(questionPage + 1);
                }
              }}
            />
            {numPages > 2 && (
              <FaAngleDoubleRight
                style={
                  questionPage < numPages - 1
                    ? { cursor: 'pointer' }
                    : { opacity: 0.3 }
                }
                onClick={() => {
                  if (questionPage < numPages - 1) {
                    setQuestionPage(numPages - 1);
                  }
                }}
              />
            )}
          </div>
        )}
        <div ref={divRef} className="quiz-results-grid-container">
          <div
            className={`quiz-results-grid-row header-row ${
              sortableData ? 'sortable' : ''
            }`}
            style={{
              gridTemplateColumns: `${nameWidth}px ${nameWidth / 2}px ${
                nameWidth / 2
              }px ${
                questionColumns > 0
                  ? ` repeat(${questionColumns}, 30px) ${Math.max(
                      0,
                      280 - questionColumns * 30
                    )}px`
                  : ` 280px`
              }`,
            }}
          >
            <div
              onClick={() => {
                if (sortableData) {
                  if (sortMethod === SORT_METHODS.NAME) {
                    setSortDirection(!sortDirection);
                  } else {
                    setSortMethod(SORT_METHODS.NAME);
                    setSortDirection(DEFAULT_SORT_DIRECTION[SORT_METHODS.NAME]);
                  }
                }
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 8,
              }}
            >
              <span>Player</span>
              {sortableData && sortMethod === SORT_METHODS.NAME && (
                <>
                  {sortDirection === SORT_DIRECTION.ASC ? (
                    <FaSortDown />
                  ) : (
                    <FaSortUp />
                  )}
                </>
              )}
            </div>
            <div
              onClick={() => {
                if (sortableData) {
                  if (sortMethod === SORT_METHODS.DATE) {
                    setSortDirection(!sortDirection);
                  } else {
                    setSortMethod(SORT_METHODS.DATE);
                    setSortDirection(DEFAULT_SORT_DIRECTION[SORT_METHODS.DATE]);
                  }
                }
              }}
            >
              <span>Date</span>
              {sortableData && sortMethod === SORT_METHODS.DATE && (
                <>
                  {sortDirection === SORT_DIRECTION.ASC ? (
                    <FaSortUp />
                  ) : (
                    <FaSortDown />
                  )}
                </>
              )}
            </div>
            <div
              onClick={() => {
                if (sortableData) {
                  if (sortMethod === SORT_METHODS.SCORE) {
                    setSortDirection(!sortDirection);
                  } else {
                    setSortMethod(SORT_METHODS.SCORE);
                    setSortDirection(
                      DEFAULT_SORT_DIRECTION[SORT_METHODS.SCORE]
                    );
                  }
                }
              }}
            >
              {sortableData ? (
                <>
                  <span>Score</span>
                  {sortableData && sortMethod === SORT_METHODS.SCORE && (
                    <>
                      {sortDirection === SORT_DIRECTION.ASC ? (
                        <FaSortUp />
                      ) : (
                        <FaSortDown />
                      )}
                    </>
                  )}
                </>
              ) : invitedPlayers.length > 0 ? (
                'Invite'
              ) : (
                ''
              )}
            </div>
            {[...Array(Math.max(0, questionColumns)).keys()].map((i, index) => (
              <React.Fragment key={index}>
                {canViewResults ? (
                  <Tooltip
                    key={`question-columns-keys-tooltip-${index}`}
                    delay={400}
                    content={
                      <ExamReviewQuestionDetails
                        question={
                          questions[i + questionsPerPage * questionPage]
                        }
                        response={
                          questionResults[
                            i + questionsPerPage * questionPage
                          ] || {}
                        }
                      />
                    }
                    direction="top"
                  >
                    <div style={{ textAlign: 'center', cursor: 'help' }}>
                      {i + questionsPerPage * questionPage + 1}
                    </div>
                  </Tooltip>
                ) : (
                  <>
                    <div style={{ textAlign: 'center', cursor: 'help' }}>
                      {i + questionsPerPage * questionPage + 1}
                    </div>
                  </>
                )}
              </React.Fragment>
            ))}
          </div>
          {_takenExams.map((tE, teIdx) => (
            <div
              key={`quiz-results-grid-${teIdx}`}
              className="quiz-results-grid-row"
              ref={divRef}
              style={{
                gridTemplateColumns: `${nameWidth}px ${nameWidth / 2}px ${
                  nameWidth / 2
                }px${
                  questionColumns > 0
                    ? ` repeat(${questionColumns}, 30px) ${Math.max(
                        0,
                        280 - questionColumns * 30
                      )}px`
                    : ` 280px`
                }`,
              }}
              onClick={() => {
                if (isFunction(showSinglePlayerResultModal)) {
                  checkAccess({
                    action: [ACCESS_ACTIONS.QUIZ_RESULTS],
                    tier,
                    type,
                    showSubscriptionModal,
                  }).doWithAccess(() => {
                    showSinglePlayerResultModal(
                      tE.questions.map((q) => q.answer)
                    );
                  });
                }
              }}
            >
              <div>{tE.playerName}</div>
              <div>{tE.dateString}</div>
              {canViewResults ? (
                <>
                  <div>{`${(tE.score * 100).toFixed(1)}%`}</div>
                  {[...Array(Math.max(0, questionColumns)).keys()].map(
                    (qIdx, index) => {
                      let idx = questionPage * questionsPerPage + qIdx;
                      if (tE?.questions?.length > idx) {
                        let q = tE.questions[idx];
                        return (
                          <Tooltip
                            key={`question-tooltip-${index}`}
                            delay={400}
                            content={
                              <ExamReviewQuestionDetails
                                question={questions[qIdx]}
                                singleResponse={q.answer}
                              />
                            }
                            direction="bottom"
                          >
                            <div
                              style={{ textAlign: 'center', cursor: 'help' }}
                            >
                              {q.correct ? (
                                <FaCheck color="green" />
                              ) : (
                                <FaTimes color="red" />
                              )}
                            </div>
                          </Tooltip>
                        );
                      }
                      return <div key={`questions-blank-${qIdx}`}></div>;
                    }
                  )}
                </>
              ) : (
                <div
                  className="quiz-results-grid-row-upgrade-to-view"
                  style={{ gridColumn: `span ${questionColumns + 1}` }}
                >
                  Upgrade to view quiz results
                </div>
              )}
            </div>
          ))}
          {_takenExams.length > 0 && invitedPlayers.length > 0 && (
            <div>
              <hr />
            </div>
          )}
          {invitedPlayers.map((iP, index) => (
            <div
              key={`invited-players-iP-${index}`}
              className="quiz-results-grid-row quiz-invites-row"
              style={{
                gridTemplateColumns: `${nameWidth}px ${nameWidth / 2}px${
                  questionColumns > 0
                    ? ` ${
                        Math.max(30 * questionColumns, 280) + nameWidth / 2
                      }px`
                    : ` ${280 + nameWidth / 2}px`
                }`,
              }}
            >
              <div style={{}}>{iP?.playerName || ''}</div>
              <div style={{}}>
                {forPrint !== true && (
                  <CTooltip content="Re-send invite">
                    <CLink
                      onClick={() => {
                        invitePlayersToQuiz({
                          organization_id: iP.organization_id,
                          team_id: iP.team_id,
                          quiz_id: iP.quiz_id,
                          exam_id: iP.exam_id,
                          user_ids: [iP.user_id],
                        });
                      }}
                    >
                      <FaEnvelopeOpenText />
                    </CLink>
                  </CTooltip>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div className="quiz-results-invited-details">
                  {`Invited ${iP.created_formatted || ''} - Not taken yet`}
                </div>
                <div className="hover-only">
                  {forPrint !== true && (
                    <CTooltip content="Un-invite player">
                      <CLink
                        onClick={async () => {
                          try {
                            toast(
                              'Invite deleted. It may take a minute to remove from view.',
                              { type: 'success' }
                            );
                            const deleteQuizInviteCallable =
                              functions.httpsCallable(
                                'deleteQuizInviteCallable'
                              );
                            let result = await deleteQuizInviteCallable({
                              user_id: iP.user_id,
                              team_id: iP.team_id,
                              quiz_id: iP.quiz_id,
                            });
                          } catch (error) {
                            handleError({
                              error,
                              toast: 'Error deleting invite',
                              internal: 'ExamPlayerResults - deleting invite',
                            });
                          }
                        }}
                      >
                        <FaTrashAlt color="red" />
                      </CLink>
                    </CTooltip>
                  )}
                </div>
              </div>
              {/* {iP.questions.map((q) => (
            <div>
              {q.correct ? <FaCheck color="green" /> : <FaTimes color="red" />}
            </div>
          ))} */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExamPlayerResults;
