import { CTooltip } from '@coreui/react';
import React from 'react';
// import Path, { Svg, Circle, G, Polygon, Line } from 'react-svg-path';
// import Svg, {
//     Circle,
//     G,
//     Path,
//     Polygon,
//     Line,
//   } from 'react-native-svg';

const PieChart = ({ size = 50, percent, style = {} }) => {
  if (isNaN(percent)) return null;

  return (
    <CTooltip content={!isNaN(percent) ? `${Math.round(percent * 100)}%` : ''}>
      <div
        style={{
          width: size,
          height: size,
          borderRadius: '50%',
          background: `conic-gradient(green 0 ${360 * percent}deg, red ${
            360 * percent
          }deg 360deg)`,
          ...style,
        }}
      ></div>
    </CTooltip>
  );
};

export default PieChart;
