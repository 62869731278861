import Container from '../Container/Container';
import {
  Content,
  H3,
  Rate,
  Testimonail,
  TestimonailHeader,
  TestimonailItem,
  Wrapper,
} from './TestimonialsStyle';

import star from '../../assets/star.png';
import testimonailImg from '../../assets/testimonail-img.png';
import React from 'react';

const Testimonials = () => {
  return (
    <Wrapper>
      <Container>
        <Content>
          <H3>What client says about us</H3>

          <Testimonail>
            <TestimonailItem>
              <Rate>
                <ol>
                  <li>
                    <img src={star} />
                  </li>
                  <li>
                    <img src={star} />
                  </li>

                  <li>
                    <img src={star} />
                  </li>

                  <li>
                    <img src={star} />
                  </li>

                  <li>
                    <img src={star} />
                  </li>
                </ol>
              </Rate>
              <TestimonailHeader>
                <img src={testimonailImg} alt="" />
                <div>
                  <strong>Anjuman K.</strong>
                  <span>I am A Coach</span>
                </div>
              </TestimonailHeader>
              <p>
                For baseball coaches like you, every game brings measurable
                progress. However, game time isn't the time for trial & error
                learning. Instead baseball coaches like you every game
              </p>
            </TestimonailItem>

            <TestimonailItem>
              <Rate>
                <ol>
                  <li>
                    <img src={star} />
                  </li>
                  <li>
                    <img src={star} />
                  </li>

                  <li>
                    <img src={star} />
                  </li>

                  <li>
                    <img src={star} />
                  </li>

                  <li>
                    <img src={star} />
                  </li>
                </ol>
              </Rate>
              <TestimonailHeader>
                <img src={testimonailImg} alt="" />
                <div>
                  <strong>Anjuman K.</strong>
                  <span>I am A Coach</span>
                </div>
              </TestimonailHeader>
              <p>
                For baseball coaches like you, every game brings measurable
                progress. However, game time isn't the time for trial & error
                learning. Instead baseball coaches like you every game
              </p>
            </TestimonailItem>
          </Testimonail>

          <button>View all testimonials</button>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default Testimonials;
