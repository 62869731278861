import { getAnalytics, setUserProperties } from 'firebase/analytics';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import { getFirestore } from 'firebase/firestore';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from './store';
import * as Sentry from '@sentry/react';
import { HelmetProvider } from 'react-helmet-async';
import { Helmet } from 'react-helmet';
import { SubscriptionModalProvider } from './r-components/SubscriptionModal/SubscriptionModal';

Sentry.init({
  dsn:
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_SENTRY_LOCAL
      : process.env.REACT_APP_PROJECT_ID === 'six-tool-staging'
      ? process.env.REACT_APP_SENTRY_STAGING
      : process.env.REACT_APP_SENTRY_PRODUCTION,
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets:
        process.env.NODE_ENV === 'development'
          ? ['localhost']
          : process.env.REACT_APP_PROJECT_ID === 'six-tool-staging'
          ? [/^https:\/\/six-tool-staging\.web\.app/]
          : [/^https:\/\/6-tool\.com/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
let app;
if (process.env.NODE_ENV === 'development') {
  app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: 'localhost',
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  });

  // Use the Firestore emulator
  firebase.firestore().settings({
    host: 'localhost:8080',
    ssl: false,
  });
  firebase.firestore().useEmulator('localhost', 8080);
  firebase.auth().useEmulator('http://localhost:9099/');
  firebase.functions().useEmulator('localhost', 5001);
  firebase.storage().useEmulator('localhost', 9199);
} else {
  app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  });
}

// const app = firebase.initializeApp({
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_APP_ID,
//   measurementId: process.env.REACT_APP_MEASUREMENT_ID,
// });

const analytics = getAnalytics(app);
const db = firebase.firestore(); // getFirestore(app);

const auth = firebase.auth();
const functions = firebase.functions();
const storage = firebase.storage();

ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <SubscriptionModalProvider>
        <App />
      </SubscriptionModalProvider>
    </HelmetProvider>
  </Provider>,
  document.getElementById('root')
);
// const helmet = Helmet.renderStatic();
serviceWorker.unregister();

export { db, auth, storage, functions, analytics, firebase, setUserProperties };
export default firebase;
