export const numberOfCoaches = (state) => {
  return (state?.DataCoachState?.data || []).length;
};

export const numberOfCoachesOnTeam = (state, { team_id }) => {
  return (state?.DataOrganizationState?.coaches || []).filter((c) =>
    c._teams?.includes(team_id)
  ).length;
};

export const numberOfCoachRequests = (state) => {
  return (state?.DataCoachState?.teamRequests || []).length;
};

export const numberOfCoachRequestsOnTeam = (state, { team_id }) => {
  return (state?.DataCoachState?.teamRequests || []).filter(
    (c) => (c.team_id = team_id)
  ).length;
};

export const numberOfPlayers = (state) => {
  return (state?.DataPlayerState?.data || []).length;
};

export const numberOfPlayersOnTeam = (state, { team_id }) => {
  return (state?.DataOrganizationState?.players || []).filter((c) =>
    c._teams?.includes(team_id)
  ).length;
};

/*
const numPlayers = useSelector(
  (state) => (state?.DataPlayerState?.data || []).length
);
const numPlayerRequests = useSelector(
  (state) => (state?.DataPlayerState?.teamRequests || []).length
);
const numQuestions = useSelector(
  (state) => (state?.DataQuestionState?.data || []).length
);
const numQuizzes = useSelector(
  (state) => (state?.DataQuizState?.data || []).length
);
const numMedia = useSelector(
  (state) => (state?.DataMediaState?.data || []).length
);
const numInvites = useSelector(
  (state) => (state?.DataExamState?.invites || []).length
);
const numTaken = useSelector(
  (state) => (state?.DataExamState?.taken || []).length
);
const subscription = useSelector((state) => state?.DataSubscriptionState || {});

*/
