import {
  ADD_NEW_QUIZ,
  CLEAR_QUIZZES,
  QUIZZES,
  QUIZ_DETAILS,
  QUIZ_TAKEN_DETAILS,
} from './constants';

const defaultQuizState = {
  quizzes: true,
  addQuizzes: false,
  quizDetails: false,
  quizTakenDetails: false,
  examId: null,
  userId: null,
};

export const quizReducer = (state = defaultQuizState, action) => {
  switch (action.type) {
    case QUIZZES:
      return {
        ...state,
        quizzes: true,
        addQuizzes: false,
        quizDetails: false,
        quizTakenDetails: false,
      };
    case ADD_NEW_QUIZ:
      return {
        ...state,
        addQuizzes: true,
        quizzes: false,
        quizDetails: false,
        quizTakenDetails: false,
      };
    case QUIZ_DETAILS:
      return {
        ...state,
        addQuizzes: false,
        quizDetails: true,
        quizzes: false,
        examId: action.payload,
      };
    case QUIZ_TAKEN_DETAILS:
      return {
        ...state,
        examId: action.payload.examId,
        userId: action.payload.userId,
        quizzes: false,
        addQuizzes: false,
        quizDetails: false,
        quizTakenDetails: true,
      };
    case CLEAR_QUIZZES:
      return {
        ...state,
        addQuizzes: false,
        quizDetails: false,
        quizTakenDetails: false,
        quizzes: false,
      };
    default:
  }
  return state;
};
