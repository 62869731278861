import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SignupWrapper from './SignupWrapper';
import { _signInWithEmailAndPassword } from '../../helpers/authHelper';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { STRIPE, SUBSCRIPTION_TIER } from '../../config';
import { isString, isArray, startCase } from 'lodash';
import ScheduleDays, {
  daysOfWeek,
} from '../../r-components/ScheduleDays/ScheduleDays';
import { functions } from '../..';
import moment from 'moment-timezone';
import PricingTable from '../../r-components/PricingTable/PricingTable';
import {
  ACCESS_ACL,
  ACCESS_ACTIONS,
  ACCESS_LEVELS,
  canDoAction,
} from '../../helpers/access';
import { omit } from 'lodash';

const SignupChooseTier = ({ isFunnel = false } = {}) => {
  const userName = useSelector((state) => state.UserState?.data?.name);
  const userEmail = useSelector((state) => state.UserState?.data?.email);
  const userId = useSelector((state) => state.UserState?.data?.id);
  const teamId = useSelector((state) => state.DataTeamState?.teamData._id);
  const [didSchedule, setDidSchedule] = useState(false);
  const [recommendedTiers, setRecommendedTiers] = useState([
    SUBSCRIPTION_TIER.REGULAR,
    SUBSCRIPTION_TIER.PREMIUM,
  ]);
  const [expectedNumQuizzes, setExpectedNumQuizzes] = useState(0);
  const [selectedNumTeams, setSelectedNumTeams] = useState(1);

  const [scheduledDays, setScheduledDays] = useState([]);
  const [actions, setActions] = useState([]);

  const [cs, setCs] = useState('');
  const [uid, setUid] = useState('');
  const [
    loadingSubscriptionLinkForTierTerm,
    setLoadingSubscriptionLinkForTierTerm,
  ] = useState(null);

  const dayMap = { su: 0, m: 1, tu: 2, w: 3, th: 4, f: 5, sa: 6 };

  useEffect(() => {
    setExpectedNumQuizzes(Math.ceil(4.3 * scheduledDays.length));
  }, [scheduledDays]);

  useEffect(() => {
    const days_of_week = [
      'm', // schedule monday
      'tu', // schedule tuesday
      'w', // schedule wednesday
      'th', // schedule thursday
      'f', // schedule friday
      'sa', // schedule saturday
      'su', // schedule sunday
    ];
    const form_params = [
      'numTeams', // number of teams
      'numPlayers', // num players per team
      'coachType', //
      ...days_of_week,
      'sched', // wants to schedule quizzes
      'auto', // wants autoquizzes if > 0
      'ques', // wants to create questions if > 0
      'quiz', // wants to create custom quizzes if > 0
      'cs', // checkout session
      'uid', // user id
    ];
    const queryParams = new URLSearchParams(window.location.search);
    const params = {};
    let days = new Set(scheduledDays);
    form_params.map((p) => {
      params[p] = queryParams.get(p);
      if (Object.keys(dayMap).includes(p)) {
        if (parseInt(params?.[p]) === 1) {
          days.add(dayMap[p]);
        }
      }
    });

    const failedActions = [];

    if (!isNaN(params.numTeams) && parseInt(params.numTeams) > 1) {
      failedActions.push({
        action: ACCESS_ACTIONS.CREATE_TEAM,
        thresholdCount: 1,
        desire: `${params.numTeams} teams`,
      });
      setSelectedNumTeams(parseInt(params.numTeams));
    }

    let numScheduledQuizzesPerWeek = 0;
    days_of_week.forEach((dayName) => {
      if (!isNaN(params[dayName]) && parseInt(params[dayName]) > 0) {
        numScheduledQuizzesPerWeek++;
      }
    });
    if (
      numScheduledQuizzesPerWeek * 4.3 >
      ACCESS_ACL[SUBSCRIPTION_TIER.FREE.key][ACCESS_ACTIONS.CREATE_AUTO_QUIZ]
        .threshold
    ) {
      failedActions.push({
        action: ACCESS_ACTIONS.CREATE_AUTO_QUIZ,
        thresholdCount: Math.ceil(4.3 * numScheduledQuizzesPerWeek),
        desire: `${numScheduledQuizzesPerWeek} quiz${
          numScheduledQuizzesPerWeek === 1 ? '' : 'zes'
        } per week (~${Math.ceil(
          parseInt(numScheduledQuizzesPerWeek) * 4.3
        )}/mon)`,
      });
    }
    if (
      !isNaN(params.numPlayers) &&
      parseInt(params.numPlayers) >
        ACCESS_ACL[SUBSCRIPTION_TIER.FREE.key][ACCESS_ACTIONS.CREATE_PLAYER]
          .threshold
    ) {
      failedActions.push({
        action: ACCESS_ACTIONS.CREATE_PLAYER,
        thresholdCount: parseInt(params.numPlayers),
        desire: `${params.numPlayers} players per team`,
      });
    }
    if (
      numScheduledQuizzesPerWeek * 4.3 >
      ACCESS_ACL[SUBSCRIPTION_TIER.FREE.key][ACCESS_ACTIONS.SCHEDULE_QUIZZES]
        .threshold
    ) {
      failedActions.push({
        action: ACCESS_ACTIONS.SCHEDULE_QUIZZES,
        thresholdCount: Math.ceil(4.3 * numScheduledQuizzesPerWeek),
        desire: `${numScheduledQuizzesPerWeek} scheduled quiz${
          numScheduledQuizzesPerWeek === 1 ? '' : 'zes'
        } per week (~${Math.ceil(
          parseInt(numScheduledQuizzesPerWeek) * 4.3
        )}/mon)`,
      });
    }
    if (!isNaN(params.quiz) && parseInt(params.quiz) > 0) {
      failedActions.push({
        action: ACCESS_ACTIONS.VIEW_6TOOL_QUESTIONS,
        thresholdCount: 1,
        desire: 'Access library of 6Tool questions',
      });
    }
    if (!isNaN(params.quiz) && parseInt(params.quiz) > 0) {
      failedActions.push({
        action: ACCESS_ACTIONS.CREATE_QUIZZES,
        thresholdCount: 1,
        desire: 'Create custom quizzes',
      });
    }
    if (!isNaN(params.quiz) && parseInt(params.quiz) > 0) {
      failedActions.push({
        action: ACCESS_ACTIONS.NUM_QUESTIONS_PER_QUIZ,
        thresholdCount:
          ACCESS_ACL[SUBSCRIPTION_TIER.FREE.key][
            ACCESS_ACTIONS.NUM_QUESTIONS_PER_QUIZ
          ].threshold + 1,
      });
    }
    if (!isNaN(params.ques) && parseInt(params.ques) > 0) {
      failedActions.push({
        action: ACCESS_ACTIONS.CREATE_QUESTIONS,
        thresholdCount: 1,
        desire: 'Create your own questions',
      });
    }

    let possibleTiers = [
      SUBSCRIPTION_TIER.FREE,
      SUBSCRIPTION_TIER.REGULAR,
      SUBSCRIPTION_TIER.PREMIUM,
    ];
    failedActions.some((a) => {
      if (isArray(possibleTiers) && possibleTiers.length > 0) {
        possibleTiers.forEach(({ key: tier, label }) => {
          if (
            !canDoAction({
              action: a.action,
              type: [ACCESS_LEVELS.OWNER.key],
              tier,
              thresholdCount: a.thresholdCount,
            }).canDo
          ) {
            possibleTiers = possibleTiers.filter((t) => t.key !== tier);
          }
        });
      }
      return possibleTiers.length <= 1;
    });
    setRecommendedTiers(
      isArray(possibleTiers) && possibleTiers.length > 0
        ? possibleTiers
        : [SUBSCRIPTION_TIER.PREMIUM]
    );

    setActions(failedActions);
    setScheduledDays([...days]);
    setUid(params?.uid || '');
    const csValue = queryParams.get('cs');
    setCs(csValue);
  }, []);

  useEffect(() => {
    if (
      isArray(scheduledDays) &&
      scheduledDays.length &&
      isString(userId) &&
      userId.length > 0 &&
      !didSchedule
    ) {
      setDidSchedule(true);
      scheduleQuizzes();
    }
  }, [scheduledDays, userId]);

  const scheduleQuizzes = async () => {
    const scheduleQuizCallable = functions.httpsCallable(
      'scheduleQuizCallable'
    );
    const userTime = moment.tz(12, 'HH', moment.tz.guess());
    const estTime = userTime.clone().tz('America/New_York');
    const hourEST = parseInt(estTime.format('HH'));

    const scheduleQuizResult = await scheduleQuizCallable({
      categories: [],
      difficultyRange: [0, 10],
      numQuestions: 3,
      title: '6Tool Quiz',
      team_ids: [teamId],
      organization_id: userId || uid,
      playersToInvite: { [teamId]: { all: true } },
      days: scheduledDays.sort(),
      hourEST,
    });
    // if (
    //   !!scheduleQuizResult &&
    //   !!scheduleQuizResult.data &&
    //   isString(scheduleQuizResult.data) &&
    //   scheduleQuizResult.data.length > 0
    // ) {
    //   console.log('success');
    // }
  };

  const toggleScheduledDay = (day) => {
    let days = new Set(scheduledDays);
    if (days.has(day)) {
      days.delete(day);
    } else {
      //  checkAccess({
      //    action: [
      //      ACCESS_ACTIONS.SCHEDULE_QUIZZES,
      //      ACCESS_ACTIONS.CREATE_AUTO_QUIZ,
      //    ],
      //    thresholdCount: [
      //      numScheduledQuizzes + days.size + 1,
      //      numAutoQuizzesThisThreshold + days.size + 1,
      //    ],
      //    tier,
      //    type,
      //    showSubscriptionModal,
      //  }).doWithAccess(() => {
      //    setIsScheduling(true);
      days.add(day);
      //  });
    }
    let daysArray = [...days].sort();

    if (daysArray[0] === 0) {
      daysArray.shift();
      daysArray.push(0);
    }
    setScheduledDays(daysArray);
  };

  return (
    <SignupWrapper>
      <div
        style={{
          marginBottom: 40,
          textAlign: 'center',
          fontSize: 18,
        }}
      >
        {isString(userName) && userName.length > 0
          ? `${startCase(userName)}, t`
          : 'T'}
        hank you for your answers.
        <br />
        Based on what you indicated, we are recommending you subscribe to 6Tool{' '}
        {recommendedTiers[0].label}. <br />
        We have highlighed some important capabilities of the different levels.
      </div>
      <div className="signup-header mb-4">
        <div />
        <h2 className="text-center font-weight-bold">Choose Tier</h2>
      </div>
      <PricingTable
        loading={false}
        actions={actions}
        loadingSubscriptionLinkForTierTerm={loadingSubscriptionLinkForTierTerm}
        enableAllTiers={true}
        preselectedTeams={selectedNumTeams}
        recommendedTiers={recommendedTiers}
        newSubscriber={true}
        setLoadingSubscriptionLinkForTierTerm={
          setLoadingSubscriptionLinkForTierTerm
        }
      />
    </SignupWrapper>
  );
};

export default SignupChooseTier;
