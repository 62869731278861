import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Footer from '../Footer';
import { useHistory, withRouter } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../..';
import { connect } from 'react-redux';
import _ from 'lodash';
import { TABS } from '../../../AppTabs';
import FunnelNav from '../../../r-components/Nav/FunnelNav';
import FunnelFooter from '../FunnelFooter';

const FunnelUserLayout = ({ children }) => {
  return (
    <>
      <FunnelNav />
      <div className="main-content">{children}</div>
      <FunnelFooter />
    </>
  );
};

export default FunnelUserLayout;
