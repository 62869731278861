import _ from 'lodash';

export const filterFunctionForPlayerTypeLabel = ({ label, question }) => {
  switch (label.toLowerCase()) {
    case 'hitter':
    case 'hitters':
      return ((question.positions || []).map((p) => p.value) || []).includes(
        'hitter'
      );

    case 'baserunner':
    case 'baserunners':
      return ((question.positions || []).map((p) => p.value) || []).includes(
        'baserunner'
      );

    case '1b':
      return ((question.positions || []).map((p) => p.value) || []).includes(
        '1B'
      );

    case '2b':
      return ((question.positions || []).map((p) => p.value) || []).includes(
        '2B'
      );

    case 'ss':
      return ((question.positions || []).map((p) => p.value) || []).includes(
        'SS'
      );

    case '3b':
      return ((question.positions || []).map((p) => p.value) || []).includes(
        '3B'
      );

    case 'pitchers':
    case 'pitcher':
    case 'p':
      return ((question.positions || []).map((p) => p.value) || []).includes(
        'P'
      );

    case 'catchers':
    case 'catcher':
    case 'c':
      return ((question.positions || []).map((p) => p.value) || []).includes(
        'C'
      );

    case 'lf':
      return ((question.positions || []).map((p) => p.value) || []).includes(
        'LF'
      );
    case 'cf':
      return ((question.positions || []).map((p) => p.value) || []).includes(
        'CF'
      );
    case 'rf':
      return ((question.positions || []).map((p) => p.value) || []).includes(
        'RF'
      );

    case 'outfield':
      return (
        ((question.positions || []).map((p) => p.value) || []).includes('LF') ||
        ((question.positions || []).map((p) => p.value) || []).includes('CF') ||
        ((question.positions || []).map((p) => p.value) || []).includes('RF') ||
        ((question.positions || []).map((p) => p.value) || []).includes('OF')
      );

    default:
      return true;
  }
};

export const filterFunctionForQuestionTypeLabel = ({ label, question }) => {
  switch (label.toLowerCase()) {
    case 'media':
      return (
        (!!question.imageUrl &&
          _.isString(question.imageUrl) &&
          question.imageUrl.length > 0) ||
        (!!question.videoUrl &&
          _.isString(question.videoUrl) &&
          question.videoUrl.length > 0)
      );

    case 'video':
      return (
        !!question.videoUrl &&
        _.isString(question.videoUrl) &&
        question.videoUrl.length > 0
      );

    case 'image':
      return (
        !!question.imageUrl &&
        _.isString(question.imageUrl) &&
        question.imageUrl.length > 0
      );

    case 'scoreboard':
      return question.showScoreboard === true;

    case 'field diagram':
      return question.showField === true;

    case 'timer':
      return question.withTimer === true;

    case 'easy':
      return (
        (_.isInteger(question.difficulty) &&
          !isNaN(question.difficulty) &&
          question.difficulty < 4) ||
        (_.isObject(question.difficulty) &&
          _.isInteger(question.difficulty.value) &&
          !isNaN(question.difficulty.value) &&
          parseInt(question.difficulty.value) < 4)
      );

    case 'medium':
      return (
        (_.isInteger(question.difficulty) &&
          !isNaN(question.difficulty) &&
          question.difficulty > 3 &&
          question.difficulty < 8) ||
        (_.isObject(question.difficulty) &&
          _.isInteger(question.difficulty.value) &&
          !isNaN(question.difficulty.value) &&
          parseInt(question.difficulty.value) > 3 &&
          parseInt(question.difficulty.value) < 8)
      );

    case 'hard':
      return (
        (_.isInteger(question.difficulty) &&
          !isNaN(question.difficulty) &&
          question.difficulty >= 8) ||
        (_.isObject(question.difficulty) &&
          _.isInteger(question.difficulty.value) &&
          !isNaN(question.difficulty.value) &&
          parseInt(question.difficulty.value) >= 8)
      );

    default:
      return true;
  }
};
