import { SET_CURRENT_COACHES, CLEAR_COACHES } from './constants';

/**
 * Set current user
 */
export function setCurrentCoaches(payload) {
  return {
    type: SET_CURRENT_COACHES,
    payload,
  };
}
export function clearCoaches() {
  return {
    type: CLEAR_COACHES,
  };
}
