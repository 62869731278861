import produce from 'immer';

import { CLEAR_PLAYERS, SET_CURRENT_PLAYERS } from './constants';

const INITIAL_STATE = {
  data: [],
  map: {},
  teamRequests: [],
};

export const playersReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_CURRENT_PLAYERS:
        return {
          ...state,
          ...action.payload,
        };

      case CLEAR_PLAYERS:
        return {
          ...INITIAL_STATE,
        };

      default:
        return state;
    }
  });
