import { addPlayers, viewPlayers } from '../../actions/playerActionCreator';

export const players = [
  {
    content: `Welcome to 6-Tool!`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}`,
  },
  {
    selector: '#team-header-players-button',
    content: `Click Players`,
    navigationAction: 'team-header-click-players',
    url: `/teamdetails/${window.location.pathname.split('/')[2]}`,
  },
  {
    dispatch: [viewPlayers],
    selector: 'button#players-add-player-button',
    content: `Click Add players`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/players`,
    navigationAction: 'players-page-click-add-players',
  },
  {
    dispatch: [addPlayers],
    selector: '.main-content .card',
    content: `Fill out player's name and email. Optionally you can add their phone number as well.`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/players`,
  },
  {
    dispatch: [addPlayers],
    selector: '.main-content .card-body form button[type="submit"].btn-primary',
    content: `Click Add Player to create the player. He or she will get an email inviting them to 6Tool and providing them instructions on how to install our mobile app. This will prepare them for when you invite them to their first quiz.`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/players`,
  },
];
