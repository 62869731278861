import React, { useEffect, useState } from 'react';
import Footer from '../Footer';
import AppNav from '../../../r-components/Nav/AppNav';
import { useSelector } from 'react-redux';
import { isNull } from 'lodash';

const SuperAdminLayout = ({ children }) => {
  const [_isSuperAdmin, _setIsSuperAdmin] = useState(null);
  const isSuperAdmin = useSelector((state) => state?.UserState?.isSuperAdmin);

  useEffect(() => {
    if (isSuperAdmin === true) {
      _setIsSuperAdmin(true);
    } else if (isSuperAdmin === false) {
      _setIsSuperAdmin(false);
    } else {
      _setIsSuperAdmin(null);
    }
  }, [isSuperAdmin]);

  return (
    <>
      <AppNav />
      {isNull(_isSuperAdmin) ? (
        <div className="main-content">Loading...</div>
      ) : _isSuperAdmin === true ? (
        <div className="main-content">{children}</div>
      ) : (
        <div className="main-content">
          You do not have access to view this page
        </div>
      )}
      <Footer />
    </>
  );
};

export default SuperAdminLayout;
