import { SET_CURRENT_MEDIA, CLEAR_MEDIA } from './constants';

/**
 * Set current user
 */
export function setCurrentMedia(payload) {
  return {
    type: SET_CURRENT_MEDIA,
    payload,
  };
}
export function clearMedia() {
  return {
    type: CLEAR_MEDIA,
  };
}
