import {
  ADD_NEW_QUESTION,
  CLEAR_QUESTIONS,
  CREATE_NEW_QUIZ,
  QUESTIONS,
  QUESTION_DETAILS,
  EDIT_QUESTION,
  SHOW_CONFIRM_EDIT_QUESTION_MODAL,
  SHOW_CONFIRM_DUPLICATE_QUESTION_MODAL,
  SHOW_CONFIRM_DELETE_QUESTION_MODAL,
  CLOSE_CONFIRM_EDIT_QUESTION_MODAL,
  CLOSE_CONFIRM_DUPLICATE_QUESTION_MODAL,
  CLOSE_CONFIRM_DELETE_QUESTION_MODAL,
  QUESTION_ADDED,
  QUESTION_REMOVED,
  CLEAR_QUESTION_ADDED,
  CLEAR_QUESTION_REMOVED,
  EXPAND_FIRST_QUESTION,
  CLEAR_EXPAND_FIRST_QUESTION,
  PREVIEW_FIRST_QUESTION,
  CLEAR_PREVIEW_FIRST_QUESTION,
  SELECT_FIRST_QUESTION,
  CLEAR_SELECT_FIRST_QUESTION,
  MOVE_SELECTED_QUESTION_RIGHT,
  CLEAR_MOVE_SELECTED_QUESTION_RIGHT,
  TOGGLE_ON_SHOW_SCOREBOARD,
  TOGGLE_ON_SHOW_FIELD,
} from '../reducers/constants';

export const viewQuestions = () => {
  return {
    type: QUESTIONS,
  };
};

export const createNewQuiz = (questionIds) => {
  return {
    type: CREATE_NEW_QUIZ,
    payload: questionIds,
  };
};

export const addQuestion = () => {
  return {
    type: ADD_NEW_QUESTION,
  };
};

export const editQuestion = (question) => {
  return {
    type: EDIT_QUESTION,
    payload: question,
  };
};

export const viewQuestionDetails = (questionId) => {
  return {
    type: QUESTION_DETAILS,
    payload: questionId,
  };
};

export const clearQuestions = () => {
  return {
    type: CLEAR_QUESTIONS,
  };
};

export const showConfirmEditModal = ({ question }) => {
  return {
    type: SHOW_CONFIRM_EDIT_QUESTION_MODAL,
    payload: { question },
  };
};

export const showConfirmDeleteModal = ({ questionId }) => {
  return {
    type: SHOW_CONFIRM_DELETE_QUESTION_MODAL,
    payload: { questionId },
  };
};

export const showConfirmDuplicateModal = ({ questionId }) => {
  return {
    type: SHOW_CONFIRM_DUPLICATE_QUESTION_MODAL,
    payload: { questionId },
  };
};

export const closeConfirmEditModal = () => {
  return {
    type: CLOSE_CONFIRM_EDIT_QUESTION_MODAL,
  };
};

export const closeConfirmDeleteModal = () => {
  return {
    type: CLOSE_CONFIRM_DELETE_QUESTION_MODAL,
  };
};

export const closeConfirmDuplicateModal = () => {
  return {
    type: CLOSE_CONFIRM_DUPLICATE_QUESTION_MODAL,
  };
};

export const questionAdded = ({ question }) => {
  return {
    type: QUESTION_ADDED,
    payload: { question },
  };
};

export const questionRemoved = ({ questionId }) => {
  return {
    type: QUESTION_REMOVED,
    payload: { questionId },
  };
};

export const clearQuestionAdded = () => {
  return {
    type: CLEAR_QUESTION_ADDED,
  };
};

export const clearQuestionRemoved = () => {
  return {
    type: CLEAR_QUESTION_REMOVED,
  };
};

export const expandFirstQuestion = () => {
  return {
    type: EXPAND_FIRST_QUESTION,
  };
};

export const clearExpandFirstQuestion = () => {
  return {
    type: CLEAR_EXPAND_FIRST_QUESTION,
  };
};

export const previewFirstQuestion = () => {
  return {
    type: PREVIEW_FIRST_QUESTION,
  };
};

export const clearPreviewFirstQuestion = () => {
  return {
    type: CLEAR_PREVIEW_FIRST_QUESTION,
  };
};

export const selectFirstQuestion = () => {
  return {
    type: SELECT_FIRST_QUESTION,
  };
};

export const clearSelectFirstQuestion = () => {
  return {
    type: CLEAR_SELECT_FIRST_QUESTION,
  };
};

export const moveSelectedQuestionsRight = () => {
  return {
    type: MOVE_SELECTED_QUESTION_RIGHT,
  };
};

export const clearMoveSelectedQuestionsRight = () => {
  return {
    type: CLEAR_MOVE_SELECTED_QUESTION_RIGHT,
  };
};

export const toggleOnShowScoreboard = () => {
  return {
    type: TOGGLE_ON_SHOW_SCOREBOARD,
  };
};

export const toggleOnShowField = () => {
  return {
    type: TOGGLE_ON_SHOW_FIELD,
  };
};
