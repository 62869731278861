import { CCard, CCardBody, CLink, CTooltip } from '@coreui/react';
import React, { useEffect } from 'react';
import './subscriptionInvoices.scss';
import moment from 'moment';
import _ from 'lodash';
import { FaFilePdf } from 'react-icons/fa';

const SubscriptionInvoices = ({ invoices }) => {
  useEffect(() => {}, [invoices]);
  if (!(_.isArray(invoices) && invoices.length > 0)) {
    return null;
  }
  return (
    <div className="subscription-details-invoices-section">
      <h3>{`Invoices (${invoices.length})`}</h3>
      <div className="subscription-details-invoices-grid">
        <div style={{ gridColumn: 'span 2' }}>Amount</div>
        <div>Invoice Number</div>
        <div>Customer</div>
        <div>Due</div>
        <div>Paid</div>
        <div style={{ gridColumn: 'span 2' }}>Created</div>

        {(invoices || []).map((invoice, index) => (
          <React.Fragment key={`invoice-${index}`}>
            <div>${(invoice.total / 100).toFixed(2)}</div>
            <div>{invoice.status}</div>
            <div>{invoice.number}</div>
            <div>{invoice.customer_email}</div>
            <div>
              {invoice.amount_remaining === 0 ? (
                <div
                  style={{
                    height: 1,
                    margin: 'auto',
                    marginTop: 'calc(50% - 1px)',
                    width: 10,
                    background: '#cccccc',
                  }}
                ></div>
              ) : (
                `${(invoice.amount_remaining / 100).toFixed(2)}`
              )}
            </div>
            <div>
              {moment
                .unix(parseInt(invoice.status_transitions.paid_at))
                .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                .format(`MMM D 'YY, h:mm A`)}
            </div>
            <div>
              {moment
                .unix(parseInt(invoice.created))
                .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                .format(`MMM D 'YY, h:mm A`)}
            </div>
            <div>
              <CTooltip content="Click to download invoice PDF">
                <a href={invoice.invoice_pdf} target="_blank" rel="noreferrer">
                  <FaFilePdf />
                </a>
              </CTooltip>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
export default SubscriptionInvoices;
