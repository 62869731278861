import React from 'react';
import _ from 'lodash';
import { mimeTypeFromURL } from '../../helpers/data';
// import Path, { Svg, Circle, G, Polygon, Line } from 'react-svg-path';
// import Svg, {
//     Circle,
//     G,
//     Path,
//     Polygon,
//     Line,
//   } from 'react-native-svg';

const QuestionMedia = ({ imageUrl, videoUrl, maxWidth, style = {} }) => {
  let hasImageUrl = !!imageUrl && _.isString(imageUrl) && imageUrl.length > 0;
  let hasVideoUrl = !!videoUrl && _.isString(videoUrl) && videoUrl.length > 0;
  if (!hasImageUrl && !hasVideoUrl) {
    return null;
  }
  return (
    <div
      className={`expanded-view-media-wrapper${
        hasImageUrl && hasVideoUrl ? ' media-2' : ''
      }`}
      style={style}
    >
      {hasImageUrl && (
        <div className="thumb" key="image-preview">
          <div className="thumb-inner">
            <img
              src={imageUrl}
              style={!_.isNaN(maxWidth) && { maxWidth }}
              alt=""
            />
          </div>
        </div>
      )}
      {hasVideoUrl && (
        <div className="thumb" key="video-preview">
          <video
            width="auto"
            height="auto"
            style={!_.isNaN(maxWidth) && { maxWidth }}
            controls
          >
            <source src={videoUrl} type={mimeTypeFromURL(videoUrl)} />
          </video>
        </div>
      )}
    </div>
  );
};

export default QuestionMedia;
