import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SignupWrapper from './SignupWrapper';
import { _signInWithEmailAndPassword } from '../../helpers/authHelper';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { isString } from 'lodash';
import { PopupButton, Sidetab, Widget } from '@typeform/embed-react';

const SignupSetupAccountForm = ({ isFunnel = false } = {}) => {
  const userEmail = useSelector((state) => state.UserState?.data?.email);
  const userId = useSelector((state) => state.UserState?.data?.id);

  const [cs, setCs] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const csValue = queryParams.get('cs');
    setCs(csValue);
  }, []);

  let hidden = { uid: userId };
  if (process.env.NODE_ENV === 'development') {
    hidden.env = 'local';
  } else if (process.env.REACT_APP_PROJECT_ID === 'six-tool-staging') {
    hidden.env = 'staging';
  }

  return (
    <SignupWrapper>
      <Helmet>
        {/* <script async src="https://js.stripe.com/v3/pricing-table.js"></script> */}
      </Helmet>
      {/* <Sidetab
        id="JPcnposq"
        style={{ fontSize: 20 }}
        className="my-button"
        hidden={{ uid: 'abcdefg', cs }}
      >
        click to open form in popup
      </Sidetab> */}
      <Widget
        id="JPcnposq"
        hidden={hidden}
        style={{ height: 'calc(100vh - 300px)' }}
      />
    </SignupWrapper>
  );
};

export default SignupSetupAccountForm;
