import { CButton, CLink } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  FaAngleLeft,
  FaCheckSquare,
  FaEnvelope,
  FaLock,
  FaRegSquare,
  FaUser,
  FaUsers,
} from 'react-icons/fa';
import { RiOrganizationChart } from 'react-icons/ri';
import Spinner from '../../../src/pages/component/Spinner';
import { Formik, Form, Field, ErrorMessage } from 'formik';
// import { Button, FormGroup, FormLabel, Input, BootstrapForm.Control.Feedback } from "react-bootstrap";
import {
  Button,
  Form as BootstrapForm,
  FormGroup,
  FormLabel,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import { auth, functions } from '../..';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { TABS } from '../../AppTabs';
import { handleError } from '../../helpers/errors';
import { values } from 'lodash';
import SignupWrapper, { SETUP_STEPS } from './SignupWrapper';
import LoadingMessage from '../../r-components/LoadingMessage/LoadingMessage';
import { _signInWithEmailAndPassword } from '../../helpers/authHelper';
import {
  DEFAULT_ANNUAL_COST_PER_TEAM,
  SUBSCRIPTION_PAYMENT_TERMS,
} from '../../config';
import { Link } from 'react-router-dom';
import { isString } from 'lodash';
import { useSelector } from 'react-redux';

const SignupSchema = Yup.object().shape({
  organizationName: Yup.string().required('Name is required'),
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters'),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  acceptTerms: Yup.boolean().oneOf(
    [true],
    'You must read and accept the terms and conditions'
  ),
});

const SignupCreateFreeUser = ({ isFunnel = false } = {}) => {
  const history = useHistory();
  const userName = useSelector((state) => state.UserState?.data?.name);
  const teamCode = useSelector((state) => state.DataTeamState?.teamData?.code);

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const createNewOwnerUserCallable = functions.httpsCallable(
      'createNewOwnerUserCallable'
    );
    try {
      try {
        const createNewOwnerUserResult = await createNewOwnerUserCallable({
          email: values.email,
          name: values.name,
          password: values.password,
          organizationName: values.organizationName,
          numTeams: values.teams,
          referral_source: localStorage.getItem('referral_source'),
          acceptedTerms: true,
          subscriptionTerm: values.subscriptionTerm,
        });
        const { user_id, url, team_id, code } = createNewOwnerUserResult.data;
        console.log({ user_id, url, team_id, code });
        await _signInWithEmailAndPassword(values.email, values.password);
      } catch (e) {
        handleError({
          error: e,
          toast: e.message.replace('Firebase:', '').trim(),
          internal: `Signup.js:onSubmit`,
        });
      }
    } catch (err) {
      handleError({
        error: err,
        toast: err.message.replace('FirebaseError:', '').trim(),
        internal: `Signup.js:onSubmit::catch`,
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <SignupWrapper>
      {isString(teamCode) && teamCode.length >= 4 ? (
        <div style={{ textAlign: 'center' }}>
          <h1>
            Welcome
            {isString(userName) && userName.length > 0 ? `, ${userName}` : ''}
          </h1>
          <div style={{ fontSize: 20, marginBottom: 40 }}>
            You are just a few clicks away from getting your team set up on
            6Tool!
          </div>
          <h2 style={{ marginTop: 40 }}>Step 1:</h2>
          <div style={{ fontSize: 20 }}>
            Tell your players to{' '}
            <CLink to={TABS.DOWNLOAD.path} target="_blank">
              download the 6Tool app
            </CLink>{' '}
            and enter in code <strong>{teamCode}</strong> to join your team.
          </div>
          <h2 style={{ marginTop: 40 }}>Step 2:</h2>
          <CButton
            style={{ marginTop: 30, fontSize: 30 }}
            color="primary"
            to={TABS.SIGNUP_SETUP_ACCOUNT_FORM.path}
          >
            Finish setting up my account
          </CButton>
        </div>
      ) : (
        <>
          <div className="signup-header mb-4">
            {isFunnel !== true ? (
              <CLink
                className="signup-back-button"
                onClick={() => {
                  history.goBack();
                }}
              >
                <FaAngleLeft />
                Back
              </CLink>
            ) : (
              <div />
            )}
            <h2 className="text-center font-weight-bold">Create Account</h2>
          </div>
          <Formik
            initialValues={{
              name: '',
              email: '',
              password: '',
              confirmPassword: '',
              teams: 1,
              acceptTerms: false,
              subscriptionTerm: SUBSCRIPTION_PAYMENT_TERMS.ANNUAL.label,
            }}
            validationSchema={SignupSchema}
            onSubmit={onSubmit}
            validateOnBlur
            validateOnChange
          >
            {({
              errors,
              touched,
              handleSubmit,
              isSubmitting,
              isValid,
              values,
              setFieldValue,
              validateField,
            }) => (
              <>
                <BootstrapForm onSubmit={handleSubmit}>
                  <div className="signup-create-account-section">
                    <div className="signup-create-account">
                      <FormGroup>
                        <FormGroup>
                          <FormLabel htmlFor="organizationName">
                            Team / Organization Name
                          </FormLabel>
                          <InputGroup>
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                <RiOrganizationChart />
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Field
                              type="text"
                              name="organizationName"
                              id="organizationName"
                              autoComplete="off"
                              className={`form-control ${
                                touched.organizationName &&
                                errors.organizationName
                                  ? 'is-invalid'
                                  : ''
                              }`}
                            />
                            <ErrorMessage
                              component={BootstrapForm.Control.Feedback}
                              name="organizationName"
                              className="invalid-feedback"
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormLabel htmlFor="name">Your Name</FormLabel>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <FaUser />
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Field
                            type="text"
                            name="name"
                            id="name"
                            autoComplete="name"
                            as={BootstrapForm.Control}
                            className={`form-control ${
                              touched.name && errors.name ? 'is-invalid' : ''
                            }`}
                          />
                          <ErrorMessage
                            component={BootstrapForm.Control.Feedback}
                            name="name"
                            className="invalid-feedback"
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup>
                        <FormLabel htmlFor="email">Email</FormLabel>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <FaEnvelope />
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Field
                            type="email"
                            name="email"
                            id="email"
                            autoComplete="email"
                            className={`form-control ${
                              touched.email && errors.email ? 'is-invalid' : ''
                            }`}
                          />
                          <ErrorMessage
                            component={BootstrapForm.Control.Feedback}
                            name="email"
                            className="invalid-feedback"
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup>
                        <FormLabel htmlFor="password">Password</FormLabel>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <FaLock />
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Field
                            type="password"
                            name="password"
                            id="password"
                            autoComplete="new-password"
                            className={`form-control ${
                              touched.password && errors.password
                                ? 'is-invalid'
                                : ''
                            }`}
                          />
                          <ErrorMessage
                            component={BootstrapForm.Control.Feedback}
                            name="password"
                            className="invalid-feedback"
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup>
                        <FormLabel htmlFor="confirmPassword">
                          Confirm Password
                        </FormLabel>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <FaLock />
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Field
                            type="password"
                            name="confirmPassword"
                            id="confirmPassword"
                            autoComplete="new-password"
                            className={`form-control ${
                              touched.confirmPassword && errors.confirmPassword
                                ? 'is-invalid'
                                : ''
                            }`}
                          />
                          <ErrorMessage
                            component={BootstrapForm.Control.Feedback}
                            name="confirmPassword"
                            className="invalid-feedback"
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup style={{ marginBottom: 0 }}>
                        <InputGroup>
                          {values.acceptTerms === true ? (
                            <>
                              <FaCheckSquare
                                size={28}
                                color="#f6d30d"
                                onClick={() => {
                                  setFieldValue('acceptTerms', false, () => {
                                    console.log('here');
                                    validateField('acceptTerms');
                                  });
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <FaRegSquare
                                size={28}
                                color="#e3e6f0"
                                onClick={() => {
                                  setFieldValue('acceptTerms', true, () => {
                                    console.log('here');
                                    validateField('acceptTerms');
                                  });
                                }}
                              />
                            </>
                          )}
                          <div style={{ marginLeft: 8 }}>
                            I have read and accept the{' '}
                            <Link
                              to={TABS.TERMS.path}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                fontWeight: 'boldest',
                                fontFamily: 'AvinerHeavy',
                              }}
                            >
                              Terms & Conditions
                            </Link>
                          </div>
                          <ErrorMessage
                            component={BootstrapForm.Control.Feedback}
                            name="acceptTerms"
                            className="invalid-feedback"
                          />
                        </InputGroup>
                      </FormGroup>
                    </div>
                    <div className="signup-create-account-separator"></div>
                    <div className="signup-create-account-join-existing-wrapper">
                      <div>
                        <div>Coach: is your team already on 6Tool?</div>
                        <CButton
                          color="secondary"
                          variant="outline"
                          onClick={() => {
                            history.push(TABS.SIGNUP_CREATE_COACH.path);
                          }}
                        >
                          Click to join an existing team
                        </CButton>
                      </div>
                      <div>
                        <div>
                          Are you a player looking for a quiz from your coach?
                        </div>
                        <CButton
                          color="secondary"
                          variant="outline"
                          onClick={() => {
                            history.push(TABS.SIGNUP_PLAYER_REDIRECT.path);
                          }}
                        >
                          Download our Mobile App
                        </CButton>
                      </div>
                      {/* <div className="signup-create-account-join-existing-top">
                    If your team is already on 6Tool and you just want to create
                    an account within an existing team
                  </div> */}
                    </div>
                  </div>
                  {isSubmitting ? (
                    <LoadingMessage
                      firstMessage="Creating account..."
                      style={{ height: 53 }}
                    />
                  ) : (
                    <Button
                      type="submit"
                      color="primary"
                      className="btn-block"
                      disabled={
                        !isValid ||
                        !(_.isString(values.email) && values.email.length > 0)
                      }
                    >
                      Sign up
                    </Button>
                  )}
                </BootstrapForm>
              </>
            )}
          </Formik>
          {isFunnel !== true && (
            <div className="text-center my-2">
              <span>
                Have an account?{' '}
                <a href={TABS.LOGIN.path} className="custom-color">
                  Log In
                </a>
              </span>
            </div>
          )}
        </>
      )}
    </SignupWrapper>
  );
};

export default SignupCreateFreeUser;
