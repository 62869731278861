import { SET_CURRENT_OWNERS, CLEAR_OWNERS } from './constants';

/**
 * Set current user
 */
export function setCurrentOwners(payload) {
  return {
    type: SET_CURRENT_OWNERS,
    payload,
  };
}
export function clearOwners() {
  return {
    type: CLEAR_OWNERS,
  };
}
