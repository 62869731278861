import React from 'react';
import coach from '../../assets/coach.png';
import brain from '../../assets/icons/brain.png';
import muscle from '../../assets/icons/muscle.png';
import player from '../../assets/icons/player.png';
import running from '../../assets/icons/running.png';
import {
  Content,
  H2,
  Item,
  LeftContent,
  RightContent,
  Segments,
  SegmentsItem,
  TopContent,
} from './ToolOptionsStyles';

import Container from '../Container/Container';

const ToolOptions = () => {
  return (
    <Container>
      <TopContent>
        <H2>What you get out of 6Tool</H2>
        <p>
          Gain access to customised quizzes, feedback, and analytics for both
          coaches and players
        </p>

        <Segments>
          <SegmentsItem active>I’m a Coach</SegmentsItem>
          <SegmentsItem>I’m a Player</SegmentsItem>
        </Segments>
      </TopContent>
      <Content>
        <RightContent>
          <img src={coach} alt="" />
        </RightContent>

        <LeftContent>
          <Item selected>
            <img src={brain} alt="" />
            <div>
              <strong>Strategic Player Assessment</strong>
              <p>
                Utilise detailed quiz results to gauge your team’s knowledge and
                readiness, allowing for targeted coaching interventions.
              </p>
            </div>
          </Item>
          <Item>
            <img src={running} alt="" />

            <div>
              <strong>Efficient Practice Planning</strong>
              <p>
                Utilise detailed quiz results to gauge your team’s knowledge and
                readiness, allowing for targeted coaching interventions.
              </p>
            </div>
          </Item>
          <Item>
            <img src={muscle} alt="" />

            <div>
              <strong>Team Performance Tracking</strong>
              <p>
                Utilise detailed quiz results to gauge your team’s knowledge and
                readiness, allowing for targeted coaching interventions.
              </p>
            </div>
          </Item>
          <Item>
            <img src={player} alt="" />

            <div>
              <strong>Engagement and Motivation</strong>
              <p>
                Utilise detailed quiz results to gauge your team’s knowledge and
                readiness, allowing for targeted coaching interventions.
              </p>
            </div>
          </Item>
        </LeftContent>
      </Content>
    </Container>
  );
};

export default ToolOptions;
