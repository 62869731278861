import produce from 'immer';

import {
  CLEAR_EVENTS,
  CLEAR_EVENT_PARTICIPANTS,
  CLEAR_EVENT_QUIZZES,
  CLEAR_EVENT_TEAMS,
  SET_EVENTS,
  SET_EVENT_PARTICIPANTS,
  SET_EVENT_QUIZZES,
  SET_EVENT_TEAMS,
} from './constants';
import { Timestamp } from 'firebase/firestore';
import { isArray, isFunction } from 'lodash';

const INITIAL_STATE = {
  events: [],
  eventMap: {},
  current_events: [],
  participants: [],
  teams: [],
  num_events: 0,
  num_current_events: 0,
  num_participants: 0,
  num_teams: 0,
  quizzes: [],
};

export const eventsReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_EVENTS: {
        const events = [...action.payload].map((event) => {
          let visibleDates = {};
          if (isArray(event.visibleDates) && event.visibleDates.length > 0) {
            let dateObjects = event.visibleDates.map((d) => new Date(d));
            const earliestDate = new Date(Math.min(...dateObjects));
            const latestDate = new Date(Math.max(...dateObjects));
            visibleDates = {
              visibleDateText: `${
                earliestDate.getUTCMonth() + 1
              }/${earliestDate.getUTCDate()} - ${
                latestDate.getUTCMonth() + 1
              }/${latestDate.getUTCDate()}`,
            };
          }
          let dateRangeText = `${event.dateRangeText}`;
          if (!dateRangeText.includes(' - ') && dateRangeText.includes('-')) {
            dateRangeText = dateRangeText.replace('-', ' - ');
          }
          return {
            ...event,
            ...{ dateRangeText },
            ...visibleDates,
          };
        });
        const num_events = events.length;
        const now = Timestamp.now();

        const current_events = events.filter((item) => {
          return (
            !!item &&
            !!item.dateRangeStart &&
            !!item.dateRangeEnd &&
            isFunction(item.dateRangeEnd.toDate) &&
            isFunction(item.dateRangeStart.toDate) &&
            item.dateRangeStart.toDate() <= now.toDate() &&
            item.dateRangeEnd.toDate() >= now.toDate()
          );
        });
        const num_current_events = current_events.length;
        const eventMap = events.reduce((acc, event) => {
          acc[event.id] = {
            id: event.id,
            name: event.name,
            visibleDateText: event.visibleDateText,
            city: event.city,
            logoURL: event.logoURL,
          };
          return acc;
        }, {});
        return {
          ...state,
          events,
          eventMap,
          num_events,
          current_events,
          num_current_events,
        };
      }
      case CLEAR_EVENTS:
        return {
          ...INITIAL_STATE,
        };
      case SET_EVENT_PARTICIPANTS: {
        const participants = [...action.payload];
        return {
          ...state,
          participants,
        };
      }
      case CLEAR_EVENT_PARTICIPANTS:
        return {
          ...state,
          participants: INITIAL_STATE.participants,
        };
      case SET_EVENT_TEAMS: {
        const teams = [...action.payload];
        return {
          ...state,
          teams,
        };
      }
      case CLEAR_EVENT_TEAMS:
        return {
          ...state,
          teams: INITIAL_STATE.teams,
        };
      case SET_EVENT_QUIZZES: {
        const quizzes = [...action.payload];
        return {
          ...state,
          quizzes,
        };
      }
      case CLEAR_EVENT_QUIZZES:
        return {
          ...state,
          quizzes: INITIAL_STATE.quizzes,
        };
      default:
        return state;
    }
  });
