import { db, functions, storage } from '..';
import _ from 'lodash';
import { fileTimestamp } from './utils';
import { paramEncode } from '../AppTabs';
export const STORAGE_TYPES = {
  LOGOS: 'logos',
  QUESTION_VIDEOS: 'question_videos',
  QUESTION_IMAGES: 'question_images',
  REPORTS: 'reports',
};
export const storageRefFolder = ({ organization_id, team_id, type }) => {
  let folder = _folder(organization_id, team_id);
  switch (type) {
    case STORAGE_TYPES.LOGOS:
      return `${folder}/logos`;
    case STORAGE_TYPES.QUESTION_IMAGES:
      return `${folder}/questions/images`;
    case STORAGE_TYPES.QUESTION_VIDEOS:
      return `${folder}/questions/videos`;
    case STORAGE_TYPES.REPORTS:
      return `${folder}/reports`;
    default:
      break;
  }
  return folder;
};

const _folder = (organization_id, team_id) => {
  let folder = `error-${new Date().getTime()}`;
  let hasOrg = false;
  let hasTeam = false;
  if (_.isString(organization_id) && organization_id.length > 0) {
    hasOrg = true;
    folder = `org-${organization_id}`;
  }
  if (_.isString(team_id) && team_id.length > 0) {
    hasTeam = true;
    folder = hasOrg
      ? `org-${organization_id}/team-${team_id}`
      : `team-${team_id}`;
  }
  return folder;
};

export const storageRefForLogo = ({ organization_id, team_id, fileName }) => {
  return storage
    .ref(
      storageRefFolder({
        organization_id,
        team_id,
        type: STORAGE_TYPES.LOGOS,
      })
    )
    .child(
      `${
        _.isString(team_id) && team_id.length > 1 ? team_id : organization_id
      }--${fileTimestamp()}.${fileName.split('.').pop()}`
    );
};

export const storageRefForQuestionImage = ({
  organization_id,
  team_id,
  fileName,
  question_id,
}) => {
  return storage
    .ref(
      storageRefFolder({
        organization_id,
        team_id,
        type: STORAGE_TYPES.QUESTION_IMAGES,
      })
    )
    .child(
      `${
        _.isString(question_id) && question_id.length > 1
          ? question_id
          : _.isString(team_id) && team_id.length > 1
          ? team_id
          : organization_id
      }--${fileTimestamp()}.${fileName.split('.').pop()}`
    );
};

export const storageRefForQuestionVideo = ({
  organization_id,
  team_id,
  fileName,
  question_id,
}) => {
  return storage
    .ref(
      storageRefFolder({
        organization_id,
        team_id,
        type: STORAGE_TYPES.QUESTION_VIDEOS,
      })
    )
    .child(
      `${
        _.isString(question_id) && question_id.length > 1
          ? question_id
          : _.isString(team_id) && team_id.length > 1
          ? team_id
          : organization_id
      }--${fileTimestamp()}.${fileName.split('.').pop()}`
    );
};
