// EmbedPage.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import EventLeaderboardViewOnly, {
  ANONYMOUS_TYPE,
} from '../r-views/pages/events/eventLeaderboard/EventLeaderboardViewOnly';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const EmbedEventLeaderboard = () => {
  const query = useQuery();
  const eventID = query.get('eventID');
  const top = query.get('top');
  const anonymous =
    query.get('anon') === 'true' ? ANONYMOUS_TYPE.ALL : query.get('anon'); // ANONYMOUS_TYPE ('all' or 'true','individual','team','none' -- default)
  const showTypeChooser = query.get('showTypeChooser') !== 'false';
  const initialLeaderboardType =
    query.get('initialLeaderboardType') === 'team' ? 'team' : 'individual';

  return (
    <>
      <div></div>
      <div style={{ maxWidth: 1160, margin: '40px auto', width: '100%' }}>
        <div
          className="left-menu-wrapper"
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <img
            style={{
              padding: 10,
              background: '#162630',
              height: 100,
              width: 'auto',
              objectFit: 'contain',
              borderRadius: 4,
              overflow: 'hidden',
            }}
            src="/img/logo.png"
            alt="Logo"
          />
          <h1 style={{ lineHeight: 0.8, textAlign: 'center', marginTop: 20 }}>
            6Tool Leaderboard
          </h1>
          <hr
            style={{
              border: '0.5px solid hsl(0,0%,90%)',
              height: 0,
              width: '50%',
            }}
          />
        </div>
        <EventLeaderboardViewOnly
          eventID={eventID}
          showTypeChooser={showTypeChooser}
          initialLeaderboardType={initialLeaderboardType}
          top={top}
          anonymous={anonymous}
        />
      </div>
      <div></div>
    </>
  );
};

export default EmbedEventLeaderboard;
