import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CLabel,
  CListGroup,
  CListGroupItem,
  CRow,
  CSpinner,
} from '@coreui/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

import { db, firebase } from '../../index';

const StripeTest = () => {
  const [loadingSubscriptions, setLoadingSubscriptions] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [numberOfTeams, setNumberOfTeams] = useState(1);
  const userData = useSelector((state) => state?.UserState);

  const getSubscriptions = useCallback(() => {
    setLoadingSubscriptions(true);
    db.collection('customers')
      .doc(userData.organizationId)
      .collection('subscriptions')
      .where('status', 'in', ['trialing', 'active'])
      .onSnapshot(async (snapshot) => {
        let activeSubscriptions = snapshot.docs.map((doc) => {
          let id = doc.id;
          let data = doc.data();
          return { id, ...data };
        });
        setSubscriptions(activeSubscriptions);
        setLoadingSubscriptions(false);
      });
  }, [userData]);

  const goToBillingPortal = async () => {
    const docRef = await db
      .collection('customers')
      .doc('42bee8cb-95c6-4643-bfcb-7d27bc3bacf1')
      .collection('checkout_sessions')
      .add({
        price: 'price_1N1a2xBwjqXte3DE95V9t1h7',
        quantity: numberOfTeams,
        success_url: `${window.location.origin}/stripe-test`,
        cancel_url: `${window.location.origin}/stripe-test`,
      });
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  };

  const simulateQuantityChangeProratedCost = async () => {
    const functionRef = firebase
      .app()
      .functions()
      .httpsCallable('getProratedCostForPotentialUpdateCallable');
    const { data } = await functionRef({
      subscriptionId: 'sub_1NB4FyBwjqXte3DEF6Qvdztz',
      subscriptionItemId: 'si_NwyCzH1IMpmdDu',
      newQuantity: 300,
      customerId: 'cus_NwyBTdjbfzV2HF',
    });
    console.log(data);
    // window.location.assign(data.url);
  };

  const simulateQuantityChange = async () => {
    const functionRef = firebase
      .app()
      .functions()
      .httpsCallable('updateSubscriptionCallable');
    const { data } = await functionRef({
      subscriptionId: 'sub_1NBUQHBwjqXte3DEbZ0t0XKr',
      newQuantity: numberOfTeams,
    });
    console.log(data);
    // window.location.assign(data.url);
  };

  const goToSubscriptionPortal = async () => {
    const functionRef = firebase
      .app()
      .functions('us-central1')
      .httpsCallable('ext-firestore-stripe-payments-createPortalLink');
    const { data } = await functionRef({
      returnUrl: `${window.location.origin}/stripe-test`,
    });
    window.location.assign(data.url);
  };

  const handleNumberOfTeamsChange = (event) => {
    setNumberOfTeams(event.target.value);
  };

  const renderSubscriptions = () => {
    if (!subscriptions.length) {
      return (
        <>
          <div>
            Number of teams:
            <input
              type="text"
              value={numberOfTeams}
              onChange={handleNumberOfTeamsChange}
            />
          </div>
          <CButton className="btn btn-success" onClick={goToBillingPortal}>
            Subscribe for $300/year
          </CButton>
          <CButton className="btn btn-success" onClick={simulateQuantityChange}>
            Change Quantity
          </CButton>
        </>
      );
    }

    console.log(subscriptions);
    return (
      <>
        <CListGroup>
          {subscriptions.map((subscription) => (
            <CListGroupItem key={subscription.id}>
              <p>
                Period started at{' '}
                {`${format(
                  new Date(subscription.current_period_start.seconds * 1000),
                  'yyyy-MM-dd'
                )}`}
                , will end at{' '}
                {`${format(
                  new Date(subscription.current_period_end.seconds * 1000),
                  'yyyy-MM-dd'
                )}`}{' '}
                - {subscription.quantity} teams
              </p>
            </CListGroupItem>
          ))}
        </CListGroup>
        <CButton className="btn btn-success" onClick={goToSubscriptionPortal}>
          Manage subscription
        </CButton>
        <CButton
          className="btn btn-success"
          onClick={simulateQuantityChangeProratedCost}
        >
          Simulate Prorated Quantity
        </CButton>
        <CButton className="btn btn-success" onClick={simulateQuantityChange}>
          Change Quantity
        </CButton>
      </>
    );
  };

  useEffect(() => {
    const loadData = async () => {
      await getSubscriptions();
    };

    if (userData) {
      loadData();
    }
  }, [getSubscriptions, userData]);

  return (
    <CRow>
      <CCol xs={12}>
        <CCard>
          <div className="p-4">
            <h1>Team subscription: </h1>
            {loadingSubscriptions ? (
              <CSpinner variant="grow" size="md" />
            ) : (
              <>{renderSubscriptions()}</>
            )}
          </div>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default StripeTest;
