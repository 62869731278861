import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Footer from '../Footer';
import { useHistory, withRouter } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../..';
import AppNav from '../../../r-components/Nav/AppNav';
import { connect, useSelector } from 'react-redux';
import _ from 'lodash';
import { TABS } from '../../../AppTabs';
import { Redirect } from 'react-router-dom';

const AdminLayout = ({ userData, children }) => {
  const [user, loading, error] = useAuthState(auth);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const inactive = useSelector(
    (state) => state?.DataSubscriptionState?.inactive === true
  );
  let history = useHistory();

  useEffect(() => {
    // if (inactive === true) {
    //   setShouldRedirect(TABS.OWNER_PROFILE_SUBSCRIPTION.path);
    // }
  }, [inactive]);

  // useEffect(() => {
  //   if (!user && !loading) {
  //     history.push(TABS.LOGIN.path);
  //   } else if (
  //     !loading &&
  //     !!user &&
  //     _.isObject(userData) &&
  //     !_.isEmpty(userData) &&
  //     userData.isOwner !== true
  //   ) {
  //     history.push(TABS.COACH_TEAMS.path);
  //   }
  // }, [user, loading, userData]);

  if (!_.isNil(shouldRedirect) && _.isString(shouldRedirect)) {
    return <Redirect to={shouldRedirect} />;
  }

  return (
    <>
      <AppNav />
      <div className="main-content">{children}</div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.UserState,
    teamData: state.DataTeamState?.teamData || {},
    teams: state.DataTeamState?.data || [],
  };
};

AdminLayout.propTypes = {
  children: PropTypes.node,
};

export default connect(mapStateToProps)(withRouter(AdminLayout));
