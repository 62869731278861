import produce from 'immer';

import { CLEAR_SUBSCRIPTION, SET_CURRENT_SUBSCRIPTION } from './constants';

import { SUBSCRIPTION_TIER } from '../../config';

const INITIAL_STATE = {
  data: [],
  memberSince: null,
  invoices: [],
  customer_id: '',
  inactive: true,
  isEventPartner: false,
  tier: SUBSCRIPTION_TIER.FREE.key,
  tierString: SUBSCRIPTION_TIER.FREE.label,
  numScheduledQuizzes: 0,
  numAutoQuizzesThisThreshold: 0,
  numGroups: 0,
};

export const subscriptionReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_CURRENT_SUBSCRIPTION:
        return {
          ...state,
          ...action.payload,
        };

      case CLEAR_SUBSCRIPTION:
        return {
          ...INITIAL_STATE,
        };

      default:
        return state;
    }
  });
