import {
  SET_EVENTS,
  CLEAR_EVENTS,
  SET_EVENT_PARTICIPANTS,
  CLEAR_EVENT_PARTICIPANTS,
  SET_EVENT_TEAMS,
  CLEAR_EVENT_TEAMS,
  SET_EVENT_QUIZZES,
  CLEAR_EVENT_QUIZZES,
} from './constants';

export function setEvents(payload) {
  return {
    type: SET_EVENTS,
    payload,
  };
}
export function clearEvents() {
  return {
    type: CLEAR_EVENTS,
  };
}
export function setEventParticipants(payload) {
  return {
    type: SET_EVENT_PARTICIPANTS,
    payload,
  };
}
export function clearEventParticipants() {
  return {
    type: CLEAR_EVENT_PARTICIPANTS,
  };
}
export function setEventTeams(payload) {
  return {
    type: SET_EVENT_TEAMS,
    payload,
  };
}
export function clearEventTeams() {
  return {
    type: CLEAR_EVENT_TEAMS,
  };
}
export function setEventQuizzes(payload) {
  return {
    type: SET_EVENT_QUIZZES,
    payload,
  };
}
export function clearEventQuizzes() {
  return {
    type: CLEAR_EVENT_QUIZZES,
  };
}
