import React from 'react';
import './ReadyToStart.scss';
import { TABS } from '../../../AppTabs';

const ReadyToStart = () => (
  <section className="ready-to-start text-center">
    <div className="container">
      <div className="bg-layout">
        <div className="icon-box">
          <img src="img/img6.png" className="img-fluid" alt="" />
        </div>
        <div className="heading">
          <h2>Ready to Start Winning with a Higher Baseball IQ?</h2>
        </div>
        <div className="btn-wrap">
          <a
            href={TABS.SIGNUP_CREATE_ACCOUNT.path}
            className="btn secondary-btn"
          >
            Sign Up Today
          </a>
        </div>
      </div>
    </div>
  </section>
);

export default ReadyToStart;
