import { db, functions, storage } from '..';
import {
  addDoc,
  collection,
  doc,
  getDocs,
  getDoc,
  query,
  serverTimestamp,
  updateDoc,
  where,
  deleteDoc,
} from 'firebase/firestore';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { handleError } from './errors';
import {
  storageRefForQuestionImage,
  storageRefForQuestionVideo,
} from './storage';

export const SortFields = Object.freeze({
  Text: 'Text',
  Created: 'Created',
  Updated: 'Updated',
});
export const SortDirections = Object.freeze({
  ASC: 'ASC',
  DESC: 'DESC',
});

// export const quizzesForOrganization = async ({ organizationId, getPublic }) => {
//   let examsList = [];
//   const q = query(
//     collection(db, 'exams'),
//     where('organizationId', '==', organizationId)
//   );
//   const examsResponseQ = await getDocs(q);
//   examsResponseQ.forEach((exam) => {
//     examsList.push({
//       id: exam.id,
//       ...exam.data(),
//     });
//   });
//   if (getPublic === true) {
//     const a = query(
//       collection(db, 'exams'),
//       where('organizationId', '==', 'all')
//     );
//     const examsResponseA = await getDocs(a);
//     examsResponseA.forEach((exam) => {
//       examsList.push({
//         id: exam.id,
//         ...exam.data(),
//       });
//     });
//   }

//   examsList.sort((a, b) => {
//     return b.created_at - a.created_at;
//   });
//   return examsList;
// };
export const extractLastName = (userName) => {
  if (!(_.isString(userName) && userName.length > 0)) {
    return '';
  }
  let names = userName.split(' ');
  if (!(_.isArray(names) && names.length > 1)) {
    return userName;
  } else {
    names.pop;
    return names.slice(1).join(' ');
  }
};

export const groupsForOrganizationIdAndTeamId = async ({
  organizationId,
  teamId,
}) => {
  try {
    const groupsList = [];

    const q = query(
      collection(db, 'groups'),
      where('organizationId', '==', organizationId)
    );
    const groupsResponse = await getDocs(q);

    groupsResponse.forEach((group) => {
      groupsList.push({
        id: group.id,
        value: group.id,
        label: group.data().title,
        ...group.data(),
      });
    });
    return groupsList;
  } catch (e) {
    handleError({
      error: e,
      internal: 'data.js:groupsForOrganizationIdAndTeamId',
    });
    return null;
  }
};

export const playersInOrganization = async ({
  organizationId,
  teamId,
  excludeUserIds,
  pickFields,
}) => {
  try {
    if (_.isString(organizationId) && organizationId.length > 0) {
      const usersData = [];
      const q = query(
        collection(db, 'users'),
        where('organizationId', 'array-contains', organizationId)
      );
      const usersOrgResponse = await getDocs(q);

      usersOrgResponse.forEach((user) => {
        if (user.exists && !(excludeUserIds || []).includes(user.id)) {
          usersData.push({
            id: user.id,
            ...user.data(),
          });
        }
      });
      if (!!pickFields && _.isArray(pickFields)) {
        return _.map(usersData, function (obj) {
          return _.pick(obj, pickFields);
        });
      }
      return usersData;
    } else {
      return null;
    }
  } catch (e) {
    handleError({
      error: e,
      internal: 'data.js:playersInOrganization',
    });
    return null;
  }
};

export const playersInOrganizationGroups = async ({
  organizationId,
  teamId,
  groupIds,
  excludeUserIds,
  teamPlayersArray,
}) => {
  try {
    let teamPlayers = [];
    if (!(_.isArrary(teamPlayersArray) && teamPlayersArray.length > 0)) {
      teamPlayers = await playersInOrganization({
        organizationId,
        teamId,
        excludeUserIds,
      });
    } else {
      teamPlayers = [...teamPlayersArray];
    }

    return teamPlayers.filter((player) => {
      if ((excludeUserIds || []).includes(player?.id)) {
        return false;
      }
      for (let index = 0; index < groupIds.length; index++) {
        const element = groupIds[index];
        if (player?.groups.includes(groupIds[index])) {
          return true;
        }
      }
      return false;
    });
  } catch (e) {
    handleError({
      error: e,
      internal: 'data.js:playersInOrganizationGroups',
    });
    return null;
  }
};

export const defaultExamData = {
  exam: { title: '' },
  questions: [],
  quiz: { title: '', intros: '', estTime: 20 },
  groups: [],
  inviteAllUsers: false,
};

export const globalCoupons = async () => {
  let coupons = [];
  try {
    const now = new Date();
    let siteSettingsRef = await collection(db, 'site_settings');
    let global_coupons = await getDocs(
      collection(siteSettingsRef, 'kSYbaPtASsbVGbGtVEEF', 'global_coupons'),
      where('start_date', '<=', now),
      where('end_date', '>=', now)
    );
    if (!!global_coupons && global_coupons.size > 0) {
      global_coupons.forEach((global_coupon) => {
        let gcd = global_coupon.data();
        if (!!gcd && _.isObject(gcd) && gcd.active !== false) {
          coupons.push(gcd);
        }
      });
    }
  } catch (error) {
    handleError({
      error,
      internal: 'data.js:globalCoupons - Error getting coupons',
    });
  }
  return coupons;
};

export const questionCategories = async () => {
  let lsQc =
    !!localStorage.getItem('questionCategories') &&
    JSON.parse(localStorage.getItem('questionCategories'));
  if (!!lsQc && _.isObject(lsQc) && Object.keys(lsQc).length > 0) {
    return lsQc;
  }
  const qCf = functions.httpsCallable('questionCategories');
  const qC = await qCf();
  qC.data.trees = {};
  qC.data.flatLabels = {};
  ['categories', 'playerTypes', 'questionTypes'].forEach((cat) => {
    let tR = getTreeRouteForCategory({ tree: qC.data[cat], treeRoutes: {} });
    qC.data.trees[cat] = tR;
  });
  ['playerTypes', 'questionTypes'].forEach((cat) => {
    let fL = getFlatLabelForCategory({ tree: qC.data[cat] });
    qC.data.flatLabels[cat] = fL;
  });
  localStorage.setItem('questionCategories', JSON.stringify(qC.data));
  return qC.data;
};

export const setQuestionSortPreference = ({ field, direction }) => {
  localStorage.setItem(
    'questionSortPreference',
    JSON.stringify({ field, direction })
  );
};

export const questionSortPreference = () => {
  if (!!localStorage.getItem('questionSortPreference')) {
    let storedSP = JSON.parse(localStorage.getItem('questionSortPreference'));
    if (
      !!storedSP &&
      _.isObject(storedSP) &&
      !!storedSP.field &&
      !!storedSP.direction
    ) {
      return storedSP;
    }
  }
  let questionSortPreference = {
    field: SortFields.Text,
    direction: SortDirections.ASC,
  };
  setQuestionSortPreference(questionSortPreference);
  return questionSortPreference;
};

export const getTreeRouteForCategory = ({ tree, routeSoFar, treeRoutes }) => {
  if (_.isArray(tree) && tree.length > 0) {
    tree.forEach((node) => {
      let ids = [...(routeSoFar || []), ...[node.id]];
      treeRoutes[node.id] = ids;
      if (_.isArray(node.children) && node.children.length > 0) {
        let subIdTree = getTreeRouteForCategory({
          tree: node.children,
          routeSoFar: ids,
          treeRoutes,
        });
      }
    });
  }
  return treeRoutes;
};

export const getFlatLabelForCategory = ({ tree }) => {
  let flattened = {};
  if (_.isArray(tree) && tree.length > 0) {
    tree.forEach((child) => {
      if (_.isArray(child.children) && child.children.length > 0) {
        let nested = getFlatLabelForCategory({ tree: child.children });
        flattened = { ...flattened, ...nested };
      }
      let update = {};
      update[child.id] = child.label;
      flattened = { ...flattened, ...update };
    });
  }
  return flattened;
};

export const createQuizAndInviteGroups = async ({
  exam,
  quiz,
  questions,
  groups,
  user,
  organization_id,
  inviter_name,
  inviter_id,
  teamIDs,
}) => {
  try {
    const questionsIds = [];
    const organizationId =
      _.isString(organization_id) && organization_id.length > 0
        ? organization_id
        : user.organizationId || 'a';
    for (
      let questionIndex = 0;
      questionIndex < questions.length;
      questionIndex++
    ) {
      const question = questions[questionIndex];

      if (
        question?.existing === true &&
        question?.edited === false &&
        !_.isEmpty(question?.id) &&
        _.isString(question?.id)
      ) {
        questionsIds.push(question.id);
      } else {
        const options = question.options.map((option, index) => ({
          correct: option.correct,
          default_order: option.order || index,
          points: option.points,
          text: option.text,
        }));
        const questionCreated = await addDoc(collection(db, 'questions'), {
          created_at: serverTimestamp(),
          options: options,
          positions: question.positions,
          showField: question.positions?.length > 0,
          text: question.text,
          title: question.title,
          difficulty: +question.difficulty.value,
          default_order: questionIndex,
          owningOrganization: organizationId || '',
          showScoreboard: question.showScoreboard || false,
          situation: question.situation || null,
          imageUrl: '',
          videoUrl: '',
          type: 1,
        });

        const file = question.files?.[0];
        if (file?.type?.includes('image')) {
          const imageRef = storageRefForQuestionImage({
            organization_id: organizationId,
            question_id: questionCreated.id,
            team_id:
              _.isArray(teamIDs) &&
              teamIDs.length > 0 &&
              _.isString(teamIDs[0]) &&
              teamIDs[0].length > 0
                ? teamIDs[0]
                : null,
            fileName: file.name,
          });
          const snapshot = await imageRef.put(file);
          const imageUrl = await snapshot.ref.getDownloadURL();
          await updateDoc(doc(db, 'questions', questionCreated.id), {
            imageUrl,
          });
          try {
            (async () => {
              let width = 0;
              let height = 0;

              // Set up the FileReader onload event
              const element = new Image();

              // Set up the element onload event
              element.onload = () => {
                // Access the dimensions using the element's naturalWidth and naturalHeight properties
                width = element.naturalWidth;
                height = element.naturalHeight;

                // Do something with the dimensions
                const addMediaCallable =
                  functions.httpsCallable('addMediaCallable');
                addMediaCallable({
                  downloadUrl: imageUrl,
                  question_id: questionCreated.id,
                  organizationId: organizationId,
                  width,
                  height,
                  usage: 'question_image',
                });
              };

              // Assign the FileReader result to the element's src
              element.src = imageUrl;
            })();
          } catch (err) {
            handleError({
              error: err,
              internal: 'data.js - createQuizAndInviteGroups',
            });
          }
        }

        if (file?.type?.includes('video')) {
          const videosRef = storageRefForQuestionVideo({
            organization_id: organizationId,
            team_id:
              _.isArray(teamIDs) &&
              teamIDs.length > 0 &&
              _.isString(teamIDs[0]) &&
              teamIDs[0].length > 0
                ? teamIDs[0]
                : null,
            fileName: file.name,
          });
          const snapshot = await videosRef.put(file);
          const videoUrl = await snapshot.ref.getDownloadURL();

          await updateDoc(doc(db, 'questions', questionCreated.id), {
            videoUrl,
          });
          try {
            (async () => {
              let width = 0;
              let height = 0;

              // Set up the FileReader onload event
              const element = new Image();

              // Set up the element onload event
              element.onload = () => {
                // Access the dimensions using the element's naturalWidth and naturalHeight properties
                width = element.naturalWidth;
                height = element.naturalHeight;

                // Do something with the dimensions
                const addMediaCallable =
                  functions.httpsCallable('addMediaCallable');
                addMediaCallable({
                  downloadUrl: videoUrl,
                  question_id: questionCreated.id,
                  organizationId: organizationId,
                  width,
                  height,
                  usage: 'question_video',
                });
              };

              // Assign the FileReader result to the element's src
              element.src = videoUrl;
            })();
          } catch (err) {
            handleError({
              error: err,
              internal: 'data.js - createQuizAndInviteGroups',
            });
          }
        }

        questionsIds.push(questionCreated.id);
      }
    }
    if (_.isArray(teamIDs) && teamIDs.length > 0) {
      let quizIDs = [];
      let quizTeamMap = {};
      for (let index = 0; index < teamIDs.length; index++) {
        const team_id = teamIDs[index];
        // if (questions.length === questionsIds.length) {
        let quizData = {
          created_at: serverTimestamp(),
          estTime: quiz.estTime,
          intros: quiz.intros,
          questions: questionsIds,
          title: quiz.title,
          teamId: team_id,
          organizationId,
        };

        const quizzesCreated = await addDoc(
          collection(db, 'quizzes'),
          quizData
        );
        quizTeamMap[quizzesCreated.id] = team_id;
        quizIDs.push(quizzesCreated.id);
      }
      let examData = {
        created_at: serverTimestamp(),
        quizzes: quizIDs,
        title: exam.title,
        average: 0,
        organizationId,
      };
      const examCreated = await addDoc(collection(db, 'exams'), examData);

      for (let index = 0; index < quizIDs.length; index++) {
        const quiz_id = quizIDs[index];
        updateDoc(doc(db, 'quizzes', quiz_id), {
          examId: examCreated.id,
        });

        //if we have multiple team_ids, we are going to create multiple quizzes.
        // if we have multiple quizzes, we need to make it so that the invite
        // for a specific group is tied to the correct quiz_id
        // which means we will need to loop and only call this function for
        // whatever team we are on
        const inviteUsersOfGroupsCallable = functions.httpsCallable(
          'inviteUsersOfGroupsCallable'
        );
        try {
          let inviteData = {
            groups: groups.filter((g) => g.teamId === quizTeamMap[quiz_id]),
            organization_id: organizationId,
            owningOrganization: organizationId,
            examId: examCreated.id,
            quizId: quiz_id,
            exam_title: exam.title,
            quiz_title: quiz.title,
            inviter_name,
            inviter_id,
          };
          let inviteResult = await inviteUsersOfGroupsCallable(inviteData);
        } catch (err) {
          console.error(err);
        }
      }
      return true;
    } else {
      // if (questions.length === questionsIds.length) {
      let quizData = {
        created_at: serverTimestamp(),
        estTime: quiz.estTime,
        intros: quiz.intros,
        questions: questionsIds,
        title: quiz.title,
        organizationId,
      };

      const quizzesCreated = await addDoc(collection(db, 'quizzes'), quizData);
      let examData = {
        created_at: serverTimestamp(),
        quizzes: [quizzesCreated.id],
        title: exam.title,
        average: 0,
        organizationId,
      };
      const examCreated = await addDoc(collection(db, 'exams'), examData);
      updateDoc(doc(db, 'quizzes', quizzesCreated.id), {
        examId: examCreated.id,
      });

      //if we have multiple team_ids, we are going to create multiple quizzes.
      // if we have multiple quizzes, we need to make it so that the invite
      // for a specific group is tied to the correct quiz_id
      // which means we will need to loop and only call this function for
      // whatever team we are on
      const inviteUsersOfGroupsCallable = functions.httpsCallable(
        'inviteUsersOfGroupsCallable'
      );
      let inviteData = {
        groups,
        organization_id: organizationId,
        owningOrganization: organizationId,
        examId: examCreated.id,
        quizId: quizzesCreated.id,
        exam_title: exam.title,
        quiz_title: quiz.title,
        inviter_name,
        inviter_id,
      };
      let inviteResult = await inviteUsersOfGroupsCallable(inviteData);
    }
    return true;
  } catch (e) {
    handleError({
      error: e,
      internal: 'data.js - createQuizAndInviteGroups - final catch',
    });
    return false;
  }
};

export const questionOptionTemplates = [
  { imgUrl: '_A_bcd', correctOption: 0, numOptions: 4, isTrueFalse: false },
  { imgUrl: 'a_B_cd', correctOption: 1, numOptions: 4, isTrueFalse: false },
  { imgUrl: 'ab_C_d', correctOption: 2, numOptions: 4, isTrueFalse: false },
  { imgUrl: 'abc_D_', correctOption: 3, numOptions: 4, isTrueFalse: false },
  {
    imgUrl: '_T_f',
    correctOption: 0,
    numOptions: 2,
    isTrueFalse: true,
    newGroup: true,
  },
  { imgUrl: 't_F_', correctOption: 1, numOptions: 2, isTrueFalse: true },
];

export const defaultQuestionOption = {
  correct: false,
  default_order: 0,
  points: 0,
  text: '',
};

export const defaultQuestionOptions = [
  defaultQuestionOption,
  defaultQuestionOption,
];

export const defaultQuestionValues = {
  title: '',
  text: '',
  difficulty: { value: 5, label: '5-Medium' },
  withTimer: false,
  timer: '',
  order: 0,
  positions: [],
  options: defaultQuestionOptions,
  tags: [],
};
export const defaultQuestionSituation = {
  awayScore: 0,
  homeScore: 0,
  homeTeam: true,
  runnerAt1: false,
  runnerAt2: false,
  runnerAt3: false,
  inning: 1,
  inningTopHalf: true,
  outs: 0,
  countBalls: 0,
  countStrikes: 0,
};

export const questionUseOptionTemplate = ({
  formikRef,
  numOptions,
  correctOption,
  isTrueFalse,
}) => {
  if (
    !!formikRef.current &&
    !!formikRef.current.values &&
    numOptions < formikRef.current.values.options.length
  ) {
    formikRef.current.setValues({ options: [] });
  }
  let newOptions = [];
  // formikRef.current.values.slice(0,numOptions);
  for (let index = 0; index < numOptions; index++) {
    let optionText = '';
    if (
      !!formikRef.current &&
      !!formikRef.current.values &&
      index < formikRef.current.values.options.length
    ) {
      optionText = formikRef.current.values.options[index].text || '';
    }
    if (isTrueFalse) {
      if (index === 0) {
        optionText = 'True';
      } else if (index === 1) {
        optionText = 'False';
      } else {
        optionText = '';
      }
    } else {
      if (index === 0 && optionText === 'True') {
        optionText = '';
      } else if (index === 1 && optionText === 'False') {
        optionText = '';
      }
    }
    formikRef.current?.setFieldValue(`options[${index}].text`, optionText);
    formikRef.current?.setFieldValue(
      `options[${index}].points`,
      index === correctOption ? 10 : 0
    );
    formikRef.current?.setFieldValue(
      `options[${index}].correct`,
      index === correctOption
    );
    newOptions.push({
      correct: index === correctOption,
      points: index === correctOption ? 10 : 0,
      index,
      text: optionText,
    });
  }
};

export const fileTypeCountForFiles = ({ files = [] } = {}) => {
  let result = { images: 0, videos: 0 };
  if (!!files && _.isArray(files) && files.length > 0) {
    files.forEach((f) => {
      if (!!f.type && _.isString(f.type) && f.type.length > 0) {
        if (f.type.includes('image')) {
          result.images = result.images + 1;
        }
        if (f.type.includes('video')) {
          result.videos = result.videos + 1;
        }
      }
    });
  }
  return result;
};

export const createQuestion = async ({ data }) => {
  const questionCreated = await addDoc(collection(db, 'questions'), {
    ...{
      created_at: serverTimestamp(),
    },
    ..._.omit(data, ['files']),
  });
  (async () => {
    const file = data.files?.[0];
    if (file?.type?.includes('image')) {
      const imageRef = storageRefForQuestionImage({
        question_id: questionCreated.id,
        organization_id: data.owningOrganization,
        fileName: file.name,
      });

      const snapshot = await imageRef.put(file);
      const imageUrl = await snapshot.ref.getDownloadURL();
      await updateDoc(doc(db, 'questions', questionCreated.id), {
        imageUrl,
      });
      try {
        (async () => {
          let width = 0;
          let height = 0;

          // Set up the FileReader onload event
          const element = new Image();

          // Set up the element onload event
          element.onload = () => {
            // Access the dimensions using the element's naturalWidth and naturalHeight properties
            width = element.naturalWidth;
            height = element.naturalHeight;

            // Do something with the dimensions
            const addMediaCallable =
              functions.httpsCallable('addMediaCallable');
            addMediaCallable({
              downloadUrl: imageUrl,
              question_id: questionCreated.id,
              organizationId: data.owningOrganization,
              width,
              height,
              usage: 'question_image',
            });
          };

          // Assign the FileReader result to the element's src
          element.src = imageUrl;
        })();
      } catch (err) {
        handleError({
          error: err,
          internal: 'data.js - createQuestions',
        });
      }
    }

    if (file?.type?.includes('video')) {
      const videosRef = storageRefForQuestionImage({
        organization_id: data.owningOrganization,
        fileName: file.name,
        question_id: questionCreated.id,
      });
      const snapshot = await videosRef.put(file);
      const videoUrl = await snapshot.ref.getDownloadURL();

      await updateDoc(doc(db, 'questions', questionCreated.id), {
        videoUrl,
      });
      try {
        (async () => {
          let width = 0;
          let height = 0;

          // Set up the FileReader onload event
          const url = URL.createObjectURL(file);
          const element = document.createElement('video');

          // Set up the element onload event
          element.onloadedmetadata = (evt) => {
            // Access the dimensions using the element's naturalWidth and naturalHeight properties
            width = element.videoWidth;
            height = element.videoHeight;

            // Do something with the dimensions
            const addMediaCallable =
              functions.httpsCallable('addMediaCallable');
            addMediaCallable({
              downloadUrl: videoUrl,
              question_id: questionCreated.id,
              organizationId: data.owningOrganization,
              width,
              height,
              usage: 'question_video',
            });
            URL.revokeObjectURL(url);
          };

          element.src = url;
          element.load();
        })();
      } catch (err) {
        handleError({
          error: err,
          internal: 'data.js - createQuizAndInviteGroups',
        });
      }
    }
  })();
};

export const getObjectDiff = (obj1, obj2) => {
  if (!(!!obj1 && _.isObject(obj1))) {
    return Object.keys(obj2 || {});
  }
  if (!(!!obj2 && _.isObject(obj2))) {
    return Object.keys(obj1 || {});
  }
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!Object.prototype.hasOwnProperty.call(obj2, key)) {
      result.push(key);
    } else if (_.isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

  return diff;
};

export const updateQuestion = async ({ questionId, data }) => {
  const questionUpdated = await updateDoc(doc(db, 'questions', questionId), {
    ...{ updated_at: serverTimestamp() },
    ..._.omit(data, ['files']),
  });
  // const questionCreated = await addDoc(collection(db, 'questions'), {
  //   ...{
  //     created_at: serverTimestamp(),
  //   },
  //   ...data,
  // });

  const file = data.files?.[0];
  if (file?.type?.includes('image')) {
    const imageRef = storageRefForQuestionImage({
      organization_id: data.owningOrganization,
      question_id: questionId,
      fileName: file.name,
    });

    const snapshot = await imageRef.put(file);
    const imageUrl = await snapshot.ref.getDownloadURL();
    await updateDoc(doc(db, 'questions', questionId), {
      imageUrl,
    });
    try {
      (async () => {
        let width = 0;
        let height = 0;

        // Set up the FileReader onload event
        const element = new Image();

        // Set up the element onload event
        element.onload = () => {
          // Access the dimensions using the element's naturalWidth and naturalHeight properties
          width = element.naturalWidth;
          height = element.naturalHeight;

          // Do something with the dimensions
          const addMediaCallable = functions.httpsCallable('addMediaCallable');
          addMediaCallable({
            downloadUrl: imageUrl,
            question_id: questionId,
            organizationId: data.owningOrganization,
            width,
            height,
            usage: 'question_image',
          });
        };

        // Assign the FileReader result to the element's src
        element.src = imageUrl;
      })();
    } catch (err) {
      handleError({
        error: err,
        internal: 'data.js - updateQuestion',
      });
    }
  }

  if (file?.type?.includes('video')) {
    const videosRef = storageRefForQuestionVideo({
      organization_id: data.owningOrganization,
      question_id: questionId,
      fileName: file.name,
    });
    const snapshot = await videosRef.put(file);
    const videoUrl = await snapshot.ref.getDownloadURL();

    await updateDoc(doc(db, 'questions', questionId), {
      videoUrl,
    });
    try {
      (async () => {
        let width = 0;
        let height = 0;

        // Set up the FileReader onload event
        const element = new Image();

        // Set up the element onload event
        element.onload = () => {
          // Access the dimensions using the element's naturalWidth and naturalHeight properties
          width = element.naturalWidth;
          height = element.naturalHeight;

          // Do something with the dimensions
          const addMediaCallable = functions.httpsCallable('addMediaCallable');
          addMediaCallable({
            downloadUrl: videoUrl,
            question_id: questionId,
            organizationId: data.owningOrganization,
            width,
            height,
            usage: 'question_video',
          });
        };

        // Assign the FileReader result to the element's src
        element.src = videoUrl;
      })();
    } catch (err) {
      handleError({
        error: err,
        internal: 'data.js - updateQuestion',
      });
    }
  }
};

export const duplicateQuestion = async ({
  questionId,
  owningOrganization,
  hideOriginal = false,
}) => {
  try {
    let duplicateQuestionCallable = functions.httpsCallable(
      'duplicateQuestionCallable'
    );
    let duplicateQuestionResult = await duplicateQuestionCallable({
      question_id: questionId,
      owningOrganization,
      hideOriginal,
    });
    if (
      _.isObject(duplicateQuestionResult) &&
      _.isObject(duplicateQuestionResult.data)
    ) {
      return duplicateQuestionResult.data;
    }
    return false;
  } catch (error) {
    handleError({
      error,
      internal: 'data.js:duplicateQuestion',
    });
    return false;
  }
};

export const deleteQuestion = async ({ questionId, owningOrganization }) => {
  if (owningOrganization === 'all') {
    return false;
  }
  try {
    let deleteQuestionCallable = functions.httpsCallable(
      'deleteQuestionCallable'
    );
    let deleteQuestionResult = await deleteQuestionCallable({
      question_id: questionId,
      owningOrganization,
    });

    toast('Deleting question. It may take a moment to remove from view.', {
      type: 'success',
    });
  } catch (error) {
    handleError({
      error,
      internal: 'data.js:deleteQuestion',
    });
    return false;
  }
};

export const sanitizePhoneNumber = (phoneNumber) => {
  if (!(!!phoneNumber && _.isString(phoneNumber) && phoneNumber.length > 0)) {
    return null;
  }
  let usedPlus = phoneNumber.length > 0 && ('' + phoneNumber).charAt(0) === '+';
  let pN = ('' + phoneNumber).replace(/\D+/g, '');
  if (usedPlus) {
    pN = `+${pN}`;
  }
  pN = `+1${pN}`;
  pN = pN.replace('+1+', '+');
  if (pN.startsWith('+1')) {
    return pN.substring(0, 12);
  }
  return pN;
};
export const desanitizePhoneNumber = (phoneNumber) => {
  if (!(!!phoneNumber && _.isString(phoneNumber) && phoneNumber.length > 0)) {
    return '';
  }
  let usedPlus = phoneNumber.length > 0 && ('' + phoneNumber).charAt(0) === '+';
  let pN = ('' + phoneNumber).replace(/\D+/g, '');
  if (usedPlus) {
    pN = `+${pN}`;
  }
  if (pN.startsWith('+1')) {
    return pN.substring(2, 12);
  }
  return pN;
};

export const mimeTypeFromURL = (url) => {
  let extension = url.split(/[#?]/)[0].split('.').pop().trim();
  switch (extension) {
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
    case 'mp4':
      return 'video/mp4';
    case 'mpeg':
      return 'video/mpeg';
    case 'gif':
      return 'image/gif';
    case 'webm':
      return 'video/webm';
    case 'webp':
      return 'image/webp';
    case 'mov':
      return 'video/quicktime';

    default:
      return '';
  }
};

export const orgAndTeamData = ({ orgData, teamData }) => {
  const hasOrg = _.isString(orgData?.data?._id) && orgData.data._id.length > 0;
  const hasTeam =
    _.isString(teamData?.teamData?._id) && teamData.teamData._id.length > 0;
  const org = {
    name: orgData?.data?.title || 'Full',
    imageUrl: orgData?.data?.imageUrl || '/img/dummy.jpg',
    id: hasOrg ? orgData.data.id : '',
  };
  const team = hasTeam
    ? {
        name: teamData.teamData.name || 'Team',
        id: teamData.teamData._id,
        imageUrl: teamData.teamData.imageUrl || '/img/dummy.jpg',
      }
    : null;
  const name = hasTeam ? team.name : org.name;
  const id = hasTeam ? team._id : org._id;
  const imageUrl = hasTeam ? team.imageUrl : org.imageUrl;
  return { hasOrg, hasTeam, org, team, name, id, imageUrl };
};

export const STATE_OPTION_VALUES = [
  { value: 'AL', label: 'AL' },
  { value: 'AK', label: 'AK' },
  { value: 'AZ', label: 'AZ' },
  { value: 'AR', label: 'AR' },
  { value: 'CA', label: 'CA' },
  { value: 'CO', label: 'CO' },
  { value: 'CT', label: 'CT' },
  { value: 'DE', label: 'DE' },
  { value: 'DC', label: 'DC' },
  { value: 'FL', label: 'FL' },
  { value: 'GA', label: 'GA' },
  { value: 'HI', label: 'HI' },
  { value: 'ID', label: 'ID' },
  { value: 'IL', label: 'IL' },
  { value: 'IN', label: 'IN' },
  { value: 'IA', label: 'IA' },
  { value: 'KS', label: 'KS' },
  { value: 'KY', label: 'KY' },
  { value: 'LA', label: 'LA' },
  { value: 'ME', label: 'ME' },
  { value: 'MD', label: 'MD' },
  { value: 'MA', label: 'MA' },
  { value: 'MI', label: 'MI' },
  { value: 'MN', label: 'MN' },
  { value: 'MS', label: 'MS' },
  { value: 'MO', label: 'MO' },
  { value: 'MT', label: 'MT' },
  { value: 'NE', label: 'NE' },
  { value: 'NV', label: 'NV' },
  { value: 'NH', label: 'NH' },
  { value: 'NJ', label: 'NJ' },
  { value: 'NM', label: 'NM' },
  { value: 'NY', label: 'NY' },
  { value: 'NC', label: 'NC' },
  { value: 'ND', label: 'ND' },
  { value: 'OH', label: 'OH' },
  { value: 'OK', label: 'OK' },
  { value: 'OR', label: 'OR' },
  { value: 'PA', label: 'PA' },
  { value: 'RI', label: 'RI' },
  { value: 'SC', label: 'SC' },
  { value: 'SD', label: 'SD' },
  { value: 'TN', label: 'TN' },
  { value: 'TX', label: 'TX' },
  { value: 'UT', label: 'UT' },
  { value: 'VT', label: 'VT' },
  { value: 'VA', label: 'VA' },
  { value: 'WA', label: 'WA' },
  { value: 'WV', label: 'WV' },
  { value: 'WI', label: 'WI' },
  { value: 'WY', label: 'WY' },
];

export const invitePlayersToQuiz = async ({
  organization_id,
  team_id,
  quiz_id,
  exam_id,
  user_ids,
}) => {
  try {
    (async () => {
      try {
        let invitePlayersToQuizCallable = functions.httpsCallable(
          'invitePlayersToQuizCallable'
        );
        let inviteResult = await invitePlayersToQuizCallable({
          organization_id,
          team_id,
          quiz_id,
          exam_id,
          user_ids,
        });
        if (inviteResult?.data === true) {
          toast('Player invited to quiz', {
            type: 'success',
          });
        } else {
          toast('There was an error inviting player to the quiz', {
            type: 'error',
          });
        }
      } catch (error) {
        handleError({
          error,
          toast: 'There was an error inviting player to the quiz',
          internal:
            'data.js:invitePlayersToQuiz - There was an error inviting player to the quiz',
        });
      }
    })();
  } catch (error) {
    handleError({
      error,
      toast: 'There was an error inviting player to the quiz',
      internal:
        'data.js:invitePlayersToQuiz::catch - There was an error inviting player to the quiz',
    });
  }
};

export const uninvitePlayersToQuiz = async ({
  organization_id,
  team_id,
  quiz_id,
  exam_id,
  user_ids,
}) => {
  try {
    (async () => {
      try {
        let uninvitePlayersToQuizCallable = functions.httpsCallable(
          'uninvitePlayersToQuizCallable'
        );
        let inviteResult = await uninvitePlayersToQuizCallable({
          organization_id,
          team_id,
          quiz_id,
          exam_id,
          user_ids,
        });
        if (inviteResult?.data === true) {
          toast(
            `Player${user_ids.length === 1 ? '' : 's'} no longer ha${
              user_ids.length === 1 ? `'s` : `ve'`
            } access to quiz`,
            {
              type: 'success',
            }
          );
        } else {
          toast(
            `There was an error revoking player${
              user_ids.length === 1 ? `'s` : `s'`
            } invite${user_ids.length === 1 ? `'s` : `s'`} to the quiz`,
            {
              type: 'error',
            }
          );
        }
      } catch (error) {
        handleError({
          error,
          toast: `There was an error revoking player${
            user_ids.length === 1 ? `'s` : `s'`
          } invite${user_ids.length === 1 ? `'s` : `s'`} to the quiz`,
          internal: `data.js:uninvitePlayersToQuiz - There was an error revoking player${
            user_ids.length === 1 ? `'s` : `s'`
          } invite${user_ids.length === 1 ? `'s` : `s'`} to the quiz`,
        });
      }
    })();
  } catch (error) {
    handleError({
      error,
      toast: `There was an error revoking player${
        user_ids.length === 1 ? `'s` : `s'`
      } invite${user_ids.length === 1 ? `'s` : `s'`} to the quiz`,
      internal: `data.js:uninvitePlayersToQuiz - There was an error revoking player${
        user_ids.length === 1 ? `'s` : `s'`
      } invite${user_ids.length === 1 ? `'s` : `s'`} to the quiz`,
    });
  }
};

export const timestampDaysInFuture = (timestampInSeconds) => {
  const currentDate = new Date();
  const futureDate = new Date(timestampInSeconds * 1000); // Convert seconds to milliseconds

  // Calculate the time difference in milliseconds
  const timeDifference = futureDate.getTime() - currentDate.getTime();

  // Calculate the number of days
  const daysInFuture = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return daysInFuture;
};
