import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CLink,
  CRow,
  CTooltip,
} from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { FaCircle, FaEdit, FaInfoCircle, FaUserPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Loading } from '../../../r-reusable';
import _ from 'lodash';
import CoachWrapper from '../../../pages/component/CoachWrapper';
import { TABS } from '../../../AppTabs';
import { orgAndTeamData } from '../../../helpers/data';
import {
  ACCESS_ACTIONS,
  canDoAction,
  checkAccess,
} from '../../../helpers/access';
import OrgTeamImageHeader from '../../../r-components/OrgTeamImageHeader/OrgTeamImageHeader';
import CoachRoles from '../../../r-components/CoachRoles/CoachRoles';
import TeamCodeExplanationModal from '../teams/TeamCodeExplanationModal';
import useAccountSelectors from '../../../hooks/useAccountSelectors';

const Coaches = () => {
  const queryPage = useLocation().search.match(/page=([0-9]+)/, '');
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const dispatch = useDispatch();
  const coachesData = useSelector((state) => state.DataCoachState?.data || []);
  const ownersData = useSelector((state) => state.DataOwnerState?.data || []);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(currentPage);
  const currentUser = useSelector((state) => state?.UserState);
  const orgData = useSelector((state) => state.DataOrganizationState);
  const teamData = useSelector((state) => state.DataTeamState);
  const [orgTeamData, setOrgTeamData] = useState({});
  const [tableFields, setTableFields] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const coachRequests = useSelector(
    (state) => state.DataCoachState?.teamRequests || []
  );
  const [showTeamCodeExplanationModal, setShowTeamCodeExplanationModal] =
    useState(false);
  const { tier, type, showSubscriptionModal, goToUrl } = useAccountSelectors();

  useEffect(() => {
    currentPage !== page && setPage(currentPage);
  }, [currentPage, page]);

  useEffect(() => {
    let orgTeam = orgAndTeamData({ orgData, teamData });
    setOrgTeamData(orgTeam);
    let tableFields = [
      { key: 'name', label: 'Name', _classes: 'font-weight-bold' },
    ];
    // if (orgTeam.hasTeam !== true) {
    //   tableFields.push({ key: 'team', label: 'Team(s)' });
    // }
    tableFields.push({ key: 'email', label: 'Email' });
    if (orgTeam.hasTeam !== true) {
      tableFields.push({ key: 'teams', label: 'Team(s)' });
    } else {
      tableFields.push({ key: 'role', label: 'Role(s)' });
    }

    tableFields.push({
      key: 'show_details',
      label: '',
      sorter: false,
    });
    setTableFields(tableFields);
  }, [orgData, teamData]);

  const sortByRolesAndName = (a, b) => {
    const rolePriority = ['superAdmin', 'admin', 'coach', 'assistant'];

    // Helper function to get the highest priority role
    const getRolePriority = (roles) => {
      for (let i = 0; i < rolePriority.length; i++) {
        if ((roles || []).includes(rolePriority[i])) {
          return i;
        }
      }
      return rolePriority.length; // Default priority for roles not in the list
    };

    const priorityA = getRolePriority(a.roles);
    const priorityB = getRolePriority(b.roles);

    if (priorityA !== priorityB) {
      return priorityA - priorityB;
    }

    // If priorities are the same, sort by name
    return a.name.localeCompare(b.name);
  };

  useEffect(() => {
    const _handleCoach = (u) => {
      let p = _.pick(u, ['_id', 'name', 'email', 'org_team']);
      p.id = p._id;
      if (
        _.isString(teamData?.teamData?._id) &&
        teamData.teamData._id.length > 0
      ) {
        delete p._teams;
        p.roles = (
          u.org_team?.[orgData.data.id]?.[teamData.teamData._id]?.roles || []
        ).filter((r) => r !== 'owner');
      } else {
        p.teams = Object.entries(u.org_team[orgData.data.id] || {})
          .map(([teamId, teamVal]) => {
            return {
              id: teamId,
              roles: (teamVal.roles || []).filter((r) => r !== 'owner'),
            };
          })
          .filter((t) => {
            return (
              !t.id.startsWith('_') && Object.keys(teamData.map).includes(t.id)
            );
          });
      }
      delete p.org_team;
      return p;
    };
    if (_.isString(orgData?.data?.id) && orgData.data.id.length > 0) {
      let newCoaches = [...(coachesData || [])].map((u) => {
        return _handleCoach(u);
      });
      let coachesIDs = newCoaches.map((c) => c._id);
      let ownerCoaches = [...(ownersData || [])]
        .filter((o) => !coachesIDs.includes(o._id))
        .map((u) => {
          return _handleCoach(u);
        });
      const sorted = [...newCoaches, ...ownerCoaches];
      sorted.sort(sortByRolesAndName);
      setCoaches(sorted);
    }
  }, [orgData, teamData, coachesData, ownersData]);

  return (
    <CoachWrapper>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader className="d-flex justify-content-between">
              <OrgTeamImageHeader
                orgTeamData={orgTeamData}
                title="Coaches"
                rightView={
                  <>
                    {_.isArray(coachRequests) && coachRequests.length > 0 && (
                      <CTooltip
                        content={`There ${
                          coachRequests.length === 1
                            ? 'is 1 coach who has'
                            : `are ${coachRequests.length} coaches who have`
                        } requested to join your team${
                          _.isArray(teamData?.data) &&
                          teamData.data.length > 1 &&
                          orgTeamData.hasTeam === true
                            ? ''
                            : 's'
                        }`}
                        placement="bottom"
                      >
                        <CLink
                          style={{ marginRight: 30, position: 'relative' }}
                          to={TABS.COACH_TEAMS_COACH_REQUESTS.path}
                        >
                          <FaUserPlus
                            style={{ marginTop: -5, marginRight: 4 }}
                          />
                          {`Team join requests`}
                          <FaCircle
                            style={{ position: 'absolute', top: -3 }}
                            color="red"
                          />
                        </CLink>
                      </CTooltip>
                    )}
                    {!!teamData?.teamData?.code && (
                      <CTooltip
                        content="Click to learn how to use this to make adding players to this team easier"
                        placement="bottom"
                      >
                        <CLink
                          onClick={() => setShowTeamCodeExplanationModal(true)}
                          style={{
                            display: 'flex',
                            fontFamily: 'AvinerHeavy',
                            fontWeight: 900,
                            alignItems: 'center',
                            gap: 4,
                          }}
                        >
                          <FaInfoCircle style={{ marginTop: -5 }} />
                          {`Team Code: ${teamData.teamData.code}`}
                        </CLink>
                      </CTooltip>
                    )}
                    {canDoAction({
                      action: ACCESS_ACTIONS.CREATE_COACH,
                      type: currentUser?.data?._roles,
                      tier,
                    }).canView && (
                      <CButton
                        color="primary"
                        id="coaches-add-coach-button"
                        onClick={() => {
                          checkAccess({
                            action: ACCESS_ACTIONS.CREATE_COACH,
                            tier,
                            type,
                            showSubscriptionModal,
                            goToUrl,
                            thresholdCount:
                              _.isString(teamData?.teamData?._id) &&
                              teamData.teamData._id.length > 0 &&
                              _.isArray(teamData?.teamData?.coaches)
                                ? teamData.teamData.coaches.length + 1
                                : 1,
                          }).navigateWithAccess(TABS.OWNER_COACHES_CREATE.path);
                          // dispatch(
                          //   triggerTourNavigation({
                          //     tourName: 'onboard',
                          //     navigationAction:
                          //       'coaches-page-click-add-coaches',
                          //     stepOffset: 1,
                          //   })
                          // );
                        }}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <span className="m-0 mr-2 h3">+</span> Add coach
                      </CButton>
                    )}
                  </>
                }
              />
            </CCardHeader>

            <CCardBody>
              {loading ? (
                <Loading />
              ) : (
                <CDataTable
                  striped
                  items={coaches}
                  itemsPerPageSelect={true}
                  itemsPerPage={50}
                  pagination={true}
                  hover
                  activePage={page}
                  tableFilter={true}
                  // sorter
                  // sorterValue={{ column: 'name', asc: true }}
                  fields={tableFields}
                  noItemsViewSlot={
                    <p className="d-flex align-content-center justify-content-center pt-4 pb-4">
                      <FiAlertCircle size="30" color="#e55353" />
                      <span className="pl-2 font-xl">No coaches added.</span>
                    </p>
                  }
                  scopedSlots={{
                    name: (coach) => (
                      <td className="font-weight-bold">
                        <Link
                          to={TABS.OWNER_COACHES_DETAILS.pathVar(
                            _.pick(coach, ['id', 'name'])
                          )}
                        >
                          {coach.name}
                        </Link>
                      </td>
                    ),
                    teams: (coach) => {
                      return (
                        <td>
                          {(coach.teams || []).map(
                            ({ id = '', roles = [] } = {}, index) => (
                              <div
                                key={`${id}--${index}`}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 6,
                                }}
                              >
                                {_.isObject(teamData) &&
                                _.isObject(teamData.map) &&
                                _.isObject(teamData.map[id]) &&
                                _.isString(teamData.map[id].imageUrl) &&
                                teamData.map[id].imageUrl.length > 0 ? (
                                  <Link
                                    to={TABS.COACH_TEAM.pathVar({
                                      id: id,
                                      name: teamData?.map[id].name,
                                    })}
                                  >
                                    <img
                                      src={teamData.map[id].imageUrl}
                                      style={{
                                        height: 20,
                                        width: 20,
                                        objectFit: 'contain',
                                        marginRight: 6,
                                        marginTop: -2,
                                      }}
                                    />
                                    {_.isObject(teamData.map) &&
                                    _.isObject(teamData.map[id]) &&
                                    _.isString(teamData?.map[id].name)
                                      ? teamData.map[id].name
                                      : ''}
                                  </Link>
                                ) : (
                                  <Link
                                    to={TABS.COACH_TEAM.pathVar({
                                      id,
                                      name:
                                        _.isObject(teamData) &&
                                        _.isObject(teamData.map) &&
                                        _.isObject(teamData.map[id]) &&
                                        _.isString(teamData?.map[id].name)
                                          ? teamData?.map[id].name
                                          : '',
                                    })}
                                  >
                                    {_.isObject(teamData) &&
                                    _.isObject(teamData.map) &&
                                    _.isObject(teamData.map[id]) &&
                                    _.isString(teamData?.map[id].name)
                                      ? teamData.map[id].name
                                      : ''}
                                  </Link>
                                )}
                                <CoachRoles type={roles} />
                              </div>
                            )
                          )}
                        </td>
                      );
                    },
                    role: (coach) => (
                      <td>
                        <CoachRoles type={coach.roles} text={true} />
                      </td>
                    ),
                    email: (coach) => <td>{coach.email}</td>,
                    show_details: (coach) => (
                      <td className="text-right">
                        {
                          <Link
                            to={TABS.OWNER_COACHES_EDIT.pathVar(
                              _.pick(coach, ['id', 'name'])
                            )}
                          >
                            <FaEdit />
                          </Link>
                        }
                      </td>
                    ),
                  }}
                />
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      {!!teamData?.teamData?.code && (
        <TeamCodeExplanationModal
          show={showTeamCodeExplanationModal}
          code={teamData.teamData.code}
          type="coach"
          onClose={() => {
            setShowTeamCodeExplanationModal(false);
          }}
        />
      )}{' '}
    </CoachWrapper>
  );
};

export default Coaches;
