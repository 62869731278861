import React from 'react';
import Spinner from '../../pages/component/Spinner';

function FlexChildLoadingOverlay({ text, spinner, ...rest }) {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        marginRight: '-100%',
        height: '100%',
        background: 'rgba(0,0,0,0.5)',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {spinner !== false && <Spinner display={true} />} {text || 'Loading...'}
    </div>
  );
}

export default FlexChildLoadingOverlay;
