import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { orgAndTeamData } from '../../helpers/data';

const OrgTeamImageHeader = ({
  orgTeamData = null,
  title = '',
  imageSize = 40,
  leftView = null,
  rightView = null,
} = {}) => {
  const orgData = useSelector((state) => state.DataOrganizationState);
  const teamData = useSelector((state) => state.DataTeamState);
  const isMounted = useRef(null);
  const [headerData, setHeaderData] = useState({});

  useEffect(() => {
    isMounted.current = true;
    if (_.isObject(orgTeamData) && !_.isNil(orgTeamData)) {
      setHeaderData(orgTeamData);
    } else {
      setTimeout(() => {
        if (isMounted.current === true && _.isEmpty(headerData)) {
          let orgTeam = orgAndTeamData({ orgData, teamData });
          if (isMounted.current == true) {
            setHeaderData(orgTeam);
          }
        }
      }, 100);
    }
    return () => {
      isMounted.current = false;
    };
  }, [orgTeamData, orgData, teamData]);

  useEffect(() => {
    isMounted.current = true;
    let orgTeam = orgAndTeamData({ orgData, teamData });
    if (isMounted.current == true) {
      setHeaderData(orgTeam);
    }
    return () => {
      isMounted.current = false;
    };
  }, [orgData, teamData]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: imageSize / 4,
        width: '100%',
      }}
    >
      {_.isString(headerData.imageUrl) && headerData.imageUrl.length > 0 && (
        <img
          src={headerData.imageUrl}
          style={{
            height: imageSize,
            width: imageSize,
            objectFit: 'contain',
            marginTop: -(imageSize / 6),
          }}
        />
      )}
      <h1 className="m-0">{`${headerData.hasTeam ? '' : 'All '}${
        headerData.name === 'Full' ? '' : headerData.name
      } ${title}`}</h1>
      {leftView}
      {!!rightView && (
        <>
          <div style={{ flex: 1 }}></div>
          {rightView}
        </>
      )}
    </div>
  );
};

export default OrgTeamImageHeader;
