import {
  FaRegSquare,
  FaArrowAltCircleLeft,
  FaArrowCircleRight,
  FaArrowAltCircleRight,
  FaPlus,
  FaCopy,
  FaTrash,
  FaEdit,
  FaCheckSquare,
  FaCheck,
  FaArrowsAlt,
  FaChevronLeft,
  FaChevronRight,
  FaSortDown,
  FaSortUp,
  FaShareAlt,
} from 'react-icons/fa';
import { BiFont, BiCalendarPlus, BiCalendarEdit } from 'react-icons/bi';
import {
  CButtonGroup,
  CButton,
  CCol,
  CFormGroup,
  CInputCheckbox,
  CLabel,
  CListGroup,
  CListGroupItem,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CImg,
  CInput,
  CTooltip,
  CContainer,
  CLink,
} from '@coreui/react';
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  limit,
} from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { db, functions } from '../../../index';
import PropTypes from 'prop-types';
import {
  clearQuestionAdded,
  clearQuestionRemoved,
} from '../../../actions/questionActionCreator';
import QuestionCategories from './QuestionCategories';
// import SearchQuestion from './SearchQuestion';
import _, { isString } from 'lodash';
import QuestionScopeSegmentedControl from '../../../pages/component/QuestionScopeSegmentedControl';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import {
  addQuestion,
  createNewQuiz,
} from '../../../actions/questionActionCreator';
import ReorderQuestionBank from '../../../r-reusable/ReorderQuestionBank';
import QuestionListView from '../../../pages/component/QuestionListView';
import {
  groupsForOrganizationIdAndTeamId,
  defaultExamData,
  duplicateQuestion,
  questionSortPreference,
  setQuestionSortPreference,
} from '../../../helpers/data';
import Select from 'react-select';
import CreateQuizFromQuestionBankModal from '../../../r-components/CreateQuizFromQuestionBankModal/CreateQuizFromQuestionBankModal';
import { toast } from 'react-toastify';
import ConfirmModal from '../../../r-components/ConfirmModal/ConfirmModal';
import FlexChildLoadingOverlay from '../../../r-components/FlexChildLoadingOverlay/FlexChildLoadingOverlay';
import { SortDirections, SortFields } from '../../../helpers/data';
import {
  filterFunctionForPlayerTypeLabel,
  filterFunctionForQuestionTypeLabel,
} from '../../../r-reusable/Categories';
import MobileMockup from '../../../r-components/MobileMockup/MobileMockup';
import MobileQuestionViewContent from '../../../r-components/MobileMockup/MobileQuestionViewContent/MobileQuestionViewContent';
import QuestionWrapper from '../../../pages/component/QuestionWrapper';
import { Link, useHistory } from 'react-router-dom';
import { TABS } from '../../../AppTabs';
import {
  ACCESS_ACTIONS,
  canDoAction,
  checkAccess,
} from '../../../helpers/access';
import OrgTeamImageHeader from '../../../r-components/OrgTeamImageHeader/OrgTeamImageHeader';
import { handleError } from '../../../helpers/errors';
import {
  DEFAULT_QUESTIONS_PER_PAGE,
  DOMAIN,
  EMAIL_ADDRESSES,
} from '../../../config';
import Pagination from '../../../r-components/Pagination/Pagination';
import useAccountSelectors from '../../../hooks/useAccountSelectors';

const maxQuestionsPerQuiz = 100;
const leftWidth = 2;
const rightWidth = 3;
const questionsPerPage = 25;

const QuestionList = ({
  closeModal,
  existingQuestionsModal,
  currentQuestions,
  handleSelectedQuestion,
  teamId,
  questionData,
  questionsList,
}) => {
  const defaultSortPreferences = questionSortPreference();
  const isMounted = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);
  const [isSortingQuestions, setIsSortingQuestions] = useState(false);
  const [isFilteringQuestions, setIsFilteringQuestions] = useState(false);
  const [filter, setFilter] = useState({});
  const [questions, setQuestions] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [questionSortField, setQuestionSortField] = useState(
    defaultSortPreferences.field
  );
  const [questionSortDirection, setQuestionSortDirection] = useState(
    defaultSortPreferences.direction
  );
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [bankedQuestions, setBankedQuestions] = useState([]);
  const [selectedBankedQuestions, setSelectedBankedQuestions] = useState([]);
  const [bankedQuestionReorderMode, setBankedQuestionReorderMode] =
    useState(false);
  const [questionScope, setQuestionScope] = useState('all');
  const [selectedFilteringCategories, setSelectedFilteringCategories] =
    useState({});
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedPlayerTypes, setSelectedPlayerTypes] = useState([]);
  const [selectedQuestionTypes, setSelectedQuestionTypes] = useState([]);
  const [selectedPlayerTypeLabels, setSelectedPlayerTypeLabels] = useState([]);
  const [selectedQuestionTypeLabels, setSelectedQuestionTypeLabels] = useState(
    []
  );
  const [expandedQuestion, setExpandedQuestion] = useState(null);
  const [mockedUpQuestion, setMockedUpQuestion] = useState(null);
  const [showLeftSide, setShowLeftSide] = useState(true);
  const [showRightSide, setShowRightSide] = useState(false);
  const [hasCustomizedShowRightSide, setHasCustomizedShowRightSide] =
    useState(false);
  const [showSegmentedControl, setShowSegmentedControl] = useState(true);
  const [bounceRightArrow, setBounceRightArrow] = useState(false);
  const [quizName, setQuizName] = useState('');
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [showingCreateQuizModal, setShowingCreateQuizModal] = useState(false);
  const [examData, setExamData] = useState(defaultExamData);
  const [_isSelectingFirstQuestion, _setIsSelectingFirstQuestion] =
    useState(false);
  const [_isExpandingFirstQuestion, _setIsExpandingFirstQuestion] =
    useState(false);
  const [_isPreviewingFirstQuestion, _setIsPreviewingFirstQuestion] =
    useState(false);
  const [_isMovingSelectedQuestionsRight, _setIsMovingSelectedQuestionsRight] =
    useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const quizNameRef = useRef(null);

  const [hasShownWindowWidthAlert, setHasShownWindowWidthAlert] =
    useState(false);
  const [hoveringSelectAllQuestions, setHoveringSelectAllQuestions] =
    useState(true);
  const [
    hoveringSelectAllBankedQuestions,
    setHoveringSelectAllBankedQuestions,
  ] = useState(true);
  const { windowWidth, windowHeight } = useWindowDimensions();
  const darkMode = useSelector((state) => state.ThemeState.darkMode);

  const [searchTerm, setSearchTerm] = useState('');
  const currentUser = useSelector((state) => state?.UserState);
  const [userCanEdit, setUserCanEdit] = useState(true);
  const [userCanCreateQuiz, setUserCanCreateQuiz] = useState(true);
  const questionToAdd = useSelector(
    (state) => state?.ViewQuestionState?.questionToAdd
  );
  const questionToRemove = useSelector(
    (state) => state?.ViewQuestionState?.questionToRemove
  );
  const [questionsPerPage, setQuestionsPerPage] = useState(
    DEFAULT_QUESTIONS_PER_PAGE
  );
  const [currentQuestionPage, setCurrentQuestionPage] = useState(0);
  const [pageVisibleQuestions, setPageVisibleQuestions] = useState([]);
  const [pageTitle, setPageTitle] = useState(null);

  const { tier, type, showSubscriptionModal, goToUrl } = useAccountSelectors();

  useEffect(() => {
    checkAccess({
      action: ACCESS_ACTIONS.VIEW_6TOOL_QUESTIONS,
      tier,
      type,
      showSubscriptionModal,
    }).noAccessFallback(() => {
      history.goBack();
    });
  }, []);

  useEffect(() => {
    setPageVisibleQuestions(
      [...filteredQuestions].slice(
        currentQuestionPage * questionsPerPage,
        (currentQuestionPage + 1) * questionsPerPage
      )
    );
  }, [filteredQuestions, currentQuestionPage, questionsPerPage]);

  useEffect(() => {
    let searchOrShow =
      !!searchTerm && searchTerm.length > 0 ? 'Searching' : 'Showing';
    let scope =
      questionScope === 'mine'
        ? 'your'
        : questionScope === 'public'
        ? '6Tool'
        : 'all';
    let categoriesText =
      !!selectedCategories && selectedCategories.length > 0
        ? ` in ${selectedCategories.length} categor${
            selectedCategories.length === 1 ? 'y' : 'ies'
          }`
        : '';
    let searchTermText =
      !!searchTerm && searchTerm.length > 0 ? ` for "${searchTerm}"` : '';
    let paginationText =
      filteredQuestions.length > questionsPerPage
        ? `${currentQuestionPage * questionsPerPage + 1}-${Math.min(
            filteredQuestions.length,
            (currentQuestionPage + 1) * questionsPerPage
          )} of ${filteredQuestions.length} on this page`
        : filteredQuestions.length;
    let sortingText =
      questionSortField === SortFields.Created
        ? `${
            questionSortDirection === SortDirections.DESC ? 'newest' : 'oldest'
          } by date created`
        : questionSortField === SortFields.Updated
        ? `${
            questionSortDirection === SortDirections.DESC ? 'newest' : 'oldest'
          } by date modified `
        : `alphabetically ${
            questionSortDirection === SortDirections.DESC ? 'Z to A' : 'A to Z'
          }`;
    setPageTitle(
      `${searchOrShow} ${scope} questions${categoriesText} ${searchTermText} sorted ${sortingText}` // (${paginationText})`
    );
  }, [
    searchTerm,
    questionScope,
    selectedCategories,
    filteredQuestions,
    questionsPerPage,
    currentQuestionPage,
    filteredQuestions,
  ]);

  useEffect(() => {
    if (existingQuestionsModal) {
      setFilteredQuestions(questions);
      setSelectedQuestions(currentQuestions);
    } else {
      filterQuestion();
    }
  }, [currentQuestions, existingQuestionsModal, questions]);

  useEffect(() => {
    filterQuestion();
  }, [
    questionScope,
    selectedCategories,
    selectedPlayerTypes,
    selectedQuestionTypes,
    selectedFilteringCategories,
    searchTerm,
    bankedQuestions,
  ]);

  useEffect(() => {
    sortQuestions();
    setQuestionSortPreference({
      field: questionSortField,
      direction: questionSortDirection,
    });
    setCurrentQuestionPage(0);
  }, [questionSortDirection, questionSortField]);

  useEffect(() => {
    if (!hasShownWindowWidthAlert && windowWidth < 1600) {
      setHasShownWindowWidthAlert(true);
      alert(
        `This page is optimized for browser windows greater than 1600px wide. For the best experience, please visit ${DOMAIN} from a larger screen.`
      );
    }
    if (hasShownWindowWidthAlert && windowWidth >= 1600) {
      setHasShownWindowWidthAlert(false);
    }
  }, [windowWidth, windowHeight]);

  useEffect(() => {
    handleTourDispatchFunctions(questionData);

    return () => {};
  }, [questionData]);

  const handleTourDispatchFunctions = ({
    selectFirstQuestion = false,
    previewFirstQuestion = false,
    expandFirstQuestion = false,
    moveSelectedQuestionsRight = false,
  } = {}) => {
    if (
      selectFirstQuestion === true &&
      _.isArray(questions) &&
      questions.length > 0 &&
      _isSelectingFirstQuestion === false
    ) {
      _setIsSelectingFirstQuestion(true);
      setSelectedQuestions([questions[0]]);
    } else if (
      selectFirstQuestion === false &&
      _.isArray(selectedQuestions) &&
      selectedQuestions.length === 1 &&
      _isSelectingFirstQuestion === true
    ) {
      _setIsSelectingFirstQuestion(true);
      setSelectedQuestions([]);
    } else {
      _setIsSelectingFirstQuestion(selectFirstQuestion);
    }
    if (
      previewFirstQuestion === true &&
      _.isArray(questions) &&
      questions.length > 0 &&
      _isPreviewingFirstQuestion === false
    ) {
      _setIsPreviewingFirstQuestion(true);
      setMockedUpQuestion(questions[0]);
    } else {
      _setIsPreviewingFirstQuestion(previewFirstQuestion);
      setMockedUpQuestion(null);
    }
    if (
      expandFirstQuestion === true &&
      _.isArray(questions) &&
      questions.length > 0 &&
      _isExpandingFirstQuestion === false
    ) {
      _setIsExpandingFirstQuestion(true);
      setExpandedQuestion(questions[0]);
    } else if (
      expandFirstQuestion === false &&
      !!expandedQuestion &&
      _isExpandingFirstQuestion === true
    ) {
      _setIsExpandingFirstQuestion(false);
      setExpandedQuestion(null);
    } else {
      _setIsExpandingFirstQuestion(expandFirstQuestion);
    }
    if (
      moveSelectedQuestionsRight === true &&
      _.isArray(selectedQuestions) &&
      selectedQuestions.length > 0 &&
      _isMovingSelectedQuestionsRight === false
    ) {
      _setIsMovingSelectedQuestionsRight(true);
      moveSelectedQuestions({ direction: 'right' });
    } else if (
      expandFirstQuestion === false &&
      _isExpandingFirstQuestion === true
    ) {
      _setIsMovingSelectedQuestionsRight(false);
    } else {
      _setIsMovingSelectedQuestionsRight(moveSelectedQuestionsRight);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    if (isMounted.current) {
      setIsLoadingQuestions(false);
      setQuestions(questionsList);
      setFilteredQuestions(questionsList);
    }

    return () => {
      isMounted.current = false;
    };
  }, [questionsList]);

  useEffect(() => {
    setUserCanEdit(
      canDoAction({
        action: ACCESS_ACTIONS.CREATE_QUESTIONS,
        type: currentUser?.data?._roles,
      }).canDo
    );
    setUserCanCreateQuiz(
      canDoAction({
        action: ACCESS_ACTIONS.CREATE_QUIZZES,
        type: currentUser?.data?._roles,
      }).canDo
    );
    (async () => {
      try {
        const organizationId = currentUser.organizationId || 'a';
        const groups = await groupsForOrganizationIdAndTeamId({
          organizationId,
          teamId,
        });
        if (isMounted.current) {
          setGroups(groups);
        }
      } catch (error) {
        handleError({
          error,
          internal: 'QuestionList.js - did not get groups',
        });
      }
    })();
  }, [teamId, currentUser]);

  useEffect(() => {
    setExamData({
      exam: { title: quizName || '' },
      questions: bankedQuestions || [],
      quiz: { title: quizName || '', intros: '', estTime: 20 },
      groups: [],
      inviteAllUsers: false,
    });
  }, [quizName, bankedQuestions]);

  useEffect(() => {
    if (
      !!questionToAdd &&
      _.isObject(questionToAdd) &&
      _.isString(questionToAdd.id) &&
      questionToAdd.id.length > 0
    ) {
      if (!!!questions.find((q) => q.id === questionToAdd.id)) {
        let newQuestions = [...questions];
        newQuestions.push(questionToAdd);
        setQuestions(newQuestions);
      }
      dispatch(clearQuestionAdded());
    }

    return () => {};
  }, [questionToAdd]);

  useEffect(() => {
    if (!hasCustomizedShowRightSide) {
      setShowRightSide(
        userCanCreateQuiz &&
          ((_.isArray(selectedQuestions) && selectedQuestions.length > 0) ||
            (_.isArray(bankedQuestions) && bankedQuestions.length > 0))
      );
    } else if (!showRightSide) {
      setBounceRightArrow(true);
    }
  }, [selectedQuestions, bankedQuestions, userCanCreateQuiz]);

  useEffect(() => {
    let timeout;
    if (bounceRightArrow) {
      timeout = setTimeout(() => {
        setBounceRightArrow(false);
      }, 2000);
    }
    return () => clearTimeout(timeout);
  }, [bounceRightArrow]);

  useEffect(() => {
    if (_.isString(questionToRemove) && questionToRemove.length > 0) {
      let newQuestions = [...questions];
      let newBankedQuestions = [...bankedQuestions];
      const i = newQuestions.findIndex((q) => q.id === questionToRemove);
      if (i !== -1) {
        newQuestions.splice(i, 1);
        setQuestions(newQuestions);
      }
      const bi = newBankedQuestions.findIndex((q) => q.id === questionToRemove);
      if (i !== -1) {
        newBankedQuestions.splice(i, 1);
        setBankedQuestions(newBankedQuestions);
      }

      dispatch(clearQuestionRemoved());
    }
    return () => {};
  }, [questionToRemove]);

  const resetPageAfterQuizCreated = () => {
    if (!!quizNameRef && !!quizNameRef.current) {
      quizNameRef.current.value = '';
    }
    setBankedQuestions([]);
    setSelectedBankedQuestions([]);
    setSelectedQuestions([]);
    setQuizName('');
    setExamData({
      exam: { title: '' },
      questions: [],
      quiz: { title: '', intros: '', estTime: 20 },
      groups: [],
      teamIDs: [],
      inviteAllUsers: false,
    });
  };

  const postHandleDuplicateQuestion = async ({ questionId }) => {
    let duplicateResult = await duplicateQuestion({
      questionId,
      owningOrganization: teamId,
    });
    if (duplicateResult) {
      toast('Question duplicated', { type: 'success' });
    } else {
      toast('Error duplicating question', { type: 'error' });
    }
  };

  const moveSelectedQuestions = ({ direction }) => {
    if (direction === 'left') {
      let selectedBankedIDs = selectedBankedQuestions.map((a) => a.id);
      setBankedQuestions(
        [...bankedQuestions].filter((question) => {
          if (!selectedBankedIDs.includes(question.id)) {
            return question;
          }
        })
      );
    } else {
      setBankedQuestions([...bankedQuestions, ...selectedQuestions]);
    }
    setSelectedQuestions([]);
    setSelectedBankedQuestions([]);
  };

  const mockupSingleQuestion = ({ question = null } = {}) => {
    if (!!question) {
      setMockedUpQuestion(question);
    }
  };

  const moveSingleQuestion = ({ question, direction }) => {
    if (!!question) {
      if (direction === 'left') {
        let selectedBankedIDs = [question.id];
        setBankedQuestions(
          [...bankedQuestions].filter((question) => {
            if (!selectedBankedIDs.includes(question.id)) {
              return question;
            }
          })
        );
      } else {
        setBankedQuestions([...bankedQuestions, ...[question]]);
      }
    }
  };

  const selectQuestion = ({ question, side }) => {
    const sideSelectedQuestions =
      side === 'bank' ? selectedBankedQuestions : selectedQuestions;
    const sideSetSelectedQuestions =
      side === 'bank' ? setSelectedBankedQuestions : setSelectedQuestions;
    const index = sideSelectedQuestions.findIndex(
      (selectedQuestion) => selectedQuestion.id === question.id
    );
    const selectedQuestionsIds = sideSelectedQuestions.map(
      (question) => question?.id
    );

    if (!selectedQuestionsIds.includes(question.id)) {
      if (index > -1) {
        const newSelectedQuestions = [...sideSelectedQuestions].filter(
          (selectedQuestion) => selectedQuestion.id !== question.id
        );
        sideSetSelectedQuestions(newSelectedQuestions);
      } else {
        const newSelectedQuestions = [...sideSelectedQuestions, question];
        sideSetSelectedQuestions(newSelectedQuestions);
      }
    } else {
      const newSelectedQuestions = [...sideSelectedQuestions].filter(
        (selectedQuestion) => selectedQuestion.id !== question.id
      );
      sideSetSelectedQuestions(newSelectedQuestions);
    }
  };

  const filterQuestion = () => {
    setIsFilteringQuestions(true);
    _filterQuestion();
  };

  const sortQuestions = () => {
    setIsSortingQuestions(true);
    _filterQuestion();
  };

  const _filterQuestion = (filterBy) => {
    const organizationId = currentUser.organizationId || 'a';
    let allQuestions = [...questions];
    let newFilter = {
      ...filter,
      ...filterBy,
    };

    if (newFilter.text?.length) {
      allQuestions = allQuestions.filter((question) =>
        question.text?.includes(newFilter.text)
      );
    }
    if (newFilter.positions?.length) {
      const positions = newFilter.positions.map((position) => position.value);
      // eslint-disable-next-line array-callback-return
      allQuestions = allQuestions?.filter((question) => {
        if (
          question?.positions?.some((position) =>
            positions.some(
              (pos) => pos.toUpperCase() === position.toString().toUpperCase()
            )
          )
        ) {
          return question;
        }
      });
    }

    if (newFilter?.tags?.length) {
      const tags = newFilter.tags?.map((tag) => tag.value.toUpperCase());
      // eslint-disable-next-line array-callback-return
      allQuestions = allQuestions.filter((question) => {
        if (
          question.tags?.some((tag) => {
            return tags.includes(tag.value.toUpperCase());
          })
        ) {
          return question;
        }
      });
    }

    if (questionScope === 'mine') {
      allQuestions = allQuestions.filter((question) => {
        if (question.owningOrganization === organizationId) {
          return question;
        }
      });
    }

    if (questionScope === 'public') {
      allQuestions = allQuestions.filter((question) => {
        if (question.owningOrganization === 'all') {
          return question;
        }
      });
    }

    if (_.isArray(selectedCategories) && selectedCategories.length > 0) {
      allQuestions = allQuestions.filter((question) => {
        if (
          selectedCategories.some(
            (cat) =>
              _.isArray(question.categories) &&
              question.categories.length > 0 &&
              question.categories.includes(cat)
          )
        ) {
          return question;
        }
      });
    }

    if (
      _.isArray(selectedPlayerTypeLabels) &&
      selectedPlayerTypeLabels.length > 0
    ) {
      allQuestions = allQuestions.filter((question) => {
        if (
          selectedPlayerTypeLabels.every((label) =>
            filterFunctionForPlayerTypeLabel({ label, question })
          )
        ) {
          return question;
        }
      });
    }
    if (
      _.isArray(selectedQuestionTypeLabels) &&
      selectedQuestionTypeLabels.length > 0
    ) {
      allQuestions = allQuestions.filter((question) => {
        if (
          selectedQuestionTypeLabels.every((label) =>
            filterFunctionForQuestionTypeLabel({ label, question })
          )
        ) {
          return question;
        }
      });
    }

    if (_.isString(searchTerm) && searchTerm.length > 0) {
      allQuestions = allQuestions.filter((question) => {
        let searchPhrases = searchTerm.split(' ');
        if (
          searchPhrases.every(
            (phrase) =>
              question?.title?.toLowerCase().includes(phrase.toLowerCase()) ||
              question?.text?.toLowerCase().includes(phrase.toLowerCase())
          )
        ) {
          return question;
        }
      });
    }

    if (_.isArray(bankedQuestions) && bankedQuestions.length > 0) {
      let bankedQuestionIDs = bankedQuestions.map((a) => a.id);
      allQuestions = allQuestions.filter((question) => {
        if (!bankedQuestionIDs.includes(question.id)) {
          return question;
        }
      });
    }
    let sortedQuestions = allQuestions.sort(sortFunction);
    setFilteredQuestions(sortedQuestions);
    setFilter(newFilter);
    setTimeout(() => {
      setIsFilteringQuestions(false);
      setIsSortingQuestions(false);
    }, 2000);
    if (_.isArray(selectedQuestions) && selectedQuestions.length > 0) {
      let selectedQuestionIDs = selectedQuestions.map((a) => a.id);
      let newSelectedQuestions = [...allQuestions].filter((question) => {
        if (selectedQuestionIDs.includes(question.id)) {
          return question;
        }
      });
      setSelectedQuestions(newSelectedQuestions);
    } else {
      setSelectedQuestions([]);
    }
  };

  const sortFunction = (a, b) => {
    const sortByDate = ({ date1, date2, fallback, direction }) => {
      if (direction !== SortDirections.ASC) {
        if (!date1 && !date2) {
          return fallback;
        }
        if (!date1) {
          return 1;
        }
        if (!date2) {
          return -1;
        }
        return date1.toMillis() > date2.toMillis() ? -1 : 1;
      }
      if (!date1 && !date2) {
        return fallback;
      }
      if (!date1) {
        return 1;
      }
      if (!date2) {
        return -1;
      }
      return date1.toMillis() < date2.toMillis() ? -1 : 1;
    };
    switch (questionSortField) {
      default:
      case SortFields.Text:
        if (questionSortDirection !== SortDirections.ASC) {
          return a.text > b.text ? -1 : 1;
        }
        return a.text < b.text ? -1 : 1;
      case SortFields.Created:
        return sortByDate({
          date1: a.created_at,
          date2: b.created_at,
          fallback: a.text < b.text ? -1 : 1,
          direction: questionSortDirection,
        });

      case SortFields.Updated:
        return sortByDate({
          date1: a.updated_at,
          date2: b.updated_at,
          fallback: a.text < b.text ? -1 : 1,
          direction: questionSortDirection,
        });
    }
  };

  const handleModalClose = () => {
    setFilter({});
    closeModal();
  };

  const addQuestions = () => {
    const newQuestions = [];
    const currentQuestionsIds = currentQuestions.map(
      (question) => question?.id
    );

    selectedQuestions.forEach((selectedQuestion) => {
      if (!currentQuestionsIds.includes(selectedQuestion?.id)) {
        newQuestions.push({
          ...selectedQuestion,
          ...{ existing: true, edited: false },
        });
      }
    });

    handleSelectedQuestion(newQuestions);
    setSelectedQuestions([]);
    handleModalClose();
  };

  const handleSelectAll = ({ type, side }) => {
    if (side === 'bank') {
      if (type === 'deselect') {
        setSelectedBankedQuestions([]);
      } else {
        setSelectedBankedQuestions([...bankedQuestions]);
      }
    } else {
      if (type === 'deselect') {
        setSelectedQuestions([]);
      } else {
        setSelectedQuestions([...pageVisibleQuestions]);
      }
    }
  };

  const getBankedQuestionsReorderKey = () => {
    return bankedQuestions
      .map((question, index) => `${question.text}-${index}`)
      .toString();
  };

  const renderSelectAllButton = ({ side }) => {
    let isAllSelected = false;
    let hoverBool = hoveringSelectAllQuestions;
    if (side === 'bank') {
      hoverBool = hoveringSelectAllBankedQuestions;
      if (!(_.isArray(bankedQuestions) && bankedQuestions.length > 0)) {
        return null;
      }
      isAllSelected =
        _.isArray(selectedBankedQuestions) &&
        selectedBankedQuestions.length >= (bankedQuestions || []).length;
    } else {
      if (
        !(_.isArray(pageVisibleQuestions) && pageVisibleQuestions.length > 0)
      ) {
        return null;
      }
      isAllSelected =
        _.isArray(selectedQuestions) &&
        selectedQuestions.length >= (pageVisibleQuestions || []).length;
    }
    return (
      <div
        onMouseOver={() => {
          if (side === 'bank') {
            setHoveringSelectAllBankedQuestions(true);
          } else {
            setHoveringSelectAllQuestions(true);
          }
        }}
        onMouseOut={() => {
          if (side === 'bank') {
            setHoveringSelectAllBankedQuestions(false);
          } else {
            setHoveringSelectAllQuestions(false);
          }
        }}
        style={{
          alignItems: 'center',
          display: 'flex',
          fontSize: 18,
          cursor: 'pointer',
          marginLeft: 15,
        }}
        onClick={() => {
          handleSelectAll({
            type: isAllSelected ? 'deselect' : 'select',
            side,
          });
        }}
      >
        {isAllSelected ? (
          <>
            {hoverBool ? (
              <>
                <FaRegSquare style={{ marginTop: -5, marginRight: 8 }} />{' '}
                De-select All
              </>
            ) : (
              <>
                <FaCheckSquare style={{ marginTop: -5, marginRight: 8 }} />{' '}
                De-select All
              </>
            )}
          </>
        ) : (
          <>
            {hoverBool ? (
              <>
                <FaCheckSquare style={{ marginTop: -5, marginRight: 8 }} />{' '}
                Select All
              </>
            ) : (
              <>
                <FaRegSquare style={{ marginTop: -5, marginRight: 8 }} /> Select
                All
              </>
            )}
          </>
        )}
      </div>
    );
  };

  const renderWithSelectedButtons = () => {
    if (!(_.isArray(selectedQuestions) && selectedQuestions.length === 1)) {
      return null;
    }
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 4,
          marginLeft: 40,
        }}
      >
        <CLabel className="m-0">With selected: </CLabel>
        <CButton
          color="primary"
          variant="ghost"
          size="sm"
          style={{ padding: 8, marginTop: -4 }}
        >
          <FaEdit />
        </CButton>
        <CButton
          color="success"
          variant="ghost"
          size="sm"
          style={{ padding: 8, marginTop: -4 }}
        >
          <FaCopy />
        </CButton>
        <CButton
          color="danger"
          variant="ghost"
          size="sm"
          style={{ padding: 8, marginTop: -4 }}
        >
          <FaTrash />
        </CButton>
      </div>
    );
  };

  const renderDonateButton = () => {
    if (
      !(
        _.isString(currentUser?.organizationId) &&
        currentUser.organizationId.length > 1
      )
    ) {
      return null;
    }
    if (
      !_.some(
        selectedQuestions || [],
        (q) => q.owningOrganization === currentUser.organizationId
      )
    ) {
      return null;
    }
    const yourQuestionIDs = selectedQuestions
      .filter((q) => q.owningOrganization === currentUser.organizationId)
      .map((q) => q.id);
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 4,
          marginLeft: 40,
        }}
      >
        <CButton
          color="success"
          variant="outline"
          size="sm"
          style={{ padding: 8, marginTop: -4 }}
          onClick={async () => {
            const showDonateError = () => {
              toast(
                `Submitting questions for donation failed. Please try again or contact ${EMAIL_ADDRESSES.INFO}`,
                { type: 'error' }
              );
            };
            toast(
              'Questions have been submitted to 6Tool. Thank you! We will review the questions and may include them in our public library.',
              { type: 'success' }
            );
            let donateQuestionsCallable = functions.httpsCallable(
              'donateQuestionsCallable'
            );
            try {
              let donateQuestionsResults = await donateQuestionsCallable({
                organization_id: currentUser.organizationId,
                questions: yourQuestionIDs,
              });
              if (donateQuestionsResults === false) {
                showDonateError();
              }
            } catch (error) {
              showDonateError();
            }
          }}
        >
          <FaShareAlt style={{ marginRight: 4, marginTop: -2 }} />
          Donate {yourQuestionIDs.length} Question
          {yourQuestionIDs.length === 1 ? '' : 's'}
        </CButton>
      </div>
    );
  };

  const renderIconForSortField = (sortField) => {
    switch (sortField) {
      default:
      case SortFields.Text:
        return (
          <BiFont
            style={{ marginRight: sortField === questionSortField ? -8 : 12 }}
          />
        );
      case SortFields.Created:
        return (
          <BiCalendarPlus
            style={{ marginRight: sortField === questionSortField ? -6 : 14 }}
          />
        );
      case SortFields.Updated:
        return (
          <BiCalendarEdit
            style={{ marginRight: sortField === questionSortField ? -6 : 14 }}
          />
        );
    }
  };

  const sortDescriptionForField = (sortField) => {
    switch (sortField) {
      default:
      case SortFields.Text:
        return 'Question Text';
      case SortFields.Created:
        return 'Question Created Date';
      case SortFields.Updated:
        return 'Question Updated Date';
    }
  };

  const renderSortButtons = () => (
    <div className="mr-3 d-flex sort-buttons">
      {Object.keys(SortFields).map((field) => (
        <CTooltip
          key={`search-tooltip-${field}`}
          content={`Sort by ${sortDescriptionForField(field)}`}
        >
          <div
            style={{
              cursor: 'pointer',
              border: '1px solid #dedede',
              borderRadius: 8,
              paddingLeft: 11,
              marginRight: 4,
              background: field === questionSortField ? 'white' : '#efefef',
            }}
            onClick={() => {
              if (field === questionSortField) {
                setQuestionSortDirection(
                  questionSortDirection === SortDirections.ASC
                    ? SortDirections.DESC
                    : SortDirections.ASC
                );
              } else {
                setQuestionSortField(field);
                setQuestionSortDirection(
                  field === SortFields.Text
                    ? SortDirections.ASC
                    : SortDirections.DESC
                );
              }
            }}
          >
            {renderIconForSortField(field)}
            {questionSortField === field && (
              <>
                {questionSortDirection === SortDirections.ASC ? (
                  <FaSortUp size={20} />
                ) : (
                  <FaSortDown size={20} />
                )}
              </>
            )}
          </div>
        </CTooltip>
      ))}
    </div>
  );

  const renderCreateQuestionButton = () => {
    return (
      <>
        {canDoAction({
          action: ACCESS_ACTIONS.CREATE_QUESTIONS,
          type,
          tier,
        }).canView && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              gap: 8,
            }}
          >
            <CButton
              id="create-new-question-button"
              color="primary"
              onClick={() => {
                checkAccess({
                  action: ACCESS_ACTIONS.CREATE_QUESTIONS,
                  tier,
                  type,
                  showSubscriptionModal,
                  goToUrl,
                }).navigateWithAccess(TABS.COACH_QUESTIONS_CREATE.path);
              }}
            >
              <FaPlus style={{ marginRight: 8 }} />
              Create New Question
            </CButton>
          </div>
        )}
      </>
    );
  };

  const renderCreateQuizButton = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          gap: 8,
        }}
      >
        {bankedQuestions.length > 0 && (
          <CButton
            id="create-new-quiz-button"
            disabled={bankedQuestions.length > maxQuestionsPerQuiz}
            color="primary"
            onClick={() => {
              setShowingCreateQuizModal(true);
            }}
          >
            <FaPlus style={{ marginRight: 8 }} />
            Create Quiz ({bankedQuestions.length})
          </CButton>
        )}
      </div>
    );
  };

  const renderMoveArrow = ({ direction, enabled }) => {
    if (direction === 'left') {
      if (enabled) {
        return (
          <FaArrowAltCircleLeft
            id={`question-list-arrow-move-${direction}`}
            style={{ cursor: 'pointer' }}
            size={40}
            onClick={() => {
              moveSelectedQuestions({ direction: 'left' });
            }}
          />
        );
      }
      return <FaArrowAltCircleLeft size={40} color="#efefef" />;
    }
    if (direction === 'right') {
      if (enabled) {
        return (
          <FaArrowAltCircleRight
            className={`question-list-arrow-move-${direction}`}
            id={`question-list-arrow-move-${direction}`}
            style={{ cursor: 'pointer' }}
            size={40}
            onClick={() => {
              moveSelectedQuestions({ direction: 'right' });
            }}
          />
        );
      }
      return <FaArrowAltCircleRight size={40} color="#efefef" />;
    }
  };

  const colWidth = () => {
    let left = showLeftSide ? leftWidth : 0;
    let right =
      showRightSide &&
      ((_.isArray(selectedQuestions) && selectedQuestions.length > 0) ||
        (_.isArray(bankedQuestions) && bankedQuestions.length > 0))
        ? rightWidth
        : 0;
    let middle = 12 - left - right;
    return { left, right, middle };
  };

  const handleListChange = (list, change) => {
    setBankedQuestions(list);
  };

  const handleQuizNameChange = (e, f) => {
    setQuizName(e?.target?.value);
  };

  const handleSearchFieldKeyDown = (e, f) => {
    if (e.keyCode === 13 || e.keyCode === 27) {
      e.target.blur();
    }
  };

  const handleSearchFieldFocus = () => {
    setShowSegmentedControl(false);
  };

  const handleSearchFieldBlur = () => {
    setShowSegmentedControl(true);
  };

  return (
    <QuestionWrapper>
      <OrgTeamImageHeader
        title="Questions"
        rightView={renderCreateQuestionButton()}
      />
      <div
        className={`question-list-grid-wrapper${
          showLeftSide ? '' : ' hide-left'
        }${showRightSide ? '' : ' hide-right'}`}
      >
        <CCol
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            gap: 10,
          }}
        >
          {showLeftSide && (
            <>
              <CInput
                id="text"
                placeholder="Search question text (ie. 'foul')"
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={handleSearchFieldKeyDown}
                style={{ width: 'auto', flex: 1 }}
                onFocus={handleSearchFieldFocus}
                onBlur={handleSearchFieldBlur}
              />
              {showSegmentedControl && (
                <QuestionScopeSegmentedControl
                  style={{ marginBottom: 0, flex: 1, height: '53px' }}
                  setQuestionScope={setQuestionScope}
                  questionScope={questionScope}
                  height={24}
                  layout="compact"
                />
              )}
            </>
          )}
        </CCol>
        <CCol className="main-section">
          <div
            style={{
              position: 'absolute',
              top: -3,
              left: showLeftSide ? -28 : -9,
              zIndex: 90,
            }}
          >
            {showLeftSide ? (
              <FaChevronLeft
                onClick={() => {
                  setShowLeftSide(!showLeftSide);
                }}
              />
            ) : (
              <FaChevronRight
                onClick={() => {
                  setShowLeftSide(!showLeftSide);
                }}
              />
            )}
          </div>
          {userCanCreateQuiz && (
            <>
              <div
                className={
                  bounceRightArrow
                    ? 'bounce toggle-right-side'
                    : 'toggle-right-side'
                }
                style={{
                  position: 'absolute',
                  top: -3,
                  right: -28,
                  zIndex: 90,
                }}
              >
                {showRightSide ? (
                  <FaChevronRight
                    onClick={() => {
                      setShowRightSide(!showRightSide);
                      setHasCustomizedShowRightSide(true);
                    }}
                  />
                ) : (
                  <FaChevronLeft
                    onClick={() => {
                      setShowRightSide(!showRightSide);
                      setHasCustomizedShowRightSide(true);
                    }}
                  />
                )}
              </div>
            </>
          )}
          <div>{pageTitle}</div>
          <div className="main-header-action-section">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {renderSelectAllButton({ side: 'left' })}
              {renderDonateButton()}
              {renderWithSelectedButtons()}
            </div>
            <div style={{ flex: 1, height: '100%' }} />
            {renderSortButtons()}

            <Pagination
              numItems={filteredQuestions.length}
              currentPage={currentQuestionPage}
              numPages={Math.ceil(filteredQuestions.length / questionsPerPage)}
              setCurrentPage={(newPage) => {
                setCurrentQuestionPage(newPage);
              }}
              itemsPerPage={questionsPerPage}
              setItemsPerPage={(i) => {
                setQuestionsPerPage(i);
              }}
              title={`Questions ${
                currentQuestionPage * questionsPerPage + 1
              } - ${Math.min(
                filteredQuestions.length,
                (currentQuestionPage + 1) * questionsPerPage
              )} of ${filteredQuestions.length}`}
            />
          </div>
        </CCol>
        <CCol
          style={{
            paddingLeft: 8,
            width: '100%',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
          }}
        >
          {showRightSide && (
            <>
              {renderSelectAllButton({ side: 'bank' })}
              <div style={{ flex: 1, height: '100%' }} />
              {renderCreateQuizButton()}
            </>
          )}
        </CCol>
        <div
          style={{
            gridColumnStart: 1,
            gridColumnEnd: 4,
            background: '#efefef',
          }}
        ></div>
        <div className="category-wrapper">
          {showLeftSide && (
            <QuestionCategories
              style={{
                paddingBottom: 150,
              }}
              preExpandedSections={{ categories: true }}
              handleSelectedCategories={(data) => {
                console.log(data);
                setSelectedFilteringCategories(data);
                setSelectedCategories(data?.categories || []);
                setSelectedPlayerTypes(data?.playerTypes || []);
                setSelectedQuestionTypes(data?.questionTypes || []);
                setSelectedQuestionTypeLabels(data?.questionTypeLabels || []);
                setSelectedPlayerTypeLabels(data?.playerTypeLabels || []);
              }}
            />
          )}
        </div>
        <div id="question-list-main-content" className="d-flex">
          {isLoadingQuestions ? (
            <FlexChildLoadingOverlay text="Loading questions..." />
          ) : (
            <>
              {isFilteringQuestions ? (
                <FlexChildLoadingOverlay text="Filtering questions..." />
              ) : (
                <>
                  {isSortingQuestions && (
                    <FlexChildLoadingOverlay text="Sorting questions..." />
                  )}
                </>
              )}
            </>
          )}
          <CListGroup
            className="question-list-view-group question-list-view-group-left"
            accent
            style={{ marginLeft: 0, overflow: 'scroll' }}
          >
            {_.isArray(pageVisibleQuestions) &&
            pageVisibleQuestions.length > 0 ? (
              <>
                {pageVisibleQuestions.map((question, index) => (
                  <QuestionListView
                    key={`ql-${question?.id}-${index}`}
                    expanded={expandedQuestion?.id === question?.id}
                    question={question}
                    index={index}
                    side="left"
                    selectQuestion={selectQuestion}
                    selectedBankedQuestions={selectedBankedQuestions}
                    expandedQuestion={expandedQuestion}
                    selectedQuestions={selectedQuestions}
                    setExpandedQuestion={setExpandedQuestion}
                    moveSingleQuestion={moveSingleQuestion}
                    mockupSingleQuestion={mockupSingleQuestion}
                    userCanEdit={userCanEdit}
                    userCanCreateQuiz={userCanCreateQuiz}
                  />
                ))}{' '}
              </>
            ) : (
              <>
                {!isLoadingQuestions && !isFilteringQuestions && (
                  <>
                    No questions meet your search/filter criteria. Remove some
                    filters to see questions
                  </>
                )}
              </>
            )}
          </CListGroup>
        </div>
        <CRow
          className="pb-3 pt-3 m-0"
          style={{
            overflow: 'scroll',
            alignItems: 'center',
          }}
        >
          {showRightSide && (
            <>
              <div
                style={{
                  width: 40,
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  gap: 4,
                  marginRight: 8,
                }}
              >
                {renderMoveArrow({
                  direction: 'left',
                  enabled:
                    _.isArray(selectedBankedQuestions) &&
                    selectedBankedQuestions.length > 0,
                })}
                {renderMoveArrow({
                  direction: 'right',
                  enabled:
                    _.isArray(selectedQuestions) &&
                    selectedQuestions.length > 0,
                })}
              </div>
              <div
                id="question-list-quiz-create-column"
                style={{
                  minHeight: 'calc(100vh - 617px)',
                  maxHeight: 'calc(100vh - 75px)',
                  overflow: 'scroll',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  height: '100%',
                  border: '1px solid #efefef',
                }}
              >
                {!bankedQuestionReorderMode && (
                  <CLabel
                    style={{
                      marginTop: 10,
                      textAlign: 'center',
                      width: '100%',
                      color:
                        _.isArray(bankedQuestions) &&
                        bankedQuestions.length > maxQuestionsPerQuiz
                          ? 'red'
                          : 'inherit',
                    }}
                  >
                    {!_.isArray(bankedQuestions) || bankedQuestions.length === 0
                      ? 'Add questions to this side to easily create a quiz'
                      : _.isArray(bankedQuestions) &&
                        bankedQuestions.length > maxQuestionsPerQuiz
                      ? `Max ${maxQuestionsPerQuiz} questions per quiz (${bankedQuestions.length} added)`
                      : `Click "Create Quiz" with ${
                          bankedQuestions.length === 1
                            ? 'this 1 question'
                            : `these ${bankedQuestions.length} questions`
                        }`}
                  </CLabel>
                )}
                {_.isArray(bankedQuestions) &&
                  bankedQuestions.length > 0 &&
                  !bankedQuestionReorderMode && (
                    <div
                      style={{
                        padding: 10,
                        width: '100%',
                        gap: 10,
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <CInput
                        type="text"
                        id="quiz-name"
                        name="quiz-name"
                        placeholder="Enter Name of Quiz..."
                        onBlur={handleQuizNameChange}
                        ref={quizNameRef}
                      />
                      {false && _.isArray(groups) && groups.length > 0 && (
                        <Select
                          className="question-bank-group-selector"
                          isMulti
                          name="groups"
                          placeholder="Group(s) to Invite (Optional)"
                          options={groups}
                          value={selectedGroups}
                          onChange={setSelectedGroups}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: darkMode
                                ? 'black'
                                : theme.colors.primary,
                              primary25: darkMode
                                ? 'black'
                                : theme.colors.primary25,
                              dangerLight: darkMode
                                ? 'black'
                                : theme.colors.dangerLight,
                              neutral0: darkMode
                                ? '#2a2b36'
                                : theme.colors.neutral0,
                            },
                          })}
                        />
                      )}
                      <div
                        style={{
                          borderBottom: '1px solid #efefef',
                          marginTop: 5,
                        }}
                      />
                    </div>
                  )}
                {bankedQuestionReorderMode ? (
                  <>
                    <div className="question-bank-reorder-view">
                      <ReorderQuestionBank
                        deletable={true}
                        editable={true}
                        listData={bankedQuestions}
                        key={getBankedQuestionsReorderKey()}
                        handleListChange={handleListChange}
                        side="bank"
                        selectQuestion={selectQuestion}
                        selectedBankedQuestions={selectedBankedQuestions}
                        expandedQuestion={expandedQuestion}
                        selectedQuestions={selectedQuestions}
                        setExpandedQuestion={setExpandedQuestion}
                      />
                    </div>
                    <div style={{ flex: 1 }}></div>
                    {_.isArray(bankedQuestions) &&
                      bankedQuestions.length > 0 && (
                        <>
                          <CLabel>Drag to re-order questions</CLabel>
                          <CLink
                            onClick={() => {
                              setBankedQuestionReorderMode(false);
                            }}
                          >
                            <FaArrowsAlt className="mr-1" />
                            <CLabel>Click to turn off reordering</CLabel>
                          </CLink>
                        </>
                      )}
                  </>
                ) : (
                  <>
                    <CListGroup
                      className="question-list-view-group question-list-view-group-bank"
                      accent
                      style={{ marginLeft: 0 }}
                    >
                      {bankedQuestions.map((question, index) => (
                        <QuestionListView
                          key={`banked-ql-${question?.id}-${index}`}
                          expanded={expandedQuestion?.id === question?.id}
                          question={question}
                          index={index}
                          side="bank"
                          selectQuestion={selectQuestion}
                          selectedBankedQuestions={selectedBankedQuestions}
                          expandedQuestion={expandedQuestion}
                          selectedQuestions={selectedQuestions}
                          setExpandedQuestion={setExpandedQuestion}
                          moveSingleQuestion={moveSingleQuestion}
                          mockupSingleQuestion={mockupSingleQuestion}
                          userCanEdit={userCanEdit}
                          userCanCreateQuiz={userCanCreateQuiz}
                        />
                      ))}
                    </CListGroup>
                    <div style={{ flex: 1 }}></div>
                    {_.isArray(bankedQuestions) &&
                      bankedQuestions.length > 1 && (
                        <CLink
                          onClick={() => {
                            setBankedQuestionReorderMode(true);
                          }}
                        >
                          <FaArrowsAlt className="mr-1" />
                          <CLabel>Click to re-order questions</CLabel>
                        </CLink>
                      )}
                  </>
                )}
              </div>
            </>
          )}
        </CRow>
      </div>
      {!!examData && (
        <CreateQuizFromQuestionBankModal
          show={showingCreateQuizModal}
          initialExamData={examData}
          onClose={() => {
            setShowingCreateQuizModal(false);
          }}
          resetPageAfterQuizCreated={resetPageAfterQuizCreated}
        />
      )}
      {!!mockedUpQuestion && (
        <CModal
          show={!!mockedUpQuestion}
          style={{ background: 'transparent', border: 'none' }}
          onClose={() => {
            setMockedUpQuestion(null);
          }}
        >
          <CModalBody>
            <MobileMockup
              content={
                <MobileQuestionViewContent
                  question={mockedUpQuestion}
                  onClose={() => {
                    setMockedUpQuestion(null);
                  }}
                />
              }
            />
          </CModalBody>
        </CModal>
      )}
    </QuestionWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    questionData: state.ViewQuestionState,
    userData: state.UserState,
    questionsList: state.DataQuestionState?.data || [],
  };
};

const mapDispatchToProps = {
  clearQuestionAdded,
  clearQuestionRemoved,
};

QuestionList.propTypes = {
  initialDefault: PropTypes.func,
  questionData: PropTypes.shape({
    questions: PropTypes.bool,
    addQuestions: PropTypes.bool,
    questionDetails: PropTypes.bool,
    createNewQuiz: PropTypes.bool,
    newQuizQuestionIds: PropTypes.array,
    question: PropTypes.object,
    setShowConfirmDuplicateModal: PropTypes.bool,
    setShowConfirmEditModal: PropTypes.bool,
    showConfirmDeleteModal: PropTypes.bool,
    questionToDelete: PropTypes.object,
    questionToRemove: PropTypes.string,
    expandFirstQuestion: PropTypes.bool,
    selectFirstQuestion: PropTypes.bool,
    previewFirstQuestion: PropTypes.bool,
    moveSelectedQuestionsRight: PropTypes.bool,
  }),
  userData: PropTypes.shape({
    id: PropTypes.string,
    organizationId: PropTypes.string,
    type: PropTypes.array,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionList);
