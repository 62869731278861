import React, { Component } from 'react';
import Mailchimp from '../component/MailChimpForm';
import AppStoreBadges from './AppStoreBadges';
import { TABS } from '../../AppTabs';
import { EMAIL_ADDRESSES } from '../../config';
// import Mailchimp from 'react-mailchimp-form'

function Subscribe() {
  return (
    <>
      <div className="d-inline text-center text-md-right">
        <Mailchimp
          action="https://app.us17.list-manage.com/subscribe/post?u=097270ac0e8bca76642b6f174&id=9483332835&f_id=00d151e0f0"
          fields={[
            {
              name: 'EMAIL',
              placeholder: 'Enter Email Address',
              type: 'email',
              required: true,
            },
          ]}
          messages={{
            sending: 'Sending...',
            success: 'Thank you for subscribing!',
            error: 'An unexpected internal error has occurred.',
            empty: 'Please provide a valid email address.',
            duplicate: 'Too many subscribe attempts for this email address.',
            button: 'Subscribe',
          }}
          className="mailchimp form"
        />
      </div>
    </>
  );
}

export const FooterCopyright = () => (
  <div className="pull-left footer-copyright-text">
    <div className="footer-copyright-text">
      &copy; 2024 6Tool. All rights reserved.
    </div>
    <a href={`mailto:${EMAIL_ADDRESSES.INFO}`}>{EMAIL_ADDRESSES.INFO}</a>
  </div>
);

export const FooterImage = ({ noLink = false } = {}) => (
  <div className="footer-top">
    <div className="footer-logo">
      {noLink !== true ? (
        <a href="/">
          <img src="/img/footer-logo.png" className="img-fluid" alt="" />
        </a>
      ) : (
        <img src="/img/footer-logo.png" className="img-fluid" alt="" />
      )}
    </div>
    <div className="footer-logo"></div>
  </div>
);

class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="container">
          <div
            className="footer-top"
            style={{ justifyContent: 'flex-end', marginBottom: 20 }}
          >
            <div className="footer-nav">
              <ul className="nav" style={{ justifyContent: 'flex-end' }}>
                <li className="nav-item">
                  <a className="nav-link" href={'/'}>
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href={TABS.PRIVACY.path}>
                    Privacy
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href={TABS.TERMS.path}>
                    Terms & Conditions
                  </a>
                </li>
              </ul>
              <ul
                className="nav"
                style={{ justifyContent: 'flex-end', marginTop: 0 }}
              >
                <li className="nav-item">
                  <a className="nav-link" href={TABS.TEAM.path}>
                    Team
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href={TABS.WHY.path}>
                    Why 6Tool
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href={TABS.PRICING.path}>
                    PRICING
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href={TABS.FAQ.path}>
                    FAQ
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-top">
            <FooterImage />
            <AppStoreBadges
              height={60}
              style={{ justifyContent: 'flex-end' }}
            />
          </div>
          <div className="copyright">
            <FooterCopyright />
            <div className="pull-right">
              <div className="social-icons">
                <span className="me-2">Follow Us:</span>
                <a
                  href="https://www.facebook.com/profile.php?id=100087896082509"
                  target="_blank"
                  rel="noreferrer"
                  className="icon facebook"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                  </svg>
                </a>
                <a
                  href="https://twitter.com/six6Tool"
                  target="_blank"
                  rel="noreferrer"
                  className="icon twitter"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                  </svg>
                </a>
                <a
                  href="https://instagram.com/6_tool"
                  target="_blank"
                  rel="noreferrer"
                  className="icon insta"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24px"
                    height="24px"
                  >
                    {' '}
                    <path d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z" />
                  </svg>
                </a>
                {false && (
                  <a href="#" className="icon youtube">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 30 30"
                      width="30px"
                      height="30px"
                    >
                      {' '}
                      <path d="M 15 4 C 10.814 4 5.3808594 5.0488281 5.3808594 5.0488281 L 5.3671875 5.0644531 C 3.4606632 5.3693645 2 7.0076245 2 9 L 2 15 L 2 15.001953 L 2 21 L 2 21.001953 A 4 4 0 0 0 5.3769531 24.945312 L 5.3808594 24.951172 C 5.3808594 24.951172 10.814 26.001953 15 26.001953 C 19.186 26.001953 24.619141 24.951172 24.619141 24.951172 L 24.621094 24.949219 A 4 4 0 0 0 28 21.001953 L 28 21 L 28 15.001953 L 28 15 L 28 9 A 4 4 0 0 0 24.623047 5.0546875 L 24.619141 5.0488281 C 24.619141 5.0488281 19.186 4 15 4 z M 12 10.398438 L 20 15 L 12 19.601562 L 12 10.398438 z" />
                    </svg>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// class Footer extends Component {
//   render() {
//     return (
//       <div>
//         <div className="container-fluid footer py-3">
//           <div className="row">
//             <div className="col-12 col-md-6 text-center text-md-left font-size-13 text-lh-sm">
//               <div>
//                 Questions? <a href="/contact">Contact 6Tool</a>
//               </div>
//               &copy; {new Date().getFullYear()} 6Tool. All rights reserved.
//             </div>
//             <div className="col-12 col-md-6 mt-5 mt-md-0">
//               <Subscribe />
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }
export default Footer;
