import React from 'react';
import moment from 'moment-timezone';
import { orgAndTeamData } from '../../helpers/data';
import { shortDateTime } from '../../helpers/utils';
import { DOMAIN } from '../../config';

const Letterhead = ({
  size = 20,
  teamData,
  orgData,
  numPlayers,
  reportName,
}) => {
  const orgTeamData = orgAndTeamData({ orgData, teamData });

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: 20,
          marginTop: 20,
        }}
      >
        <img
          className="u-header__navbar-brand-default six-tool-logo"
          src={`${DOMAIN}/img/logo-letterhead.png`}
          alt="Logo"
          width="162"
          height="60"
          style={{ height: 60, width: 162 }}
        />
        <img
          style={{ marginLeft: 8, width: 60, height: 60 }}
          width="60"
          height="60"
          src={orgTeamData.imageUrl}
        />
        <div style={{ marginLeft: 20 }}>
          <div className="team-details-text">
            <div className="team-details-name">
              {orgTeamData.name || 'All teams'}
            </div>
            <div className="team-details-players">
              {numPlayers} Player{numPlayers === 1 ? '' : 's'}
            </div>
          </div>
        </div>
        <div style={{ flex: 1 }}></div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
        >
          <div
            style={{ textAlign: 'right', fontWeight: 900, fontSize: '1.4rem' }}
          >
            {reportName}
          </div>
          <div style={{ textAlign: 'right', fontWeight: 900 }}>
            {shortDateTime()}
          </div>
        </div>
      </div>
    </>
  );
};

export default Letterhead;
