import React, { useEffect, useRef, useState } from 'react';
import { Lightbox } from 'react-modal-image';
import './mediaGallery.scss';
import _ from 'lodash';

const MEDIA_GALLERY_GAP = 0;

const MediaGallery = ({ media, types, mediaWidth = 200 }) => {
  const [_mediaWidth, _setMediaWidth] = useState(mediaWidth);
  const [mediaPerRow, setMediaPerRow] = useState(1);
  const [modalUrlToShow, setModalUrlToShow] = useState(null);

  const divRef = useRef(null);

  const handleResize = () => {
    if (divRef.current) {
      const width = divRef.current.offsetWidth;
      let newWidth = width;
      let numImagesPerRow = Math.round(
        width / (mediaWidth + MEDIA_GALLERY_GAP)
      );
      setMediaPerRow(numImagesPerRow);
      _setMediaWidth(
        (width - (numImagesPerRow - 1) * MEDIA_GALLERY_GAP) / numImagesPerRow
      );
    }
  };

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    handleResize();
  }, [mediaWidth]);

  return (
    <div
      ref={divRef}
      className="media-gallery"
      style={{
        gridTemplateColumns: `repeat(${mediaPerRow},1fr)`,
        gap: MEDIA_GALLERY_GAP,
      }}
    >
      {(media || [])
        .filter((m) => (_.isArray(types) ? types.includes(m.type) : true))
        .map((m, mIdx) => (
          <div
            key={`media-gallery-${mIdx}`}
            onClick={() => setModalUrlToShow(m.downloadUrl)}
          >
            {m.type === 'video' ? (
              <video
                controls
                style={{ height: (_mediaWidth * 9) / 16 }}
                height={(_mediaWidth * 9) / 16}
                width={_mediaWidth}
              >
                <source src={m.downloadUrl} type={m.mimeType}></source>
              </video>
            ) : m.type === 'pdf' ? (
              <embed
                width="191"
                height="207"
                name="plugin"
                src={m.downloadUrl}
                type="application/pdf"
              ></embed>
            ) : (
              <img
                style={{ height: (_mediaWidth * 9) / 16 }}
                src={m.downloadUrl}
                height={(_mediaWidth * 9) / 16}
                width={_mediaWidth}
              />
            )}
          </div>
        ))}
      {_.isString(modalUrlToShow) && modalUrlToShow.length > 0 && (
        <Lightbox
          small={modalUrlToShow}
          large={modalUrlToShow}
          onClose={() => {
            setModalUrlToShow(null);
          }}
        />
      )}
    </div>
  );
};

export default MediaGallery;
