import {
  CButton,
  CCardTitle,
  CCol,
  CFormGroup,
  CInput,
  CInputCheckbox,
  CLabel,
  CCard,
  CRow,
  CSwitch,
  CCardHeader,
  CImg,
  CTooltip,
  CInputRadio,
  CTextarea,
  CModal,
  CModalBody,
} from '@coreui/react';
import PropTypes from 'prop-types';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { Field, Form, Formik, FieldArray } from 'formik';
import _, { repeat } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  FiCornerDownLeft,
  FiPlus,
  FiPlusSquare,
  FiTrash2,
} from 'react-icons/fi';
import { connect, useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import * as Yup from 'yup';
import { db } from '../../../index';
import { ScoreBoard } from '../../../r-components';
import AddOption from '../createQuiz/tabs/CreateQuestion/AddOption';
import AddQuestionTag from '../createQuiz/tabs/CreateQuestion/AddQuestionTag';
import QuestionCategories from './QuestionCategories';
import { FaExternalLinkAlt, FaInfoCircle } from 'react-icons/fa';
import {
  questionOptionTemplates as optionTemplates,
  defaultQuestionValues as initialValues,
  defaultQuestionSituation as initialSituation,
  questionUseOptionTemplate as useOptionTemplate,
  defaultQuestionOptions,
  defaultQuestionOption,
  questionUseOptionTemplate,
  createQuestion,
  updateQuestion,
  getObjectDiff,
  mimeTypeFromURL,
  fileTypeCountForFiles,
} from '../../../helpers/data';
import BallField from '../../../r-components/BallField/BallField';
import {
  positionHighlightsAndLabelForPositions,
  positionValueAndLabelAndPositionForPosition,
} from '../../../r-reusable/Positions';
import MobileMockup from '../../../r-components/MobileMockup/MobileMockup';
import MobileQuestionViewContent from '../../../r-components/MobileMockup/MobileQuestionViewContent/MobileQuestionViewContent';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { TABS } from '../../../AppTabs';
import { FormGroup, FormLabel, InputGroup } from 'react-bootstrap';

const defaultValidationErrors = {
  details: [],
  answers: [],
  categories: [],
};

const validationSchema = Yup.object().shape({
  title: Yup.string().optional(),
  text: Yup.string().required('Question text cannot be blank'),
  withTimer: Yup.bool().optional(),
  timer: Yup.number().optional(),
  order: Yup.number().optional(),
  positions: Yup.array().min(0).optional(),
  tags: Yup.array().min(0).optional(),
  options: Yup.array()
    .of(
      Yup.object().shape({
        correct: Yup.boolean(),
        text: Yup.string().min(1).required('Option text cannot be blank'),
      })
    )
    .test(
      'has-correct-option',
      'At least one option must be marked as correct',
      (options) => options.some((option) => option.correct === true)
    ),
});

const CreateQuestionPage = ({
  createQuestionModal,
  handleCustomQuestion,
  closeModal,
  // question,
  questions,
  questionData,
  match,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const location = useLocation();
  const formikRef = useRef(null);
  const selectTagsRef = useRef(null);
  const selectPositionsRef = useRef(null);
  const [showScoreboard, setShowScoreboard] = useState(false);
  const [showField, setShowField] = useState(false);
  const [showMockup, setShowMockup] = useState(false);
  const [optionModal, setOptionModal] = useState(true);
  const [showTagModal, setShowTagModal] = useState(false);
  const [uneditedQuestion, setUneditedQuestion] = useState(null);
  const [changesMade, setChangesMade] = useState(false);
  const [inEditMode, setInEditMode] = useState(false);
  const [question, setQuestion] = useState(null);

  const [validationErrors, setValidationErrors] = useState(
    defaultValidationErrors
  );

  const [tags, setTags] = useState([]);
  const [situation, setSituation] = useState(initialSituation);
  const [positions, setPositions] = useState([]);
  const [videoUrl, setVideoUrl] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [difficulty, setDifficulty] = useState(5);

  const [files, setFiles] = useState([]);
  const [options, setOptions] = useState(defaultQuestionOptions);
  const darkMode = useSelector((state) => state.ThemeState.darkMode);
  const user = useSelector((state) => state.UserState);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*, image/png, video/mp4, video/*',
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  useEffect(() => {
    setInEditMode(match.path === TABS.COACH_QUESTIONS_EDIT.path);
    let q = null;
    if (
      _.isArray(questions.data) &&
      questions.data.length > 0 &&
      _.isString(match?.params.id) &&
      match.params.id.length > 0
    ) {
      q = questions.data.find((q) => q.id === match.params.id);
      if (q === undefined) {
        q = null;
      }
    }
    setQuestion(q);
  }, [match, questions]);

  useEffect(() => {
    const loadData = async () => {
      const organizationId = user.organizationId || 'a';

      const q = query(
        collection(db, 'tags'),
        where('organizationId', 'in', ['Global', organizationId])
      );
      const tagsData = [];
      const tagsResponse = await getDocs(q);

      tagsResponse.forEach((tag) => {
        tagsData.push({
          value: tag.id,
          label: tag.data().name,
        });
      });

      setTags(tagsData);
    };

    if (user) {
      loadData();
    }
  }, [user]);

  useEffect(() => {
    if (!showScoreboard) {
      setSituation(initialSituation);
    }
  }, [showScoreboard]);

  useEffect(() => {
    if (!!question) {
      if (
        !uneditedQuestion ||
        _.isEqual(question, _.omit(uneditedQuestion, ['inEditMode']))
      ) {
        setUneditedQuestion({ ...question });
      }
      setShowScoreboard(question.showScoreboard);
      setShowField(question.showScoreboard);
      setDifficulty(question.difficulty);
      setPositions(question.positions);
      setSituation(question.situation);
      setOptions(question.options);
      setFiles(question.files);
      setVideoUrl(question.videoUrl);
      setImageUrl(question.imageUrl);
    } else {
      setOptions(defaultQuestionOptions);
    }
  }, [question]);

  useEffect(() => {
    handleTourDispatchFunctions(questionData);
    return () => {};
  }, [questionData]);

  const handleTourDispatchFunctions = ({
    toggleOnShowScoreboard = false,
    toggleOnShowField = false,
  } = {}) => {
    if (toggleOnShowScoreboard === true) {
      setShowScoreboard(true);
    }
    if (toggleOnShowField === true) {
      setShowField(true);
    }
  };

  const onSubmit = async (rawData, action) => {
    let data = { ...rawData };
    let wasAll = data.owningOrganization === 'all';
    const _data = {};
    Object.keys(data).forEach((k) => {
      _data[k] = typeof data[k] == 'string' ? data[k].trim() : data[k];
    });
    data = _data;
    if (_.isArray(data.options) && data.options.length > 0) {
      data.options = data.options.map((o, oIdx) => {
        const trimmedOption = {};
        Object.keys(o).forEach((k) => {
          trimmedOption[k] = typeof o[k] === 'string' ? o[k].trim() : o[k];
        });
        trimmedOption.default_order = oIdx;
        return trimmedOption;
      });
    }
    const fieldsToCompare = [
      'files',
      'options',
      'positions',
      'categories',
      'showField',
      'difficulty',
      'showScoreboard',
      'situation',
      'tags',
      'text',
      'thinkAbout',
      'title',
      'imageUrl',
      'videoUrl',
    ];
    let customQuestionData = {
      ...data,
      files,
      difficulty: parseInt(data.difficulty || 5),
      difficultyRange: [parseInt(data.difficulty || 5)],
      // options,
      // showScoreboard,
      // situation,
    };
    let updateData = {};
    if (
      !(
        !!data.owningOrganization &&
        _.isString(data.owningOrganization) &&
        data.owningOrganization.length > 0
      ) ||
      data.owningOrganization === 'all'
    ) {
      data.owningOrganization = user.organizationId || 'a';
      customQuestionData.owningOrganization = data.owningOrganization;
    }
    if (
      !_.isEqual(
        _.pick(uneditedQuestion, fieldsToCompare),
        _.pick(customQuestionData, fieldsToCompare)
      )
    ) {
      updateData = _.omit(
        _.pick(
          customQuestionData,
          getObjectDiff(uneditedQuestion, customQuestionData)
        ),
        ['edited', 'difference']
      );
      data.edited = true;
    } else {
      data.edited = false;
    }
    data.existing = !!uneditedQuestion;
    let mediaCount = fileTypeCountForFiles({ files });

    let mediaText = '';
    let totalSum = 0;
    if (_.isObject(mediaCount)) {
      if (_.isNumber(mediaCount.images) && mediaCount.images > 0) {
        totalSum += mediaCount.images;
        mediaText =
          mediaText +
          `${mediaCount.images} image${mediaCount.images === 1 ? '' : 's'}`;
        if (_.isNumber(mediaCount.videos) && mediaCount.videos > 0) {
          mediaText = mediaText + ' and ';
        }
      }
      if (_.isNumber(mediaCount.videos) && mediaCount.videos > 0) {
        totalSum += mediaCount.videos;
        mediaText =
          mediaText +
          `${mediaCount.videos} video${mediaCount.videos === 1 ? '' : 's'}`;
      }
    }
    if (mediaText.length > 0) {
      mediaText =
        mediaText +
        ` ${
          totalSum === 1 ? 'is' : 'are'
        } being uploaded. This may take a moment...`;
    }
    let hasFiles = totalSum > 0;
    if (data.existing && data.edited) {
      if (!wasAll) {
        await updateQuestion({ questionId: data.id, data: updateData });
      } else {
        await createQuestion({ data: customQuestionData });
      }
    } else {
      await createQuestion({ data: customQuestionData });
    }
    toast(`Question created${hasFiles ? `. ${mediaText}` : ''}`, {
      type: 'success',
    });
    action.resetForm();
    clearFormAndReturnToQuestions();
  };

  const clearFormAndReturnToQuestions = () => {
    setOptions(defaultQuestionOptions);
    setFiles([]);
    setShowScoreboard(false);
    setVideoUrl(null);
    setImageUrl(null);
    setSituation(initialSituation);
    selectPositionsRef.current?.select?.setValue(initialValues.positions);
    selectTagsRef.current?.select?.setValue(initialValues.tags);
    history.push(TABS.COACH_QUESTIONS.path);
  };

  const handleSubmitTag = (tag) => {
    const newTags = [...tags, tag];
    selectTagsRef.current?.select?.selectOption(tag);
    setTags(newTags);
    setShowTagModal(false);
  };

  const renderThumbs = (setFieldValue) => {
    if ((files || []).length === 0) {
      if (!!videoUrl && _.isString(videoUrl) && videoUrl.length > 0) {
        return (
          <div className="thumb" key="video-preview">
            <video width="auto" height="auto" controls>
              <source src={videoUrl} type={mimeTypeFromURL(videoUrl)} />
            </video>
            <button
              className="thumb-button"
              onClick={() => {
                setFiles([]);
                setVideoUrl(null);
                setFieldValue('videoUrl', null);
              }}
            >
              <FiTrash2 />
            </button>
          </div>
        );
      }
      if (!!imageUrl && _.isString(imageUrl) && imageUrl.length > 0) {
        return (
          <div className="thumb" key="image-preview">
            <div className="thumb-inner">
              <img src={imageUrl} alt="" />
            </div>
            <button
              className="thumb-button"
              onClick={() => {
                setFiles([]);
                setImageUrl(null);
                setFieldValue('imageUrl', null);
              }}
            >
              <FiTrash2 />
            </button>
          </div>
        );
      }
    }
    return (
      <>
        {files?.map((file) => {
          if (file?.type.includes('image')) {
            return (
              <div className="thumb" key={file.name}>
                <div className="thumb-inner">
                  <img src={file?.preview} alt="" />
                </div>
                <button className="thumb-button" onClick={() => setFiles([])}>
                  <FiTrash2 />
                </button>
              </div>
            );
          }

          if (file?.type.includes('video')) {
            return (
              <div className="thumb" key={file?.name}>
                <video width="auto" height="auto" controls>
                  <source src={file?.preview} type={file?.type} />
                </video>

                <button className="thumb-button" onClick={() => setFiles([])}>
                  <FiTrash2 />
                </button>
              </div>
            );
          }

          return null;
        })}
      </>
    );
  };

  const togglePosition = (position, setFieldValue) => {
    let newPositions = positions;
    if (positions.map((p) => p.position).includes(position)) {
      newPositions = _.remove(positions, (p) => p.position !== position);
    } else {
      newPositions = [
        ...positions,
        ...[positionValueAndLabelAndPositionForPosition(position)],
      ];
    }
    newPositions.sort((a, b) => {
      return a.position - b.position;
    });
    setFieldValue('positions', newPositions);
    setPositions(newPositions);
  };
  const { positionHighlights, label } =
    positionHighlightsAndLabelForPositions(positions);
  return (
    <>
      <Formik
        enableReinitialize
        innerRef={formikRef}
        initialValues={!!question ? question : initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          isValid,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <>
            <CCard className="create-question-grid-wrapper">
              <CCardHeader className="header">
                <div
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <CCardTitle style={{ margin: 0, display: 'inline-block' }}>
                    {inEditMode ? 'Edit Question' : 'Create Question'}
                  </CCardTitle>
                  <CButton
                    variant="outline"
                    color="success"
                    onClick={() => {
                      setShowMockup(true);
                    }}
                  >
                    <FaExternalLinkAlt className="mr-2" />
                    Preview Question
                  </CButton>
                </div>
              </CCardHeader>
              <CCard className="create-question-section-categorization">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <CCardTitle className="mb-0">Categorization</CCardTitle>
                  <p style={{ fontSize: 14, marginBottom: 0 }}>
                    <CTooltip content="This will help you find the question later on. It is not required.">
                      <FaInfoCircle className="ml-2" style={{ fontSize: 18 }} />
                    </CTooltip>
                  </p>
                </div>

                <QuestionCategories
                  style={{
                    overflow: 'scroll',
                    maxHeight: 'calc(100% - 40px)',
                    paddingBottom: 150,
                  }}
                  preExpandedSections={{ categories: true }}
                  preSelectedCategories={question?.categories || []}
                  hiddenSections={['playerTypes', 'questionTypes']}
                  handleSelectedCategories={(data) => {
                    // setSelectedFilteringCategories(data);
                    // setSelectedCategories(data?.categories || []);
                    // setSelectedPlayerTypes(data?.playerTypes || []);
                    // setSelectedQuestionTypes(data?.questionTypes || []);
                    setFieldValue('categories', data?.categories || []);
                  }}
                />
              </CCard>
              <CCard className="create-question-section-main">
                <CCardTitle>Question Details</CCardTitle>
                <div style={{ overflow: 'scroll' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <div className="d-flex flex-row mb-2">
                        <CSwitch
                          className="mr-2"
                          color="dark"
                          shape="pill"
                          id="scoreboard"
                          variant="opposite"
                          checked={showScoreboard === true}
                          onClick={() => {
                            setFieldValue('showScoreboard', !showScoreboard);
                            setShowScoreboard(!showScoreboard);
                          }}
                          readOnly={true}
                        />
                        <CLabel htmlFor="scoreboard">Show Scoreboard</CLabel>
                      </div>
                      <div>
                        {showScoreboard && (
                          <ScoreBoard
                            editable={true}
                            situation={situation}
                            setSituation={(situation) => {
                              setSituation(situation);
                              setFieldValue('situation', situation);
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <div className="d-flex flex-row mb-2">
                        <CSwitch
                          className="mr-2"
                          color="dark"
                          shape="pill"
                          id="field"
                          variant="opposite"
                          checked={showField === true}
                          onClick={() => {
                            setFieldValue('showField', !showField);
                            setShowField(!showField);
                          }}
                          readOnly={true}
                        />
                        <CLabel htmlFor="field">Show Field</CLabel>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {showField && (
                          <>
                            <BallField
                              positionHighlights={positionHighlights}
                              editable={true}
                              size={180}
                              togglePosition={(position) =>
                                togglePosition(position, setFieldValue)
                              }
                            />
                            {!!positions &&
                              positions.length === 1 &&
                              _.isString(label) &&
                              label.length > 0 && (
                                <CLabel
                                  style={{ fontSize: 18, fontWeight: 900 }}
                                >
                                  {label}
                                </CLabel>
                              )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <CLabel>Image or Video for questions</CLabel>
                  {!(
                    !!imageUrl &&
                    _.isString(imageUrl) &&
                    imageUrl.length > 0
                  ) &&
                  !(
                    !!videoUrl &&
                    _.isString(videoUrl) &&
                    videoUrl.length > 0
                  ) &&
                  (files || []).length === 0 ? (
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />
                      <p>Drag 'n' drop image or video</p>
                      <em>
                        (Only *.jpeg and *.png images or *.mp4 will be accepted)
                      </em>
                    </div>
                  ) : (
                    <aside className="thumbs-container">
                      {renderThumbs(setFieldValue)}
                    </aside>
                  )}

                  <CFormGroup>
                    <CLabel htmlFor="text">
                      Text <span className="text-danger">*</span>
                    </CLabel>
                    <CTextarea
                      required
                      id="text"
                      type="text"
                      value={values.text}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {_.isString(errors.text) && errors.text.length > 0 && (
                      <div
                        style={{
                          marginTop: 0,
                          fontSize: 12,
                          fontWeight: 'boldest',
                          fontFamily: 'AvinerHeavy',
                          color: 'red',
                        }}
                      >
                        {errors.text}
                      </div>
                    )}
                  </CFormGroup>

                  <CFormGroup>
                    <CLabel htmlFor="thinkAbout">
                      Think About{' '}
                      <CTooltip
                        style={{ fontSize: 10 }}
                        content="This text will be shown to your players when they review a quiz AFTER they have submitted their answers. Use it as a training tool."
                      >
                        <FaInfoCircle />
                      </CTooltip>
                    </CLabel>
                    <CTextarea
                      id="thinkAbout"
                      type="text"
                      value={values.thinkAbout}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </CFormGroup>

                  <CFormGroup>
                    <CLabel htmlFor="tags">Tags</CLabel>
                    <CRow>
                      <CCol xs="10" className="pr-0">
                        <Select
                          isMulti
                          id="tags"
                          name="tags"
                          options={tags}
                          ref={selectTagsRef}
                          onChange={(option) => setFieldValue('tags', option)}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: darkMode
                                ? 'black'
                                : theme.colors.primary,
                              primary25: darkMode
                                ? 'black'
                                : theme.colors.primary25,
                              dangerLight: darkMode
                                ? 'black'
                                : theme.colors.dangerLight,
                              neutral0: darkMode
                                ? '#2a2b36'
                                : theme.colors.neutral0,
                            },
                          })}
                        />
                      </CCol>
                      <CCol xs="2" className="text-right">
                        <CButton
                          className="pt-2 pb-2"
                          onClick={() => setShowTagModal(true)}
                        >
                          <FiPlus />
                        </CButton>
                      </CCol>
                    </CRow>
                  </CFormGroup>

                  <Field type="checkbox" name="withTimer">
                    {({ field, form, meta }) => (
                      <CFormGroup variant="custom-checkbox" className="pb-3">
                        <CInputCheckbox custom id="withTimer" {...field} />
                        <CLabel variant="custom-checkbox" htmlFor="withTimer">
                          Question has timeout
                        </CLabel>
                      </CFormGroup>
                    )}
                  </Field>

                  {values.withTimer && (
                    <CFormGroup>
                      <CLabel htmlFor="type">
                        Time (in seconds) <span className="text-danger">*</span>
                      </CLabel>
                      <CInput
                        type="number"
                        id="timer"
                        step="1"
                        placeholder=".s"
                        value={values.timer}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </CFormGroup>
                  )}
                </div>
              </CCard>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 10,
                }}
              >
                <CCard className="create-question-section-details">
                  <CCardTitle>Answer Options</CCardTitle>
                  <div style={{ overflowY: 'scroll' }}>
                    <>
                      <div
                        style={{
                          flexDirection: 'column',
                          display: 'flex',
                          alignItems: 'flex-end',
                        }}
                      >
                        <CLabel>Choose from a template (optional):</CLabel>
                        <div
                          style={{
                            flexDirection: 'row',
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          {optionTemplates.map(
                            (
                              {
                                imgUrl,
                                numOptions,
                                correctOption,
                                isTrueFalse,
                                newGroup,
                              },
                              index
                            ) => (
                              <CButton
                                key={`${index}`}
                                style={{
                                  padding: 0,
                                  marginLeft: newGroup ? 12 : 0,
                                }}
                                onClick={() => {
                                  useOptionTemplate({
                                    formikRef,
                                    numOptions,
                                    correctOption,
                                    isTrueFalse,
                                  });
                                }}
                              >
                                <CImg
                                  src={`/img/question-option-templates/${imgUrl}.png`}
                                  fluid
                                  style={{
                                    height: 24,
                                    objectFit: 'contain',
                                    flex: 0,
                                    marginRight: 4,
                                  }}
                                />
                              </CButton>
                            )
                          )}
                        </div>
                      </div>
                      <hr />
                      <FieldArray
                        name="options"
                        render={(arrayHelpers) => (
                          <>
                            {values.options && values.options.length > 0
                              ? values.options.map((_dummy, index) => {
                                  return (
                                    <CRow
                                      style={{ maxWidth: '100%' }}
                                      key={index}
                                    >
                                      <CCol>
                                        <Field name={`options[${index}].text`}>
                                          {({ field }) => {
                                            return (
                                              <CFormGroup>
                                                <CLabel>
                                                  Text{' '}
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </CLabel>
                                                <CInput
                                                  {...field}
                                                  required
                                                  onChange={(e) => {
                                                    setFieldValue(
                                                      `options[${index}].text`,
                                                      e.target.value
                                                    );
                                                  }}
                                                />
                                              </CFormGroup>
                                            );
                                          }}
                                        </Field>
                                        {errors.options &&
                                          _.isArray(errors.options) &&
                                          errors.options.length > index &&
                                          _.isString(
                                            errors.options?.[index]?.text
                                          ) &&
                                          errors.options?.[index]?.text.length >
                                            0 &&
                                          touched.options?.[index]?.text ===
                                            true && (
                                            <div
                                              style={{
                                                marginTop: -10,
                                                fontSize: 12,
                                                fontWeight: 'boldest',
                                                fontFamily: 'AvinerHeavy',
                                                color: 'red',
                                              }}
                                            >
                                              {errors.options?.[index]?.text}
                                            </div>
                                          )}
                                      </CCol>

                                      <CCol xs={2}>
                                        <Field
                                          name={`options[${index}].points`}
                                        >
                                          {({ field }) => (
                                            <CFormGroup>
                                              <CLabel>
                                                Points{' '}
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </CLabel>
                                              <CInput
                                                type="number"
                                                step="1"
                                                {...field}
                                              />
                                            </CFormGroup>
                                          )}
                                        </Field>
                                      </CCol>

                                      <CCol xs={2}>
                                        <Field type="radio">
                                          {({ field }) => (
                                            <CFormGroup>
                                              <CLabel>Correct?</CLabel>
                                              <CInputRadio
                                                className="form-control"
                                                {...field}
                                                checked={
                                                  values.options[index].correct
                                                }
                                                onClick={() => {
                                                  values.options.forEach(
                                                    (_, optionIndex) => {
                                                      setFieldValue(
                                                        `options[${optionIndex}].correct`,
                                                        false
                                                      );
                                                    }
                                                  );
                                                  setFieldValue(
                                                    `options[${index}].correct`,
                                                    true
                                                  );
                                                }}
                                                style={{
                                                  width: 20,
                                                  height: 20,
                                                  marginTop: 13,
                                                  marginLeft: 19,
                                                }}
                                              />
                                            </CFormGroup>
                                          )}
                                        </Field>

                                        <div>
                                          <CButton
                                            type="button"
                                            style={{
                                              position: 'relative',
                                              left: 60,
                                              top: -21,
                                            }}
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                          >
                                            <FiTrash2 size={24} />
                                          </CButton>
                                        </div>
                                      </CCol>
                                    </CRow>
                                  );
                                })
                              : null}
                            {errors.options &&
                              _.isString(errors.options) &&
                              errors.options.length > 0 && (
                                <div
                                  style={{
                                    marginTop: -10,
                                    fontSize: 12,
                                    fontWeight: 'boldest',
                                    fontFamily: 'AvinerHeavy',
                                    color: 'red',
                                    textAlign: 'right',
                                  }}
                                >
                                  {errors.options}
                                </div>
                              )}
                            <CButton
                              type="button"
                              role="button"
                              onClick={() => {
                                arrayHelpers.push(defaultQuestionOption);
                              }}
                            >
                              <FiPlusSquare /> Add option
                            </CButton>
                          </>
                        )}
                      />
                    </>

                    <AddQuestionTag
                      showModal={showTagModal}
                      closeModal={() => setShowTagModal(false)}
                      handleSubmit={handleSubmitTag}
                    />
                  </div>
                </CCard>
                <CCard style={{ flex: 0 }}>
                  <CCardTitle>Difficulty</CCardTitle>
                  <FormGroup style={{ margin: '0px auto 1rem' }}>
                    <div>
                      <InputGroup>
                        <div
                          className="number-of-questions-wrapper"
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <div
                            className="number-of-questions-slider-wrapper"
                            style={{ display: 'flex', gap: 8 }}
                          >
                            <span>Easier</span>
                            <input
                              style={{
                                width: 300,
                                maxWidth: 'calc(100% - 100px)',
                              }}
                              type="range"
                              min={1}
                              max={9}
                              name="difficulty"
                              id="difficulty"
                              value={difficulty}
                              onChange={(d) => {
                                setDifficulty(d.target.value);
                                setFieldValue('difficulty', d.target.value);
                              }}
                              // className={`form-control ${
                              //   touched.code && errors.code ? 'is-invalid' : ''
                              // }`}
                            />
                            <span>Harder</span>
                          </div>
                          <span
                            style={{ textAlign: 'center' }}
                          >{`Difficulty: ${difficulty} (${
                            difficulty < 4
                              ? 'Easy'
                              : difficulty > 6
                              ? 'Hard'
                              : 'Medium'
                          })`}</span>
                        </div>
                      </InputGroup>
                    </div>
                  </FormGroup>
                </CCard>
              </div>
              <div className="create-question-validation-error create-question-categorization-validation-error">
                {_.isObject(validationErrors) &&
                  _.isArray(validationErrors.categories) &&
                  validationErrors.categories.length > 0 && (
                    <>{validationErrors.categories.join(', ')}</>
                  )}
              </div>
              <div className="create-question-validation-error create-question-question-validation-error">
                {_.isObject(validationErrors) &&
                  _.isArray(validationErrors.question) &&
                  validationErrors.question.length > 0 && (
                    <>{validationErrors.question.join(', ')}</>
                  )}
              </div>
              <div className="create-question-validation-error create-question-answer-validation-error">
                {_.isObject(validationErrors) &&
                  _.isArray(validationErrors.answers) &&
                  validationErrors.answers.length > 0 && (
                    <>{validationErrors.answers.join(', ')}</>
                  )}
              </div>
              <CCard className="footer">
                <div>
                  <CButton
                    type="reset"
                    color="danger"
                    variant="outline"
                    className="mr-2"
                    onClick={() => {
                      clearFormAndReturnToQuestions();
                    }}
                  >
                    Cancel
                  </CButton>

                  <CButton
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!isValid}
                  >
                    {inEditMode ? 'Save Question' : 'Create Question'}
                  </CButton>
                </div>
              </CCard>
            </CCard>
            <CModal
              show={showMockup}
              style={{ background: 'transparent', border: 'none' }}
              onClose={() => {
                setShowMockup(false);
              }}
            >
              <CModalBody>
                <MobileMockup
                  content={
                    <MobileQuestionViewContent
                      question={values}
                      files={files}
                      onClose={() => {
                        setShowMockup(false);
                      }}
                    />
                  }
                />
              </CModalBody>
            </CModal>
          </>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    questionData: state.ViewQuestionState,
    questions: state.DataQuestionState,
  };
};

const mapDispatchToProps = {};

CreateQuestionPage.propTypes = {
  questionData: PropTypes.shape({
    toggleOnField: PropTypes.bool,
    toggleOnScoreboard: PropTypes.bool,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateQuestionPage);
