const defaultCreateTeamState = {
  createTeam: null,
  isLoading: false,
  status: 0,
  forgotPassStatus: null,
};

export const createTeamReducer = (state = defaultCreateTeamState, action) => {
  switch (action.type) {
    case 'DEFAULT_SCRIMMAGE':
      return { ...state, isLoading: false, status: 0, forgotPassStatus: null };

    case 'REQUESTED_CREATE_TEAM':
      return { ...state, isLoading: true, status: 1 };
    case 'RECEIVED_CREATE_TEAM_FAILURE':
      return { ...state, isLoading: false, status: 2 };
    case 'RECEIVED_CREATE_TEAM_SUCCESS':
      return {
        ...state,
        createTeam: action.createTeamData,
        isLoading: false,
        status: 3,
      };

    case 'RECEIVED_GET_TEAM_EDIT_DATA':
      return { ...state, isLoading: true };
    case 'RECEIVED_GET_TEAM_EDIT_DATA_FAILURE':
      return { ...state, isLoading: false };
    case 'RECEIVED_GET_TEAM_EDIT_DATA_SUCCESS':
      return {
        ...state,
        getTeamEditData: action.getTeamEditData,
        isLoading: false,
      };

    case 'REQUESTED_TEAM_EDIT':
      return { ...state, isLoading: true, status: 1 };
    case 'RECEIVED_TEAM_EDIT_FAILURE':
      return { ...state, isLoading: false, status: 2 };
    case 'RECEIVED_TEAM_EDIT_SUCCESS':
      return {
        ...state,
        TeamEdit: action.TeamEdit,
        isLoading: false,
        status: 3,
      };
    default:
  }

  return state;
};
