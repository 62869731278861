import {
  SET_CURRENT_TOUR,
  SET_CURRENT_TOUR_STEP,
  TRIGGER_TOUR_NAVIGATION,
  CLOSE_TOUR,
} from './constants';

/**
 * Set current user
 */
export function setCurrentTour(payload) {
  return {
    type: SET_CURRENT_TOUR,
    payload,
  };
}

export function setCurrentTourStep(payload) {
  return {
    type: SET_CURRENT_TOUR_STEP,
    payload,
  };
}

export function triggerTourNavigation(payload) {
  return {
    type: TRIGGER_TOUR_NAVIGATION,
    payload,
  };
}
