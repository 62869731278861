import { isEmpty, isString } from 'lodash';
import React from 'react';
import { Helmet } from 'react-helmet-async';
export default function SEOHead({
  title = null,
  description = null,
  name = null,
  type = null,
}) {
  return (
    <Helmet>
      {isString(title) && !isEmpty(title) && (
        <>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta name="twitter:title" content={title} />
        </>
      )}
      {isString(description) && !isEmpty(description) && (
        <>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta name="twitter:description" content={description} />
        </>
      )}
      {isString(type) && !isEmpty(type) && (
        <>
          <meta property="og:type" content={type} />
          <meta name="twitter:card" content={type} />
        </>
      )}
      {isString(name) && !isEmpty(name) && (
        <>
          <meta name="twitter:creator" content={name} />
        </>
      )}
    </Helmet>
  );
}
