import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CImg,
  CRow,
  CSpinner,
  CTooltip,
} from '@coreui/react';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { db, functions } from '../../../index';
import { Button } from 'react-bootstrap';
import { InviteUsersToExamModal } from '../../../r-components';
import { AUTO_QUIZ_DEFAULT_NUMBER_QUESTIONS } from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import {
  FaAddressBook,
  FaBaseballBall,
  FaBook,
  FaRunning,
} from 'react-icons/fa';
import { GiBaseballGlove } from 'react-icons/gi';

const AutoQuizButtons = () => {
  const [inviteUsersModal, setInviteUsersModal] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedQuizTitle, setSelectedQuizTitle] = useState('');
  const team_id = useSelector(
    (state) =>
      state.DataTeamState.teamData?._id ||
      state.DataTeamState.data?.[0]?._id ||
      ''
  );

  const toggleInviteUserModal = useCallback(() => {
    setInviteUsersModal((usersModal) => !usersModal);
  }, []);

  return (
    <>
      <CRow>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <h2>6Tool School: Auto-Quiz</h2>
        </div>
      </CRow>
      <CRow>
        <div
          className="auto-quiz-wrapper"
          style={{
            padding: '30px 120px',
            display: 'flex',
            alignItems: 'stretch',
            justifyContent: 'center',
            gap: 20,
            margin: '0 auto',
            flexWrap: 'wrap',
          }}
        >
          {[
            {
              label: 'Baseball Lingo',
              categories: ['OaBUITxZdjA8139mxe77'],
            },
            {
              label: 'Baserunning',
              categories: [
                'IcFXufD2na6uAmM8XHYQ',
                'm4PmYkc9U9sxyJQY1rQV',
                'ppsFewTlKB1L5PKpfySG',
                'rSZObGCqFgVXqNcYyRL6',
              ],
            },
            {
              label: 'Communication on Defense',
              categories: ['fyuyjt8wTn40SYdL1hQa'],
            },
            {
              label: 'Covering Bases',
              categories: ['r5ji0XOdPhp6MOKQRljW'],
            },
            { label: 'Cuts / Relays', categories: ['2H4cZvrpbTYns0YGivrd'] },
            {
              label: 'Defense',
              categories: ['VQO1xknhMHZCesLwSjlA'],
            },
            {
              label: 'Mental Approach',
              categories: ['bzchR9Fy7FsVxWmCZ10X'],
            },
            {
              label: 'Pitching Strategy',
              categories: ['0BxMryB3mt7cLZcQfZMO', 'bzchR9Fy7FsVxWmCZ10X'],
            },
            {
              label: 'Rules',
              categories: ['W5TXtfsF1N52Qn2dF5M7'],
            },
            {
              label: 'Where to throw the ball',
              categories: ['ZC5UvR0BFPpKXgZxfTyZ'],
            },
          ].map((t, _i) => (
            <Button
              onClick={async () => {
                setInviteUsersModal(true);
                setSelectedQuizTitle(t.label);
                setSelectedCategories([t]);
                // const qCf = functions.httpsCallable(
                //   'createQuizWithRandomQuestionsForCategoriesCallable'
                // );
                // const qC = await qCf({ categories: t.categories });
                // console.log(qC);
              }}
              variant="dark"
              size="lg"
              style={{
                fontSize: '2.2em',
                fontWeight: '900',
                width: '18%',
                minHeight: 160,
                padding: 14,
              }}
              key={`${_i}`}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  height: '100%',
                  color: 'white',
                }}
              >
                {t.icon}
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    lineHeight: '1.2em',
                    fontSize: '1.4vw',
                    marginTop: 8,
                    color: 'white',
                    fontWeight: 700,
                    textTransform: 'uppercase',
                  }}
                >
                  {t.label}
                </div>
              </div>
            </Button>
          ))}
        </div>
      </CRow>
      <InviteUsersToExamModal
        quiz_id={null}
        quiz_team_id={team_id}
        isAutoQuiz={true}
        autoQuizCategories={selectedCategories}
        title={selectedQuizTitle}
        show={inviteUsersModal}
        onClose={toggleInviteUserModal}
      />
    </>
  );
};

export default AutoQuizButtons;
