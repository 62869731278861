import Container from '../Container/Container';
import { Content, H1, StepItem, Steps, Wrapper } from './How6ToolWorkStyles';
import React from 'react';

import guide from '../../assets/guide.png';

const How6ToolWork = () => {
  return (
    <Wrapper>
      <Container>
        <Content>
          <H1>How 6Tool Works</H1>
          <img src={guide} alt="" />

          <Steps>
            <StepItem current>
              <span>
                You assign quizzes in your
                <br /> coaching portal
              </span>
              <strong>Step 1</strong>
            </StepItem>
            <StepItem>
              <span>
                Your team takes quizzes via the
                <br /> mobile app - anytime, anywhere
              </span>

              <strong>Step 2</strong>
            </StepItem>
            <StepItem>
              <span>
                You review quiz results to create
                <br /> the perfect practice plan
              </span>
              <strong>Step 3</strong>
            </StepItem>
          </Steps>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default How6ToolWork;
