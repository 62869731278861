import { CFormGroup, CImg, CInputCheckbox, CLabel, CLink } from '@coreui/react';
import {
  FaArrowAltCircleLeft,
  FaArrowAltCircleRight,
  FaArrowRight,
  FaCheck,
  FaCopy,
  FaEdit,
  FaMobileAlt,
  FaTrash,
} from 'react-icons/fa';

import './questions.scss';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import _ from 'lodash';
import { ScoreBoard } from '../../r-components';
import BallField from '../../r-components/BallField/BallField';
import { positionHighlightsAndLabelForPositions } from '../../r-reusable/Positions';
import { useDispatch } from 'react-redux';
import {
  editQuestion,
  showConfirmDeleteModal,
  showConfirmDuplicateModal,
  showConfirmEditModal,
} from '../../actions/questionActionCreator';
import ConfirmModal from '../../r-components/ConfirmModal/ConfirmModal';
import { deleteQuestion, mimeTypeFromURL } from '../../helpers/data';
import { useHistory } from 'react-router-dom';
import { TABS } from '../../AppTabs';
import QuestionMedia from '../../r-components/Questions/QuestionMedia';
import QuestionOptions from '../../r-components/Questions/QuestionOptions';
import QuestionText from '../../r-components/Questions/QuestionText';
import { FaEllipsis, FaEllipsisVertical } from 'react-icons/fa6';
import { ACCESS_ACTIONS, canDoAction, checkAccess } from '../../helpers/access';
import useAccountSelectors from '../../hooks/useAccountSelectors';

const QuestionListItem = ({
  question,
  index,
  side,
  expanded,
  selectQuestion,
  selectedBankedQuestions,
  expandedQuestion,
  selectedQuestions,
  setExpandedQuestion,
  reordering,
  moveSingleQuestion,
  mockupSingleQuestion,
  duplicateQuestion,
  userCanEdit,
  userCanCreateQuiz,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { tier, type, showSubscriptionModal } = useAccountSelectors();

  const renderExpandedView = ({ question, side }) => {
    if (side === 'bank') {
      return renderExpandedViewBankSide({ question });
    }
    return renderExpandedViewLeftSide({ question });
  };

  const renderExpandedViewLeftSide = ({ question }) => {
    const { positionHighlights, label } =
      positionHighlightsAndLabelForPositions(question?.positions);
    return (
      <>
        <div className="expanded-view-wrapper">
          <div className="expanded-view-content">
            <div className="expanded-view-left-column">
              <QuestionMedia
                imageUrl={question?.imageUrl}
                videoUrl={question?.videoUrl}
              />
              <QuestionOptions options={question?.options} />
              {renderExpandedViewThinkAbout({
                thinkAbout: question?.thinkAbout,
              })}
            </div>
            {(question?.showScoreboard === true ||
              question?.showField === true) && (
              <div className="expanded-view-right-column">
                {question?.showScoreboard === true && (
                  <ScoreBoard
                    editable={false}
                    situation={question.situation}
                    setSituation={() => {}}
                  />
                )}
                {question?.showField === true && (
                  <>
                    <BallField
                      size={120}
                      positionHighlights={positionHighlights}
                    />
                    {_.isString(label) && label.length > 0 && (
                      <CLabel>{label}</CLabel>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
          {renderCreatedAndUpdated({
            createdAt: question?.created_at,
            updatedAt: question?.updated_at,
            owningOrganization: question?.owningOrganization || 'all',
          })}
        </div>
      </>
    );
  };

  const renderCreatedAndUpdated = ({
    createdAt,
    updatedAt,
    owningOrganization,
  }) => {
    if (owningOrganization === 'all') {
      return null;
    }
    try {
      let showUpdated = false;
      let output = null;
      let createdDate = '';
      let updatedDate = '';
      if (_.isObject(createdAt)) {
        createdDate = createdAt.toDate().toLocaleString('en-US', {
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
      }
      if (_.isObject(updatedAt)) {
        if (updatedAt.toDate() > createdAt.toDate()) {
          updatedDate = updatedAt.toDate().toLocaleString('en-US', {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          });
        }
      }
      return (
        <CLabel>
          {createdDate.length > 0 && `Created: ${createdDate}`}
          {createdDate.length > 0 && updatedDate.length > 0 && ', '}
          {updatedDate.length > 0 && `Updated: ${updatedDate}`}
        </CLabel>
      );
    } catch (error) {
      return null;
    }
  };

  const renderExpandedViewThinkAbout = ({ thinkAbout }) => (
    <>
      {_.isString(thinkAbout) && thinkAbout.length > 0 && (
        <>
          <CLabel
            style={{
              marginTop: 25,
              marginBottom: 0,
              fontSize: 18,
              fontWeight: 900,
            }}
          >
            Keep in mind:
          </CLabel>
          <p style={{ fontSize: 14, lineHeight: '18px' }}>{thinkAbout}</p>
        </>
      )}
    </>
  );
  const renderExpandedViewBankSide = ({ question }) => {
    const { positionHighlights, label } =
      positionHighlightsAndLabelForPositions(question?.positions);
    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingTop: 15,
            gap: 10,
          }}
        >
          {(question?.showScoreboard === true ||
            question?.showField === true) && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
                flexWrap: 'wrap-reverse',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {question?.showField === true && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <BallField
                    size={80}
                    positionHighlights={positionHighlights}
                  />
                  {_.isString(label) && label.length > 0 && (
                    <CLabel>{label}</CLabel>
                  )}
                </div>
              )}
              {question?.showScoreboard === true && (
                <ScoreBoard
                  editable={false}
                  situation={question.situation}
                  setSituation={() => {}}
                />
              )}
            </div>
          )}
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <QuestionOptions options={question?.options} />
            {renderExpandedViewThinkAbout({ thinkAbout: question?.thinkAbout })}
            {renderCreatedAndUpdated({
              createdAt: question?.created_at,
              updatedAt: question?.updated_at,
              owningOrganization: question?.owningOrganization || 'all',
            })}
          </div>
        </div>
      </>
    );
  };

  const renderActionButtons = () => (
    <>
      <div className="item-action-buttons only-on-hover only-on-expand">
        {side !== 'bank' && (
          <div className="item-action-buttons-more-button-wrapper">
            <FaEllipsisVertical />
          </div>
        )}
        {side === 'bank' && (
          <CLink
            onClick={(e) => {
              moveSingleQuestion({ question, direction: 'left' });
            }}
          >
            <FaArrowAltCircleLeft />
          </CLink>
        )}
        {userCanEdit && (
          <>
            <CLink
              onClick={(e) => {
                checkAccess({
                  action: ACCESS_ACTIONS.CREATE_QUESTIONS,
                  tier,
                  type,
                  showSubscriptionModal,
                }).doWithAccess(() => {
                  if (question.owningOrganization === 'all') {
                    dispatch(showConfirmEditModal({ question }));
                  } else {
                    history.push(
                      TABS.COACH_QUESTIONS_EDIT.pathVar({ id: question.id })
                    );
                  }
                });
              }}
            >
              <FaEdit /> {side !== 'bank' && 'Edit'}
            </CLink>
            <CLink
              className="success"
              onClick={(e) => {
                checkAccess({
                  action: ACCESS_ACTIONS.CREATE_QUESTIONS,
                  tier,
                  type,
                  showSubscriptionModal,
                }).doWithAccess(() => {
                  dispatch(
                    showConfirmDuplicateModal({ questionId: question.id })
                  );
                });
                // console.log(setShowConfirmDuplicateModal);
                // setHandleDuplicateQuestion(() => { console.log(`question ${question.id} duplicated`); });
                // setShowConfirmDuplicateModal(true);
              }}
            >
              <FaCopy /> {side !== 'bank' && 'Duplicate'}
            </CLink>
            {side === 'left' && (
              <CLink
                className="danger"
                onClick={(e) => {
                  dispatch(showConfirmDeleteModal({ questionId: question.id }));
                  // setHandleDeleteQuestion(() => { deleteQuestion({questionId: question.id, owningOrganization: question.owningOrganization}) });
                  // setShowConfirmDeleteModal(true);
                }}
              >
                <FaTrash /> Delete
              </CLink>
            )}
          </>
        )}

        <CLink
          className={`${side !== 'bank' ? 'preview-icon' : ''}`}
          onClick={(e) => {
            mockupSingleQuestion({ question });
          }}
        >
          <FaMobileAlt /> {side !== 'bank' && 'Preview'}
        </CLink>
        {side !== 'bank' && userCanCreateQuiz && (
          <CLink
            onClick={(e) => {
              moveSingleQuestion({ question, direction: 'right' });
            }}
          >
            <FaArrowAltCircleRight /> Add to Quiz
          </CLink>
        )}
      </div>
    </>
  );

  return (
    <>
      <CFormGroup variant="checkbox" className="checkbox question-main">
        <>
          {!reordering && (
            <CInputCheckbox
              name={`checkbox_${question.id}`}
              onClick={() => {
                if (!reordering) {
                  selectQuestion({ question, side });
                }
              }}
              checked={
                (side === 'bank'
                  ? selectedBankedQuestions
                  : selectedQuestions
                ).filter(
                  (selectedQuestion) => selectedQuestion.id === question.id
                ).length > 0
              }
              onChange={() => {}}
            />
          )}
          {side !== 'bank' ? (
            <div style={{ flex: 1 }}>
              <QuestionText
                reordering={reordering}
                expandedQuestion={expandedQuestion}
                question={question}
                setExpandedQuestion={setExpandedQuestion}
              />
              {!reordering && renderActionButtons()}
            </div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <QuestionText
                reordering={reordering}
                expandedQuestion={expandedQuestion}
                question={question}
                setExpandedQuestion={setExpandedQuestion}
              />
              {!reordering && renderActionButtons()}
            </div>
          )}
        </>
        <CImg
          src={`/img/${
            question.owningOrganization === 'all' ? 'light-bulb-icon' : 'user'
          }.png`}
          fluid
          style={{
            marginLeft: 10,
            height: 24,
            objectFit: 'contain',
            flex: 0,
          }}
        />
      </CFormGroup>
      {expanded && renderExpandedView({ question, side })}
    </>
  );
};

QuestionListItem.propTypes = {
  display: PropTypes.bool,
  children: PropTypes.node,
};

export default QuestionListItem;
