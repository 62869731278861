import produce from 'immer';

import { SET_CURRENT_OWNERS, CLEAR_OWNERS } from './constants';

const INITIAL_STATE = {
  data: [],
  map: {},
};

export const ownersReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_CURRENT_OWNERS:
        return {
          ...action.payload,
        };

      case CLEAR_OWNERS:
        return {
          ...INITIAL_STATE,
        };

      default:
        return state;
    }
  });
