import React, { Component } from 'react';
import './text-page.scss';
import { DOMAIN, EMAIL_ADDRESSES } from '../config';

class Terms extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div className="d-flex">
          <div className="container mt-9">
            <div className="mx-lg-auto text-page">
              <h1>Services Terms of Use</h1>
              <p>Last modified: July 7, 2023</p>
              <h2>Acceptance of the Terms of Use</h2>
              <p>
                These terms of use are entered into by and between You (whether
                for yourself or on behalf of a minor for whom you are a parent
                or guardian) and 6Tool LLC (“<strong>Company</strong>,” “
                <strong>we</strong>,” “<strong>our</strong>” or “
                <strong>us</strong>”). The following terms and conditions (“
                <strong>Terms of Use</strong>”) govern your access to and use of
                Company’s website (available at <a href={DOMAIN}>{DOMAIN}</a>)
                and Company’s app available on iOS and Android devices (the “
                <strong>Application</strong>”, and together with the website,
                the “<strong>Services</strong>”), whether as a guest or a
                registered user. There are two types of registered users, “
                <strong>Team Members</strong>,” who are individuals invited to
                use the Application by “<strong>Coaches</strong>,” who are paid
                registered users who have the right during such paid term of use
                to invite Team Members to become a registered user able to use
                the Application during the Coach’s paid term of use. If you are
                a Coach, you agree to only invite Team Members who are 18 years
                of age or older, or, if you wish to invite a Team Member who is
                under 18 years old, you will only do so by sending the
                registration offering to a parent or guardian of such Team
                Member who may consent to the creation of an account for such
                Team Member. By registering for the Services, you represent and
                warrant that you are of legal age to form a binding contract
                with the Company and meet all of the foregoing eligibility
                requirements, and if you are a parent or guardian of a potential
                Team Member who is under 18 years old, you hereby consent to the
                creation of a registered user account for your child as a Team
                Member and to the collection of personal information disclosed
                in our <a href="/privacy">Privacy Policy</a>. If you do not meet
                all of these requirements, you must not access or use the
                Services.
              </p>
              <h2>Changes to the Terms of Use</h2>
              <p>
                We may revise and update these Terms of Use from time to time in
                our sole discretion. All changes are effective immediately when
                we post them for unregistered users, and at your next renewal
                for registered users, and in each case apply to all access to
                and use of the Services thereafter.
              </p>
              <p>
                Your continued use of the Services, including by renewing your
                subscription, following the posting of revised Terms of Use
                means that you accept and agree to the changes. You are expected
                to check this page from time to time, including in advance of
                renewal, so you are aware of any changes, as they are binding on
                you.
              </p>
              <h2>Accessing the Services and Account Security</h2>
              <p>
                You understand that unless you are a Coach, you will not have
                access to all features of the Services. If you are a Coach (for
                clarity, with a then-current paid subscription), you will be
                able to invite up to 40 Team Members to register for the
                Services as part of your team.
              </p>
              <p>
                We reserve the right to withdraw or amend this Services, and any
                service or material we provide on the Services, to our
                non-paying users in our sole discretion without notice. We will
                not be liable if for any reason all or any part of the Services
                is unavailable at any time or for any period for any user. From
                time to time, we may restrict access to some parts of the
                Services, or the entire Services, to users, including registered
                users, as necessary to protect our users, their data and the
                Services, as well as when useful to maintain or modify the
                Services.
              </p>
              <p className="list-header">You are responsible for both:</p>
              <ul>
                <li>
                  Making all arrangements necessary for you to have access to
                  the Services.
                </li>
                <li>
                  Ensuring that all persons who access the Services through your
                  internet connection are aware of these Terms of Use and comply
                  with them.
                </li>
              </ul>
              <p>
                To access the Services or some of the resources they offer, you
                may be asked to provide certain registration details or other
                information. It is a condition of your use of the Services that
                all the information you provide on the Services is correct,
                current, and complete. You agree that all information you
                provide to register with this Services or otherwise, including,
                but not limited to, through the use of any interactive features
                on the Services, is governed by our{' '}
                <a href="/privacy">Privacy Policy</a>, and you consent to all
                actions we take with respect to your information consistent with
                our Privacy Policy.
              </p>
              <p>
                If you choose, or are provided with, a user name, password, or
                any other piece of information as part of our security
                procedures, you must treat such information as confidential, and
                you must not disclose it to any other person or entity. You also
                acknowledge that your account is personal to you and agree not
                to provide any other person with access to these Services or
                portions of it using your user name, password, or other security
                information. You agree to notify us immediately of any
                unauthorized access to or use of your user name or password or
                any other breach of security. You also agree to ensure that you
                exit from your account at the end of each session. You should
                use particular caution when accessing your account from a public
                or shared computer so that others are not able to view or record
                your password or other personal information.
              </p>
              <p>
                We have the right to disable any user name, password, or other
                identifier, whether chosen by you or provided by us, at any time
                in our reasonable discretion, including if, in our opinion, you
                have violated any provision of these Terms of Use.
              </p>
              <h2>Intellectual Property Rights</h2>
              <p>
                The Services and its entire contents, features, and
                functionality (including but not limited to all information,
                software, text, displays, images, video, and audio, and the
                design, selection, and arrangement thereof) are owned by the
                Company, its licensors, or other providers of such material and
                are protected by United States and international copyright,
                trademark, patent, trade secret, and other intellectual property
                or proprietary rights laws.
              </p>
              <p className="list-header">
                These Terms of Use permit you to use the Services for a Coach’s
                use in support of his team and Team Members and your personal,
                non-commercial use. You must not reproduce, distribute, modify,
                create derivative works of, publicly display, publicly perform,
                republish, download, store, or transmit any of the material on
                our Services, except as follows:
              </p>
              <ul>
                <li>
                  Your computer or other device may temporarily store copies of
                  such materials in RAM incidental to your accessing and viewing
                  those materials.
                </li>
                <li>
                  You may store files that are automatically cached by your Web
                  browser or device for display enhancement purposes.
                </li>
                <li>
                  You may print or download one copy of a reasonable number of
                  pages of the Services for your own personal, non-commercial
                  use and not for further reproduction, publication, or
                  distribution.
                </li>
                <li>
                  You may download a copy of Company’s app to your mobile device
                  solely for your own personal, non-commercial use, subject to
                  these Terms of Use.
                </li>
              </ul>
              <p className="list-header">You must not:</p>
              <ul>
                <li>Modify copies of any materials from this site.</li>
                <li>
                  Rent use of the Services to any third party (other than your
                  Team Members).
                </li>
                <li>
                  Delete or alter any copyright, trademark, or other proprietary
                  rights notices from copies of materials from this site.
                </li>
              </ul>
              <p>
                You must not access or use for any commercial purposes any part
                of the Services or any services or materials available through
                the Services.
              </p>
              <p>
                If you print, copy, modify, download, or otherwise use or
                provide any other person with access to any part of the Services
                in breach of the Terms of Use, your right to use the Services
                will stop immediately and you must, at our option, return or
                destroy any copies of the materials you have made. No right,
                title, or interest in or to the Services or any content on the
                Services is transferred to you, and all rights not expressly
                granted are reserved by the Company. Any use of the Services not
                expressly permitted by these Terms of Use is a breach of these
                Terms of Use and may violate copyright, trademark, and other
                laws.
              </p>
              <h2>Trademarks</h2>
              <p>
                The Company name, and all related names, logos, product and
                service names, designs, and slogans are trademarks of the
                Company or its affiliates or licensors. You must not use such
                marks without the prior written permission of the Company. All
                other names, logos, product and service names, designs, and
                slogans on this Services are the trademarks of their respective
                owners.
              </p>
              <h2>Prohibited Uses</h2>
              <p>
                You may use the Services only for lawful purposes and in
                accordance with these Terms of Use. You agree not to use the
                Services:
              </p>
              <ul>
                <li>
                  In any way that violates any applicable federal, state, local,
                  or international law or regulation (including, without
                  limitation, any laws regarding the export of data or software
                  to and from the US or other countries).
                </li>
                <li>
                  For the purpose of exploiting, harming, or attempting to
                  exploit or harm minors in any way by exposing them to
                  inappropriate content, asking for personally identifiable
                  information, or otherwise.
                </li>
                <li>
                  To engage in any other conduct that restricts or inhibits
                  anyone’s use or enjoyment of the Services, or which, as
                  determined by us, may harm the Company or users of the
                  Services, or expose them to liability.
                </li>
              </ul>
              <p className="list-header">Additionally, you agree not to:</p>
              <ul>
                <li>
                  Use the Services in any manner that could disable, overburden,
                  damage, or impair the site or interfere with any other party’s
                  use of the Services, including their ability to engage in real
                  time activities through the Services.
                </li>
                <li>
                  Use any robot, spider, or other automatic device, process, or
                  means to access the Services for any purpose, including
                  monitoring or copying any of the material on the Services.
                </li>
                <li>
                  Use any manual process to monitor or copy any of the material
                  on the Services, or for any other purpose not expressly
                  authorized in these Terms of Use, without our prior written
                  consent.
                </li>
                <li>
                  Use any device, software, or routine that interferes with the
                  proper working of the Services.
                </li>
                <li>
                  Introduce any viruses, Trojan horses, worms, logic bombs, or
                  other material that is malicious or technologically harmful.
                </li>
                <li>
                  Attempt to gain unauthorized access to, interfere with,
                  damage, or disrupt any parts of the Services, the server on
                  which the Services is stored, or any server, computer, or
                  database connected to the Services.
                </li>
                <li>
                  Attack the Services via a denial-of-service attack or a
                  distributed denial-of-service attack.
                </li>
                <li>
                  Otherwise attempt to interfere with the proper working of the
                  Services.
                </li>
              </ul>
              <h2>User Contributions</h2>
              <p>
                The Services contain interactive features (collectively,
                “Interactive Services”) that allow Team Members to submit and
                transmit to other users content or materials (collectively,
                “User Contributions”) on or through the Services.
              </p>
              <p>
                All User Contributions must comply with the Content Standards
                (defined below) set out in these Terms of Use.
              </p>
              <p>
                Any User Contribution you submit will be considered
                non-confidential and non-proprietary. By providing any User
                Contribution on the Services, you grant us and our service
                providers, and each of their and our respective licensees,
                successors, and assigns the right to use, reproduce, modify,
                perform, display, distribute, and otherwise disclose to your
                Coach and when permitted by the Services your Team Members any
                such material, to use such material to provide the Services and
                to use such material to improve the Services.
              </p>
              <p className="list-header">You represent and warrant that:</p>
              <ul>
                <li>
                  You own or control all rights in and to the User Contributions
                  and have the right to grant the license granted above.
                </li>
                <li>
                  All of your User Contributions do and will comply with these
                  Terms of Use.
                </li>
              </ul>
              <p>
                You understand and acknowledge that you are responsible for any
                User Contributions you submit or contribute, and you, not the
                Company, have full responsibility for such content, including
                its legality, reliability, accuracy, and appropriateness.
              </p>
              <p>
                We are not responsible or liable to any third party for the
                content or accuracy of any User Contributions posted by you or
                any other user of the Services.
              </p>
              <h2>Monitoring and Enforcement; Termination</h2>
              <p className="list-header">We have the right to:</p>
              <ul>
                <li>
                  Remove or refuse to post any User Contributions for any or no
                  reason in our sole discretion.
                </li>
                <li>
                  Take any action with respect to any User Contribution that we
                  deem necessary or appropriate in our sole discretion,
                  including if we believe that such User Contribution violates
                  the Terms of Use, including the Content Standards, infringes
                  any intellectual property right or other right of any person
                  or entity, threatens the personal safety of users of the
                  Services or the public, or could create liability for the
                  Company.
                </li>
                <li>
                  Disclose your identity or other information about you to any
                  third party who claims that material posted by you violates
                  their rights, including their intellectual property rights or
                  their right to privacy.
                </li>
                <li>
                  Take appropriate legal action, including without limitation,
                  referral to law enforcement, for any illegal or unauthorized
                  use of the Services.
                </li>
                <li>
                  Terminate or suspend your access to all or part of the
                  Services for any violation of these Terms of Use.
                </li>
              </ul>
              <p>
                Without limiting the foregoing, we have the right to cooperate
                fully with any law enforcement authorities or court order
                requesting or directing us to disclose the identity or other
                information of anyone posting any materials on or through the
                Services. YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS
                LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM
                ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING, OR
                TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER SUCH PARTIES
                OR LAW ENFORCEMENT AUTHORITIES.
              </p>
              <p>
                However, we do not undertake to review material before it is
                posted on the Services, and cannot ensure prompt removal of
                objectionable material after it has been posted. Accordingly, we
                assume no liability for any action or inaction regarding
                transmissions, communications, or content provided by any user
                or third party. We have no liability or responsibility to anyone
                for performance or nonperformance of the activities described in
                this section.
              </p>
              <h2>Content Standards</h2>
              <p>
                These “<strong>Content Standards</strong>” apply to any and all
                User Contributions and use of Interactive Services. User
                Contributions must in their entirety comply with all applicable
                federal, state, local, and international laws and regulations.
                Without limiting the foregoing, User Contributions must not:
              </p>
              <ul>
                <li>
                  Contain any material that is defamatory, obscene, indecent,
                  abusive, offensive, harassing, violent, hateful, inflammatory,
                  or otherwise objectionable.
                </li>
                <li>
                  Promote sexually explicit or pornographic material, violence,
                  or discrimination based on race, sex, religion, nationality,
                  disability, sexual orientation, or age.
                </li>
                <li>
                  Infringe any patent, trademark, trade secret, copyright, or
                  other intellectual property or other rights of any other
                  person.
                </li>
                <li>
                  Violate the legal rights (including the rights of publicity
                  and privacy) of others or contain any material that could give
                  rise to any civil or criminal liability under applicable laws
                  or regulations or that otherwise may be in conflict with these
                  Terms of Use and our <a href="/privacy">Privacy Policy</a>. Be
                  likely to deceive any person.
                </li>
                <li>
                  Promote any illegal activity, or advocate, promote, or assist
                  any unlawful act.
                </li>
                <li>
                  Cause annoyance, inconvenience, or needless anxiety or be
                  likely to upset, embarrass, alarm, or annoy any other person.
                </li>
                <li>
                  Impersonate any person, or misrepresent your identity or
                  affiliation with any person or organization. Involve
                  commercial activities or sales, such as contests, sweepstakes,
                  and other sales promotions, barter, or advertising.
                </li>
                <li>
                  Give the impression that they emanate from or are endorsed by
                  us or any other person or entity, if this is not the case.
                </li>
              </ul>
              <h2>Reliance on Information Posted</h2>
              <p>
                The information presented on or through the Services is made
                available solely for general information purposes. We do not
                warrant the accuracy, completeness, or usefulness of this
                information. Any reliance you place on such information is
                strictly at your own risk. We disclaim all liability and
                responsibility arising from any reliance placed on such
                materials by you or any other visitor to the Services, or by
                anyone who may be informed of any of its contents.
              </p>
              <h2>Changes to the Services</h2>
              <p>
                We may update the content on this Services from time to time,
                but its content is not necessarily complete or up-to-date. Any
                of the material on the Services may be out of date at any given
                time, and we are under no obligation to update such material.
              </p>
              <h2>Information About You and Your Visits to the Services</h2>
              <p>
                All information we collect on this Services is subject to our
                <a href="/privacy">Privacy Policy</a>. By using the Services,
                you consent to all actions taken by us with respect to your
                information in compliance with the Privacy Policy.
              </p>
              <h2>Online Purchases and Other Terms and Conditions</h2>
              <p>
                All purchases through our site or other transactions for the
                sale of the Services are subject to the following terms. You
                agree that your order is an offer to buy, under these Terms of
                Use, those features of the Services available only to Coaches
                who pay for a subscription to the Services. All orders must be
                accepted by us, or we will not be obligated to provide the paid
                Services to you. We may choose not to accept orders at our sole
                discretion, even after we send you a confirmation email with
                your order number and details of the order. All subscriptions
                are for a one-year period, and will auto-renew at the end of
                such one-year period unless you cancel the subscription prior to
                such renewal.
              </p>
              <p>
                All prices, discounts, and promotions posted on the Services are
                subject to change without notice. The price charged for a
                subscription will be the price in effect at the time the order
                is placed and will be set out in your order confirmation email.
                Posted prices do not include taxes. All such taxes will be added
                to your total and will be itemized in your order confirmation
                email. We strive to display accurate price information, however,
                we may, on occasion, make inadvertent typographical errors,
                inaccuracies, or omissions related to pricing and availability.
                We reserve the right to correct any errors, inaccuracies, or
                omissions at any time and to cancel any orders arising from such
                occurrences.
              </p>
              <p>
                Terms of payment are within our sole discretion and payment must
                be received by us before we accept an order. We accept credit
                cards for all purchases. You represent and warrant that (i) the
                credit card information you supply to us is true, correct, and
                complete, (ii) you are duly authorized to use such credit card
                for the purchase, (iii) charges incurred by you will be honored
                by your credit card company, and (iv) you will pay charges
                incurred by you at the posted prices, including shipping and
                handling charges and all applicable taxes, if any, regardless of
                the amount quoted on the Services at the time of your order.
              </p>
              <p>
                A purchase of a subscription once accepted by us is a final sale
                and is non-returnable and non-refundable. However, in our sole
                discretion we may opt to issue a refund. In such case, refunds
                are processed within approximately three business days. Your
                refund will be credited back to the same payment method used to
                make the original purchase.
              </p>
              <h2>Geographic Restrictions</h2>
              <p>
                The owner of the Services is based in the Commonwealth of
                Virginia in the United States. We provide this Services for use
                only by persons located in the United States. We make no claims
                that the Services or any of its content is accessible or
                appropriate outside of the United States. Access to the Services
                may not be legal by certain persons or in certain countries. If
                you access the Services from outside the United States, you do
                so on your own initiative and are responsible for compliance
                with local laws.
              </p>
              <h2>Disclaimer of Warranties</h2>
              <p>
                You understand that we cannot and do not guarantee or warrant
                that files available for downloading from the internet or the
                Services will be free of viruses or other destructive code. You
                are responsible for implementing sufficient procedures and
                checkpoints to satisfy your particular requirements for
                anti-virus protection and accuracy of data input and output, and
                for maintaining a means external to our site for any
                reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED
                BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A
                DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER
                TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
                EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY
                MATERIAL DUE TO YOUR USE OF THE SERVICES OR ITS CONTENT OR TO
                YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE
                LINKED TO OR THROUGH IT.
              </p>
              <p>
                YOUR USE OF THE SERVICES, ITS CONTENT OR ITEMS OBTAINED THROUGH
                THE SERVICES ARE AT YOUR OWN RISK. THE SERVICES AND ITS CONTENT
                ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY
                WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE
                COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY
                WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
                SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE
                SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY
                NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS
                THAT THE SERVICES OR ITS CONTENT WILL BE ACCURATE, RELIABLE,
                ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED,
                THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
                VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE SERVICES WILL
                OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
              </p>
              <p>
                TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY
                DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
                IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO
                ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS
                FOR PARTICULAR PURPOSE.
              </p>
              <p>
                THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE
                EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
              </p>
              <h2>Limitation on Liability</h2>
              <p>
                TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE
                COLLECTIVE LIABILITY OF THE COMPANY AND ITS SUBSIDIARIES AND
                AFFILIATES, AND THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES,
                AGENTS, OFFICERS, AND DIRECTORS, TO ANY PARTY (REGARDLESS OF THE
                FORM OF ACTION, WHETHER IN CONTRACT, TORT, OR OTHERWISE) EXCEED
                THE GREATER OF $10 OR THE AMOUNT YOU HAVE PAID TO THE COMPANY
                FOR THE APPLICABLE SERVICE IN THE LAST TWELVE MONTHS OUT OF
                WHICH LIABILITY AROSE.
              </p>
              <p>
                The limitation of liability set out above does not apply to
                liability resulting from our gross negligence or willful
                misconduct.
              </p>
              <p>
                THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE
                EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
              </p>
              <h2>Indemnification</h2>
              <p>
                You agree to defend, indemnify, and hold harmless the Company,
                its affiliates, licensors, and service providers, and its and
                their respective officers, directors, employees, contractors,
                agents, licensors, suppliers, successors, and assigns from and
                against any claims, liabilities, damages, judgments, awards,
                losses, costs, expenses, or fees (including reasonable
                attorneys’ fees) arising out of or relating to your violation of
                these Terms of Use or your use of the Services, including, but
                not limited to, your User Contributions, any use of the
                Services’ content, services, and products other than as
                expressly authorized in these Terms of Use, or your use of any
                information obtained from the Services
              </p>
              <h2>Governing Law and Jurisdiction</h2>
              <p>
                All matters relating to the Services and these Terms of Use, and
                any dispute or claim arising therefrom or related thereto (in
                each case, including non-contractual disputes or claims), shall
                be governed by and construed in accordance with the internal
                laws of the Commonwealth of Virginia without giving effect to
                any choice or conflict of law provision or rule (whether of the
                Commonwealth of Virginia or any other jurisdiction).
              </p>
              <p>
                Any legal suit, action, or proceeding arising out of, or related
                to, these Terms of Use or the Services shall be instituted
                exclusively in the federal courts of the United States or the
                courts of the Commonwealth of Virginia, in each case located in
                Arlington, VA, although we retain the right to bring any suit,
                action, or proceeding against you for breach of these Terms of
                Use in your country of residence or any other relevant country.
                You waive any and all objections to the exercise of jurisdiction
                over you by such courts and to venue in such courts.
              </p>
              <h2>Arbitration</h2>
              <p>
                At Company’s sole discretion, it may require You to submit any
                disputes arising from these Terms of Use or use of the Services,
                including disputes arising from or concerning their
                interpretation, violation, invalidity, non-performance, or
                termination, to final and binding arbitration under the Rules of
                Arbitration of the American Arbitration Association applying the
                law of the Commonwealth of Virginia.
              </p>
              <h2>Limitation on Time to File Claims</h2>
              <p>
                ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR
                RELATING TO THESE TERMS OF USE OR THE SERVICES MUST BE COMMENCED
                WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES;
                OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
              </p>
              <h2>Waiver and Severability</h2>
              <p>
                No waiver by the Company of any term or condition set out in
                these Terms of Use shall be deemed a further or continuing
                waiver of such term or condition or a waiver of any other term
                or condition, and any failure of the Company to assert a right
                or provision under these Terms of Use shall not constitute a
                waiver of such right or provision.
              </p>
              <p>
                If any provision of these Terms of Use is held by a court or
                other tribunal of competent jurisdiction to be invalid, illegal,
                or unenforceable for any reason, such provision shall be
                eliminated or limited to the minimum extent such that the
                remaining provisions of the Terms of Use will continue in full
                force and effect.
              </p>
              <h2>Entire Agreement</h2>
              <p>
                The Terms of Use and our Privacy Policy constitute the sole and
                entire agreement between you and 6Tool LLC regarding the
                Services and supersede all prior and contemporaneous
                understandings, agreements, representations, and warranties,
                both written and oral, regarding the Services.
              </p>
              <h2>Your Comments and Concerns</h2>
              <p className="list-header">The Services are operated by:</p>
              <p className="list-header">6Tool LLC</p>
              <p className="list-header">PO Box 1577</p>
              <p>Middleberg, VA 20117.</p>
              <p className="list-header">
                All other feedback, comments, requests for technical support,
                and other communications relating to the Services should be
                directed to:
              </p>
              <p>
                <a href={`mailto:${EMAIL_ADDRESSES.INFO}`}>
                  {EMAIL_ADDRESSES.INFO}
                </a>
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Terms;
