import {
  CButton,
  CCol,
  CFormGroup,
  CInputCheckbox,
  CLabel,
  CListGroup,
  CListGroupItem,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { filter } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { createQuizAndInviteGroups } from '../../helpers/data';
import { db, functions } from '../../index';
import ReviewExamBody from '../ReviewExamBody/ReviewExamBody';
import LoadingMessage from '../LoadingMessage/LoadingMessage';
import { isFunction } from 'lodash';

function ConfirmModal({
  show,
  onClose,
  title,
  message,
  onClickCancel,
  onClickConfirm,
  buttons,
  cancelButtonText,
  okButtonText,
  cancelButtonColor,
  okButtonColor,
  submitting,
  submitMessage,
  children,
  size = 'lg',
  className,
  ...rest
}) {
  const isMounted = useRef(false);

  return (
    <CModal
      show={show}
      backdrop={true}
      scrollable={false}
      onClose={onClose}
      size={size}
      className={className}
    >
      <CModalHeader>
        <CModalTitle>{title}</CModalTitle>
      </CModalHeader>

      <CModalBody>
        {message}
        {children}
      </CModalBody>

      <CModalFooter>
        {submitting ? (
          <LoadingMessage firstMessage={submitMessage} />
        ) : (
          <>
            {isFunction(onClickCancel) && (
              <CButton
                color={cancelButtonColor || 'danger'}
                variant="outline"
                disabled={submitting}
                onClick={onClickCancel}
              >
                {cancelButtonText || 'Cancel'}
              </CButton>
            )}
            {_.isArray(buttons) && (
              <>
                {buttons.map((b, bIdx) => (
                  <CButton
                    key={bIdx}
                    color={b.color || 'primary'}
                    variant={b.variant || ''}
                    disabled={b.disabled}
                    className={b.className || ''}
                    onClick={b.onClick}
                  >
                    {b.text}
                  </CButton>
                ))}
              </>
            )}

            <CButton
              color={okButtonColor || 'primary'}
              disabled={submitting}
              className="mr-2"
              onClick={onClickConfirm}
            >
              {okButtonText || 'OK'}
            </CButton>
          </>
        )}
      </CModalFooter>
    </CModal>
  );
}

export default React.memo(ConfirmModal);
