import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Footer from '../Footer';
import { Redirect, useHistory, withRouter } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../..';
import AppNav from '../../../r-components/Nav/AppNav';
import { connect, useSelector } from 'react-redux';
import _ from 'lodash';
import { TABS } from '../../../AppTabs';

const OrphanLayout = ({ children, teamData }) => {
  const [user, loading, error] = useAuthState(auth);
  const userData = useSelector((state) => state?.UserState || {});
  const [shouldRedirect, setShouldRedirect] = useState(false);
  let history = useHistory();

  // useEffect(() => {
  //   const delayedRedirect = _.debounce(() => {
  //     if (!user && !loading) {
  //       setShouldRedirect(TABS.LOGIN.path);
  //     }
  //   }, 500);

  //   delayedRedirect();

  //   return () => {
  //     delayedRedirect.cancel();
  //   };
  // }, [user, loading]);

  // useEffect(() => {
  //   const delayedRedirect = _.debounce(() => {
  //     if (!loading && (_.isNil(teamData) || _.isEmpty(teamData))) {
  //       setShouldRedirect(TABS.COACH_TEAMS.path);
  //     }
  //   }, 500);

  //   delayedRedirect();

  //   return () => {
  //     delayedRedirect.cancel();
  //   };
  // }, [teamData]);

  if (!_.isNil(shouldRedirect) && _.isString(shouldRedirect)) {
    return <Redirect to={shouldRedirect} />;
  }
  return (
    <>
      <AppNav />
      {loading === true ? (
        <div className="page-loading-auth">Loading account...</div>
      ) : (
        <div className="main-content">{children}</div>
      )}
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.UserState,
    teamData: state.DataTeamState?.teamData || {},
    teams: state.DataTeamState?.data || [],
  };
};

OrphanLayout.propTypes = {
  children: PropTypes.node,
};

export default connect(mapStateToProps)(withRouter(OrphanLayout));
