import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import {
  FaCalendarDay,
  FaClipboardList,
  FaListOl,
  FaQuestion,
  FaUserAlt,
  FaUserFriends,
} from 'react-icons/fa';
import { connect, useSelector } from 'react-redux';
import { Link, withRouter, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash';
import AppNavDropdown from './AppNavDropdown';
import { ROUTE_ELEMENTS, TABS } from '../../AppTabs';
import {
  ACCESS_ACTIONS,
  ACCESS_LEVELS,
  canDoAction,
} from '../../helpers/access';
import { GiWhistle } from 'react-icons/gi';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../..';
import { SUBSCRIPTION_TIER } from '../../config';

toast.configure();

const AppNavMenu = ({ userData, teamLength, match }) => {
  const isEventPartner = useSelector(
    (state) => state?.DataSubscriptionState?.isEventPartner === true
  );
  const tier = useSelector(
    (state) => state?.DataSubscriptionState?.tier || SUBSCRIPTION_TIER.FREE.key
  );
  const [user, loading, error] = useAuthState(auth);
  const [menuItems, setMenuItems] = useState([]);
  const location = useLocation();
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    let mI = [];
    let path = match.path;
    if (_.isString(path) && path.length > 0 && path.charAt(0) === '/') {
      path = path.substring(1);
    }
    let pathArray = path.split('/');
    if (
      _.isArray(pathArray) &&
      pathArray.length > 0 &&
      pathArray[0] === ROUTE_ELEMENTS.HOME
    ) {
      let userType = [...(userData?.data?._roles || [])];
      if (isEventPartner === true) {
        // if (_.isArray(userType) && userType.length > 0) {
        //   try {
        //     userType.push(ACCESS_LEVELS.EVENT_PARTNER.key);
        //   } catch (error) {
        //     console.error(error);
        //   }
        // } else {
        userType = [ACCESS_LEVELS.EVENT_PARTNER.key];
        // }
      }
      if (_.isArray(userType) && userType.length > 0) {
        if (
          canDoAction({
            action: ACCESS_ACTIONS.VIEW_EVENT_PARTICIPANTS_TAB,
            type: userType,
            tier,
          }).canDo
        ) {
          mI.push({
            label: 'Participants',
            icon: <FaUserAlt />,
            to: TABS.EVENT_PARTICIPANTS.path,
          });
        }
        if (
          canDoAction({
            action: ACCESS_ACTIONS.VIEW_EVENT_TEAMS_TAB,
            type: userType,
            tier,
          }).canDo
        ) {
          mI.push({
            label: 'Teams',
            icon: <FaClipboardList />,
            to: TABS.EVENT_TEAMS.path,
          });
        }
        if (
          canDoAction({
            action: ACCESS_ACTIONS.VIEW_EVENT_EVENTS_TAB,
            type: userType,
            tier,
          }).canDo
        ) {
          mI.push({
            label: 'Events',
            icon: <FaCalendarDay />,
            to: TABS.EVENT_EVENTS.path,
          });
        }
        if (
          canDoAction({
            action: ACCESS_ACTIONS.VIEW_COACHES,
            type: userType,
            tier,
          }).canDo
        ) {
          mI.push({
            label: 'Coaches',
            icon: <GiWhistle />,
            to: TABS.OWNER_COACHES.path,
          });
        }
        if (
          canDoAction({
            action: ACCESS_ACTIONS.VIEW_PLAYERS,
            type: userType,
            tier,
          }).canDo
        ) {
          mI.push({
            label: 'Players',
            icon: <FaUserAlt />,
            to: TABS.COACH_PLAYERS.path,
          });
        }
        if (
          canDoAction({
            action: ACCESS_ACTIONS.VIEW_GROUPS,
            type: userType,
            tier,
          }).canDo
        ) {
          mI.push({
            label: 'Groups',
            icon: <FaUserFriends />,
            to: TABS.COACH_GROUPS.path,
          });
        }
        if (
          canDoAction({
            action: ACCESS_ACTIONS.VIEW_QUIZZES,
            type: userType,
          }).canDo
        ) {
          mI.push({
            label: 'Quizzes',
            icon: <FaListOl />,
            to: isEventPartner
              ? TABS.EVENT_QUIZZES.path
              : TABS.COACH_QUIZZES.path,
          });
        }
        if (
          canDoAction({
            action: ACCESS_ACTIONS.VIEW_6TOOL_QUESTIONS,
            type: userType,
            tier,
          }).canDo
        ) {
          mI.push({
            label: 'Questions',
            icon: <FaQuestion />,
            to: TABS.COACH_QUESTIONS.path,
          });
        }
      }
    } else {
      mI.push({ label: 'Team', to: TABS.TEAM.path });
      mI.push({ label: 'Why 6Tool', to: TABS.WHY.path });
      mI.push({ label: 'Pricing', to: TABS.PRICING.path });
      mI.push({ label: 'FAQ', to: TABS.FAQ.path });
      if (!!user && userData?.data?.orphan !== true) {
        mI.push({ label: 'My Account', to: TABS.COACH_APP_HOME.path });
      }
    }
    if (isMounted.current) {
      setMenuItems(mI);
    }

    return () => {
      isMounted.current = false;
    };
  }, [userData, match, user, isEventPartner]);

  // const menuItems = [
  //   {
  //     label: 'Players',
  //     icon: <FaUserAlt />,
  //     to: TABS.COACH_PLAYERS.path,
  //   },
  //   {
  //     label: 'Groups',
  //     icon: <FaUserFriends />,
  //     to: TABS.COACH_GROUPS.path,
  //   },
  //   {
  //     label: 'Quizzes',
  //     icon: <FaListOl />,
  //     to: TABS.COACH_QUIZZES.path,
  //   },
  //   {
  //     label: 'Questions',
  //     icon: <FaQuestion />,
  //     to: TABS.COACH_QUESTIONS.path,
  //   },
  // ];

  return (
    <>
      <button
        type="button"
        className="navbar-toggler btn u-hamburger u-header__hamburger collapsed"
        aria-label="Toggle navigation"
        aria-expanded="false"
        aria-controls="navBar"
        data-toggle="collapse"
        data-target="#navBar"
      >
        <span id="hamburgerTrigger" className="u-hamburger__box">
          <span className="u-hamburger__inner"></span>
        </span>
      </button>

      <div
        id="navBar"
        className="navbar-collapse u-header__navbar-collapse py-0 collapse"
      >
        <ul className="navbar-nav u-header__navbar-nav mr-3">
          {menuItems.map((item, index) => {
            if (
              item.hidden === true ||
              (!_.isUndefined(item.show) && item.show === false)
            ) {
              return null;
            }
            return (
              <React.Fragment key={`${item.label}-${index}-main-menu`}>
                <li
                  className={`nav-item u-header__nav-item-btn ${
                    index >= menuItems.length - 1 ? 'mr-3' : ''
                  }${location.pathname.startsWith(item.to) ? ' active' : ''}`}
                >
                  <Link
                    className={`u-header__nav-link navtext ${
                      location.pathname.startsWith(item.to) ? 'active' : ''
                    }`}
                    to={item.to}
                    aria-haspopup="true"
                    aria-expanded="false"
                    aria-labelledby="docsSubMenu"
                  >
                    {item.icon}
                    {item.label}
                  </Link>
                </li>
              </React.Fragment>
            );
          })}
          {!!user ? (
            <AppNavDropdown userData={userData} />
          ) : (
            <>
              <li className="nav-item u-header__nav-item-btn">
                <a
                  className="btn btn-outline-primary mx-1 py-2"
                  href={TABS.SIGNUP_CREATE_ACCOUNT.path}
                  role="button"
                >
                  Sign Up
                </a>
                <a
                  className="btn btn-primary mx-1 py-2"
                  href={TABS.LOGIN.path}
                  role="button"
                >
                  Log In
                </a>
              </li>
            </>
          )}
        </ul>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    teamLength:
      state.DataTeamState.teamData && state.DataTeamState.teamData.length,
    userData: state.UserState,
  };
};

const mapDispatchToProps = {};

AppNavMenu.propTypes = {
  userData: PropTypes.shape({
    // errorStatus: PropTypes.string,
    // profileData: PropTypes.shape({
    //   email: PropTypes.string,
    //   firstName: PropTypes.string,
    //   lastName: PropTypes.string,
    //   phone: PropTypes.string,
    // }),
    // profilePassErr: PropTypes.string,
    // status: PropTypes.number,
  }),
  teamLength: PropTypes.number,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppNavMenu));
