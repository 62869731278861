// hooks/useAppSelectors.js
import { shallowEqual, useSelector } from 'react-redux';
import { useSubscriptionModal } from '../r-components/SubscriptionModal/SubscriptionModal';
import { useHistory } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import {
  mapValues,
  omit,
  pickBy,
  startsWith,
  max,
  map,
  reduce,
  mergeWith,
} from 'lodash';
import { ACCESS_ACL, ACCESS_ACTIONS } from '../helpers/access';
import { paymentTermAndTierForPrice, SUBSCRIPTION_TIER } from '../config';

const useAccountSelectors = () => {
  const { showSubscriptionModal } = useSubscriptionModal();
  const history = useHistory();

  const goToUrl = (url) => {
    history.push(url);
  };

  const organizationId = useSelector(
    (state) => state.DataOrganizationState?.data?._id || 'a'
  );
  const type = useSelector(
    (state) => state.UserState?.access?.[organizationId]?._roles || []
  );
  const tier = useSelector(
    (state) => state.DataSubscriptionState?.tier || SUBSCRIPTION_TIER.FREE.key
  );

  const ownerName = useSelector(
    (state) => state.DataOwnerState?.data?.[0]?.name || ''
  );
  const ownerEmail = useSelector(
    (state) =>
      state.DataOwnerState?.data?.[0]?.emailLower ||
      state.DataOwnerState?.data?.[0]?.email ||
      ''
  );

  const customer_id = useSelector(
    (state) => state.DataSubscriptionState?.customer_id
  );
  const subscription_id = useSelector(
    (state) => state.DataSubscriptionState?.current?.id
  );
  const subscription_quantity = useSelector(
    (state) => state.DataSubscriptionState?.current?.quantity || 1
  );
  const subscription_item_id = useSelector(
    (state) => state.DataSubscriptionState?.current?.items?.[0]?.id
  );

  const { paymentTerm: subscription_term } = useSelector((state) =>
    paymentTermAndTierForPrice(state.DataSubscriptionState?.price_id)
  );

  const teamMap = useSelector(
    (state) => state.DataTeamState?.map || [],
    shallowEqual
  );
  const teamCounts = useMemo(() => {
    return mapValues(teamMap, (t) =>
      pickBy(t, (v, k) => startsWith(k, 'count') || startsWith(k, 'max'))
    );
  }, [teamMap]);

  const orgCounts = useMemo(() => {
    let totalCoaches = 0;
    let totalGroups = 0;
    let totalPlayers = 0;
    const result = reduce(
      teamCounts,
      (result, obj) => {
        totalCoaches += obj.countCoaches || 0;
        totalGroups += obj.countGroups || 0;
        totalPlayers += obj.countPlayers || 0;
        return mergeWith(result, obj, (resultValue, srcValue, key) => {
          if (key.startsWith('maxxed')) {
            return resultValue || srcValue;
          } else {
            return Math.max(resultValue || 0, srcValue);
          }
        });
      },
      {}
    );
    result.totalCoaches = totalCoaches;
    result.totalGroups = totalGroups;
    result.totalPlayers = totalPlayers;
    return result;
  }, [teamCounts]);

  const numTeams = useSelector(
    (state) => (state.DataTeamState?.data || []).length
  );
  const numScheduledQuizzes = useSelector(
    (state) => state.DataSubscriptionState?.numScheduledQuizzes || 0
  );
  const numQuizzesThisThreshold = useSelector(
    (state) => state.DataSubscriptionState?.numQuizzesThisThreshold || 0
  );
  const numAutoQuizzesThisThreshold = useSelector(
    (state) => state.DataSubscriptionState?.numAutoQuizzesThisThreshold || 0
  );

  return {
    organizationId,
    type,
    tier,
    showSubscriptionModal,
    goToUrl,
    teamCounts,
    orgCounts,
    customer_id,
    subscription_id,
    subscription_quantity,
    subscription_item_id,
    subscription_term,
    numTeams,
    numScheduledQuizzes,
    numQuizzesThisThreshold,
    numAutoQuizzesThisThreshold,
    ownerName,
    ownerEmail,
  };
};

export default useAccountSelectors;
