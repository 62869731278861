import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
} from '@coreui/react';
import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import { FiEdit3 } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { editPlayer } from '../../../actions/playerActionCreator';
import { db } from '../../../index';
import { Loading } from '../../../r-reusable';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { TABS } from '../../../AppTabs';
import DoneButton from '../../../r-components/DoneButton/DoneButton';
import PlayerWrapper from '../../../pages/component/PlayerWrapper';
import { ACCESS_ACTIONS, canDoAction } from '../../../helpers/access';
import CoachRoles from '../../../r-components/CoachRoles/CoachRoles';
import HeaderWithBackButton from '../../../r-components/HeaderWithBackButton/HeaderWithBackButton';
import { handleError } from '../../../helpers/errors';

const CoachDetails = ({ match }) => {
  const dispatch = useDispatch();
  const [userId, setUserId] = useState('a');
  const isMounted = useRef(null);
  const [coach, setCoach] = useState({});
  const [access, setAccess] = useState({});
  const [loading, setLoading] = useState(true);
  const [userCanEditCoaches, setUserCanEditCoaches] = useState(false);
  const user = useSelector((state) => state.UserState);
  const organizationId = useSelector(
    (state) => state.DataOrganizationState?.data?.id || ''
  );
  const teamMap = useSelector((state) => state.DataTeamState?.map || {});
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    setUserId(match.params.id || 'a');
  }, [match]);

  useEffect(() => {
    setTeams(
      (
        Object.entries((coach?.org_team || {})[organizationId] || {}) || []
      ).filter(([teamId, teamVal]) => Object.keys(teamMap).includes(teamId))
    );
  }, [teamMap, coach]);

  useEffect(() => {
    const loadData = async () => {
      try {
        if (userId.length > 1) {
          const groups = [];
          const groupsPromise = [];
          isMounted.current = true;
          setLoading(true);

          const userRef = doc(db, 'users', userId);
          const userResponse = await getDoc(userRef);
          const userData = userResponse.data();

          if (isMounted.current) {
            setLoading(false);
            setCoach({
              ...userData,
              id: userId,
            });
          }
        }
      } catch (e) {
        handleError({
          error: e,
          internal: 'CoachDetails - setting coach details',
        });

        if (isMounted.current) {
          setLoading(false);
        }
      }
    };

    loadData();

    return () => {
      isMounted.current = false;
    };
  }, [userId]);

  useEffect(() => {
    setUserCanEditCoaches(
      canDoAction({
        action: ACCESS_ACTIONS.CREATE_COACH,
        type: user?.data?._roles,
      }).canDo
    );
  }, [user]);

  return (
    <PlayerWrapper>
      <CRow>
        <CCol xs={12}>
          <CCard>
            <HeaderWithBackButton>
              <div className="d-flex justify-content-between">
                <span className="mr-auto">Coach Detail</span>
                {userCanEditCoaches && (
                  <span className="text-right">
                    <Link
                      to={TABS.OWNER_COACHES_EDIT.pathVar(
                        _.pick(coach, ['id', 'name'])
                      )}
                    >
                      <FiEdit3 /> Edit
                    </Link>
                  </span>
                )}
              </div>
            </HeaderWithBackButton>

            <CCardBody>
              {loading ? (
                <Loading />
              ) : (
                <div className="bd-coach">
                  <dl className="row mb-2">
                    <dt className="col-sm-3">Name:</dt>
                    <dd className="col-sm-9">{coach.name}</dd>
                  </dl>

                  <dl className="row mb-2">
                    <dt className="col-sm-3">Email:</dt>
                    <dd className="col-sm-9">{coach.email}</dd>
                  </dl>

                  <dl className="row mb-2">
                    <dt className="col-sm-3">{`Team${
                      teams.length === 1 ? '' : 's'
                    }:`}</dt>
                    <dd className="col-sm-9">
                      {teams.map(([teamId, teamVal], index) => (
                        <div
                          key={`coach-team-list-${teamId}-${index}`}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 4,
                          }}
                        >
                          {((teamMap[teamId] || {}).imageUrl || '').length >
                            0 && (
                            <img
                              src={(teamMap[teamId] || {}).imageUrl || ''}
                              style={{
                                height: 20,
                                width: 20,
                                objectFit: 'contain',
                                marginTop: -2,
                              }}
                            />
                          )}
                          {(teamMap[teamId] || {}).name || ''}
                          {' - '}
                          <CoachRoles type={teamVal.roles} text={true} />
                        </div>
                      ))}
                    </dd>
                  </dl>
                </div>
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <DoneButton />
    </PlayerWrapper>
  );
};

export default CoachDetails;
