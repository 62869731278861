import React from 'react';
import './MobileMockup.css';

const MobileMockup = ({ content = null } = {}) => (
  <div className="mobile-phone">
    <div className="brove">
      <span className="speaker"></span>
    </div>
    <div className="screen">{content}</div>
  </div>
);

export default MobileMockup;
