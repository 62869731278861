// User constants
export const SET_CURRENT_USER = 'USER/SET_CURRENT_USER';

export const SET_USER_TEAMS = 'USER/SET_USER_TEAMS';
export const UPDATE_USER_SELECTED_TEAM = 'USER/UPDATE_USER_SELECTED_TEAM';
export const SET_USER_SELECTED_TEAM = 'USER/SET_USER_SELECTED_TEAM';
export const SET_SINGLE_TEAM = 'USER/SET_SINGLE_TEAM';

export const SET_PLAYERS = 'USER/SET_PLAYERS';
export const UPDATE_PLAYERS = 'USER/UPDATE_PLAYERS';

export const CLEAR_USER = 'USER/CLEAR_USER';
