export const teamMembers = [
  {
    name: 'Kenny Wood',
    role: 'President',
    imageUrl: 'img/aboutus/kenny-wood.png',
    shortBio: '',
    longBio:
      'Kenneth, a 6Tool co-founder, has over 20 years of experience working in the athletics and sporting goods fields.  He is a member of the University of Richmond Hall of Fame and played seven years of professional basketball in six different countries. Kenneth has coached youth sports and his son, James, was a 2nd Round Draft pick and currently plays for the Harrisburg Senators, the AA affiliate of the Washing Nationals',
    actionImageUrl: 'img/aboutus/kenny-wood-action.png',
  },
  {
    name: 'John Rosen',
    role: 'Chief Sales Officer',
    imageUrl: 'img/aboutus/john-rosen.png',
    shortBio: '',
    longBio:
      'John is a co-founder and currently the Chief Sales Officer for 6Tool. John has had a long and successful career as an Enterprise Sales Executive. John played baseball in college at Assumption College and now has two sons who play in college, having just experienced the recruiting process with them first-hand.',
    actionImageUrl: 'img/aboutus/john-rosen-action.png',
  },
  {
    name: 'Tim Sutherland',
    role: 'Chairman',
    imageUrl: 'img/aboutus/tim-sutherland.png',
    longBio: '',
    actionImageUrl: 'img/aboutus/tim-sutherland.png',
  },
  {
    name: 'Chris Hayes',
    role: 'Chief Technology Officer',
    imageUrl: 'img/aboutus/chris-hayes.png',
    longBio:
      "Chris played 10 seasons of professional baseball mostly with the Kansas City Royals, reaching AAA. He founded the largest online organization of current and former pro baseball players and is currently the CTO and lead developer at 6Tool. In 2012, Chris founded a video social media app called flik and since has run a digital agency that consults for mid-size companies in software development. Chris has an undergraduate degree from Northwestern in Computer Science and Economics and has a Master's degree from Harvard University in Psychology.",
    actionImageUrl: 'img/aboutus/chris-hayes-action.png',
  },
  {
    name: 'David Sutherland',
    role: 'Chief Financial Officer',
    imageUrl: 'img/aboutus/david-sutherland.png',
    longBio:
      'David is co-founder and President of Middleburg Capital Development, a family investment office located in Middleburg, VA.  He has served on the Board of Advisors for the Gigot Center of Entrepreneurial Studies and the Graduate Alumni Board of the Mendoza College of Business, both at the University of Notre Dame and spent five years on the Board of Directors for the IrishAngels, a Notre Dame inspired angel group dedicated to furthering start up growth through early stage investment. David received his BBA and MBA from the Unversity of Notre Dame.',
    actionImageUrl: 'img/aboutus/david-sutherland-action.png',
  },
  {
    name: 'Christopher Beaufils',
    role: 'Chief Operations Officer',
    imageUrl: 'img/aboutus/christopher-beaufils.png',
    longBio:
      'Christopher is a DevSecOps engineer specializing in Cloud Infrastructure. His passion for sports & innovation led him to develop an early iteration of 6Tool through his Master’s thesis. Christopher has an undergraduate degree in Computer Science & Engineering and a Master’s Degree in Entrepreneurship and Innovation, both from the University of Notre Dame.',
    actionImageUrl: 'img/aboutus/christopher-beaufils.png',
  },
  // {
  //   name: 'Jeff Bianchi',
  //   role: 'Director of Content',
  //   imageUrl: 'img/aboutus/jeff-bianchi.png',
  //   longBio:
  //     'Jeff Bianchi, founder of Bianchi Baseball Academy, a baseball and life skills training organization, graduated high school in 2005 a state champion and a 2nd round draft pick of the Kansas City Royals. Jeff played 12 successful seasons in professional baseball including 2 ½ years at the Major League level with the Milwaukee Brewers and Boston Red Sox, in addition to being a silver medalist for Team USA.',
  //   actionImageUrl: 'img/aboutus/jeff-bianchi-action.png',
  // },
  {
    name: 'Jason Aspito',
    role: 'Director of Travel Baseball Relations',
    imageUrl: 'img/aboutus/jason-aspito.png',
    longBio:
      "Jason was drafted by the Chicago White Sox in the 45th round of the 1997 draft, but opted to attend the University of Texas where he was named MVP of the 1998 NBC World Series. Jason was drafted by the White Sox in the 9th round in 2000 and later traded to the Angels in 2003.   He was named to the Texas League All Star team in 2006 and then played for the Angles at the Triple A level in Salt Lake City.   A prospect in the Chicago White Sox and Los Angeles Angels organizations, Jason arrived in York during the York Revolution's inaugural season. Aspito hit a combined 42 homers in his 2 1/2 seasons with the York Revolution. Jason is the former head coach of the Penn State York baseball team and has recently been named an associate scout for the Milwaukee Brewers.   He is also the Head Coach of the 13 and under Backyard University Gamers.",
    actionImageUrl: 'img/aboutus/jason-aspito-action.png',
  },
  {
    name: 'Collin Radack',
    role: 'Director of College Baseball Relations',
    imageUrl: 'img/aboutus/collin-radack.png',
    longBio:
      'Radack has spent 7 seasons as a college coach, and is currently a Division I hitting coach for the Richmond Spiders. Radack has led the Spiders to regularly be one of the top offenses in the Atlantic 10 Conference. An All-American outfielder, Radack was drafted in the 20th Round of the 2014 MLB Draft by the St. Louis Cardinals. He played in 203 games during his minor league career before retiring to begin coaching. Over his three-year playing career, Radack advanced as high as Double A, playing with the Springfield Cardinals in the Texas League. He finished his professional career with a .279 average with 33 extra-base hits including five home runs.',
    actionImageUrl: 'img/aboutus/collin-radack-action.png',
  },
  {
    name: 'Tyler Goodro',
    role: 'Director of Content',
    imageUrl: 'img/aboutus/tyler-goodro.png',
    longBio:
      'For over 13+ years Tyler has instructed, coached and scouted thousands of youth, high school and collegiate baseball players. He spent over five seasons playing Minor League Baseball with the LA Dodgers and various independent Minor League ball clubs. He currently serves as an Area Associate Scout for the Philladelphia Phillies organization covering Eastern Nebraska, and Western Iowa. Tyler played college ball at University of Cincinnati. During his time at Cincinnati, the team set regular season records for wins, team batting average, and home runs  - eventually finishing Runners-up in both the Big East regular season and conference tournament. Tyler would go on to play baseball professionally for five years, having had the opportunity to suit up for two managers that won World Series. He also had the chance to catch many pitchers that have made it to the Big Leagues; most notably Jordan Zimmerman (Detroit Tigers), and Clayton Kershaw (LA Dodgers).',
    actionImageUrl: 'img/aboutus/tyler-goodro-action.png',
  },
];
