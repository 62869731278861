import {
  CButton,
  CButtonGroup,
  CInput,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react';
import _, { isFunction, isString, isObject } from 'lodash';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { ACCESS_ACL, ACCESS_ACTIONS } from '../../helpers/access';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { STRIPE } from '../../config';
import './SubscriptionModal.scss';
import useAccountSelectors from '../../hooks/useAccountSelectors';
import PricingTable from '../PricingTable/PricingTable';

const SubscriptionModalContext = createContext();

export const useSubscriptionModal = () => {
  return useContext(SubscriptionModalContext);
};

function SubscriptionModalProvider({ children }) {
  const [show, setShow] = useState(false);
  const [actions, setActions] = useState([]);
  const [portalLinks, setPortalLinks] = useState({});
  const [
    loadingSubscriptionLinkForTierTerm,
    setLoadingSubscriptionLinkForTierTerm,
  ] = useState(null);

  const [userEmail, setUserEmail] = useState('');
  const [showRedirect, setShowRedirect] = useState(false);
  const [enableAllTiers, setEnableAllTiers] = useState(false);
  const [redirectCountdown, setRedirectCountdown] = useState(-1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!showRedirect) return;

    const timer = setInterval(() => {
      setRedirectCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(timer);
          if (prevCountdown === 1) {
            handleRedirect();
          }
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [showRedirect]);

  useEffect(() => {
    if (show !== true) {
      return;
    }
    setLoading(true);

    const timer = setInterval(() => {
      setLoading(false);
    }, 2000);

    return () => clearInterval(timer);
  }, [show]);

  const showSubscriptionModal = (actions) => {
    setLoading(true);
    setActions(actions);
    setShow(true);
  };

  const hideSubscriptionModal = () => {
    setActions([]);
    setPortalLinks({});
    setLoadingSubscriptionLinkForTierTerm(null);
    setShow(false);
    setLoading(false);
  };

  const showGoingExternalToStripeModal = (userEmail) => {
    setRedirectCountdown(10);
    setUserEmail(userEmail);
    setShow(false);
    setActions([]);
    setShowRedirect(true);
  };

  const handleHideGoingToExternalStripeModal = () => {
    setShowRedirect(false);
    setRedirectCountdown(-1);
    // setAction(null);
    // setShowRedirect(true);
  };

  const handleRedirect = () => {
    window.location.href = `${STRIPE.customer_portal_link}${
      isString(userEmail) && userEmail.length > 0
        ? `?prefilled_email=${encodeURIComponent(userEmail)}`
        : ''
    }`;
  };

  return (
    <SubscriptionModalContext.Provider
      value={{
        showSubscriptionModal,
        hideSubscriptionModal,
        showGoingExternalToStripeModal,
      }}
    >
      {children}
      <SubscriptionModal
        show={show && !showRedirect}
        actions={actions}
        loading={loading}
        enableAllTiers={enableAllTiers}
        portalLinks={portalLinks}
        setPortalLinks={setPortalLinks}
        loadingSubscriptionLinkForTierTerm={loadingSubscriptionLinkForTierTerm}
        setLoadingSubscriptionLinkForTierTerm={
          setLoadingSubscriptionLinkForTierTerm
        }
      />
      <ConfirmModal
        show={showRedirect}
        title="Redirecting to Customer Portal"
        message={`You will be redirected to a secure customer portal to manage your subscription. For your security, you may be asked to verify you the subscription owner's email. You will be redirected in ${redirectCountdown} second${
          redirectCountdown === 1 ? '' : 's'
        } or click 'Proceed' below to go there now.`}
        okButtonColor="success"
        cancelButtonColor="primary"
        cancelButtonText="Stay Here"
        okButtonText="Proceed"
        onClickCancel={handleHideGoingToExternalStripeModal}
        onClose={handleHideGoingToExternalStripeModal}
        onClickConfirm={handleRedirect}
      />
    </SubscriptionModalContext.Provider>
  );
}

function SubscriptionModal({
  show,
  actions,
  loading,
  enableAllTiers,
  loadingSubscriptionLinkForTierTerm,
  setLoadingSubscriptionLinkForTierTerm,
}) {
  const { hideSubscriptionModal, showGoingExternalToStripeModal } =
    useSubscriptionModal();
  const { tier } = useAccountSelectors();

  const isRedirecting =
    isObject(loadingSubscriptionLinkForTierTerm) &&
    isString(loadingSubscriptionLinkForTierTerm.tier) &&
    isString(loadingSubscriptionLinkForTierTerm.term);

  return (
    <CModal
      className="subscription-modal"
      show={show}
      backdrop={true}
      scrollable={false}
      onClose={hideSubscriptionModal}
      size={'xl'}
    >
      <CModalHeader>
        <CModalTitle>Upgrade your Subscription</CModalTitle>
      </CModalHeader>

      <CModalBody>
        <div style={{ fontSize: 16, marginBottom: 30 }}>
          {ACCESS_ACL[tier][actions?.[0]?.action || 'empty']?.deniedReason ||
            'You are unable to do this on your current subscription level. Upgrade your subscription first.'}
        </div>
        <PricingTable
          loading={loading}
          actions={actions}
          loadingSubscriptionLinkForTierTerm={
            loadingSubscriptionLinkForTierTerm
          }
          setLoadingSubscriptionLinkForTierTerm={
            setLoadingSubscriptionLinkForTierTerm
          }
          enableAllTiers={enableAllTiers}
        />
      </CModalBody>

      <CModalFooter>
        {isFunction(hideSubscriptionModal) && (
          <CButton
            color="primary"
            variant="outline"
            onClick={hideSubscriptionModal}
            style={{
              opacity: isRedirecting ? 0 : 1,
            }}
            disabled={isRedirecting}
          >
            Close
          </CButton>
        )}
      </CModalFooter>
    </CModal>
  );
}

export { SubscriptionModalProvider, SubscriptionModal };
