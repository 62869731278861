import { CSpinner } from '@coreui/react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { db } from '../../../index';
import PieChart from '../../../r-components/PieChart/PieChart';

const QuizUserResponse = ({ quizId, examId, average, numTaken }) => {
  const isMounted = useRef(false);
  const [loading, setLoading] = useState(false);
  // const [userTotal, setUserTotal] = useState([]);
  const invitedUsers = useSelector(
    (state) => state.DataExamState?.inviteMap?.[quizId]?.users || []
  );
  const takenUsers = useSelector(
    (state) => state.DataExamState?.takenMap?.[quizId]?.users || []
  );

  // useEffect(() => {
  //   isMounted.current = true;

  //   const loadData = async () => {
  //     setLoading(true);
  //     const usersTotal = [];

  //     const q = query(
  //       collection(db, 'exam_invites'),
  //       where('exam_id', '==', examId)
  //     );
  //     const userTotalResponse = await getDocs(q);

  //     userTotalResponse.forEach((examInvite) => {
  //       const data = examInvite.data();
  //       const inviteAdded = usersTotal.find(
  //         ({ user_id, exam_id }) =>
  //           user_id === data.user_id && exam_id === data.exam_id
  //       );

  //       if (!inviteAdded) {
  //         usersTotal.push({ id: examInvite.id, ...examInvite.data() });
  //       }
  //     });

  //     if (isMounted.current) {
  //       setLoading(false);
  //       setUserTotal(usersTotal);
  //     }
  //   };

  //   if (examId) {
  //     loadData();
  //   }

  //   return () => {
  //     isMounted.current = false;
  //   };
  // }, [examId]);

  return (
    <>
      {loading ? (
        <CSpinner variant="grow" size="sm" />
      ) : (
        <>
          <div style={{ display: 'flex' }}>
            <div>
              {`${(takenUsers || []).length}/${
                (invitedUsers || []).length
              } invited player${
                invitedUsers.length == 1 ? ' has' : 's have'
              } taken
            this quiz`}
            </div>
            {!isNaN(takenUsers.length) && takenUsers.length > 0 && (
              <PieChart size={24} percent={average} style={{ marginLeft: 6 }} />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default QuizUserResponse;
