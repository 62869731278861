import { CCardHeader, CLink } from '@coreui/react';
import React from 'react';
import { FaAngleLeft } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import './HeaderWithBackButton.scss';

const HeaderWithBackButton = ({
  headerStyle = {},
  className = '',
  style = {},
  children,
  rightSide = null,
  ...props
}) => {
  const history = useHistory();

  const backButton = () => (
    <CLink
      style={{
        alignSelf: 'flex-start',
        color: '#162630',
        fontWeight: 'bolder',
        fontSize: '0.8em',
        display: 'flex',
        alignItems: 'center',
        lineHeight: '0.8em',
        marginLeft: -3,
      }}
      onClick={() => {
        history.goBack();
      }}
    >
      <FaAngleLeft style={{ marginTop: -2 }} />
      Back
    </CLink>
  );
  return (
    <CCardHeader
      {...props}
      style={{
        headerStyle,
        ...{
          display: 'flex',
          flexDirection: 'column',
          gap: 6,
        },
      }}
    >
      {rightSide ? (
        <div className="headerWrapper-left-and-right">
          <div className="headerWrapper-left">
            {backButton()}
            {children && (
              <div className={className} style={style}>
                {children}
              </div>
            )}
          </div>
          <div className="headerWrapper-right">{rightSide}</div>
        </div>
      ) : (
        <>
          {backButton()}
          <div className={className} style={style}>
            {children}
          </div>
        </>
      )}
    </CCardHeader>
  );
};

export default HeaderWithBackButton;
