import React, { Component } from 'react';
import { EMAIL_ADDRESSES } from '../config';

class Contact extends Component {
  render() {
    return (
      <>
        <div className="container-fluid space-1">
          <div className="w-md-80 w-lg-60 text-center mx-md-auto mb-5">
            <h2 className="font-weight-black">Contact Us</h2>
            <br />
            <div className="text-uppercase">For Sales and Support:</div>
            <h2>
              <a href={`mailto:${EMAIL_ADDRESSES.SUPPORT}`}>
                {EMAIL_ADDRESSES.SUPPORT}
              </a>
              <br />
              <a href="tel:18884016411">1 (888) 401-6411</a>
              <br />
            </h2>
            <br />
            <div className="text-uppercase">Mailing Address:</div>
            <p>
              6Tool
              <br />
              PO Box 1601
              <br />
              Olney, MD 20830
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default Contact;
