import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CInputCheckbox,
  CLabel,
  CListGroup,
  CListGroupItem,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSpinner,
} from '@coreui/react';
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { Field, FieldArray, Form, Formik, useFormik } from 'formik';
import { isArray } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { viewGroups } from '../../../../actions/groupActionCreator';
import { db, functions } from '../../../../index';
import GroupWrapper from '../../../../pages/component/GroupWrapper';
import HeaderWithBackButton from '../../../../r-components/HeaderWithBackButton/HeaderWithBackButton';
import { handleError } from '../../../../helpers/errors';
import SelectUsers from '../edit/SelectUsers';
import LoadingMessage from '../../../../r-components/LoadingMessage/LoadingMessage';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { Button } from 'react-bootstrap';
import { EMAIL_ADDRESSES } from '../../../../config';

const AddGroup = () => {
  const history = useHistory();
  const isMounted = useRef(false);
  const user = useSelector((state) => state.UserState);
  const [existingModalIsVisible, setAddExistingUsersIsVisible] =
    useState(false);
  const [newUserModalIsVisible, setAddNewUsersIsVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const teamData = useSelector((state) => state.DataTeamState?.teamData || {});
  const players = useSelector((state) => state.DataPlayerState?.data || []);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [usersToSelectFrom, setUsersToSelectFrom] = useState([]);
  const formik = useFormik({
    initialValues: {
      title: '',
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required(),
    }),
  });

  useEffect(() => {
    let _usersToSelectFrom = [...(players || [])];
    let _selected = [];
    if (_.isArray(usersToSelectFrom) && usersToSelectFrom.length > 0) {
      _selected = usersToSelectFrom
        .filter((obj) => obj.isChecked)
        .map((obj) => obj.id);
    }
    setUsersToSelectFrom(
      players.map((p) => {
        return { ...p, isChecked: _selected.includes(p.id) };
      })
    );
  }, [players]);

  const createGroup = async () => {
    setSubmitting(true);
    const { title } = formik.values;

    const organizationId = user.organizationId || 'a';

    try {
      const group = await addDoc(collection(db, 'groups'), {
        title,
        players: [],
        coaches: [],
        default: false,
        organizationId,
        teamId: teamData?._id || 'all',
        created_at: serverTimestamp(),
      });

      await updateDoc(doc(db, 'organization', organizationId), {
        groups: arrayUnion(group.id),
      });

      if (_.isArray(users) && users.length > 0) {
        let addUserIDsToGroupCallableResult = await functions.httpsCallable(
          'addUserIDsToGroupCallable'
        )({
          user_ids: users.map((u) => u._id),
          team_id: teamData?._id,
          organization_id: organizationId,
          group_id: group.id,
        });
        if (addUserIDsToGroupCallableResult.data !== true) {
          handleError({
            error: `Players were not added to the group. If this persists, contact ${EMAIL_ADDRESSES.SUPPORT}`,
            internal: 'AddGroup - addUserIDsToGroupCallable',
            toast: `Players were not added to the group. If this persists, contact ${EMAIL_ADDRESSES.SUPPORT}`,
          });
        }
      }
      setSubmitting(false);
      toast(
        `Group created.${
          _.isArray(users) && users.length > 0
            ? ` Players being added to group in the background. This may take a minute. You can continue to use the app normally.`
            : ''
        }`,
        { type: 'success' }
      );
      history.goBack();
    } catch (e) {
      handleError({
        error: e,
        internal: 'AddGroup - createGroup',
      });
      setSubmitting(false);
    }
  };

  const closedSelectingUsersModal = (newUsers) => {
    // setExistingUsers(newUsers);
    const newSelectedUsers = [];
    const groupUsers = [...users];
    const selectedUsers = [...newUsers].filter((user) => user.isChecked);
    const removedUsers = [...newUsers].filter((user) => !user.isChecked);
    const usersIds = users.map((user) => user.id);

    selectedUsers.forEach((selectedUser) => {
      if (!usersIds.includes(selectedUser.id)) {
        newSelectedUsers.push({
          ...selectedUser,
          oldStatus: 'NEW_TO_GROUP',
          status: 'NEW_TO_GROUP',
        });
      }
    });

    removedUsers.forEach((removedUser) => {
      if (usersIds.includes(removedUser.id)) {
        const userIndex = groupUsers.findIndex(
          (groupUser) => groupUser.id === removedUser.id
        );
        groupUsers[userIndex] = { ...removedUser, status: 'DELETE_FROM_GROUP' };
      }
    });

    const updatedUsers = [...newSelectedUsers, ...groupUsers];
    setUsersToSelectFrom(newUsers);
    setUsers(updatedUsers);
  };

  const removeUser = (index) => {
    const newUsers = [...users];
    newUsers.splice(index, 1);
    if (_.isArray(users) && users.length > index && index >= 0) {
      let userToRemove = users[index];
      let _usersToSelectFrom = [...usersToSelectFrom];
      const foundObject = _usersToSelectFrom.find(
        (obj) => obj.id === userToRemove.id
      );
      if (foundObject) {
        foundObject.isChecked = false;
      }
      setUsersToSelectFrom(_usersToSelectFrom);
    }
    setUsers(newUsers);
  };

  const hasDuplicate = (values) => {
    const emailValues = values.map(({ email }) => email);

    return emailValues.some(
      (email, index) => emailValues.indexOf(email) !== index
    );
  };

  return (
    <GroupWrapper>
      <CRow className="pt-3 pb-3">
        <CCol>
          {!loading ? (
            <CCard className="mb-2">
              <HeaderWithBackButton>Group</HeaderWithBackButton>
              <CCardBody>
                <form onSubmit={formik.handleSubmit}>
                  <CFormGroup row>
                    <CCol xs="12">
                      <CLabel htmlFor="title">
                        Title:
                        <span className="text-danger">*</span>
                      </CLabel>
                    </CCol>

                    <CCol xs="12">
                      <CInput
                        required
                        id="title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </CCol>
                  </CFormGroup>
                </form>

                <h4 className="mb-1">Group members</h4>
                {users.length ? (
                  <CListGroup className="border-0 mt-3 mb-3">
                    {users.map((user, index) => (
                      <CListGroupItem
                        className="d-flex justify-content-between"
                        key={user.id || index}
                      >
                        <p className="mb-0">
                          <b>Name:</b> {user.name} <br />
                          <b>Email:</b> {user.email} <br />
                        </p>
                        <div>
                          <CButton
                            color="danger"
                            onClick={() => removeUser(index)}
                          >
                            X
                          </CButton>
                        </div>
                      </CListGroupItem>
                    ))}
                  </CListGroup>
                ) : (
                  <p>
                    There are no users for this group, you can add them from
                    group section
                  </p>
                )}
                <Button
                  color="primary"
                  className="btn-inline-block"
                  onClick={() => setAddExistingUsersIsVisible(true)}
                >
                  Add team members to group
                </Button>
                {/* <CButton
                onClick={() => setAddNewUsersIsVisible(true)}
                className="ml-2"
              >
                Add new users
              </CButton> */}
              </CCardBody>
              <CCardFooter className="text-right">
                {submitting ? (
                  <LoadingMessage
                    firstMessage="Creating group..."
                    style={{ height: 53, justifyContent: 'flex-end' }}
                  />
                ) : (
                  <>
                    <CButton
                      disabled={submitting}
                      color="danger"
                      className="mr-2"
                      variant="outline"
                      onClick={history.goBack}
                    >
                      Cancel
                    </CButton>
                    <CButton
                      disabled={
                        !formik.values.title ||
                        !!formik.errors.title ||
                        submitting
                      }
                      color="primary"
                      className="mr-2"
                      onClick={createGroup}
                    >
                      Create
                    </CButton>
                  </>
                )}
              </CCardFooter>
            </CCard>
          ) : (
            <CCol xl={12} className="text-center pt-2 pb-2">
              <CSpinner variant="grow" size="lg" />
            </CCol>
          )}
        </CCol>

        <SelectUsers
          usersToSelectFrom={usersToSelectFrom.filter(
            (u) => u.isChecked !== true
          )}
          title={formik.values.title}
          isVisible={existingModalIsVisible}
          onClose={setAddExistingUsersIsVisible}
          addSelectedUsers={closedSelectingUsersModal}
        />

        <CModal
          size="lg"
          backdrop={false}
          scrollable={false}
          show={false}
          onClose={() => setAddExistingUsersIsVisible(false)}
        >
          <CModalHeader>
            <CModalTitle>
              Add exisitng user to group {formik.values.title}
            </CModalTitle>
          </CModalHeader>

          <CModalBody>
            <CListGroup className="">
              {usersToSelectFrom.map((user) => (
                <CListGroupItem key={user.id} className="border-0">
                  <CFormGroup
                    variant="custom-checkbox"
                    className="p-0 text-left"
                  >
                    <CInputCheckbox
                      custom
                      id={user.id}
                      value={user.isChecked}
                      checked={user.isChecked}
                      onChange={() => {
                        return false;
                      }}
                    />
                    <CLabel variant="custom-checkbox" htmlFor={user.id}>
                      {user.name}
                    </CLabel>
                  </CFormGroup>
                </CListGroupItem>
              ))}
            </CListGroup>
          </CModalBody>

          <CModalFooter className="mt-2">
            <CButton
              type="submit"
              color="primary"
              className="mr-2"
              onClick={() => {
                closedSelectingUsersModal([]);
                setAddExistingUsersIsVisible(false);
              }}
            >
              Add existing users
            </CButton>
            <CButton
              color="danger"
              variant="outline"
              onClick={() => setAddExistingUsersIsVisible(false)}
            >
              Cancel
            </CButton>
          </CModalFooter>
        </CModal>

        <CModal
          size="lg"
          backdrop={false}
          scrollable={false}
          show={newUserModalIsVisible}
          onClose={() => setAddNewUsersIsVisible(false)}
        >
          <CModalHeader>
            <CModalTitle>Add player to group {formik.values.title}</CModalTitle>
          </CModalHeader>

          <CModalBody>
            <Formik
              initialValues={{
                players: [
                  {
                    name: '',
                    email: '',
                    type: { value: 'player', label: 'player' },
                  },
                ],
              }}
              validationSchema={Yup.object().shape({
                players: Yup.array().of(
                  Yup.object().shape({
                    name: Yup.string().min(4, 'too short').required('Required'),
                    email: Yup.string().trim().email().required('Required'),
                    type: Yup.object().notRequired(),
                  })
                ),
              })}
              onSubmit={(values, actions) => {
                setUsers([...users, ...values.players]);
                setAddNewUsersIsVisible(false);
                actions.setSubmitting(false);
              }}
              render={({ values, isSubmitting, isValid, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <FieldArray
                    name="players"
                    render={(arrayHelpers) => (
                      <>
                        {values.players && values.players.length > 0 ? (
                          values.players.map((players, index) => (
                            <CRow key={index} className="mb-2">
                              <CCol>
                                <Field name={`players.${index}.name`}>
                                  {({ field }) => (
                                    <CFormGroup>
                                      <CLabel>
                                        Name{' '}
                                        <span className="text-danger">*</span>
                                      </CLabel>
                                      <CInput {...field} required />
                                    </CFormGroup>
                                  )}
                                </Field>
                              </CCol>
                              <CCol>
                                <Field name={`players.${index}.email`}>
                                  {({ field }) => (
                                    <CFormGroup>
                                      <CLabel>
                                        Email{' '}
                                        <span className="text-danger">*</span>
                                      </CLabel>
                                      <CInput {...field} required />
                                    </CFormGroup>
                                  )}
                                </Field>
                              </CCol>

                              <CCol xs="2" className="text-right">
                                <CLabel>&nbsp;</CLabel>
                                <div>
                                  <CButton
                                    type="button"
                                    className="mr-1"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    -
                                  </CButton>
                                  <CButton
                                    type="button"
                                    onClick={() =>
                                      arrayHelpers.insert(index + 1, {
                                        name: '',
                                        player: '',
                                        type: {
                                          value: 'player',
                                          label: 'Player',
                                        },
                                      })
                                    }
                                  >
                                    +
                                  </CButton>
                                </div>
                              </CCol>
                            </CRow>
                          ))
                        ) : (
                          <CButton
                            type="button"
                            color="primary"
                            onClick={() =>
                              arrayHelpers.push({
                                name: '',
                                player: '',
                                type: { value: 'player', label: 'Player' },
                              })
                            }
                          >
                            Add Players/Coaches
                          </CButton>
                        )}
                      </>
                    )}
                  />

                  <CRow>
                    <CCol className="pl-0 pr-0">
                      <CModalFooter className="mt-2">
                        <CButton
                          type="submit"
                          color="primary"
                          className="mr-2"
                          disabled={
                            !isValid ||
                            !values.players.length ||
                            isSubmitting ||
                            hasDuplicate(values.players)
                          }
                        >
                          Add new users
                        </CButton>
                        <CButton
                          color="danger"
                          variant="outline"
                          disabled={isSubmitting}
                          onClick={() => setAddNewUsersIsVisible(false)}
                        >
                          Cancel
                        </CButton>
                      </CModalFooter>
                    </CCol>
                  </CRow>
                </Form>
              )}
            />
          </CModalBody>
        </CModal>
      </CRow>
    </GroupWrapper>
  );
};

export default AddGroup;
