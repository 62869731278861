import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CLabel,
  CLink,
  CRow,
  CTooltip,
} from '@coreui/react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { FaCircle, FaEdit, FaInfoCircle, FaUserPlus } from 'react-icons/fa';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
  addPlayers,
  editPlayer,
  viewPlayerDetails,
} from '../../../actions/playerActionCreator';
import { db, functions } from '../../../index';
import { Loading } from '../../../r-reusable';
import _, { isArray, isString } from 'lodash';
import PlayerWrapper from '../../../pages/component/PlayerWrapper';
import { useHistory } from 'react-router-dom';
import { TABS } from '../../../AppTabs';
import { orgAndTeamData } from '../../../helpers/data';
import {
  ACCESS_ACTIONS,
  ACCESS_LEVELS,
  canDoAction,
} from '../../../helpers/access';
import OrgTeamImageHeader from '../../../r-components/OrgTeamImageHeader/OrgTeamImageHeader';
import TeamCodeExplanationModal from '../teams/TeamCodeExplanationModal';
import EventsWrapper from '../../../pages/component/EventsWrapper';

// import { currentTeamAndOrgData } from '../../../AppReducer';

const getBadge = (status) => {
  return status ? 'success' : 'warning';
};

const getStatus = (status) => {
  return status ? 'Active' : 'Pending';
};

const Events = () => {
  const history = useHistory();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, '');
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const dispatch = useDispatch();
  const users = useSelector((state) => state.DataPlayerState?.data || []);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(currentPage);
  const currentUser = useSelector((state) => state?.UserState);
  const orgData = useSelector((state) => state.DataOrganizationState);
  const teamData = useSelector((state) => state.DataTeamState);
  const [orgTeamData, setOrgTeamData] = useState({});
  const [tableFields, setTableFields] = useState([]);
  const [players, setPlayers] = useState([]);
  const [showTeamCodeExplanationModal, setShowTeamCodeExplanationModal] =
    useState(false);
  const events = useSelector((state) => state.DataEventsState?.events || []);
  const isEventPartner = useSelector(
    (state) => state?.DataSubscriptionState?.isEventPartner === true
  );

  useEffect(() => {
    currentPage !== page && setPage(currentPage);
  }, [currentPage, page]);

  useEffect(() => {
    console.log({ roles: currentUser?.data?._roles });
  }, [currentUser?.data?._roles]);

  // useEffect(async () => {
  //   const createNewPlayerUserCallable = functions.httpsCallable(
  //     'createNewPlayerUserCallable'
  //   );
  //   let org_team = {};
  //   let team_requests = [
  //     {
  //       id: '0a08d209-01e8-496f-8e87-11c17f0e6c1e',
  //       name: 'Deacons',
  //       organization_id: '42bee8cb-95c6-4643-bfcb-7d27bc3bacf1',
  //       location: { city: 'Cromwell', state: 'CT' },
  //     },
  //   ];
  //   let phoneNumber = `+1${Math.round(10000000000 * Math.random())}`;
  //   const createNewPlayerUserResult = await createNewPlayerUserCallable({
  //     name: 'Test',
  //     email: `test${Math.round(100000 * Math.random())}@email.com`,
  //     phoneNumber,
  //     organization_id: '42bee8cb-95c6-4643-bfcb-7d27bc3bacf1',
  //     team_requests,
  //     orgName: 'Deacons',
  //   });
  // }, []);

  useEffect(() => {
    let orgTeam = orgAndTeamData({ orgData, teamData });
    setOrgTeamData(orgTeam);
    let tableFields = [
      { key: 'logo', label: '', sorter: false },
      { key: 'name', label: 'Name', _classes: 'font-weight-bold' },
      { key: 'city', label: 'City' },
      { key: 'dateRangeText', label: 'Dates' },
      { key: 'visibleDateText', label: 'Dates Visible' },
      { key: 'numTeams', label: '# Teams' },
      { key: 'numResponses', label: '# Participant Responses' },
      { key: 'password', label: 'Code' },
      { key: 'show_details', label: '', sorter: false },
    ];
    setTableFields(tableFields);
  }, [orgData, teamData]);

  useEffect(() => {
    if (_.isString(orgData?.data?.id) && orgData.data.id.length > 0) {
      let newPlayers = [...(users || [])].map((u) => {
        let p = _.pick(u, [
          '_id',
          'name',
          'activated',
          'email',
          'registered',
          '_teams',
          '_positions',
        ]);
        p.id = u._id;
        if (
          _.isString(teamData?.teamData?._id) &&
          teamData.teamData._id.length > 0
        ) {
          delete p._teams;
          p._positions =
            u.org_team?.[orgData.data.id]?.[teamData.teamData._id]?.positions ||
            [];
        }
        return p;
      });
      setPlayers(newPlayers);
    }
  }, [orgData, teamData, users]);

  return (
    <EventsWrapper>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader className="d-flex justify-content-between">
              <OrgTeamImageHeader
                orgTeamData={orgTeamData}
                title="Events"
                rightView={
                  <>
                    {canDoAction({
                      action: ACCESS_ACTIONS.EVENT_EVENT_CREATE,
                      type: isEventPartner
                        ? [ACCESS_LEVELS.EVENT_PARTNER.key]
                        : currentUser?.data?._roles,
                    }).canView && (
                      <CButton
                        color="primary"
                        id="players-add-player-button"
                        onClick={() => {
                          history.push(TABS.EVENT_EVENT_CREATE.path);
                        }}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <span className="m-0 mr-2 h3">+</span> Add Event
                      </CButton>
                    )}
                  </>
                }
              />
            </CCardHeader>

            <CCardBody>
              {loading ? (
                <Loading />
              ) : (
                <CDataTable
                  striped
                  items={events}
                  itemsPerPageSelect={true}
                  itemsPerPage={50}
                  pagination={true}
                  hover
                  activePage={page}
                  tableFilter={true}
                  sorter
                  sorterValue={{ column: 'name', asc: true }}
                  fields={tableFields}
                  noItemsViewSlot={
                    <p className="d-flex align-content-center justify-content-center pt-4 pb-4">
                      <FiAlertCircle size="30" color="#e55353" />
                      <span className="pl-2 font-xl">No events yet</span>
                    </p>
                  }
                  scopedSlots={{
                    logo: (event) => (
                      <td className="font-weight-bold">
                        <>
                          {isString(event.logoUrl) &&
                            event.logoUrl.length > 0 && (
                              <img
                                src={event.logoUrl}
                                style={{ maxHeight: 30, objectFit: 'contain' }}
                              />
                            )}
                        </>
                      </td>
                    ),
                    name: (event) => (
                      <td className="font-weight-bold">
                        <Link
                          to={TABS.EVENT_LEADERBOARD.pathVar(
                            _.pick(event, ['id', 'name'])
                          )}
                        >
                          {event.name}
                        </Link>
                      </td>
                    ),
                    numTeams: (event) => <td>{(event.teams || []).length}</td>,
                    numResponses: (event) => (
                      <td>{(event.participants || []).length}</td>
                    ),
                    datesVisible: (event) => {
                      let datesVisibleText = '';
                      if (
                        isArray(event.visibleDates) &&
                        event.visibleDates.length > 0
                      ) {
                        let dateObjects = event.visibleDates.map(
                          (d) => new Date(d)
                        );
                        const earliestDate = new Date(Math.min(...dateObjects));
                        const latestDate = new Date(Math.max(...dateObjects));
                      }
                      return <td>{datesVisibleText}</td>;
                    },
                    password: (event) => {
                      return (
                        <td>
                          {isString(event?.password) &&
                          event.password.length > 0
                            ? event.password
                            : '----'}
                        </td>
                      );
                    },
                    show_details: (player) => (
                      <td className="text-right">
                        {
                          <Link
                            to={TABS.EVENT_EVENTS_EDIT.pathVar(
                              _.pick(player, ['id', 'name'])
                            )}
                          >
                            <FaEdit />
                          </Link>
                        }
                      </td>
                    ),
                  }}
                />
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </EventsWrapper>
  );
};

export default Events;
