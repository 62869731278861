import React from 'react';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import styled from 'styled-components';

const US_TEXT = 'Us';
const THEM_TEXT = 'Them';

const DiamondMain = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 0 auto 20px;
  background: #000000;
`;

const DiamondTop = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
`;

const DiamondLeft = styled.div`
  cursor: pointer;
  background: #0b2748;
  flex: 0 0 60%;
  padding: 20px 20px 10px;
`;

const DiamondRight = styled.div`
  background: #22360a;
  flex: 0 0 40%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DiamondLeftRow = styled.div`
  display: flex;
  padding: 0 0 10px 0;
  margin: 0;
  justify-content: space-between;
  align-items: center;
`;

const DiamondLeftCol = styled.div`
  flex: 0 0 50%;
  font-family: 'Aviner', sans-serif;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  text-transform: uppercase;
`;

const DiamondRightCol = styled.div`
  flex: 0 0 50%;
  font-family: 'Aviner', sans-serif;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  text-transform: uppercase;
`;

const DiamondBottom = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding: 5px 0;
  margin: 0;
`;

const DiamondBottomLeft = styled.div`
  flex: 0 0 30%;
  font-family: 'Aviner', sans-serif;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0 0 0 20px;
  margin: 0;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DiamondBottomLeftIcon = styled.i`
  color: #959595;
  padding-top: 0px;
  margin-right: 4px;
  top: -2px;
  position: relative;
`;

const DiamondBottomCenter = styled.div`
  flex: 0 0 30%;
  font-family: 'Aviner', sans-serif;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0 20px 0 0;
  margin: 0;
  text-align: right;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const DiamondBottomCenterSpan = styled.div`
  color: #646464;
  font-size: 15px;
`;

const DiamondBottomRight = styled.div`
  flex: 0 0 40%;
  font-family: 'Aviner', sans-serif;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0 10px;
  margin: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DiamondBottomRightInner = styled.div`
  position: relative;
  display: inline-block;
  width: 70px;
  height: 46px;
`;

const DiamondBox = styled.div`
  width: 20px;
  height: 20px;
  background: #010300;
  border: 2px solid rgba(255, 255, 255, 0.3);
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
`;

const DiamondCount = styled.div`
  cursor: pointer;
  display: inline;
  width: 20px;
  text-align: center;
`;

const ViewScoreBoard = (props) => (
  <DiamondMain>
    <DiamondTop>
      <DiamondLeft>
        <DiamondLeftRow>
          <DiamondLeftCol>
            {props.homeTeam ? THEM_TEXT : US_TEXT}
          </DiamondLeftCol>
          <DiamondRightCol>
            {props.homeTeam ? props.awayScore : props.homeScore}
          </DiamondRightCol>
        </DiamondLeftRow>
        <DiamondLeftRow>
          <DiamondLeftCol>
            {props.homeTeam ? US_TEXT : THEM_TEXT}
          </DiamondLeftCol>
          <DiamondRightCol>
            {props.homeTeam ? props.homeScore : props.awayScore}
          </DiamondRightCol>
        </DiamondLeftRow>
      </DiamondLeft>
      <DiamondRight>
        <DiamondBottomRightInner>
          <DiamondBox
            style={{
              top: 20,
              left: 40,
              backgroundColor: props.runnerAt1 ? 'yellow' : '#010300',
            }}
          ></DiamondBox>
          <DiamondBox
            style={{
              top: 0,
              left: 0,
              backgroundColor: props.runnerAt2 ? 'yellow' : '#010300',
            }}
          ></DiamondBox>
          <DiamondBox
            style={{
              top: 20,
              left: -40,
              backgroundColor: props.runnerAt3 ? 'yellow' : '#010300',
            }}
          ></DiamondBox>
        </DiamondBottomRightInner>
      </DiamondRight>
    </DiamondTop>
    <DiamondBottom>
      <DiamondBottomLeft>
        <DiamondBottomLeftIcon>
          {props.inningTopHalf ? <FiChevronUp /> : <FiChevronDown />}
        </DiamondBottomLeftIcon>
        {props.inning}
      </DiamondBottomLeft>
      <DiamondBottomCenter>
        <span style={{ marginRight: 4 }}>{props.outs}</span>
        <DiamondBottomCenterSpan>
          out{props.outs !== 1 && 's'}
        </DiamondBottomCenterSpan>
      </DiamondBottomCenter>
      <DiamondBottomRight>
        <DiamondCount>{props.countBalls}</DiamondCount>-
        <DiamondCount>{props.countStrikes}</DiamondCount>
      </DiamondBottomRight>
    </DiamondBottom>
  </DiamondMain>
);

export default ViewScoreBoard;
