import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCardHeader,
  CCol,
  CImg,
  CLink,
  CRow,
  CTooltip,
} from '@coreui/react';
import './team.scss';
import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';
import {
  FaBaseballBall,
  FaCheckSquare,
  FaCircle,
  FaClipboard,
  FaEnvelope,
  FaImages,
  FaMobile,
  FaPencilAlt,
  FaPlus,
  FaQuestion,
  FaRegEdit,
  FaUser,
  FaUsers,
} from 'react-icons/fa';
import { setCurrentTeam } from '../../../r-store/team/actions';
import { TABS } from '../../../AppTabs';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { orgAndTeamData } from '../../../helpers/data';
import { GiWhistle } from 'react-icons/gi';
import TeamViewRunsSaved from './TeamViewRunsSaved';
import SubscriptionOverviewCard from '../../../r-components/Subscription/SubscriptionOverviewCard';
import OrgTeamImageHeader from '../../../r-components/OrgTeamImageHeader/OrgTeamImageHeader';
import FlexSpacer from '../../../r-components/FlexSpacer/FlexSpacer';
import TeamRequestAccess from './TeamRequestAccess';
import {
  numberOfCoachRequests,
  numberOfCoaches,
  numberOfCoachesOnTeam,
  numberOfPlayers,
  numberOfPlayersOnTeam,
} from '../../../helpers/stateDataHandler';
import {
  ACCESS_ACTIONS,
  canDoAction,
  checkAccess,
} from '../../../helpers/access';
import useAccountSelectors from '../../../hooks/useAccountSelectors';

const StatSection = ({
  Icon,
  count,
  label,
  text = null,
  badge,
  badgeText,
  to = null,
  onClick,
  pluralSuffix = 's',
}) => (
  <div className="coach-team-view-org-details-roster-wrapper">
    {_.isNil(to) && _.isNil(onClick) ? (
      <div>
        {!isNaN(badge) && parseInt(badge) > 0 && (
          <div className="coach-team-view-org-details-roster-badge">
            {_.isString(badgeText) && badgeText.length > 0 ? (
              <CTooltip content={badgeText}>
                <FaCircle color="red" />
              </CTooltip>
            ) : (
              <FaCircle color="red" />
            )}
          </div>
        )}
        <Icon size={40} />
        {!_.isNil(text) ? (
          <>{text}</>
        ) : (
          <>{`${count} ${label}${count === 1 ? '' : pluralSuffix}`}</>
        )}
      </div>
    ) : (
      <Link to={to || '#'} onClick={onClick}>
        {!isNaN(badge) && parseInt(badge) > 0 && (
          <div className="coach-team-view-org-details-roster-badge">
            {_.isString(badgeText) && badgeText.length > 0 ? (
              <CTooltip content={badgeText}>
                <FaCircle color="red" />
              </CTooltip>
            ) : (
              <FaCircle color="red" />
            )}
          </div>
        )}
        <Icon size={40} />
        {!_.isNil(text) ? (
          <>{text}</>
        ) : (
          <>{`${count} ${label}${count === 1 ? '' : pluralSuffix}`}</>
        )}
      </Link>
    )}
  </div>
);

const TeamView = ({ teamData, organizationData, teams, userData }) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const [showingOrgDetails, setShowingOrgDetails] = useState(true);
  const { type, tier, showSubscriptionModal, goToUrl, numTeams } =
    useAccountSelectors();
  const [orgTeamData, setOrgTeamData] = useState({});
  const state = useSelector((state) => state);
  const [isFlashingManageSubscription, setIsFlashingManageSubscription] =
    useState(false);
  const numCoaches = numberOfCoaches(state);
  const numCoachRequests = numberOfCoachRequests(state);
  const numPlayers = numberOfPlayers(state);
  const numPlayerRequests = useSelector(
    (state) => (state?.DataPlayerState?.teamRequests || []).length
  );
  const numQuestions = useSelector(
    (state) => (state?.DataQuestionState?.data || []).length
  );
  const numQuizzes = useSelector(
    (state) => (state?.DataQuizState?.data || []).length
  );
  const numMedia = useSelector(
    (state) => (state?.DataMediaState?.data || []).length
  );
  const numInvites = useSelector(
    (state) => (state?.DataExamState?.invites || []).length
  );
  const numTaken = useSelector(
    (state) => (state?.DataExamState?.taken || []).length
  );
  const subscription = useSelector(
    (state) => state?.DataSubscriptionState || {}
  );
  const requestsData = useSelector(
    (state) => state.UserState?.data?.team_requests || []
  );
  useEffect(() => {
    setOrgTeamData(orgAndTeamData({ teamData, orgData: organizationData }));
  }, [teamData, organizationData]);

  const renderExtraSlots = () => {
    const teamsAvailable =
      (subscription?.current?.quantity || -1) - teams.length;
    if (teamsAvailable <= 0) {
      return renderCreateATeamCard(false);
    }
    return (
      <>
        {[...Array(Math.max(0, teamsAvailable)).keys()].map((i, index) => (
          <React.Fragment key={`render-create-team-card-${index}`}>
            {renderCreateATeamCard(true)}
          </React.Fragment>
        ))}
      </>
    );
    // return renderCreateATeamCard();
  };

  const renderCreateATeamCard = (hasAvailableTeams) => {
    return (
      <CCard
        className="choose-team-card"
        onClick={() => {
          checkAccess({
            action: ACCESS_ACTIONS.CREATE_TEAM,
            type,
            tier,
            thresholdCount: numTeams + 1,
            showSubscriptionModal,
            goToUrl,
          }).navigateWithAccess(TABS.COACH_TEAMS_CREATE.path);
        }}
      >
        <CCardBody
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
            alignItems: 'center',
            justifyContent: 'center',
            padding: '40px 10px',
          }}
        >
          <FaPlus size={50} />
          Create team
        </CCardBody>
      </CCard>
    );
    // return (
    //   <>
    //     {hasAvailableTeams ? (
    //       <Link
    //         to={TABS.COACH_TEAMS_CREATE.path}
    //         style={{ flex: '1 0', height: '100%' }}
    //       >
    //         {card(hasAvailableTeams)}
    //       </Link>
    //     ) : (
    //       <>{card(hasAvailableTeams)}</>
    //     )}
    //   </>
    // );
  };

  const {
    canView: canViewEditOrg,
    // canDo,
    // reason,
    // step,
  } = canDoAction({
    action: ACCESS_ACTIONS.EDIT_ORGANIZATION,
    type,
    tier,
  });
  return (
    <Container className="space-1 coach-team-view">
      {userData.isOwner && (
        <CRow
          className={`coach-team-view-org-section${
            showingOrgDetails ? ' showing-details' : ''
          }`}
        >
          <CCol className="coach-team-view-org-wrapper" xl={12}>
            <OrgTeamImageHeader
              orgTeamData={orgTeamData}
              title={orgTeamData.hasTeam === true ? 'Team' : 'Organization'}
              leftView={
                <>
                  {canViewEditOrg && (
                    <CButton
                      color="link"
                      variant="ghost"
                      onClick={() => {
                        checkAccess({
                          action: ACCESS_ACTIONS.EDIT_ORGANIZATION,
                          type,
                          tier,
                          showSubscriptionModal,
                          goToUrl,
                        }).navigateWithAccess(
                          orgTeamData.hasTeam === true
                            ? TABS.OWNER_TEAM_EDIT.pathVar({
                                id: orgTeamData?.team?.id,
                                name: orgTeamData?.team?.name,
                              })
                            : TABS.OWNER_ORGANIZATION_EDIT.path
                        );
                      }}
                    >
                      <FaRegEdit
                        style={{
                          marginTop: -6,
                          marginLeft: 10,
                          marginRight: 4,
                        }}
                      />
                      {showingOrgDetails && `Edit`}
                    </CButton>
                  )}
                </>
              }
              rightView={
                <CLink
                  onClick={() => {
                    setShowingOrgDetails(!showingOrgDetails);
                  }}
                >
                  {`${showingOrgDetails ? 'hide' : 'show'} details`}
                </CLink>
              }
            />
            {/* <div className="coach-team-view-org-header">
              <img
                src={orgTeamData?.org?.imageUrl}
                style={{
                  height: 50,
                  width: 'auto',
                  objectFit: 'contain',
                  marginRight: 10,
                }}
              />
              <h1 className="m-0">
                {_.isString(organizationData.data?.title) &&
                organizationData.data?.title.length > 0
                  ? `${organizationData.data.title} Organization`
                  : 'Organization Details'}
              </h1>
              <Link
                to={TABS.OWNER_ORGANIZATION_EDIT.path}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 4,
                }}
              >
                <FaRegEdit style={{ marginTop: -4, marginLeft: 10 }} />
                {showingOrgDetails && `Edit`}
              </Link>
              <div style={{ flex: 1 }}></div>
              <CLink
                onClick={() => {
                  setShowingOrgDetails(!showingOrgDetails);
                }}
              >
                {`${showingOrgDetails ? 'hide' : 'show'} details`}
              </CLink>
            </div> */}
            {showingOrgDetails && (
              <div className="coach-team-view-org-details-section">
                <div className="coach-team-view-org-details-roster">
                  <StatSection
                    onClick={() => {
                      setTimeout(() => {
                        var element =
                          document.getElementById('choose-team-card');
                        element.scrollIntoView({ behavior: 'smooth' });
                      }, 0);
                    }}
                    Icon={FaUsers}
                    text={`${
                      orgTeamData.hasTeam === true ? 1 : teams.length
                    } Team${
                      orgTeamData.hasTeam === true || teams.length === 1
                        ? ''
                        : 's'
                    }`}
                  />
                  <StatSection
                    to={TABS.OWNER_COACHES.path}
                    Icon={GiWhistle}
                    count={numCoaches}
                    label="Coach"
                    pluralSuffix="es"
                    badge={numCoachRequests}
                    badgeText={
                      numCoachRequests > 0
                        ? `${numCoachRequests} coach${
                            numCoachRequests === 1 ? ' has' : 'es have'
                          } requested to join your team${
                            orgTeamData.hasTeam === true ? '' : 's'
                          }`
                        : null
                    }
                  />
                  <StatSection
                    to={TABS.COACH_PLAYERS.path}
                    Icon={FaUser}
                    count={numPlayers}
                    badge={numPlayerRequests}
                    badgeText={
                      numPlayerRequests > 0
                        ? `${numPlayerRequests} player${
                            numPlayerRequests === 1 ? ' has' : 's have'
                          } requested to join your team${
                            orgTeamData.hasTeam === true ? '' : 's'
                          }`
                        : null
                    }
                    label="Player"
                  />
                  {/* <StatSection
                    to={TABS.COACH_QUESTIONS.path}
                    Icon={FaQuestion}
                    count={numQuestions}
                    label="Question"
                  />
                  <StatSection
                    to={TABS.COACH_MEDIA.path}
                    Icon={FaImages}
                    count={numMedia}
                    text={`${numMedia} Image${numMedia === 1 ? '' : 's'}/Video${
                      numMedia === 1 ? '' : 's'
                    }`}
                  /> */}
                  <StatSection
                    to={TABS.COACH_QUIZZES.path}
                    Icon={FaCheckSquare}
                    count={numQuizzes}
                    label="Quiz"
                    pluralSuffix="zes"
                  />
                  <StatSection
                    to={TABS.COACH_QUIZ_INVITES.path}
                    Icon={FaEnvelope}
                    count={numInvites}
                    label="Quiz Invite"
                  />
                  <StatSection
                    to={TABS.COACH_QUIZ_TAKENS.path}
                    Icon={FaMobile}
                    text={`${numTaken} Quiz${
                      numTaken === 1 ? '' : 'zes'
                    } Taken`}
                  />
                  {/* <div className="coach-team-view-org-details-roster-wrapper">
                    <TeamViewRunsSaved />
                  </div> */}
                </div>
                {orgTeamData.hasOrg === true &&
                  orgTeamData.hasTeam === false &&
                  userData.isOwner === true && (
                    <SubscriptionOverviewCard
                      subscription={subscription}
                      teams={teams}
                      showManageButton={true}
                      isFlashingManageSubscription={
                        isFlashingManageSubscription
                      }
                    />
                  )}
              </div>
            )}
          </CCol>
        </CRow>
      )}
      <CRow>
        <CCol xl={12}>
          <CCard id="choose-team-card">
            <CCardHeader className="d-flex justify-content-between">
              <h1 className="m-0">
                {_.isString(teamData?.teamData?._id) &&
                teamData.teamData._id.length > 0
                  ? `Selected Team: ${teamData.teamData.name}`
                  : 'My Teams'}
              </h1>
              {_.isArray(teamData?.data) && teamData.data.length > 1 && (
                <>
                  {orgTeamData.hasTeam === true && (
                    <>
                      <FlexSpacer />
                      <CTooltip content="This will de-select all teams and the application will show you content for the full organization, not specifically one team">
                        <CButton
                          onClick={() => {
                            dispatch(setCurrentTeam(null));
                          }}
                          color="primary"
                        >
                          View Full Organization
                        </CButton>
                      </CTooltip>
                    </>
                  )}
                </>
              )}
            </CCardHeader>
            <CCardBody>
              <CCardGroup
                deck={true}
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr',
                  rowGap: 30,
                }}
              >
                {teams.map((t, index) => (
                  <CCard
                    className={`choose-team-card${
                      t._id === teamData?.teamData?._id
                        ? ' selected'
                        : ' selectable'
                    }`}
                    key={`choose-team-card-team-${t.name}-${index}`}
                    onClick={() => {
                      dispatch(
                        setCurrentTeam(
                          teamData?.teamData?._id === t._id ? null : t._id
                        )
                      );
                      // history.push(TABS.COACH_PLAYERS.path);
                    }}
                  >
                    <CCardBody>
                      <div className="team-card-header">
                        {_.isString(t.imageUrl) && t.imageUrl.length > 0 && (
                          <>
                            <img src={t.imageUrl} />
                          </>
                        )}
                        <div>
                          <h3>{t.name}</h3>
                          <div className="age-group">{t.ageGroup}</div>
                        </div>
                        <div style={{ flex: 1 }}></div>
                        <Link
                          to={TABS.OWNER_TEAM_EDIT.pathVar({
                            id: t._id,
                            name: t.name,
                          })}
                          className="team-card-edit-team-link"
                        >
                          <FaPencilAlt />
                        </Link>
                      </div>
                      <div className="team-details">
                        <div>
                          {`${numberOfPlayersOnTeam(state, {
                            team_id: t._id,
                          })} player${
                            numberOfPlayersOnTeam(state, { team_id: t._id }) ===
                            1
                              ? ''
                              : 's'
                          }`}
                        </div>
                        <div>
                          {`${numberOfCoachesOnTeam(state, {
                            team_id: t._id,
                          })} coach${
                            numberOfCoachesOnTeam(state, {
                              team_id: t._id,
                            }) === 1
                              ? ''
                              : 'es'
                          }`}
                        </div>
                      </div>
                    </CCardBody>
                  </CCard>
                ))}
                {userData.isOwner === true && <>{renderExtraSlots()}</>}
              </CCardGroup>
            </CCardBody>
          </CCard>
          {userData.isOwner !== true && (
            <TeamRequestAccess
              title="Join Another Team?"
              orgTeamData={orgTeamData}
              requestsData={requestsData}
            />
          )}
        </CCol>
      </CRow>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.UserState,
    organizationData: state.DataOrganizationState || {},
    teamData: state.DataTeamState || {},
    teams: state.DataTeamState?.data || [],
  };
};

export default connect(mapStateToProps)(withRouter(TeamView));
