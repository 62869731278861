import React, { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash';
import './AppNav.scss';

const FunnelNav = () => {
  return (
    <div className="header-sticky">
      <header
        id="header"
        className="u-header u-header--modern u-header--bordered"
      >
        <div className="u-header__section">
          <div id="logoAndNav" className="container-fluid">
            <nav className="js-mega-menu navbar navbar-expand-lg u-header__navbar hs-menu-initialized hs-menu-horizontal">
              <div className="left-menu-wrapper">
                <div
                  className="navbar-brand u-header__navbar-brand"
                  aria-label="Space"
                >
                  <img
                    className="u-header__navbar-brand-default six-tool-logo"
                    src="/img/logo.png"
                    alt="Logo"
                  />
                  <img
                    className="u-header__navbar-brand-mobile six-tool-logo"
                    src="/img/logo.png"
                    alt="Logo"
                  />
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </div>
  );
};

export default FunnelNav;
