import styled from "styled-components";

// TODO: add media query for responsiveness
export const Wrapper = styled.div`
  max-width: 1170px;
  margin: 0px auto;



`;
