import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import Tour from 'reactour';
import { connect } from 'react-redux';
import { setCurrentTour, setCurrentTourStep } from '../../r-store/tour/actions';
import { addPlayers } from '../../actions/playerActionCreator';
import { db } from '../..';
import { updateDoc, doc } from 'firebase/firestore';
import { toTitleCase } from '../../helpers/utils';

export const showTour = (userData, tourName, dispatch, options = {}) => {
  if (
    !!userData &&
    _.isObject(userData.data) &&
    _.isString(userData.data.id) &&
    userData.data.id.length > 0
  ) {
    let viewedField = `viewed${toTitleCase(tourName)}`;
    if (
      !(
        _.isObject(userData?.data?.onboard) &&
        _.isObject(userData.data.onboard.tours) &&
        userData?.data?.onboard?.tours?.[viewedField] === true
      )
    ) {
      let u = {};
      u[viewedField] = true;
      updateDoc(doc(db, 'users', userData.data.id), {
        onboard: {
          ...userData.data.onboard,
          ...{ tours: { ...(userData?.data?.onboard?.tours || {}), ...u } },
        },
      });
      dispatch(setCurrentTourStep(0));
      dispatch(
        setCurrentTour({
          tourName,
          ...options,
        })
      );
    }
  }
  return true;
};

export const TourView = ({ name, tourData }) => {
  const dispatch = useDispatch();
  const [tourStep, setTourStep] = useState(0);
  const closeTour = () => {
    dispatch(setCurrentTour(null));
  };
  useEffect(() => {
    if (
      _.isArray(tourData?.dispatchFunctions) &&
      tourData.dispatchFunctions.length > 0
    ) {
      tourData.dispatchFunctions.forEach((df) => {
        if (_.isFunction(df)) {
          dispatch(df());
        }
      });
    }
    setTourStep(tourData?.currentTourStep || 0);
  }, [tourData]);

  return (
    <Tour
      steps={tourData?.steps}
      isOpen={
        tourData.name === name &&
        _.isArray(tourData?.steps) &&
        tourData.steps.length > 0
      }
      onRequestClose={closeTour}
      showNavigationNumber={false}
      showNumber={false}
      startAt={tourData?.currentTourStep || 0}
      nextStep={() => {
        dispatch(setCurrentTourStep((tourData.currentTourStep || 0) + 1));
      }}
      prevStep={() => {
        dispatch(
          setCurrentTourStep(Math.max(0, (tourData.currentTourStep || 0) - 1))
        );
      }}
      goToStep={tourStep}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    tourData: state.TourState,
  };
};

TourView.propTypes = {
  tourData: PropTypes.shape({
    steps: PropTypes.array,
    name: PropTypes.string,
    currentTourStep: PropTypes.number,
  }),
};

export default connect(mapStateToProps)(withRouter(TourView));
