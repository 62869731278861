import React, { useEffect, useState } from 'react';
// import { requestUpdatedProfileData } from '../../actions/profileActionCreator';
import { useDispatch, useSelector } from 'react-redux';
import Addteam from '../team/AddTeam';
import YourTeam from '../team/YourTeam';
import { getAnnualSubscriptionExpirationDate } from '../../helpers/utils';
import { today } from '../../helpers/constants';
import { toast } from 'react-toastify';
import { globalCoupons } from '../../helpers/data';
import _ from 'lodash';

export const Subscription = () => {
  const dispatch = useDispatch();
  const teamLength = useSelector(
    (state) =>
      state.DataTeamState.teamData && state.DataTeamState.teamData.length
  );
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [coupons, setCoupons] = useState([]);
  let [sessionId, setSessionId] = useState('');
  let [activeSubscription, setActiveSubscription] = useState(true);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      const sessionId = query.get('session_id');
      setSessionId(sessionId);
      // getCheckoutSession(dispatch, sessionId);
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setActiveSubscription(false);
      setMessage('Order canceled');
    }
  }, [sessionId]);

  useEffect(async () => {
    let gc = await globalCoupons();
    if (_.isArray(gc) && gc.length > 0) {
      setCoupons(gc);
    }
  }, []);

  if (
    (success && sessionId !== '') ||
    (localStorage.getItem('subscription_status') &&
      localStorage.getItem('subscription_expiration_date') &&
      getAnnualSubscriptionExpirationDate(
        localStorage.getItem('subscription_expiration_date')
      ) > today &&
      teamLength === 0)
  ) {
    // If successfully created subscription OR update active subscription with no teams created
    return <Addteam activeSubscription={activeSubscription} />;
  } else if (
    localStorage.getItem('subscription_status') &&
    localStorage.getItem('subscription_expiration_date') &&
    getAnnualSubscriptionExpirationDate(
      localStorage.getItem('subscription_expiration_date')
    ) > today &&
    teamLength !== 0
  ) {
    // If update active subscription with teams created
    return <YourTeam activeSubscription={activeSubscription} />;
    // } else if (!success && message === '') {
    // If cancelling signup
  } else {
    return <ProductDisplay message={message} coupons={coupons} />;
    // return <Message message={message} />;
  }
};
/*
const getCheckoutSession = async (dispatch, sessionId) => {
  const session = await requestGetCheckoutSession(sessionId);

  const userId = localStorage.getItem('user_id');
  const stripeCustomerId = session.customer;
  const subscriptionStatus = session.status;
  const subscriptionExpirationDate = session.expires_at;

  const updateData = {
    stripeCustomerId: stripeCustomerId,
    subscriptionStatus: subscriptionStatus,
    subscriptionExpirationDate: subscriptionExpirationDate,
  };

  // dispatch(requestUpdatedProfileData(updateData, userId));
};*/

const ProductDisplay = ({ message = '', coupons = [] } = {}) => (
  <section>
    <div className="gradient-overlay-half-dark-v3 bg-img-hero login-background">
      <div className="align-items-lg-center min-height-100vh">
        <div className="container space-2">
          <div className="row justify-content-lg-between align-items-lg-center">
            <div className="col-lg-7 m-auto">
              <div className="bg-white shadow-sm rounded p-6">
                <div className="product">
                  <div className="description">
                    <div
                      className="d-flex"
                      style={{ justifyContent: 'space-between' }}
                    >
                      <h3>Annual Subscription</h3>
                      <h3>$300/year</h3>
                    </div>
                    <p>
                      Team access for a full year. Weekly quizzes from 6Tool and
                      full access to library of questions.
                    </p>
                    {!!coupons && _.isArray(coupons) && coupons.length > 0 && (
                      <>
                        <strong>{`Available coupon${
                          coupons.length === 1 ? '' : 's'
                        }`}</strong>
                        <ul>
                          {coupons.map((coupon, index) => (
                            <li key={`${index}-${coupon.coupon_id || ''}`}>
                              {coupon.text}
                              {coupon.auto_apply === true
                                ? ' (Will be automatically applied)'
                                : ''}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                </div>
                <button
                  id="checkout-and-portal-button"
                  className="btn btn-block btn-primary"
                  onClick={() => {
                    /*createCheckoutSession(coupons)*/
                  }}
                >
                  Checkout
                </button>
                {_.isString(message) && message.length > 0 && <p>{message}</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);
