import React, { Component } from 'react';
import Slider from 'react-slick';

import './why.scss';
import ReadyToStart from './component/ReadyToStart/ReadyToStart';
import SEOHead from '../r-components/SEOHead/SEOHead';

const Why6Tool = () => {
  return (
    <>
      <SEOHead
        title="Elevate Player Development with Our Baseball Coaching App"
        description="Discover why 6Tool is preferred by successful baseball coaches. Enhance your players' Baseball IQ, foster player development, and elevate your winning potential with our revolutionary baseball coaching app. Explore the 6Tool difference and get ready to transform your team's performance."
      />
      <section className="why-banner">
        <div className="container">
          <h1>
            Why <span>6Tool?</span>
          </h1>
          <p>
            Increase the Baseball IQ of Your Players, No Matter Their Skill
            Level
          </p>
          <div className="player-image" />
        </div>
      </section>

      <section className="why-6-tool">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="img-box pb-4">
                <img src="img/image-collage.png" className="img-fluid" alt="" />
              </div>
            </div>

            <div className="col-md-6">
              <div className="content">
                <div className="title">
                  <h2>Win with a higher baseball IQ</h2>
                </div>
                <p>
                  With more and more emphasis being placed on travel ball in our
                  society, players' time is being squeezed. Kids do not spend as
                  much time watching baseball games or playing in the backyard
                  as they do playing in organized tournaments.
                </p>

                <p>
                  Coaches focus on the traditional 5Ts (hitting, power,
                  fielding, throwing and running) during practices , training
                  and games. Yet, without knowing what the right play to make
                  is, a player is not able to reach their full potential. What
                  good is having a strong arm if you don't know which base to
                  throw it to?{' '}
                </p>

                <p>
                  6Tool allows coaches to pick from thousands of questions,
                  while allowing them to create their own questions/situations
                  to ask their players in the form of a multiple choice quiz.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="how-it-works-example">
        <div className="container">
          <div className="title text-center">
            <h2>See How 6Tool Puts You In Control</h2>
          </div>
          <div className="img-box">
            <img src="img/6Tool-example.gif" className="img-fluid" alt="" />
          </div>
        </div>
      </section>
      <ReadyToStart />
    </>
  );
};

export default Why6Tool;
