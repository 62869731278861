import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import _, { isFunction } from 'lodash';
import { FaAngleDoubleRight, FaArrowRight } from 'react-icons/fa';
import { ROUTE_ELEMENTS, tabForPathArrayAndAction, TABS } from '../../AppTabs';
import { useSelector } from 'react-redux';

const Breadcrumbs = ({ match }) => {
  const [crumbs, setCrumbs] = useState([]);
  const teamData = useSelector((state) => state.DataTeamState);
  const orgData = useSelector((state) => state.DataOrganizationState);
  const userData = useSelector((state) => state.UserState);
  const [showingOrg, setShowingOrg] = useState(false);
  const [showingTeam, setShowingTeam] = useState(false);
  useEffect(() => {
    let pArray = match.path
      .replace('/:name/:id', '')
      .replace('/:id', '')
      .replace('/app/', '')
      .split('/');
    let c;
    if (
      match.path.endsWith(`/${ROUTE_ELEMENTS.DETAILS}/:name/:id`) ||
      match.path.endsWith(`/${ROUTE_ELEMENTS.DETAILS}/:id`) ||
      match.path.endsWith(`/${ROUTE_ELEMENTS.LEADERBOARD}/:name/:id`) ||
      match.path.endsWith(`/${ROUTE_ELEMENTS.LEADERBOARD}/:id`) ||
      match.path.endsWith(`/${ROUTE_ELEMENTS.EDIT}/:name/:id`) ||
      match.path.endsWith(`/${ROUTE_ELEMENTS.EDIT}/:id`)
    ) {
      c = pArray.map((c) => {
        if (
          c === ROUTE_ELEMENTS.DETAILS ||
          c === ROUTE_ELEMENTS.EDIT ||
          c === ROUTE_ELEMENTS.LEADERBOARD
        ) {
          const tab = tabForPathArrayAndAction({
            pathObject: _.first(pArray) || '',
            pathAction: c === ROUTE_ELEMENTS.EDIT ? ROUTE_ELEMENTS.DETAILS : c,
          });
          if (!!tab && isFunction(tab.pathVar)) {
            return {
              label: match.params.name,
              link: `${tab.pathVar(_.pick(match.params, ['name', 'id']))}`,
            };
          } else {
            return { label: match.params.name, link: '#' };
          }
        }
        return { label: c, link: `${TABS.COACH_APP_HOME.path}/${c}` };
      });
      if (_.last(pArray) === ROUTE_ELEMENTS.EDIT) {
        const tab = tabForPathArrayAndAction({
          pathObject: _.first(pArray) || '',
          pathAction: ROUTE_ELEMENTS.EDIT,
        });
        if (!!tab && isFunction(tab.pathVar)) {
          c.push({
            label: ROUTE_ELEMENTS.EDIT,
            link: tab.pathVar(_.pick(match.params, ['name', 'id'])),
          });
        }
      }
    } else {
      let temp = '';
      c = pArray.map((c, _cIdx) => {
        return {
          link: `${TABS.COACH_APP_HOME.path}${(temp += '/' + c)}`,
          label: c,
          _cIdx,
        };
      });
    }
    if (
      match.path.endsWith(`/${ROUTE_ELEMENTS.LEADERBOARD}/:name/:id`) ||
      match.path.endsWith(`/${ROUTE_ELEMENTS.LEADERBOARD}/:id`)
    ) {
      c.push({
        label: ROUTE_ELEMENTS.LEADERBOARD,
        link: TABS.EVENT_LEADERBOARD.path,
      });
    }
    if (
      _.isArray(teamData.data) &&
      teamData.data.length > 0 &&
      _.isObject(teamData.teamData) &&
      _.isString(teamData.teamData._id) &&
      teamData.teamData._id.length > 0 &&
      _.isString(teamData.teamData.name) &&
      teamData.teamData.name.length > 0
    ) {
      setShowingTeam(true);
      setShowingOrg(false);
      c.unshift({ label: teamData.teamData.name, link: TABS.COACH_TEAMS.path });
    } else {
      if (userData.isOwner === true) {
        setShowingOrg(true);
        c.unshift({
          label: `${orgData?.data?.title} Org.`,
          link: TABS.COACH_TEAMS.path,
        });
      } else if (
        _.isArray(userData?.data?.teams) &&
        userData?.data?.teams.length > 0
      ) {
        setShowingTeam(true);
        setShowingOrg(false);
        c.unshift({ label: 'All teams', link: TABS.COACH_TEAMS.path });
      } else {
        setShowingOrg(false);
      }
      setShowingTeam(false);
    }
    setCrumbs(c);
  }, [match, teamData]);

  if (!(_.isArray(crumbs) && crumbs.length > 1)) {
    return null;
  }
  return (
    <div className="breadcrumbs">
      {crumbs.map((p, index) => (
        <React.Fragment key={`breadcrumb-${index}-${_.kebabCase(p.label)}`}>
          <Link
            to={
              _.isString(p.link) && p.link.length > 0
                ? p.link
                : `/app/${crumbs
                    .map((c) => c.label)
                    .slice(showingTeam === true ? 1 : 0, index + 1)
                    .join('/')}`
            }
          >
            {_.startCase(_.toLower(p.label))}
          </Link>
          {index < crumbs.length - 1 && <FaAngleDoubleRight />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default withRouter(Breadcrumbs);
