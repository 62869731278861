import React, { Component } from 'react';
import './text-page.scss';
import { DOMAIN, EMAIL_ADDRESSES, PHONE_NUMBER } from '../config';

class Privacy extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div className="d-flex">
          <div className="container mt-9">
            <div className="mx-lg-auto text-page">
              <h1>Privacy Policy</h1>
              <p>Last modified: July 7, 2023</p>
              <h2>Introduction</h2>
              <p>
                6Tool LLC (“<strong>Company</strong>,” “<strong>we</strong>,” “
                <strong>our</strong>” or “<strong>us</strong>”) respect your
                privacy and are committed to protecting it through our
                compliance with this policy.
              </p>
              <p>
                This policy describes the types of information we may collect
                from you or that you may provide when you visit the Company’s
                website (available at <a href={DOMAIN}>{DOMAIN}</a>) and use
                Company’s app available on iOS and Android devices (the
                “Application”, and together with the website, the “Services”)
                and our practices for collecting, using, maintaining,
                protecting, and disclosing that information.
              </p>
              <p className="list-header">
                This policy applies to information we collect:
              </p>
              <ul>
                <li>On these Services.</li>
                <li>
                  In email, text, and other electronic messages between you and
                  us.
                </li>
              </ul>
              <p className="list-header">
                It does not apply to information collected by:
              </p>
              <ul>
                <li>
                  Us offline or through any other means, including on any other
                  website operated by Company or any third party; or
                </li>
                <li>
                  Any third party, including through any application or content
                  that may link to or be accessible from or through the
                  Services.
                </li>
              </ul>
              <p>
                Please read this policy carefully to understand our policies and
                practices regarding your information and how we will treat it.
                If you do not agree with our policies and practices, your choice
                is not to use our Services. By accessing or using these
                Services, you agree to this privacy policy. This policy may
                change from time to time (see Changes to Our Privacy Policy).
                Your continued use of these Services after we make changes is
                deemed to be acceptance of those changes, so please check the
                policy periodically for updates.
              </p>
              <h2>Children Under the Age of 13</h2>
              <p>
                Our Services may be used by children under the age of 13 only
                where a parent or guardian of such child has registered for the
                Services on behalf of such child as provided in the associated
                Terms of Use and thereby has consented to this Privacy Policy
                with respect to such child. We collect only such personal
                information of such child as is reasonably necessary for
                participation in the Services, we use such information only to
                provide the Services and we maintain such children’s personal
                information in a reasonably secure manner to protect its
                confidentiality, security, and integrity (subject to the sharing
                features of the Services). If personal information was collected
                from a child under the age of 13 we will delete that information
                upon request of a parent or guardian. To do so, please contact
                us at{' '}
                <a href={`mailto:${EMAIL_ADDRESSES.INFO}`}>
                  {EMAIL_ADDRESSES.INFO}
                </a>{' '}
                or{' '}
                <a href={`tel:${PHONE_NUMBER.TEL}`}>{PHONE_NUMBER.FORMATTED}</a>
                .
              </p>
              <h2>Information We Collect About You and How We Collect It</h2>
              <p className="list-header">
                We collect several types of information from and about users of
                our Services, including information:
              </p>
              <ul>
                <li>
                  By which you may be personally identified, such as name,
                  postal address, email address, telephone number, or any other
                  identifier by which you may be contacted online or offline (“
                  <strong>personal information</strong>”);
                </li>
                <li>
                  That is about you but individually does not identify you, such
                  as information you provide in connection with our
                  questionnaires provided as part of the Services; and/or
                </li>
                <li>
                  About your internet connection, the equipment you use to
                  access our Services, and usage details.
                </li>
              </ul>
              <p className="list-header">We collect this information:</p>
              <ul>
                <li>Directly from you when you provide it to us.</li>
                <li>
                  Automatically as you navigate through the site. Information
                  collected automatically may include usage details, IP
                  addresses, and information collected through cookies and other
                  tracking technologies (e.g., Google analytics).
                </li>
              </ul>
              <h3>Information You Provide to Us</h3>
              <p className="list-header">
                The information we collect on or through our Services may
                include:
              </p>
              <ul>
                <li>
                  Information that you provide by filling in forms on our
                  Services. This includes information provided at the time of
                  registering to use our Services, subscribing to our service,
                  your associations with Coaches and other Team Members and
                  completing questionnaires that are part of the Services. We
                  may also ask you for information when you report a problem
                  with our Services.
                </li>
                <li>
                  Records and copies of your correspondence (including email
                  addresses), if you contact us.
                </li>
                <li>
                  Details of subscription transactions you carry out through our
                  Services.
                </li>
              </ul>
              <p>
                You also may provide information to be published or displayed
                (hereinafter, “posted”) on areas of the Services available to
                other users, or transmitted to other users of the Services or
                third parties (collectively, “User Contributions”). Your User
                Contributions are posted on and transmitted to others at your
                own risk. Although we limit access to within your Team Members
                and Coach, please be aware that no security measures are perfect
                or impenetrable. Additionally, we cannot control the actions of
                other users of the Services with whom you may choose to share
                your User Contributions. Therefore, we cannot and do not
                guarantee that your User Contributions will not be viewed by
                unauthorized persons.
              </p>
              <h3>
                Information We Collect Through Automatic Data Collection
                Technologies
              </h3>
              <p className="list-header">
                As you navigate through and interact with our Services, we may
                use automatic data collection technologies to collect certain
                information about your equipment, browsing actions, and
                patterns, including:
              </p>
              <ul>
                <li>
                  Details of your visits to our Services, including traffic
                  data, location data, logs, and other communication data and
                  the resources that you access and use on the Services.
                </li>
                <li>
                  Information about your computer and internet connection,
                  including your IP address, operating system, and browser type.
                </li>
              </ul>
              <p className="list-header">
                The information we collect automatically is only statistical
                data and does not include personal information, but we may
                maintain it or associate it with personal information we collect
                in other ways or receive from third parties. It helps us to
                improve our Services and to deliver a better and more
                personalized service, including by enabling us to:
              </p>
              <ul>
                <li>Estimate our audience size and usage patterns.</li>
                <li>
                  Store information about your preferences, allowing us to
                  customize our Services according to your individual interests.
                </li>
                <li>Speed up your searches.</li>
                <li>Recognize you when you return to our Services.</li>
              </ul>
              <p className="list-header">
                The technologies we use for this automatic data collection may
                include:
              </p>
              <ul>
                <li>
                  <strong>Cookies (or browser cookies)</strong>. A cookie is a
                  small file placed on the hard drive of your computer. You may
                  refuse to accept browser cookies by activating the appropriate
                  setting on your browser. However, if you select this setting
                  you may be unable to access certain parts of our Services.
                  Unless you have adjusted your browser setting so that it will
                  refuse cookies, our system will issue cookies when you direct
                  your browser to our Services
                </li>
              </ul>
              <p>
                We do not collect personal information automatically, but we may
                tie this information to personal information about you that we
                collect from other sources or you provide to us.
              </p>
              <h2>
                Third-Party Use of Cookies and Other Tracking Technologies
              </h2>
              <p>
                Some content or applications on the Services may be served by
                third-parties. These third parties may use cookies alone or in
                conjunction with web beacons or other tracking technologies to
                collect information about you when you use our Services. The
                information they collect may be associated with your personal
                information or they may collect information, including personal
                information, about your online activities over time and across
                different websites and other online services. They may use this
                information to provide you with interest-based (behavioral)
                advertising or other targeted content.
              </p>
              <p>
                We do not control these third parties’ tracking technologies or
                how they may be used. If you have any questions about an
                advertisement or other targeted content, you should contact the
                responsible provider directly. For information about how you can
                opt out of receiving targeted advertising from many providers,
                (see Choices About How We Use and Disclose Your Information).
              </p>
              <h2>How We Use Your Information</h2>
              <p className="list-header">
                We use information that we collect about you or that you provide
                to us, including any personal information:
              </p>
              <ul>
                <li>To present our Services and its contents to you.</li>
                <li>
                  To provide you with information, products, or services that
                  you request from us.
                </li>
                <li>To fulfill any other purpose for which you provide it.</li>
                <li>
                  To provide you with notices about your subscription, including
                  expiration and renewal notices.
                </li>
                <li>
                  To carry out our obligations and enforce our rights arising
                  from any contracts entered into between you and us, including
                  for billing and collection.
                </li>
                <li>
                  To notify you about changes to our Services or any products or
                  services we offer or provide though it.
                </li>
                <li>
                  To allow you to participate in interactive features on our
                  Services.
                </li>
                <li>
                  In any other way we may describe when you provide the
                  information.
                </li>
                <li>For any other purpose with your consent.</li>
              </ul>
              <h2>Disclosure of Your Information</h2>
              <p>
                We may disclose aggregated information about our users, and
                information that does not identify any individual, without
                restriction.
              </p>
              <p className="list-header">
                We may disclose personal information that we collect or you
                provide as described in this privacy policy:
              </p>
              <ul>
                <li>
                  To contractors, service providers, and other third parties we
                  use to support our business and who are bound by contractual
                  obligations to keep personal information confidential and use
                  it only for the purposes for which we disclose it to them.
                </li>
                <li>
                  To a buyer or other successor in the event of a merger,
                  divestiture, restructuring, reorganization, dissolution, or
                  other sale or transfer of some or all of 6Tool LLC’s assets,
                  whether as a going concern or as part of bankruptcy,
                  liquidation, or similar proceeding, in which personal
                  information held by 6Tool LLC about our Services users is
                  among the assets transferred.
                </li>
                <li>
                  To fulfill the purpose for which you provide it, including
                  sharing with your Coach and other Team Members.
                </li>
                <li>
                  For any other purpose disclosed by us when you provide the
                  information.
                </li>
                <li>With your consent.</li>
              </ul>
              <p className="list-header">
                We may also disclose your personal information:
              </p>
              <ul>
                <li>
                  To comply with any court order, law, or legal process,
                  including to respond to any government or regulatory request.
                </li>
                <li>
                  To enforce or apply our <a href="/terms">Terms of Use</a>,
                  including for billing and collection purposes.
                </li>
                <li>
                  If we believe disclosure is necessary or appropriate to
                  protect the rights, property, or safety of 6Tool LLC, our
                  customers, or others.
                </li>
              </ul>
              <h2>Choices About How We Use and Disclose Your Information</h2>
              <p className="list-header">
                We strive to provide you with choices regarding the personal
                information you provide to us. We have created mechanisms to
                provide you with the following control over your information:
              </p>
              <ul>
                <li>
                  <strong>Tracking Technologies and Advertising</strong>. You
                  can set your browser to refuse all or some browser cookies, or
                  to alert you when cookies are being sent. If you disable or
                  refuse cookies, please note that some parts of these Services
                  may then be inaccessible or not function properly.
                </li>
              </ul>
              <p>
                We do not control third parties’ collection or use of your
                information to serve interest-based advertising. However these
                third parties may provide you with ways to choose not to have
                your information collected or used in this way. You can opt out
                of receiving targeted ads from members of the Network
                Advertising Initiative (“<strong>NAI</strong>”) on the NAI’s
                website.
              </p>
              <p>
                Residents of certain states, such as California, Nevada,
                Colorado, Connecticut, Virginia, and Utah may have additional
                personal information rights and choices. Please see Your State
                Privacy Rights for more information.
              </p>
              <h2>Accessing and Correcting Your Information</h2>
              <p>
                You can review and change your personal information by logging
                into the Services and visiting your account profile page.
              </p>
              <p>
                You may also send us an email at{' '}
                <a href={`mailto:${EMAIL_ADDRESSES.INFO}`}>
                  {EMAIL_ADDRESSES.INFO}
                </a>{' '}
                to request to delete any personal information that you have
                provided to us. We cannot delete your personal information
                except by also deleting your user account. We may not
                accommodate a request to change information if we believe the
                change would violate any law or legal requirement or cause the
                information to be incorrect.
              </p>
              <h2>Your State Privacy Rights</h2>
              <p>
                State consumer privacy laws may provide their residents with
                additional rights regarding our use of their personal
                information. To learn more about California residents’ privacy
                rights, visit our{' '}
                <a href="/privacy-ccpa">
                  Privacy Policy for California Residents
                </a>
                .
              </p>
              <p className="list-header">
                Colorado, Connecticut, Virginia, and Utah each provide their
                state residents with rights to:
              </p>
              <ul>
                <li>Confirm whether we process their personal information.</li>
                <li>Access and delete certain personal information.</li>
                <li>Data portability.</li>
                <li>
                  Opt-out of personal data processing for targeted advertising
                  and sales.
                </li>
              </ul>
              <p className="list-header">
                Colorado, Connecticut, and Virginia also provide their state
                residents with rights to:
              </p>
              <ul>
                <li>
                  Correct inaccuracies in their personal information, taking
                  into account the information’s nature processing purpose.
                </li>
                <li>
                  Opt-out of profiling in furtherance of decisions that produce
                  legal or similarly significant effects.
                </li>
              </ul>
              <p>
                To exercise any of these rights please contact us at{' '}
                <a href={`mailto:${EMAIL_ADDRESSES.INFO}`}>
                  {EMAIL_ADDRESSES.INFO}
                </a>{' '}
                or{' '}
                <a href={`tel:${PHONE_NUMBER.TEL}`}>{PHONE_NUMBER.FORMATTED}</a>
                .
              </p>
              <p>
                Nevada provides its residents with a limited right to opt-out of
                certain personal information sales. Residents who wish to
                exercise this sale opt-out rights may submit a request to this
                designated address:{' '}
                <a href={`mailto:${EMAIL_ADDRESSES.INFO}`}>
                  {EMAIL_ADDRESSES.INFO}
                </a>
                . However, please know we do not currently sell data triggering
                that statute’s opt-out requirements.
              </p>
              <h2>Data Security</h2>
              <p>
                We have implemented reasonable measures designed to secure your
                personal information from accidental loss and from unauthorized
                access, use, alteration, and disclosure. Any payment
                transactions will be encrypted.
              </p>
              <p>
                The safety and security of your information also depends on you.
                Where we have given you (or where you have chosen) a password
                for access to certain parts of our Services, you are responsible
                for keeping this password confidential. We ask you not to share
                your password with anyone.
              </p>
              <p>
                Unfortunately, the transmission of information via the internet
                is not completely secure. Although we do our best to protect
                your personal information, we cannot guarantee the security of
                your personal information transmitted to our Services. Any
                transmission of personal information is at your own risk. We are
                not responsible for circumvention of any privacy settings or
                security measures contained on the Services.
              </p>
              <h2>Changes to Our Privacy Policy</h2>
              <p>
                It is our policy to post any changes we make to our privacy
                policy on this page with a notice that the privacy policy has
                been updated on the Services home page. If we make material
                changes to how we treat our users’ personal information, we will
                notify you by email to the email address specified in your
                account and through a notice on the Services home page. The date
                the privacy policy was last revised is identified at the top of
                the page. You are responsible for ensuring we have an up-to-date
                active and deliverable email address for you, and for
                periodically visiting our Services and this privacy policy to
                check for any changes.
              </p>
              <h2>Contact Information</h2>
              <p className="list-header">
                To ask questions or comment about this privacy policy and our
                privacy practices, contact us at:{' '}
              </p>
              <p className="list-header">PO Box 1577</p>
              <p className="list-header">Middleburg, VA 20118</p>
              <p>
                <a href={`mailto:${EMAIL_ADDRESSES.INFO}`}>
                  {EMAIL_ADDRESSES.INFO}
                </a>
              </p>
              <p className="list-header">or via our toll-free number:</p>
              <p>
                <a href={`tel:${PHONE_NUMBER.TEL}`}>{PHONE_NUMBER.FORMATTED}</a>
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Privacy;
