import { ErrorMessage, Field, Form, Formik } from 'formik';
import { firebase } from '../..';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import {
  Col,
  Container,
  FormGroup,
  Nav,
  Row,
  Tab,
  TabContainer,
  TabContent,
  Form as BootstrapForm,
  FormLabel,
  InputGroup,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from 'react-bootstrap';
import {
  FaEnvelope,
  FaLock,
  FaPencilAlt,
  FaUser,
  FaUserLock,
} from 'react-icons/fa';
import { toast } from 'react-toastify';
import _ from 'lodash';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { CButton } from '@coreui/react';

const initialValues = {
  email: 'test@t.com',
  displayName: '',
  age: '',
  city: '',
  positions: [],
  classYear: '',
  youtube: '',
  baseballReports: '',
  twitter: '',
  instagram: '',
  type: '',
};

const SecuritySchema = Yup.object().shape({
  currentPassword: Yup.string().required('Current password is required'),
  newPassword: Yup.string()
    .notOneOf(
      [Yup.ref('currentPassword'), null],
      'New password must be different from old password'
    )
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/\d/, 'Password must contain at least one number')
    .min(8, 'New password must be at least 8 characters')
    .required('Required'),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});

const PasswordFields = ['Current Password', 'New Password', 'Confirm Password'];

const PasswordChangeModal = ({ show, onClose }) => {
  const currentUser = firebase.auth().currentUser;

  function onSubmit(values, { setSubmitting, resetForm }) {
    const { currentPassword, newPassword } = values;
    const credential = firebase.auth.EmailAuthProvider.credential(
      currentUser.email,
      currentPassword
    );
    currentUser
      .reauthenticateWithCredential(credential)
      .then(() => {
        currentUser
          .updatePassword(newPassword)
          .then(() => {
            resetForm();
            onClose(true);
            toast('Password updated successfully!', { type: 'success' });
          })
          .catch((error) => {
            toast(
              'Error updating password: ' +
                error.message
                  .replace('Firebase:', '')
                  .replace(/\(.*\)/g, '')
                  .replace('. .', '.')
                  .trim(),
              {
                type: 'error',
              }
            );
          });
      })
      .catch((error) => {
        toast(
          'Error updating password: ' +
            error.message
              .replace('Firebase:', '')
              .replace(/\(.*\)/g, '')
              .replace('. .', '.')
              .trim(),
          {
            type: 'error',
          }
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  return (
    <Modal show={show} scrollable={false} onClose={onClose} onHide={onClose}>
      <Formik
        initialValues={{
          email: currentUser?.email,
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
        }}
        validationSchema={SecuritySchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({ errors, touched, handleSubmit, isSubmitting, dirty }) => (
          <>
            <BootstrapForm onSubmit={handleSubmit}>
              <ModalHeader closeButton={true}>Update Password</ModalHeader>
              <ModalBody>
                <FormGroup>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <FaEnvelope />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Field
                      type="email"
                      name="email"
                      id="email"
                      disabled={true}
                      className={`form-control
                    `}
                    />
                  </InputGroup>
                </FormGroup>
                {PasswordFields.map((p) => {
                  let camel = _.camelCase(p);
                  return (
                    <FormGroup>
                      <FormLabel htmlFor={camel}>{p}</FormLabel>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>
                            {p.startsWith('Current') ? (
                              <FaUserLock />
                            ) : (
                              <FaLock />
                            )}
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Field
                          type="password"
                          name={camel}
                          id={camel}
                          placeholder="*************"
                          autoComplete={
                            p.startsWith('Current')
                              ? 'current-password'
                              : 'new-password'
                          }
                          className={`form-control ${
                            touched[camel] && errors[camel] ? 'is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          component={BootstrapForm.Control.Feedback}
                          name={camel}
                          className="invalid-feedback"
                        />
                      </InputGroup>
                    </FormGroup>
                  );
                })}
              </ModalBody>
              <ModalFooter>
                <CButton color="danger" variant="outline" onClick={onClose}>
                  Cancel
                </CButton>
                <Button className="" type="submit">
                  Update Password
                </Button>
              </ModalFooter>
            </BootstrapForm>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default PasswordChangeModal;
