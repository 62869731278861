import produce from 'immer';

import {
  IMPERSONATE_CLEAR,
  IMPERSONATE_CLEAR_TIER,
  IMPERSONATE_CLEAR_USER_ID,
  IMPERSONATE_SET_TIER,
  IMPERSONATE_SET_USER_ID,
} from './constants';
import { isString } from 'lodash';
import { SUBSCRIPTION_TIER } from '../../config';

const INITIAL_STATE = {
  tier: null,
  userID: null,
};

export const impersonateReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case IMPERSONATE_SET_TIER:
        return {
          ...state,
          tier:
            isString(action.payload) &&
            [
              SUBSCRIPTION_TIER.FREE.key.toLowerCase(),
              SUBSCRIPTION_TIER.REGULAR.key.toLowerCase(),
              SUBSCRIPTION_TIER.PREMIUM.key.toLowerCase(),
            ].includes(action.payload.trim().toLowerCase())
              ? action.payload.trim()
              : INITIAL_STATE.tier,
        };

      case IMPERSONATE_SET_USER_ID:
        return {
          ...state,
          userID: action.payload,
        };

      case IMPERSONATE_CLEAR_TIER:
        return {
          ...state,
          tier: INITIAL_STATE.tier,
        };

      case IMPERSONATE_CLEAR_USER_ID:
        return {
          ...state,
          userID: INITIAL_STATE.userID,
        };

      case IMPERSONATE_CLEAR:
        return {
          ...INITIAL_STATE,
        };

      default:
        return state;
    }
  });
