import CIcon from '@coreui/icons-react';
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CLink,
  CRow,
  CTooltip,
} from '@coreui/react';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  FaAngleLeft,
  FaCheckCircle,
  FaEnvelope,
  FaInfoCircle,
  FaLock,
  FaSearch,
  FaSyncAlt,
  FaTimes,
  FaTrashAlt,
  FaUser,
  FaUsers,
} from 'react-icons/fa';
import Spinner from '../../../src/pages/component/Spinner';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
// import { Button, FormGroup, FormLabel, Input, BootstrapForm.Control.Feedback } from "react-bootstrap";
import {
  Button,
  Form as BootstrapForm,
  FormGroup,
  FormLabel,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import { auth, functions } from '../..';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { TABS } from '../../AppTabs';
import { handleError } from '../../helpers/errors';
import SignupWrapper, { SETUP_STEPS } from './SignupWrapper';
import LoadingMessage from '../../r-components/LoadingMessage/LoadingMessage';
import FlexSpacer from '../../r-components/FlexSpacer/FlexSpacer';
import { _signInWithEmailAndPassword } from '../../helpers/authHelper';

const SignupSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  name: Yup.string().required('Name is required'),
  coachEmail: Yup.string().nullable().email('Invalid email').optional(),
  code: Yup.string()
    .nullable()
    .matches(/^[a-zA-Z0-9]{4}$/, 'Code must be four alphanumeric characters')
    .optional(),
  selectedTeams: Yup.array()
    .min(1, 'At least one team is required')
    .of(
      Yup.object().shape({
        id: Yup.string().required('One of your teams has an error'),
        organization_id: Yup.string().required(
          'One of your teams has an error (no-org-id)'
        ),
      })
    )
    .required('At least one team is required.'),
});

const SEARCH_TYPES = { BY_COACH_EMAIL: 0, BY_TEAM_CODE: 1 };

const SignupCreateCoach = ({ errors, setFieldValue, touched, values }) => {
  const history = useHistory();
  const [currentSearchType, setCurrentSearchType] = useState(
    SEARCH_TYPES.BY_TEAM_CODE
  );
  const [searchResults, setSearchResults] = useState([]);
  const [searchingForTeam, setSearchingForTeam] = useState(false);
  const [noResultsFound, setNoResultsFound] = useState(false);

  const searchForTeam = async ({ code = null, email = null }) => {
    setSearchingForTeam(true);
    setSearchResults([]);
    if (
      currentSearchType === SEARCH_TYPES.BY_TEAM_CODE &&
      _.isString(code) &&
      code.length === 4
    ) {
      let teamDataForTeamCodeCallable = functions.httpsCallable(
        'teamDataForTeamCodeCallable'
      );
      let teamIdResult = await teamDataForTeamCodeCallable({ code });
      setSearchResults([teamIdResult.data]);
      setNoResultsFound(
        !(_.isArray(teamIdResult.data) && teamIdResult.data.length > 0)
      );
    } else if (
      currentSearchType === SEARCH_TYPES.BY_COACH_EMAIL &&
      _.isString(email) &&
      email.length > 0
    ) {
      let teamDataForCoachEmailCallable = functions.httpsCallable(
        'teamDataForCoachEmailCallable'
      );
      let teamIdResultEmail = await teamDataForCoachEmailCallable({ email });
      setSearchResults(teamIdResultEmail.data);
      setNoResultsFound(
        !(
          _.isArray(teamIdResultEmail.data) && teamIdResultEmail.data.length > 0
        )
      );
    }
    setSearchingForTeam(false);
  };

  // const onSubmit = async (values, { setSubmitting }) => {
  //   setSubmitting(true);
  //   const createNewCoachUserCallable = functions.httpsCallable(
  //     'createNewCoachUserCallable'
  //   );
  //   try {
  //     try {
  //       const createNewCoachUserRequestResult =
  //         await createNewCoachUserCallable({
  //           email: values.email,
  //           name: values.name,
  //           password: values.password,
  //           team_requests: values.selectedTeams,
  //         });
  //       const { user_id, url } = createNewCoachUserRequestResult.data;
  //       await _signInWithEmailAndPassword(values.email, values.password);
  //     } catch (e) {
  //       handleError({
  //         error: e,
  //         toast: e.message.replace('Firebase:', '').trim(),
  //         internal: `SignupCreateCoach.js:onSubmit`,
  //       });
  //     }
  //   } catch (err) {
  //     handleError({
  //       error: err,
  //       toast: err.message.replace('FirebaseError:', '').trim(),
  //       internal: `SignupCreateCoach.js:onSubmit::catch`,
  //     });
  //   } finally {
  //     setSubmitting(false);
  //   }
  // };

  useEffect(() => {
    let timer;

    if (noResultsFound) {
      timer = setTimeout(() => {
        setNoResultsFound(false);
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [noResultsFound]);

  return (
    <>
      <CButtonGroup className="create-new-access-segmented-control" size="sm">
        <CButton
          onClick={() => {
            setCurrentSearchType(SEARCH_TYPES.BY_TEAM_CODE);
          }}
          className={
            currentSearchType === SEARCH_TYPES.BY_TEAM_CODE ? 'active' : ''
          }
        >
          Team Code
        </CButton>
        <CButton
          onClick={() => {
            setCurrentSearchType(SEARCH_TYPES.BY_COACH_EMAIL);
          }}
          className={
            currentSearchType === SEARCH_TYPES.BY_COACH_EMAIL ? 'active' : ''
          }
        >
          Coach's Email
        </CButton>
      </CButtonGroup>
      {currentSearchType === SEARCH_TYPES.BY_TEAM_CODE && (
        <FormGroup>
          <CTooltip content="Ask your head coach or organization owner to look at the team player page. It will show a code with four alpha-numeric characters. Use this to find your team and request access.">
            <FormLabel htmlFor="teams">
              4-digit Team Code{' '}
              <FaInfoCircle style={{ marginLeft: 2, marginTop: -3 }} />
            </FormLabel>
          </CTooltip>
          <InputGroup>
            <Field
              type="text"
              name="code"
              id="code"
              autoComplete="off"
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  if (!errors.code) {
                    searchForTeam({
                      code: (values.code || '').toString(),
                    });
                  }
                }
              }}
              onChange={(e) => {
                setSearchResults([]);
                var regex = /[^a-zA-Z0-9]/;
                let v = e.target.value.replace(regex, '');
                // if (
                //   regex.test(e.target.value) ||
                //   v.toString().length > 4
                // ) {
                //   toast(
                //     'Team codes are four characters of only letters and numbers',
                //     { type: 'info' }
                //   );
                // }
                setFieldValue(
                  'code',
                  (v.length <= 3 ? v : v.slice(0, 4)).toString().toUpperCase()
                );
              }}
              className={`form-control ${
                touched.code && errors.code ? 'is-invalid' : ''
              }`}
            />
            <InputGroup.Append
              className={`signup-create-account-search-append${
                _.isString(values.code) &&
                values.code.length === 4 &&
                !errors.code
                  ? ' visible'
                  : ''
              }`}
              onClick={() => {
                searchForTeam({
                  code: (values.code || '').toString(),
                });
              }}
            >
              <InputGroup.Text>
                <FaSearch />
              </InputGroup.Text>
            </InputGroup.Append>
            <ErrorMessage
              component={BootstrapForm.Control.Feedback}
              name="code"
              className="invalid-feedback"
            />
          </InputGroup>
        </FormGroup>
      )}
      {currentSearchType === SEARCH_TYPES.BY_COACH_EMAIL && (
        <FormGroup>
          <CTooltip content="Enter the head coach's email to find your team.">
            <FormLabel htmlFor="teams">
              Coach's Email{' '}
              <FaInfoCircle style={{ marginLeft: 2, marginTop: -3 }} />
            </FormLabel>
          </CTooltip>
          <InputGroup>
            <Field
              type="email"
              name="coachEmail"
              id="coachEmail"
              autoComplete="off"
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  if (!errors.coachEmail) {
                    searchForTeam({ email: values.coachEmail });
                  }
                }
              }}
              onChange={(e) => {
                setSearchResults([]);
                setFieldValue('coachEmail', e.target.value.trim());
              }}
              className={`form-control ${
                touched.coachEmail && errors.coachEmail ? 'is-invalid' : ''
              }`}
            />
            <InputGroup.Append
              className={`signup-create-account-search-append${
                _.isString(values.coachEmail) &&
                values.coachEmail.length > 0 &&
                !errors.coachEmail
                  ? ' visible'
                  : ''
              }`}
              onClick={() => {
                searchForTeam({ email: values.coachEmail });
              }}
            >
              <InputGroup.Text>
                <FaSearch />
              </InputGroup.Text>
            </InputGroup.Append>
            <ErrorMessage
              component={BootstrapForm.Control.Feedback}
              name="coachEmail"
              className="invalid-feedback"
            />
          </InputGroup>
        </FormGroup>
      )}
      {searchingForTeam && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 8,
          }}
        >
          <FaSyncAlt
            style={{ marginRight: 4, marginTop: -3 }}
            className="spinning-icon"
          />
          <div>Searching for teams...</div>
        </div>
      )}
      {noResultsFound && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 8,
          }}
        >
          <div>No teams found</div>
        </div>
      )}
      <div className={`signup-create-account-suggested-teams`}>
        <FieldArray name="selectedTeams">
          {({ push, remove }) => (
            <>
              {(values.selectedTeams || []).map((t, index) => (
                <div
                  className="suggested-team selected"
                  key={`selected-team-${index}`}
                >
                  <div className="suggested-team-details">
                    <div>
                      {t.name}
                      {_.isObject(t.location) &&
                      _.isString(t.location.city) &&
                      t.location.city.length > 0 &&
                      _.isString(t.location.state) &&
                      t.location.state.length > 0
                        ? ` - ${t.location?.city}, ${t.location?.state}`
                        : ''}
                    </div>
                    <div style={{ flex: 1 }}></div>
                    <div style={{ fontSize: 12 }}>will request access</div>
                    <CTooltip content="remove access request">
                      <FaTimes
                        className="suggested-team-remove-selected"
                        size={24}
                        onClick={() => {
                          remove(t);
                        }}
                      />
                    </CTooltip>
                  </div>
                </div>
              ))}
              {(
                [...(searchResults || [])]
                  .filter(
                    (team) =>
                      !(values.selectedTeams || [])
                        .map((t) => t.id)
                        .includes(team.id) &&
                      _.isString(team.id) &&
                      team.id.length > 0
                  )
                  .sort((a, b) => a.name.localeCompare(b.name)) || []
              ).map((t, index) => (
                <CTooltip
                  key={`suggested-team-unselected-${index}`}
                  content="click to request access"
                >
                  <CLink
                    className="suggested-team"
                    onClick={() => {
                      push(t);
                      // toggleSelectedTeam(t);
                    }}
                  >
                    <div className="suggested-team-details">
                      {t.name} - {t.location?.city}, {t.location?.state}
                    </div>
                    <FaCheckCircle />
                  </CLink>
                </CTooltip>
              ))}
            </>
          )}
        </FieldArray>
        {/* {(selectedTeams || []).map((t) => (
                      <>
                        <CLink
                          className="suggested-team selected"
                          onClick={() => {
                            toggleSelectedTeam(t);
                          }}
                        >
                          <div className="suggested-team-details">
                            {t.name} - {t.location?.city}, {t.location?.state}
                          </div>
                          <FaCheckCircle color="green" />
                        </CLink>
                      </>
                    ))} */}
      </div>
      <FlexSpacer />
      {!!errors.selectedTeams && (
        <div className="selected-team-error-message">
          {_.isString(errors.selectedTeams) &&
            errors.selectedTeams.length > 0 && (
              <div>{errors.selectedTeams}</div>
            )}
          {_.isArray(errors.selectedTeams) &&
            errors.selectedTeams.length > 0 && (
              <>
                <div>Errors:</div>
                <ul>
                  {errors.selectedTeams.map((eo) => (
                    <>
                      {(Object.values(eo || {}) || []).map((e) => (
                        <li>{e}</li>
                      ))}
                    </>
                  ))}
                </ul>
              </>
            )}
        </div>
      )}
    </>
  );
};

export default SignupCreateCoach;
