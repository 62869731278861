import React from 'react';
// import Path, { Svg, Circle, G, Polygon, Line } from 'react-svg-path';
// import Svg, {
//     Circle,
//     G,
//     Path,
//     Polygon,
//     Line,
//   } from 'react-native-svg';

const BallField = ({
  size = 20,
  positionHighlights,
  editable,
  togglePosition,
}) => (
  <>
    <svg
      className="ball-field"
      width={(size * 23) / 20}
      height={size}
      viewBox="30 70 340 240"
    >
      <g fill="#448e4a" stroke="none">
        <path d="M38,171 A170,170 0 0,1 362,171" />
        <polygon points="38,170 362,170 200,330" />
      </g>
      <g fill="#bf7e59" stroke="none">
        <path d="M112,228 A90,90 0 0,1 288,228" />
        <polygon points="112,228 288,228 200,312" />
        <circle cx="200" cy="300" r="15" />
      </g>
      <polygon
        points="195,215 205,215 235,245 235,255 205,285 195,285 165,255 165,245"
        fill="#448e4a"
      />
      <circle fill="#bf7e59" cx="200" cy="253" r="8" />
      <line
        stroke="white"
        strokeWidth="2"
        x1="196"
        y1="253"
        x2="204"
        y2="253"
      />
      <g fill="white">
        <polygon points="150,250 156,244 162,250 156,256" />
        <polygon points="200,200 194,206 200,212 206,206" />
        <polygon points="250,250 244,244 238,250 244,256" />
        <polygon points="200,300 194,294 194,288 206,288 206,294" />
      </g>
      <g stroke="white" strokeWidth="2">
        <line x1="185" y1="285" x2="50" y2="150" />
        <line x1="215" y1="285" x2="350" y2="150" />
      </g>
      <g fill="yellow">
        {positionHighlights?.includes(1) && (
          <circle
            cx="200"
            cy="253"
            r="15"
            className={`${
              editable ? 'ball-field-editable-position selected-position' : ''
            }`}
          />
        )}
        {positionHighlights?.includes(2) && (
          <circle
            cx="200"
            cy="308"
            r="15"
            className={`${
              editable ? 'ball-field-editable-position selected-position' : ''
            }`}
          />
        )}
        {positionHighlights?.includes(3) && (
          <circle
            cx="262"
            cy="218"
            r="15"
            className={`${
              editable ? 'ball-field-editable-position selected-position' : ''
            }`}
          />
        )}
        {positionHighlights?.includes(4) && (
          <circle
            cx="235"
            cy="190"
            r="15"
            className={`${
              editable ? 'ball-field-editable-position selected-position' : ''
            }`}
          />
        )}
        {positionHighlights?.includes(5) && (
          <circle
            cx="138"
            cy="218"
            r="15"
            className={`${
              editable ? 'ball-field-editable-position selected-position' : ''
            }`}
          />
        )}
        {positionHighlights?.includes(6) && (
          <circle
            cx="165"
            cy="190"
            r="15"
            className={`${
              editable ? 'ball-field-editable-position selected-position' : ''
            }`}
          />
        )}
        {positionHighlights?.includes(7) && (
          <circle
            cx="115"
            cy="145"
            r="15"
            className={`${
              editable ? 'ball-field-editable-position selected-position' : ''
            }`}
          />
        )}
        {positionHighlights?.includes(8) && (
          <circle
            cx="200"
            cy="115"
            r="15"
            className={`${
              editable ? 'ball-field-editable-position selected-position' : ''
            }`}
          />
        )}
        {positionHighlights?.includes(9) && (
          <circle
            cx="285"
            cy="145"
            r="15"
            className={`${
              editable ? 'ball-field-editable-position selected-position' : ''
            }`}
          />
        )}
        {positionHighlights?.includes(21) && (
          <polygon
            points="262,238 244,220 226,238 244,256" // R, T, L, B 250,250 244,244 238,250 244,256
            className={`${
              editable ? 'ball-field-editable-position selected-position' : ''
            }`}
          />
        )}
        {positionHighlights?.includes(22) && (
          <polygon
            points="200,176 182,194 200,212 218,194" // T, L, B, R
            className={`${
              editable ? 'ball-field-editable-position selected-position' : ''
            }`}
          />
        )}
        {positionHighlights?.includes(23) && (
          <polygon
            points="138,238 156,220 174,238 156,256" // L, T, R, B
            className={`${
              editable ? 'ball-field-editable-position selected-position' : ''
            }`}
          />
        )}
        {positionHighlights?.includes(30) && (
          <polygon
            points="200,312 182,294 182,274 218,274 218,294" // B, L, TL, TR, R "200,300 194,294 194,288 206,288 206,294"
            className={`${
              editable ? 'ball-field-editable-position selected-position' : ''
            }`}
          />
        )}
      </g>
      {editable && (
        <g stroke="yellow" fill="rgba(0,0,0,0)" style={{ cursor: 'pointer' }}>
          <polygon
            points="262,238 244,220 226,238 244,256"
            className="ball-field-editable-position"
            onClick={() => togglePosition(21)}
          />
          <polygon
            points="200,176 182,194 200,212 218,194"
            className="ball-field-editable-position"
            onClick={() => togglePosition(22)}
          />
          <polygon
            points="138,238 156,220 174,238 156,256"
            className="ball-field-editable-position"
            onClick={() => togglePosition(23)}
          />
          <polygon
            points="200,312 182,294 182,274 218,274 218,294"
            className="ball-field-editable-position"
            onClick={() => togglePosition(30)}
          />
          <circle
            cx="200"
            cy="253"
            r="15"
            className="ball-field-editable-position"
            onClick={() => togglePosition(1)}
          />
          <circle
            cx="200"
            cy="308"
            r="15"
            className="ball-field-editable-position"
            onClick={() => togglePosition(2)}
          />
          <circle
            cx="262"
            cy="218"
            r="15"
            className="ball-field-editable-position"
            onClick={() => togglePosition(3)}
          />
          <circle
            cx="235"
            cy="190"
            r="15"
            className="ball-field-editable-position"
            onClick={() => togglePosition(4)}
          />
          <circle
            cx="138"
            cy="218"
            r="15"
            className="ball-field-editable-position"
            onClick={() => togglePosition(5)}
          />
          <circle
            cx="165"
            cy="190"
            r="15"
            className="ball-field-editable-position"
            onClick={() => togglePosition(6)}
          />
          <circle
            cx="115"
            cy="145"
            r="15"
            className="ball-field-editable-position"
            onClick={() => togglePosition(7)}
          />
          <circle
            cx="200"
            cy="115"
            r="15"
            className="ball-field-editable-position"
            onClick={() => togglePosition(8)}
          />
          <circle
            cx="285"
            cy="145"
            r="15"
            className="ball-field-editable-position"
            onClick={() => togglePosition(9)}
          />
        </g>
      )}
    </svg>
  </>
);

export default BallField;
