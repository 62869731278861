import { SET_CURRENT_EXAMS, CLEAR_EXAMS } from './constants';

/**
 * Set current user
 */
export function setCurrentExams(payload) {
  return {
    type: SET_CURRENT_EXAMS,
    payload,
  };
}
export function clearExams() {
  return {
    type: CLEAR_EXAMS,
  };
}
