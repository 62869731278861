import { SET_CURRENT_GROUPS, CLEAR_GROUPS } from './constants';

/**
 * Set current user
 */
export function setCurrentGroups(payload) {
  return {
    type: SET_CURRENT_GROUPS,
    payload,
  };
}
export function clearGroups() {
  return {
    type: CLEAR_GROUPS,
  };
}
