import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CLabel,
  CListGroup,
  CListGroupItem,
  CRow,
  CSpinner,
  CTooltip,
} from '@coreui/react';
import '../teams/team.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import _ from 'lodash';
import { useLocation, useHistory } from 'react-router-dom';

import { db, firebase, functions } from '../../..';
import moment from 'moment';
import SubscriptionOverviewCard from '../../../r-components/Subscription/SubscriptionOverviewCard';
import SubscriptionInvoices from '../../../r-components/Subscription/SubscriptionInvoices';
import { handleError } from '../../../helpers/errors';
import ProfileWrapper from './ProfileWrapper';
import { TABS } from '../../../AppTabs';
import ProfileSubscriptionInactiveUser from './ProfileSubscriptionInactiveUser';
import { DOMAIN, EMAIL_ADDRESSES, STRIPE } from '../../../config';
import { toast } from 'react-toastify';

const ProfileSubscription = () => {
  const [loadingSubscriptions, setLoadingSubscriptions] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [numberOfTeams, setNumberOfTeams] = useState(1);
  const userData = useSelector((state) => state?.UserState);
  const subscription = useSelector(
    (state) => state?.DataSubscriptionState || {}
  );
  const isFriendly = useSelector(
    (state) => state?.DataSubscriptionState?.current?.id === 'sub_friendly'
  );
  const inactive = false;
  // useSelector(
  //   (state) => state?.DataSubscriptionState?.inactive === true
  // );

  const teams = useSelector((state) => state.DataTeamState?.data || []);
  const [memberSinceString, setMemberSinceString] = useState('?');
  const [renewsOnString, setRenewsOnString] = useState('?');

  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get('source');

  useEffect(() => {
    switch (source) {
      case STRIPE.redirects.back_from_pay:
        toast(
          'If you made changes to your subscription, they may take a moment to update in the system.',
          {
            type: 'success',
          }
        );
        history.replace(history.location.pathname);
        break;

      default:
        break;
    }
  }, [source]);

  useEffect(() => {
    if (!isNaN(subscription?.memberSince)) {
      setMemberSinceString(
        moment
          .unix(parseInt(subscription.memberSince))
          .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
          .format('M/D/YY')
      );
    }
    if (!isNaN(subscription?.current?.current_period_end?.seconds)) {
      setRenewsOnString(
        moment
          .unix(parseInt(subscription.current.current_period_end.seconds))
          .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
          .format('M/D/YY')
      );
    }
  }, [subscription]);

  const goToBillingPortal = async () => {
    const getBillingPortalLinkForOrganizationIDCallable =
      functions.httpsCallable('getBillingPortalLinkForOrganizationIDCallable');
    const getBillingPortalLinkForOrganizationIDResult =
      await getBillingPortalLinkForOrganizationIDCallable({
        organization_id: userData.organizationId,
        return_url: `${DOMAIN}${TABS.OWNER_PROFILE_SUBSCRIPTION.path}`,
      });
    if (
      _.isString(getBillingPortalLinkForOrganizationIDResult?.data) &&
      getBillingPortalLinkForOrganizationIDResult.data.length > 0
    ) {
      history.push(getBillingPortalLinkForOrganizationIDResult.data);
    } else {
      handleError({
        toast: `Error generating link to billing portal. Please contact ${EMAIL_ADDRESSES.SUPPORT}`,
        internal: 'ProfileSubscription.js - goToBillingPortal',
      });
    }
  };

  return (
    <ProfileWrapper currentProfileTab="SUBSCRIPTION">
      {isFriendly ? (
        <div>
          We hope you are enjoying your complimentary 6Tool subscription!
        </div>
      ) : (
        <>
          <SubscriptionOverviewCard
            teams={teams}
            subscription={subscription}
            editable={true}
          />
          {userData?.isOwner === true && (
            <SubscriptionInvoices invoices={subscription.invoices} />
          )}
        </>
      )}
    </ProfileWrapper>
  );
};

export default ProfileSubscription;
