import { viewGroups, addGroups } from '../../actions/groupActionCreator';

export const groups = [
  {
    content: `Welcome to 6-Tool!`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}`,
  },
  {
    dispatch: [viewGroups],
    selector: 'button#groups-add-group-button',
    content: `Click Create new group`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/groups`,
    navigationAction: 'groups-page-click-create-group',
  },
  {
    dispatch: [addGroups],
    selector: '.main-content .card',
    content: `Create a title for the group. Some ideas are: "Pitchers", "Hitters", "Infielders", "Captains", "1st Years", etc. The groups can be used later to send a quiz to only the members of a specific group. If you have a quiz for pitchers, there is no point in sending it to all your hitters. Creating groups will help you distribute your quizzes only to the players you want.`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/groups`,
  },
  {
    dispatch: [addGroups],
    selector: '.main-content .card .card-body button.btn',
    content: `You can add players to the group now, or wait until later and add them.`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/groups`,
  },
  {
    dispatch: [addGroups],
    selector: '.main-content .card button[type="button"].btn-primary',
    content: `Click Create to create the group. Players will not know what groups they are in. This is only used for your organization to be able to easily send out quiz invites.`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/groups`,
  },
];
