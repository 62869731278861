import produce from 'immer';
import _ from 'lodash';
import { addPlayers, viewPlayers } from '../../actions/playerActionCreator';
import { viewGroups, addGroups } from '../../actions/groupActionCreator';
import { players } from './tourPlayers';
import { groups } from './tourGroups';
import { quizzes } from './tourQuizzes';
import { questions } from './tourQuestions';

import {
  SET_CURRENT_TOUR,
  SET_CURRENT_TOUR_STEP,
  TRIGGER_TOUR_NAVIGATION,
} from './constants';

const INITIAL_STATE = {
  currentTour: null,
  steps: null,
  currentTourStep: 0,
  navigationFunction: null,
  dispatchFunctions: null,
  initDispatches: null, // not functional yet since some initializers aren't functions
};

const tourSteps = {
  players,
  groups,
  quizzes,
  questions,
};

const initDispatchesByName = ({ name = null } = {}) => {
  // not functional yet since some initializers aren't functions
  switch (name) {
    case 'onboard':
      return [viewPlayers];
    default:
      return [];
  }
};

export const tourReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    let dispatchFunctions = null;
    switch (action.type) {
      case SET_CURRENT_TOUR:
        if (
          _.isString(action.payload?.tourName) &&
          action.payload.tourName.length > 0
        ) {
          return {
            ...INITIAL_STATE,
            steps: tourSteps[action.payload?.tourName || ''],
            name: action.payload?.tourName || '',
            currentTourStep: action.payload?.currentTourStep || 0,
            navigationFunction: action.payload?.navigationFunction,
            initDispatches: initDispatchesByName({
              // not functional yet since some initializers aren't functions
              name: action.payload?.tourName,
            }),
          };
        }
        return INITIAL_STATE;

      case SET_CURRENT_TOUR_STEP:
        if (
          _.isNumber(action.payload) &&
          _.isString(state.name) &&
          state.name.length > 0 &&
          _.isArray(tourSteps[state.name]) &&
          tourSteps[state.name].length > action.payload &&
          _.isString(tourSteps[state.name][action.payload].url) &&
          tourSteps[state.name][action.payload].url.length > 0
        ) {
          if (
            !window.location.pathname.includes(
              tourSteps[state.name][action.payload].url
            )
          ) {
            if (_.isFunction(state.navigationFunction)) {
              state.navigationFunction(
                tourSteps[state.name][action.payload].url
              );
            }
          }
        }

        if (
          _.isNumber(action.payload) &&
          _.isString(state.name) &&
          state.name.length > 0 &&
          _.isArray(tourSteps[state.name]) &&
          tourSteps[state.name].length > action.payload &&
          _.isArray(tourSteps[state.name][action.payload].dispatch)
        ) {
          dispatchFunctions = tourSteps[state.name][action.payload].dispatch;
        }
        return {
          ...state,
          dispatchFunctions,
          currentTourStep: action.payload,
        };
      case TRIGGER_TOUR_NAVIGATION:
        console.log('TRIGGER_TOUR_NAVIGATION');
        if (
          _.isString(action.payload.tourName) &&
          action.payload.tourName.length > 0 &&
          _.isString(action.payload.navigationAction) &&
          action.payload.navigationAction.length > 0
        ) {
          if (
            _.isArray(tourSteps[action.payload.tourName]) &&
            tourSteps[action.payload.tourName].length > 0
          ) {
            let step = null;
            let stepIndex = tourSteps[action.payload.tourName].findIndex(
              (s) => s.navigationAction === action.payload.navigationAction
            );
            if (stepIndex >= 0) {
              step = tourSteps[action.payload.tourName][stepIndex];
            }
            // if (_.isObject(step) && _.isFunction(step.navigate)) {
            //   step.navigate(action.payload.navigationParameters);
            // }
            return {
              ...state,
              currentTourStep:
                stepIndex +
                (_.isNumber(action.payload.stepOffset)
                  ? action.payload.stepOffset
                  : 1),
            };
          }
        }
        return {
          ...state,
        };

      default:
        return state;
    }
  });
