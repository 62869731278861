import React, { Component } from 'react';

class StatusCheck extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <p>
          Status: OK for {process.env.REACT_APP_PROJECT_ID} in{' '}
          {process.env.NODE_ENV}.
        </p>
        {/* <p>{JSON.stringify(process.env)}</p> */}
      </div>
    );
  }
}

export default StatusCheck;
