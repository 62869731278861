import {
  IMPERSONATE_CLEAR,
  IMPERSONATE_CLEAR_TIER,
  IMPERSONATE_CLEAR_USER_ID,
  IMPERSONATE_SET_TIER,
  IMPERSONATE_SET_USER_ID,
} from './constants';

/**
 * Impersonate current user or tier
 */
export function impersonateTier(payload) {
  return {
    type: IMPERSONATE_SET_TIER,
    payload,
  };
}

export function impersonateUserID(payload) {
  return {
    type: IMPERSONATE_SET_USER_ID,
    payload,
  };
}

export function clearImpersonateUserID(payload) {
  return {
    type: IMPERSONATE_CLEAR_USER_ID,
    payload,
  };
}

export function clearImpersonateTier(payload) {
  return {
    type: IMPERSONATE_CLEAR_TIER,
    payload,
  };
}

export function clearImpersonate() {
  return {
    type: IMPERSONATE_CLEAR,
  };
}
