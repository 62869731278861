import React from 'react';
import _ from 'lodash';

const TeamCellInDataTable = ({ item, teamData }) => {
  return (
    <td>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 6,
        }}
      >
        {_.isObject(teamData) &&
        _.isObject(teamData.map) &&
        _.isObject(teamData.map[item.teamId]) &&
        _.isString(teamData.map[item.teamId].imageUrl) &&
        teamData.map[item.teamId].imageUrl.length > 0 ? (
          <div>
            <img
              src={teamData.map[item.teamId].imageUrl}
              style={{
                height: 20,
                width: 20,
                objectFit: 'contain',
                marginRight: 6,
                marginTop: -2,
              }}
            />
            {_.isObject(teamData.map) &&
            _.isObject(teamData.map[item.teamId]) &&
            _.isString(teamData?.map[item.teamId].name)
              ? teamData.map[item.teamId].name
              : ''}
          </div>
        ) : (
          <div>
            {_.isObject(teamData) &&
            _.isObject(teamData.map) &&
            _.isObject(teamData.map[item.teamId]) &&
            _.isString(teamData?.map[item.teamId].name)
              ? teamData.map[item.teamId].name
              : ''}
          </div>
        )}
      </div>
    </td>
  );
};

export default TeamCellInDataTable;
