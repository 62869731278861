import { CButton, CCol, CRow } from '@coreui/react';
import React from 'react';
import './ScheduleDays.scss';
export const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

const ScheduleDays = ({ scheduledDays, toggleScheduledDay }) => (
  <CRow>
    {Array.from({ length: 7 }, (_, index) => index).map((day, dIdx) => (
      <CCol key={dIdx} className={'schedule-choose-day-column'}>
        <CButton
          variant={scheduledDays.includes((day + 1) % 7) ? null : 'outline'}
          color={scheduledDays.includes((day + 1) % 7) ? 'success' : 'light'}
          style={
            scheduledDays.includes((day + 1) % 7) ? { color: 'white' } : {}
          }
          onClick={() => {
            toggleScheduledDay((day + 1) % 7);
          }}
        >
          {daysOfWeek[(day + 1) % 7]}
        </CButton>
      </CCol>
    ))}
  </CRow>
);

export default ScheduleDays;
