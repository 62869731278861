import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { CDataTable, CButton, CLink } from '@coreui/react';
import {
  FaDownload,
  FaInfoCircle,
  FaTrashAlt,
  FaTrashRestore,
  FaUpload,
} from 'react-icons/fa';
import './SuperAdminUploadUsers.scss';
import { functions } from '../..';
import { isArray, omit, pick } from 'lodash';
import ReactSelect from 'react-select';
import { isString } from 'lodash';
import { toast } from 'react-toastify';
import FlexSpacer from '../../r-components/FlexSpacer/FlexSpacer';
import ConfirmModal from '../../r-components/ConfirmModal/ConfirmModal';
import Toggle from '../../r-components/Toggle/Toggle';

const downloadTemplateText = 'Download template';

function SuperAdminUploadUsers() {
  const [data, setData] = useState([]);
  const [loadingOrgs, setLoadingOrgs] = useState(true);
  const [loadingTeams, setLoadingTeams] = useState(true);
  const [orgs, setOrgs] = useState([]);
  const [teams, setTeams] = useState([]);
  const [orgOptions, setOrgOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState('');
  const [selectedOrgName, setSelectedOrgName] = useState('');
  const [selectedTeamId, setSelectedTeamId] = useState('');
  const [allItemsToUploadCount, setAllItemsToUploadCount] = useState(0);
  const [uploadFormValid, setUploadFormValid] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [suppressAutoEmail, setSuppressAutoEmail] = useState(true);

  useEffect(() => {
    setUploadFormValid(
      isString(selectedOrgId) &&
        selectedOrgId.length > 1 &&
        isString(selectedTeamId) &&
        selectedTeamId.length > 1
    );
  }, [selectedOrgId, selectedTeamId]);

  useEffect(() => {
    if (isString(selectedOrgId) && selectedOrgId.length > 1) {
      const updatedData = data.map((item) => {
        if (!item.organization_id) {
          return { ...item, organization_id: selectedOrgId };
        }
        return { ...item };
      });
      console.log({ updatedData });
      setData(updatedData);
    }
  }, [selectedOrgId]);

  useEffect(() => {
    if (isString(selectedTeamId) && selectedTeamId.length > 1) {
      const updatedData = data.map((item) => {
        if (!(isArray(item.teams) && item.teams.length > 0)) {
          return { ...item, teams: [selectedTeamId] };
        }
        return { ...item };
      });
      console.log({ updatedData });
      setData(updatedData);
    }
  }, [selectedTeamId]);

  useEffect(() => {
    console.log({ data });
  }, [data]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const arrayBuffer = e.target.result;
      const workbook = XLSX.read(arrayBuffer, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 2,
        skipHidden: true,
      });
      console.log(jsonData);
      setData(
        jsonData.map((d) => {
          return {
            ...pick(d, ['name', 'email']),
            ...{ role: 'player', password: '6Tool!' },
            ...omit(d, ['name', 'email']),
            ...{ teams: d?.teams?.split(',') },
            enabled: true,
            _classes: 'enabled',
          };
        })
      );
    };
    if (!!file) {
      reader.readAsArrayBuffer(file);
    }
  };

  const toggleEnabled = (index) => {
    const newData = data.map((row, i) =>
      i === index
        ? {
            ...row,
            enabled: !row.enabled,
            _classes: row.enabled ? 'disabled' : 'enabled',
          }
        : row
    );
    setData(newData);
  };

  const uploadAll = async () => {
    // if (!uploadFormValid) {
    //   toast('First select an organization and team', { type: 'error' });
    //   return;
    // }
    const uploadData = [...data];
    for (let index = 0; index < uploadData.length; index++) {
      const item = uploadData[index];
      if (item.enabled !== false) {
        await uploadUser({
          ...{ index },
          ...pick(item, [
            'name',
            'email',
            'password',
            'role',
            'teams',
            'organization_id',
          ]),
        });
      }
    }
    uploadData.forEach((item, index) => {});
    // console.log('upload all');
  };

  const uploadUser = async ({
    index,
    name,
    email,
    password = '6Tool!',
    role = 'player',
    teams,
    organization_id,
  }) => {
    // if (!uploadFormValid) {
    //   toast('First select an organization and team', { type: 'error' });
    //   return;
    // }

    const createNewUserCallable = functions.httpsCallable(
      role === 'coach' || role === 'assistant' || role === 'owner'
        ? 'createNewCoachUserCallable'
        : 'createNewPlayerUserCallable'
    );
    const org_team = { [organization_id]: {} };
    (teams || []).forEach((team_id) => {
      org_team[organization_id][team_id] = { roles: role.split(',') };
    });
    const orgName = orgTitleFromOrgId(organization_id);
    const createNewUserResult = await createNewUserCallable({
      name,
      email,
      password,
      org_team,
      orgName,
      organization_id: organization_id,
      suppressAutoEmail,
    });
    // console.log(
    //   role === 'coach'
    //     ? 'createNewCoachUserCallable'
    //     : 'createNewPlayerUserCallable',
    //   {
    //     name,
    //     email,
    //     password,
    //     org_team,
    //     orgName,
    //     organization_id: organization_id,
    //   }
    // );
    // console.log({ createNewUserResult });
    if (
      isString(createNewUserResult?.data) &&
      createNewUserResult.data.length > 1
    ) {
      toast(`Created ${role} ${name}`);
      const newData = data.filter((_, i) => i !== index);
      await setData(newData);
    } else {
      toast(`Error creating ${role} ${name}`);
    }
  };
  const fields = ['teams', 'name', 'email', 'password', 'role'];

  // const fields = [
  //   ...['teams'],
  //   ...(data.length > 0
  //     ? Object.keys(data[0])
  //         .filter(
  //           (k) =>
  //             k !== 'enabled' &&
  //             k !== '_classes' &&
  //             k !== 'teams' &&
  //             k !== 'organization_id'
  //         )
  //         .map((key, index) => ({
  //           key,
  //           label: key,
  //         }))
  //     : []),
  // ];

  const handleOrgSelect = (selectedOption) => {
    setSelectedOrgId(selectedOption.value);
    setSelectedOrgName(selectedOption.label);
  };

  const handleTeamSelect = (selectedOption) => {
    setSelectedTeamId(selectedOption.value);
  };

  useEffect(() => {
    fetchOrgs();
  }, []);

  useEffect(() => {
    console.log({ orgs });
  }, [orgs]);

  const orgTitleFromOrgId = (organization_id) => {
    const targetOrg = orgs?.find((obj) => obj.id === organization_id);
    return targetOrg?.title || '';
  };

  const fetchOrgs = async () => {
    try {
      const allOrgsCallable = functions.httpsCallable('allOrgsCallable');
      const allOrgsCallableResult = await allOrgsCallable();
      setOrgs(
        isArray(allOrgsCallableResult?.data?.organizations) &&
          allOrgsCallableResult.data.organizations.length > 0
          ? allOrgsCallableResult.data.organizations
          : []
      );
    } catch (error) {
      console.log('here');
      console.error(error);
      setOrgs([]);
    }
  };
  const fetchTeams = async () => {
    try {
      const allTeamsCallable = functions.httpsCallable('allTeamsCallable');
      const allTeamsCallableResult = await allTeamsCallable({
        organization_id: selectedOrgId,
      });
      setTeams(
        isArray(allTeamsCallableResult?.data?.teams) &&
          allTeamsCallableResult.data.teams.length > 0
          ? allTeamsCallableResult.data.teams
          : []
      );
    } catch (error) {
      console.log('here');
      console.error(error);
      setTeams([]);
    }
  };

  useEffect(() => {
    setOrgOptions(
      orgs.map((org) => ({
        value: org.id,
        label: org.title || 'No Org Name',
      }))
    );
    setLoadingOrgs(false);
    setLoadingTeams(true);
  }, [orgs]);

  useEffect(() => {
    setTeamOptions(
      teams.map((team) => ({
        value: team.id,
        label: team.title || 'No Team Name',
      }))
    );
    setLoadingTeams(false);
  }, [teams]);

  useEffect(() => {
    if (isString(selectedOrgId) && selectedOrgId.length > 0) {
      fetchTeams();
    } else {
      setTeams([]);
    }
  }, [selectedOrgId]);

  useEffect(() => {
    setAllItemsToUploadCount(
      (data || []).filter(
        (item) =>
          isString(item.organization_id) &&
          item.organization_id.length > 1 &&
          isArray(item.teams) &&
          item.teams.length > 0 &&
          item.enabled !== false
      ).length
    );
    // (data || [])
    //     .filter((i) => i.enabled !== false).length
    // );
  }, [data]);

  return (
    <div>
      <div className="container" style={{ padding: 30 }}>
        <h1>Import Users</h1>
        <div style={{ display: 'flex' }}>
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileUpload}
            style={{ marginBottom: 30 }}
          />
          <div style={{ display: 'flex', gap: 10, marginLeft: 20 }}>
            <span>Suppress emails</span>
            <Toggle
              value={suppressAutoEmail}
              onToggle={(val) => {
                setSuppressAutoEmail(val);
              }}
              size={34}
            />
          </div>
          <FlexSpacer />
          <a
            href="/files/6tool_user_import_template.xlsx"
            download="6tool_user_import_template.xlsx"
          >
            <FaDownload style={{ marginRight: 8 }} />
            {downloadTemplateText}
          </a>
          <CLink
            onClick={() => {
              setShowHelpModal(true);
            }}
            style={{ marginLeft: 20 }}
          >
            <FaInfoCircle style={{ marginRight: 8 }} />
            Help
          </CLink>
        </div>
        {data.length > 0 && data.length > allItemsToUploadCount && (
          <>
            <div style={{ fontSize: '0.8em' }}>
              There are {data.length - allItemsToUploadCount} players who do not
              have an org or team. Choose from the dropdowns to select what
              org/team you want to use for those players.
            </div>
            {allItemsToUploadCount > 0 && (
              <div style={{ fontSize: '0.8em' }}>
                The {allItemsToUploadCount} user
                {allItemsToUploadCount === 1
                  ? ' who already has'
                  : 's who already have'}{' '}
                an org and team will keep the values they already have
                regardless of what you select below.
              </div>
            )}
            {loadingOrgs ? (
              <p>Loading orgs...</p>
            ) : (
              <div style={{ marginTop: 20 }}>
                <ReactSelect
                  options={orgOptions}
                  onChange={handleOrgSelect}
                  placeholder="Select an organization"
                />
                <p>Selected Organization ID: {selectedOrgId}</p>
              </div>
            )}
            {isString(selectedOrgId) && selectedOrgId.length > 0 && (
              <>
                {loadingTeams ? (
                  <p>Loading teams...</p>
                ) : (
                  <>
                    <ReactSelect
                      options={teamOptions}
                      onChange={handleTeamSelect}
                      placeholder="Select a team"
                    />
                    <p>Selected Team ID: {selectedTeamId}</p>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
      {data.length > 0 && (
        <>
          <CButton
            color="success"
            size="lg"
            onClick={() => uploadAll()}
            style={{
              float: 'right',
              marginLeft: 20,
              marginBottom: 20,
              marginRight: 20,
            }}
            disabled={allItemsToUploadCount === 0}
          >
            <>
              <FaUpload /> Upload {allItemsToUploadCount} user
              {allItemsToUploadCount === 1 ? '' : 's'}
            </>
          </CButton>
          <CDataTable
            addTableClasses={`import-table`}
            items={data}
            itemsPerPage={100}
            itemsPerPageSelect={true}
            fields={[
              ...fields,
              { key: 'actions', label: 'Actions', _style: { width: '300px' } },
            ]}
            header={true}
            sorter={true}
            tableFilter={true}
            scopedSlots={{
              teams: (item, index) => (
                <td style={{ textAlign: 'left' }}>
                  <div>Org [{item?.organization_id}]</div>
                  <ul>
                    {item?.teams?.map((t) => (
                      <li>{t}</li>
                    ))}
                  </ul>
                </td>
              ),
              actions: (item, index) => (
                <td style={{ textAlign: 'right' }}>
                  {item.enabled && (
                    <CButton
                      color="success"
                      variant="outline"
                      size="sm"
                      disabled={
                        !(
                          isString(item.organization_id) &&
                          item.organization_id.length > 1 &&
                          isArray(item.teams) &&
                          item.teams.length > 0
                        )
                      }
                      onClick={() => {
                        uploadUser({
                          ...{ index },
                          ...pick(item, [
                            'name',
                            'email',
                            'password',
                            'role',
                            'teams',
                            'organization_id',
                          ]),
                        });
                        console.log(item);
                      }}
                    >
                      <>
                        <FaUpload /> Upload
                      </>
                    </CButton>
                  )}
                  <CButton
                    color={item.enabled ? 'danger' : 'warning'}
                    size="sm"
                    style={{ marginLeft: 8 }}
                    onClick={() => toggleEnabled(index)}
                  >
                    <>
                      {item.enabled ? (
                        <>
                          <FaTrashAlt /> Skip
                        </>
                      ) : (
                        <>
                          <FaTrashRestore /> Restore
                        </>
                      )}
                    </>
                  </CButton>
                </td>
              ),
              'th.actions': (item, index) => (
                <th>
                  <CButton
                    color="primary"
                    onClick={() => {
                      console.log('here');
                    }}
                  >
                    Action Button
                  </CButton>
                </th>
              ),
            }}
            pagination
          />
        </>
      )}
      <ConfirmModal
        show={showHelpModal}
        size="lg"
        title="Import Instructions"
        message={`You can bulk import users into your 6Tool account. To do so:`}
        okButtonColor="info"
        okButtonText="Done"
        onClickConfirm={async () => {
          setShowHelpModal(false);
        }}
      >
        <ol>
          <li>Click '{downloadTemplateText}'</li>
          <li>
            Open the spreadsheet (
            <b>
              <i>do not change the # of rows or the column names</i>
            </b>
            )
          </li>
          <li>
            Fill out the <b>name</b> and <b>email</b> column for all users
            you're importing
          </li>
          <li>
            The <b>password</b> field you can enter a desired password for them
            to sign in or you can leave it empty. An empty password field will
            generate a random password for the user that can be reset easily by
            them during their sign-up process
          </li>
          <li>
            For <b>role</b> put either 'player', 'coach', or 'owner'. If the
            user should be a coach and and owner, you can put 'coach,owner'
            (comma-separated, no spaces).
          </li>
          <li>
            For <b>organization_id</b> and <b>teams</b> leave this blank unless
            you know what these columns do already
          </li>
          <li>
            Save the file and then click the Choose File button on this page,
            selecting the file you just saved with your users' data.
          </li>
          <li>
            From the dropdowns, select the organization and/or team you want to
            add the users to. Note: you can only import users to one team at a
            time unless you've filled out the teams column in your spreadsheet.
          </li>
        </ol>
      </ConfirmModal>
    </div>
  );
}

export default SuperAdminUploadUsers;
