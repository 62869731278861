import React, { useEffect, useState } from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CLabel,
  CLink,
  CModal,
  CModalBody,
  CModalHeader,
} from '@coreui/react';
import ViewScoreBoard from '../ViewScoreBoard/ViewScoreBoard';
import BallField from '../BallField/BallField';
import QuestionText from '../Questions/QuestionText';
import QuestionOptions from '../Questions/QuestionOptions';
import _, { isString } from 'lodash';
import QuestionMedia from '../Questions/QuestionMedia';

const QuizPrintout = ({
  className,
  quiz,
  allQuestionsMap,
  itemRefs,
  singleResponse,
  responses,
  visibleItemIndex,
}) => {
  return (
    <div className={className}>
      {(quiz?.questions || []).map((q, index) => {
        let questionId = q?.questionId || isString(q) ? q : '';
        let question = { ...q, ...allQuestionsMap?.[questionId] };
        let options = [];
        if (_.isArray(question.options) && question.options.length > 0) {
          options = question.options;
        } else {
          options = [...(allQuestionsMap?.[questionId]?.options || [])];
          if (
            !isNaN(question.default_order) &&
            options.length > question.default_order
          ) {
            options[question.default_order] = {
              ...options[question.default_order],
              correct: true,
            };
          }
        }
        return (
          <div
            key={`${question.text}--${index}`}
            ref={(el) => (itemRefs.current[index] = el)}
            className={`quiz-questions-details-card ${
              singleResponse === true &&
              (options?.[responses?.[index]]?.correct ?? null === true
                ? ' correct'
                : ' wrong')
            }`}
          >
            {question.deleted === true ? (
              <CCard className="mb-0 p-4">
                <CLabel className="m-0">Question has been deleted</CLabel>
              </CCard>
            ) : (
              <>
                <div className={`quiz-questions-details-index`}>
                  {index + 1}
                </div>
                <CCard
                  className={`${visibleItemIndex === index ? 'active' : ''}`}
                >
                  <CCardBody className="mt-4">
                    {question.showScoreboard && (
                      <div>
                        <ViewScoreBoard {...question.situation} />
                      </div>
                    )}
                    {question.showField && (
                      <div>
                        <BallField positionHighlights={question.positions} />
                      </div>
                    )}
                    <QuestionMedia
                      imageUrl={question.imageUrl}
                      videoUrl={question.videoUrl}
                      maxWidth={500}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    />
                    <QuestionText
                      question={question}
                      style={{
                        fontWeight: 900,
                        fontFamily: 'AvinerHeavy',
                      }}
                    />
                    {question.withTimer && (
                      <p>
                        Question will be marked as answered within:
                        {question.timer}s
                      </p>
                    )}
                    <QuestionOptions
                      options={options}
                      responseCount={
                        singleResponse !== true &&
                        _.isArray(responses) &&
                        responses.length > index
                          ? responses[index]
                          : null
                      }
                      singleResponse={
                        singleResponse === true &&
                        _.isArray(responses) &&
                        responses.length > index
                          ? responses[index]
                          : null
                      }
                    />
                  </CCardBody>
                </CCard>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default QuizPrintout;
