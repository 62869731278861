import {
  SET_TEAMS,
  SET_CURRENT_TEAM,
  // SET_USER_TEAMS,
  // UPDATE_USER_SELECTED_TEAM,
  // SET_PLAYERS,
  // UPDATE_PLAYERS,
  CLEAR_TEAM,
  // SET_USER_SELECTED_TEAM,
} from './constants';

/**
 * Set current user
 */

export function setTeams(payload) {
  return {
    type: SET_TEAMS,
    payload,
  };
}
export function setCurrentTeam(payload) {
  return {
    type: SET_CURRENT_TEAM,
    payload,
  };
}
/*
export function setUserTeams(payload) {
  return {
    type: SET_USER_TEAMS,
    payload,
  };
}

export function updateUserTeam(payload) {
  return {
    type: UPDATE_USER_SELECTED_TEAM,
    payload,
  };
}

export function setTeam(payload) {
  return {
    type: SET_USER_SELECTED_TEAM,
    payload,
  };
}

export function setPlayers(payload) {
  return {
    type: SET_PLAYERS,
    payload,
  };
}

export function updatePlayers(payload) {
  return {
    type: UPDATE_PLAYERS,
    payload,
  };
}
*/
export function clearTeam() {
  return {
    type: CLEAR_TEAM,
  };
}
