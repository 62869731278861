import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CLabel,
  CListGroup,
  CListGroupItem,
  CRow,
  CSpinner,
  CTooltip,
} from '@coreui/react';
import { useStripe } from '@stripe/react-stripe-js';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { range } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiEdit2, FiEdit3 } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import * as Yup from 'yup';
import { db, functions } from '../../../index';
import { POSITIONS } from '../../../r-reusable';
import { setCurrentUser } from '../../../r-store/user/actions';
import {
  Col,
  Container,
  Nav,
  Row,
  Tab,
  TabContainer,
  TabContent,
  Form as BootstrapForm,
  FormGroup,
  InputGroup,
  FormLabel,
} from 'react-bootstrap';
import CIcon from '@coreui/icons-react';
import { cilPuzzle, cilSpeedometer } from '@coreui/icons';
import {
  FaCity,
  FaEnvelope,
  FaMapMarkedAlt,
  FaPhoneAlt,
  FaUser,
} from 'react-icons/fa';
import _ from 'lodash';
import ProfileWrapper from './ProfileWrapper';
import { toast } from 'react-toastify';
import { handleError } from '../../../helpers/errors';

const initialValues = {
  email: '',
  displayName: '',
  age: '',
  city: '',
  positions: [],
  classYear: '',
  youtube: '',
  baseballReports: '',
  twitter: '',
  instagram: '',
  type: '',
};

const FormFields = [
  {
    field: 'name',
    icon: <FaUser />,
    autoComplete: 'name',
    validation: Yup.string().required(),
    required: true,
  },
  // {
  //   field: 'email',
  //   type: 'email',
  //   icon: <FaEnvelope />,
  //   autoComplete: 'email',
  //   validation: Yup.string().trim().email().required('Email is required'),
  //   required: true,
  // },
  { field: 'phone number', autoComplete: 'tel', icon: <FaPhoneAlt /> },

  [
    {
      field: 'city',
      autoComplete: 'address-level2',
      icon: <FaCity />,
      style: { flex: 3 },
    },
    {
      field: 'state',
      icon: <FaMapMarkedAlt />,
      autoComplete: 'address-level3',
      flex: 1,
    },
  ],
];

const validationSchema = Yup.object().shape(
  FormFields.filter((f) => !!f.validation).reduce((acc, curr) => {
    acc[_.camelCase(curr.field)] = curr.validation;
    return acc;
  }, {})
);

const ProfileDetails = () => {
  // const stripe = useStripe();
  const dispatch = useDispatch();
  const useFormik = useRef(null);
  const isMounted = useRef(false);
  const selectPositionsRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loadingSubscription, setLoadingSubscription] = useState(false);
  const [subscriptionInfo, setSubscriptionInfo] = useState({});
  const [isEditMode, setIsEditMode] = useState(true);
  const darkMode = useSelector((state) => state.ThemeState.darkMode);
  const userData = useSelector((state) => state?.UserState);

  const onSubmit = async (
    { name, city, state, phoneNumber, email, ...res },
    action
  ) => {
    try {
      email = email.trim();
      setLoading(true);
      const usersRef = db.collection('users');
      let update = { name };
      if (_.isString(city) && city.length > 0) {
        update.city = city;
      }
      if (_.isString(state) && state.length > 0) {
        update.state = state;
      }
      if (_.isString(email) && email.length > 0) {
        update.email = email;
      }
      if (_.isString(phoneNumber) && phoneNumber.length > 0) {
        update.phoneNumber = phoneNumber;
      }
      await usersRef.doc(userData.data._id).update(update);
      const updateUser = functions.httpsCallable('updateUser');
      await updateUser({ ...update, id: userData.data._id });
      // dispatch(
      //   setCurrentUser({
      //     data: { ...userData, ...rest, email, name: displayName },
      //     isLoggedIn: true,
      //     isHeadCoach: type.value === 'coach',
      //   })
      // );
      setLoading(false);
      setIsEditMode(false);
      toast('User updated', { type: 'success' });
    } catch (e) {
      handleError({
        error: e,
        internal: 'PlayerUpdate.js - onSubmit',
        toast: 'There was an error updating user',
      });
      setLoading(false);
    }
  };

  const renderFormField = ({ field, touched, errors }) => {
    const camelField = _.camelCase(field.field);
    return (
      <>
        <FormLabel htmlFor={camelField}>
          {field.label || _.startCase(field.field)}
          {field.required === true && (
            <span style={{ marginLeft: 4 }} className="text-danger">
              *
            </span>
          )}
        </FormLabel>
        <InputGroup>
          {!!field.icon && (
            <InputGroup.Prepend>
              <InputGroup.Text>{field.icon}</InputGroup.Text>
            </InputGroup.Prepend>
          )}
          <Field
            type={field.type || 'text'}
            name={camelField}
            id={camelField}
            autoComplete={field.autoComplete || 'off'}
            className={`form-control ${
              touched[camelField] && errors[camelField] ? 'is-invalid' : ''
            }`}
          />
          <ErrorMessage
            component={BootstrapForm.Control.Feedback}
            name={camelField}
            className="invalid-feedback"
          />
        </InputGroup>
      </>
    );
  };

  return (
    <ProfileWrapper currentProfileTab="DETAILS">
      <Formik
        enableReinitialize
        onSubmit={onSubmit}
        innerRef={useFormik}
        initialValues={{
          ...(userData?.data || {}),
          city: _.capitalize(userData?.data?.location?.city || ''),
          state: _.upperCase(userData?.data?.location?.state || ''),
        }}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          touched,
          isValid,
          dirty,
        }) => (
          <BootstrapForm onSubmit={handleSubmit}>
            {FormFields.map((field, index) => {
              if (_.isArray(field) && field.length > 0) {
                return (
                  <div
                    key={`form-div-${index}`}
                    style={{ display: 'flex', gap: 16 }}
                  >
                    {field.map((f, i) => {
                      return (
                        <FormGroup
                          key={`form-group-${i}`}
                          style={{
                            flex: !isNaN(f.flex) ? parseInt(f.flex) : 1,
                            ...(f.style || {}),
                          }}
                        >
                          {renderFormField({ field: f, touched, errors })}
                        </FormGroup>
                      );
                    })}
                  </div>
                );
              }
              return (
                <FormGroup key={`form-div-${index}`}>
                  {renderFormField({ field, touched, errors })}
                </FormGroup>
              );
            })}
            <CButton
              size="md"
              type="submit"
              color="primary"
              className="profile-save-button"
              disabled={loading || !isValid || !dirty}
            >
              Save Changes
            </CButton>
          </BootstrapForm>
        )}
      </Formik>
    </ProfileWrapper>
  );
};

export default ProfileDetails;
