import { isString, find, isArray } from 'lodash';
import { toast } from 'react-toastify';

export const DEFAULT_QUESTIONS_PER_PAGE = 25;
export const DEFAULT_ANNUAL_COST_PER_TEAM = 200;
export const DEFAULT_MONTHLY_COST_PER_TEAM = 25;

export const SUBSCRIPTION_TIER = {
  FREE: { key: 'free', label: 'Rookie' },
  REGULAR: { key: 'regular', label: 'Pro' },
  PREMIUM: { key: 'premium', label: 'All Star' },
};

export const SUBSCRIPTION_LABEL_BY_TIER = (key) => {
  const tier = find(SUBSCRIPTION_TIER, { key });
  return tier ? tier.label : null;
};

export const SUBSCRIPTION_PAYMENT_TERMS = {
  ANNUAL: { value: 0, label: 'annual', costLabel: 'annualCost' },
  MONTHLY: { value: 1, label: 'monthly', costLabel: 'monthlyCost' },
};

export const PRICING_TIERS = [
  {
    label: 'Rookie',
    className: 'grey-logo',
    tier: SUBSCRIPTION_TIER.FREE.key,
    [SUBSCRIPTION_PAYMENT_TERMS.ANNUAL.label]: 'Free',
    [SUBSCRIPTION_PAYMENT_TERMS.MONTHLY.label]: 'Free',
    [SUBSCRIPTION_PAYMENT_TERMS.ANNUAL.costLabel]: [0],
    [SUBSCRIPTION_PAYMENT_TERMS.MONTHLY.costLabel]: [0],
  },
  {
    label: 'Pro',
    className: 'blue-logo',
    tier: SUBSCRIPTION_TIER.REGULAR.key,
    [SUBSCRIPTION_PAYMENT_TERMS.ANNUAL.label]: '$200',
    [SUBSCRIPTION_PAYMENT_TERMS.MONTHLY.label]: '$25',
    [SUBSCRIPTION_PAYMENT_TERMS.ANNUAL.costLabel]: [
      0, 200, 200, 200, 200, 200, 200, 200, 200, 200, 175, 175, 175, 175, 175,
      175, 175, 175, 175, 175, 150,
    ],
    [SUBSCRIPTION_PAYMENT_TERMS.MONTHLY.costLabel]: [
      0, 25, 25, 25, 25, 25, 25, 25, 25, 25, 20, 20, 20, 20, 20, 20, 20, 20, 20,
      20, 15,
    ],
  },
  {
    label: 'All-Star',
    className: 'gold-logo',
    tier: SUBSCRIPTION_TIER.PREMIUM.key,
    [SUBSCRIPTION_PAYMENT_TERMS.ANNUAL.label]: '$300',
    [SUBSCRIPTION_PAYMENT_TERMS.MONTHLY.label]: '$50',
    [SUBSCRIPTION_PAYMENT_TERMS.ANNUAL.costLabel]: [
      0, 300, 300, 300, 300, 300, 300, 300, 300, 300, 275, 275, 275, 275, 275,
      275, 275, 275, 275, 275, 250,
    ],
    [SUBSCRIPTION_PAYMENT_TERMS.MONTHLY.costLabel]: [
      0, 50, 50, 50, 50, 50, 50, 50, 50, 50, 40, 40, 40, 40, 40, 40, 40, 40, 40,
      40, 30,
    ],
  },
];

export const STRIPE = {
  prices: {
    [SUBSCRIPTION_TIER.FREE.key]: {
      [SUBSCRIPTION_PAYMENT_TERMS.ANNUAL.label]:
        process.env.REACT_APP_PROJECT_ID === 'six-tool-staging'
          ? 'price_1PU8hfBwjqXte3DEONpKWKjA'
          : 'price_1PTvjeBwjqXte3DELC3DGJOZ',
      [SUBSCRIPTION_PAYMENT_TERMS.MONTHLY.label]:
        process.env.REACT_APP_PROJECT_ID === 'six-tool-staging'
          ? 'price_1PTvACBwjqXte3DEK9WlysrO'
          : 'price_1PTvjOBwjqXte3DEAY1VMegG',
    },
    [SUBSCRIPTION_TIER.REGULAR.key]: {
      [SUBSCRIPTION_PAYMENT_TERMS.ANNUAL.label]:
        process.env.REACT_APP_PROJECT_ID === 'six-tool-staging'
          ? 'price_1PU93VBwjqXte3DERXuVs7oP'
          : 'price_1PTvlvBwjqXte3DEsqXQ8DoM',
      [SUBSCRIPTION_PAYMENT_TERMS.MONTHLY.label]:
        process.env.REACT_APP_PROJECT_ID === 'six-tool-staging'
          ? 'price_1PU93VBwjqXte3DEamiOFLXx'
          : 'price_1PTvlvBwjqXte3DEH5ujnECG',
    },
    [SUBSCRIPTION_TIER.PREMIUM.key]: {
      [SUBSCRIPTION_PAYMENT_TERMS.ANNUAL.label]:
        process.env.REACT_APP_PROJECT_ID === 'six-tool-staging'
          ? 'price_1PU95tBwjqXte3DE8AC9tKsI'
          : 'price_1PTvmJBwjqXte3DEHWAr2Z25',
      [SUBSCRIPTION_PAYMENT_TERMS.MONTHLY.label]:
        process.env.REACT_APP_PROJECT_ID === 'six-tool-staging'
          ? 'price_1PU95tBwjqXte3DEvq7GlTsi'
          : 'price_1PTvnZBwjqXte3DE2MtgBU5L',
    },
    price_1PTvmJBwjqXte3DEHWAr2Z25: SUBSCRIPTION_TIER.PREMIUM.key,
    price_1NHA2rBwjqXte3DEiXmIajuJ: SUBSCRIPTION_TIER.PREMIUM.key,
    price_1OoxG6BwjqXte3DEkcI7CwfI: SUBSCRIPTION_TIER.PREMIUM.key,
    price_1NHA1sBwjqXte3DEry9AwoLq: SUBSCRIPTION_TIER.PREMIUM.key,
  },
  products: {
    [SUBSCRIPTION_TIER.FREE.key]:
      process.env.REACT_APP_PROJECT_ID === 'six-tool-staging'
        ? 'prod_QKaKiPZi3MlWmp'
        : 'prod_QKavPitEdDFx1A',
    [SUBSCRIPTION_TIER.REGULAR.key]:
      process.env.REACT_APP_PROJECT_ID === 'six-tool-staging'
        ? 'prod_QKog81D7jx1x7o'
        : 'prod_QKaxxFwPeqXlNZ',
    [SUBSCRIPTION_TIER.PREMIUM.key]:
      process.env.REACT_APP_PROJECT_ID === 'six-tool-staging'
        ? 'prod_QKojCFTV04B3ls'
        : 'prod_QKayYQqUTKjaPT',
  },
  pricing_tables: {
    ALL_TIERS_RECOMMEND_PREMIUM:
      process.env.REACT_APP_PROJECT_ID === 'six-tool-staging'
        ? 'prctbl_1PU9CABwjqXte3DEc8ZH0QE6'
        : 'prctbl_1PTvt8BwjqXte3DEPx2ntUGt',
    ALL_TIERS_RECOMMEND_REGULAR:
      process.env.REACT_APP_PROJECT_ID === 'six-tool-staging'
        ? 'prctbl_1Pa0rXBwjqXte3DESDb1ILIi'
        : 'prctbl_1Pa0iOBwjqXte3DEkpUinBVh',
    ALL_TIERS_NO_RECOMMEND:
      process.env.REACT_APP_PROJECT_ID === 'six-tool-staging'
        ? 'prctbl_1Pa0xXBwjqXte3DERW5p5b5x'
        : 'prctbl_1Pa0lSBwjqXte3DEQc9t0JtH',
  },
  customer_portal_link:
    process.env.REACT_APP_PROJECT_ID === 'six-tool-staging'
      ? 'https://pay.6-tool.com/p/login/test_fZe9BZagQ2rs5fq5kk'
      : 'https://pay.6-tool.com/p/login/8wMeYlaMv2tt90Y4gg',
  redirects: {
    back_from_pay: 'redirect_back_from_pay',
  },
  subscription_override_friendly: 'sub_friendly',
  subscription_override_admin: 'sub_admin',
};

export const paymentTermAndTierForPrice = (price) => {
  if (isString(price) && price.length > 0) {
    for (const tierKey in STRIPE.prices) {
      const paymentTerms = STRIPE.prices[tierKey];
      for (const termKey in paymentTerms) {
        if (paymentTerms[termKey] === price) {
          return { tier: tierKey, paymentTerm: termKey };
        }
      }
    }
  }
  return {
    tier: SUBSCRIPTION_TIER.FREE.key,
    paymentTerm: SUBSCRIPTION_PAYMENT_TERMS.ANNUAL.label,
  };
};

export const DOMAIN =
  process.env.REACT_APP_PROJECT_ID === 'six-tool-staging'
    ? 'https://six-tool-staging.web.app'
    : 'https://6-tool.com';

export const PHONE_NUMBER = { FORMATTED: '855-749-8665', TEL: '+18557498665' };

const EMAIL_DOMAIN = '6-tool.com';

export const EMAIL_ADDRESSES = {
  SALES: `support@${EMAIL_DOMAIN}`,
  SUPPORT: `support@${EMAIL_DOMAIN}`,
  INFO: `info@${EMAIL_DOMAIN}`,
};

export const PARAM_FLAG_TYPES = {
  SUBSCRIPTION_UPDATED: 'subscription_updated',
};
export const removeParamFlags = (flags) => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  if (isArray(flags) && flags.length > 0) {
    flags.forEach((flag) => {
      params.delete(flag);
    });
  }
  const newSearch = params.toString();
  const newUrl = `${url.pathname}${newSearch ? `?${newSearch}` : ''}`;
  window.history.replaceState(null, '', newUrl);
};

/* paramFlags *****
key = PARAM_FLAG_TYPES
flag = key
onFlagged: (value) => { code to run when the flag is received. return true to remove the flag }
clearAllParams: if true, all params will be removed from the url
clearParams: an array of params you'd also like to have cleared
******************/
export const paramFlags = {
  [PARAM_FLAG_TYPES.SUBSCRIPTION_UPDATED]: {
    flag: PARAM_FLAG_TYPES.SUBSCRIPTION_UPDATED,
    onFlagged: (value) => {
      if (value?.toString() === 'true') {
        toast(
          'Subscription updated. It may take the system a moment to reflect the changes.',
          { type: 'success' }
        );
      } else if (value?.toString() === 'false') {
        toast(
          `Subscription was not updated. Please try again or contact ${EMAIL_ADDRESSES.SALES}`,
          { type: 'error' }
        );
      }
      return true;
    },
  },
};
