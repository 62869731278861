const defaultScrimmageState = {
  token: null,
  isLoading: false,
  status: 0,
  scrimmageRequestData: null,
};

export const scrimmageReducer = (state = defaultScrimmageState, action) => {
  switch (action.type) {
    case 'DEFAULT_SCRIMMAGE':
      return {
        ...state,
        isLoading: false,
        status: 0,
        scrimmageRequestData: null,
      };

    case 'REQUESTED_CREATE_SCRIMMAGE':
      return { ...state, isLoading: true, status: 1 };
    case 'RECEIVED_CREATE_SCRIMMAGE_FAILURE':
      return { ...state, isLoading: false, status: 2 };
    case 'RECEIVED_CREATE_SCRIMMAGE_SUCCESS':
      return {
        ...state,
        scrimmageData: action.scrimmageData,
        isLoading: false,
        status: 3,
      };

    case 'REQUESTED_GET_SCRIMMAGE':
      return { ...state, isLoading: true };
    case 'RECEIVED_GET_SCRIMMAGE_SUCCESS':
      return {
        ...state,
        scrimmageInvitationDataByMail: action.scrimmageInvitationDataByMail,
        isLoading: false,
      };

    case 'REQUESTED_SCRIMMAGE_REQUEST':
      return { ...state, isLoading: true };
    case 'RECEIVED_SCRIMMAGE_REQUEST_SUCCESS':
      return {
        ...state,
        scrimmageRequestData: action.scrimmageRequestData,
        isLoading: false,
      };

    case 'REQUESTED_SCRIMMAGE_INVITATION':
      return { ...state, isLoading: true };
    case 'RECEIVED_SCRIMMAGE_INVITATION_SUCCESS':
      return {
        ...state,
        scrimmageInvitationData: action.scrimmageInvitationData,
        isLoading: false,
      };

    case 'REQUESTED_SCRIMMAGE_CONFIRMATION':
      return { ...state, isLoading: true };
    case 'RECEIVED_SCRIMMAGE_CONFIRMATION_SUCCESS':
      return {
        ...state,
        scrimmageConfirmationData: action.scrimmageConfirmationData,
        isLoading: false,
      };

    case 'REQUESTED_SCRIMMAGE_REQUEST_DECLINE':
      return { ...state, isLoading: true, status: 1 };
    case 'RECEIVED_SCRIMMAGE_REQUEST_DECLINE_FAILURE':
      return { ...state, isLoading: false, status: 2 };
    case 'RECEIVED_SCRIMMAGE_REQUEST_DECLINE_SUCCESS':
      return {
        ...state,
        scrimmageRequestDecline: action.scrimmageRequestDecline,
        isLoading: false,
        status: 3,
      };

    case 'REQUESTED_SCRIMMAGE_REQUEST_ACCEPT':
      return { ...state, isLoading: true, status: 1 };
    case 'RECEIVED_SCRIMMAGE_REQUEST_ACCEPT_FAILURE':
      return { ...state, isLoading: false, status: 2 };
    case 'RECEIVED_SCRIMMAGE_REQUEST_ACCEPT_SUCCESS':
      return {
        ...state,
        scrimmageRequestAccepted: action.scrimmageRequestAccepted,
        isLoading: false,
        status: 3,
      };

    case 'RECEIVED_SCRIMMAGE_HISTORY':
      return { ...state, isLoading: true, status: 1 };
    case 'RECEIVED_SCRIMMAGE_HISTORY_SUCCESS':
      return {
        ...state,
        scrimmageHistory: action.scrimmageHistory,
        isLoading: false,
      };

    case 'REQUESTED_SCRIMMAGE_FEEDBACK':
      return { ...state, isLoading: true, status: 1 };
    case 'RECEIVED_SCRIMMAGE_FEEDBACK_FAILURE':
      return { ...state, isLoading: false, status: 2 };
    case 'RECEIVED_SCRIMMAGE_FEEDBACK_SUCCESS':
      return {
        ...state,
        scrimmageFeedback: action.scrimmageFeedback,
        isLoading: false,
        status: 3,
      };

    case 'REQUESTED_SCRIMMAGE_FEEDBACK_DATA':
      return { ...state, isLoading: true, status: 1 };
    case 'RECEIVED_SCRIMMAGE_FEEDBACK_DATA_SUCCESS':
      return {
        ...state,
        scrimmageFeedbackData: action.scrimmageFeedbackData,
        isLoading: false,
      };

    case 'REQUESTED_SCRIMMAGE_REQUEST_CANCEL':
      return { ...state, isLoading: true, status: 1 };
    case 'RECEIVED_SCRIMMAGE_REQUEST_CANCEL_FAILURE':
      return { ...state, isLoading: false, status: 2 };
    case 'RECEIVED_SCRIMMAGE_REQUEST_CANCEL_SUCCESS':
      return { ...state, isLoading: false, status: 3 };

    case 'REQUESTED_SCRIMMAGE_PAYMENT_DATA':
      return { ...state, isLoading: true, status: 1 };
    case 'RECEIVED_SCRIMMAGE_PAYMENT_DATA_FAILURE':
      return { ...state, isLoading: false, status: 2 };
    case 'RECEIVED_SCRIMMAGE_PAYMENT_DATA_SUCCESS':
      return {
        ...state,
        isLoading: false,
        scrimmagePaymentData: action.scrimmagePaymentData,
      };
    default:
  }

  return state;
};
