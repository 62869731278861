import PropTypes from 'prop-types';
import React from 'react';
import Footer from '../Footer';
import AppNav from '../../../r-components/Nav/AppNav';
class BlankLayout extends React.Component {
  render(props) {
    return <>{this.props.children}</>;
  }
}

BlankLayout.propTypes = {
  children: PropTypes.node,
};

export default BlankLayout;
