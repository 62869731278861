import {
  CButton,
  CLink,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react';
import './teamCodeExplanationModal.scss';
import React, { useEffect, useRef, useState } from 'react';
import { FaCopy } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { DOMAIN } from '../../../config';

function TeamCodeExplanationModal({
  show,
  onClose,
  title,
  code,
  message,
  onClickCancel,
  onClickConfirm,
  cancelButtonText,
  okButtonText,
  cancelButtonColor,
  okButtonColor,
  type = 'player',
  ...rest
}) {
  const isMounted = useRef(false);
  const [submitting, setSubmitting] = useState(false);

  return (
    <CModal
      className="team-code-explanation-modal"
      size="xl"
      show={show}
      backdrop={true}
      scrollable={false}
      onClose={onClose}
    >
      <CModalHeader>
        <CModalTitle>
          <h1>
            How to Use Team Code{' '}
            <span style={{ fontWeight: 'bolder', color: '#f6d30d' }}>
              {code}
            </span>{' '}
            to Invite a {type === 'coach' ? 'Coach' : 'Player'}
          </h1>
        </CModalTitle>
      </CModalHeader>

      <CModalBody>
        {type === 'coach' ? (
          <p>
            When a coach joins 6Tool, he/she needs to get linked to your team to
            verify their access. This step to link the coach can happen one of
            two ways:
          </p>
        ) : (
          <p>
            When a player joins 6Tool, he/she needs to get linked to your team
            so that you have the ability to invite them to take quizzes. This
            step to link the players can happen one of two ways:
          </p>
        )}
        <ol>
          <li>
            You can personally add them from {DOMAIN} by entering their details
            (name and email). An email invite will go out to them and ask them
            to download the app. <strong>Pros:</strong> When they sign in to the{' '}
            {type === 'coach' ? 'website' : 'app'}, they will see your team
            automatically. <strong>Cons:</strong> You have to know and enter the
            name and email of all your{' '}
            {type === 'coach' ? 'coaches' : 'players'}{' '}
            <b>
              <i>AND</i>
            </b>{' '}
            they have to notice that email invite from 6Tool and follow the{' '}
            {type === 'coach' ? 'signup' : 'download'} instructions.
          </li>
          {type === 'coach' ? (
            <li>
              You can ask them to sign up as a coach at {DOMAIN} and enter code{' '}
              {code} so they get added to your team. <strong>Pros:</strong> You
              don't have to enter every coach individually, you aren't left
              hoping they see the invite from 6Tool in their inbox.{' '}
              <strong>Cons:</strong> The onus is on them to sign up and enter
              the code correctly.
            </li>
          ) : (
            <li>
              You can ask them to download the app and then create an account
              and enter code {code} so they get added to your team.{' '}
              <strong>Pros:</strong> You don't have to enter every player
              individually, you aren't left hoping they see the invite from
              6Tool in their inbox. <strong>Cons:</strong> The onus is on them
              to sign up and enter the code correctly.
            </li>
          )}
        </ol>
        <p
          style={{
            fontFamily: 'AvinerHeavy',
            fontSize: '1.3rem',
            textAlign: 'center',
            lineHeight: '1.5rem',
          }}
        >
          The code {code} is unique to your team. If you have many teams on
          6Tool, each team will have its own unique code. Any user who enters
          this code will request to be added to your team. You may get
          accidental requests with this method.
        </p>
        <h5 style={{ marginLeft: 50, marginTop: 50 }}>
          Here is a sample body of an email or text you could send to your{' '}
          {type === 'coach' ? 'coaches' : 'players'}:
        </h5>
        <div className="message-body-wrapper">
          <CButton
            color="secondary"
            size="sm"
            variant="ghost"
            className="message-copy-button"
            onClick={() => {
              try {
                if (type === 'coach') {
                  navigator.clipboard.writeText(
                    `Please go to ${DOMAIN}/signup/create-coach, fill out your details and enter the code ${code} in the Team Code search box. This will add you to our team so you can start to create and distribute questions and quizzes and see the results of our team.`
                  );
                } else {
                  navigator.clipboard.writeText(
                    `Please go to the App Store or Google Play store and download the 6Tool app ${DOMAIN}/download. Once you have downloaded the app to your phone, create an account and enter team code ${code}. This will add you to our team so I can start to send you quizzes from 6Tool.`
                  );
                }
                toast('Message copied to clipboard', { type: 'success' });
              } catch (error) {
                toast(
                  'There was an error copying to clipboard. Your browser may not allow it. You can highlight the text and copy it.',
                  { type: 'error' }
                );
              }
            }}
          >
            <div>copy</div>
            <FaCopy size={24} />
          </CButton>
          <div className="message-body">
            {type === 'coach' ? (
              <>
                <p>
                  Please go to
                  <a href={`${DOMAIN}/signup/create-coach`}>
                    {DOMAIN}/signup/create-coach
                  </a>
                  , fill out your details and enter the code{' '}
                  <strong>{code}</strong> in the Team Code search box.
                </p>
                <p>
                  This will add you to our team so you can start to create and
                  distribute questions and quizzes and see the results of our
                  team.
                </p>
              </>
            ) : (
              <>
                <p>
                  Please go to the App Store or Google Play store and download
                  the 6Tool app (
                  <a href={`${DOMAIN}/download`}>{DOMAIN}/download</a>
                  ).
                </p>
                <p>
                  Once you have downloaded the app to your phone, create an
                  account and enter team code <strong>{code}</strong>. This will
                  add you to our team so I can start to send you quizzes from
                  6Tool.
                </p>
              </>
            )}
          </div>
        </div>
      </CModalBody>

      <CModalFooter>
        <CButton color={'primary'} className="mr-2" onClick={onClose}>
          Got it
        </CButton>
      </CModalFooter>
    </CModal>
  );
}

export default React.memo(TeamCodeExplanationModal);
