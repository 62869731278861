import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #151b26;

  ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    gap: 20px;
    align-items: center;

    li {
      a {
        color: #fff;
        text-decoration: none;

        font-family: 'Raleway', sans-serif;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 28px 0;
`;

export const Button = styled.button`
  background: #f6d30d;
  border: solid 1px #f6d30d;
  color: #151b26;
  padding: 10px 2px;
  border-radius: 10px;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  width: 160px;

  ${({ variant }) => {
    if (variant === 'outline') {
      return `
        background:transparent;
        color: #f6d30d;
      `;
    }
  }};
`;
