import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CLabel,
  CLink,
  CListGroup,
  CListGroupItem,
  CRow,
  CSpinner,
} from '@coreui/react';
import './profile.scss';
import { useStripe } from '@stripe/react-stripe-js';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { range } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiEdit2, FiEdit3 } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import * as Yup from 'yup';
import { db, functions, firebase } from '../../../index';
import { POSITIONS } from '../../../r-reusable';
import { setCurrentUser } from '../../../r-store/user/actions';
import {
  Col,
  Container,
  FormGroup,
  Nav,
  Row,
  Tab,
  TabContainer,
  TabContent,
  Form as BootstrapForm,
  FormLabel,
  InputGroup,
  Button,
} from 'react-bootstrap';
import CIcon from '@coreui/icons-react';
import { cilPuzzle, cilSpeedometer } from '@coreui/icons';
import { FaEnvelope, FaLock, FaPencilAlt, FaUser } from 'react-icons/fa';
import { toast } from 'react-toastify';
import PasswordChangeModal from '../../../r-components/PasswordChangeModal/PasswordChangeModal';
import ProfileWrapper from './ProfileWrapper';
import { handleError } from '../../../helpers/errors';

const initialValues = {
  email: 'test@t.com',
  displayName: '',
  age: '',
  city: '',
  positions: [],
  classYear: '',
  youtube: '',
  baseballReports: '',
  twitter: '',
  instagram: '',
  type: '',
};

const ProfileSecurity = ({ isDirty, ...props }) => {
  const userEmail = useSelector((state) => state?.UserState?.data?.email || '');
  const [securitySchemaShape, setSecuritySchemaShape] = useState({});
  const currentUser = firebase.auth().currentUser;
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  function onSubmit(values, { setSubmitting, resetForm }) {
    const { email } = values;

    currentUser
      .updateEmail(email)
      .then(() => {
        toast('Email updated successfully!', { type: 'success' });
        firebase
          .firestore()
          .collection('users')
          .doc(currentUser.uid)
          .update({ email })
          .then(() => {
            console.log('email updated');
          })
          .catch((dbErr) => {
            handleError({
              error: dbErr,
              internal: 'ProfileSecurity.js - currentUser',
            });
          });
      })
      .catch((error) => {
        toast(
          'Error updating email: ' +
            error.message
              .replace('Firebase:', '')
              .replace(/\(.*\)/g, '')
              .replace('. .', '.')
              .trim(),
          {
            type: 'error',
          }
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  return (
    <>
      <ProfileWrapper currentProfileTab="SECURITY">
        <Formik
          initialValues={{
            name: '',
            email: userEmail,
            currentPassword: Math.random().toString(36).replace('0.', ''),
            confirmPassword: '',
          }}
          validationSchema={Yup.object().shape(securitySchemaShape)}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({ errors, touched, handleSubmit, isSubmitting, dirty }) => (
            <BootstrapForm onSubmit={handleSubmit}>
              <FormGroup>
                <FormLabel htmlFor="email">Email</FormLabel>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FaEnvelope />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="email"
                    className={`form-control ${
                      touched.email && errors.email ? 'is-invalid' : ''
                    }`}
                  />
                  <ErrorMessage
                    component={BootstrapForm.Control.Feedback}
                    name="email"
                    className="invalid-feedback"
                  />
                </InputGroup>
              </FormGroup>

              <FormGroup>
                <FormLabel htmlFor="currentPassword">Password</FormLabel>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FaLock />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Field
                    type="password"
                    name="password"
                    id="password"
                    placeholder="*************"
                    disabled={true}
                    autoComplete="new-password"
                    className={`form-control ${
                      touched.currentPassword && errors.currentPassword
                        ? 'is-invalid'
                        : ''
                    }`}
                  />
                  <div
                    className="field-edit-button"
                    onClick={() => {
                      if (dirty) {
                        toast(
                          'For your account security, please save your email changes first and then edit your password.'
                        );
                      } else {
                        setShowPasswordModal(true);
                      }
                    }}
                  >
                    <FaPencilAlt />
                  </div>
                </InputGroup>
              </FormGroup>
              <Button
                disabled={!dirty}
                className="profile-save-button"
                type="submit"
              >
                Save Changes
              </Button>
            </BootstrapForm>
          )}
        </Formik>
        <PasswordChangeModal
          show={showPasswordModal}
          onClose={(updated = false) => {
            setShowPasswordModal(false);
            if (updated !== true) {
              toast('Password not updated', { type: 'info' });
            }
          }}
        />
      </ProfileWrapper>
    </>
  );
};

export default ProfileSecurity;
