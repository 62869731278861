import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { orgAndTeamData } from '../../helpers/data';
import { ACCESS_LEVELS } from '../../helpers/access';
import { GiWhistle } from 'react-icons/gi';
import { FaClipboard } from 'react-icons/fa';
import { CTooltip } from '@coreui/react';

const CoachRoles = ({ type, text = false } = {}) => {
  let roles = [];
  if (_.isArray(type) && type.length > 0) {
    const potentialRoles = [ACCESS_LEVELS.COACH, ACCESS_LEVELS.ASSISTANT];
    potentialRoles.forEach((r) => {
      if (type.includes(r.key)) {
        roles.push(r.label);
      }
    });
  }
  if (text === true) {
    return roles.map((role, index) => (
      <div key={`coach-role-${index}`}>
        {role === ACCESS_LEVELS.COACH.label ? (
          <GiWhistle />
        ) : role === ACCESS_LEVELS.ASSISTANT.label ? (
          <FaClipboard />
        ) : null}
        {role}
      </div>
    ));
  }
  return roles.map((role, index) => (
    <div key={`coach-role-${index}`}>
      {role === ACCESS_LEVELS.COACH.label ? (
        <CTooltip content={ACCESS_LEVELS.COACH.label}>
          <GiWhistle />
        </CTooltip>
      ) : role === ACCESS_LEVELS.ASSISTANT.label ? (
        <CTooltip content={ACCESS_LEVELS.ASSISTANT.label}>
          <FaClipboard />
        </CTooltip>
      ) : null}
    </div>
  ));
};

export default CoachRoles;
