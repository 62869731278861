import { SET_CURRENT_PLAYERS, CLEAR_PLAYERS } from './constants';

/**
 * Set current user
 */
export function setCurrentPlayers(payload) {
  return {
    type: SET_CURRENT_PLAYERS,
    payload,
  };
}
export function clearPlayers() {
  return {
    type: CLEAR_PLAYERS,
  };
}
