import {
  CButton,
  CCol,
  CDataTable,
  CLink,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CTabs,
  CTooltip,
} from '@coreui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  FaInfoCircle,
  FaCheckSquare,
  FaRegSquare,
  FaShareSquare,
  FaCheck,
  FaCalendarAlt,
  FaRegClock,
  FaAngleLeft,
  FaTrashAlt,
} from 'react-icons/fa';
import './InviteUsersToExamModal.scss';
import { shallowEqual, useSelector } from 'react-redux';
import { db, functions } from '../../index';
import _, { isEqual, keys, union } from 'lodash';
import { FiAlertCircle } from 'react-icons/fi';
import { toast } from 'react-toastify';
import {
  AUTO_QUIZ_DEFAULT_NUMBER_QUESTIONS,
  AUTO_QUIZ_MIN_NUMBER_QUESTIONS,
  AUTO_QUIZ_MAX_NUMBER_QUESTIONS,
  HEX_COLOR_GREEN_SUCCESS,
  AUTO_QUIZ_DEFAULT_DIFFICULTY_RANGE,
} from '../../helpers/constants';
import { FormGroup, FormLabel, InputGroup } from 'react-bootstrap';
import FlexSpacer from '../FlexSpacer/FlexSpacer';
import { concatenateStringFromArray } from '../../helpers/utils';
import LoadingMessage from '../LoadingMessage/LoadingMessage';
import moment from 'moment-timezone';
import { ACCESS_ACL, ACCESS_ACTIONS, checkAccess } from '../../helpers/access';
import useAccountSelectors from '../../hooks/useAccountSelectors';
import { EMAIL_ADDRESSES } from '../../config';
import { daysOfWeek } from '../ScheduleDays/ScheduleDays';
import { uniq, flatMap, values } from 'lodash';
import { every } from 'lodash';
import { some } from 'lodash';
import { isString } from 'lodash';
import { isFunction } from 'lodash';

export const INVITE_USERS_TO_EXAM_MODE = {
  CREATE: 'create',
  EDIT: 'edit',
};

const INITIAL_VALUES = {
  _selectedPlayerIDs: {},
  _selectedTeamIDs: [],
  _selectedAllFromTeamDict: {},
  _selectedAnyFromTeamDict: {},
  _selectedSomeButNotAllFromTeamDict: {},
  _selectedCounts: 0,
  _isScheduling: false,
  _isInitialized: false,
  _difficulty: 5,
  _scheduledHour: 12,
  _scheduledDays: [],
  _scheduleSummaryText: '',
  _selectedDifficultyRange: AUTO_QUIZ_DEFAULT_DIFFICULTY_RANGE,
  _selectedNumQuestions: AUTO_QUIZ_DEFAULT_NUMBER_QUESTIONS,
  _numberOfQuestions: 2,
  _title: '',
  _quizCategoriesString: '',
};

function InviteUsersToExamModal({
  show,
  onClose,
  id,
  organizationId,
  quizzes,
  title,
  isAutoQuiz = false,
  isScheduledQuiz = false,
  quiz_id,
  exam_id,
  quiz_team_id,
  autoQuizCategories,
  mode = INVITE_USERS_TO_EXAM_MODE.CREATE,
  editing_scheduled_quiz_id,
  preselectedNumQuestions = null,
  preselectedPlayersToInvite = INITIAL_VALUES._selectedPlayerIDs,
  preselectedDifficulty = INITIAL_VALUES._difficulty,
  preselectedDays = INITIAL_VALUES._scheduledDays,
  preselectedTime = INITIAL_VALUES._scheduledHour,
  preselectedQuizCategories = [],
  ...rest
}) {
  const isMounted = useRef(false);
  const [submitting, setSubmitting] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const groupData = useSelector((state) => state.DataGroupState?.data || []);
  const groupMap = useSelector((state) => state.DataGroupState?.map || {});
  const teams = useSelector(
    (state) => state.DataTeamState.data || [],
    shallowEqual
  );
  const players = useSelector(
    (state) => state.DataPlayerState.data || [],
    shallowEqual
  );
  const memoizedTeams = useMemo(() => teams, [teams]);
  const memoizedPlayers = useMemo(() => players, [players]);
  const _organizationId = useSelector(
    (state) => state.DataOrganizationState?.data?._id
  );
  const preInvitedPlayers = useSelector(
    (state) => state.DataExamState?.inviteMap?.[quiz_id]?.users || []
  );
  const preTakenPlayers = useSelector(
    (state) => state.DataExamState?.takenMap?.[quiz_id]?.users || []
  );
  const [selectedPlayerIDs, setSelectedPlayerIDs] = useState(
    INITIAL_VALUES._selectedPlayerIDs
  );
  const [rawTeamPlayersDict, setRawTeamPlayersDict] = useState({});
  const memoizedRawTeamPlayersDict = useMemo(
    () => rawTeamPlayersDict,
    [rawTeamPlayersDict]
  );
  const memoizedSelectedPlayerIDs = useMemo(
    () => selectedPlayerIDs,
    [selectedPlayerIDs]
  );
  const memoizedPreTakenPlayers = useMemo(
    () => preTakenPlayers,
    [preTakenPlayers]
  );
  const memoizedPreInvitedPlayers = useMemo(
    () => preInvitedPlayers,
    [preInvitedPlayers]
  );

  const [teamPlayersDict, setTeamPlayersDict] = useState({});
  const [activeTeamID, setActiveTeamID] = useState(null);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedTeamIDs, setSelectedTeamIDs] = useState(
    INITIAL_VALUES._selectedTeamIDs
  );
  const [selectedAllFromTeamDict, setSelectedAllFromTeamDict] = useState({});
  const [selectedAnyFromTeamDict, setSelectedAnyFromTeamDict] = useState({});
  const [
    selectedSomeButNotAllFromTeamDict,
    setSelectedSomeButNotAllFromTeamDict,
  ] = useState({});
  const [buttonText, setButtonText] = useState('Create Quiz');
  const [loading, setLoading] = useState({});
  const [_selectedGroupsCount, _setSelectedGroupsCount] = useState(
    INITIAL_VALUES._selectedCounts
  );
  const [_selectedTeamsCount, _setSelectedTeamsCount] = useState(
    INITIAL_VALUES._selectedCounts
  );
  const [_selectedPlayersCount, _setSelectedPlayersCount] = useState(
    INITIAL_VALUES._selectedCounts
  );
  const [isScheduling, setIsScheduling] = useState(
    INITIAL_VALUES._isScheduling
  );
  const {
    tier,
    type,
    showSubscriptionModal,
    numQuizzesThisThreshold,
    numAutoQuizzesThisThreshold,
    numScheduledQuizzes,
  } = useAccountSelectors();

  const [numberOfQuestions, setNumberOfQuestions] = useState(
    Math.ceil(
      (ACCESS_ACL[tier][ACCESS_ACTIONS.NUM_QUESTIONS_PER_AUTO_QUIZ].threshold +
        AUTO_QUIZ_MIN_NUMBER_QUESTIONS) /
        2
    ) || INITIAL_VALUES._numberOfQuestions
  );
  const [isInitialized, setIsInitialized] = useState(
    INITIAL_VALUES._isInitialized
  );
  const [difficulty, setDifficulty] = useState(INITIAL_VALUES._difficulty);
  const [scheduledHour, setScheduledHour] = useState(
    INITIAL_VALUES._scheduledHour
  );
  const [scheduledDays, setScheduledDays] = useState(
    INITIAL_VALUES._scheduledDays
  );
  const [scheduleSummaryText, setScheduleSummaryText] = useState(
    INITIAL_VALUES._scheduleSummaryText
  );
  const [customQuizTitle, setCustomQuizTitle] = useState(
    title || INITIAL_VALUES._title
  );
  const [customQuizCategoriesString, setCustomQuizCategoriesString] = useState(
    title || INITIAL_VALUES._quizCategoriesString
  );

  /* Auto Quiz Details */
  const [selectedDifficultyRange, setSelectedDifficultyRange] = useState(
    INITIAL_VALUES._selectedDifficultyRange
  );

  /* Auto Quiz Details */
  const [selectedNumQuestions, setSelectedNumQuestions] = useState(
    INITIAL_VALUES._selectedNumQuestions
  );

  const [inviteLink, setInviteLink] = useState(
    'https://sixtool.page.link/player-take-a-quiz-from-invite'
  );
  const user = useSelector((state) => state.UserState);
  const selectedTeamID = useSelector(
    (state) => state.DataTeamState?.teamData?._id
  );

  useEffect(() => {
    if (show) {
      if (!isInitialized) {
        let _selectedPlayerIDs = { ...preselectedPlayersToInvite };
        Object.entries(preselectedPlayersToInvite).forEach(
          ([teamID, value]) => {
            if (value?.all === true) {
              _selectedPlayerIDs[teamID] = {
                players: (teamPlayersDict[teamID] || []).map((p) => p.id),
              };
            }
          }
        );
        setSelectedPlayerIDs(
          _selectedPlayerIDs || INITIAL_VALUES._selectedPlayerIDs
        );
        setNumberOfQuestions(
          preselectedNumQuestions ||
            Math.ceil(
              (ACCESS_ACL[tier][ACCESS_ACTIONS.NUM_QUESTIONS_PER_AUTO_QUIZ]
                .threshold +
                AUTO_QUIZ_MIN_NUMBER_QUESTIONS) /
                2
            ) ||
            INITIAL_VALUES._numberOfQuestions
        );
        setDifficulty();
        setIsInitialized(true);
        setCustomQuizTitle(title || INITIAL_VALUES._title);
        setCustomQuizCategoriesString(
          title || INITIAL_VALUES._quizCategoriesString
        );
        setScheduledDays(preselectedDays || INITIAL_VALUES._scheduledDays);
        setScheduledHour(preselectedTime || INITIAL_VALUES._scheduledHour);
      }
    } else {
      setIsInitialized(false);
      setSelectedPlayerIDs(INITIAL_VALUES._selectedPlayerIDs);
      setSelectedTeamIDs(INITIAL_VALUES._selectedTeamIDs);
      setSelectedAllFromTeamDict(INITIAL_VALUES._selectedAllFromTeamDict);
      setSelectedAnyFromTeamDict(INITIAL_VALUES._selectedAnyFromTeamDict);
      setSelectedSomeButNotAllFromTeamDict(
        INITIAL_VALUES._selectedSomeButNotAllFromTeamDict
      );
      _setSelectedGroupsCount(INITIAL_VALUES._selectedCounts);
      _setSelectedTeamsCount(INITIAL_VALUES._selectedCounts);
      _setSelectedPlayersCount(INITIAL_VALUES._selectedCounts);
      setIsScheduling(INITIAL_VALUES._isScheduling);
      setDifficulty(INITIAL_VALUES._difficulty);
      setScheduledHour(INITIAL_VALUES._scheduledHour);
      setScheduledDays(INITIAL_VALUES._scheduledDays);
      setScheduleSummaryText(INITIAL_VALUES._scheduleSummaryText);
      setSelectedDifficultyRange(INITIAL_VALUES._selectedDifficultyRange);
      setSelectedNumQuestions(INITIAL_VALUES._selectedNumQuestions);
      setNumberOfQuestions(INITIAL_VALUES._numberOfQuestions);
    }
  }, [show]);

  useEffect(() => {
    // Assuming selectedPlayerIDs is an object with fields that might have a 'players' array
    const playerIds = flatMap(
      values(selectedPlayerIDs),
      (value) => value.players || []
    );

    // Get unique player IDs
    const uniquePlayerIds = uniq(playerIds);

    // Update selected players count
    _setSelectedPlayersCount(uniquePlayerIds.length);
  }, [selectedPlayerIDs]);
  useEffect(() => {
    _setSelectedTeamsCount((selectedTeamIDs || []).length);
  }, [selectedTeamIDs]);

  useEffect(() => {
    setActiveTeamID(quiz_team_id || selectedTeamID || teams?.[0]?._id);
  }, [quiz_team_id, selectedTeamID, teams]);

  useEffect(() => {
    let _selectedTeamIds = new Set();
    Object.keys(selectedPlayerIDs || {}).forEach((_tId) => {
      if ((selectedPlayerIDs?.[_tId]?.players || []).length > 0) {
        _selectedTeamIds.add(_tId);
      } else {
        _selectedTeamIds.delete(_tId);
      }
    });
    setSelectedTeamIDs(Array.from(_selectedTeamIds));
  }, [selectedPlayerIDs]);

  useEffect(() => {
    let _teamPlayersDict = [...memoizedTeams].reduce((acc, team) => {
      acc[team._id] = [];

      return acc;
    }, {});
    // Sort players by name alphabetically
    if (_.isArray(memoizedPlayers) && (memoizedPlayers || []).length > 0) {
      let _players = [...memoizedPlayers];
      _players.sort((a, b) =>
        (a.name || '').toLowerCase().localeCompare((b.name || '').toLowerCase())
      );
      // Populate the resulting object with sorted players
      _players.forEach((player) => {
        player._teams.forEach((teamId) => {
          // Find the team in the result object
          const teamArray = _teamPlayersDict[teamId];
          // Add player data to the teamArray
          if (_.isArray(teamArray)) {
            teamArray.push({
              id: player._id,
              name: player.name,
              groups: player._groups,
              selected: false, // Default value for the "selected" field
            });
          }
        });
      });
    }
    setRawTeamPlayersDict(_teamPlayersDict);
  }, [memoizedTeams, memoizedPlayers]);

  useEffect(() => {
    let _selectedAllFromTeamDict = {};
    let _selectedAnyFromTeamDict = {};
    let _selectedSomeButNotAllFromTeamDict = {};
    Object.keys(teamPlayersDict || {}).forEach((teamID) => {
      _selectedAllFromTeamDict[teamID] = every(
        teamPlayersDict[teamID],
        (obj) => {
          return obj.selected || obj.preTaken || obj.preInvited;
        }
      );
      _selectedAnyFromTeamDict[teamID] = some(
        teamPlayersDict[teamID],
        (obj) => {
          return obj.selected;
        }
      );
      _selectedSomeButNotAllFromTeamDict[teamID] =
        _selectedAnyFromTeamDict[teamID] && !_selectedAllFromTeamDict[teamID];
    });
    _selectedAllFromTeamDict.all = every(
      Object.values(_selectedAllFromTeamDict),
      (obj) => {
        return obj;
      }
    );

    _selectedAnyFromTeamDict.all = every(
      Object.values(_selectedAnyFromTeamDict),
      (obj) => {
        return obj;
      }
    );
    _selectedSomeButNotAllFromTeamDict.all = every(
      Object.values(_selectedSomeButNotAllFromTeamDict),
      (obj) => {
        return obj;
      }
    );
    _selectedSomeButNotAllFromTeamDict.any = some(
      Object.values(_selectedSomeButNotAllFromTeamDict),
      (obj) => {
        return obj;
      }
    );
    setSelectedAllFromTeamDict(_selectedAllFromTeamDict);
    setSelectedAnyFromTeamDict(_selectedAnyFromTeamDict);
    setSelectedSomeButNotAllFromTeamDict(_selectedSomeButNotAllFromTeamDict);
  }, [teamPlayersDict]);
  useEffect(() => {
    let text = isAutoQuiz ? 'Create Quiz' : '';
    if (_selectedPlayersCount > 0) {
      text += isAutoQuiz ? ' & ' : '';
      text += 'Invite ';
      if (selectedSomeButNotAllFromTeamDict.any === false) {
        text += `All Player${_selectedPlayersCount === 1 ? '' : 's'}`;
        if (selectedAllFromTeamDict.all !== true) {
          text += ` from ${_selectedTeamsCount} Team${
            _selectedTeamsCount === 1 ? '' : 's'
          }`;
        }
      } else {
        text += `${_selectedPlayersCount} Player${
          _selectedPlayersCount === 1 ? '' : 's'
        }`;
        if ((Object.keys(selectedAllFromTeamDict || {}) || []).length > 2) {
          text += ` from ${_selectedTeamsCount} Team${
            _selectedTeamsCount === 1 ? '' : 's'
          }`;
        }
      }
    }
    if (text.length === 0) {
      text = 'Invite Players';
    }
    setButtonText(text);
  }, [
    isAutoQuiz,
    selectedAllFromTeamDict,
    selectedAnyFromTeamDict,
    selectedSomeButNotAllFromTeamDict,
  ]);

  useEffect(() => {
    if (!(isString(title) && title.length > 0)) {
      try {
        const _string = concatenateStringFromArray([
          ...new Set((autoQuizCategories || []).flatMap((obj) => obj.label)),
        ]);
        setCustomQuizTitle(_string);
        setCustomQuizCategoriesString(_string);
      } catch (error) {
        console.error('did not have a properly-formatted autoquiz category');
      }
    }
  }, [autoQuizCategories]);

  useEffect(() => {
    let _teamPlayersDict = { ...memoizedRawTeamPlayersDict };
    const _tIDs =
      union(
        keys(memoizedSelectedPlayerIDs),
        keys(memoizedRawTeamPlayersDict)
      ) || [];
    let hasUpdate = false;
    _tIDs.forEach((_tID) => {
      if (
        _.isObject(_teamPlayersDict) &&
        _.isString(_tID) &&
        _tID.length > 0 &&
        _.isArray(memoizedRawTeamPlayersDict[_tID])
      ) {
        let _players = [..._teamPlayersDict[_tID]];
        _players.forEach((player, index) => {
          if (
            _teamPlayersDict[_tID][index].selected !==
              (memoizedSelectedPlayerIDs?.[_tID]?.players || []).includes(
                player.id
              ) ||
            _teamPlayersDict[_tID][index].preInvited !==
              memoizedPreInvitedPlayers.includes(player.id) ||
            _teamPlayersDict[_tID][index].preTaken !==
              memoizedPreTakenPlayers.includes(player.id)
          ) {
            hasUpdate = true;
            _teamPlayersDict[_tID][index] = {
              ..._teamPlayersDict[_tID][index],
              ...{
                selected: (
                  memoizedSelectedPlayerIDs?.[_tID]?.players || []
                ).includes(player.id),
                preInvited: memoizedPreInvitedPlayers.includes(player.id),
                preTaken: memoizedPreTakenPlayers.includes(player.id),
              },
            };
          }
        });
      }
    });
    if (hasUpdate) {
      setTeamPlayersDict({ ..._teamPlayersDict });
    }
    let _selectedGroups = new Set();
    groupData
      .filter((g) => g.teamId === activeTeamID)
      .forEach((g) => {
        if (
          g.players.every((p) =>
            (selectedPlayerIDs?.[activeTeamID]?.players || []).includes(p)
          )
        ) {
          _selectedGroups.add(g.id);
        }
      });
    if (!isEqual(selectedGroups, Array.from(_selectedGroups))) {
      setSelectedGroups(Array.from(_selectedGroups));
    }
  }, [
    memoizedSelectedPlayerIDs,
    activeTeamID,
    memoizedPreTakenPlayers,
    memoizedPreInvitedPlayers,
    memoizedRawTeamPlayersDict,
  ]);

  const resetModal = () => {
    setSelectedPlayerIDs({});
    setSelectedGroups([]);
    setNumberOfQuestions(AUTO_QUIZ_DEFAULT_NUMBER_QUESTIONS);
  };

  const cancelModal = () => {
    resetModal();
    onClose();
  };

  const handleCustomQuizTitleChange = (event) => {
    setCustomQuizTitle(event.target.value);
  };

  const handleNumberOfQuestionsSliderChange = (event) => {
    setNumberOfQuestions(parseInt(event.target.value));
  };

  const handleDifficultySliderChange = (event) => {
    setDifficulty(parseInt(event.target.value));
  };

  const handleScheduledHourSliderChange = (event) => {
    setScheduledHour(parseInt(event.target.value));
  };

  useEffect(() => {
    isMounted.current = show;
    setInviteLink(
      `https://sixtool.page.link/player-take-a-quiz-from-invite?o=${
        _organizationId || organizationId || ''
      }&e=${id || ''}&q=${
        !!quizzes && _.isArray(quizzes) && quizzes.length > 0 ? quizzes[0] : ''
      }`
    );
  }, [show, user.data, quizzes, id]);

  const scheduleQuiz = async () => {
    const isUpdating =
      mode === INVITE_USERS_TO_EXAM_MODE.EDIT &&
      isString(editing_scheduled_quiz_id) &&
      editing_scheduled_quiz_id.length > 0;
    setSubmitting(true);
    let teamIds = [...(Object.keys(selectedPlayerIDs || {}) || [])]; //TO-DO
    if ((teamIds || []).length === 0) {
      toast(
        `An error occurred, please try again. If this issue continues, please contact ${EMAIL_ADDRESSES.SUPPORT}`,
        { type: 'error' }
      );
      setSubmitting(false);
    } else {
      const scheduleQuizCallable = functions.httpsCallable(
        isUpdating ? 'updateScheduledQuizCallable' : 'scheduleQuizCallable'
      );
      toast(
        `${
          isUpdating ? 'Updating scheduled' : 'Scheduling '
        } quiz. This may take a minute, you can continue to use the app.`,
        {
          type: 'info',
        }
      );
      const userTime = moment.tz(scheduledHour, 'HH', moment.tz.guess());
      const estTime = userTime.clone().tz('America/New_York');
      const hourEST = parseInt(estTime.format('HH'));
      let _playersToInvite = { ...selectedPlayerIDs };
      Object.keys(selectedAllFromTeamDict || {}).forEach((teamID) => {
        if (
          isString(teamID) &&
          teamID !== 'all' &&
          selectedAllFromTeamDict[teamID] === true
        ) {
          _playersToInvite[teamID] = { all: true };
        }
      });

      let categories = [];
      try {
        if (!isUpdating) {
          categories = [
            ...new Set(
              (autoQuizCategories || []).flatMap((obj) => obj.categories)
            ),
          ];
        }
      } catch {
        console.error('could not generate categories');
      }

      if (isUpdating) {
        categories = [...preselectedQuizCategories];
      }
      let quizData = {};
      try {
        quizData = {
          categories: [...categories],
          difficulty: [...selectedDifficultyRange],
          numQuestions: numberOfQuestions,
          title: `${customQuizTitle}`,
          team_ids: [...teamIds],
          organization_id: `${
            _organizationId || rest.organizationId || user.organizationId || 'a'
          }`,
          playersToInvite: { ..._playersToInvite },
          days: [...scheduledDays],
          hourEST,
        };
      } catch (error) {
        toast(
          `An error occurred, please try again. If this issue continues, please contact ${EMAIL_ADDRESSES.SUPPORT}`,
          { type: 'error' }
        );
        setSubmitting(false);
        return;
      }
      if (isUpdating) {
        quizData.exams_scheduled_id = `${editing_scheduled_quiz_id}`;
      }
      setSubmitting(false);
      cancelModal();
      try {
        const scheduleQuizResult = await scheduleQuizCallable(quizData);
        if (
          !(
            !!scheduleQuizResult &&
            !!scheduleQuizResult.data &&
            _.isString(scheduleQuizResult.data) &&
            scheduleQuizResult.data.length > 0
          )
        ) {
          toast(
            `An error occurred, please try again. If this issue continues, please contact ${EMAIL_ADDRESSES.SUPPORT}`,
            { type: 'error' }
          );
        }
      } catch (error) {
        toast(
          `An error occurred, please try again. If this issue continues, please contact ${EMAIL_ADDRESSES.SUPPORT}`,
          { type: 'error' }
        );
      }

      // setSubmitting(false);
    }
  };
  const createQuizAndInvitePlayers = async () => {
    setSubmitting(true);
    if (isAutoQuiz) {
      let teamIds = [...(Object.keys(selectedPlayerIDs || {}) || [])]; //TO-DO
      if ((teamIds || []).length === 0) {
        toast(
          `An error occurred, please try again. If this issue continues, please contact ${EMAIL_ADDRESSES.SUPPORT}`,
          { type: 'error' }
        );
      } else {
        const qCf = functions.httpsCallable(
          'createQuizWithRandomQuestionsForCategoriesCallable'
        );
        toast(
          'Creating quiz and inviting players. This may take a minute, you can continue to use the app.',
          { type: 'error' }
        );
        setSubmitting(false);
        cancelModal();

        const createQuizCallableResult = await qCf({
          categories: [
            ...new Set(
              (autoQuizCategories || []).flatMap((obj) => obj.categories)
            ),
          ],
          difficultyRange: selectedDifficultyRange,
          numQuestions: numberOfQuestions,
          title: customQuizTitle,
          teamIds,
          organizationId:
            _organizationId ||
            rest.organizationId ||
            user.organizationId ||
            'a',
          playersToInvite: selectedPlayerIDs,
        });
        console.log({
          categories: [
            ...new Set(
              (autoQuizCategories || []).flatMap((obj) => obj.categories)
            ),
          ],
          difficultyRange: selectedDifficultyRange,
          numQuestions: numberOfQuestions,
          title: customQuizTitle,
          teamIds,
          organizationId:
            _organizationId ||
            rest.organizationId ||
            user.organizationId ||
            'a',
          playersToInvite: selectedPlayerIDs,
        });
        console.log(createQuizCallableResult);
        if (
          !!createQuizCallableResult &&
          !!createQuizCallableResult.data &&
          _.isObject(createQuizCallableResult.data) &&
          _.isArray(Object.keys(createQuizCallableResult.data)) &&
          Object.keys(createQuizCallableResult.data).length > 0
        ) {
          console.log('success');
        } else {
          toast(
            `An error occurred, please try again. If this issue continues, please contact ${EMAIL_ADDRESSES.SUPPORT}`,
            { type: 'error' }
          );
          setSubmitting(false);
        }
      }
    } else {
      const inviteUserIDsToQuizCallable = functions.httpsCallable(
        'inviteUserIDsToQuizCallable'
      );
      let inviteData = {
        preInvitedPlayers,
        teamUserIDs: selectedPlayerIDs,
        examId: exam_id,
        quizId: quiz_id,
        organization_id: _organizationId || organizationId,
        owningOrganization: organizationId,
        exam_title: title,
        quiz_title: title,
      };
      cancelModal();
      setSubmitting(false);
      toast(
        'Inviting players. This may take a minute, you can continue to use the app.',
        { type: 'error' }
      );
      let result = await inviteUserIDsToQuizCallable(inviteData);
      if (result.data === true) {
        console.log('success');
      } else {
        toast(
          `An error occurred, please try inviting players again. If this issue continues, please contact ${EMAIL_ADDRESSES.SUPPORT}`,
          { type: 'error' }
        );
      }

      // const inviteUsersOfGroupsCallable = functions.httpsCallable(
      //   'inviteUsersOfGroupsCallable'
      // );
      // const { _selectedGroups, _selectedTeams } =
      //   _transformSelectedGroupsAndTeams();
      // let groups = selectedGroupsData.filter((g) =>
      //   _selectedGroups.includes(g.id)
      // );
      // let teamGroups = _selectedTeams.map((t) => {
      //   return { id: 'all', teamId: t };
      // });
      // let inviteData = {
      //   groups: [...groups, ...teamGroups],
      //   organization_id: user.organizationId || 'a',
      //   owningOrganization: rest.organizationId || user.organizationId || 'a',
      //   examId: exam_id,
      //   quizId: quiz_id,
      //   exam_title: title,
      //   quiz_title: title,
      //   inviter_name: user?.data?.name,
      //   inviter_id: user?.data?._id,
      // };
      // let result = await inviteUsersOfGroupsCallable(inviteData);
      // setSubmitting(false);
      // if (result.data === true) {
      //   toast('Invites sent', { type: 'success' });
      //   resetModal();
      //   onClose();
      // } else {
      //   toast(
      //     `An error occurred, please try again. If this issue continues, please contact ${EMAIL_ADDRESSES.SUPPORT}`,
      //     { type: 'error' }
      //   );
      // }
      // setTeamsToInviteAll([]);
    }
  };

  const toggleGroupSelectedById = (groupId) => {
    // This is only called by clicking on a group button
    let newSelectedGroups = [...selectedGroups];
    let invitedGroupsSet = new Set(selectedPlayerIDs?.[activeTeamID]?.groups);
    var index = newSelectedGroups.indexOf(groupId);

    let newSelectedPlayers = new Set([
      ...(selectedPlayerIDs?.[activeTeamID]?.players || []),
    ]);
    if (index === -1) {
      invitedGroupsSet.add(groupId);
      newSelectedGroups.push(groupId);
      ((groupMap?.[groupId] || {}).players || []).forEach((playerID) => {
        newSelectedPlayers.add(playerID);
      });
    } else {
      invitedGroupsSet.delete(groupId);
      newSelectedGroups.splice(index, 1);
      let _newSelectedGroupsPlayersSet = new Set();
      if (_.isArray(newSelectedGroups) && newSelectedGroups.length > 0) {
        newSelectedGroups.forEach((gId) => {
          ((groupMap?.[gId] || {}).players || []).forEach((pID) => {
            _newSelectedGroupsPlayersSet.add(pID);
          });
        });
      }
      const _newSelectedGroupsPlayers = Array.from(
        _newSelectedGroupsPlayersSet
      );
      ((groupMap?.[groupId] || {}).players || []).forEach((playerID) => {
        if (!_newSelectedGroupsPlayers.includes(playerID)) {
          newSelectedPlayers.delete(playerID);
        }
      });
    }
    let _selectedPlayerIDs = { ...selectedPlayerIDs };
    _selectedPlayerIDs[activeTeamID] = {
      players: Array.from(newSelectedPlayers),
      groups: Array.from(invitedGroupsSet),
    };
    setSelectedPlayerIDs(_selectedPlayerIDs);
  };

  const togglePlayersSelectedById = (playerId) => {
    let newSelectedPlayers = [
      ...(selectedPlayerIDs?.[activeTeamID]?.players || []),
    ];
    var index = newSelectedPlayers.indexOf(playerId);
    if (index === -1) {
      newSelectedPlayers.push(playerId);
    } else {
      newSelectedPlayers.splice(index, 1);
    }
    let _selectedPlayerIDs = { ...selectedPlayerIDs };
    _selectedPlayerIDs[activeTeamID] = { players: [...newSelectedPlayers] };
    setSelectedPlayerIDs(_selectedPlayerIDs);
  };

  const renderSelect = (item, _index) => (
    <td
      onClick={() => {
        if (item.preTaken !== true) {
          togglePlayersSelectedById(item.id);
        }
      }}
      style={{ width: 50, padding: '2px 12px' }}
    >
      <>
        {item.preTaken !== true && (
          <>
            {teamPlayersDict?.[activeTeamID]?.[_index]?.selected === true ||
            (selectedPlayerIDs?.[activeTeamID]?.players || []).includes(
              item.id
            ) ? (
              <FaCheckSquare
                color={HEX_COLOR_GREEN_SUCCESS}
                style={{
                  cursor: 'pointer',
                  color: `${HEX_COLOR_GREEN_SUCCESS} !important`,
                }}
              />
            ) : (
              <FaRegSquare style={{ cursor: 'pointer' }} />
            )}
          </>
        )}
      </>
    </td>
  );

  const renderPlayerNames = (item) => (
    <td
      onClick={() => {
        if (item.preTaken !== true) {
          togglePlayersSelectedById(item.id);
        }
      }}
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        padding: '2px 12px',
      }}
    >
      <span>{item.name}</span>
      <span>
        {item.preTaken === true
          ? 'Quiz Completed'
          : item.preInvited === true
          ? 'Already invited'
          : ''}
      </span>
    </td>
  );

  const renderDetails = (item) => {
    <td
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: '2px 12px',
      }}
    >
      {item.preTaken === true
        ? 'Quiz Completed'
        : item.preInvited === true
        ? 'Already invited'
        : ''}
    </td>;
  };

  const renderTeamInviteTable = () => {
    return (
      <>
        <div className="quiz-invite-groups">
          {((groupData || []).filter((g) => g.teamId === activeTeamID) || [])
            .filter((g) => g.players.length > 0)
            .map((g, _i) => (
              <CButton
                key={`${_i}`}
                className="quiz-invite-group-button"
                color={`${selectedGroups.includes(g.id) ? 'success' : 'dark'}`}
                variant={`${selectedGroups.includes(g.id) ? '' : 'outline'}`}
                onClick={async () => {
                  toggleGroupSelectedById(g.id);
                }}
              >
                <>
                  {selectedGroups.includes(g.id) ? (
                    <FaCheckSquare />
                  ) : (
                    <FaRegSquare />
                  )}
                </>
                {g.title}
              </CButton>
            ))}
        </div>
        <div className="group-invite-table-wrapper">
          <div className="quiz-invite-team-table-header">
            <CTooltip
              content={
                selectedAllFromTeamDict[activeTeamID] &&
                (selectedPlayerIDs?.[activeTeamID]?.players || []).length > 0
                  ? `Deselect all players`
                  : (selectedPlayerIDs?.[activeTeamID]?.players || []).length >
                    0
                  ? `Select remaining players`
                  : `Select all players`
              }
            >
              <div
                className="quiz-invite-all-team-checkbox"
                onClick={() => {
                  let _selectedPlayerIDs = { ...selectedPlayerIDs };
                  if (
                    selectedAllFromTeamDict[activeTeamID] &&
                    (selectedPlayerIDs?.[activeTeamID]?.players || []).length >
                      0
                  ) {
                    _selectedPlayerIDs[activeTeamID] = [];
                    // setSelectedPlayerIDs({});
                  } else {
                    _selectedPlayerIDs[activeTeamID] = {
                      players: (teamPlayersDict?.[activeTeamID] || []).map(
                        (p) => p.id
                      ),
                    };
                  }
                  setSelectedPlayerIDs(_selectedPlayerIDs);
                }}
              >
                {selectedAllFromTeamDict[activeTeamID] ? (
                  <FaCheckSquare color={HEX_COLOR_GREEN_SUCCESS} />
                ) : (
                  <FaRegSquare />
                )}
              </div>
            </CTooltip>
            <div style={{ fontWeight: 700, padding: 12 }}>PLAYERS</div>
          </div>
          <CDataTable
            items={teamPlayersDict?.[activeTeamID]}
            itemsPerPage={50}
            hover
            loading={loading?.[activeTeamID] === true}
            pagination
            clickableRows
            fields={[
              {
                key: 'select',
                label: null,
                _style: { textAlign: 'left', width: '50px' },
              },
              {
                key: 'name',
                label: null,
                _classes: 'font-weight-bold text-left',
                _style: { textAlign: 'left', width: 'auto' },
              },
            ]}
            noItemsViewSlot={
              <>
                {loading?.[activeTeamID] === true ? (
                  <p className="d-flex align-content-center justify-content-center pt-4 pb-4">
                    <span className="pl-2 font-xl">Loading players</span>
                  </p>
                ) : (
                  <p className="d-flex align-content-center justify-content-center pt-4 pb-4">
                    <FiAlertCircle size="30" color="#e55353" />
                    <span className="pl-2 font-xl">No players</span>
                  </p>
                )}
              </>
            }
            scopedSlots={{
              select: renderSelect,
              // players: renderPlayers,
              name: renderPlayerNames,
            }}
          />
        </div>
      </>
    );
  };

  const _renderQuizSummary = (className) => (
    <div className={className}>
      <CModalHeader>
        <CModalTitle>Quiz Summary</CModalTitle>
      </CModalHeader>
      <p className="quiz-summary-paragraph">
        This quiz will be named <strong>"{customQuizTitle}"</strong> and have{' '}
        <strong>{numberOfQuestions}</strong> random question
        {numberOfQuestions === 1 ? '' : 's'} from the{' '}
        <strong>{customQuizCategoriesString}</strong> categor
        {(autoQuizCategories || []).length === 1 ? 'y' : 'ies'}. It will be sent
        to {_selectedTeamsCount === 1 ? '' : 'a total of '}
        <strong>{_selectedPlayersCount}</strong> player
        {_selectedPlayersCount === 1 ? '' : 's'}{' '}
        {_selectedTeamsCount === 1 ? 'on' : 'across'}{' '}
        <strong>{_selectedTeamsCount}</strong> team
        {_selectedTeamsCount === 1 ? '' : 's'}.
      </p>
    </div>
  );

  const toggleScheduledDay = (day) => {
    let days = new Set(scheduledDays);
    if (days.has(day)) {
      days.delete(day);
    } else {
      checkAccess({
        action: [
          ACCESS_ACTIONS.SCHEDULE_QUIZZES,
          ACCESS_ACTIONS.CREATE_AUTO_QUIZ,
        ],
        thresholdCount: [
          numScheduledQuizzes + days.size + 1,
          numAutoQuizzesThisThreshold + days.size + 1,
        ],
        tier,
        type,
        showSubscriptionModal,
      }).doWithAccess(() => {
        setIsScheduling(true);
        days.add(day);
      });
    }
    let daysArray = [...days].sort();

    if (daysArray[0] === 0) {
      daysArray.shift();
      daysArray.push(0);
    }
    setScheduledDays(daysArray);
  };

  useEffect(() => {
    if ((scheduledDays || []).length === 0) {
      setScheduleSummaryText('');
    } else {
      const dayNames = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ];
      const selectedDays = (scheduledDays || []).map(
        (day) => dayNames[(day + 6) % 7]
      );
      const prefix = 'Every ';
      const suffix = ` at ${moment()
        .startOf('day')
        .add(scheduledHour, 'hours')
        .format('ha')}`;
      if (selectedDays.length === 1) {
        setScheduleSummaryText(`${prefix}${selectedDays[0]}${suffix}`);
      } else if (selectedDays.length === 2) {
        setScheduleSummaryText(
          `${prefix}${selectedDays[0]} and ${selectedDays[1]}${suffix}`
        );
      } else {
        const firstDays = [...selectedDays];
        const lastDay = firstDays.pop();
        console.log({ scheduledDays, firstDays, lastDay });
        setScheduleSummaryText(
          `${prefix}${firstDays.join(', ')}, and ${lastDay}${suffix}`
        );
      }
    }
  }, [scheduledDays, scheduledHour]);

  const _renderSchedule = () => (
    <div className="invite-users-modal-content-wrapper">
      <CModalHeader
        style={{ alignItems: 'center', borderBottom: '2px solid #efefef' }}
      >
        <CLink
          style={{
            color: '#162630',
            fontWeight: 'bolder',
            fontSize: '0.8em',
            display: 'flex',
            alignItems: 'center',
            lineHeight: '0.8em',
            marginLeft: -3,
          }}
          onClick={() => {
            setIsScheduling(false);
          }}
        >
          <FaAngleLeft style={{ marginTop: -2 }} />
          Back
        </CLink>
        <div>Choose Quiz Schedule</div>
        <CLink
          disabled={true}
          style={{
            visibility: 'hidden',
          }}
        >
          <FaAngleLeft style={{ marginTop: -2 }} />
          Back
        </CLink>
      </CModalHeader>
      <CModalBody>
        <h3>Choose Day(s)</h3>
        <CRow>
          {Array.from({ length: 7 }, (_, index) => index).map((day, dIdx) => (
            <CCol key={dIdx} className={'schedule-choose-day-column'}>
              <CButton
                variant={
                  scheduledDays.includes((day + 1) % 7) ? null : 'outline'
                }
                color={
                  scheduledDays.includes((day + 1) % 7) ? 'success' : 'light'
                }
                style={
                  scheduledDays.includes((day + 1) % 7)
                    ? { color: 'white' }
                    : {}
                }
                onClick={() => {
                  toggleScheduledDay((day + 1) % 7);
                }}
              >
                {daysOfWeek[(day + 1) % 7]}
              </CButton>
            </CCol>
          ))}
        </CRow>
        <h3 style={{ marginTop: 30 }}>Choose Time of Day</h3>
        <CRow>
          <FormGroup style={{ margin: '0px auto 1rem', width: '50%' }}>
            <InputGroup>
              <div className="schedule-choose-time-wrapper">
                <div className="schedule-choose-time-slider-wrapper">
                  <span>12a</span>
                  <input
                    type="range"
                    min={0}
                    max={23}
                    name="scheuldedHour"
                    id="scheuldedHour"
                    value={scheduledHour}
                    onChange={handleScheduledHourSliderChange}
                  />
                  <span>11p</span>
                </div>
                <span>{`${moment()
                  .startOf('day')
                  .add(scheduledHour, 'hours')
                  .format('h:mma')}${
                  scheduledHour === 0
                    ? ' (midnight)'
                    : scheduledHour === 12
                    ? ' (noon)'
                    : ''
                }`}</span>
              </div>
            </InputGroup>
          </FormGroup>
        </CRow>
      </CModalBody>

      <CModalFooter>
        {submitting ? (
          <LoadingMessage
            firstMessage={
              mode === INVITE_USERS_TO_EXAM_MODE.EDIT
                ? 'Saving Changes...'
                : 'Creating Quiz...'
            }
          />
        ) : (
          <>
            <div style={{ flex: 1, fontSize: '1rem' }}>
              {scheduleSummaryText}
            </div>
            <div style={{ display: 'flex', gap: 6 }}>
              <CButton
                color="danger"
                variant="outline"
                disabled={submitting}
                onClick={() => {
                  setIsScheduling(false);
                }}
              >
                Cancel
              </CButton>
              <CButton
                color="success"
                disabled={submitting || (scheduledDays || []).length === 0}
                className="mr-2"
                onClick={() => {
                  checkAccess({
                    action: [
                      ACCESS_ACTIONS.SCHEDULE_QUIZZES,
                      ACCESS_ACTIONS.CREATE_AUTO_QUIZ,
                    ],
                    thresholdCount: [
                      numScheduledQuizzes + (scheduledDays || []).length,
                      numAutoQuizzesThisThreshold +
                        (scheduledDays || []).length,
                    ],
                    tier,
                    type,
                    showSubscriptionModal,
                  }).doWithAccess(() => {
                    scheduleQuiz();
                  });
                }}
              >
                <FaRegClock style={{ marginRight: 6, marginTop: -2 }} />
                {mode === INVITE_USERS_TO_EXAM_MODE.EDIT
                  ? 'Apply Changes'
                  : 'Schedule Quiz'}
              </CButton>
            </div>
          </>
        )}
      </CModalFooter>
    </div>
  );

  return (
    <>
      <CModal
        className="xxl"
        size={isScheduling ? 'lg' : 'xl'}
        show={show}
        backdrop={true}
        scrollable={false}
        onClose={onClose}
      >
        {isScheduling ? (
          _renderSchedule()
        ) : (
          <>
            <CRow
              className={`invite-users-modal-content-wrapper${
                isAutoQuiz === true ? ' auto-quiz' : ' re-invite'
              }`}
            >
              <CCol className="invite-users-modal-content-choose-team-col">
                <CModalHeader>
                  <CModalTitle style={{ flex: 1, textAlign: 'left' }}>
                    Invite player(s) to take "{title}" quiz
                  </CModalTitle>
                  {isAutoQuiz !== true && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'right',
                      }}
                    >
                      <CLink
                        href={inviteLink}
                        rel="noopener norefferer"
                        target="_blank"
                        onClick={(e) => {
                          e.preventDefault();
                          navigator.clipboard.writeText(inviteLink);
                          setLinkCopied(true);
                          setTimeout(() => {
                            setLinkCopied(false);
                          }, 2000);
                        }}
                      >
                        {linkCopied ? (
                          <span style={{ color: 'green' }}>
                            Link copied to your clipboard
                            <FaCheck className="ml-2" />
                          </span>
                        ) : (
                          <>
                            Shareable link (click to copy)
                            <FaShareSquare className="ml-2" />
                          </>
                        )}
                      </CLink>
                      <CLink
                        style={{ fontSize: 14 }}
                        onClick={() => {
                          alert(
                            `You can use the shareable link as a reminder to send to a player who has already been invited to a quiz. If you haven't yet invited the player by selecting a group in this list, this link alone will not grant access. You need to invite them first.`
                          );
                        }}
                      >
                        What is this <FaInfoCircle />
                      </CLink>
                    </div>
                  )}
                </CModalHeader>
                {_.isArray(teams) && teams.length > 0 ? (
                  <div className="quiz-invite-tab-wrapper">
                    <CTabs activeTab={activeTeamID}>
                      <CNav variant="tabs">
                        {(teams || []).map((t, tIndex) => (
                          <CNavItem key={`nav-item-key-${tIndex}`}>
                            <CTooltip
                              content={
                                (selectedTeamIDs || []).includes(t._id)
                                  ? `Quiz will be created for this team`
                                  : `Select players on this team to create this quiz for this team`
                              }
                            >
                              <CNavLink
                                data-tab={t._id}
                                onClick={() => {
                                  setActiveTeamID(t._id);
                                }}
                              >
                                {_.isString(t.imageUrl) &&
                                  t.imageUrl.length > 0 && (
                                    <img
                                      src={t.imageUrl}
                                      style={{
                                        width: 30,
                                        height: 30,
                                        objectFit: 'contain',
                                        marginRight: 8,
                                      }}
                                    />
                                  )}
                                <span style={{ fontWeight: 'bolder' }}>
                                  {t.name}
                                </span>
                                <FaCheckSquare
                                  color={HEX_COLOR_GREEN_SUCCESS}
                                  style={{
                                    width: 30,
                                    opacity: (selectedTeamIDs || []).includes(
                                      t._id
                                    )
                                      ? 1
                                      : 0,
                                  }}
                                />
                              </CNavLink>
                            </CTooltip>
                          </CNavItem>
                        ))}
                      </CNav>
                      {!(
                        _.isString(activeTeamID) && activeTeamID.length > 1
                      ) && (
                        <div style={{ margin: 30 }}>
                          Please select a team from the tabs above to choose
                          groups to invite
                        </div>
                      )}

                      <CTabContent>
                        <CTabPane data-tab={activeTeamID}>
                          <CModalBody>{renderTeamInviteTable()}</CModalBody>
                        </CTabPane>
                      </CTabContent>
                    </CTabs>
                  </div>
                ) : (
                  <CModalBody>{renderTeamInviteTable()}</CModalBody>
                )}
                {_renderQuizSummary('summary-main-col')}
              </CCol>
              {isAutoQuiz === true && (
                <CCol className="invite-users-modal-content-quiz-details-col">
                  <CModalHeader>
                    <CModalTitle>Quiz Details</CModalTitle>
                  </CModalHeader>
                  <CModalBody>
                    <FormGroup>
                      <FormLabel htmlFor="quizName">Quiz Name</FormLabel>
                      <InputGroup>
                        <input
                          type="text"
                          name="quizName"
                          id="quizName"
                          value={customQuizTitle}
                          onChange={handleCustomQuizTitleChange}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup style={{ margin: '0px auto 1rem' }}>
                      <div>
                        <FormLabel htmlFor="numQuestions">
                          Number of Questions
                        </FormLabel>
                        <InputGroup>
                          <div className="number-of-questions-wrapper">
                            <div className="number-of-questions-slider-wrapper">
                              <span>{AUTO_QUIZ_MIN_NUMBER_QUESTIONS}</span>
                              <input
                                type="range"
                                min={`${AUTO_QUIZ_MIN_NUMBER_QUESTIONS}`}
                                max={`${
                                  ACCESS_ACL[tier][
                                    ACCESS_ACTIONS.NUM_QUESTIONS_PER_AUTO_QUIZ
                                  ].threshold
                                }`}
                                name="numQuestions"
                                id="numQuestions"
                                value={numberOfQuestions}
                                onChange={handleNumberOfQuestionsSliderChange}
                                // className={`form-control ${
                                //   touched.code && errors.code ? 'is-invalid' : ''
                                // }`}
                              />
                              <span>
                                {
                                  ACCESS_ACL[tier][
                                    ACCESS_ACTIONS.NUM_QUESTIONS_PER_AUTO_QUIZ
                                  ].threshold
                                }
                              </span>
                            </div>
                            <span>{`${numberOfQuestions} Question${
                              numberOfQuestions === 1 ? '' : 's'
                            }`}</span>
                          </div>
                        </InputGroup>
                      </div>
                    </FormGroup>
                    {/* <FormGroup style={{ margin: '0px auto 1rem' }}>
                      <div>
                        <FormLabel htmlFor="difficulty">Difficulty</FormLabel>
                        <InputGroup>
                          <div className="number-of-questions-wrapper">
                            <div className="number-of-questions-slider-wrapper">
                              <span>Easy</span>
                              <input
                                type="range"
                                min={1}
                                max={9}
                                name="difficulty"
                                id="difficulty"
                                value={difficulty}
                                onChange={handleDifficultySliderChange}
                                // className={`form-control ${
                                //   touched.code && errors.code ? 'is-invalid' : ''
                                // }`}
                              />
                              <span>Hard</span>
                            </div>
                            <span>{`${difficulty} = ${
                              difficulty < 4
                                ? 'Easy'
                                : difficulty > 6
                                ? 'Hard'
                                : 'Medium'
                            }`}</span>
                          </div>
                        </InputGroup>
                      </div>
                    </FormGroup> */}
                    <FormGroup>
                      <FormLabel htmlFor="categoryName">{`From Categor${
                        (autoQuizCategories || []).length === 1 ? 'y' : 'ies'
                      }`}</FormLabel>
                      <InputGroup>
                        <input
                          disabled={true}
                          type="text"
                          name="categoryName"
                          id="categoryName"
                          value={customQuizCategoriesString}
                          style={{ cursor: 'not-allowed' }}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FlexSpacer className="summary-right-side" />
                    {_renderQuizSummary('summary-right-side')}
                  </CModalBody>
                </CCol>
              )}
            </CRow>

            <CModalFooter style={{ alignItems: 'flex-end' }}>
              {submitting ? (
                <LoadingMessage
                  firstMessage={
                    mode === INVITE_USERS_TO_EXAM_MODE.EDIT
                      ? 'Saving Changes...'
                      : 'Creating Quiz...'
                  }
                />
              ) : (
                <>
                  {mode === INVITE_USERS_TO_EXAM_MODE.EDIT &&
                    isScheduledQuiz && (
                      <CButton
                        color="danger"
                        variant="ghost"
                        disabled={submitting}
                        onClick={async () => {
                          let _quiz_id = isScheduledQuiz
                            ? editing_scheduled_quiz_id
                            : id;
                          if (isString(_quiz_id) && _quiz_id.length > 0) {
                            if (isScheduledQuiz) {
                              setSubmitting(true);
                              let deleteScheduledQuizCallable =
                                functions.httpsCallable(
                                  'deleteScheduledQuizCallable'
                                );
                              let deleteResult =
                                await deleteScheduledQuizCallable({
                                  quizId: _quiz_id,
                                });
                              setSubmitting(false);
                              if (isFunction(onClose)) {
                                onClose();
                              }
                            } else {
                              console.log('delete existing quiz');
                            }
                          } else {
                            toast('Error deleting quiz', { type: 'error' });
                          }
                        }}
                      >
                        <FaTrashAlt style={{ marginRight: 8, marginTop: -2 }} />
                        Delete Quiz
                      </CButton>
                    )}
                  <CButton
                    color="danger"
                    variant="outline"
                    disabled={submitting}
                    onClick={cancelModal}
                  >
                    Cancel
                  </CButton>
                  {isAutoQuiz === true && (
                    <CButton
                      color="success"
                      disabled={
                        submitting ||
                        _selectedTeamsCount === 0 ||
                        (isAutoQuiz === true &&
                          (customQuizTitle || '').length === 0)
                      }
                      className="mr-2"
                      onClick={() => {
                        checkAccess({
                          action: [
                            ACCESS_ACTIONS.SCHEDULE_QUIZZES,
                            ACCESS_ACTIONS.CREATE_AUTO_QUIZ,
                          ],
                          thresholdCount: [0],
                          tier,
                          type,
                          showSubscriptionModal,
                        }).doWithAccess(() => {
                          setIsScheduling(true);
                        });
                      }}
                    >
                      <FaRegClock style={{ marginRight: 6, marginTop: -2 }} />
                      {mode === INVITE_USERS_TO_EXAM_MODE.EDIT
                        ? 'Change Scheduled Days'
                        : 'Schedule Quiz'}
                    </CButton>
                  )}
                  {mode === INVITE_USERS_TO_EXAM_MODE.EDIT ? (
                    <CButton color="primary" onClick={scheduleQuiz}>
                      Save Changes
                    </CButton>
                  ) : (
                    <CButton
                      color="primary"
                      disabled={
                        submitting ||
                        _selectedTeamsCount === 0 ||
                        (isAutoQuiz === true &&
                          (customQuizTitle || '').length === 0)
                      }
                      className="mr-2"
                      onClick={createQuizAndInvitePlayers}
                    >
                      {buttonText}
                    </CButton>
                  )}
                </>
              )}
            </CModalFooter>
          </>
        )}
      </CModal>
    </>
  );
}

export default React.memo(InviteUsersToExamModal);
