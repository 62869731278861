import React, { useEffect, useState } from 'react';
import {
  CFormGroup,
  CLabel,
  CListGroup,
  CListGroupItem,
  CButton,
  CModalFooter,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CInputCheckbox,
} from '@coreui/react';
import _ from 'lodash';

const SelectUsers = ({
  isVisible,
  onClose,
  title,
  usersToSelectFrom,
  addSelectedUsers,
}) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (isVisible) {
      setUsers(usersToSelectFrom);
    }
    return () => {
      setUsers([]);
    };
  }, [usersToSelectFrom, isVisible]);

  const handleChange = (userId) => {
    const updatedUsers = [...users].map((user) => ({
      ...user,
      status: 'NEW_GROUP',
      isChecked: user._id === userId ? !user.isChecked : user.isChecked,
    }));
    setUsers(updatedUsers);
  };

  return (
    <CModal
      size="lg"
      backdrop={false}
      scrollable={false}
      show={isVisible}
      onClose={() => onClose(false)}
    >
      <CModalHeader>
        <CModalTitle>Add existing user to group {title}</CModalTitle>
      </CModalHeader>

      <CModalBody>
        <CListGroup className="border-0">
          {users?.map((user) => (
            <CListGroupItem key={user._id} className="border-0">
              <CFormGroup variant="custom-checkbox" className="p-0 text-left">
                <CInputCheckbox
                  custom
                  id={user._id}
                  value={user.isChecked}
                  checked={user.isChecked}
                  onChange={() => handleChange(user._id)}
                />
                <CLabel variant="custom-checkbox" htmlFor={user._id}>
                  {user.name}
                </CLabel>
              </CFormGroup>
            </CListGroupItem>
          ))}
        </CListGroup>
      </CModalBody>

      <CModalFooter className="mt-2">
        <CButton
          color="danger"
          variant="outline"
          onClick={() => onClose(false)}
        >
          Cancel
        </CButton>
        <CButton
          type="submit"
          color="primary"
          className="mr-2"
          onClick={() => {
            addSelectedUsers(users);
            onClose(false);
          }}
        >
          Add Users
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default React.memo(SelectUsers);
