import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import AppStoreBadges from './component/AppStoreBadges';
import './home.css';
import { TABS } from '../AppTabs';

toast.configure();

class DownloadApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      class: 'u-video-player',
      emailError: '',
      passwordError: '',
      resetEmail: '',
      resetEmailError: '',
      COVID1: true,
    };
  }

  playVideo = () => {
    this.setState({
      class: 'u-video-player u-video-player__played',
    });
  };

  render() {
    return (
      <div id="page-home">
        <section id="hero" className="hero">
          <div className="img-box">
            <img src="img/hero-1.png" className="hero-img img-fluid" alt="" />
          </div>
          <div className="container">
            <div
              className="hero-text"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ flex: 1 }}>
                <div className="subtitle" style={{ display: 'none' }}>
                  <p>The Best App</p>
                </div>
                <div className="title">
                  <h1>
                    Be Prepared for your <span>next Big Win</span>
                  </h1>
                </div>
                <div className="content">
                  <p>
                    Increase the Baseball IQ of Your Players, No Matter Their
                    Skill Level
                  </p>
                </div>
                <div className="btn-wrap">
                  <a
                    href={TABS.SIGNUP_CREATE_ACCOUNT.path}
                    className="btn primary-btn"
                  >
                    <span className="material-symbols-outlined">login</span>
                    <span>Sign Up Today</span>
                  </a>
                </div>
              </div>
              <div style={{ flex: 0 }}>
                <div>
                  <hr
                    style={{ marginTop: 40, maxWidth: '90%', marginLeft: 0 }}
                  />
                  <h4 style={{ color: 'white' }}>
                    Player looking for your coach's quiz?
                  </h4>
                  <AppStoreBadges height={60} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

DownloadApp.propTypes = {
  teamLength: PropTypes.number,
};

export default connect(mapStateToProps)(DownloadApp);
