import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import './home.css';
import './team.scss';
import { CButton, CModal, CModalBody } from '@coreui/react';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { TABS } from '../AppTabs';
import ReadyToStart from './component/ReadyToStart/ReadyToStart';
import { teamMembers } from './teamMembers';
import { FaTimes, FaTimesCircle } from 'react-icons/fa';
import SEOHead from '../r-components/SEOHead/SEOHead';

const Team = () => {
  // const { windowWidth, windowHeight } = useWindowDimensions();
  const [viewBioIndex, setViewBioIndex] = useState(-1);

  return (
    <>
      <SEOHead
        title="Meet the 6Tool Team: Pioneers in Baseball Player Development & Coaching Apps"
        description="Redefine baseball player coaching and development with 6Tool. Our baseball coaching app combines innovative technology and intelligent baseball wisdom to develop better baseball players like never before. Join the 6Tool revolution and cultivate champions today."
      />
      <section className="team-banner">
        <div className="container">
          <h1>
            Our <span>Team</span>
          </h1>
          <p>
            Increase the Baseball IQ of Your Players, No Matter Their Skill
            Level
          </p>
          <div className="player-image" />
        </div>
      </section>
      <section className="team">
        <div className="container">
          <div className="content text-center">
            <p>
              6Tool brings experience from a variety of angles within the
              Baseball landscape. From former Major and Minor Leaguers to
              College Coaches and Baseball Dads who've experienced the
              recruiting process first-hand. 6Tool aims to uplift the Baseball
              Community by creating a Learning Management System that identifies
              what our young athletes know and don't know in a fun and
              stress-free environment. Increasing the Baseball awareness of our
              youth is ultimately beneficial for the game to grow and allow the
              next generations to flourish. This is why we've created 6Tool.
            </p>
          </div>
          <div className="team-cards">
            <div className="row">
              {teamMembers.map((p, _pIdx) => (
                <div className="column col-lg-4 col-md-6 px-lg-4">
                  <div className="team-each-wrap">
                    <div
                      className="team-each"
                      onClick={() => {
                        setViewBioIndex(_pIdx);
                      }}
                    >
                      <div className="team-img">
                        <img className="profile" src={p.imageUrl} alt />
                        {/* <img
                          className="social-icon"
                          src="img/linkedin.svg"
                          alt
                        /> */}
                      </div>
                      <div className="team-info">
                        <h3>{p.name}</h3>
                        <p className="designation">{p.role}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <ReadyToStart />
      <CModal
        className="team-bio-details-modal"
        size="xl"
        show={viewBioIndex >= 0 && viewBioIndex < teamMembers.length}
        onClose={() => {
          setViewBioIndex(-1);
        }}
        fade={true}
        centered={true}
      >
        <div
          className="modal-close-button"
          onClick={() => {
            setViewBioIndex(-1);
          }}
        >
          <div className="modal-close-button-background"></div>
          <FaTimesCircle size={40} color="#f6d30d" />
        </div>
        <CModalBody>
          <div className="row" style={{ minHeight: 400 }}>
            <div className="column col-lg-4 px-xs-4 image-wrapper">
              <img src={teamMembers?.[viewBioIndex]?.actionImageUrl} />
            </div>
            <div className="column col-lg-8 px-xs-8">
              <h3>{teamMembers?.[viewBioIndex]?.name}</h3>
              <h4>{teamMembers?.[viewBioIndex]?.role}</h4>
              <div className="team-bio-text">
                {teamMembers?.[viewBioIndex]?.longBio}
              </div>
            </div>
          </div>
        </CModalBody>
      </CModal>
    </>
  );
};

export default Team;
