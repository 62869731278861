import React, { useState } from 'react';
import { FaToggleOff, FaToggleOn } from 'react-icons/fa6';

const Toggle = ({
  value = false,
  onToggle,
  size = 60,
  onColor = '#f6d30d',
  offColor = '#162630',
}) => {
  return (
    <>
      {value === false ? (
        <FaToggleOff
          size={size}
          color={offColor}
          onClick={() => {
            onToggle(true);
          }}
        />
      ) : (
        <FaToggleOn
          size={size}
          color={onColor}
          onClick={() => {
            onToggle(false);
          }}
        />
      )}
    </>
  );
};
export default Toggle;
