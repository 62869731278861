import { combineReducers } from 'redux';
import { themeReducer } from '../r-store/theme/reducer';
import { userReducer } from '../r-store/user/reducer';
import { teamReducer } from '../r-store/team/reducer';
import { organizationReducer } from '../r-store/organization/reducer';
import { tourReducer } from '../r-store/tour/reducer';
import { createLeagueReducer } from './createLeagueReducer';
import { createTeamReducer } from './createTeamReducer';
import { imageReducer } from './imageReducer';
import { loginReducer } from './loginReducer';
import { profileReducer } from './profileReducer';
import { quizReducer } from './quizReducer';
import { questionReducer } from './questionReducer';
import { scrimmageReducer } from './scrimmageReducer';
import { playersReducer } from '../r-store/player/reducer';
import { coachesReducer } from '../r-store/coach/reducer';
import { ownersReducer } from '../r-store/owner/reducer';
import { groupsReducer } from '../r-store/group/reducer';
import { quizzesReducer } from '../r-store/quizzes/reducer';
import { examsReducer } from '../r-store/exams/reducer';
import { questionsReducer } from '../r-store/questions/reducer';
import { subscriptionReducer } from '../r-store/subscriptions/reducer';
import { mediaReducer } from '../r-store/media/reducer';
import { eventsReducer } from '../r-store/events/reducer';
import { impersonateReducer } from '../r-store/impersonate/reducer';

const reducers = combineReducers({
  CreateTeamState: createTeamReducer,
  DataCoachState: coachesReducer,
  DataExamState: examsReducer,
  DataGroupState: groupsReducer,
  DataMediaState: mediaReducer,
  DataOrganizationState: organizationReducer,
  DataOwnerState: ownersReducer,
  DataPlayerState: playersReducer,
  DataQuestionState: questionsReducer,
  DataQuizState: quizzesReducer,
  DataSubscriptionState: subscriptionReducer,
  DataTeamState: teamReducer,
  DataEventsState: eventsReducer,
  ImageState: imageReducer,
  ImpersonateState: impersonateReducer,
  LeagueDataState: createLeagueReducer,
  ProfileState: profileReducer,
  ScrimmageState: scrimmageReducer,
  ThemeState: themeReducer,
  TokenState: loginReducer,
  TourState: tourReducer,
  UserState: userReducer,
  ViewQuestionState: questionReducer,
  ViewQuizState: quizReducer,
});

export default reducers;
