import { CButton, CTooltip, CImg } from '@coreui/react';
import React, { useCallback, useState } from 'react';
import { FiServer, FiUsers } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { viewQuizDetails } from '../../actions/quizActionCreator';
import InviteUsersToExamModal from '../InviteUsersToExamModal/InviteUsersToExamModal';
import { Link } from 'react-router-dom';
import { TABS } from '../../AppTabs';
import { CLink } from '@coreui/react';

const QuizActions = (props) => {
  const [inviteUsersModal, setInviteUsersModal] = useState(false);
  const dispatch = useDispatch();

  const toggleInviteUserModal = useCallback(() => {
    setInviteUsersModal((usersModal) => !usersModal);
  }, []);

  return (
    <div
      className={props?.className || ''}
      style={{
        marginLeft: 10,
      }}
    >
      {/* <CTooltip
        content={
          props.organizationId === 'all'
            ? '6Tool made this exam'
            : 'You made this exam'
        }
        placement="top"
      >
        <CImg
          className="quiz-owner-icon"
          src={`/img/${
            props.organizationId === 'all' ? 'light-bulb-icon' : 'user'
          }.png`}
          fluid
          style={{
            marginLeft: 10,
            height: 44,
            objectFit: 'contain',
            flex: 0,
            padding: '0.625rem 1.125rem',
          }}
        />
      </CTooltip> */}
      <CLink
        className="ml-1 quiz-invite-button"
        onClick={toggleInviteUserModal}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 6,
        }}
      >
        <FiUsers size={24} />
        <span style={{ marginTop: 3, fontSize: '0.8em' }}>
          Invite Players to Quiz
        </span>
      </CLink>

      {/* <CTooltip content="View quiz details" placement="top">
        <Link
          to={TABS.COACH_QUIZZES_DETAILS.pathVar({
            name: props.title,
            id: props.id,
          })}
        >
          <CButton size="sm" className="ml-1 quiz-details-button">
            <FiServer size={24} />
          </CButton>
        </Link>
      </CTooltip> */}
      <InviteUsersToExamModal
        {...props}
        quiz_id={props.id}
        exam_id={props.examId}
        quiz_team_id={props.team_id}
        title={props.title}
        show={inviteUsersModal}
        onClose={toggleInviteUserModal}
      />
    </div>
  );
};

export default QuizActions;
