import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import reducers from '../reducers';
import { handleError } from '../helpers/errors';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (!serializedState) return undefined;
    else return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    handleError({
      error: err,
      internal: 'store/index.js - saveState',
    });
  }
};

const persistedStore = loadState();
const initialState = persistedStore;

export function configureStore() {
  const store = createStore(reducers, initialState, applyMiddleware(thunk));
  return store;
}
export const store = configureStore();

store.subscribe(() => {
  saveState(store.getState());
});
