import PropTypes from 'prop-types';
import React from 'react';

class Spinner extends React.Component {
  render(props) {
    return (
      <>
        <div
          className={this.props.display ? 'm-5 text-center spinner' : 'd-none'}
        >
          <div className="spinner-grow text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
        <div className={this.props.display ? 'd-none' : ''}>
          {this.props.children}
        </div>
      </>
    );
  }
}

Spinner.propTypes = {
  display: PropTypes.bool,
  children: PropTypes.node,
};

export default Spinner;
