import styled from 'styled-components';

import ellipse from '../../assets/ellipse.png';

export const TopContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  padding-top: 120px;

  h4 {
    font-family: 'Raleway', sans-serif;
    font-size: 46px;
    line-height: 50px;
    font-weight: 700;
    color: #151b26;
  }
  p {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 40px;
    color: #151b26;
  }
  button {
    background: #f6d30d;
    border: solid 1px #f6d30d;
    color: #151b26;
    padding: 19px 2px;
    border-radius: 10px;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    width: 220px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 120px;
  padding-bottom: 60px;
  gap: 60px;
`;

export const ContentLeft = styled.div`
  background: url(${ellipse}) center bottom no-repeat;
  background-size: contain;
  min-width: 640px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 500px;
    height: auto;
  }
`;

export const ContentRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const Item = styled.div`
  padding: 26px 30px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #151b26;
  gap: 20px;

  img {
    width: 88px;
    height: 88px;
  }

  p {
    font-family: 'Raleway', sans-serif;
    font-size: 22px;
    font-weight: 500;
    color: #ffffff;
    line-height: 30px;
  }
`;
