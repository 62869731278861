import styled from 'styled-components';

import footerBackground from '../../assets/bg-footer.png';

export const Wrapper = styled.footer`
  background: url(${footerBackground}) top center no-repeat;
  background-size: cover;
  border-radius: 100px 100px 0 0;
  min-height: 510px;
  overflow: hidden;
`;

export const Background = styled.div`
  background: rgba(21, 27, 38, 0.8);
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 120px;
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span {
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    display: block;
    margin: 50px 0 30px;
  }

  div {
    display: flex;
    gap: 20px;

    img {
      width: 170px;
    }
  }
`;

export const FollowUsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;

  ol {
    display: flex;
    gap: 16px;
    justify-content: center;
    list-style: none;
  }

  span {
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    padding-right: 16px;
  }
`;

export const H4 = styled.h4`
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  line-height: 40px;
  margin-bottom: 8px;
`;

export const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
`;

export const Li = styled.li`
  line-height: 36px;
`;

export const A = styled.a`
  font-family: 'Raleway', sans-serif;
  font-size: 24px;
  font-weight: 500;
  color: rgb(255, 255, 255);
  text-decoration: none;
`;

export const CopyRight = styled.div`
  border-top: solid 1px rgba(255, 255, 255, 0.4);
  padding: 40px 0 58px;
  text-align: center;
  margin-top: 80px;

  p {
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: rgb(255, 255, 255);
    line-height: 40px;
  }
`;
