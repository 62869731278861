import React from 'react';
import _ from 'lodash';
import { mimeTypeFromURL } from '../../helpers/data';
import { CLabel, CLink } from '@coreui/react';
// import Path, { Svg, Circle, G, Polygon, Line } from 'react-svg-path';
// import Svg, {
//     Circle,
//     G,
//     Path,
//     Polygon,
//     Line,
//   } from 'react-native-svg';

const QuestionText = ({
  reordering,
  expandedQuestion,
  question,
  setExpandedQuestion,
  style,
}) => (
  <CLabel
    variant="checkbox"
    className="form-check-label"
    style={{ flex: 1, cursor: 'pointer', ...style }}
    onClick={() => {
      if (!reordering && _.isFunction(setExpandedQuestion)) {
        if (
          _.isString(expandedQuestion?.id) &&
          expandedQuestion?.id === question.id
        ) {
          setExpandedQuestion(null);
        } else {
          setExpandedQuestion(question);
        }
      }
    }}
  >
    {question.title && question.title.trim() != '' && (
      <div
        style={{
          fontWeight: question.text && question.text.trim() != '' ? 600 : 400,
        }}
        dangerouslySetInnerHTML={{ __html: question.title }}
      />
    )}
    {question.text && question.text.trim() != '' && (
      <div
        style={{ fontSize: 16 }}
        dangerouslySetInnerHTML={{ __html: question.text }}
      />
    )}
  </CLabel>
);

export default QuestionText;
