import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Spinner from '../component/Spinner';
import { getAnnualSubscriptionExpirationDate } from '../../helpers/utils';
import { today } from '../../helpers/constants';
import { TABS } from '../../AppTabs';

class YourTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teamData: [],
      userId: '',
    };
  }

  componentDidMount = () => {
    if (
      !this.props.activeSubscription ||
      (localStorage.getItem('subscription_expiration_date') &&
        getAnnualSubscriptionExpirationDate(
          localStorage.getItem('subscription_expiration_date')
        ) < today)
    ) {
      this.props.history.push(TABS.SUBSCRIPTION.path);
    }

    this.setState(
      {
        userId: localStorage.getItem('user_id'),
      },
      () => {
        this.getTeamData();
      }
    );
  };

  getTeamData = () => {
    let userId = localStorage.getItem('user_id');
    // this.props.requestTeamData(userId);
    if (
      this.props.data &&
      this.props.data.length === 1 &&
      !!this.props.data[0].id &&
      this.props.data[0].id.length > 0
    ) {
      this.props.history.push(`/teamdetails/${this.props.data[0].id}/quizzes`);
    }
  };
  render() {
    return (
      <div>
        {localStorage.getItem('user_id') !== null ? (
          <div className="container space-1">
            <Spinner display={this.props.loading}>
              <div className="row justify-content-lg-between align-items-md-center">
                <div className="col-md-12">
                  <div className="mb-4 text-center">
                    <h1 className="mb-3 font-weight-black">Your Team</h1>
                    {/* Currently not allowing coaches to have more than one team, therefore we aren't providing the below code */}
                    {/* <a
                      className="btn btn-primary text-white font-weight-bold"
                      href="/createteam"
                    >
                      Add New Team
                    </a> */}
                    {/* <hr />
                    <h1>
                      You have{' '}
                      {`${this.props.data && this.props.data.length} ${
                        this.props.data.length === 1 ? 'team' : 'teams'
                      }`}
                    </h1>
                    <p>
                      Click on a team below to schedule a scrimmage, view
                      requests and invites, or edit a team.
                    </p> */}
                  </div>
                </div>
              </div>

              <div className="row">
                {this.props.data &&
                  this.props.data.map((data, key) => {
                    return (
                      <div className="col-sm-6 col-lg-3 mb-5 mb-lg-2" key={key}>
                        <a
                          className="card card-shadow bg-img-hero border-0 gradient-teamlist min-height-320 text-white rounded-pseudo p-3 h-100 team-list-image"
                          href={'/teamdetails/' + data.id + '/quizzes'}
                          style={{ backgroundImage: `url(${data.imageUrl})` }}
                        >
                          <div className="mt-auto">
                            <span className="h3 font-weight-medium text-uppercase text-white">
                              {data.name}
                            </span>
                            <p className="text-white mb-2 custom-p">
                              {data.sport}
                            </p>
                            <ul className="list-inline small text-white mb-0">
                              <li className="list-inline-item mr-3 text-capitalize">
                                <img
                                  src="/img/team-list/employees.png"
                                  className="img-fluid mx-1"
                                  alt="gender"
                                />
                                <span className="align-middle text-white">
                                  {data.gender}
                                </span>
                              </li>
                              <li className="list-inline-item float-right">
                                <img
                                  src="/img/team-list/star.png"
                                  className="img-fluid mx-1"
                                  alt="strength"
                                />
                                <span className="align-middle text-white">
                                  {data.skillLevel} Skill Level
                                </span>
                              </li>
                            </ul>
                          </div>
                        </a>
                      </div>
                    );
                  })}
              </div>
            </Spinner>
          </div>
        ) : (
          <div className="row justify-content-lg-between align-items-md-center space-1">
            <div className="col-md-12">
              <div className="mb-4 text-center">
                <h1 className="mb-3 font-weight-black">Login Required</h1>
                <p>Please login or create an account to view your teams.</p>
                <hr />
                <a
                  className="btn btn-primary text-white font-weight-bold"
                  href="/login"
                >
                  Log in
                </a>
                &nbsp;
                <a
                  className="btn btn-primary text-white font-weight-bold"
                  href={TABS.SIGNUP_CREATE_ACCOUNT.path}
                >
                  Sign Up
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.DataTeamState.teamData,
    loading: state.DataTeamState.isLoading,
  };
};
const mapDispatchToProps = {};

YourTeam.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(YourTeam));
