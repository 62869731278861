const defaultImageState = {
  image: null,
  isLoading: false,
  status: 0,
  coverImage: null,
  isLoadingCoverImage: false,
  coverImageStatus: 0,
};

export const imageReducer = (state = defaultImageState, action) => {
  switch (action.type) {
    case 'DEFAULT_SCRIMMAGE':
      return { ...state, isLoading: false, status: 0, forgotPassStatus: null };

    case 'REQUESTED_UPLOAD':
      return { ...state, isLoading: true, status: 1 };
    case 'RECEIVED_UPLOAD_FAILURE':
      return { ...state, isLoading: false, status: 2 };
    case 'RECEIVED_UPLOAD_SUCCESS':
      return { ...state, image: action.image, isLoading: false, status: 3 };
    case 'REQUESTED_COVER_IMAGE_UPLOAD':
      return { ...state, isLoadingCoverImage: true, coverImageStatus: 1 };
    case 'RECEIVED_UPLOAD_COVER_IMAGE_FAILURE':
      return { ...state, isLoadingCoverImage: false, coverImageStatus: 2 };
    case 'RECEIVED_UPLOAD_COVER_IMAGE_SUCCESS':
      return {
        ...state,
        coverImage: action.coverImage,
        isLoadingCoverImage: false,
        coverImageStatus: 3,
      };

    case 'REQUESTED_TEAM_COVER_BG':
      return { ...state, isLoading: true };
    case 'RECEIVED_TEAM_COVER_BG_SUCCESS':
      return { ...state, teamCoverbg: action.teamCoverbg, isLoading: false };
    case 'RECEIVED_TEAM_COVER_BG_FAILURE':
      return { ...state, isLoading: false };
    default:
  }

  return state;
};
