import produce from 'immer';

import { CLEAR_GROUPS, SET_CURRENT_GROUPS } from './constants';
import _ from 'lodash';

const INITIAL_STATE = {
  data: [],
  map: {},
};

export const groupsReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    let map;
    switch (action.type) {
      case SET_CURRENT_GROUPS:
        if (_.isArray(action.payload.data) && action.payload.data.length > 0) {
          map = action.payload.data.reduce((acc, cur) => {
            acc[cur.id] = {
              ..._.pick(cur, [
                'title',
                'default',
                'players',
                'organizationId',
                'teamId',
                'id',
              ]),
              // ...{ id: cur.id },
            };
            return acc;
          }, {});
        }
        return {
          ...state,
          ...{ map },
          ...action.payload,
        };

      case CLEAR_GROUPS:
        return {
          ...INITIAL_STATE,
        };

      default:
        return state;
    }
  });
