// EmbedPage.js
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import EventLeaderboardViewOnly, {
  ANONYMOUS_TYPE,
} from '../r-views/pages/events/eventLeaderboard/EventLeaderboardViewOnly';
import { db } from '..';
import { isArray, pick, uniq } from 'lodash';
import ExamPlayerResults from '../r-views/pages/examDetails/ExamPlayerResults';
import { isString } from 'lodash';
import { format } from 'date-fns';
import { isObject } from 'lodash';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const QUIZ_DATA_FIELDS = [
  'exam_id',
  'finished',
  'num_correct',
  'num_questions',
  'organization_id',
  'point',
  'questions',
  'started',
  'team_id',
  'time_spent',
  'totalPoints',
  'user_id',
];
const RESPONDANT_DATA_FIELDS = ['email', 'name', 'positions', '_id'];

const EmbedQuizResults = () => {
  const query = useQuery();
  const quizID = query.get('quizID');
  const eventID = query.get('eventID');
  const top = query.get('top');
  const anonymous =
    query.get('anon') === 'true' ? ANONYMOUS_TYPE.ALL : query.get('anon'); // ANONYMOUS_TYPE ('all' or 'true','individual','team','none' -- default)
  const [quizTitle, setQuizTitle] = useState('');
  const [respondantMap, setRespondantMap] = useState({});
  const [questionResults, setQuestionResults] = useState({});
  const [respondantIDs, setRespondantIDs] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [takenExams, setTakenExams] = useState([]);
  const missingIDs = useMemo(() => {
    return respondantIDs.filter((id) => !respondantMap[id]);
  }, [respondantIDs, respondantMap]);
  // const [teamMap, setTeamMap] = useState({});
  // const [respondantIDs, setRespondantIDs] = useState([]);
  // const showTypeChooser = query.get('showTypeChooser') !== 'false';
  // const initialLeaderboardType =
  //   query.get('initialLeaderboardType') === 'team' ? 'team' : 'individual';

  useEffect(() => {
    // Function to handle real-time updates for the anonymous field
    // const handleAnonymousSnapshot = (snapshot) => {
    //   if (snapshot.exists) {
    //     const data = snapshot.data();
    //     if (data && data.anonymous !== undefined) {
    //       _setDBAnonymous(data.anonymous);
    //     }
    //   }
    // };

    // Real-time listener for the events document

    let quizQuery =
      isString(eventID) && eventID.length > 0
        ? db
            .collection(
              isString(eventID) && eventID.length > 0
                ? 'event_exams_taken'
                : 'exams_taken'
            )
            .where('quiz_id', '==', quizID)
            .where('event_id', '==', eventID)
        : db
            .collection(
              isString(eventID) && eventID.length > 0
                ? 'event_exams_taken'
                : 'exams_taken'
            )
            .where('quiz_id', '==', quizID);
    const unsubscribeExamsTaken = quizQuery.onSnapshot((snapshot) => {
      if (snapshot.size > 0) {
        let _responses = [];
        let maxQuestions = 0;
        let questionResults = {}; // will become an array

        snapshot.forEach((doc) => {
          let data = doc.data();
          let r = pick(data, QUIZ_DATA_FIELDS);
          r.id = doc.id;
          r.playerName = data.player_name;
          r.score = parseFloat(data.score);
          r.dateString = !isNaN(data?.finished?.seconds)
            ? format(new Date(data?.finished?.seconds * 1000), 'MMM d')
            : '';
          _responses.push(r);

          r.questions = data.questions.map((q, qIdx) => {
            if (!isObject(questionResults[qIdx])) {
              questionResults[qIdx] = {
                idx: qIdx,
                correct: 0,
                responses: 0,
                points: 0,
                maxPoints: 0,
                answers: {},
              };
            }
            questionResults[qIdx].responses += 1;
            questionResults[qIdx].correct += q.correct;
            questionResults[qIdx].points += q.points;
            questionResults[qIdx].maxPoints += q.maxPoints;
            if (!isNaN(q.default_order)) {
              if (isNaN(questionResults[qIdx].answers[q.default_order])) {
                questionResults[qIdx].answers[q.default_order] = 1;
              } else {
                questionResults[qIdx].answers[q.default_order] += 1;
              }
            }
            return {
              ...q,
              ...{ answer: q.default_order },
            };
          });
          setQuestions(r.questions);
        });
        setQuestionResults(questionResults);
        setTakenExams(_responses);
      } else {
        setTakenExams([]);
      }
    });

    return () => {
      unsubscribeExamsTaken();
    };
  }, [quizID, eventID]);

  useEffect(() => {
    if (isArray(takenExams) && takenExams.length > 0) {
      setRespondantIDs(uniq(takenExams.map((r) => r.user_id)));
    } else {
      setRespondantIDs([]);
    }
  }, [takenExams]);

  const fetchFromDB = async (id) => {
    // Example: fetch data from an API or database
    const response = await db.collection('users').doc(id).get();
    if (response.exists) {
      return pick(response.data(), RESPONDANT_DATA_FIELDS);
    }
    return {};
  };

  useEffect(() => {
    if (missingIDs.length === 0) return;

    const fetchMissingData = async () => {
      const fetchedData = await Promise.all(
        missingIDs.map(async (id) => {
          const data = await fetchFromDB(id); // Replace with your DB query function
          return { id, data };
        })
      );

      setRespondantMap((prevMap) => {
        const newMap = { ...prevMap };
        fetchedData.forEach(({ id, data }) => {
          newMap[id] = data;
        });
        return newMap;
      });
    };

    fetchMissingData();
  }, [missingIDs]);

  return (
    <>
      <div></div>
      <div style={{ maxWidth: 1160, margin: '40px auto', width: '100%' }}>
        <div
          className="left-menu-wrapper"
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <img
            style={{
              padding: 10,
              background: '#162630',
              height: 100,
              width: 'auto',
              objectFit: 'contain',
              borderRadius: 4,
              overflow: 'hidden',
            }}
            src="/img/logo.png"
            alt="Logo"
          />
          <h1 style={{ lineHeight: 0.8, textAlign: 'center', marginTop: 20 }}>
            {quizTitle}
          </h1>
          <hr
            style={{
              border: '0.5px solid hsl(0,0%,90%)',
              height: 0,
              width: '50%',
            }}
          />
        </div>
        <ExamPlayerResults
          questions={questions}
          takenExams={takenExams}
          invitedPlayers={[]}
          questionResults={questionResults}
          showSinglePlayerResultModal={(responses) => {
            // setShowQuestionsResponses(responses);
            // setShowingQuestionsSingleResponse(true);
            // _handleSetShowingQuestions(true);
          }}
          generatePDF={() => {}}
          loadingPDF={false}
          isEmbedded={true}
        />
      </div>
      <div></div>
    </>
  );
};

export default EmbedQuizResults;
