import React, { useEffect } from 'react';

import { FaCheck, FaEnvelopeOpenText, FaTimes } from 'react-icons/fa';
import BallField from '../../../r-components/BallField/BallField';
import { ScoreBoard } from '../../../r-components';
import QuestionText from '../../../r-components/Questions/QuestionText';
import QuestionOptions from '../../../r-components/Questions/QuestionOptions';
import QuestionMedia from '../../../r-components/Questions/QuestionMedia';

const ExamReviewQuestionDetails = ({ question, response, singleResponse }) => {
  if (question.deleted === true) {
    return <div>Question has been removed</div>;
  }
  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ height: 130, marginTop: -20 }}>
          {question?.showScoreboard && (
            <ScoreBoard
              size={90}
              situation={question.situation}
              editable={false}
            />
          )}
        </div>
        <div>
          {question?.showField && (
            <BallField
              size={80}
              positionHighlights={question?.positions}
              editable={false}
              togglePosition={false}
            />
          )}
        </div>
      </div>
      <QuestionMedia
        imageUrl={question?.imageUrl}
        videoUrl={question?.videoUrl}
        maxWidth={300}
      />
      <QuestionText question={question} style={{ margin: '20px auto 10px' }} />
      <QuestionOptions
        options={question?.options}
        responseCount={response?.answers}
        singleResponse={singleResponse}
      />
    </div>
  );
};

export default ExamReviewQuestionDetails;
