import {
  CCard,
  CCardBody,
  CCardHeader,
  CLabel,
  CLink,
  CModal,
  CModalBody,
  CModalHeader,
} from '@coreui/react';
import React, { useEffect, useRef, useState } from 'react';
import { FaAngleLeft, FaPrint } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import ViewScoreBoard from '../ViewScoreBoard/ViewScoreBoard';
import BallField from '../BallField/BallField';
import QuestionText from '../Questions/QuestionText';
import QuestionOptions from '../Questions/QuestionOptions';
import QuizPrintout from '../QuizPrintout/QuizPrintout';
import _ from 'lodash';
import Spinner from '../../pages/component/Spinner';
import { useSelector } from 'react-redux';

const QuizQuestionsModal = ({
  show,
  setShow,
  quiz,
  responses,
  singleResponse,
  allQuestionsMap,
}) => {
  const [visibleItemIndex, setVisibleItemIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const itemRefs = useRef([]);
  // const [questionIds, setQuestionIds] = useState([]);

  // useEffect(() => {
  //   setLoading(true);
  //   let hasOptions = true;
  //   if (_.isObject(quiz) && _.isArray(quiz.questions)) {
  //     quiz.questions.forEach((q) => {
  //       if (!(_.isArray(q.options) && q.options.length > 0)) {
  //         hasOptions = false;
  //       }
  //     });
  //   }
  //   if (!hasOptions) {
  //     setQuestionIds(quiz.questions.map((q) => q.questionId));
  //   }
  //   setLoading(false);
  // }, [quiz]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.75, // Customize the visibility threshold as needed
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        const { target, isIntersecting } = entry;
        if (isIntersecting) {
          const index = itemRefs.current.indexOf(target);
          setVisibleItemIndex(index);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    itemRefs.current.forEach((item) => {
      if (!!item) {
        observer.observe(item);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [quiz]);

  const scrollToItem = (index) => {
    if (itemRefs.current[index]) {
      itemRefs.current[index].scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <CModal
      className="quiz-questions-details-modal"
      size="xl"
      show={show}
      // onHide={() => {
      //   setShow(false);
      //   document.body.classList.remove('modal-open');
      //   setVisibleItemIndex(0);
      //   scrollToItem(0);
      // }}
      onClose={() => {
        setShow(false);
        setVisibleItemIndex(0);
        scrollToItem(0);
        document.body.classList.remove('modal-open');
      }}
      scrollable={true}
      backdrop={true}
      onOpened={() => {
        document.body.classList.add('modal-open');
      }}
    >
      {loading ? (
        <>Loading</>
      ) : (
        <>
          <CModalHeader>
            <CLink
              style={{ alignSelf: 'flex-start', marginBottom: -35 }}
              onClick={window.print}
            >
              <FaPrint />
            </CLink>

            {/* <h2>{`${quiz?.title || ''} Questions`}</h2> */}
            <CLink
              style={{ alignSelf: 'flex-end', marginBottom: -35 }}
              onClick={() => {
                setShow(false);
                document.body.classList.remove('modal-open');
                setVisibleItemIndex(0);
                scrollToItem(0);
              }}
            >
              Close
            </CLink>
            <div className="quiz-questions-details-modal-jump-section-content">
              Jump to Question
            </div>
            <div className="quiz-questions-details-modal-jump-section-content">
              {[...Array((quiz?.questions || []).length).keys()].map(
                (i, index) => (
                  <div
                    key={`modal-jump-index-${index}`}
                    className={`quiz-questions-details-modal-jump-index-link ${
                      visibleItemIndex === i ? 'active' : ''
                    }`}
                    onClick={() => {
                      scrollToItem(i);
                    }}
                  >
                    {i + 1}
                  </div>
                )
              )}
            </div>
          </CModalHeader>
          <CModalBody>
            <QuizPrintout
              quiz={quiz}
              allQuestionsMap={allQuestionsMap}
              itemRefs={itemRefs}
              singleResponse={singleResponse}
              responses={responses}
              visibleItemIndex={visibleItemIndex}
            />
          </CModalBody>
        </>
      )}
    </CModal>
  );
};

export default QuizQuestionsModal;
