import _ from 'lodash';
import moment from 'moment-timezone';
import { toast as showToast } from 'react-toastify';
import { analytics } from '..';
import { logEvent } from 'firebase/analytics';

// import { IncomingWebhook } from '@slack/webhook';

// const webhook = new IncomingWebhook(process.env.REACT_APP_SLACK_ERROR_WEBHOOK);

// Stripe stores the expiration date of a session/subscription by default at 24 hours after creation
// This function returns the expiration date one year in advance to match with the current annual pricing structure
export const handleError = ({
  error = 'An unknown error occurred',
  logToConsole = process.env.NODE_ENV === 'development',
  slack = true,
  toast = null,
  internal = null,
} = {}) => {
  let message = error;
  if (
    _.isObject(error) &&
    _.isString(error.message) &&
    error.message.length > 0
  ) {
    message = error.message;
  }
  try {
    let logData = { message };
    if (_.isObject(error) && error.stack) {
      logData.stack = error.stack;
    }
    logEvent(analytics, 'error', logData);
    // eslint-disable-next-line no-empty
  } catch {}
  if (slack === true) {
    try {
      // (async () => {
      //   await webhook.send({
      //     text: `${
      //       _.isString(internal) && internal.length > 0 ? `[${internal}] ` : ''
      //     }${message}`,
      //   });
      // })();
    } catch (error) {
      if (logToConsole) {
        console.error('Error sending message to Slack:', error);
      }
    }
  }
  if (_.isString(toast) && toast.length > 0) {
    showToast(toast, { type: 'error' });
  }
  if (logToConsole) {
    console.error(
      `${
        _.isString(internal) && internal.length > 0 ? `[${internal}] ` : ''
      }${message}`
    );
  }
};
