import { CLink } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  FaAngleLeft,
  FaCheckSquare,
  FaEnvelope,
  FaLock,
  FaRegSquare,
  FaUser,
  FaUsers,
} from 'react-icons/fa';
import { RiOrganizationChart } from 'react-icons/ri';
import Spinner from '../../../src/pages/component/Spinner';
import { Formik, Form, Field, ErrorMessage } from 'formik';
// import { Button, FormGroup, FormLabel, Input, BootstrapForm.Control.Feedback } from "react-bootstrap";
import {
  Button,
  Form as BootstrapForm,
  FormGroup,
  FormLabel,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import { auth, functions } from '../..';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { TABS } from '../../AppTabs';
import { handleError } from '../../helpers/errors';
import { values } from 'lodash';
import SignupWrapper, { SETUP_STEPS } from './SignupWrapper';
import LoadingMessage from '../../r-components/LoadingMessage/LoadingMessage';
import { _signInWithEmailAndPassword } from '../../helpers/authHelper';
import { DEFAULT_ANNUAL_COST_PER_TEAM } from '../../config';
import SubscriptionNumberOfTeamsPicker from '../../r-components/SubscriptionNumberOfTeamsPicker/SubscriptionNumberOfTeamsPicker';
import { Link } from 'react-router-dom';
import { Widget } from '@typeform/embed-react';

const CreateAccountForm = () => (
  <Widget
    id="m2Lt11Ke"
    style={{
      margin: '60px auto',
      width: 'calc(100% - 120px)',
      height: 'calc(100vh - 495px)',
      maxWidth: 1140,
    }}
    hidden={{ id: 'abcdef' }}
    className="my-form"
  />
);

export default CreateAccountForm;
