import styled from 'styled-components';

export const TopContent = styled.div`
  text-align: center;
  padding-top: 120px;
  padding-bottom: 60px;

  p {
    font-family: 'Raleway', sans-serif;
    font-size: 24px;
    line-height: 40px;
    color: #151b26;

    padding: 10px 0 35px;
  }
`;

export const H2 = styled.h2`
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 40px;
  color: #0e0e0e;
  line-height: 50px;
`;

export const Segments = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background: #d9d9d9;
  border-radius: 10px;
  padding: 10px;
`;

export const SegmentsItem = styled.span`
  border-radius: 10px;
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #a6a6a6;
  display: block;
  height: 60px;
  line-height: 60px;
  width: 215px;
  text-align: center;

  ${({ active }) =>
    active &&
    `
    background: #f6d30d;
    color: #151b26;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 100px;

  padding-bottom: 120px;
`;

export const RightContent = styled.div`
  width: 470px;

  img {
    width: 100%;
    height: auto;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const Item = styled.div`
  padding: 26px 30px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  img {
    width: 46px;
    height: 46px;
  }

  div {
    strong {
      font-family: 'Raleway', sans-serif;
      font-size: 22px;
      font-weight: 500;
      color: #151b26;
      line-height: 30px;

      ${({ selected }) =>
        selected &&
        `
          color: #FFF;
     `}
    }

    p {
      font-family: 'Raleway', sans-serif;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      line-height: 20px;
      display: none;
    }
  }

  ${({ selected }) =>
    selected &&
    `
    background: #151b26;
    align-items: flex-start;

    div p {
      display: block;
    }
  `}
`;

export const ImageWrapper = styled.div``;
