import {
  CButton,
  CCol,
  CFormGroup,
  CInputCheckbox,
  CLabel,
  CListGroup,
  CListGroupItem,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { filter } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { createQuizAndInviteGroups } from '../../helpers/data';
import { db, functions } from '../../index';
import ReviewExamBody from '../ReviewExamBody/ReviewExamBody';
import { handleError } from '../../helpers/errors';
import _ from 'lodash';
import LoadingMessage from '../LoadingMessage/LoadingMessage';
import { EMAIL_ADDRESSES } from '../../config';

function CreateQuizFromQuestionBankModal({
  show,
  onClose,
  initialExamData,
  resetPageAfterQuizCreated,
  resetData,
  ...rest
}) {
  const isMounted = useRef(false);
  const [submitting, setSubmitting] = useState(false);
  const [examData, setExamData] = useState([]);
  const user = useSelector((state) => state.UserState);
  const team_id = useSelector((state) => state.DataTeamState?.teamData?._id);
  const [formError, setFormError] = useState(null);

  useEffect(() => {
    let timeoutId;

    if (formError) {
      timeoutId = setTimeout(() => {
        setFormError(null);
      }, 3000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [formError]);

  const organizationId = user.organizationId || 'a';

  const submitCreateQuiz = async () => {
    setSubmitting(true);
    let createQuizResult = await submitCreateQuizForTeam(examData);
    if (createQuizResult) {
      toast(`Quiz created`, {
        type: 'success',
      });
      onClose();
      setExamData({});
      resetPageAfterQuizCreated();
    } else {
      handleError({
        error: `Error creating quiz. If this persists, contact ${EMAIL_ADDRESSES.SUPPORT}`,
        toast: `Error creating quiz. If this persists, contact ${EMAIL_ADDRESSES.SUPPORT}`,
        internal: `CreateQuizFromQuestionBankModal.js:submitCreateQuiz`,
      });
    }
    setSubmitting(false);
  };
  const submitCreateQuizForTeam = async ({
    exam,
    quiz,
    questions,
    groups,
    organization_id,
    teamIDs,
  }) => {
    return await createQuizAndInviteGroups({
      exam,
      quiz,
      organization_id,
      questions: (questions || []).map((q) => {
        return { ...q, existing: true, edited: false };
      }),
      groups,
      user,
      inviter_name: user?.data?.name,
      inviter_id: user?.data?._id,
      teamIDs,
    });
  };

  const handleExamDataChange = (examData) => {
    setExamData(examData);
  };

  return (
    <CModal
      size="xl"
      show={show}
      backdrop={false}
      scrollable={false}
      onClose={onClose}
    >
      <CModalBody>
        <ReviewExamBody
          data={initialExamData}
          resetData={resetData}
          editable={true}
          handleExamDataChange={handleExamDataChange}
        />
      </CModalBody>

      <CModalFooter>
        {submitting ? (
          <LoadingMessage firstMessage="Creating Quiz..." />
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <div>
              <CButton
                color="danger"
                variant="outline"
                disabled={submitting}
                onClick={onClose}
              >
                Cancel
              </CButton>
              <CButton
                color="primary"
                className={`ml-2${
                  !(_.isArray(examData?.teamIDs) && examData.teamIDs.length > 0)
                    ? ' feaux-disabled'
                    : ''
                }`}
                onClick={
                  _.isArray(examData?.teamIDs) && examData.teamIDs.length > 0
                    ? submitCreateQuiz
                    : () => {}
                }
                onMouseEnter={() => {
                  if (
                    !(
                      _.isArray(examData?.teamIDs) &&
                      examData.teamIDs.length > 0
                    )
                  ) {
                    setFormError('Select at least one team');
                    console.log('need at least one team');
                  }
                }}
              >
                Create Quiz
              </CButton>
            </div>
            {_.isString(formError) && formError.length > 0 && (
              <div
                className="invalid-feedback"
                style={{ display: 'block', textAlign: 'right' }}
              >
                {formError}
              </div>
            )}
          </div>
        )}
      </CModalFooter>
    </CModal>
  );
}

export default React.memo(CreateQuizFromQuestionBankModal);
