import PropTypes from 'prop-types';
import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import AppStoreBadges from './component/AppStoreBadges';
import './home.css';
import './how-it-works.scss';
import SEOHead from '../r-components/SEOHead/SEOHead';
import Logos, { LogoList } from './Logos';
import Header from '../public-components/Header/Header.jsx';
import Jumbotron from '../public-components/Jumbotron/Jumbotron.jsx';
import WinningTeams from '../public-components/WinningTeams/WinningTeams.jsx';
import Empowering from '../public-components/Empowering/Empowering.jsx';
import ToolOptions from '../public-components/ToolOptions/ToolOptions.jsx';
import How6ToolWork from '../public-components/How6TooWork/How6ToolWork.jsx';
import Testimonials from '../public-components/Testimonials/Testimonials.jsx';
import ToolAdvantage from '../public-components/ToolAdvantage/ToolAdvantage.jsx';
import Footer from '../public-components/Footer/Footer.jsx';

toast.configure();

class NewHome extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  logoContent = lazy(() => import('./Logos.js'));

  render() {
    return (
      <div id="page-new-home">
        <Header />
        <Jumbotron />
        <WinningTeams />
        <Empowering />
        <ToolOptions />
        <How6ToolWork />
        <Testimonials />
        <ToolAdvantage />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

NewHome.propTypes = {
  teamLength: PropTypes.number,
};

export default connect(mapStateToProps)(NewHome);
