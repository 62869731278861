import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CImg,
  CRow,
  CSpinner,
  CTooltip,
} from '@coreui/react';
import './eventQuizzes.scss';
import { format } from 'date-fns';
import _, { isArray, isObject } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { TABS } from '../../../../AppTabs';
import {
  FaCheckSquare,
  FaEnvelopeOpenText,
  FaMobile,
  FaRegClock,
} from 'react-icons/fa';
import { orgAndTeamData } from '../../../../helpers/data';

import OrgTeamImageHeader from '../../../../r-components/OrgTeamImageHeader/OrgTeamImageHeader';
import TeamCellInDataTable from '../../../../r-components/TeamCellInDataTable/TeamCellInDataTable';
import EventsWrapper from '../../../../pages/component/EventsWrapper';
import QuizQuestionsModal from '../../../../r-components/QuizQuestionsModal/QuizQuestionsModal';

// With implementation of 6tool, UI presents exams as quizzes. The data model contains both exams and quizzes
// having migrated over from Gamer-Metrics
const EventQuizzes = () => {
  const history = useHistory();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, '');
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [quizModalToShow, setQuizModalToShow] = useState(null);
  const [allQuestionsMap, setAllQuestionsMap] = useState({});
  const allQuestionsData = useSelector(
    (state) => state.DataQuestionState?.data || []
  );
  const orgData = useSelector((state) => state.DataOrganizationState);
  const teamData = useSelector((state) => state.DataTeamState);
  const quizzesRaw = useSelector(
    (state) => state.DataEventsState?.quizzes || []
  );
  const eventMap = useSelector(
    (state) => state.DataEventsState?.eventMap || {}
  );

  const [quizzes, setQuizzes] = useState([]);
  const [orgTeamData, setOrgTeamData] = useState({});
  const [tableFields, setTableFields] = useState([]);

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(currentPage);

  useEffect(() => {
    currentPage !== page && setPage(currentPage);
  }, [currentPage, page]);

  useEffect(() => {
    if (
      isObject(quizModalToShow) &&
      isArray(quizModalToShow.questions) &&
      quizModalToShow.questions.length > 0
    ) {
      const _allQuestionsMap = allQuestionsData
        .filter((q) => quizModalToShow.questions.includes(q.id))
        .reduce((acc, obj) => {
          acc[obj.id] = obj;
          return acc;
        }, {});
      setAllQuestionsMap(_allQuestionsMap);
    } else {
      setAllQuestionsMap({});
    }
  }, [quizModalToShow, allQuestionsData]);

  useEffect(() => {
    if (_.isArray(quizzesRaw) && quizzesRaw.length > 0) {
      let newQuizzes = quizzesRaw
        .map((q) => {
          return {
            ...q,
            ...{
              createdFormatted: format(
                new Date(q?.created_at?.seconds * 1000 || q?.created_at),
                'MMM d'
              ),
              _created: q?.created_at?.seconds || q?.created_at || 0,
              events: (q?.events || [])
                .map((e_id) => {
                  return eventMap[e_id];
                })
                .filter((e) => e !== undefined),
            },
          };
        })
        .sort((a, b) => b._created - a._created);
      setQuizzes(newQuizzes);
    } else {
      setQuizzes([]);
    }
  }, [quizzesRaw, eventMap]);

  useEffect(() => {
    let orgTeam = orgAndTeamData({ orgData, teamData });
    setOrgTeamData(orgTeam);
    let tableFields = [];
    tableFields = tableFields.concat([
      {
        key: 'title',
        _classes: 'font-weight-bold',
        sortable: true,
        sortIcon: 'fa fa-sort',
      },
      {
        key: 'events',
        label: 'Event(s)',
      },
      {
        key: 'createdFormatted',
        label: 'Created',
        sortable: true,
        sortIcon: 'fa fa-sort',
      },
      {
        key: 'num_questions',
        label: '# Questions',
        sortable: true,
        sortIcon: 'fa fa-sort',
      },
      {
        key: 'estTime',
        label: 'Est. Time (min)',
        sortable: true,
        sortIcon: 'fa fa-sort',
      },
      // { key: 'show_details', label: '' },
    ]);
    setTableFields(tableFields);
  }, [orgData, teamData]);

  const _handleSetQuizModalToShow = (shouldShow) => {
    if (shouldShow === false) {
      setQuizModalToShow(null);
    }
  };

  return (
    <EventsWrapper>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader className="d-flex align-items-center justify-content-between">
              <OrgTeamImageHeader title="Event Quizzes" />
            </CCardHeader>

            <CCardBody>
              {!loading ? (
                <CDataTable
                  addTableClasses="quiz-table"
                  items={quizzes}
                  tableFilter
                  itemsPerPageSelect
                  itemsPerPage={50}
                  hover
                  pagination
                  onRowClick={(item) => {
                    setQuizModalToShow(item);
                    // history.push(
                    //   TABS.COACH_QUIZZES_DETAILS.pathVar({
                    //     name: item.title,
                    //     id: item.quiz_id,
                    //   })
                    // );
                  }}
                  fields={tableFields}
                  noItemsViewSlot={
                    <p className="d-flex align-content-center justify-content-center pt-4 pb-4">
                      <FiAlertCircle size="30" color="#e55353" />
                      <span className="pl-2 font-xl">No quizzes added.</span>
                    </p>
                  }
                  scopedSlots={{
                    created_at: (item) => <td>{item?.createdFormatted}</td>,
                    team: (item) => (
                      <TeamCellInDataTable item={item} teamData={teamData} />
                    ),
                    num_questions: (item) => (
                      <td>{(item?.questions || []).length}</td>
                    ),
                    events: (item) => (
                      <td>
                        {(item.events || []).length > 0 && (
                          <>
                            {item.events.length > 1 ? (
                              <CTooltip
                                content={(item.events || [])
                                  .map((e) =>
                                    _.isString(e)
                                      ? e
                                      : _.isObject(e)
                                      ? `${e.name} | ${e.city} (${e.visibleDateText})`
                                      : ''
                                  )
                                  .join(', ')}
                              >
                                <div>{`${
                                  (item.events || []).length
                                } Events`}</div>
                              </CTooltip>
                            ) : (
                              <>
                                {(item.events || [])
                                  .map((e) =>
                                    _.isString(e)
                                      ? e
                                      : _.isObject(e)
                                      ? `${e.name} | ${e.city} (${e.visibleDateText})`
                                      : ''
                                  )
                                  .join(', ')}
                              </>
                            )}
                          </>
                        )}
                      </td>
                    ),
                    show_details: (item) => (
                      <td className="py-2 text-right quiz-details">
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <CTooltip
                            content={
                              item.organizationId === 'all'
                                ? '6Tool made this quiz'
                                : 'You made this quiz'
                            }
                            placement="top"
                          >
                            <CImg
                              className="quiz-owner-icon"
                              src={`/img/${
                                item.organizationId === 'all'
                                  ? 'light-bulb-icon'
                                  : 'user'
                              }.png`}
                              fluid
                              style={{
                                marginLeft: 10,
                                height: 44,
                                objectFit: 'contain',
                                flex: 0,
                                padding: '0.625rem 1.125rem',
                              }}
                            />
                          </CTooltip>
                        </div>
                      </td>
                    ),
                  }}
                />
              ) : (
                <CCol xl={12} className="text-center pt-2 pb-2">
                  <CSpinner variant="grow" size="lg" />
                </CCol>
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <QuizQuestionsModal
        show={
          isObject(quizModalToShow) &&
          isArray(quizModalToShow.questions) &&
          quizModalToShow.questions.length > 0
        }
        setShow={_handleSetQuizModalToShow}
        quiz={quizModalToShow}
        singleResponse={false}
        responses={false}
        allQuestionsMap={allQuestionsMap}
      />
    </EventsWrapper>
  );
};

export default EventQuizzes;
