import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import './home.css';
import './pricing.scss';
import { CButton, CTooltip } from '@coreui/react';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { TABS } from '../AppTabs';
import SEOHead from '../r-components/SEOHead/SEOHead';
import { isArray, isNull, isString } from 'lodash';
import { FaInfoCircle } from 'react-icons/fa';
import { ACCESS_ACL, ACCESS_ACTIONS } from '../helpers/access';
import {
  SUBSCRIPTION_TIER,
  SUBSCRIPTION_PAYMENT_TERMS,
  PRICING_TIERS,
} from '../config';
const {
  threshold: autoQuizFreeThreshold,
  thresholdTimeInterval: autoQuizFreeThresholdTimeInterval,
  thresholdTimePeriods: autoQuizFreeThresholdTimePeriods,
} = ACCESS_ACL[SUBSCRIPTION_TIER.FREE.key][ACCESS_ACTIONS.CREATE_AUTO_QUIZ];
const {
  threshold: autoQuizRegularThreshold,
  thresholdTimeInterval: autoQuizRegularThresholdTimeInterval,
  thresholdTimePeriods: autoQuizRegularThresholdTimePeriods,
} = ACCESS_ACL[SUBSCRIPTION_TIER.REGULAR.key][ACCESS_ACTIONS.CREATE_AUTO_QUIZ];
const {
  threshold: autoQuizPremiumThreshold,
  thresholdTimeInterval: autoQuizPremiumThresholdTimeInterval,
  thresholdTimePeriods: autoQuizPremiumThresholdTimePeriods,
} = ACCESS_ACL[SUBSCRIPTION_TIER.PREMIUM.key][ACCESS_ACTIONS.CREATE_AUTO_QUIZ];

const PRICING_TIER_FIELDS = [
  {
    text: 'Quiz Creation',
    [SUBSCRIPTION_TIER.FREE.key]:
      autoQuizFreeThreshold === -1
        ? 'Unlimited'
        : `${autoQuizFreeThreshold}/${autoQuizFreeThresholdTimeInterval}`,
    [SUBSCRIPTION_TIER.REGULAR.key]:
      autoQuizRegularThreshold === -1
        ? 'Unlimited'
        : `${autoQuizRegularThreshold}/${autoQuizRegularThresholdTimeInterval}`,
    [SUBSCRIPTION_TIER.PREMIUM.key]:
      autoQuizPremiumThreshold === -1
        ? 'Unlimited'
        : `${autoQuizPremiumThreshold}/${autoQuizPremiumThresholdTimeInterval}`,
  },
  {
    text: 'Players per Team',
    tip: 'Maximum number of players per team',
    [SUBSCRIPTION_TIER.FREE.key]:
      ACCESS_ACL[SUBSCRIPTION_TIER.FREE.key][ACCESS_ACTIONS.CREATE_PLAYER]
        .threshold,
    [SUBSCRIPTION_TIER.REGULAR.key]:
      ACCESS_ACL[SUBSCRIPTION_TIER.REGULAR.key][ACCESS_ACTIONS.CREATE_PLAYER]
        .threshold,
    [SUBSCRIPTION_TIER.PREMIUM.key]:
      ACCESS_ACL[SUBSCRIPTION_TIER.PREMIUM.key][ACCESS_ACTIONS.CREATE_PLAYER]
        .threshold,
  },
  {
    text: 'Coaches per Team',
    tip: 'Maximum number of coaches per team',
    [SUBSCRIPTION_TIER.FREE.key]:
      ACCESS_ACL[SUBSCRIPTION_TIER.FREE.key][ACCESS_ACTIONS.CREATE_COACH]
        .threshold,
    [SUBSCRIPTION_TIER.REGULAR.key]:
      ACCESS_ACL[SUBSCRIPTION_TIER.REGULAR.key][ACCESS_ACTIONS.CREATE_COACH]
        .threshold,
    [SUBSCRIPTION_TIER.PREMIUM.key]:
      ACCESS_ACL[SUBSCRIPTION_TIER.PREMIUM.key][ACCESS_ACTIONS.CREATE_COACH]
        .threshold,
  },
  {
    text: 'Questions per Quiz',
    tip: 'Maximum number of questions per quiz',
    [SUBSCRIPTION_TIER.FREE.key]:
      ACCESS_ACL[SUBSCRIPTION_TIER.FREE.key][
        ACCESS_ACTIONS.NUM_QUESTIONS_PER_QUIZ
      ].threshold,
    [SUBSCRIPTION_TIER.REGULAR.key]:
      ACCESS_ACL[SUBSCRIPTION_TIER.REGULAR.key][
        ACCESS_ACTIONS.NUM_QUESTIONS_PER_QUIZ
      ].threshold,
    [SUBSCRIPTION_TIER.PREMIUM.key]:
      ACCESS_ACL[SUBSCRIPTION_TIER.PREMIUM.key][
        ACCESS_ACTIONS.NUM_QUESTIONS_PER_QUIZ
      ].threshold,
  },
  {
    text: 'Library of 6Tool Questions',
    tip: 'Access to our ever-expanding library of questions',
    [SUBSCRIPTION_TIER.FREE.key]:
      ACCESS_ACL[SUBSCRIPTION_TIER.FREE.key][
        ACCESS_ACTIONS.VIEW_6TOOL_QUESTIONS
      ].threshold,
    [SUBSCRIPTION_TIER.REGULAR.key]:
      ACCESS_ACL[SUBSCRIPTION_TIER.REGULAR.key][
        ACCESS_ACTIONS.VIEW_6TOOL_QUESTIONS
      ].threshold,
    [SUBSCRIPTION_TIER.PREMIUM.key]:
      ACCESS_ACL[SUBSCRIPTION_TIER.PREMIUM.key][
        ACCESS_ACTIONS.VIEW_6TOOL_QUESTIONS
      ].threshold,
  },
  {
    text: 'View Quiz Results',
    [SUBSCRIPTION_TIER.FREE.key]:
      ACCESS_ACL[SUBSCRIPTION_TIER.FREE.key][
        ACCESS_ACTIONS.VIEW_6TOOL_QUESTIONS
      ].threshold,
    [SUBSCRIPTION_TIER.REGULAR.key]:
      ACCESS_ACL[SUBSCRIPTION_TIER.REGULAR.key][
        ACCESS_ACTIONS.VIEW_6TOOL_QUESTIONS
      ].threshold,
    [SUBSCRIPTION_TIER.PREMIUM.key]:
      ACCESS_ACL[SUBSCRIPTION_TIER.PREMIUM.key][
        ACCESS_ACTIONS.VIEW_6TOOL_QUESTIONS
      ].threshold,
  },

  {
    text: 'Scheduling Quizzes',
    tip: 'Ahead of time schedule quizzes to send every Monday at 3p, for example',
    [SUBSCRIPTION_TIER.FREE.key]:
      ACCESS_ACL[SUBSCRIPTION_TIER.FREE.key][ACCESS_ACTIONS.SCHEDULE_QUIZZES]
        .threshold,
    [SUBSCRIPTION_TIER.REGULAR.key]:
      ACCESS_ACL[SUBSCRIPTION_TIER.REGULAR.key][ACCESS_ACTIONS.SCHEDULE_QUIZZES]
        .threshold,
    [SUBSCRIPTION_TIER.PREMIUM.key]:
      ACCESS_ACL[SUBSCRIPTION_TIER.PREMIUM.key][ACCESS_ACTIONS.SCHEDULE_QUIZZES]
        .threshold,
  },
  {
    text: 'Question Creation',
    tip: 'Create your own custom questions within the 6Tool website',
    [SUBSCRIPTION_TIER.FREE.key]:
      ACCESS_ACL[SUBSCRIPTION_TIER.FREE.key][ACCESS_ACTIONS.CREATE_QUESTIONS]
        .threshold,
    [SUBSCRIPTION_TIER.REGULAR.key]:
      ACCESS_ACL[SUBSCRIPTION_TIER.REGULAR.key][ACCESS_ACTIONS.CREATE_QUESTIONS]
        .threshold,
    [SUBSCRIPTION_TIER.PREMIUM.key]:
      ACCESS_ACL[SUBSCRIPTION_TIER.PREMIUM.key][ACCESS_ACTIONS.CREATE_QUESTIONS]
        .threshold,
  },
];

const Pricing = ({
  headerTextMain,
  headerTextSecondary,
  hideSubscribe,
  hideCosts,
}) => {
  const { windowWidth, windowHeight } = useWindowDimensions();
  const [paymentTerm, setPaymentTerm] = useState(
    SUBSCRIPTION_PAYMENT_TERMS.ANNUAL.value
  );

  const renderCheckBoxes = (tier, fullWidthLast) => {
    return PRICING_TIER_FIELDS.map((f, _fidx) => {
      return (
        <div
          className={`checkbox-each text grid-item${_fidx % 2 ? '' : ' even'}${
            fullWidthLast === true ? ' full-width-last' : ''
          }`}
        >
          {f[tier] === true || f[tier] === -1 ? (
            <span className="svg-icon">
              <svg
                width="22px"
                height="22px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L12.7348 11.2045L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z"
                  fill="#5f5f86"
                />
              </svg>
            </span>
          ) : (
            <span>{f[tier] !== 0 ? f[tier] : ''}</span>
          )}
        </div>
      );
    });
  };
  const renderSubscribeButton = (fullWidthLast) => (
    <div
      className={`subscribe-btn blank-cell-top-border${
        fullWidthLast === true ? ' full-width-last' : ''
      }`}
    >
      <div className="btn-wrap">
        <a href={TABS.SIGNUP_CREATE_ACCOUNT.path} className="btn primary-btn">
          Subscribe
        </a>
      </div>
    </div>
  );
  const renderTextBoxes = (tier = 'all') => (
    <>
      {tier === 'all' ? (
        <>
          {PRICING_TIER_FIELDS.map((f, _index) => (
            <div
              key={_index}
              className={`text grid-item${_index % 2 === 0 ? ' even' : ''}`}
            >
              {f.text}
              {isString(f.tip) && f.tip.length > 0 && (
                <CTooltip content={f.tip}>
                  <FaInfoCircle style={{ marginLeft: 6 }} />
                </CTooltip>
              )}
            </div>
          ))}
        </>
      ) : (
        <>
          {PRICING_TIER_FIELDS.filter(
            (obj) =>
              obj[tier] !== null &&
              obj[tier] !== 0 &&
              obj[tier] !== false &&
              obj[tier] !== ''
          ).map((f, _index) => (
            <div
              key={_index}
              className={`text grid-item${_index % 2 === 0 ? ' even' : ''}`}
            >
              {f.text}
              {isString(f.tip) && f.tip.length > 0 && (
                <CTooltip content={f.tip}>
                  <FaInfoCircle style={{ marginLeft: 6 }} />
                </CTooltip>
              )}
              {f[tier] !== true && f[tier] !== -1 && (
                <span style={{ marginLeft: 6 }}> - {f[tier]}</span>
              )}
            </div>
          ))}
        </>
      )}
    </>
  );

  return (
    <>
      <SEOHead
        title="Top-Notch Baseball Player Development at the Cost of a Single Bat"
        description="Experience revolutionary player development and coaching for your entire team with 6Tool — all at the price of one new baseball bat. Our affordable pricing includes unlimited access to our extensive 6Tool question library, custom quizzes, inclusive team management, and more. Discover our value-added baseball coaching app today."
      />
      <section className="pricing">
        <div className="container">
          <div className="title">
            <h1>
              {isString(headerTextMain) && headerTextMain.length > 0
                ? headerTextMain
                : 'Pricing'}{' '}
              <span>
                {isString(headerTextSecondary) && headerTextSecondary.length > 0
                  ? headerTextSecondary
                  : 'Plans'}
              </span>
            </h1>
            {/* <h3 style={{ marginBottom: 40 }}>Win More Close Games</h3> */}
          </div>
          {hideCosts !== true && (
            <div className="segmented-wrapper">
              <div className="segmented-content">
                <button
                  className={`segmented-button${
                    paymentTerm === SUBSCRIPTION_PAYMENT_TERMS.MONTHLY.value
                      ? ' selected'
                      : ''
                  }`}
                  onClick={() => {
                    setPaymentTerm(SUBSCRIPTION_PAYMENT_TERMS.MONTHLY.value);
                  }}
                >
                  Monthly
                </button>
                <button
                  className={`segmented-button${
                    paymentTerm === SUBSCRIPTION_PAYMENT_TERMS.ANNUAL.value
                      ? ' selected'
                      : ''
                  }`}
                  onClick={() => {
                    setPaymentTerm(SUBSCRIPTION_PAYMENT_TERMS.ANNUAL.value);
                  }}
                >
                  Annual
                  <span className="savings">Save $300</span>
                </button>
              </div>
            </div>
          )}
          <div
            className="pricing-wrapper"
            style={{
              '--grid-rows':
                PRICING_TIER_FIELDS.length + (hideSubscribe !== true ? 2 : 1),
            }}
          >
            {PRICING_TIERS.map((t, _tidx) => (
              <>
                {_tidx === 0 && (
                  <>
                    {windowWidth > 991 && (
                      <>
                        <div className="blank-cell-bottom-border here"></div>
                        {renderTextBoxes()}
                        {hideSubscribe !== true && (
                          <div className="blank-cell-top-border"></div>
                        )}
                      </>
                    )}
                  </>
                )}
                <div
                  className={`price-header text-center${
                    _tidx >= PRICING_TIERS.length - 1 ? ' full-width-last' : ''
                  }`}
                >
                  <div className={`logo ${t.className}`}>
                    <img
                      src="/img/6-tool-six-white.svg"
                      className="img-fluid"
                      alt
                    />
                  </div>
                  <h5>{t.label}</h5>
                  {hideCosts !== true && (
                    <div>
                      <strong>
                        {paymentTerm === SUBSCRIPTION_PAYMENT_TERMS.ANNUAL.value
                          ? t[SUBSCRIPTION_PAYMENT_TERMS.ANNUAL.label]
                          : t[SUBSCRIPTION_PAYMENT_TERMS.MONTHLY.label]}
                      </strong>{' '}
                      /
                      {paymentTerm === SUBSCRIPTION_PAYMENT_TERMS.ANNUAL.value
                        ? 'year'
                        : 'month'}{' '}
                      (per team)
                    </div>
                  )}
                </div>
                {windowWidth > 991
                  ? renderCheckBoxes(t.tier, _tidx >= PRICING_TIERS.length - 1)
                  : renderTextBoxes(t.tier)}
                {hideSubscribe !== true &&
                  renderSubscribeButton(_tidx === PRICING_TIERS.length - 1)}
              </>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Pricing;
