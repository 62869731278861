import {
  ADD_NEW_QUESTION,
  CLEAR_QUESTIONS,
  QUESTIONS,
  QUESTION_DETAILS,
  CREATE_NEW_QUIZ,
  EDIT_QUESTION,
  SHOW_CONFIRM_DELETE_QUESTION_MODAL,
  SHOW_CONFIRM_EDIT_QUESTION_MODAL,
  SHOW_CONFIRM_DUPLICATE_QUESTION_MODAL,
  CLOSE_CONFIRM_DELETE_QUESTION_MODAL,
  CLOSE_CONFIRM_EDIT_QUESTION_MODAL,
  CLOSE_CONFIRM_DUPLICATE_QUESTION_MODAL,
  QUESTION_ADDED,
  QUESTION_REMOVED,
  CLEAR_QUESTION_ADDED,
  CLEAR_QUESTION_REMOVED,
  EXPAND_FIRST_QUESTION,
  CLEAR_EXPAND_FIRST_QUESTION,
  PREVIEW_FIRST_QUESTION,
  CLEAR_PREVIEW_FIRST_QUESTION,
  SELECT_FIRST_QUESTION,
  CLEAR_SELECT_FIRST_QUESTION,
  MOVE_SELECTED_QUESTION_RIGHT,
  CLEAR_MOVE_SELECTED_QUESTION_RIGHT,
  TOGGLE_ON_SHOW_SCOREBOARD,
  TOGGLE_ON_SHOW_FIELD,
} from './constants';

const defaultQuestionState = {
  questions: true,
  addQuestions: false,
  editQuestion: false,
  createNewQuiz: false,
  newQuizQuestionIds: [],
  questionDetails: false,
  examId: null,
  userId: null,
  question: null,
  questionId: null,
  showConfirmDeleteQuestionModal: false,
  showConfirmEditQuestionModal: false,
  showConfirmDuplicateQuestionModal: false,
  questionToDelete: null,
  questionToRemove: null,
  expandFirstQuestion: false,
  previewFirstQuestion: false,
  selectFirstQuestion: false,
  moveSelectedQuestionsRight: false,
  toggleOnShowScoreboard: false,
  toggleOnShowField: false,
};

export const questionReducer = (state = defaultQuestionState, action) => {
  switch (action.type) {
    case QUESTIONS:
      return {
        ...state,
        questions: true,
        addQuestions: false,
        editQuestion: false,
        newQuizQuestionIds: [],
        createNewQuiz: false,
        questionDetails: false,
        question: null,
        questionId: null,
        showConfirmDeleteQuestionModal: false,
        showConfirmEditQuestionModal: false,
        showConfirmDuplicateQuestionModal: false,
      };
    case ADD_NEW_QUESTION:
      return {
        ...state,
        addQuestions: true,
        editQuestion: false,
        questions: false,
        createNewQuiz: false,
        newQuizQuestionIds: [],
        questionDetails: false,
        question: null,
        questionId: null,
        showConfirmDeleteQuestionModal: false,
        showConfirmEditQuestionModal: false,
        showConfirmDuplicateQuestionModal: false,
      };
    case EDIT_QUESTION:
      return {
        ...state,
        addQuestions: false,
        editQuestion: true,
        questions: false,
        createNewQuiz: false,
        newQuizQuestionIds: [],
        questionDetails: false,
        question: action.payload || null,
        questionId: null,
        showConfirmDeleteQuestionModal: false,
        showConfirmEditQuestionModal: false,
        showConfirmDuplicateQuestionModal: false,
      };
    case CREATE_NEW_QUIZ:
      return {
        ...state,
        addQuestions: false,
        editQuestion: false,
        questions: false,
        createNewQuiz: true,
        newQuizQuestionIds: action.payload,
        questionDetails: false,
        question: null,
        questionId: null,
        showConfirmDeleteQuestionModal: false,
        showConfirmEditQuestionModal: false,
        showConfirmDuplicateQuestionModal: false,
      };
    case QUESTION_DETAILS:
      return {
        ...state,
        addQuestions: false,
        editQuestion: false,
        questionDetails: true,
        questions: false,
        createNewQuiz: false,
        newQuizQuestionIds: [],
        examId: action.payload,
        question: null,
        questionId: null,
        showConfirmDeleteQuestionModal: false,
        showConfirmEditQuestionModal: false,
        showConfirmDuplicateQuestionModal: false,
      };
    case CLEAR_QUESTIONS:
      return {
        ...state,
        addQuestions: false,
        editQuestion: false,
        questionDetails: false,
        createNewQuiz: false,
        newQuizQuestionIds: [],
        questions: false,
        question: null,
        questionId: null,
        showConfirmDeleteQuestionModal: false,
        showConfirmEditQuestionModal: false,
        showConfirmDuplicateQuestionModal: false,
      };
    case SHOW_CONFIRM_DELETE_QUESTION_MODAL:
      return {
        ...state,
        questionId: action.payload.questionId,
        showConfirmDeleteQuestionModal: true,
        showConfirmEditQuestionModal: false,
        showConfirmDuplicateQuestionModal: false,
      };
    case SHOW_CONFIRM_DUPLICATE_QUESTION_MODAL:
      return {
        ...state,
        questionId: action.payload.questionId,
        showConfirmDuplicateQuestionModal: true,
        showConfirmDeleteQuestionModal: false,
        showConfirmEditQuestionModal: false,
      };
    case SHOW_CONFIRM_EDIT_QUESTION_MODAL:
      return {
        ...state,
        question: action.payload.question || null,
        showConfirmEditQuestionModal: true,
        showConfirmDeleteQuestionModal: false,
        showConfirmDuplicateQuestionModal: false,
      };
    case CLOSE_CONFIRM_DELETE_QUESTION_MODAL:
      return {
        ...state,
        showConfirmDeleteQuestionModal: false,
        showConfirmEditQuestionModal: false,
        showConfirmDuplicateQuestionModal: false,
      };
    case CLOSE_CONFIRM_DUPLICATE_QUESTION_MODAL:
      return {
        ...state,
        showConfirmDeleteQuestionModal: false,
        showConfirmEditQuestionModal: false,
        showConfirmDuplicateQuestionModal: false,
      };
    case CLOSE_CONFIRM_EDIT_QUESTION_MODAL:
      return {
        ...state,
        showConfirmDeleteQuestionModal: false,
        showConfirmEditQuestionModal: false,
        showConfirmDuplicateQuestionModal: false,
      };
    case QUESTION_ADDED:
      return {
        ...state,
        questionToAdd: action.payload?.question,
      };
    case QUESTION_REMOVED:
      return {
        ...state,
        questionToRemove: action.payload?.questionId,
      };
    case CLEAR_QUESTION_ADDED:
      return {
        ...state,
        questionToAdd: defaultQuestionState.questionToAdd,
      };
    case CLEAR_QUESTION_REMOVED:
      return {
        ...state,
        questionToRemove: defaultQuestionState.questionToRemove,
      };
    case EXPAND_FIRST_QUESTION:
      return {
        ...state,
        expandFirstQuestion: true,
      };
    case CLEAR_EXPAND_FIRST_QUESTION:
      return {
        ...state,
        expandFirstQuestion: defaultQuestionState.expandFirstQuestion,
      };
    case PREVIEW_FIRST_QUESTION:
      return {
        ...state,
        previewFirstQuestion: true,
      };
    case CLEAR_PREVIEW_FIRST_QUESTION:
      return {
        ...state,
        previewFirstQuestion: defaultQuestionState.previewFirstQuestion,
      };
    case SELECT_FIRST_QUESTION:
      return {
        ...state,
        selectFirstQuestion: true,
      };
    case CLEAR_SELECT_FIRST_QUESTION:
      return {
        ...state,
        selectFirstQuestion: defaultQuestionState.selectFirstQuestion,
      };
    case MOVE_SELECTED_QUESTION_RIGHT:
      return {
        ...state,
        moveSelectedQuestionsRight: true,
      };
    case CLEAR_MOVE_SELECTED_QUESTION_RIGHT:
      return {
        ...state,
        moveSelectedQuestionsRight:
          defaultQuestionState.moveSelectedQuestionsRight,
      };
    case TOGGLE_ON_SHOW_SCOREBOARD:
      return {
        ...state,
        toggleOnShowScoreboard: true,
      };
    case TOGGLE_ON_SHOW_FIELD:
      return {
        ...state,
        toggleOnShowField: true,
      };

    default:
  }
  return state;
};
