import React, { useEffect, useState } from 'react';
import {
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
} from '@coreui/react';
import { FaCopy } from 'react-icons/fa';
import { DOMAIN } from '../../../../config';

const EventLeaderboardEmbedCodeModal = ({ eventID, show, onClose }) => {
  const [embedCode, setEmbedCode] = useState('');
  const [linkCopied, setLinkCopied] = useState(false);

  useEffect(() => {
    setEmbedCode(
      `<div id="6Tool-leaderboard" eventID="${eventID}"><script src="${DOMAIN}/js/embed-leaderboard.js"></script></div>`
    );
  }, [eventID]);

  const copyToClipboard = () => {
    // navigator.clipboard.writeText(embedCode);
    // You can also show a message that the code has been copied if needed
  };

  return (
    <CModal show={show} onClose={onClose} size="lg" alignment="center">
      <CModalHeader>
        Copy the code below and paste it in your website. This will embed the
        leaderboard in your site. Users will see the leaderboard and it will
        update in real-time (no need to refresh the page)!
      </CModalHeader>
      <CModalBody>
        <div style={{ position: 'relative' }}>
          {linkCopied ? (
            <div
              style={{
                position: 'absolute',
                right: 20,
                top: 8,
                fontSize: '0.8em',
                padding: '0.75rem 1rem',
              }}
            >
              Code copied to clipboard
            </div>
          ) : (
            <CButton
              style={{ position: 'absolute', right: 20, top: 8 }}
              onClick={(e) => {
                e.preventDefault();
                navigator.clipboard.writeText(embedCode);
                setLinkCopied(true);
                setTimeout(() => {
                  setLinkCopied(false);
                }, 2000);
              }}
            >
              <FaCopy />{' '}
              <span style={{ fontSize: '0.8em' }}>Copy to Clipboard</span>
            </CButton>
          )}
          <pre
            style={{
              whiteSpace: 'pre-wrap',
              padding: 20,
              paddingTop: 60,
              borderRadius: 10,
              background: '#efefef',
            }}
          >
            {embedCode}
          </pre>
        </div>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" onClick={onClose}>
          Done
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default EventLeaderboardEmbedCodeModal;
