import { CButton, CCard, CCardBody, CLink, CTooltip } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { TABS } from '../../AppTabs';
import './subscriptionOverviewCard.scss';
import moment from 'moment';
import { FaCog, FaPencilAlt } from 'react-icons/fa';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { pick } from 'lodash';
import { SUBSCRIPTION_TIER } from '../../config';
import { useSubscriptionModal } from '../SubscriptionModal/SubscriptionModal';

const SubscriptionOverviewCard = ({
  teams,
  subscription,
  isFlashingManageSubscription,
  editable,
}) => {
  const [memberSinceString, setMemberSinceString] = useState('?');
  const [renewsOnString, setRenewsOnString] = useState('?');
  const [showNonOwnerModal, setShowNonOwnerModal] = useState(false);
  const isOwner = useSelector((state) => state?.UserState?.isOwner === true);
  const userEmail = useSelector((state) => state?.UserState?.data?.email);
  const userId = useSelector((state) => state?.UserState?.data?.id);
  const owners = useSelector(
    (state) =>
      state?.DataOwnerState?.data?.map((o) => {
        return pick(o, ['email', 'id', 'name']);
      }) || []
  );
  const [hoveringEdit, setHoveringEdit] = useState(false);
  const { showGoingExternalToStripeModal } = useSubscriptionModal();

  useEffect(() => {
    if (!isNaN(subscription?.memberSince)) {
      setMemberSinceString(
        moment
          .unix(parseInt(subscription.memberSince))
          .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
          .format('M/D/YY')
      );
    }
    if (!isNaN(subscription?.upcomingInvoice?.current_period_end?.seconds)) {
      setRenewsOnString(
        moment
          .unix(
            parseInt(subscription.upcomingInvoice.current_period_end.seconds)
          )
          .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
          .format('M/D/YY')
      );
    } else if (!isNaN(subscription?.current?.current_period_end?.seconds)) {
      setRenewsOnString(
        moment
          .unix(parseInt(subscription.current.current_period_end.seconds))
          .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
          .format('M/D/YY')
      );
    }
  }, [subscription]);

  return (
    <div className="coach-team-view-org-details-plan">
      <CCard>
        <CTooltip content="Click to manage your subscription plan, your # of teams, or your payment method">
          <CCardBody
            onMouseEnter={() => {
              setHoveringEdit(true);
            }}
            onMouseLeave={() => setHoveringEdit(false)}
            onClick={
              isOwner
                ? () => {
                    showGoingExternalToStripeModal(userEmail);
                  }
                : () => {
                    setShowNonOwnerModal();
                  }
            }
            style={{ cursor: 'pointer' }}
            className={hoveringEdit ? 'hovered' : ''}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h3>Subscription details</h3>
              {editable && (
                <>
                  <CLink
                    onClick={
                      isOwner
                        ? () => {
                            showGoingExternalToStripeModal(userEmail);
                          }
                        : () => {
                            setShowNonOwnerModal();
                          }
                    }
                  >
                    <>
                      <FaCog style={{ marginTop: -5, marginRight: 6 }} />
                      Manage
                    </>
                  </CLink>
                </>
              )}
            </div>
            {editable ? (
              <div>Member since {memberSinceString}</div>
            ) : (
              <div>{`You are using ${teams.length}/${
                subscription?.current?.quantity || '?'
              } teams in your plan`}</div>
            )}
            <div className="coach-team-view-org-details-plan-grid">
              <div>Plan:</div>
              <div>
                {subscription?.tierString || SUBSCRIPTION_TIER.FREE.label}
              </div>
              <div>{`Plan Admin${owners.length === 1 ? '' : 's'}:`}</div>
              <div>
                <div
                  style={{
                    display: 'inline-grid',
                    gridTemplateColumns: 'repeat(2, auto)',
                    columnGap: '12px',
                  }}
                >
                  {owners.map((o, idx) => (
                    <React.Fragment key={idx}>
                      <div style={{ textAlign: 'left' }}>{o.name}</div>
                      <div>{o.email}</div>
                    </React.Fragment>
                  ))}
                </div>
              </div>

              <div># Teams:</div>

              <div>
                {`${subscription?.current?.quantity || '1'} team${
                  isNaN(subscription?.current?.quantity) ||
                  subscription.current.quantity === 1
                    ? ''
                    : 's'
                }`}
              </div>

              {!editable && (
                <>
                  <div>Active Since:</div>
                  <div>{memberSinceString}</div>
                </>
              )}
              <div>Renews On:</div>
              <div>{renewsOnString}</div>
              <div>Renewal Price:</div>
              <div>
                {!isNaN(subscription?.upcomingInvoice?.amount_due) && (
                  <>
                    {`$${(subscription?.upcomingInvoice?.amount_due / 100)
                      .toFixed(2)
                      .replace('.00', '')}`}
                  </>
                )}
              </div>
            </div>
            {!editable && (
              <CLink to={`${TABS.OWNER_PROFILE_SUBSCRIPTION.path}`}>
                <CButton
                  style={{ width: '100%' }}
                  className="d-block"
                  variant={isFlashingManageSubscription ? null : 'ghost'}
                  color="primary"
                  type="submit"
                >
                  Manage Subscription
                </CButton>
              </CLink>
            )}
          </CCardBody>
        </CTooltip>
      </CCard>
      <ConfirmModal
        show={showNonOwnerModal}
        title="Contact Owner to Manage Subscription"
        message={`You are not the owner of this subscription. Please contact one of the Plan Admins (listed on this page) to manage the subscription.`}
        okButtonColor="primary"
        okButtonText="OK"
        onClickConfirm={() => setShowNonOwnerModal(false)}
      />
    </div>
  );
};
export default SubscriptionOverviewCard;
