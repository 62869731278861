import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Footer from '../Footer';
import { Redirect, useHistory, withRouter } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../..';
import AppNav from '../../../r-components/Nav/AppNav';
import _ from 'lodash';

const InactiveSubscriptionLayout = ({ children }) => {
  const [user, loading, error] = useAuthState(auth);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  if (!_.isNil(shouldRedirect) && _.isString(shouldRedirect)) {
    return <Redirect to={shouldRedirect} />;
  }
  return (
    <>
      <AppNav />
      {loading === true ? (
        <div className="page-loading-auth">Loading account...</div>
      ) : (
        <div className="main-content">{children}</div>
      )}
      <Footer />
    </>
  );
};

InactiveSubscriptionLayout.propTypes = {
  children: PropTypes.node,
};

export default InactiveSubscriptionLayout;
