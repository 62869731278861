import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { FaEnvelope, FaUser } from 'react-icons/fa';
import { RiOrganizationChart } from 'react-icons/ri';
import { Formik, Field, ErrorMessage } from 'formik';
import {
  Button,
  Form as BootstrapForm,
  FormGroup,
  FormLabel,
  InputGroup,
} from 'react-bootstrap';
import { functions } from '../..';
import _ from 'lodash';
import { handleError } from '../../helpers/errors';
import LoadingMessage from '../../r-components/LoadingMessage/LoadingMessage';
import { _signInWithEmailAndPassword } from '../../helpers/authHelper';
import SignupWrapper from '../user/SignupWrapper';

const SignupSchema = Yup.object().shape({
  organizationName: Yup.string().required('Name is required'),
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
});

const SuperAdminCreateFiendly = () => {
  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    const createFriendlyCallable = functions.httpsCallable(
      'createFriendlyCallable'
    );
    try {
      try {
        const createFriendlyResult = await createFriendlyCallable({
          email: values.email,
          name: values.name,
          organizationName: values.organizationName,
          numTeams: values.teams,
        });
        console.log(createFriendlyResult);
        resetForm();
      } catch (e) {
        handleError({
          error: e,
          toast: e.message.replace('Firebase:', '').trim(),
          internal: `SuperAdminCreateFriendly.js:onSubmit`,
        });
      }
    } catch (err) {
      handleError({
        error: err,
        toast: err.message.replace('FirebaseError:', '').trim(),
        internal: `SuperAdminCreateFriendly.js:onSubmit::catch`,
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <SignupWrapper>
      <div className="signup-header mb-4">
        <div />
        <h2 className="text-center font-weight-bold">
          Create Friendly Account
        </h2>
      </div>
      <Formik
        initialValues={{
          name: '',
          email: '',
          organizationName: '',
        }}
        validationSchema={SignupSchema}
        onSubmit={onSubmit}
        validateOnBlur
        validateOnChange
      >
        {({ errors, touched, handleSubmit, isSubmitting, isValid, values }) => (
          <>
            <BootstrapForm onSubmit={handleSubmit}>
              <div className="signup-create-account-section friendly">
                <div className="signup-create-account">
                  <FormGroup>
                    <FormGroup>
                      <FormLabel htmlFor="organizationName">
                        Team / Organization Name
                      </FormLabel>
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>
                            <RiOrganizationChart />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Field
                          type="text"
                          name="organizationName"
                          id="organizationName"
                          autoComplete="off"
                          className={`form-control ${
                            touched.organizationName && errors.organizationName
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <ErrorMessage
                          component={BootstrapForm.Control.Feedback}
                          name="organizationName"
                          className="invalid-feedback"
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormLabel htmlFor="name">Name</FormLabel>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <FaUser />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Field
                        type="text"
                        name="name"
                        id="name"
                        autoComplete="name"
                        as={BootstrapForm.Control}
                        className={`form-control ${
                          touched.name && errors.name ? 'is-invalid' : ''
                        }`}
                      />
                      <ErrorMessage
                        component={BootstrapForm.Control.Feedback}
                        name="name"
                        className="invalid-feedback"
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <FaEnvelope />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Field
                        type="email"
                        name="email"
                        id="email"
                        autoComplete="email"
                        className={`form-control ${
                          touched.email && errors.email ? 'is-invalid' : ''
                        }`}
                      />
                      <ErrorMessage
                        component={BootstrapForm.Control.Feedback}
                        name="email"
                        className="invalid-feedback"
                      />
                    </InputGroup>
                  </FormGroup>
                </div>
              </div>
              {isSubmitting ? (
                <LoadingMessage
                  firstMessage="Creating account..."
                  style={{ height: 53 }}
                />
              ) : (
                <Button
                  type="submit"
                  color="primary"
                  className="btn-block"
                  disabled={
                    !isValid ||
                    !(_.isString(values.email) && values.email.length > 0)
                  }
                >
                  Create Acccount
                </Button>
              )}
            </BootstrapForm>
          </>
        )}
      </Formik>
    </SignupWrapper>
  );
};

export default SuperAdminCreateFiendly;
