import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CListGroup,
  CListGroupItem,
  CRow,
  CSpinner,
} from '@coreui/react';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { useFormik } from 'formik';
import { isArray } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FaTrashAlt, FaUndo } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { viewGroups } from '../../../../actions/groupActionCreator';
import { db, functions } from '../../../../index';
import CreateUser from './CreateUser';
import SelectUsers from './SelectUsers';
import GroupWrapper from '../../../../pages/component/GroupWrapper';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import HeaderWithBackButton from '../../../../r-components/HeaderWithBackButton/HeaderWithBackButton';
import { handleError } from '../../../../helpers/errors';
import LoadingMessage from '../../../../r-components/LoadingMessage/LoadingMessage';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import { EMAIL_ADDRESSES } from '../../../../config';

// user status: IN_GROUP, NEW_TO_GROUP, DELETE_FROM_GROUP
const getUserColor = (user) => {
  switch (user.status) {
    case 'NEW_TO_GROUP':
      return 'success';

    case 'DELETE_FROM_GROUP':
      return 'danger';

    default:
      return '';
  }
};

const EditGroup = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isMounted = useRef(false);
  const user = useSelector((state) => state.UserState);

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [users, setUsers] = useState([]);
  const [existingModalIsVisible, setAddExistingUsersIsVisible] =
    useState(false);
  const [usersToSelectFrom, setUsersToSelectFrom] = useState([]);
  const players = useSelector((state) => state.DataPlayerState?.data || []);

  const [groupId, setGroupId] = useState('a');
  const group = useSelector((state) =>
    _.isArray(state.DataGroupState?.data) &&
    state.DataGroupState.data.length > 0
      ? (state.DataGroupState?.data || {}).find((g) => g.id === groupId) || {}
      : {}
  );

  useEffect(() => {
    setGroupId(match.params.id || 'a');
  }, [match]);

  useEffect(() => {
    setUsers(players.filter((p) => p._groups.includes(groupId)));
    setUsersToSelectFrom(players.filter((p) => !p._groups.includes(groupId)));
  }, [groupId, players]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: group?.title || '',
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required(),
    }),
  });

  const updateGroup = async () => {
    const { title } = formik.values;
    const groupsRef = doc(db, 'groups', groupId);
    const updateUsersGroup = functions.httpsCallable('updateUsersGroup');
    //HERE
    const organizationId = user.organizationId || 'a';

    try {
      setSubmitting(true);
      if (group.title !== title) {
        await updateDoc(groupsRef, {
          title,
        });
      }

      let usersToAdd = users
        .filter((u) => u.status === 'NEW_TO_GROUP')
        .map((u) => u._id);
      let usersToRemove = users
        .filter((u) => u.status === 'DELETE_FROM_GROUP')
        .map((u) => u._id);

      let successful = true;
      if (_.isArray(usersToAdd) && usersToAdd.length > 0) {
        let addUserIDsToGroupCallableResult = await functions.httpsCallable(
          'addUserIDsToGroupCallable'
        )({
          user_ids: usersToAdd,
          team_id: group?.teamId,
          organization_id: organizationId,
          group_id: group?.id,
        });
        if (addUserIDsToGroupCallableResult.data !== true) {
          successful = false;
        }
      }

      if (_.isArray(usersToRemove) && usersToRemove.length > 0) {
        let removeUserIDsToGroupCallableResult = await functions.httpsCallable(
          'removeUserIDsToGroupCallable'
        )({
          user_ids: usersToRemove,
          team_id: group?.teamId,
          organization_id: organizationId,
          group_id: group?.id,
        });
        if (removeUserIDsToGroupCallableResult.data !== true) {
          successful = false;
        }
      }
      setSubmitting(false);

      if (successful) {
        history.goBack();
        toast(
          `Group updated.${
            _.isArray(users) && users.length > 0
              ? ` Users being added to group in the background. This may take a minute. You can continue to use the app normally.`
              : ''
          }`,
          { type: 'success' }
        );
      } else {
        toast(
          `Group update was not completed. Please try again. If this persists, please contact ${EMAIL_ADDRESSES.SUPPORT}.`,
          { type: 'error' }
        );
      }

      // if (addUsersToGroupCallableResult.data === true) {

      console.log(users);
      // await updateUsersGroup({
      //   users,
      //   group,
      //   organizationId,
      //   teamId,
      // });

      // setTimeout(() => {
      //   setSubmitting(false);
      //   history.goBack();
      //   toast(
      //     `Group updated.${
      //       _.isArray(users) && users.length > 0
      //         ? ` Users being added to group in the background. This may take a minute. You can continue to use the app normally.`
      //         : ''
      //     }`,
      //     { type: 'success' }
      //   );
      // }, 1000);
      setSubmitting(false);
    } catch (e) {
      handleError({
        error: e,
        internal: 'EditGroup - updateGroup',
      });
    }
  };

  const addExistingUsers = (newUsers) => {
    const newSelectedUsers = [];
    const groupUsers = [...users];
    const selectedUsers = [...newUsers].filter((user) => user.isChecked);
    const removedUsers = [...newUsers].filter((user) => !user.isChecked);
    const usersIds = users.map((user) => user.id);

    selectedUsers.forEach((selectedUser) => {
      if (!usersIds.includes(selectedUser.id)) {
        newSelectedUsers.push({
          ...selectedUser,
          oldStatus: 'NEW_TO_GROUP',
          status: 'NEW_TO_GROUP',
        });
      }
    });

    removedUsers.forEach((removedUser) => {
      if (usersIds.includes(removedUser.id)) {
        const userIndex = groupUsers.findIndex(
          (groupUser) => groupUser.id === removedUser.id
        );
        groupUsers[userIndex] = { ...removedUser, status: 'DELETE_FROM_GROUP' };
      }
    });

    const updatedUsers = [...newSelectedUsers, ...groupUsers];
    setUsersToSelectFrom(newUsers);
    setUsers(updatedUsers);
  };

  const removeUser = (user, index) => {
    const newUsers = [...users];
    newUsers.splice(index, 1);
    const updateUsers = [...newUsers, { ...user, status: 'DELETE_FROM_GROUP' }];
    setUsers(updateUsers);
  };

  const undoDeletingUser = (user, index) => {
    const newUsers = [...users];
    newUsers.splice(index, 1);
    const status = user.oldStatus;
    const updateUsers = [...newUsers, { ...user, status }];
    setUsers(updateUsers);
  };

  const renderButtonsHandler = (user, index) => {
    if (user.status === 'DELETE_FROM_GROUP') {
      return (
        <CButton color="danger" onClick={() => undoDeletingUser(user, index)}>
          <FaUndo />
        </CButton>
      );
    } else {
      return (
        <CButton color="danger" onClick={() => removeUser(user, index)}>
          <FaTrashAlt />
        </CButton>
      );
    }
  };

  const addUserToGroup = (newUsers) => {
    const updatedUsers = [...users, ...newUsers];
    setUsers(updatedUsers);
  };

  return (
    <GroupWrapper>
      <CRow className="pt-3 pb-3">
        <CCol>
          <CCard className="mb-2">
            {!loading ? (
              <>
                <HeaderWithBackButton>Group</HeaderWithBackButton>
                <CCardBody>
                  <form onSubmit={formik.handleSubmit}>
                    <CFormGroup row>
                      <CCol xs="12">
                        <CLabel htmlFor="title">
                          Title:
                          <span className="text-danger">*</span>
                        </CLabel>
                      </CCol>

                      <CCol xs="12">
                        <CInput
                          required
                          id="title"
                          defaultValue={group?.title}
                          value={formik.values.title}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </CCol>
                    </CFormGroup>
                  </form>
                  <h4 className="mb-1">Group members</h4>
                  {(users || []).length > 0 ? (
                    <CListGroup className="mt-3 mb-3">
                      {users?.map((user, index) => (
                        <CListGroupItem
                          key={user.id || index}
                          color={getUserColor(user)}
                          className="d-flex justify-content-between"
                        >
                          <p className="p-0 m-0">
                            <b>Name:</b> {user.name} <br />
                            <b>Email:</b> {user.email} <br />
                          </p>

                          <div>{renderButtonsHandler(user, index)}</div>
                        </CListGroupItem>
                      ))}
                    </CListGroup>
                  ) : (
                    <p>
                      There are no users for this group, you can add them from
                      group section
                    </p>
                  )}
                  <div>
                    <Button
                      color="primary"
                      className="btn-inline-block"
                      onClick={() => setAddExistingUsersIsVisible(true)}
                    >
                      Add team members to group
                    </Button>
                  </div>
                </CCardBody>

                <CCardFooter className="text-right">
                  {submitting ? (
                    <LoadingMessage
                      firstMessage="Updating group..."
                      style={{ justifyContent: 'flex-end', height: 53 }}
                    />
                  ) : (
                    <>
                      <CButton
                        color="danger"
                        variant="outline"
                        className="mr-2"
                        onClick={history.goBack}
                      >
                        Cancel
                      </CButton>
                      <CButton
                        disabled={
                          !formik.values.title ||
                          !!formik.errors.title ||
                          submitting
                        }
                        color="primary"
                        onClick={updateGroup}
                      >
                        Update Group
                      </CButton>
                    </>
                  )}
                </CCardFooter>
              </>
            ) : (
              <CCol xl={12} className="text-center pt-2 pb-2">
                <CSpinner variant="grow" size="lg" />
              </CCol>
            )}
          </CCard>
        </CCol>
      </CRow>

      <SelectUsers
        usersToSelectFrom={usersToSelectFrom.filter(
          (u) => u.isChecked !== true
        )}
        title={formik.values.title}
        isVisible={existingModalIsVisible}
        onClose={setAddExistingUsersIsVisible}
        addSelectedUsers={addExistingUsers}
      />
    </GroupWrapper>
  );
};

export default EditGroup;
