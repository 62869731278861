import React, { Component } from 'react';

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div className="d-flex">
          <div className="row m-5">
            <div className="col-md-6 d-flex" style={{ alignItems: 'center' }}>
              <img
                src="/img/aboutus/batter.jpg"
                className="img-fluid"
                alt="batter"
              />
            </div>
            <div className="col-md-6 d-flex" style={{ alignItems: 'center' }}>
              <div className="container add-team-column">
                <div className="mx-lg-auto">
                  <p className="font-weight-normal\ mb-5 text-primary">
                    6Tool brings the experience and perspective from college
                    coaches and former D1/ professional athletes who recognized
                    the importance of developing baseball IQ to ultimate success
                    on the field. They saw, time and time again, players with
                    the talent, metrics and physical ability who underperformed
                    in game settings. They wanted to find a way to improve
                    situational knowledge and to provide opportunities to put
                    that enhanced knowledge into practice and 6Tool was born.
                  </p>
                  {/* <table className="table">
                <tbody>
                  <tr>
                    <td>
                      <span className="font-weight-bold">
                        The Competition
                      </span>{' '}
                      <i className="fas fa-long-arrow-alt-right"></i> Poorly
                      matched
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="font-weight-bold">
                        The Event Organization
                      </span>{' '}
                      <i className="fas fa-long-arrow-alt-right"></i>{' '}
                      Abysmal
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="font-weight-bold">Travel Costs</span>{' '}
                      <i className="fas fa-long-arrow-alt-right"></i>{' '}
                      Prohibitive
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="font-weight-bold">Wasted Time</span>{' '}
                      <i className="fas fa-long-arrow-alt-right"></i> Too
                      Much!
                    </td>
                  </tr>
                </tbody>
              </table> */}
                  <br />
                  <p>
                    <a href="/why" className="btn btn-lg btn-primary">
                      <h4 className="font-weight-black m-0">Why 6Tool?</h4>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AboutUs;
