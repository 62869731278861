import {
  SET_CURRENT_QUIZZES,
  CLEAR_QUIZZES,
  SET_CURRENT_QUIZZES_SCHEDULED,
} from './constants';

/**
 * Set current user
 */
export function setCurrentQuizzes(payload) {
  return {
    type: SET_CURRENT_QUIZZES,
    payload,
  };
}
export function clearQuizzes() {
  return {
    type: CLEAR_QUIZZES,
  };
}
export function setCurrentScheduledQuizzes(payload) {
  return { type: SET_CURRENT_QUIZZES_SCHEDULED, payload };
}
