import Container from '../Container/Container';
import {
  Background,
  Content,
  ContentText,
  H1,
  ImageWrapper,
  Wrapper,
} from './JumbotronStyles';

import AppStore from '../../assets/app-store.png';
import JumboImage from '../../assets/jump-image.png';
import playStore from '../../assets/play-store.png';
import React from 'react';

const Jumbotron = () => {
  return (
    <Wrapper>
      <Background>
        <Container>
          <Content>
            <ContentText>
              <H1>
                Level Up Your <br /> Players' <span>Baseball Skills</span> With
                The 6Tool App
              </H1>
              <p>
                Join the ranks of successful coaches using 6Tool to win more
                games and develop superior baseball players. Take advantage of
                our easy-to-use app that enhances your team's baseball IQ and
                assist in your players' development.
              </p>

              <div>
                <span>Download Our App Now !</span>
                <img src={AppStore} />
                <img src={playStore} />
              </div>

              <button>let’s get started</button>
            </ContentText>
            <ImageWrapper>
              <img src={JumboImage} />
            </ImageWrapper>
          </Content>
        </Container>
      </Background>
    </Wrapper>
  );
};

export default Jumbotron;
