import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CLink,
  CRow,
  CTooltip,
} from '@coreui/react';
import './examDetails.scss';
import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { db, functions, storage } from '../../../index';
import { Loading } from '../../../r-reusable';
import _ from 'lodash';
import { Container } from 'react-bootstrap';
import ExamPlayerResults from './ExamPlayerResults';
import { format } from 'date-fns';
import QuizWrapper from '../../../pages/component/QuizWrapper';
import HeaderWithBackButton from '../../../r-components/HeaderWithBackButton/HeaderWithBackButton';
import QuizQuestionsModal from '../../../r-components/QuizQuestionsModal/QuizQuestionsModal';
import { renderToString } from 'react-dom/server';
import { TABS, paramEncode } from '../../../AppTabs';
import { toast } from 'react-toastify';
import Letterhead from '../../../r-components/Letterhead/Letterhead';
import { handleError } from '../../../helpers/errors';
import { QuizActions } from '../../../r-components';
import { FaPencilAlt, FaQuestion, FaTrashAlt } from 'react-icons/fa';
import { shortDateTime } from '../../../helpers/utils';
import QuizPrintout from '../../../r-components/QuizPrintout/QuizPrintout';
import { CButton } from '@coreui/react';
import ConfirmModal from '../../../r-components/ConfirmModal/ConfirmModal';
import { useHistory } from 'react-router-dom';
import {
  ACCESS_ACTIONS,
  canDoAction,
  checkAccess,
} from '../../../helpers/access';
import useAccountSelectors from '../../../hooks/useAccountSelectors';

const QuizDetailsPage = ({ match }) => {
  const history = useHistory();
  const isMounted = useRef(false);
  const organizationID = useSelector(
    (state) => state?.DataOrganizationState?.data?.id
  );
  const teamID = useSelector((state) => state?.DataTeamState?.teamData?._id);
  const [exam, setExam] = useState(null);
  const [loading, setLoading] = useState(true);
  const [accordion, setAccordion] = useState(null);
  const [quizId, setQuizId] = useState('a');
  const [quiz, setQuiz] = useState(null);
  const [showingQuestions, setShowingQuestions] = useState(false);
  const examData = useSelector((state) => state.DataExamState || {});
  const playerMap = useSelector((state) => state.DataPlayerState?.map || {});
  const [takenExams, setTakenExams] = useState([]);
  const [numQuestions, setNumQuestions] = useState(0);
  const [invitedPlayers, setInvitedPlayers] = useState([]);
  const [questionResults, setQuestionResults] = useState([]);
  const [visibleItemIndex, setVisibleItemIndex] = useState(0);
  const [showingQuestionsSingleResponse, setShowingQuestionsSingleResponse] =
    useState(false);
  const [showQuestionsResponses, setShowQuestionsResponses] = useState(null);
  const [pdfLoading, setPDFLoading] = useState(false);
  const teamData = useSelector((state) => state.DataTeamState);
  const orgData = useSelector((state) => state.DataOrganizationState);
  const numPlayers = useSelector(
    (state) => (state.DataPlayerState?.data || []).length
  );
  const [questionIds, setQuestionIds] = useState([]);
  const [inEditMode, setInEditMode] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showConfirmAgainDeleteModal, setShowConfirmAgainDeleteModal] =
    useState(false);

  const { type, tier, showSubscriptionModal } = useAccountSelectors();

  const allQuestionsMap = useSelector((state) =>
    (state.DataQuestionState?.data || [])
      .filter((q) => questionIds.includes(q.id))
      .reduce((acc, obj) => {
        acc[obj.id] = obj;
        return acc;
      }, {})
  );

  useEffect(() => {
    setLoading(true);
    let hasOptions = true;
    if (_.isObject(quiz) && _.isArray(quiz.questions)) {
      quiz.questions.forEach((q) => {
        if (!(_.isArray(q.options) && q.options.length > 0)) {
          hasOptions = false;
        }
      });
    }
    if (!hasOptions) {
      setQuestionIds(quiz.questions.map((q) => q.questionId));
    }
    setLoading(false);
  }, [quiz]);

  const itemRefs = useRef([]);

  useEffect(() => {
    setQuizId(match.params.id || 'a');
  }, [match]);

  useEffect(() => {
    let tExams = [];
    let maxQuestions = 0;
    let questionResults = {}; // will become an array
    let t = (examData?.taken || [])
      .filter((t) => t.quiz_id === quizId)
      .map((t) => {
        let taken = _.pick(t, [
          'finished',
          'num_correct',
          'point',
          'score',
          'user_id',
        ]);
        taken.score = parseFloat(t.score);
        taken.playerName =
          _.isObject(playerMap) && _.isObject(playerMap[t.user_id])
            ? playerMap[t.user_id].name || ''
            : '';
        maxQuestions = Math.max(maxQuestions, (t.questions || []).length);
        taken.questions = t.questions.map((q, qIdx) => {
          if (!_.isObject(questionResults[qIdx])) {
            questionResults[qIdx] = {
              idx: qIdx,
              correct: 0,
              responses: 0,
              points: 0,
              maxPoints: 0,
              answers: {},
            };
          }
          questionResults[qIdx].responses += 1;
          questionResults[qIdx].correct += q.correct;
          questionResults[qIdx].points += q.points;
          questionResults[qIdx].maxPoints += q.maxPoints;
          if (!isNaN(q.default_order)) {
            if (isNaN(questionResults[qIdx].answers[q.default_order])) {
              questionResults[qIdx].answers[q.default_order] = 1;
            } else {
              questionResults[qIdx].answers[q.default_order] += 1;
            }
          }
          return {
            ..._.pick(q, ['correct', 'points', 'maxPoints']),
            ...{ answer: q.default_order },
          };
        });
        taken.finished = t.finished;
        taken.dateString =
          !isNaN(t?.finished?.seconds) || !isNaN(t?.created)
            ? format(
                new Date(t?.finished?.seconds * 1000 || t?.created),
                'MMM d'
              )
            : '';
        return taken;
      });
    setQuestionResults(
      _.reduce(
        questionResults,
        (result, item) => {
          const { idx, ...rest } = item;
          result[idx] = rest;
          return result;
        },
        []
      )
    );
    setNumQuestions(maxQuestions);

    setTakenExams(t);
    const takenUserIDs = t.map((t) => t.user_id);

    let i = (examData?.invites || [])
      .filter((i) => i.quiz_id === quizId)
      .filter((i) => !takenUserIDs.includes(i.user_id))
      .map((i) => {
        let invite = _.pick(i, [
          'created',
          'group_id',
          'user_id',
          'isNew',
          'team_id',
          'exam_id',
          'quiz_id',
          'organization_id',
        ]);
        invite.playerName =
          _.isObject(playerMap) && _.isObject(playerMap[i.user_id])
            ? playerMap[i.user_id].name || ''
            : '';
        invite.created_formatted = format(
          new Date(i?.created?.seconds * 1000 || i?.created),
          'MMM d'
        );
        return invite;
      });
    setInvitedPlayers(
      i.sort((a, b) => {
        return a.playerName.localeCompare(b.playerName);
      })
    );
  }, [examData, quizId, playerMap]);

  const _handleSetShowingQuestions = (shouldShow) => {
    if (shouldShow === false) {
      setShowingQuestionsSingleResponse(false);
      setShowQuestionsResponses([]);
      setShowingQuestions(shouldShow);
    } else {
      checkAccess({
        action: [ACCESS_ACTIONS.VIEW_AUTO_QUIZ_QUESTIONS],
        type,
        tier,
        showSubscriptionModal,
      }).doWithAccess(() => {
        setShowingQuestions(shouldShow);
      });
    }
  };
  useEffect(() => {
    isMounted.current = true;

    const loadExams = async () => {
      setLoading(true);

      const questionsPromises = [];
      let quizData;

      const quizResponse = await getDoc(doc(db, 'quizzes', quizId));
      if (quizResponse.exists) {
        quizData = { id: quizResponse.id, ...quizResponse.data() };
      }

      (quizData.questions || []).forEach((questionId) => {
        questionsPromises.push(getDoc(doc(db, 'questions', questionId)));
      });

      quizData.questions = [];

      const questionsResponse = await Promise.all(questionsPromises);
      questionsResponse.forEach((question, index) => {
        if (question.exists) {
          let qObj = { id: question.id };
          let qData = question.data();
          if (!!qData && _.isObject(qData) && !_.isEmpty(qData)) {
            qObj = { ...qObj, ...qData };
          } else {
            qObj.deleted = true;
          }
          quizData.questions.push(qObj);
        }
      });

      if (isMounted.current) {
        setLoading(false);
        setQuiz(quizData);
      }
    };

    loadExams();

    return () => {
      isMounted.current = false;
    };
  }, [quizId]);

  const generatePDF = async () => {
    try {
      setPDFLoading(true);
      const generatePDFCallable = functions.httpsCallable(
        'generatePDFCallable'
      );

      // Render your React component and get the generated HTML
      const component = renderToString(
        <Container>
          <CCard>
            <CCardHeader>
              <Letterhead
                teamData={teamData}
                orgData={orgData}
                numPlayers={numPlayers}
                reportName={quiz?.title}
              />
            </CCardHeader>
            <CCardBody>
              <ExamPlayerResults
                questions={quiz.questions}
                takenExams={takenExams}
                invitedPlayers={invitedPlayers}
                questionResults={questionResults}
                forPrint={true}
                showSinglePlayerResultModal={(responses) => {
                  setShowQuestionsResponses(responses);
                  setShowingQuestionsSingleResponse(true);
                  _handleSetShowingQuestions(true);
                }}
              />
            </CCardBody>
          </CCard>
        </Container>
      );
      const css = `.quiz-results-grid-container {display: flex;  flex-direction: column;  .quiz-results-grid-row { display: grid;    justify-content: center;    cursor: pointer;    & > .quiz-results-grid-header {cursor: default;} & > .quiz-results-grid-header.quiz-results-question-number-header {text-align: center;cursor: help;} &:hover {& > div:not(.quiz-results-grid-header) {  background-color: #efefef;}.show-on-hover {visibility: visible;} } .show-on-hover {visibility: hidden;}}.quiz-results-separator-header {display: flex;justify-content: space-between;    align-items: center;    border-top: 1px solid black; text-align: center; margin-top: 20px; padding-top: 20px; padding-bottom: 10px; cursor: pointer;  }}`;
      const html = `<html><header><style>${css}</style><body>${component}</body></html>`;

      // Invoke the Cloud Function and pass the HTML content
      const response = await generatePDFCallable({
        html,
        teamID,
        organizationID,
        filename: `org-${organizationID}/${
          _.isString(quiz.teamId) && quiz.teamId.length > 1
            ? `team-${quiz.teamId}/`
            : ''
        }quiz-${paramEncode(quiz?.title || 'quiz')}-$TIME`,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
      let filename = response.data.filename;
      if (_.isString(filename) && filename.length > 0) {
        let downloadUrl = await storage.ref().child(filename).getDownloadURL();
        window.open(downloadUrl);
        try {
          const addMediaCallable = functions.httpsCallable('addMediaCallable');
          addMediaCallable({
            downloadUrl,
            organizationId: organizationID,
            teamId:
              _.isString(quiz.teamId) && quiz.teamId.length > 1
                ? quiz.teamId
                : teamID,
            usage: 'report',
          });
        } catch (error) {
          console.error(error);
          handleError({ internal: 'ExamDetails - generatePDF uploadMedia' });
        }
        toast('PDF Downloaded', { type: 'success' });
      } else {
        handleError({
          error: `Error generating PDF: ${filename}`,
          internal: 'ExamDetails - generatePDF',
          toast: 'There was an error generating PDF',
        });
      }
    } catch (error) {
      handleError({
        error,
        internal: 'ExamDetails - generatePDF',
        toast: 'There was an error generating PDF',
      });
    } finally {
      setPDFLoading(false);
    }
  };

  const closeAllModals = () => {
    setShowConfirmDeleteModal(false);
    setShowConfirmAgainDeleteModal(false);
  };

  const { canDo: canDoEditQuiz, canView: canViewEditQuiz } = canDoAction({
    action: ACCESS_ACTIONS.EDIT_QUIZ,
    type,
    tier,
  });
  const { canDo: canDoDeleteQuiz, canView: canViewDeleteQuiz } = canDoAction({
    action: ACCESS_ACTIONS.DELETE_QUIZ,
    type,
    tier,
  });

  return (
    <QuizWrapper>
      <ConfirmModal
        show={showConfirmDeleteModal && !showConfirmAgainDeleteModal}
        title="Confirm Delete"
        message="Are you sure you would like to delete this quiz? THIS CANNOT BE UNDONE."
        okButtonColor="danger"
        cancelButtonColor="primary"
        okButtonText="Delete"
        onClickCancel={closeAllModals}
        onClickConfirm={async () => {
          setShowConfirmDeleteModal(false);
          setShowConfirmAgainDeleteModal(true);
        }}
      />
      <ConfirmModal
        show={showConfirmAgainDeleteModal}
        title="Re-Confirm Delete"
        message="Are you sure you would like to delete this quiz? THIS IS YOUR LAST WARNING. The quiz will be deleted and this cannot be undone."
        okButtonColor="danger"
        cancelButtonColor="primary"
        okButtonText="Delete"
        onClickCancel={closeAllModals}
        onClickConfirm={async () => {
          setShowConfirmAgainDeleteModal(false);
          toast(
            'Quiz being deleted. It may take a minute for the quiz to be removed.',
            { type: 'success' }
          );
          history.push(TABS.COACH_QUIZZES.path);
          let deleteQuizFromOrgCallable = functions.httpsCallable(
            'deleteQuizFromOrgCallable'
          );
          let deleteResult = await deleteQuizFromOrgCallable({
            quizId: quiz?.id || '',
            organizationId: orgData.data.id,
          });
        }}
      />{' '}
      <Container className="space-1">
        <CRow>
          <CCol xs="12">
            {loading || _.isNil(quiz) ? (
              <Loading />
            ) : (
              <>
                <CCard
                  className={
                    showingQuestions ? 'showingQuestions' : 'showingResults'
                  }
                  key={`quiz-card-${quiz?.id || ''}`}
                >
                  <HeaderWithBackButton>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: 10,
                      }}
                    >
                      <h2 style={{ margin: 0 }}>{quiz?.title || ''}</h2>
                      {!isNaN(quiz?.estTime) && (
                        <>
                          <div>({quiz.estTime} min)</div>
                        </>
                      )}
                      {!inEditMode && canViewEditQuiz && (
                        <CTooltip content="Edit quiz">
                          <CLink
                            onClick={() => {
                              setInEditMode(true);
                            }}
                          >
                            <FaPencilAlt />
                          </CLink>
                        </CTooltip>
                      )}

                      <div style={{ flex: 1 }}></div>
                      {!inEditMode ? (
                        <>
                          <CLink
                            onClick={() => {
                              _handleSetShowingQuestions(true);
                            }}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              gap: 6,
                            }}
                            className="noprint"
                          >
                            <FaQuestion />
                            <span style={{ marginTop: 3, fontSize: '0.8em' }}>
                              View Questions
                            </span>
                          </CLink>

                          <QuizActions className="noprint" {...quiz} />
                        </>
                      ) : (
                        <>
                          <CButton
                            className="noprint"
                            size="md"
                            variant="outline"
                            color="danger"
                            onClick={() => {
                              setInEditMode(false);
                            }}
                          >
                            {`Cancel`}
                          </CButton>
                          {canViewDeleteQuiz && (
                            <CButton
                              className="noprint"
                              size="md"
                              color="danger"
                              variant="ghost"
                              onClick={() => {
                                setShowConfirmDeleteModal(true);
                              }}
                            >
                              <FaTrashAlt
                                style={{ marginRight: 6, marginTop: -2 }}
                                size={20}
                              />
                              {`Delete Quiz`}
                            </CButton>
                          )}
                        </>
                      )}
                    </div>
                    <div className="printonly">{shortDateTime()}</div>
                  </HeaderWithBackButton>
                  <CCardBody className="pb-1">
                    {quiz?.intros && (
                      <p className="mb-0">
                        <b>Introduction:</b> {quiz?.intros || ''}
                      </p>
                    )}
                  </CCardBody>
                  <QuizPrintout
                    className="quizPrintout"
                    quiz={quiz}
                    allQuestionsMap={allQuestionsMap}
                    itemRefs={itemRefs}
                    singleResponse={showingQuestionsSingleResponse}
                    responses={showQuestionsResponses}
                    visibleItemIndex={visibleItemIndex}
                  />
                  <CCol
                    className={'playerResults'}
                    style={{
                      minHeight: `calc(100vh - 500px)`,
                      flex: 1,
                    }}
                  >
                    <ExamPlayerResults
                      questions={quiz.questions}
                      takenExams={takenExams}
                      invitedPlayers={invitedPlayers}
                      questionResults={questionResults}
                      showSinglePlayerResultModal={(responses) => {
                        setShowQuestionsResponses(responses);
                        setShowingQuestionsSingleResponse(true);
                        _handleSetShowingQuestions(true);
                      }}
                      generatePDF={generatePDF}
                      loadingPDF={pdfLoading}
                    />
                  </CCol>
                </CCard>
              </>
            )}
          </CCol>
        </CRow>
      </Container>
      <QuizQuestionsModal
        show={showingQuestions}
        setShow={_handleSetShowingQuestions}
        quiz={quiz}
        singleResponse={showingQuestionsSingleResponse}
        responses={showQuestionsResponses}
      />
    </QuizWrapper>
  );
};

export default QuizDetailsPage;
