import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getAnnualSubscriptionExpirationDate } from '../../helpers/utils';
import { today } from '../../helpers/constants';
import { TABS } from '../../AppTabs';

class Addteam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
    };
  }

  componentDidMount = () => {
    if (
      !this.props.activeSubscription ||
      (localStorage.getItem('subscription_expiration_date') &&
        getAnnualSubscriptionExpirationDate(
          localStorage.getItem('subscription_expiration_date')
        ) < today)
    ) {
      this.props.history.push(TABS.SUBSCRIPTION.path);
    }

    this.setState({
      userName: localStorage.getItem('user_name'),
    });
  };

  render() {
    return (
      <div>
        <div className="d-flex">
          <div>
            <img
              src="/img/create-team/create-team.jpg"
              className="img-fluid"
              alt="fielder"
            />
          </div>
          <div className="container mt-15 mr-9 add-team-column">
            <div className="mx-lg-auto">
              <div>
                <h4 className="font-weight-normal\ mb-5 text-primary">
                  Welcome to 6Tool! You are one step closer to helping your team
                  learn and understand the way you envision the game. A better
                  understanding of the game can take your team from good to
                  great! How many games have been lost by a missed sign or the
                  throw going to the incorrect base. Now you have the ability to
                  see what knowledge your players have prior to games so you
                  have a better understanding of what needs to be taught.
                </h4>
                <Link to="/createteam">
                  <div className="btn btn-sm btn-primary text-white addteambtntext mb-5">
                    Add your first team
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Addteam;
