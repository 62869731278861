import CIcon from '@coreui/icons-react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CLink,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
} from '@coreui/react';
import { Form, Formik } from 'formik';
import { Form as BootstrapForm } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setCurrentUser } from '../../../r-store/user/actions';
import { auth, db } from '../../..';
// import { auth, db } from 'src/utils/firebase';
import * as Yup from 'yup';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { FaEnvelope, FaLock, FaUserLock } from 'react-icons/fa';
import { useAuthState } from 'react-firebase-hooks/auth';
import { TABS } from '../../../AppTabs';
import { toast } from 'react-toastify';
import { handleError } from '../../../helpers/errors';
import _ from 'lodash';
import {
  _sendPasswordResetEmail,
  _signInWithEmailAndPassword,
} from '../../../helpers/authHelper';

const validationSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
  email: Yup.string().trim().email().required('Email is required'),
});

const Login = () => {
  const [user, userLoading, error] = useAuthState(auth);
  let history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [resetPasswordEmail, setResetPasswordEmail] = useState('');
  const [
    showResetPasswordEnterEmailModal,
    setShowResetPasswordEnterEmailModal,
  ] = useState(false);

  useEffect(() => {
    if (!!user && !loading) {
      history.push(TABS.COACH_APP_HOME.path);
    }
  }, [user, loading]);
  const onSubmit = async ({ email, password }, _) => {
    try {
      setLoading(true);
      await _signInWithEmailAndPassword(email, password);
    } catch (e) {
      handleError({
        error: e,
        internal: 'Login.js - onSubmit',
        toast: e.message.replace('Firebase:', '').trim(),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="c-app c-default-layout flex-row align-items-center login-background"
      style={{ minHeight: 'calc(100vh - 464px)' }}
    >
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <Formik
                    initialValues={{ password: '', email: '' }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <h1 style={{ textAlign: 'center' }}>
                          Login To Your Account
                        </h1>
                        <CInputGroup className="mb-3">
                          <CInputGroupPrepend>
                            <CInputGroupText>
                              <FaEnvelope />
                            </CInputGroupText>
                          </CInputGroupPrepend>
                          <CInput
                            className={
                              touched.email && errors.email ? 'is-invalid' : ''
                            }
                            id="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            type="text"
                            placeholder="Email"
                          />
                          {touched.email && errors.email && (
                            <div className="invalid-feedback">
                              {errors.email}
                            </div>
                          )}
                        </CInputGroup>
                        <CInputGroup className="mb-4">
                          <CInputGroupPrepend>
                            <CInputGroupText>
                              <FaLock />
                            </CInputGroupText>
                          </CInputGroupPrepend>
                          <CInput
                            id="password"
                            className={
                              touched.password && errors.password
                                ? 'is-invalid'
                                : ''
                            }
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            type="password"
                            placeholder="Password"
                            autoComplete="current-password"
                          />
                          {touched.password && errors.password && (
                            <div className="invalid-feedback">
                              {errors.password}
                            </div>
                          )}
                        </CInputGroup>
                        <CRow>
                          <CCol xs="12" style={{ textAlign: 'right' }}>
                            <CLink
                              label="Forgot Password?"
                              onClick={async () => {
                                try {
                                  if (
                                    _.isString(values.email) &&
                                    values.email.length > 0 &&
                                    !errors.email
                                  ) {
                                    await _sendPasswordResetEmail(values.email);
                                    toast(
                                      `Password reset email sent to ${values.email}`,
                                      {
                                        type: 'success',
                                      }
                                    );
                                  } else {
                                    setResetPasswordEmail(values.email);
                                    setShowResetPasswordEnterEmailModal(true);
                                  }
                                } catch (error) {
                                  handleError({
                                    error,
                                    internal:
                                      'Login.js - send password reset email from modal',
                                    toast:
                                      'There was an error trying to send your reset password email',
                                  });
                                }
                              }}
                            >
                              Forgot Password?
                            </CLink>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol xs="12">
                            <CButton
                              color="primary"
                              type="submit"
                              className="px-4 btn-block"
                              disabled={!!errors.password || !!errors.email}
                            >
                              Login
                            </CButton>
                          </CCol>
                        </CRow>{' '}
                        <CRow className="mt-2">
                          <CCol xs="12" style={{ textAlign: 'center' }}>
                            Don't have an account yet?
                            <CLink
                              to={TABS.SIGNUP_CREATE_ACCOUNT.path}
                              label="Forgot Password?"
                              className="ml-2"
                            >
                              Sign Up
                            </CLink>
                          </CCol>
                        </CRow>
                      </Form>
                    )}
                  </Formik>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
      <CModal show={showResetPasswordEnterEmailModal}>
        <CModalHeader>
          <h3>Enter account email</h3>
        </CModalHeader>
        <CModalBody>
          <BootstrapForm>
            <BootstrapForm.Group controlId="emailInput">
              <BootstrapForm.Label>Email address</BootstrapForm.Label>
              <BootstrapForm.Control
                type="email"
                placeholder="Enter email"
                value={resetPasswordEmail}
                onChange={(e) => setResetPasswordEmail(e.target.value)}
              />
            </BootstrapForm.Group>
          </BootstrapForm>
        </CModalBody>
        <CModalFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CButton
            size="md"
            color="danger"
            type="button"
            variant="outline"
            onClick={() => {
              setShowResetPasswordEnterEmailModal(false);
            }}
          >
            Cancel
          </CButton>
          <CButton
            size="md"
            color="primary"
            type="button"
            onClick={async () => {
              try {
                await _sendPasswordResetEmail(resetPasswordEmail);
                toast(`Password reset email sent to ${resetPasswordEmail}`, {
                  type: 'success',
                });
                setShowResetPasswordEnterEmailModal(false);
              } catch (error) {
                handleError({
                  error,
                  internal: 'Login.js - send password reset from modal',
                  toast:
                    'There was an error trying to send your reset password email',
                });
              }
            }}
          >
            Send Password Reset Email
          </CButton>
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default Login;
