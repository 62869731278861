import {
  CButton,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react';
import { addDoc, collection } from 'firebase/firestore';
import { Form, Formik } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { db } from '../../../../../index';
import { handleError } from '../../../../../helpers/errors';

const initialValues = {
  name: '',
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
});

function AddQuestionTag({ showModal, handleSubmit, closeModal }) {
  const user = useSelector((state) => state.UserState);

  const onSubmit = async (data, action) => {
    try {
      const organizationId = user.organizationId || 'a';

      const tag = await addDoc(collection(db, 'tags'), {
        name: data.name,
        organizationId,
      });

      action.resetForm();
      handleSubmit({
        value: tag.id,
        label: data.name,
      });
    } catch (e) {
      handleError({
        error: e,
        internal: 'AddQuestionTag.js - onSubmit',
      });
    }
  };

  return (
    <CModal show={showModal} onClose={closeModal}>
      <CModalHeader closeButton>
        <CModalTitle>Create tag</CModalTitle>
      </CModalHeader>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <CModalBody>
              <CFormGroup>
                <CLabel htmlFor="name">Name</CLabel>
                <CInput
                  id="name"
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </CFormGroup>
            </CModalBody>

            <CModalFooter>
              <CButton
                color="danger"
                variant="outline"
                className="mr-2"
                onClick={closeModal}
              >
                Cancel
              </CButton>

              <CButton
                color="primary"
                type="submit"
                disabled={!values.name || !!errors.name}
              >
                Add tag
              </CButton>
            </CModalFooter>
          </Form>
        )}
      </Formik>
    </CModal>
  );
}

export default AddQuestionTag;
