import produce from 'immer';

import { CLEAR_EXAMS, SET_CURRENT_EXAMS } from './constants';

const INITIAL_STATE = {
  data: [],
  inviteMap: {},
  invites: [],
  taken: [],
  takenMap: {},
};

export const examsReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_CURRENT_EXAMS:
        return {
          ...state,
          ...action.payload,
        };

      case CLEAR_EXAMS:
        return {
          ...INITIAL_STATE,
        };

      default:
        return state;
    }
  });
