import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CLink,
  CRow,
  CSpinner,
  CTooltip,
} from '@coreui/react';
import { format } from 'date-fns';
import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { FiAlertCircle } from 'react-icons/fi';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
  addGroups,
  editGroup,
  viewGroupDetails,
} from '../../../../actions/groupActionCreator';
import { db } from '../../../../index';
import GroupWrapper from '../../../../pages/component/GroupWrapper';
import { TABS } from '../../../../AppTabs';
import OrgTeamImageHeader from '../../../../r-components/OrgTeamImageHeader/OrgTeamImageHeader';
import { orgAndTeamData } from '../../../../helpers/data';
import _ from 'lodash';
import { ACCESS_ACTIONS, checkAccess } from '../../../../helpers/access';
import useAccountSelectors from '../../../../hooks/useAccountSelectors';

const GroupsListPage = () => {
  // id here is the team id
  const { id } = useParams();
  const dispatch = useDispatch();
  const isMounted = useRef(false);
  // const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.UserState);
  const groups = useSelector((state) => state.DataGroupState?.data || []);
  const queryPage = useLocation().search.match(/page=([0-9]+)/, '');
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);
  const [tableFields, setTableFields] = useState([]);
  const orgData = useSelector((state) => state.DataOrganizationState);
  const teamData = useSelector((state) => state.DataTeamState);
  const [orgTeamData, setOrgTeamData] = useState({});
  const { type, tier, showSubscriptionModal, goToUrl, teamCounts } =
    useAccountSelectors();

  useEffect(() => {
    currentPage !== page && setPage(currentPage);
  }, [currentPage, page]);

  useEffect(() => {
    let orgTeam = orgAndTeamData({ orgData, teamData });
    setOrgTeamData(orgTeam);
    let tableFields = [
      {
        key: 'title',
        label: 'Name',
        _classes: 'font-weight-bold',
      },
    ];
    if (orgTeam.hasTeam !== true) {
      tableFields.push({ key: 'team', label: 'Team' });
    }
    tableFields = tableFields.concat([
      { key: 'players', label: 'Players' },
      { key: 'created_at', label: 'Created' },
      { key: 'show_details', label: '' },
    ]);
    // if (orgTeam.hasTeam !== true) {
    //   tableFields.push({ key: 'team', label: 'Team(s)' });
    // }

    setTableFields(tableFields);
  }, [orgData, teamData]);

  return (
    <GroupWrapper>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader className="d-flex justify-content-between">
              <OrgTeamImageHeader
                title="Groups"
                rightView={
                  <>
                    {_.isString(teamData?.teamData?._id) &&
                    teamData.teamData._id.length > 0 ? (
                      <CButton
                        id="groups-add-group-button"
                        color="primary"
                        className="d-flex justify-content-center align-items-center"
                        onClick={() => {
                          checkAccess({
                            action: [ACCESS_ACTIONS.CREATE_GROUP],
                            type,
                            tier,
                            thresholdCount:
                              (teamCounts?.[teamData.teamData._id]
                                ?.countGroups || 0) + 1,
                            threshold:
                              teamCounts?.[teamData.teamData._id]?.maxGroups,
                            showSubscriptionModal,
                            goToUrl,
                          }).navigateWithAccess(TABS.COACH_GROUPS_CREATE.path);
                        }}
                      >
                        <span className="m-0 mr-2 h3">+</span> Create new group
                      </CButton>
                    ) : (
                      <CTooltip
                        content="Groups need to belong to a team. Select a team from the dropdown under your name first."
                        placement="bottom"
                      >
                        <CButton
                          id="groups-add-group-button"
                          color="primary"
                          className="d-flex justify-content-center align-items-center"
                        >
                          <span className="m-0 mr-2 h3">+</span> Create new
                          group
                        </CButton>
                      </CTooltip>
                    )}
                  </>
                }
              />
            </CCardHeader>

            <CCardBody>
              {!loading ? (
                <CDataTable
                  items={groups}
                  fields={tableFields}
                  noItemsViewSlot={
                    <p className="d-flex align-content-center justify-content-center pt-4 pb-4">
                      <FiAlertCircle size="30" color="#e55353" />
                      <span className="pl-2 font-xl">No groups added.</span>
                    </p>
                  }
                  scopedSlots={{
                    title: (item) => (
                      <td className="font-weight-bold">
                        <Link
                          to={TABS.COACH_GROUPS_DETAILS.pathVar({
                            name: item.title,
                            id: item.id,
                          })}
                        >
                          {item.title}
                        </Link>
                      </td>
                    ),
                    players: (item) => (
                      <td>
                        {`${[...(item.players || [])].length || '0'} player${
                          [...(item.players || [])].length === 1 ? '' : 's'
                        }`}
                      </td>
                    ),
                    team: (item) => (
                      <td>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 6,
                          }}
                        >
                          {_.isObject(teamData) &&
                          _.isObject(teamData.map) &&
                          _.isObject(teamData.map[item.teamId]) &&
                          _.isString(teamData.map[item.teamId].imageUrl) &&
                          teamData.map[item.teamId].imageUrl.length > 0 ? (
                            <Link
                              to={TABS.COACH_TEAM.pathVar({
                                id: item.teamId,
                                name: teamData?.map[item.teamId].name,
                              })}
                            >
                              <img
                                src={teamData.map[item.teamId].imageUrl}
                                style={{
                                  height: 20,
                                  width: 20,
                                  objectFit: 'contain',
                                  marginRight: 6,
                                  marginTop: -2,
                                }}
                              />
                              {_.isObject(teamData.map) &&
                              _.isObject(teamData.map[item.teamId]) &&
                              _.isString(teamData?.map[item.teamId].name)
                                ? teamData.map[item.teamId].name
                                : ''}
                            </Link>
                          ) : (
                            <Link
                              to={TABS.COACH_TEAM.pathVar({
                                id,
                                name:
                                  _.isObject(teamData) &&
                                  _.isObject(teamData.map) &&
                                  _.isObject(teamData.map[item.teamId]) &&
                                  _.isString(teamData?.map[item.teamId].name)
                                    ? teamData?.map[item.teamId].name
                                    : '',
                              })}
                            >
                              {_.isObject(teamData) &&
                              _.isObject(teamData.map) &&
                              _.isObject(teamData.map[item.teamId]) &&
                              _.isString(teamData?.map[item.teamId].name)
                                ? teamData.map[item.teamId].name
                                : ''}
                            </Link>
                          )}
                        </div>
                      </td>
                    ),
                    created_at: (item) => (
                      <td>
                        {item?.created_at
                          ? format(
                              new Date(
                                item.created_at?.seconds * 1000 ||
                                  item.created_at
                              ),
                              'MMM dd'
                            )
                          : null}
                      </td>
                    ),
                    show_details: (item) => (
                      <td className="text-right">
                        <Link
                          to={TABS.COACH_GROUPS_EDIT.pathVar({
                            name: item.title,
                            id: item.id,
                          })}
                        >
                          <FaEdit />
                        </Link>
                      </td>
                    ),
                  }}
                />
              ) : (
                <CCol xl={12} className="text-center pt-2 pb-2">
                  <CSpinner variant="grow" size="lg" />
                </CCol>
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </GroupWrapper>
  );
};

export default GroupsListPage;
