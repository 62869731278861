import { CTooltip } from '@coreui/react';
import './team.scss';
import React, { useEffect, useRef, useState } from 'react';
import { FaBaseballBall } from 'react-icons/fa';
import _ from 'lodash';

const TeamViewRunsSaved = () => {
  const [runsSaved, setRunsSaved] = useState(1);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setRunsSaved(
        runsSaved > 1000000
          ? runsSaved + 10000
          : runsSaved > 1000
          ? runsSaved + 100
          : runsSaved + 1
      );
    }, 30);
    return () => clearTimeout(timeoutId);
  }, [runsSaved]);

  return (
    <CTooltip content="Roughly" placement="bottom">
      <div>
        <FaBaseballBall size={40} />
        {runsSaved > 1000000
          ? `${(runsSaved / 1000000).toFixed(2)}M`
          : runsSaved > 1000
          ? `${(runsSaved / 1000).toFixed(1)}k`
          : runsSaved}{' '}
        runs saved
      </div>
    </CTooltip>
  );
};

export default TeamViewRunsSaved;
