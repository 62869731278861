import React from 'react';

import empoweringVideo from '../../assets/empowering-video.png';

import { Content, H1, RightContent, Wrapper } from './EmpoweringStyles';
import Container from '../Container/Container';

const Empowering = () => {
  return (
    <Container>
      <Wrapper>
        <Content>
          <H1>
            Empowering Coaches to <span>Develop</span> Top-notch baseball
            players.
          </H1>
          <p>
            For baseball coaches like you, every game brings measurable
            progress. However, game time isn't the time for trial & error
            learning. Instead, have a proactive approach, use 6-Tool the best
            baseball player development tool on the market.
          </p>

          <button>let’s get started</button>
        </Content>
        <RightContent>
          <img src={empoweringVideo} alt="emporing video" />
        </RightContent>
      </Wrapper>
    </Container>
  );
};

export default Empowering;
