import _, {
  isArray,
  isBoolean,
  isFunction,
  isNull,
  isObject,
  isString,
} from 'lodash';
import { SUBSCRIPTION_TIER } from '../config';
import { toast } from 'react-toastify';
export const ACCESS_LEVELS = {
  SUPER: { key: 'admin', label: 'Admin' },
  OWNER: { key: 'owner', label: 'Org. Admin' },
  COACH: { key: 'coach', label: 'Head Coach' },
  EVENT_PARTNER: { key: 'eventPartner', label: 'Event Partner' },
  ASSISTANT: { key: 'assistant', label: 'Assistant Coach' },
  PLAYER: { key: 'player', label: 'Player' },
};

export const ACCESS_ACTIONS = {
  CREATE_AUTO_QUIZ: 'CREATE_AUTO_QUIZ',
  CREATE_COACH: 'CREATE_COACH',
  CREATE_EVENT: 'CREATE_EVENT',
  CREATE_GROUP: 'CREATE_GROUP',
  CREATE_PLAYER: 'CREATE_PLAYER',
  CREATE_QUESTIONS: 'CREATE_QUESTIONS',
  CREATE_QUIZZES: 'CREATE_QUIZZES',
  CREATE_TEAM: 'CREATE_TEAM',
  DELETE_TEAM: 'DELETE_TEAM',
  DELETE_QUIZ: 'DELETE_QUIZ',
  EDIT_ORGANIZATION: 'EDIT_ORGANIZATION',
  EDIT_TEAM: 'EDIT_TEAM',
  EDIT_QUIZ: 'EDIT_QUIZ',
  NUM_QUESTIONS_PER_AUTO_QUIZ: 'NUM_QUESTIONS_PER_AUTO_QUIZ',
  NUM_QUESTIONS_PER_QUIZ: 'NUM_QUESTIONS_PER_QUIZ',
  QUIZ_RESULTS: 'QUIZ_RESULTS',
  SCHEDULE_QUIZZES: 'SCHEDULE_QUIZZES',
  VIEW_6TOOL_QUESTIONS: 'VIEW_6TOOL_QUESTIONS',
  VIEW_AUTO_QUIZ_QUESTIONS: 'VIEW_AUTO_QUIZ_QUESTIONS',
  VIEW_COACHES: 'VIEW_COACHES',
  VIEW_EVENT_EVENTS_TAB: 'VIEW_EVENT_EVENTS_TAB',
  VIEW_EVENT_PARTICIPANTS_TAB: 'VIEW_EVENT_PARTICIPANTS_TAB',
  VIEW_EVENT_TEAMS_TAB: 'VIEW_EVENT_TEAMS_TAB',
  VIEW_GROUPS: 'VIEW_GROUPS',
  VIEW_ORGANIZATION: 'VIEW_ORGANIZATION',
  VIEW_PLAYERS: 'VIEW_PLAYERS',
  VIEW_QUIZZES: 'VIEW_QUIZZES',
  VIEW_SUBSCRIPTION: 'VIEW_SUBSCRIPTION',
  VIEW_TEAM: 'VIEW_TEAM',
};

export const ACCESS_DENIED_REASONS = {
  NO_TYPE: 'noType', // your user type is not allowed
  NO_ABILITY: 'noAbility', // you can only do 0 of these (ie. not allowed)
  NO_MORE: 'noMore', // you have hit your threshold limit
  OVERRIDE: 'noOverride', // access was overriden
};

export const ACCESS_GRANTED_REASONS = {
  UNLIMITED: 'unlimited', // you can do this without limits
  ABILITY: 'ability', // you can only do 0 of these (ie. not allowed)
  MORE: 'more', // you have not yet hit your threshold limit, so you can do more
  OVERRIDE: 'override', // access was overriden
};

export const ACCESS_ACL = {
  [SUBSCRIPTION_TIER.FREE.key]: {
    [ACCESS_ACTIONS.CREATE_AUTO_QUIZ]: {
      threshold: 2,
      thresholdTimeInterval: 'month',
      thresholdTimePeriods: 1,
      description: 'Quiz Creation - 2/month',
      deniedReason:
        'You have reached your quiz creation limit for the month. To create a quiz, upgrade your subscription.',
      pricingTableOrder: 1,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.CREATE_COACH]: {
      threshold: 1,
      description: 'Coaches per Team - 1',
      deniedReason:
        'You have reached the maximum number of coaches on your team. To add another coach, upgrade your subscription.',
      pricingTableOrder: 3,
      roles: [ACCESS_LEVELS.SUPER.key, ACCESS_LEVELS.OWNER.key],
    },
    [ACCESS_ACTIONS.CREATE_EVENT]: {
      threshold: -1,
      roles: [ACCESS_LEVELS.EVENT_PARTNER.key],
      hideForNoAccess: true,
    },
    [ACCESS_ACTIONS.CREATE_GROUP]: {
      threshold: 0,
      deniedReason:
        'You cannot create groups. To do so, upgrade your subscription.',
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.CREATE_PLAYER]: {
      threshold: 10,
      description: 'Players per Team - 10',
      deniedReason:
        'You have reached the maximum number of players on your team. To add another player, upgrade your subscription.',
      pricingTableOrder: 2,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.CREATE_QUESTIONS]: {
      threshold: 0, //not allowed
      deniedReason:
        'You cannot create custom questions. To do so, upgrade your subscription.',
    },
    [ACCESS_ACTIONS.CREATE_QUIZZES]: {
      threshold: 0,
      deniedReason:
        'You cannot create custom quizzes. To do so, upgrade your subscription.',
    },
    [ACCESS_ACTIONS.CREATE_TEAM]: {
      threshold: 0,
      deniedReason:
        'You cannot create another team. To do so, upgrade your subscription.',
    },
    [ACCESS_ACTIONS.DELETE_TEAM]: {
      threshold: 0,
      hideForNoAccess: true,
    },
    [ACCESS_ACTIONS.DELETE_QUIZ]: {
      threshold: 0,
      hideForNoAccess: true,
    },
    [ACCESS_ACTIONS.EDIT_ORGANIZATION]: {
      threshold: -1,
      roles: [ACCESS_LEVELS.SUPER.key, ACCESS_LEVELS.OWNER.key],
      hideForNoAccess: true,
    },
    [ACCESS_ACTIONS.EDIT_QUIZ]: {
      threshold: 0,
      hideForNoAccess: true,
    },
    [ACCESS_ACTIONS.EDIT_TEAM]: {
      threshold: -1,
      roles: [ACCESS_LEVELS.SUPER.key, ACCESS_LEVELS.OWNER.key],
      hideForNoAccess: true,
    },
    [ACCESS_ACTIONS.NUM_QUESTIONS_PER_AUTO_QUIZ]: {
      threshold: 3,
      description: 'Questions per Auto Quiz - 3',
      deniedReason:
        'To make auto quizzes longer than 3 questions, upgrade your subscription.',
      pricingTableOrder: 4,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.NUM_QUESTIONS_PER_QUIZ]: {
      threshold: 3,
      description: 'Questions per Quiz - 3',
      deniedReason:
        'To make quizzes longer than 3 questions, upgrade your subscription.',
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.QUIZ_RESULTS]: {
      threshold: 0,
      deniedReason:
        "You are not able to view the results of your players' quizzes. To do so, upgrade your subscription.",
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.SCHEDULE_QUIZZES]: {
      threshold: 2,
      deniedReason:
        'You cannot schedule another quiz. To do so, upgrade your subscription.',
      description: 'Schedule Quizzes - 2/month',
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
      pricingTableOrder: 5,
    },
    [ACCESS_ACTIONS.VIEW_6TOOL_QUESTIONS]: {
      threshold: 0, //not allowed
      deniedReason:
        'You cannot access the 6Tool library of questions. To do so, upgrade your subscription.',
    },
    [ACCESS_ACTIONS.VIEW_AUTO_QUIZ_QUESTIONS]: {
      threshold: 0,
      deniedReason:
        'You cannot view questions from auto quizzes. To do so, upgrade your subscription.',
    },
    [ACCESS_ACTIONS.VIEW_COACHES]: {
      threshold: -1,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.VIEW_EVENT_EVENTS_TAB]: {
      threshold: -1,
      roles: [ACCESS_LEVELS.EVENT_PARTNER.key],
      hideForNoAccess: true,
    },
    [ACCESS_ACTIONS.VIEW_EVENT_PARTICIPANTS_TAB]: {
      threshold: -1,
      roles: [ACCESS_LEVELS.EVENT_PARTNER.key],
      hideForNoAccess: true,
    },
    [ACCESS_ACTIONS.VIEW_EVENT_TEAMS_TAB]: {
      threshold: -1,
      roles: [ACCESS_LEVELS.EVENT_PARTNER.key],
      hideForNoAccess: true,
    },
    [ACCESS_ACTIONS.VIEW_GROUPS]: {
      threshold: -1,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.VIEW_ORGANIZATION]: {
      threshold: -1,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
      ],
    },
    [ACCESS_ACTIONS.VIEW_PLAYERS]: {
      threshold: -1,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.VIEW_QUIZZES]: {
      threshold: -1,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.VIEW_SUBSCRIPTION]: {
      threshold: -1,
      roles: [ACCESS_LEVELS.SUPER.key, ACCESS_LEVELS.OWNER.key],
    },
    [ACCESS_ACTIONS.VIEW_TEAM]: {
      threshold: -1,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
  },
  /*************************************************** REGULAR *********************************************************/
  [SUBSCRIPTION_TIER.REGULAR.key]: {
    [ACCESS_ACTIONS.CREATE_AUTO_QUIZ]: {
      threshold: 25,
      thresholdTimeInterval: 'month',
      thresholdTimePeriods: 1,
      description: 'Quiz Creation - 25/month',
      deniedReason:
        'You have reached your quiz creation limit for the month. To create a quiz, upgrade your subscription.',
      pricingTableOrder: 1,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.CREATE_COACH]: {
      threshold: 5,
      description: 'Coaches per Team - 5',
      pricingTableOrder: 3,
      roles: [ACCESS_LEVELS.SUPER.key, ACCESS_LEVELS.OWNER.key],
      hideForNoAccess: true,
    },
    [ACCESS_ACTIONS.CREATE_EVENT]: {
      threshold: -1,
      roles: [ACCESS_LEVELS.EVENT_PARTNER.key],
      hideForNoAccess: true,
    },
    [ACCESS_ACTIONS.CREATE_GROUP]: {
      threshold: -1,
      description: 'Group Players by Position',
      pricingTableOrder: 6,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.CREATE_PLAYER]: {
      threshold: 50,
      description: 'Players per Team - 50',
      pricingTableOrder: 2,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.CREATE_QUESTIONS]: {
      threshold: 0, //not allowed
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.CREATE_QUIZZES]: {
      threshold: 25,
      deniedReason:
        'You have reached the maximum number of quizzes created this month. To create more, upgrade your subscription.',
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.CREATE_TEAM]: {
      threshold: -1,
      deniedReason:
        'You cannot create another team. To do so, upgrade your subscription.',
      roles: [ACCESS_LEVELS.SUPER.key, ACCESS_LEVELS.OWNER.key],
    },
    [ACCESS_ACTIONS.DELETE_QUIZ]: {
      threshold: -1,
      roles: [ACCESS_LEVELS.SUPER.key, ACCESS_LEVELS.OWNER.key],
    },
    [ACCESS_ACTIONS.EDIT_ORGANIZATION]: {
      threshold: -1,
      roles: [ACCESS_LEVELS.SUPER.key, ACCESS_LEVELS.OWNER.key],
      hideForNoAccess: true,
    },
    [ACCESS_ACTIONS.EDIT_QUIZ]: {
      threshold: -1,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.EDIT_TEAM]: {
      threshold: -1,
      roles: [ACCESS_LEVELS.SUPER.key, ACCESS_LEVELS.OWNER.key],
      hideForNoAccess: true,
    },
    [ACCESS_ACTIONS.NUM_QUESTIONS_PER_AUTO_QUIZ]: {
      threshold: 9,
      description: 'Questions per Auto Quiz - 9',
      deniedReason:
        'To make auto quizzes longer than 9 questions, upgrade your subscription.',
      pricingTableOrder: 4,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.NUM_QUESTIONS_PER_QUIZ]: {
      threshold: 15,
      description: 'Custom Quizzes - 15 Questions',
      deniedReason:
        'To make quizzes longer than 15 questions, upgrade your subscription.',
      pricingTableOrder: 9,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.QUIZ_RESULTS]: {
      threshold: -1,
      description: 'View Quiz Results',
      pricingTableOrder: 8,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.SCHEDULE_QUIZZES]: {
      threshold: -1,
      description: 'Schedule Quizzes',
      pricingTableOrder: 5,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.VIEW_6TOOL_QUESTIONS]: {
      threshold: -1, // allowed
      description: 'Library of 6Tool Questions',
      pricingTableOrder: 7,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.VIEW_AUTO_QUIZ_QUESTIONS]: {
      threshold: -1,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.VIEW_COACHES]: {
      threshold: -1,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.VIEW_EVENT_EVENTS_TAB]: {
      threshold: -1,
      roles: [ACCESS_LEVELS.EVENT_PARTNER.key],
      hideForNoAccess: true,
    },
    [ACCESS_ACTIONS.VIEW_EVENT_PARTICIPANTS_TAB]: {
      threshold: -1,
      roles: [ACCESS_LEVELS.EVENT_PARTNER.key],
      hideForNoAccess: true,
    },
    [ACCESS_ACTIONS.VIEW_EVENT_TEAMS_TAB]: {
      threshold: -1,
      roles: [ACCESS_LEVELS.EVENT_PARTNER.key],
      hideForNoAccess: true,
    },
    [ACCESS_ACTIONS.VIEW_GROUPS]: {
      threshold: -1,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.VIEW_ORGANIZATION]: {
      threshold: -1,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
      ],
    },
    [ACCESS_ACTIONS.VIEW_PLAYERS]: {
      threshold: -1,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.VIEW_QUIZZES]: {
      threshold: -1,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.VIEW_SUBSCRIPTION]: {
      threshold: -1,
      roles: [ACCESS_LEVELS.SUPER.key, ACCESS_LEVELS.OWNER.key],
    },
    [ACCESS_ACTIONS.VIEW_TEAM]: {
      threshold: -1,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
  },
  /*************************************************** PREMIUM *********************************************************/
  [SUBSCRIPTION_TIER.PREMIUM.key]: {
    [ACCESS_ACTIONS.CREATE_AUTO_QUIZ]: {
      threshold: -1, // unlimited
      thresholdTimeInterval: 'month',
      thresholdTimePeriods: 1,
      description: 'Quiz Creation - Unlimited',
      pricingTableOrder: 1,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.CREATE_COACH]: {
      threshold: 15,
      description: 'Coaches per Team - 15',
      pricingTableOrder: 3,
      roles: [ACCESS_LEVELS.SUPER.key, ACCESS_LEVELS.OWNER.key],
      hideForNoAccess: true,
    },
    [ACCESS_ACTIONS.CREATE_EVENT]: {
      threshold: -1,
      roles: [ACCESS_LEVELS.EVENT_PARTNER.key],
      hideForNoAccess: true,
    },
    [ACCESS_ACTIONS.CREATE_GROUP]: {
      threshold: -1,
      description: 'Group Players by Position',
      pricingTableOrder: 6,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.CREATE_PLAYER]: {
      threshold: 100,
      description: 'Players per Team - 100',
      pricingTableOrder: 2,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.CREATE_QUESTIONS]: {
      threshold: -1, //not allowed
      description: 'Create your Own Questions',
      pricingTableOrder: 10,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.CREATE_QUIZZES]: {
      threshold: -1,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.CREATE_TEAM]: {
      threshold: -1,
      roles: [ACCESS_LEVELS.SUPER.key, ACCESS_LEVELS.OWNER.key],
      hideForNoAccess: true,
    },
    [ACCESS_ACTIONS.DELETE_QUIZ]: {
      threshold: -1,
      roles: [ACCESS_LEVELS.SUPER.key, ACCESS_LEVELS.OWNER.key],
    },
    [ACCESS_ACTIONS.EDIT_ORGANIZATION]: {
      threshold: -1,
      roles: [ACCESS_LEVELS.SUPER.key, ACCESS_LEVELS.OWNER.key],
      hideForNoAccess: true,
    },
    [ACCESS_ACTIONS.EDIT_QUIZ]: {
      threshold: -1,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.EDIT_TEAM]: {
      threshold: -1,
      roles: [ACCESS_LEVELS.SUPER.key, ACCESS_LEVELS.OWNER.key],
      hideForNoAccess: true,
    },
    [ACCESS_ACTIONS.NUM_QUESTIONS_PER_AUTO_QUIZ]: {
      threshold: 9,
      description: 'Questions per Auto Quiz - 9',
      deniedReason:
        'To make auto quizzes longer than 9 questions, upgrade your subscription.',
      pricingTableOrder: 4,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.NUM_QUESTIONS_PER_QUIZ]: {
      threshold: 30,
      description: 'Custom Quizzes - 30 Questions',
      deniedReason: 'Max # of questions is 30.',
      pricingTableOrder: 9,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.QUIZ_RESULTS]: {
      threshold: -1,
      description: 'View Quiz Results',
      pricingTableOrder: 8,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.SCHEDULE_QUIZZES]: {
      threshold: -1,
      description: 'Schedule Quizzes',
      pricingTableOrder: 5,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.VIEW_6TOOL_QUESTIONS]: {
      threshold: -1, // allowed
      description: 'Library of 6Tool Questions',
      pricingTableOrder: 7,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.VIEW_AUTO_QUIZ_QUESTIONS]: {
      threshold: -1,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.VIEW_COACHES]: {
      threshold: -1,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.VIEW_EVENT_EVENTS_TAB]: {
      threshold: -1,
      roles: [ACCESS_LEVELS.EVENT_PARTNER.key],
      hideForNoAccess: true,
    },
    [ACCESS_ACTIONS.VIEW_EVENT_PARTICIPANTS_TAB]: {
      threshold: -1,
      roles: [ACCESS_LEVELS.EVENT_PARTNER.key],
      hideForNoAccess: true,
    },
    [ACCESS_ACTIONS.VIEW_EVENT_TEAMS_TAB]: {
      threshold: -1,
      roles: [ACCESS_LEVELS.EVENT_PARTNER.key],
      hideForNoAccess: true,
    },
    [ACCESS_ACTIONS.VIEW_GROUPS]: {
      threshold: -1,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.VIEW_ORGANIZATION]: {
      threshold: -1,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
      ],
      hideForNoAccess: true,
    },
    [ACCESS_ACTIONS.VIEW_PLAYERS]: {
      threshold: -1,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.VIEW_QUIZZES]: {
      threshold: -1,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
    [ACCESS_ACTIONS.VIEW_SUBSCRIPTION]: {
      threshold: -1,
      roles: [ACCESS_LEVELS.SUPER.key, ACCESS_LEVELS.OWNER.key],
      hideForNoAccess: true,
    },
    [ACCESS_ACTIONS.VIEW_TEAM]: {
      threshold: -1,
      roles: [
        ACCESS_LEVELS.SUPER.key,
        ACCESS_LEVELS.OWNER.key,
        ACCESS_LEVELS.COACH.key,
        ACCESS_LEVELS.ASSISTANT.key,
      ],
    },
  },
};
const extractPricingTableOrderAndDescription = (obj) => {
  return _.mapValues(obj, (tier) => {
    // Extract actions with pricingTableOrder and description
    let actions = _.pickBy(
      _.mapValues(tier, (action) =>
        action.pricingTableOrder !== undefined
          ? _.pick(action, ['pricingTableOrder', 'description'])
          : undefined
      ),
      _.identity
    );

    // Sort actions by pricingTableOrder
    actions = _.sortBy(actions, ['pricingTableOrder']);

    return actions;
  });
};

// export const filteredACL = extractPricingTableOrderAndDescription(ACCESS_ACL);
// console.log(filteredACL);

// checks to see if you can do a given action
// action         = the action you're performing
// type           = the user types (ie. ['coach', 'owner', 'player'])
// thresholdCount = the # of things you've done already (ie. already have 40 players)
// tier           = the subscription tier (ie. 'free', 'premium')
export const canDoAction = ({
  action,
  type = [ACCESS_LEVELS.COACH.key],
  thresholdCount,
  threshold,
  tier,
}) => {
  let _threshold = threshold || ACCESS_ACL[tier]?.[action]?.threshold;
  if (
    !isNaN(_threshold) &&
    _threshold !== -1 &&
    (_threshold === 0 || _threshold < thresholdCount)
  ) {
    let minUpgrade = null;
    const currentTiers = [
      SUBSCRIPTION_TIER.FREE.key,
      SUBSCRIPTION_TIER.REGULAR.key,
    ];
    currentTiers.some((c) => {
      if (tier === c) {
        const tiersToCheck = [
          SUBSCRIPTION_TIER.REGULAR.key,
          SUBSCRIPTION_TIER.PREMIUM.key,
        ].filter((t) => t !== c);
        tiersToCheck.some((t) => {
          if (
            _threshold === -1 ||
            !isNaN(thresholdCount) ||
            _threshold > thresholdCount
          ) {
            minUpgrade = t;
            return true;
          }
          return false;
        });
      }
    });
    return {
      canDo: false,
      reason:
        _threshold === 0
          ? ACCESS_DENIED_REASONS.NO_ABILITY
          : ACCESS_DENIED_REASONS.NO_MORE,
      minUpgrade,
      step: 1,
      threshold: _threshold,
      canView: ACCESS_ACL[tier]?.[action]?.hideForNoAccess !== true,
    };
  }
  if (
    isArray(ACCESS_ACL[tier]?.[action]?.roles) &&
    (_.intersection(ACCESS_ACL[tier][action].roles, type) || []).length === 0
  ) {
    return {
      canDo: false,
      reason: ACCESS_DENIED_REASONS.NO_TYPE,
      minUpgrade: null,
      threshold: _threshold,
      canView: ACCESS_ACL[tier]?.[action]?.hideForNoAccess !== true,
    };
  }
  if (ACCESS_ACL[tier]?.[action]?.threshold === -1) {
    return {
      canDo: true,
      reason: ACCESS_GRANTED_REASONS.UNLIMITED,
      minUpgrade: null,
      step: 2,
      threshold: _threshold,
      canView: true,
    };
  }
  if (!isNaN(thresholdCount)) {
    return {
      canDo: true,
      reason: ACCESS_GRANTED_REASONS.ABILITY,
      minUpgrade: null,
      step: 3,
      threshold: _threshold,
      canView: true,
    };
  }

  return {
    canDo: true,
    reason: ACCESS_GRANTED_REASONS.MORE,
    minUpgrade: null,
    step: 4,
    threshold: _threshold,
    canView: true,
  };
};

export const checkAccess = ({
  action: _action,
  type = [ACCESS_LEVELS.COACH.key],
  tier = SUBSCRIPTION_TIER.FREE.key,
  thresholdCount: _thresholdCount,
  threshold: _threshold,
  showSubscriptionModal,
  goToUrl,
}) => {
  let _actions = [];
  let _thresholds = [];
  let _thresholdCounts = [];
  if (isString(_action)) {
    _actions = [_action];
  } else if (isArray(_action)) {
    _actions = [..._action];
  }
  if (!isNaN(_threshold)) {
    _thresholds = [_threshold];
  } else if (isArray(_threshold)) {
    _thresholds = [..._threshold];
  }
  if (!isNaN(_thresholdCount)) {
    _thresholdCounts = [_thresholdCount];
  } else if (isArray(_thresholdCount)) {
    _thresholdCounts = [..._thresholdCount];
  }
  const handlers = {
    successCallback: null,
    successNavigateCallback: null,
    failureCallback: null,
    userTypeCallback: null,
    doWithAccess(callback) {
      this.successCallback = callback;
      if (this.success) {
        this.successCallback(this.value);
      }
      return this;
    },
    navigateWithAccess(toUrl) {
      this.successNavigateCallback = () => {
        if (isFunction(goToUrl)) {
          goToUrl(toUrl);
        }
      };
      if (this.success) {
        this.successNavigateCallback();
      }
      return this;
    },
    noAccessFallback(callback) {
      this.failureCallback = callback;
      if (!this.success) {
        this.failureCallback(this.error);
      }
      return this;
    },
    userTypeFallback(callback) {
      this.userTypeCallback = callback;
      if (isFunction(this.userTypeCallback)) {
        this.userTypeCallback(this.value);
      }
      return this;
    },
  };

  let success = true;
  let failedActions = [];
  let reasons = new Set();

  for (let actionIndex = 0; actionIndex < _actions.length; actionIndex++) {
    const action = _actions[actionIndex];
    let threshold = null;
    if (isArray(_thresholds) && _thresholds.length > actionIndex) {
      threshold = _thresholds[actionIndex];
    }
    let thresholdCount = 0;
    if (isArray(_thresholdCounts) && _thresholdCounts.length > actionIndex) {
      thresholdCount = _thresholdCounts[actionIndex];
    }
    const {
      canView,
      canDo: _success,
      reason: _reason,
      step,
    } = canDoAction({
      action,
      type,
      tier,
      thresholdCount,
      threshold,
    });
    reasons.add(_reason);
    if (_success === false) {
      success = false;
      if (isArray(failedActions)) {
        const existingActionIndex = failedActions.findIndex(
          (a) => a.action === action
        );
        if (
          existingActionIndex >= 0 &&
          existingActionIndex < failedActions.length
        ) {
          failedActions[existingActionIndex].thresholdCount = Math.max(
            failedActions[existingActionIndex].thresholdCount,
            thresholdCount
          );
        } else {
          failedActions.push({ action, threshold, thresholdCount });
        }
      }
    }
  }

  // if (action === ACCESS_ACTIONS.CREATE_COACH) {
  //   console.log({
  //     action,
  //     type,
  //     tier,
  //     thresholdCount,
  //     success,
  //     canView,
  //     reason,
  //     step,
  //   });
  // }
  if (success) {
    handlers.success = true;
    handlers.value = 'Has access';
    if (isFunction(handlers.successCallback)) {
      handlers.successCallback(handlers.value);
    }
    if (isFunction(handlers.successNavigateCallback)) {
      handlers.successNavigateCallback();
    }
  } else if (reasons.has(ACCESS_DENIED_REASONS.NO_TYPE)) {
    if (isFunction(handlers.userTypeCallback)) {
      handlers.userTypeCallback();
    } else {
      toast(
        `Your user type (${type}) does not have the ability to do this. Ask the coach who signed up for 6Tool to perform this task.`,
        { type: 'info' }
      );
    }
  } else {
    handlers.success = false;
    handlers.error = 'No access';
    if (isFunction(handlers.failureCallback)) {
      handlers.failureCallback([...failedActions]);
    } else if (isFunction(showSubscriptionModal)) {
      showSubscriptionModal([...failedActions]);
    }
  }
  return handlers;
};
