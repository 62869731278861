import React, { Component } from 'react';
import AppStoreBadges from './component/AppStoreBadges';

class PlayerInvite extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section id="why-6tool" className="why-6tool pb-2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 pt-2 pb-5">
              <div className="title">
                <h2>You were invited to take a quiz</h2>
              </div>
              <div className="reasons">
                <div className="reason-each">
                  <div className="icon">
                    <img src="img/icon-7.png" className="img-fluid" alt="" />
                  </div>
                  <div className="text">
                    <p style={{ margin: '1rem 0' }}>
                      Quizzes are only on our mobile app. Chose the AppStore or
                      Google Play icon below to download our app.
                    </p>
                  </div>
                </div>

                <div className="reason-each">
                  <div className="icon">
                    <img src="img/icon-8.png" className="img-fluid" alt="" />
                  </div>
                  <div className="text">
                    <p style={{ margin: '1rem 0' }}>
                      Install the app and sign in with the user account your
                      coach provided you.
                    </p>
                  </div>
                </div>

                <div className="reason-each">
                  <div className="icon">
                    <img src="img/icon-4.png" className="img-fluid" alt="" />
                  </div>
                  <div className="text">
                    <p style={{ margin: '1rem 0' }}>
                      Either re-click your link from your email, or you should
                      already see the quiz in your app dashboard. Go take the
                      quiz!
                    </p>
                  </div>
                </div>
              </div>
              <AppStoreBadges />
            </div>
            <div className="col-md-5 py-2">
              <div className="img-box">
                <img src="img/why-6tool.png" className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default PlayerInvite;
