import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCardHeader,
  CCol,
  CLink,
  CRow,
  CTooltip,
} from '@coreui/react';
// import './team.scss';
import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, withRouter } from 'react-router-dom';
import _ from 'lodash';
import { toast } from 'react-toastify';
// import { useUploadFile } from 'react-firebase-hooks/storage';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, Form as BootstrapForm } from 'react-bootstrap';
import { db, functions, storage } from '../../..';
// import { getStorage, storageRef } from 'firebase/storage';
import { useUploadFile } from 'react-firebase-hooks/storage';
import { fileTimestamp } from '../../../helpers/utils';
import { handleError } from '../../../helpers/errors';
import { TABS } from '../../../AppTabs';
import { storageRefForLogo } from '../../../helpers/storage';

// const storage = getStorage(firebaseApp);

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
});

const iValues = {
  name: '',
  image: null,
};

const OrganizationView = ({ teamData, teams, userData }) => {
  let dispatch = useDispatch();
  const [showingOrgDetails, setShowingOrgDetails] = useState(true);
  const [runsSaved, setRunsSaved] = useState(1);
  const [initialValues, setInitialValues] = useState(iValues);
  const [imageUrl, setImageUrl] = useState('');
  const organizationData = useSelector(
    (state) => state.DataOrganizationState || {}
  );
  const [uploadFile, uploading, snapshot, error] = useUploadFile();

  useEffect(() => {
    let values = {
      name: organizationData?.data?.title || '',
      image:
        _.isString(organizationData?.data?.imageUrl) &&
        organizationData?.data?.imageUrl.length > 0
          ? organizationData.data.imageUrl
          : null,
    };
    setInitialValues(values);
    setLogoPreview(values.image);
  }, [organizationData]);
  const [logoPreview, setLogoPreview] = useState('');

  const handleImageChange = async (event) => {
    let now = fileTimestamp();
    const file = event.target.files[0];
    let storageRef = storageRefForLogo({
      organization_id: organizationData.data._id,
      fileName: file.name,
    });
    const result = await uploadFile(storageRef, file, {
      contentType: file.type,
    });
    let downloadUrl = await storageRef.getDownloadURL();
    setImageUrl(downloadUrl);
    let _objectURL = URL.createObjectURL(file);
    setLogoPreview(_objectURL);
    try {
      (async () => {
        let width = 0;
        let height = 0;

        // Set up the FileReader onload event
        const element = new Image();

        // Set up the element onload event
        element.onload = () => {
          // Access the dimensions using the element's naturalWidth and naturalHeight properties
          width = element.naturalWidth;
          height = element.naturalHeight;

          // Do something with the dimensions
          const addMediaCallable = functions.httpsCallable('addMediaCallable');
          addMediaCallable({
            downloadUrl,
            organizationId: organizationData.data._id,
            width,
            height,
            usage: 'organization_logo',
          });
        };

        // Assign the FileReader result to the element's src
        element.src = _objectURL;
      })();
    } catch (err) {
      handleError({
        error: err,
        internal: 'TeamEdit.js - handleImageChange',
      });
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    if (uploading) {
      toast('File is still uploading, please try again', { type: 'info' });
      return false;
    }
    setSubmitting(false);
    try {
      let updates = { title: values.name };
      if (_.isString(imageUrl) && imageUrl.length > 0) {
        updates.imageUrl = imageUrl;
      }
      let response = await db
        .collection('organization')
        .doc(organizationData.data.id)
        .update(updates);
      toast('Organization updated successfully', { type: 'success' });
    } catch (error) {
      handleError({
        error,
        internal: 'OrganizationView.js - handleSubmit',
        toast: error.message,
      });
    }
  };

  return (
    <Container className="space-1 coach-team-view">
      {userData?.isOwner === true ? (
        <>
          <CRow className={`coach-team-view-org-section`}>
            <CCol className="coach-team-view-org-wrapper" xl={12}>
              <div className="coach-team-view-org-header">
                <h1 className="m-0">Edit Organization</h1>
                <CButton color="primary" to={TABS.COACH_TEAMS.path}>
                  Done
                </CButton>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({ isSubmitting, errors, touched }) => (
                <Form>
                  <BootstrapForm.Group>
                    <BootstrapForm.Label>Organization Name</BootstrapForm.Label>
                    <Field
                      name="name"
                      type="text"
                      as={BootstrapForm.Control}
                      isInvalid={!!errors.name && touched.name}
                    />
                    <ErrorMessage
                      name="name"
                      component={BootstrapForm.Control.Feedback}
                      type="invalid"
                    />
                  </BootstrapForm.Group>
                  <BootstrapForm.Group>
                    <BootstrapForm.Label>Image</BootstrapForm.Label>
                    <BootstrapForm.File
                      id="image"
                      name="image"
                      accept="image/*"
                      onChange={(event) => {
                        handleImageChange(event);
                        // event.target.files.length > 0 &&
                        //   event.currentTarget.nextSibling.classList.add(
                        //     'selected'
                        //   );
                        // event.currentTarget.nextSibling.textContent =
                        //   event.target.files[0].name;
                      }}
                      isInvalid={!!errors.image && touched.image}
                      feedback={errors.image}
                      style={{ display: 'block' }}
                    />
                    {uploading && <p>Uploading...</p>}
                    {logoPreview && (
                      <img
                        src={logoPreview}
                        alt="Logo Preview"
                        className="img-fluid mt-2"
                        style={{
                          width: '200px',
                          height: 'auto',
                          objectFit: 'contain',
                        }}
                      />
                    )}
                    <ErrorMessage
                      name="image"
                      component={BootstrapForm.Control.Feedback}
                      type="invalid"
                    />
                  </BootstrapForm.Group>
                  <Button type="submit" disabled={isSubmitting}>
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </CRow>
        </>
      ) : (
        <CRow className={`coach-team-view-org-section`}>
          You do not have access to this view
        </CRow>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.UserState,
    teamData: state.DataTeamState?.teamData || {},
    teams: state.DataTeamState?.data || [],
  };
};

export default connect(mapStateToProps)(withRouter(OrganizationView));
