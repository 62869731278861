import CIcon from '@coreui/icons-react';
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CLink,
  CRow,
  CTooltip,
} from '@coreui/react';
import { serverTimestamp } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  FaAngleLeft,
  FaCheckCircle,
  FaCheckSquare,
  FaEnvelope,
  FaInfoCircle,
  FaLock,
  FaRegSquare,
  FaSearch,
  FaTimes,
  FaTrashAlt,
  FaUser,
  FaUsers,
} from 'react-icons/fa';
import Spinner from '../../../src/pages/component/Spinner';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
// import { Button, FormGroup, FormLabel, Input, BootstrapForm.Control.Feedback } from "react-bootstrap";
import {
  Button,
  Form as BootstrapForm,
  FormGroup,
  FormLabel,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import { auth, functions } from '../..';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { TABS } from '../../AppTabs';
import { handleError } from '../../helpers/errors';
import SignupWrapper, { SETUP_STEPS } from './SignupWrapper';
import CoachRequestAccessToTeams from './CoachRequestAccessToTeams';
import LoadingMessage from '../../r-components/LoadingMessage/LoadingMessage';
import FlexSpacer from '../../r-components/FlexSpacer/FlexSpacer';
import { _signInWithEmailAndPassword } from '../../helpers/authHelper';
import { Link } from 'react-router-dom';
import { extractLastName } from '../../helpers/data';

const SignupSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  name: Yup.string().required('Name is required'),
  coachEmail: Yup.string().nullable().email('Invalid email').optional(),
  code: Yup.string()
    .nullable()
    .matches(/^[a-zA-Z0-9]{4}$/, 'Code must be four alphanumeric characters')
    .optional(),
  selectedTeams: Yup.array()
    .min(1, 'At least one team is required')
    .of(
      Yup.object().shape({
        id: Yup.string().required('One of your teams has an error'),
        organization_id: Yup.string().required(
          'One of your teams has an error (no-org-id)'
        ),
      })
    )
    .required('At least one team is required.'),
  acceptTerms: Yup.boolean().oneOf(
    [true],
    'You must read and accept the terms and conditions'
  ),
});

const SEARCH_TYPES = { BY_COACH_EMAIL: 0, BY_TEAM_CODE: 1 };

const SignupCreateCoach = () => {
  const history = useHistory();

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const createNewCoachUserCallable = functions.httpsCallable(
      'createNewCoachUserCallable'
    );
    try {
      try {
        let team_requests = _.map(values.selectedTeams, (obj) =>
          _.assign({}, obj, { created_at: new Date() })
        );
        const createNewCoachUserRequestResult =
          await createNewCoachUserCallable({
            email: values.email,
            name: values.name,
            coachLastName: '',
            password: values.password,
            team_requests,
            acceptedTerms: true,
          });
        const { user_id, url } = createNewCoachUserRequestResult.data;
        await _signInWithEmailAndPassword(values.email, values.password);
      } catch (e) {
        handleError({
          error: e,
          toast: e.message.replace('Firebase:', '').trim(),
          internal: `SignupCreateCoach.js:onSubmit`,
        });
      }
    } catch (err) {
      handleError({
        error: err,
        toast: err.message.replace('FirebaseError:', '').trim(),
        internal: `SignupCreateCoach.js:onSubmit::catch`,
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <SignupWrapper>
      <div className="signup-header mb-4">
        <CLink className="signup-back-button" onClick={history.goBack}>
          <FaAngleLeft />
          Back
        </CLink>
        <h2 className="text-center font-weight-bold">Create Account</h2>
      </div>
      <Formik
        initialValues={{
          name: '',
          email: '',
          password: '',
          confirmPassword: '',
          teams: 1,
          acceptTerms: false,
        }}
        validationSchema={SignupSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          values,
          isValid,
          validateField,
        }) => (
          <>
            <BootstrapForm onSubmit={handleSubmit}>
              <div className="signup-create-account-section">
                <div className="signup-create-account">
                  <FormGroup>
                    <FormLabel htmlFor="name">Name</FormLabel>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <FaUser />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Field
                        type="text"
                        name="name"
                        id="name"
                        autoComplete="name"
                        as={BootstrapForm.Control}
                        className={`form-control ${
                          touched.name && errors.name ? 'is-invalid' : ''
                        }`}
                      />
                      <ErrorMessage
                        component={BootstrapForm.Control.Feedback}
                        name="name"
                        className="invalid-feedback"
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <FaEnvelope />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Field
                        type="email"
                        name="email"
                        id="email"
                        autoComplete="email"
                        className={`form-control ${
                          touched.email && errors.email ? 'is-invalid' : ''
                        }`}
                      />
                      <ErrorMessage
                        component={BootstrapForm.Control.Feedback}
                        name="email"
                        className="invalid-feedback"
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <FormLabel htmlFor="password">Password</FormLabel>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <FaLock />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Field
                        type="password"
                        name="password"
                        id="password"
                        autoComplete="new-password"
                        className={`form-control ${
                          touched.password && errors.password
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <ErrorMessage
                        component={BootstrapForm.Control.Feedback}
                        name="password"
                        className="invalid-feedback"
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <FormLabel htmlFor="confirmPassword">
                      Confirm Password
                    </FormLabel>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <FaLock />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Field
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        autoComplete="new-password"
                        className={`form-control ${
                          touched.confirmPassword && errors.confirmPassword
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      <ErrorMessage
                        component={BootstrapForm.Control.Feedback}
                        name="confirmPassword"
                        className="invalid-feedback"
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup style={{ marginBottom: 0 }}>
                    <InputGroup>
                      {values.acceptTerms === true ? (
                        <>
                          <FaCheckSquare
                            size={28}
                            color="#f6d30d"
                            onClick={() => {
                              setFieldValue('acceptTerms', false, () => {
                                console.log('here');
                                validateField('acceptTerms');
                              });
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <FaRegSquare
                            size={28}
                            color="#e3e6f0"
                            onClick={() => {
                              setFieldValue('acceptTerms', true, () => {
                                console.log('here');
                                validateField('acceptTerms');
                              });
                            }}
                          />
                        </>
                      )}
                      <div style={{ marginLeft: 8 }}>
                        I have read and accept the{' '}
                        <Link
                          to={TABS.TERMS.path}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            fontWeight: 'boldest',
                            fontFamily: 'AvinerHeavy',
                          }}
                        >
                          Terms & Conditions
                        </Link>
                      </div>
                      <ErrorMessage
                        component={BootstrapForm.Control.Feedback}
                        name="acceptTerms"
                        className="invalid-feedback"
                      />
                    </InputGroup>
                  </FormGroup>
                </div>
                <div className="signup-create-account-separator"></div>
                <div className="signup-create-account-choose-team">
                  <h5>Find your team(s) by:</h5>
                  <CoachRequestAccessToTeams
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                </div>
              </div>
              {isSubmitting || 4 === 3 ? (
                <LoadingMessage
                  firstMessage="Creating account..."
                  style={{ height: 53 }}
                />
              ) : (
                <Button
                  type="submit"
                  color="primary"
                  className="btn-block"
                  disabled={!isValid}
                >
                  Sign up
                </Button>
              )}
            </BootstrapForm>
            <div
              className="text-center mb-6"
              style={{ lineHeight: '1.2rem', marginTop: 10 }}
            >
              Clicking Sign Up will create your account on 6Tool and will
              request access to the teams you have selected. You must be
              approved by a coach with an existing account on the team in order
              to be granted access to your team's 6Tool.
            </div>
          </>
        )}
      </Formik>
      <div className="text-center my-2">
        <span>
          Have an account?{' '}
          <a href={TABS.LOGIN.path} className="custom-color">
            Log In
          </a>
        </span>
      </div>
    </SignupWrapper>
  );
};

export default SignupCreateCoach;
