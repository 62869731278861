import {
  CButton,
  CCol,
  CFormGroup,
  CImg,
  CInput,
  CInputRadio,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTooltip,
} from '@coreui/react';
import { Field, FieldArray, Form, Formik } from 'formik';
import React, { useRef } from 'react';
import * as Yup from 'yup';
import { FiTrash2, FiCornerDownLeft, FiPlusSquare } from 'react-icons/fi';
import {
  defaultQuestionOptions as defaultOptions,
  questionOptionTemplates as optionTemplates,
} from '../../../../../helpers/data';
import { render } from '@testing-library/react';

const validationSchema = Yup.object().shape({
  options: Yup.array().of(
    Yup.object().shape({
      correct: Yup.bool(),
      index: Yup.number(),
      points: Yup.number(),
      text: Yup.string().required('Text of option is required'),
    })
  ),
});

const AddOption = ({
  optionModal,
  closeModal,
  handleSubmitOptions,
  options,
  setFieldValue,
  type,
}) => {
  const formikRef = useRef(null);

  const closeModalAndReset = () => {
    closeModal();
    setTimeout(() => {
      formikRef.current?.resetForm();
    }, 500);
  };

  const disableAddOptions = ({ options }) => {
    const oneOptionsHasMarkedCorrectWithPoints = options.filter(
      ({ correct, points }) => correct && +points > 0
    );
    const oneOptionsHasPoints = options.filter(({ points }) => +points > 0);
    const allOptionsHasText = options.every(({ text }) => text.length >= 4);

    return (
      options.length < 2 ||
      !allOptionsHasText ||
      oneOptionsHasMarkedCorrectWithPoints.length !== 1 ||
      oneOptionsHasPoints.length < 1
    );
  };

  const useOptionTemplate = ({ numOptions, correctOption, isTrueFalse }) => {
    if (
      !!formikRef.current &&
      !!formikRef.current.values &&
      numOptions < formikRef.current.values.options.length
    ) {
      formikRef.current.setValues({ options: [] });
    }
    let newOptions = [];
    // formikRef.current.values.slice(0,numOptions);
    for (let index = 0; index < numOptions; index++) {
      let optionText = '';
      if (
        !!formikRef.current &&
        !!formikRef.current.values &&
        index < formikRef.current.values.options.length
      ) {
        optionText = formikRef.current.values.options[index].text || '';
      }
      if (isTrueFalse) {
        if (index === 0) {
          optionText = 'True';
        } else if (index === 1) {
          optionText = 'False';
        } else {
          optionText = '';
        }
      } else {
        if (index === 0 && optionText === 'True') {
          optionText = '';
        } else if (index === 1 && optionText === 'False') {
          optionText = '';
        }
      }
      formikRef.current?.setFieldValue(`options[${index}].text`, optionText);
      formikRef.current?.setFieldValue(
        `options[${index}].points`,
        index === correctOption ? 10 : 0
      );
      formikRef.current?.setFieldValue(
        `options[${index}].correct`,
        index === correctOption
      );
      newOptions.push({
        correct: index === correctOption,
        points: index === correctOption ? 10 : 0,
        index,
        text: optionText,
      });
    }
  };

  const renderFieldArray = (options, setFieldValue) => (
    <FieldArray
      name="options"
      render={(arrayHelpers) => (
        <>
          {options && options.length > 0
            ? options.map((_, index) => (
                <CRow style={{ maxWidth: '100%' }} key={index}>
                  <CCol>
                    <Field name={`options[${index}].text`}>
                      {({ field }) => (
                        <CFormGroup>
                          <CLabel>
                            Text <span className="text-danger">*</span>
                          </CLabel>
                          <CInput
                            {...field}
                            required
                            onChange={(e) => {
                              console.log('input onchange');
                              console.log(e.target.value);
                            }}
                          />
                        </CFormGroup>
                      )}
                    </Field>
                  </CCol>

                  <CCol xs={2}>
                    <Field name={`options[${index}].points`}>
                      {({ field }) => (
                        <CFormGroup>
                          <CLabel>
                            Points <span className="text-danger">*</span>
                          </CLabel>
                          <CInput type="number" step="1" {...field} />
                        </CFormGroup>
                      )}
                    </Field>
                  </CCol>

                  <CCol xs={2}>
                    <Field type="radio">
                      {({ field }) => (
                        <CFormGroup>
                          <CLabel>Correct?</CLabel>
                          <CInputRadio
                            className="form-control"
                            {...field}
                            checked={options[index].correct}
                            onClick={() => {
                              options.forEach((_, optionIndex) => {
                                setFieldValue(
                                  `options[${optionIndex}].correct`,
                                  false
                                );
                              });
                              setFieldValue(`options[${index}].correct`, true);
                            }}
                            style={{
                              width: 20,
                              height: 20,
                              marginTop: 13,
                              marginLeft: 19,
                            }}
                          />
                        </CFormGroup>
                      )}
                    </Field>

                    <div>
                      <CButton
                        type="button"
                        style={{
                          position: 'relative',
                          left: 60,
                          top: -21,
                        }}
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        <FiTrash2 size={24} />
                      </CButton>
                      {false && (
                        <CTooltip content="Insert an option after this one">
                          <CButton
                            type="button"
                            onClick={() =>
                              arrayHelpers.insert(index + 1, {
                                correct: false,
                                index: index + 1,
                                points: 0,
                                text: '',
                              })
                            }
                          >
                            <FiCornerDownLeft />
                          </CButton>
                        </CTooltip>
                      )}
                    </div>
                  </CCol>
                </CRow>
              ))
            : null}
          <CButton
            type="button"
            role="button"
            onClick={() =>
              arrayHelpers.push({
                correct: false,
                index: 0,
                points: 0,
                text: '',
              })
            }
          >
            <FiPlusSquare /> Add option
          </CButton>
        </>
      )}
    />
  );

  const renderFormContents = (values, setFieldValue) => (
    <>
      <div
        style={{
          flexDirection: 'column',
          display: 'flex',
          alignItems: 'flex-end',
        }}
      >
        <CLabel>Choose from a template (optional):</CLabel>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {optionTemplates.map(
            (
              { imgUrl, numOptions, correctOption, isTrueFalse, newGroup },
              index
            ) => (
              <CButton
                key={`add-quiz-option-button-${index}`}
                style={{ padding: 0, marginLeft: newGroup ? 12 : 0 }}
                onClick={() => {
                  useOptionTemplate({
                    numOptions,
                    correctOption,
                    isTrueFalse,
                  });
                }}
              >
                <CImg
                  src={`/img/question-option-templates/${imgUrl}.png`}
                  fluid
                  style={{
                    height: 24,
                    objectFit: 'contain',
                    flex: 0,
                    marginRight: 4,
                  }}
                />
              </CButton>
            )
          )}
        </div>
      </div>
      <hr />
      {type === 'modal' ? (
        <CModalBody style={{ minHeight: 200 }}>
          {renderFieldArray(values.options, setFieldValue)}
        </CModalBody>
      ) : (
        <>{renderFieldArray(options, setFieldValue)}</>
      )}
    </>
  );

  const renderForm = () => (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      validationSchema={validationSchema}
      initialValues={{
        options: options.length > 0 ? options : defaultOptions,
      }}
      onSubmit={(data, action) => {
        handleSubmitOptions(data.options);
        closeModal();
        setTimeout(() => {
          action.resetForm();
        }, 500);
      }}
    >
      {({ values, errors, setFieldValue, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          {type === 'modal' && (
            <CModalHeader>
              <CModalTitle>Add options</CModalTitle>
            </CModalHeader>
          )}
          {renderFormContents(values, setFieldValue)}
          {type === 'modal' && (
            <CModalFooter>
              <CButton
                color="danger"
                className="mr-2"
                variant="outline"
                onClick={closeModalAndReset}
              >
                Cancel
              </CButton>
              <CButton
                color="primary"
                type="submit"
                disabled={disableAddOptions(values, errors)}
              >
                Save Question Options
              </CButton>
            </CModalFooter>
          )}
        </Form>
      )}
    </Formik>
  );

  return (
    <>
      {type === 'modal' ? (
        <CModal
          size="lg"
          show={optionModal}
          onClose={closeModalAndReset}
          scrollable={true}
        >
          {renderForm()}
        </CModal>
      ) : (
        <>{renderFormContents({}, setFieldValue)}</>
      )}
    </>
  );
};

export default AddOption;
