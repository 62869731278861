import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CLabel,
  CRow,
  CTooltip,
} from '@coreui/react';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import './playerUpdate.scss';
import { Form, Formik } from 'formik';
import { range } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import * as Yup from 'yup';
import { viewPlayers } from '../../../actions/playerActionCreator';
import { db, functions } from '../../../index';
import { POSITIONS } from '../../../r-reusable';
import 'yup-phone-lite';
import {
  desanitizePhoneNumber,
  extractLastName,
  sanitizePhoneNumber,
} from '../../../helpers/data';
import _ from 'lodash';
import PlayerWrapper from '../../../pages/component/PlayerWrapper';
import { TABS } from '../../../AppTabs';
import { useHistory } from 'react-router-dom';
import {
  FaAngleDown,
  FaAngleLeft,
  FaCheckCircle,
  FaCheckSquare,
  FaInfoCircle,
  FaRegSquare,
  FaTrashAlt,
} from 'react-icons/fa';
import HeaderWithBackButton from '../../../r-components/HeaderWithBackButton/HeaderWithBackButton';
import { handleError } from '../../../helpers/errors';
import LoadingMessage from '../../../r-components/LoadingMessage/LoadingMessage';
import { toast } from 'react-toastify';
import FlexSpacer from '../../../r-components/FlexSpacer/FlexSpacer';
import ConfirmModal from '../../../r-components/ConfirmModal/ConfirmModal';

const _initialValues = {
  email: '',
  displayName: '',
  phoneNumber: '',
  age: '',
  city: '',
  positions: [],
  groups: [],
  classYear: '',
  youtube: '',
  baseballReports: '',
  twitter: '',
  instagram: '',
  teams: {},
};

const validationSchema = Yup.object().shape({
  email: Yup.string().trim().email().required('Email is required'),
  displayName: Yup.string().trim().required('Name is required'),
  phoneNumber: Yup.string()
    .trim()
    .phone('US', 'Phone number is invalid')
    .optional(),
  age: Yup.object().optional(),
  city: Yup.string().optional(),
  positions: Yup.array().optional(),
  groups: Yup.array().optional(),
  classYear: Yup.object().optional(),
  youtube: Yup.string().optional(),
  baseballReports: Yup.string().optional(),
  twitter: Yup.string().optional(),
  instagram: Yup.string().optional(),
  teams: Yup.object().test(
    'notEmptyObject',
    'You must select at least one team',
    (value) => {
      return Object.keys(value).length !== 0;
    }
  ),
});

function PlayerUpdate({ match }) {
  const history = useHistory();
  const isMounted = useRef(false);
  const [user, setUser] = useState({});
  const [teamGroups, setTeamGroups] = useState({});
  const [groupMap, setGroupMap] = useState({});
  const formikRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(_initialValues);
  const [submitting, setSubmitting] = useState(false);
  const [showingAdvancedDetails, setShowingAdvancedDetails] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const [maxxedOutTeams, setMaxxedOutTeams] = useState([]);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showConfirmAgainDeleteModal, setShowConfirmAgainDeleteModal] =
    useState(false);
  const {
    UserState: currentUser,
    ThemeState: { darkMode },
    DataTeamState: teamData,
    DataOrganizationState: orgData,
    DataGroupState: groupData,
  } = useSelector((state) => state);
  const [userId, setUserId] = useState('a');
  useEffect(() => {
    setUserId(match.params.id || 'a');
  }, [match]);

  useEffect(() => {
    let _maxxedOutTeams = new Set();
    if (_.isArray(teamData?.data) && teamData.data.length > 0) {
      teamData.data.forEach((team) => {
        if (
          _.isString(team?._id) &&
          team._id.length > 0 &&
          team.maxxedPlayers === true &&
          !(Object.keys(initialValues?.teams || {}) || []).includes(team._id)
        ) {
          _maxxedOutTeams.add(team._id);
        }
      });
    }
    setMaxxedOutTeams(Array.from(_maxxedOutTeams));
  }, [orgData, teamData, initialValues]);

  useEffect(() => {
    if (_.isEmpty(initialValues.teams)) {
      setInitialValues({
        ...initialValues,
        ...user,
        ...{
          teams:
            _.isArray(teamData?.data) && teamData.data.length === 1
              ? { [teamData.data[0]._id]: { roles: ['player'] } }
              : {},
        },
      });
    }
  }, [user, teamData]);
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        // const groupsList = [];
        const groupsPromise = [];

        // const organizationId = currentUser?.organizationId || 'a';

        isMounted.current = true;
        const userRef = doc(db, 'users', userId);
        const userResponse = await getDoc(userRef);
        const userData = userResponse.data();

        if (!!userData) {
          if (isMounted.current) {
            const { name, type, phoneNumber, ...rest } = userData;
            const data = _.pickBy(
              {
                ...initialValues,
                ...rest,
                displayName: name,
                phoneNumber: desanitizePhoneNumber(phoneNumber),
                teams: _.pickBy(
                  (userData.org_team || {})[orgData.data.id] || {},
                  (value, key) => !key.startsWith('_')
                ),
              },
              (value, key) => !key.startsWith('_')
            );
            setLoading(false);
            setInitialValues(data);
            setUser(data);
          }
        }
      } catch (e) {
        handleError({
          error: e,
          internal: 'PlayerUpdate.js - loadData',
        });
        if (isMounted.current) {
          setLoading(false);
        }
      }
    };

    if (currentUser && !!userId && userId.length > 1) {
      loadData();
    }
    setIsCreate(!(!!userId && userId.length > 1));
    return () => {
      isMounted.current = false;
    };
  }, [currentUser, userId]);

  useEffect(() => {
    let gs = (groupData.data || []).map((g) => {
      return { id: g.id, name: g.title };
    });
    setGroupMap(_.chain(gs).keyBy('id').mapValues('name').value());
    setTeamGroups(
      _.groupBy(
        _.map(groupData.data || [], (g) =>
          _.pick(g, ['teamId', 'id', 'title', 'default'])
        ),
        'teamId'
      )
    );
  }, [groupData]);

  const onSubmit = async (
    { displayName, email, groups, phoneNumber, teams, ...rest },
    _
  ) => {
    if (isCreate) {
      return createPlayer({
        displayName,
        email,
        phoneNumber: sanitizePhoneNumber(phoneNumber),
        teams,
        ...rest,
      });
    } else {
      return updatePlayer({
        displayName,
        email,
        phoneNumber: sanitizePhoneNumber(phoneNumber),
        teams,
        ...rest,
      });
    }
  };

  const createPlayer = async ({
    displayName,
    email,
    groups,
    phoneNumber,
    teams,
    ...rest
  }) => {
    try {
      email = email.trim();
      setSubmitting(true);

      const createNewPlayerUserCallable = functions.httpsCallable(
        'createNewPlayerUserCallable'
      );
      let org_team = {};
      org_team[orgData.data.id] = { _roles: ['player'], ...teams };
      console.log({
        name: displayName,
        email,
        phoneNumber,
        org_team,
        orgName: orgData.data.title,
        ...rest,
      });
      const createNewPlayerUserResult = await createNewPlayerUserCallable({
        name: displayName,
        email,
        coachLastName: extractLastName(currentUser.data.name),
        phoneNumber,
        org_team,
        orgName: orgData.data.title,
        ...rest,
      });

      setSubmitting(false);
      toast(
        `Player ${
          isCreate === true ? 'created' : 'updated'
        }. Team/group membership for the player will be updated in the background. This may take a minute. You can continue to use the app normally.`,
        { type: 'success' }
      );
      history.push(TABS.COACH_PLAYERS.path);
    } catch (e) {
      handleError({
        error: e,
        internal: 'PlayerUpdate.js - createPlayer',
        toast: e.message.trim(),
      });
      setLoading(false);
    }
  };

  const closeAllModals = () => {
    setShowConfirmDeleteModal(false);
    setShowConfirmAgainDeleteModal(false);
  };

  const deletePlayerButton = () => (
    <>
      {!isCreate && _.isString(userId) && userId.length > 1 && (
        <CButton
          size="md"
          color="danger"
          variant="ghost"
          onClick={() => {
            setShowConfirmDeleteModal(true);
          }}
        >
          <FaTrashAlt style={{ marginRight: 6, marginTop: -2 }} size={20} />
          {`Delete Player`}
        </CButton>
      )}
    </>
  );

  const updatePlayer = async ({
    displayName,
    email,
    groups,
    age,
    classYear,
    phoneNumber,
    teams,
    ...rest
  }) => {
    try {
      email = email.trim();
      setSubmitting(true);
      let org_team = {};
      org_team[orgData.data.id] = teams;
      const usersRef = doc(db, 'users', userId);
      // const selectedGroupsIds = groups.map((group) => group.value);
      let update = {
        ...rest,
        email,
        name: displayName,
        org_team,
        age: (age || {}).value,
        classYear: (classYear || {}).value,
        // type: [type.value],
        // groups: selectedGroupsIds,
      };
      let updateUserData = { displayName, email, id: userId };
      phoneNumber = sanitizePhoneNumber(phoneNumber);
      if (!!phoneNumber) {
        update = { ...update, phoneNumber };
        updateUserData = { ...updateUserData, phoneNumber };
      }
      update = _.pickBy(update || {}, (value, key) => !key.startsWith('_'));
      update = _.pickBy(update, _.identity);
      await updateDoc(usersRef, update);
      const updateUser = functions.httpsCallable('updateUser');
      await updateUser(updateUserData);

      setSubmitting(false);
      history.push(TABS.COACH_PLAYERS.path);
    } catch (e) {
      handleError({
        error: e,
        internal: 'PlayerUpdate.js - updatePlayer',
      });
      setLoading(false);
    }
  };

  return (
    <PlayerWrapper>
      <ConfirmModal
        show={showConfirmDeleteModal && !showConfirmAgainDeleteModal}
        title="Confirm Delete"
        message="Are you sure you would like to delete this player from this organization? THIS CANNOT BE UNDONE. If you would like to remove the player from this team, but keep the player on another team, please use the Teams section below to manage the player instead of deleting the player."
        okButtonColor="danger"
        cancelButtonColor="primary"
        okButtonText="Delete"
        onClickCancel={closeAllModals}
        onClickConfirm={async () => {
          setShowConfirmDeleteModal(false);
          setShowConfirmAgainDeleteModal(true);
        }}
      />
      <ConfirmModal
        show={showConfirmAgainDeleteModal}
        title="Re-Confirm Delete"
        message="Are you sure you would like to delete this player? THIS IS YOUR LAST WARNING. The player will be deleted and this cannot be undone."
        okButtonColor="danger"
        cancelButtonColor="primary"
        okButtonText="Delete"
        onClickCancel={closeAllModals}
        onClickConfirm={async () => {
          setShowConfirmAgainDeleteModal(false);
          toast(
            'Player being deleted. It may take a minute for the player to be removed.',
            { type: 'success' }
          );
          history.push(TABS.COACH_PLAYERS.path);
          let deleteUserFromOrgCallable = functions.httpsCallable(
            'deleteUserFromOrgCallable'
          );
          let deleteResult = await deleteUserFromOrgCallable({
            userId,
            organizationId: orgData.data.id,
          });
        }}
      />
      <CRow>
        <CCol lg={12}>
          <CCard>
            <HeaderWithBackButton
              rightSide={isCreate ? null : deletePlayerButton()}
            >
              <div>{`${isCreate ? 'Create' : 'Edit'} Player`}</div>
            </HeaderWithBackButton>

            <CCardBody>
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div className="sk-bounce">
                    <div className="sk-bounce-dot"></div>
                    <div className="sk-bounce-dot"></div>
                  </div>
                </div>
              ) : (
                <Formik
                  enableReinitialize
                  onSubmit={onSubmit}
                  innerRef={formikRef}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    isValid,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <h2>Player Details</h2>
                      <CFormGroup row>
                        <CCol xs="2">
                          <CLabel htmlFor="title">
                            Name <span className="text-danger">*</span>
                          </CLabel>
                          {touched.displayName === true &&
                            !!errors.displayName && (
                              <CLabel className="player-update-error-label">
                                {errors.displayName}
                              </CLabel>
                            )}
                        </CCol>

                        <CCol xs="10">
                          <CInput
                            required
                            id="displayName"
                            value={values.displayName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{
                              border:
                                touched.displayName === true &&
                                !!errors.displayName
                                  ? '1px solid red'
                                  : '',
                            }}
                          />
                        </CCol>
                      </CFormGroup>

                      <CFormGroup row>
                        <CCol xs="2">
                          <CLabel htmlFor="title">
                            Email <span className="text-danger">*</span>
                          </CLabel>
                          {touched.email === true && !!errors.email && (
                            <CLabel className="player-update-error-label">
                              {errors.email}
                            </CLabel>
                          )}
                        </CCol>

                        <CCol xs="10">
                          <CInput
                            required
                            id="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{
                              border:
                                touched.email === true && !!errors.email
                                  ? '1px solid red'
                                  : '',
                            }}
                          />
                        </CCol>
                      </CFormGroup>

                      {/* <CFormGroup row>
                        <CCol xs="2">
                          <CLabel htmlFor="title">Phone Number</CLabel>
                          {touched.phoneNumber === true &&
                            !!errors.phoneNumber && (
                              <CLabel className="player-update-error-label">
                                {errors.phoneNumber}
                              </CLabel>
                            )}
                        </CCol>

                        <CCol xs="10">
                          <CInput
                            type="tel"
                            id="phoneNumber"
                            value={values.phoneNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{
                              border:
                                touched.phoneNumber === true &&
                                !!errors.phoneNumber
                                  ? '1px solid red'
                                  : '',
                            }}
                          />
                        </CCol>
                      </CFormGroup> */}
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: 20,
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          marginBottom: 20,
                        }}
                      >
                        <h2 style={{ margin: 0 }}>Advanced Details</h2>
                        <CButton
                          onClick={() => {
                            setShowingAdvancedDetails(!showingAdvancedDetails);
                          }}
                          size="sm"
                          variant="ghost"
                          color="primary"
                        >
                          {showingAdvancedDetails
                            ? 'Hide details'
                            : 'Expand details'}
                          {showingAdvancedDetails ? (
                            <FaAngleDown />
                          ) : (
                            <FaAngleLeft />
                          )}
                        </CButton>
                      </div>
                      {showingAdvancedDetails && (
                        <>
                          <CFormGroup row>
                            <CCol xs="2">
                              <CLabel htmlFor="city">City</CLabel>
                            </CCol>

                            <CCol xs="10">
                              <CInput
                                id="city"
                                value={values.city}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </CCol>
                          </CFormGroup>

                          <CFormGroup row>
                            <CCol xs="2">
                              <CLabel htmlFor="age">Age</CLabel>
                            </CCol>

                            <CCol xs="10">
                              <Select
                                id="age"
                                name="age"
                                placeholder="Please select your age"
                                value={values.age}
                                defaultValue={values.age}
                                options={range(18, 40).map((number) => ({
                                  value: number,
                                  label: number,
                                }))}
                                onChange={(option) =>
                                  setFieldValue('age', option)
                                }
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary: darkMode
                                      ? 'black'
                                      : theme.colors.primary,
                                    primary25: darkMode
                                      ? 'black'
                                      : theme.colors.primary25,
                                    dangerLight: darkMode
                                      ? 'black'
                                      : theme.colors.dangerLight,
                                    neutral0: darkMode
                                      ? '#2a2b36'
                                      : theme.colors.neutral0,
                                  },
                                })}
                              />
                            </CCol>
                          </CFormGroup>
                          <CFormGroup row>
                            <CCol xs="2">
                              <CLabel htmlFor="classYear">Class Year</CLabel>
                            </CCol>

                            <CCol xs="10">
                              <Select
                                id="classYear"
                                name="classYear"
                                placeholder="Please select class year"
                                value={values.classYear}
                                defaultValue={values.classYear}
                                options={range(18, 31).map((number) => ({
                                  value: `20${number}`,
                                  label: `'${number}`,
                                }))}
                                onChange={(option) =>
                                  setFieldValue('classYear', option)
                                }
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary: darkMode
                                      ? 'black'
                                      : theme.colors.primary,
                                    primary25: darkMode
                                      ? 'black'
                                      : theme.colors.primary25,
                                    dangerLight: darkMode
                                      ? 'black'
                                      : theme.colors.dangerLight,
                                    neutral0: darkMode
                                      ? '#2a2b36'
                                      : theme.colors.neutral0,
                                  },
                                })}
                              />
                            </CCol>
                          </CFormGroup>

                          <CFormGroup row>
                            <CCol xs="2">
                              <CLabel htmlFor="baseballReports">
                                Baseball reports
                              </CLabel>
                            </CCol>

                            <CCol xs="10">
                              <CInput
                                id="baseballReports"
                                placeholder="https://prepbaseballreports.com/your-link"
                                value={values.baseballReports}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </CCol>
                          </CFormGroup>

                          <CFormGroup row>
                            <CCol xs="2">
                              <CLabel htmlFor="youtube">Youtube</CLabel>
                            </CCol>

                            <CCol xs="10">
                              <CInput
                                id="youtube"
                                placeholder="https://youtube.com/your-link"
                                value={values.youtube}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </CCol>
                          </CFormGroup>

                          <CFormGroup row>
                            <CCol xs="2">
                              <CLabel htmlFor="twitter">Twitter</CLabel>
                            </CCol>

                            <CCol xs="10">
                              <CInputGroup>
                                <CInputGroupPrepend>
                                  <CInputGroupText>@</CInputGroupText>
                                </CInputGroupPrepend>
                                <CInput
                                  id="twitter"
                                  value={values.twitter}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </CInputGroup>
                            </CCol>
                          </CFormGroup>

                          <CFormGroup row>
                            <CCol xs="2">
                              <CLabel htmlFor="instagram">Instagram</CLabel>
                            </CCol>

                            <CCol xs="10">
                              <CInputGroup>
                                <CInputGroupPrepend>
                                  <CInputGroupText>@</CInputGroupText>
                                </CInputGroupPrepend>
                                <CInput
                                  id="instagram"
                                  value={values.instagram}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </CInputGroup>
                            </CCol>
                          </CFormGroup>
                        </>
                      )}
                      <div
                        className={`player-update-team-wrapper${
                          errors.teams ? ' error' : ''
                        }`}
                      >
                        <div className="player-update-team-header">
                          <h2>
                            Teams
                            <CTooltip content="Clicking the box for a team will add this player to the team. You must add the player to at least one team.">
                              <FaInfoCircle
                                style={{ marginLeft: 10 }}
                                size={20}
                              />
                            </CTooltip>
                          </h2>
                          {errors.teams ? (
                            <CCol xs="10">
                              <div className="team-error-message">
                                {errors.teams}
                              </div>
                            </CCol>
                          ) : (
                            <>
                              {(Object.keys(values.teams) || []).length > 0 && (
                                <>
                                  <FlexSpacer />
                                  <div
                                    style={{ fontSize: '1rem' }}
                                  >{`Assign player a postition(s) or group(s) for given team${
                                    (Object.keys(values.teams) || []).length ===
                                    1
                                      ? ''
                                      : 's'
                                  } (optional, can be done later)`}</div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                        <CFormGroup row style={{ padding: '0px 20px' }}>
                          <CCol xs="12">
                            <div className="player-team-list-wrapper">
                              {(teamData.data || []).map((team, index) => (
                                <div
                                  key={index}
                                  className={`player-team-list-item-wrapper ${
                                    maxxedOutTeams.includes(team?._id)
                                      ? 'maxxed'
                                      : ''
                                  } ${
                                    (Object.keys(values.teams) || []).includes(
                                      team._id
                                    )
                                      ? 'selected'
                                      : ''
                                  }`}
                                >
                                  <div
                                    key={`player-team-card-${index}-${team._id}`}
                                    className={`player-update-team-list-item`}
                                  >
                                    {(teamData.data || []).length > 1 && (
                                      <div className="player-update-team-checkbox">
                                        {(
                                          Object.keys(values.teams) || []
                                        ).includes(team._id) ? (
                                          <FaCheckSquare
                                            className="player-team-list-item-checkbox"
                                            style={
                                              (teamData.data || []).length > 1
                                                ? { cursor: 'pointer' }
                                                : {}
                                            }
                                            color="green"
                                            onClick={() => {
                                              if (
                                                (teamData.data || []).length > 1
                                              ) {
                                                let newTeamValue = {
                                                  ...values.teams,
                                                };
                                                delete newTeamValue[team._id];
                                                setFieldValue(
                                                  'teams',
                                                  newTeamValue
                                                );
                                              }
                                            }}
                                          />
                                        ) : (
                                          <FaRegSquare
                                            className="player-team-list-item-checkbox"
                                            style={
                                              (teamData.data || []).length >
                                                1 &&
                                              !maxxedOutTeams.includes(
                                                team?._id
                                              )
                                                ? { cursor: 'pointer' }
                                                : {}
                                            }
                                            onClick={() => {
                                              if (
                                                !maxxedOutTeams.includes(
                                                  team?._id
                                                )
                                              ) {
                                                if (
                                                  (teamData.data || []).length >
                                                  1
                                                ) {
                                                  let newTeamValue = {
                                                    ...values.teams,
                                                  };
                                                  newTeamValue[team._id] = {
                                                    roles: ['player'],
                                                  };
                                                  setFieldValue(
                                                    'teams',
                                                    newTeamValue
                                                  );
                                                }
                                              }
                                            }}
                                          />
                                        )}
                                      </div>
                                    )}
                                    <div
                                      className="player-update-team-card-header"
                                      style={
                                        (teamData.data || []).length > 1 &&
                                        !maxxedOutTeams.includes(team?._id)
                                          ? { cursor: 'pointer' }
                                          : {}
                                      }
                                      onClick={(e) => {
                                        if (
                                          !maxxedOutTeams.includes(team?._id)
                                        ) {
                                          if (
                                            (teamData.data || []).length > 1
                                          ) {
                                            let newTeamValue = {
                                              ...values.teams,
                                            };
                                            if (
                                              (
                                                Object.keys(values.teams) || []
                                              ).includes(team._id)
                                            ) {
                                              delete newTeamValue[team._id];
                                            } else {
                                              newTeamValue[team._id] = {
                                                roles: ['player'],
                                              };
                                            }
                                            setFieldValue(
                                              'teams',
                                              newTeamValue
                                            );
                                          }
                                        }
                                      }}
                                    >
                                      {_.isString(team.imageUrl) &&
                                        team.imageUrl.length > 0 && (
                                          <img
                                            src={team.imageUrl}
                                            style={{
                                              height: 30,
                                              width: 30,
                                              objectFit: 'contain',
                                            }}
                                          />
                                        )}
                                      <h4
                                        style={{
                                          margin: 0,
                                          lineHeight: 1,
                                          flex: 1,
                                        }}
                                      >
                                        {team.name}
                                      </h4>
                                    </div>
                                    {(Object.keys(values.teams) || []).includes(
                                      team._id
                                    ) && (
                                      <>
                                        <Select
                                          isMulti
                                          className="player-update-team-select"
                                          id="positions"
                                          placeholder={`Position(s) on ${team.name}`}
                                          options={POSITIONS}
                                          isClearable={true}
                                          value={(
                                            (values.teams[team._id] || {})
                                              .positions || []
                                          ).map((p) => {
                                            return { value: p, label: p };
                                          })}
                                          defaultValue={(
                                            (values.teams[team._id] || {})
                                              .positions || []
                                          ).map((p) => {
                                            return { value: p, label: p };
                                          })}
                                          onChange={(positions) => {
                                            let t = {};
                                            t[team._id] = {
                                              positions: [],
                                              roles: ['player'],
                                            };
                                            let newTeamValue = {
                                              ...t,
                                              ...values.teams,
                                            };
                                            newTeamValue[team._id].positions = (
                                              positions || []
                                            ).map((p) => p.value);
                                            setFieldValue(
                                              'teams',
                                              newTeamValue
                                            );
                                          }}
                                        />
                                        <Select
                                          isMulti
                                          id="groups"
                                          className="player-update-team-select"
                                          options={(
                                            (teamGroups || {})[team._id] || []
                                          )
                                            .filter((group) => !group.default)
                                            .map((g) => {
                                              return {
                                                value: g.id,
                                                label: g.title,
                                              };
                                            })}
                                          placeholder={`Group(s) on ${team.name}`}
                                          value={(
                                            (values.teams[team._id] || {})
                                              .groups || []
                                          ).map((g) => {
                                            return {
                                              value: g,
                                              label: groupMap[g] || '',
                                            };
                                          })}
                                          defaultValue={(
                                            (values.teams[team._id] || {})
                                              .groups || []
                                          ).map((g) => {
                                            return {
                                              value: g,
                                              label: groupMap[g] || '',
                                            };
                                          })}
                                          onChange={(groups) => {
                                            let t = {};
                                            t[team._id] = {
                                              groups: [],
                                              roles: ['player'],
                                            };
                                            let newTeamValue = {
                                              ...t,
                                              ...values.teams,
                                            };
                                            newTeamValue[team._id].groups =
                                              groups.map((g) => g.value);
                                            setFieldValue(
                                              'teams',
                                              newTeamValue
                                            );
                                          }}
                                        />
                                      </>
                                    )}
                                  </div>
                                  {maxxedOutTeams.includes(team?._id) &&
                                    !(Object.keys(values.teams) || []).includes(
                                      team._id
                                    ) && (
                                      <div className="team-maxxed-out-message">
                                        This team has the maximum number of
                                        allowed players on this team
                                      </div>
                                    )}
                                </div>
                              ))}
                            </div>
                          </CCol>
                        </CFormGroup>
                      </div>

                      <CRow>
                        <CCol xs="12" className="text-right pt-3">
                          {isSubmitting ? (
                            <LoadingMessage
                              firstMessage={`${
                                isCreate ? 'Creating' : 'Updating'
                              } player...`}
                              style={{ justifyContent: 'flex-end', height: 53 }}
                            />
                          ) : (
                            <>
                              <CButton
                                className="mr-2"
                                size="md"
                                color="danger"
                                variant="outline"
                                onClick={() => {
                                  history.push(TABS.COACH_PLAYERS.path);
                                }}
                              >
                                Cancel
                              </CButton>
                              <CButton
                                disabled={
                                  (isCreate && !isValid) ||
                                  (!isCreate &&
                                    _.isEqual(initialValues, values))
                                }
                                size="md"
                                type="submit"
                                color="primary"
                              >
                                {`${isCreate ? 'Create' : 'Update'} Player`}
                              </CButton>
                            </>
                          )}
                        </CCol>
                      </CRow>
                    </Form>
                  )}
                </Formik>
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </PlayerWrapper>
  );
}

export default PlayerUpdate;
