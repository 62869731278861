import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import TourView, { showTour } from '../../r-components/Tours/TourView';
import Breadcrumbs from './Breadcrumbs';

const QuizWrapper = ({ children, userData }) => {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   showTour(userData, 'quizzes', dispatch);
  // }, [userData]);
  return (
    <>
      <Breadcrumbs />
      <div className="container-fluid space-1">
        <div className="row">
          <div className="col">
            <div className="pr-lg-4">
              {children}
              {/* {quizData.quizzes ? <QuizzesListPage /> : null}
              {quizData.addQuizzes ? <AddQuizPage /> : null}
              {quizData.quizDetails ? <ExamDetailsPage /> : null}
              {quizData.quizTakenDetails ? <QuizTakenDetails /> : null} */}
            </div>
          </div>
        </div>
      </div>
      <TourView name="quizzes" />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.UserState,
  };
};

const mapDispatchToProps = {};

QuizWrapper.propTypes = {
  quizData: PropTypes.shape({
    quizzes: PropTypes.bool,
    addQuizzes: PropTypes.bool,
    quizDetails: PropTypes.bool,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizWrapper);
