import produce from 'immer';

import {
  CLEAR_QUIZZES,
  SET_CURRENT_QUIZZES,
  SET_CURRENT_QUIZZES_SCHEDULED,
} from './constants';

const INITIAL_STATE = {
  data: [],
  map: {},
  scheduled: [],
};

export const quizzesReducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_CURRENT_QUIZZES:
        return {
          ...state,
          ...action.payload,
        };
      case SET_CURRENT_QUIZZES_SCHEDULED:
        return { ...state, ...action.payload };
      case CLEAR_QUIZZES:
        return {
          ...INITIAL_STATE,
        };

      default:
        return state;
    }
  });
