import _ from 'lodash';
import moment from 'moment-timezone';
import CryptoJS from 'crypto-js';

// Stripe stores the expiration date of a session/subscription by default at 24 hours after creation
// This function returns the expiration date one year in advance to match with the current annual pricing structure
export const getAnnualSubscriptionExpirationDate = (epochString) => {
  const date = new Date(Number(epochString) * 1000);
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const expirationDate = new Date(year + 1, month, day);

  return expirationDate;
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const toUrlString = (str) => {
  if (!(_.isString(str) && str.length > 0)) {
    return '';
  }

  return (
    (str.replace(/\s+/g, '-') || '').replace(/[^0-9a-zA-Z-]/g, '') || ''
  ).toLowerCase();
};

export const fileTimestamp = () => {
  // set the time zone to Eastern Time
  const easternTime = moment.tz(
    Date.now(),
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  // format the date in the desired format
  const formattedDate = easternTime.format('YYYY-MM-DD--HHmmss');
  return formattedDate;
};

export const shortDateTime = () => {
  const easternTime = moment.tz(
    Date.now(),
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  // format the date in the desired format
  const formattedDate = easternTime.format('M/D/YY h:mma');
  return formattedDate;
};

export const formatPhoneNumber = (phoneNumber = '') => {
  let cleanedNumber = phoneNumber.toString();

  if (cleanedNumber.length === 12 && cleanedNumber.startsWith('+1')) {
    cleanedNumber = cleanedNumber.slice(2);
  }
  if (cleanedNumber.length === 11 && cleanedNumber.startsWith('1')) {
    cleanedNumber = cleanedNumber.slice(1);
  }
  cleanedNumber = cleanedNumber.replace(/\D/g, '');
  if (cleanedNumber.length > 10) {
    const countryCode = cleanedNumber.slice(0, cleanedNumber.length - 10);
    const areaCode = cleanedNumber.slice(
      countryCode.length,
      countryCode.length + 3
    );
    const firstPart = cleanedNumber.slice(
      countryCode.length + 3,
      countryCode.length + 6
    );
    const secondPart = cleanedNumber.slice(
      countryCode.length + 6,
      countryCode.length + 10
    );
    return `${
      countryCode.length > 0 ? `+${countryCode} ` : ''
    }(${areaCode}) ${firstPart}-${secondPart}`;
  }

  // Apply the desired format for numbers without a country code
  const formattedNumber = cleanedNumber.replace(
    /^(\d{3})(\d{3})(\d{4})$/,
    '($1) $2-$3'
  );

  return formattedNumber;
};

export const joinWithCommas = (array) => {
  const length = array.length;

  if (length === 0) {
    return '';
  } else if (length === 1) {
    return array[0];
  } else if (length === 2) {
    return array.join(' and ');
  } else {
    const lastElement = array[length - 1];
    const remainingElements = array.slice(0, length - 1);
    return `${remainingElements.join(', ')}, and ${lastElement}`;
  }
};

export const concatenateStringFromArray = (arrayOfStrings) => {
  const length = arrayOfStrings.length;

  if (length === 0) {
    return '';
  } else if (length === 1) {
    return arrayOfStrings[0];
  } else if (length === 2) {
    return `${arrayOfStrings[0]} & ${arrayOfStrings[1]}`;
  } else {
    const labels = [...arrayOfStrings];
    const lastLabel = labels.pop();
    return `${labels.join(', ')} & ${lastLabel}`;
  }
};

export const textTo8DigitHash = (text) => {
  // Compute SHA-256 hash
  const hash = CryptoJS.SHA256(text);
  // Convert hash to hexadecimal string
  const hashHex = hash.toString(CryptoJS.enc.Hex);
  // Convert a portion of the hash to an integer
  const hashInt = parseInt(hashHex.slice(0, 16), 16);
  // Convert the integer to a base-36 string
  const base36String = hashInt.toString(36).toUpperCase();
  // Get a 6-character alphanumeric string
  const sixDigitString = base36String.padStart(6, '0').slice(0, 6);
  return sixDigitString;
};
