import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
  FaCaretDown,
  FaCheck,
  FaCheckCircle,
  FaDollarSign,
  FaMapMarkedAlt,
  FaPowerOff,
  FaUserAlt,
  FaUserFriends,
  FaUserPlus,
  FaUsers,
  FaUserSecret,
} from 'react-icons/fa';
import { connect, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setCurrentTour, setCurrentTourStep } from '../../r-store/tour/actions';
import { viewPlayers } from '../../actions/playerActionCreator';
import _ from 'lodash';
import { doc, updateDoc } from 'firebase/firestore';
import { db, firebase } from '../..';
import { toUrlString } from '../../helpers/utils';
import { clearTeam, setCurrentTeam } from '../../r-store/team/actions';
import { useSignOut } from 'react-firebase-hooks/auth';
import { TABS, ROUTE_ELEMENTS } from '../../AppTabs';
import { handleError } from '../../helpers/errors';
import { _signOut } from '../../helpers/authHelper';
import { FaClipboardQuestion } from 'react-icons/fa6';

toast.configure();

const AppNavDropdown = ({ userData, teamData, teams, orgData }) => {
  const dispatch = useDispatch();

  const menuItems = [
    // {
    //   label: 'Edit Team',
    //   to: `/app/team/${toUrlString(teamData?.name || 'team')}/edit`,
    //   icon: <FaUsers />,
    //   onClick: () => {
    //     console.log('clicked edit team');
    //   },
    // },
    {
      label: 'Profile',
      to: TABS.USER_PROFILE_DETAILS.path,
      icon: <FaUserAlt />,
    },
    {
      label: 'View Help Tour',
      to: '#',
      icon: <FaMapMarkedAlt />,
      onClick: () => {
        let page = /[^/]*$/.exec(window.location.pathname)[0];
        switch (page) {
          case 'quizzes':
            break;
          case 'questions':
            break;
          case 'players':
            viewPlayers();
            break;
          case 'groups':
            break;
          default:
            break;
        }
        setCurrentTourStep(0);
        setCurrentTour({
          tourName: /[^/]*$/.exec(window.location.pathname)[0],
          navigationFunction: (url) => {
            //   this.props.history.push(url);
          },
        });
      },
    },
    {
      label: `${
        userData?.data?.onboard?.goals_show_override === true ? 'Hide' : 'Show'
      } Setup Steps`,
      to: '#',
      icon: <FaCheckCircle />,
      show: userData?.data?.onboard?.goalsCompleted === true,
      onClick: () => {
        updateDoc(doc(db, 'users', userData.data.id), {
          'onboard.goals_show_override':
            userData?.data?.onboard?.goals_show_override === true
              ? false
              : true,
          'onboard.goals_hidden': false,
        });
      },
    },
    {
      label: `Question Counts`,
      to: TABS.SUPER_ADMIN_QUESTION_COUNTS.path,
      icon: <FaClipboardQuestion />,
      show: userData?.isSuperAdmin === true,
    },
    {
      label: `Create Friendly`,
      to: TABS.SUPER_ADMIN_CREATE_FRIENDLY.path,
      icon: <FaUserPlus />,
      show: userData?.isSuperAdmin === true,
    },
    {
      label: `Import Users`,
      to: TABS.SUPER_ADMIN_UPLOAD_USERS.path,
      icon: <FaUsers />,
      show: userData?.isSuperAdmin === true,
    },
    {
      label: `Impersonate`,
      to: TABS.SUPER_ADMIN_IMPERSONATE.path,
      icon: <FaUserSecret />,
      show: userData?.isSuperAdmin === true,
    },
    {
      label: 'Logout',
      to: '/',
      icon: <FaPowerOff />,
      onClick: () => {
        _signOut(dispatch)
          .then(() => {
            window.localStorage.clear();
            sessionStorage.clear();
            toast('Logged out', { type: 'success' });
          })
          .catch((error) => {
            handleError({
              error,
              toast: 'Error logging out',
              internal: `AppNavDropdown.js:onClick Logout`,
            });
          });
      },
    },
  ];
  return (
    <li className="nav-item u-header__nav-item-btn dropdown">
      <a className="btn btn-sm btn-outline-primary" role="button">
        <FaUserAlt /> &nbsp; Hello{' '}
        <span className="text-capitalize">{userData?.data?.name}</span>
        <FaCaretDown size={20} />
      </a>
      <div className="dropdown-menu-wrapper">
        <div className="dropdown-menu py-0">
          <div className="dropdown-item no-hover cursor-default teams">
            {_.isObject(teamData) &&
            _.isString(teamData.imageUrl) &&
            teamData.imageUrl.length > 0 ? (
              <img className="team-logo" src={teamData.imageUrl} height={22} />
            ) : null}

            <div className="team-wrapper">
              <div className="team-name">
                {_.isObject(teamData) &&
                _.isString(teamData.name) &&
                teamData.name.length > 0
                  ? teamData.name
                  : userData.isOwner &&
                    _.isString(orgData?.data?.title) &&
                    orgData.data.title.length > 0
                  ? orgData.data.title
                  : 'All Teams'}
              </div>
              <>
                {_.isObject(teamData) &&
                _.isString(teamData.name) &&
                teamData.name.length > 0 ? (
                  <a
                    className="edit-team-link"
                    href={`/${ROUTE_ELEMENTS.HOME}/${
                      ROUTE_ELEMENTS.TEAMS
                    }/${toUrlString(teamData?.name || 'team')}/${
                      ROUTE_ELEMENTS.EDIT
                    }`}
                  >
                    edit
                  </a>
                ) : (
                  <a
                    className="edit-team-link"
                    href={`/${ROUTE_ELEMENTS.HOME}/${ROUTE_ELEMENTS.TEAMS}`}
                  >
                    view
                  </a>
                )}
              </>
            </div>
            {(userData.isOwner || userData?.data?._teams?.length > 1) && (
              <div className="select-team-submenu">
                <div className="select-team-submenu-header">
                  Switch to team:
                </div>
                <ul>
                  {(userData?.isOwner === true ||
                    userData?.data?._teams?.length > 1) && (
                    <li>
                      <Link
                        to="#"
                        onClick={() => {
                          dispatch(setCurrentTeam(null));
                        }}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        {userData?.isOwner === true
                          ? `${orgData?.data?.title || 'Full'} Org.`
                          : 'All Teams'}
                        {!(
                          _.isObject(teamData) &&
                          _.isString(teamData._id) &&
                          teamData._id.length > 0
                        ) && <FaCheck size={18} color="#162630" />}
                      </Link>
                    </li>
                  )}
                  {[...(teams || [])]
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((t, index) => (
                      <li
                        key={`teams-nav-dropdown-${index}-${t._id}`}
                        className={
                          _.isObject(teamData) &&
                          _.isString(teamData._id) &&
                          teamData._id.length > 0 &&
                          t._id === teamData?._id
                            ? 'active'
                            : ''
                        }
                      >
                        <Link
                          to="#"
                          onClick={() => {
                            dispatch(setCurrentTeam(t._id));
                          }}
                        >
                          <div
                            className={`select-team-line-wrapper ${
                              _.isObject(teamData) &&
                              _.isString(teamData._id) &&
                              teamData._id.length > 0 &&
                              t._id === teamData?._id
                                ? 'active'
                                : ''
                            }`}
                          >
                            <img
                              className="select-team-line-logo"
                              src={
                                _.isString(t.imageUrl) && t.imageUrl.length > 0
                                  ? t.imageUrl
                                  : '/img/dummy.jpg'
                              }
                            />
                            <div className="select-team-line-name">
                              {t.name}
                            </div>
                            {_.isObject(teamData) &&
                              _.isString(teamData._id) &&
                              teamData._id.length > 0 &&
                              t._id === teamData?._id && (
                                <FaCheck size={18} color="#162630" />
                              )}
                          </div>
                        </Link>
                      </li>
                    ))}
                </ul>
                {userData.isOwner && (
                  <Link
                    to={TABS.COACH_TEAMS.path}
                    className="select-team-submenu-footer"
                  >
                    Manage teams
                  </Link>
                )}
              </div>
            )}
          </div>
          {menuItems.map((item, index) => {
            if (
              item.hidden === true ||
              (!_.isUndefined(item.show) && item.show === false)
            ) {
              return null;
            }
            return (
              <React.Fragment key={`${item.label}-${item.index}-dropdown`}>
                <hr className="m-0" />
                <Link
                  className={`dropdown-item ${item.className || ''}`}
                  to={item.to}
                  onClick={item.onClick}
                >
                  {item.icon}
                  {item.label}
                </Link>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </li>
  );
};

const mapStateToProps = (state) => {
  return {
    orgData: state.DataOrganizationState,
    userData: state.UserState,
    teamData: state.DataTeamState?.teamData,
    teams: state.DataTeamState?.data || [],
  };
};

const mapDispatchToProps = {
  setCurrentTour,
  setCurrentTourStep,
  viewPlayers,
};

AppNavDropdown.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  isLoading: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  profileState: PropTypes.shape({
    errorStatus: PropTypes.string,
    profileData: PropTypes.shape({
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      phone: PropTypes.string,
    }),
    profilePassErr: PropTypes.string,
    status: PropTypes.number,
  }),
  teamLength: PropTypes.number,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppNavDropdown));
