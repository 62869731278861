import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { CButtonGroup, CButton, CImg, CTooltip } from '@coreui/react';

class QuestionScopeSegmentedControl extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  imgStyle = {
    height: this.props.height || 24,
    objectFit: 'contain',
    flex: 0,
  };

  buttonStyle = (isSelected) => {
    return {
      borderRadius: 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: `${this.props.layout === 'compact' ? 'auto' : 80}`,
      width: 'unset',
      position: 'unset',
      color: 'hsl(0,0%,45%)',
      backgroundColor: isSelected ? 'white' : '#efefef',
      padding: `${this.props.layout === 'compact' ? '4px 8px' : 'inherit'}`,
    };
  };

  render() {
    return (
      <CButtonGroup
        style={{
          ...{ background: 'hsl(0,0%,90%)', padding: 2 },
          ...this.props.style,
        }}
      >
        <CTooltip content="Show All Questions">
          <CButton
            color="black"
            style={this.buttonStyle(this.props.questionScope === 'all')}
            onClick={() => {
              this.props.setQuestionScope('all');
            }}
          >
            <CImg
              src="/img/user.png"
              fluid
              style={{ ...this.imgStyle, ...{ marginRight: 4 } }}
            />
            +
            <CImg
              src="/img/light-bulb-icon.png"
              fluid
              style={{ ...this.imgStyle, ...{ marginLeft: 4 } }}
            />
          </CButton>
        </CTooltip>
        <CTooltip content="Show Only My Questions">
          <CButton
            color={this.props.questionScope === 'mine' ? 'white' : '#efefef'}
            style={this.buttonStyle(this.props.questionScope === 'mine')}
            onClick={() => {
              this.props.setQuestionScope('mine');
            }}
          >
            <CImg src="/img/user.png" fluid style={this.imgStyle} />
          </CButton>
        </CTooltip>
        <CTooltip content="Show Only Public Questions">
          <CButton
            color={this.props.questionScope === 'public' ? 'white' : '#efefef'}
            style={this.buttonStyle(this.props.questionScope === 'public')}
            onClick={() => {
              this.props.setQuestionScope('public');
            }}
          >
            <CImg src="/img/light-bulb-icon.png" fluid style={this.imgStyle} />
          </CButton>
        </CTooltip>
      </CButtonGroup>
    );
  }
}

QuestionScopeSegmentedControl.propTypes = {
  setQuestionScope: PropTypes.func,
  questionScope: PropTypes.string,
  height: PropTypes.number,
};

export default QuestionScopeSegmentedControl;
