import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { TABS } from '../../AppTabs';

class CreateNewPass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPass: '',
      confirmNewPass: '',
      newPassError: '',
      confirmNewPassError: '',
      class: 'u-video-player',
      token: '',
    };
  }

  componentDidMount = () => {
    this.setState({
      token: window.location.search.split('=')[1],
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  playVideo = () => {
    this.setState({
      class: 'u-video-player u-video-player__played',
    });
  };

  handleSubmit = (e) => {
    let { newPass, confirmNewPass } = this.state;
    if (newPass && confirmNewPass) {
      if (newPass === confirmNewPass) {
        const resetPassData = {
          password: this.state.newPass,
        };
        // this.props.requestForgotPassword(resetPassData);
      } else {
        e.preventDefault();
        this.setState({
          confirmNewPassError: 'Password does not match',
        });
      }
    } else {
      if (this.state.newPass === '') {
        this.setState({
          newPassError: 'This field is required',
        });
      } else {
        this.setState({
          newPassError: '',
        });
      }
      if (this.state.confirmNewPass === '') {
        this.setState({
          confirmNewPassError: 'This field is required',
        });
      } else {
        this.setState({
          confirmNewPassError: '',
        });
      }
    }
  };
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.reset === 3) {
      this.props.history.push(TABS.LOGIN.path);
    }
  };
  render() {
    return (
      <div>
        <div className="gradient-overlay-half-dark-v3 bg-img-hero login-background">
          <div className="d-lg-flex align-items-lg-center min-height-100vh">
            <div className="container space-2">
              <div className="row justify-content-lg-between align-items-lg-center">
                <div className="col-lg-7 m-auto">
                  <div className="bg-white shadow-sm rounded p-6">
                    <div className="mb-4">
                      <h2 className="font-weight-bold">Create new password</h2>
                      <p>
                        Enter your new password by using capital letters,
                        special characters and numbers.
                      </p>
                    </div>

                    <div className="js-form-message mb-3">
                      <div className="js-focus-state input-group input-group form">
                        <div className="input-group-prepend form__prepend">
                          <span
                            className={
                              this.state.newPassError
                                ? 'input-group-text form__text error'
                                : 'input-group-text form__text'
                            }
                          >
                            <img
                              src="/img/login-and-signup/pass.png"
                              className="info"
                              alt=""
                            />
                          </span>
                        </div>
                        <input
                          type="password"
                          className={
                            this.state.newPassError
                              ? 'form-control form__input error'
                              : 'form-control form__input'
                          }
                          name="newPass"
                          value={this.state.newPass}
                          placeholder="Password"
                          onChange={this.handleChange}
                        />
                      </div>
                      {this.state.newPassError ? (
                        <div className="custom-color">
                          {this.state.newPassError}
                        </div>
                      ) : null}
                    </div>

                    <div className="js-form-message mb-3">
                      <div className="js-focus-state input-group input-group form">
                        <div className="input-group-prepend form__prepend">
                          <span
                            className={
                              this.state.confirmNewPassError
                                ? 'input-group-text form__text error'
                                : 'input-group-text form__text'
                            }
                          >
                            <img
                              src="/img/login-and-signup/repeat-pass.png"
                              className="info"
                              alt=""
                            />
                          </span>
                        </div>
                        <input
                          type="password"
                          className={
                            this.state.confirmNewPassError
                              ? 'form-control form__input error'
                              : 'form-control form__input'
                          }
                          name="confirmNewPass"
                          value={this.state.confirmNewPass}
                          placeholder="Confirm Password"
                          onChange={this.handleChange}
                        />
                      </div>
                      {this.state.confirmNewPassError ? (
                        <div className="custom-color">
                          {this.state.confirmNewPassError}
                        </div>
                      ) : null}
                    </div>
                    <button
                      type="submit"
                      className="btn btn-block btn-primary"
                      onClick={this.handleSubmit}
                    >
                      Complete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reset: state.TokenState.status,
  };
};

const mapDispatchToProps = {};

CreateNewPass.propTypes = {
  reset: PropTypes.number,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateNewPass));
