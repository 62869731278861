import React from 'react';
import { CCol, CContainer, CRow, CImg } from '@coreui/react';
import { EMAIL_ADDRESSES } from '../../../config';

const Page404 = () => {
  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol>
            <div className="clearfix" style={{ textAlign: 'center' }}>
              <h1 className="d-block">404 page not found</h1>
              <h4 className="pt-3">Looks like we booted this one.</h4>
            </div>
            <CImg
              fluid
              align="center"
              src="/img/404.jpeg"
              style={{ maxHeight: '50vh', objectFit: 'contain' }}
            ></CImg>
            <p style={{ textAlign: 'center', marginTop: 20 }}>
              Please contact{' '}
              <a href={`mailto:${EMAIL_ADDRESSES.SUPPORT}`}>
                {EMAIL_ADDRESSES.SUPPORT}
              </a>{' '}
              if this happens again.
            </p>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Page404;
