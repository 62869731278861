import CIcon from '@coreui/icons-react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CLink,
  CRow,
} from '@coreui/react';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { FaAngleLeft, FaEnvelope, FaLock, FaUser } from 'react-icons/fa';
import Spinner from '../../../src/pages/component/Spinner';
import { Formik, Form, Field, ErrorMessage } from 'formik';
// import { Button, FormGroup, FormLabel, Input, BootstrapForm.Control.Feedback } from "react-bootstrap";
import {
  Button,
  Form as BootstrapForm,
  FormGroup,
  FormLabel,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import { auth, functions } from '../..';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { DYNAMIC_LINK_DOWNLOAD, TABS } from '../../AppTabs';
import { handleError } from '../../helpers/errors';
import { values } from 'lodash';
import SignupWrapper from './SignupWrapper';

const SignupSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
});

const SignupRedirectPlayerToDownload = () => {
  const [countdown, setCountdown] = useState(10);
  const history = useHistory();

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Redirect the user after 10 seconds
    const redirectInterval = setTimeout(() => {
      clearInterval(countdownInterval);
      history.push(DYNAMIC_LINK_DOWNLOAD);
    }, 10000);

    return () => {
      clearInterval(countdownInterval);
      clearInterval(redirectInterval);
    };
  }, []);

  return (
    <SignupWrapper>
      <div className="signup-header mb-4">
        <CLink
          className="signup-back-button"
          to={TABS.SIGNUP_CREATE_ACCOUNT.path}
        >
          <FaAngleLeft />
          Back
        </CLink>
        <h2 className="text-center font-weight-bold">
          Redirecting To Download
        </h2>
      </div>
      <div className="redirect-player-content-section">
        <div className="img-box">
          <img src="/img/why-6tool.png" className="img-fluid" alt="" />
        </div>
        <div className="redirect-player-content">
          <p>
            For players, you will download and use the 6Tool App. You will be
            redirected to your respective App Store or a download detail page,
            depending on what device you are using.
          </p>
          <div>
            {' '}
            Redirecting you in {countdown} second{countdown === 1 ? '' : 's'}...
          </div>
          <div>
            <CLink href={DYNAMIC_LINK_DOWNLOAD}>Go there now</CLink> or{' '}
            <CLink to={TABS.SIGNUP_CREATE_ACCOUNT.path}>
              go back to the sign-up page
            </CLink>
          </div>
        </div>
      </div>
    </SignupWrapper>
  );
};

export default SignupRedirectPlayerToDownload;
