import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Footer from '../Footer';
import { useHistory, withRouter } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../..';
import { connect } from 'react-redux';
import _ from 'lodash';
import { TABS } from '../../../AppTabs';
import FunnelNav from '../../../r-components/Nav/FunnelNav';
import FunnelFooter from '../FunnelFooter';

const PublicOnlyLayout = ({ children }) => {
  const [user, loading, error] = useAuthState(auth);
  let history = useHistory();

  useEffect(() => {
    if (!!user && !loading) {
      history.push(TABS.COACH_TEAMS.path);
    }
  }, [user]);

  return (
    <>
      <FunnelNav />
      <div className="main-content">{children}</div>
      <FunnelFooter />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.UserState,
    teamData: state.DataTeamState?.teamData || {},
    teams: state.DataTeamState?.data || [],
  };
};

PublicOnlyLayout.propTypes = {
  children: PropTypes.node,
};

export default connect(mapStateToProps)(withRouter(PublicOnlyLayout));
