import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CLabel,
  CLink,
  CRow,
  CTooltip,
} from '@coreui/react';
import React, { useEffect, useRef, useState } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { FaCircle, FaEdit, FaInfoCircle, FaUserPlus } from 'react-icons/fa';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Loading } from '../../../../r-reusable';
import { TABS } from '../../../../AppTabs';
import { orgAndTeamData } from '../../../../helpers/data';
import OrgTeamImageHeader from '../../../../r-components/OrgTeamImageHeader/OrgTeamImageHeader';
import EventsWrapper from '../../../../pages/component/EventsWrapper';
import _, { isString } from 'lodash';

const EventTeams = ({ match }) => {
  const history = useHistory();
  const [eventID, setEventID] = useState('all');
  const [eventName, setEventName] = useState('');
  const queryPage = useLocation().search.match(/page=([0-9]+)/, '');
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const dispatch = useDispatch();
  const _teams = useSelector((state) => state.DataEventsState?.teams || []);
  const events = useSelector((state) => state.DataEventsState?.events || []);
  const eventMap = useSelector(
    (state) => state.DataEventsState?.eventMap || {}
  );
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(currentPage);
  const currentUser = useSelector((state) => state?.UserState);
  const orgData = useSelector((state) => state.DataOrganizationState);
  const teamData = useSelector((state) => state.DataTeamState);
  const [orgTeamData, setOrgTeamData] = useState({});
  const [tableFields, setTableFields] = useState([]);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    setEventID(match?.params?.id || 'all');
    setEventName(match?.params?.name || '');
  }, [match]);

  useEffect(() => {
    currentPage !== page && setPage(currentPage);
  }, [currentPage, page]);

  // useEffect(async () => {
  //   const createNewPlayerUserCallable = functions.httpsCallable(
  //     'createNewPlayerUserCallable'
  //   );
  //   let org_team = {};
  //   let team_requests = [
  //     {
  //       id: '0a08d209-01e8-496f-8e87-11c17f0e6c1e',
  //       name: 'Deacons',
  //       organization_id: '42bee8cb-95c6-4643-bfcb-7d27bc3bacf1',
  //       location: { city: 'Cromwell', state: 'CT' },
  //     },
  //   ];
  //   let phoneNumber = `+1${Math.round(10000000000 * Math.random())}`;
  //   const createNewPlayerUserResult = await createNewPlayerUserCallable({
  //     name: 'Test',
  //     email: `test${Math.round(100000 * Math.random())}@email.com`,
  //     phoneNumber,
  //     organization_id: '42bee8cb-95c6-4643-bfcb-7d27bc3bacf1',
  //     team_requests,
  //     orgName: 'Deacons',
  //   });
  // }, []);

  useEffect(() => {
    let orgTeam = orgAndTeamData({ orgData, teamData });
    setOrgTeamData(orgTeam);
    let tableFields = [
      { key: 'name', label: 'Name', _classes: 'font-weight-bold' },
      { key: 'city', label: 'City', _classes: '' },
    ];
    if (eventID === 'all') {
      tableFields.push({ key: 'events', label: 'Event(s)' });
    }
    tableFields = tableFields.concat([
      { key: 'show_details', label: '', sorter: false },
    ]);
    setTableFields(tableFields);
  }, [orgData, teamData]);

  useEffect(() => {
    const participantMap = (p) => {
      const isSingleEvent = isString(eventID) && eventID !== 'all';
      let exams_taken = (p.exams_taken || []).filter(
        (e) => (isSingleEvent && e.event_id === eventID) || !isSingleEvent
      );
      let events = (p.event_ids || []).map((event_id) => {
        return eventMap[event_id];
      });
      let resultP = { ...p };
      resultP.exams_taken = exams_taken;
      resultP.exam_ids = [
        ...new Set((exams_taken || []).map((i) => i.event_id)),
      ];
      resultP.exam_taken_ids = [
        ...new Set((exams_taken || []).map((i) => i.id)),
      ];
      resultP.events = events;
      return resultP;
    };
    console.log({ eventID });
    if (isString(eventID) && eventID !== 'all') {
      let newParticipants = [...(_teams || [])]
        .filter((p) => (p.event_ids || []).includes(eventID))
        .map(participantMap);
      setTeams(newParticipants);
    } else {
      setTeams(_teams.map(participantMap));
    }
  }, [_teams, eventID, eventMap]);

  useEffect(() => {
    console.log(teams);
  }, [teams]);

  return (
    <EventsWrapper>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader className="d-flex justify-content-between">
              <OrgTeamImageHeader
                orgTeamData={orgTeamData}
                title={
                  eventID === 'all'
                    ? `Event Teams`
                    : `Teams${
                        isString(eventName) && eventName.length > 0
                          ? ` - ${eventName}`
                          : ''
                      }`
                }
              />
            </CCardHeader>

            <CCardBody>
              {loading ? (
                <Loading />
              ) : (
                <CDataTable
                  striped
                  items={teams}
                  itemsPerPageSelect={true}
                  itemsPerPage={50}
                  pagination={true}
                  hover
                  activePage={page}
                  tableFilter={true}
                  sorter
                  sorterValue={{ column: 'name', asc: true }}
                  fields={tableFields}
                  noItemsViewSlot={
                    <p className="d-flex align-content-center justify-content-center pt-4 pb-4">
                      <FiAlertCircle size="30" color="#e55353" />
                      <span className="pl-2 font-xl">No teams</span>
                    </p>
                  }
                  scopedSlots={{
                    team: (player) => (
                      <td>
                        {(player._teams || [])
                          .map((tId) =>
                            _.isObject(teamData.map) &&
                            _.isObject(teamData.map[tId]) &&
                            _.isString(teamData?.map[tId].name)
                              ? teamData.map[tId].name
                              : ''
                          )
                          .filter((name) => name !== '')
                          .join(', ')}
                      </td>
                    ),
                    events: (player) => (
                      <td>
                        {(player.events || []).length > 0 && (
                          <>
                            {player.events.length > 1 ? (
                              <CTooltip
                                content={(player.events || [])
                                  .map((e) =>
                                    _.isString(e)
                                      ? e
                                      : _.isObject(e)
                                      ? `${e.name} | ${e.city} (${e.visibleDateText})`
                                      : ''
                                  )
                                  .join(', ')}
                              >
                                <div>{`${
                                  (player.events || []).length
                                } Events`}</div>
                              </CTooltip>
                            ) : (
                              <>
                                {(player.events || [])
                                  .map((e) =>
                                    _.isString(e)
                                      ? e
                                      : _.isObject(e)
                                      ? `${e.name} | ${e.city} (${e.visibleDateText})`
                                      : ''
                                  )
                                  .join(', ')}
                              </>
                            )}
                          </>
                        )}
                      </td>
                    ),
                    score: (player) => (
                      <td
                        className="font-weight-bold"
                        style={{ fontFamily: 'monospace' }}
                      >{`${(player.score * 100).toFixed(1)}%`}</td>
                    ),
                    show_details: (player) => (
                      <td className="text-right">
                        {
                          <Link
                            to={TABS.COACH_PLAYERS_EDIT.pathVar(
                              _.pick(player, ['id', 'name'])
                            )}
                          >
                            <FaEdit />
                          </Link>
                        }
                      </td>
                    ),
                  }}
                />
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </EventsWrapper>
  );
};

export default EventTeams;
