// import React, { FC } from 'react';

import { Wrapper } from './ContainerStyles';
import React from 'react';
import jsonp from 'jsonp';
import PropTypes from 'prop-types';

const Container = ({ children }) => <Wrapper>{children}</Wrapper>;

export default Container;
