import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CDataTable,
  CLink,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CTooltip,
} from '@coreui/react';
import './team.scss';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import {
  FaBaseballBall,
  FaInfoCircle,
  FaTimes,
  FaUserMinus,
  FaUserPlus,
} from 'react-icons/fa';
import _ from 'lodash';
import '../../../pages/user/signup.scss';
import OrgTeamImageHeader from '../../../r-components/OrgTeamImageHeader/OrgTeamImageHeader';
import { TABS } from '../../../AppTabs';
import { GiWhistle } from 'react-icons/gi';
import { FiAlertCircle } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { orgAndTeamData } from '../../../helpers/data';
import { format } from 'date-fns';
import { auth, functions } from '../../..';
import { toast } from 'react-toastify';
import { handleError } from '../../../helpers/errors';
import { Container } from 'react-bootstrap';
import { Form as BootstrapForm } from 'react-bootstrap';
import { Formik } from 'formik';
import CoachRequestAccessToTeams from '../../../pages/user/CoachRequestAccessToTeams';
import LoadingMessage from '../../../r-components/LoadingMessage/LoadingMessage';
import FlexSpacer from '../../../r-components/FlexSpacer/FlexSpacer';

const SignupSchema = Yup.object().shape({
  selectedTeams: Yup.array()
    .min(1, 'At least one team is required')
    .of(
      Yup.object().shape({
        id: Yup.string().required('One of your teams has an error'),
        organization_id: Yup.string().required(
          'One of your teams has an error (no-org-id)'
        ),
      })
    )
    .required('At least one team is required.'),
});
const SEARCH_TYPES = { BY_COACH_EMAIL: 0, BY_TEAM_CODE: 1 };

const TeamRequestAccess = ({ title, orgTeamData, requestsData }) => {
  const orgData = useSelector((state) => state.DataOrganizationState);
  const teamData = useSelector((state) => state.DataTeamState);

  const userData = useSelector((state) => state.UserState?.data || {});
  const [tableFields, setTableFields] = useState([]);
  // const [orgTeamData, setOrgTeamData] = useState({});
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCreateRequestModal, setShowCreateRequestModal] = useState(false);
  const [currentSearchType, setCurrentSearchType] = useState(
    SEARCH_TYPES.BY_COACH_EMAIL
  );
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (!(_.isArray(requestsData) && requestsData.length > 0)) {
      setRequests([]);
    } else {
      setLoading(true);
      // const organization_ids = [
      //   ...new Set((requestsData || []).map((obj) => obj.organization_id)),
      // ];
      // const orgNamesForOrganizationIDsCallable = functions.httpsCallable(
      //   'orgNamesForOrganizationIDsCallable'
      // );
      // const orgNamesForOrganizationIDsResult =
      //   await orgNamesForOrganizationIDsCallable({ organization_ids });
      let _requests = [];
      [...requestsData].forEach((rD) => {
        let r = {
          team_id: rD.id,
          team: rD.name,
          organization_id: rD.organization_id,
        };
        if (
          _.isString(rD?.location?.city) &&
          rD.location.city.length > 0 &&
          _.isString(rD?.location?.state) &&
          rD.location.state.length > 0
        ) {
          r.location = `${rD.location.city}, ${rD.location.state}`;
        } else {
          r.location = 'Unknown';
        }
        if (!!rD.created_at) {
          r.created_at = format(
            new Date(rD.created_at?.seconds * 1000 || rD.created_at),
            'MMM dd'
          );
        }
        // if (
        //   _.isObject(orgNamesForOrganizationIDsResult?.data) &&
        //   _.isString(orgNamesForOrganizationIDsResult.data[rD.organization_id]) &&
        //   orgNamesForOrganizationIDsResult.data[rD.organization_id].length > 0
        // ) {
        //   r.organization =
        //     orgNamesForOrganizationIDsResult.data[rD.organization_id];
        // }
        _requests.push(r);
      });
      setRequests(_requests);
      setLoading(false);
    }
  }, [requestsData]);

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    const createTeamRequestsForUserCallable = functions.httpsCallable(
      'createTeamRequestsForUserCallable'
    );
    try {
      try {
        const createTeamRequestsForUserResult =
          await createTeamRequestsForUserCallable({
            user_id: userData.id,
            email: userData.email,
            name: userData.name,
            phoneNumber: userData.phoneNumber,
            team_requests: values.selectedTeams,
            type: 'coach',
          });
        setShowCreateRequestModal(false);
        resetForm();
      } catch (e) {
        handleError({
          error: e,
          toast: e.message.replace('Firebase:', '').trim(),
          internal: `SignupCreateCoach.js:onSubmit`,
        });
      }
    } catch (err) {
      handleError({
        error: err,
        toast: err.message.replace('FirebaseError:', '').trim(),
        internal: `SignupCreateCoach.js:onSubmit::catch`,
      });
    } finally {
      // setSubmitting(false);
    }
  };

  return (
    <div className="container-fluid space-1">
      <Container>
        <CCard>
          <CCardHeader className="d-flex justify-content-between">
            {_.isString(title) && title.length > 0 ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: '100%',
                  gap: 10,
                }}
              >
                <h2 style={{ margin: 0 }}>{title}</h2>
                <CTooltip content="These are teams who you have requested access to join as a coach. Until your request is approved, you are not able to access these teams.">
                  <div>
                    <FaInfoCircle /> What is this?
                  </div>
                </CTooltip>
                <FlexSpacer />
                <CButton
                  onClick={() => {
                    setShowCreateRequestModal(true);
                  }}
                >
                  <FaUserPlus style={{ marginTop: -3, marginRight: 4 }} />
                  Request Access
                </CButton>
              </div>
            ) : (
              <OrgTeamImageHeader
                orgTeamData={orgTeamData}
                title="Team Requests"
                leftView={
                  <CTooltip content="These are teams who you have requested access to join as a coach. Until your request is approved, you are not able to access these teams.">
                    <div>
                      <FaInfoCircle /> What is this?
                    </div>
                  </CTooltip>
                }
                rightView={
                  <CButton
                    onClick={() => {
                      setShowCreateRequestModal(true);
                    }}
                  >
                    <FaUserPlus style={{ marginTop: -3, marginRight: 4 }} />
                    Request Access
                  </CButton>
                }
              />
            )}
          </CCardHeader>

          <CCardBody>
            <CDataTable
              addTableClasses="team-coach-requests-table"
              striped
              items={requests}
              itemsPerPage={50}
              hover
              fields={[
                // {
                //   key: 'organization',
                //   label: 'organization',
                //   _classes: 'font-weight-bold',
                // },
                {
                  key: 'team',
                  label: 'Team',
                  _classes: 'font-weight-bold',
                },
                {
                  key: 'location',
                  label: 'City',
                },
                { key: 'created_at', label: 'Request Time' },
                { key: 'show_details', label: '' },
              ]}
              noItemsViewSlot={
                loading ? (
                  <p className="d-flex align-content-center justify-content-center pt-4 pb-4">
                    <span className="pl-2 font-xl">Loading requests...</span>
                  </p>
                ) : (
                  <p className="d-flex align-content-center justify-content-center pt-4 pb-4">
                    <FiAlertCircle size="30" color="#e55353" />
                    <span className="pl-2 font-xl">No pending requests.</span>
                  </p>
                )
              }
              scopedSlots={{
                show_details: (request) => (
                  <td className="text-right">
                    <CButton
                      className="team-coach-requests-action-buttons"
                      variant="outline"
                      color="danger"
                      size="sm"
                      onClick={async () => {
                        try {
                          const deleteTeamRequestForUserCallable =
                            functions.httpsCallable(
                              'deleteTeamRequestForUserCallable'
                            );
                          let result = await deleteTeamRequestForUserCallable({
                            ...{ type: 'coach', user_id: userData.id },
                            ..._.pick(request, ['team_id', 'organization_id']),
                          });
                          toast('Request deleted', { type: 'success' });
                        } catch (error) {
                          handleError({
                            error,
                            toast: 'Error deleting join request',
                            internal: 'TeamRequestAccess - deleting request',
                          });
                        }
                      }}
                    >
                      Delete request
                      <FaTimes size={20} style={{ marginLeft: 4 }} />
                    </CButton>
                  </td>
                ),
              }}
            />
          </CCardBody>
        </CCard>
      </Container>
      <CModal
        show={showCreateRequestModal}
        size="lg"
        onClose={() => {
          setShowCreateRequestModal(false);
        }}
      >
        <Formik
          initialValues={{
            code: '',
            coachEmail: '',
            selectedTeams: [],
          }}
          onSubmit={onSubmit}
        >
          {({
            errors,
            touched,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            values,
          }) => (
            <>
              <BootstrapForm onSubmit={handleSubmit}>
                <CModalHeader>
                  <h2>Create New Access Request</h2>
                </CModalHeader>
                <CModalBody>
                  <div>Search for teams to request access by:</div>
                  <div className="signup-create-account-choose-team">
                    <CoachRequestAccessToTeams
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      values={values}
                    />
                  </div>
                </CModalBody>
                <CModalFooter>
                  {isSubmitting ? (
                    <LoadingMessage
                      firstMessage="Creating request..."
                      style={{ justifyContent: 'flex-end', height: 53 }}
                    />
                  ) : (
                    <>
                      <CButton
                        variant="outline"
                        color="danger"
                        onClick={() => {
                          setShowCreateRequestModal(false);
                        }}
                      >
                        Cancel
                      </CButton>
                      <CButton
                        color="primary"
                        type="submit"
                        disabled={(values.selectedTeams || []).length === 0}
                      >
                        {(values.selectedTeams || []).length === 0
                          ? 'Request Access'
                          : `Request Access to ${
                              (values.selectedTeams || []).length
                            } Team${
                              (values.selectedTeams || []).length === 1
                                ? ''
                                : 's'
                            }`}
                      </CButton>
                    </>
                  )}
                </CModalFooter>
              </BootstrapForm>
            </>
          )}
        </Formik>
      </CModal>
    </div>
  );
};

export default TeamRequestAccess;
