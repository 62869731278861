import { CListGroupItem } from '@coreui/react';

import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import QuestionListItem from './QuestionListItem';

const QuestionListView = ({
  question,
  index,
  side,
  expanded,
  selectQuestion,
  selectedBankedQuestions,
  expandedQuestion,
  selectedQuestions,
  setExpandedQuestion,
  moveSingleQuestion,
  mockupSingleQuestion,
  userCanEdit,
  userCanCreateQuiz,
}) => {
  return (
    <CListGroupItem
      className={`question-list-view question-list-view-${side} ${
        expanded ? ' expanded' : ''
      }`}
      key={`${question.id}-${index}`}
    >
      {expanded ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <QuestionListItem
            key={`qli-${question.id}-${index}`}
            expanded={expandedQuestion?.id === question?.id}
            question={question}
            index={index}
            side={side}
            selectQuestion={selectQuestion}
            selectedBankedQuestions={selectedBankedQuestions}
            expandedQuestion={expandedQuestion}
            selectedQuestions={selectedQuestions}
            setExpandedQuestion={setExpandedQuestion}
            moveSingleQuestion={moveSingleQuestion}
            mockupSingleQuestion={mockupSingleQuestion}
            userCanEdit={userCanEdit}
            userCanCreateQuiz={userCanCreateQuiz}
          />
        </div>
      ) : (
        <QuestionListItem
          key={`qli-${question.id}-${index}`}
          expanded={expandedQuestion?.id === question?.id}
          question={question}
          index={index}
          side={side}
          selectQuestion={selectQuestion}
          selectedBankedQuestions={selectedBankedQuestions}
          expandedQuestion={expandedQuestion}
          selectedQuestions={selectedQuestions}
          setExpandedQuestion={setExpandedQuestion}
          moveSingleQuestion={moveSingleQuestion}
          mockupSingleQuestion={mockupSingleQuestion}
          userCanEdit={userCanEdit}
          userCanCreateQuiz={userCanCreateQuiz}
        />
      )}
    </CListGroupItem>
  );
};

QuestionListView.propTypes = {
  display: PropTypes.bool,
  children: PropTypes.node,
};

export default QuestionListView;
