import { CButton, CCol, CRow } from '@coreui/react';
import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
const DoneButton = ({ to, label = 'Done' }) => (
  <CRow>
    <CCol xs="12" className="text-right pt-3">
      {_.isString(to) && to.length > 0 ? (
        <Link to={to}>
          <CButton className="mr-6 mb-8" size="md" color="primary">
            {label}
          </CButton>
        </Link>
      ) : (
        <CButton
          className="mr-6 mb-8"
          size="md"
          color="primary"
          onClick={() => {
            history.back();
          }}
        >
          {label}
        </CButton>
      )}
    </CCol>
  </CRow>
);

export default DoneButton;
