import styled from 'styled-components';


export const Wrapper = styled.div`
  background: #F2F2F2;
  padding: 80px 0 120px;

`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  gap: 60px;

  button {
    background: #f6d30d;
    border: solid 1px #f6d30d;
    color: #151b26;
    padding: 19px 2px;
    border-radius: 10px;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    width: 310px;
  }
`;

export const H3 = styled.h3`
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 40px;
  color: #0e0e0e;
  line-height: 50px;
  text-align: center;
  margin: 0px 0 40px;
`;

export const Testimonail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
`;

export const TestimonailItem = styled.div`
  background: #fff;
  border-radius: 22px;
  padding: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;

  p {
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
    color: #151b26;
    line-height: 30px;
  }
`;


export const TestimonailHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;

  p {
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
    color: #151b26;
    line-height: 30px;
  }

  img {
    width: 95px;
    height: 95px;
    border-radius: 100px;
    overflow: hidden;
  }

  div {
    display: flex;
    flex-direction: column;

    strong {
      font-family: 'Raleway', sans-serif;
      font-weight: 700;
      font-size: 20px;
      color: #151b26;
      line-height: 28px;
    }

    span {
      font-family: 'Raleway', sans-serif;
      font-size: 14px;
      color: #151b26;
    }
  }
`;

export const Rate = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  background: #fbf4d9;
  border-radius: 0 0 0 22px;
  padding: 20px 30px 20px;

  ol {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }
`;
