import advance from '../../assets/advance.png';
import brain from '../../assets/icons/brain.png';
import muscle from '../../assets/icons/muscle.png';
import player from '../../assets/icons/player.png';
import React from 'react';
import Container from '../Container/Container';
import {
  Content,
  ContentLeft,
  ContentRight,
  Item,
  TopContent,
} from './ToolAdvantageStyles';

const ToolAdvantage = () => {
  return (
    <Container>
      <TopContent>
        <h4>
          Unleash the
          <br /> 6Tool advantage
        </h4>
        <p>
          Commit to a comprehensive
          <br /> baseball coaching system.
        </p>
        <button>sign up here</button>
      </TopContent>
      <Content>
        <ContentLeft>
          <img src={advance} alt="" />
        </ContentLeft>
        <ContentRight>
          <Item>
            <img src={brain} />
            <p>Maps out each player's strengths and weaknesses</p>
          </Item>
          <Item>
            <img src={muscle} />
            <p>
              Develops intuitive game strategies, like knowing where to throw
            </p>
          </Item>
          <Item>
            <img src={player} />
            <p>Helps you win more games by mastering routine and solutions.</p>
          </Item>
        </ContentRight>
      </Content>
    </Container>
  );
};

export default ToolAdvantage;
