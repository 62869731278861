import React, { lazy, Suspense, useState } from 'react';
import './Logos.scss';

import {
  FaEnvelope,
  FaEnvelopeOpen,
  FaMinus,
  FaPlus,
  FaTimes,
  FaTimesCircle,
} from 'react-icons/fa';
import _ from 'lodash';

export const LogoList = [
  {
    name: 'Assumption College',
    logo: 'assumption-college-logo.png',
  },
  {
    name: 'Bloomsburg University',
    logo: 'bloomsburg-university-logo.png',
  },
  {
    name: 'Coppin State University',
    logo: 'coppin-state-university-logo.png',
  },
  {
    name: 'Franklin & Marshall College',
    logo: 'franklin-marshall-logo.png',
  },
  {
    name: 'College of the Holy Cross',
    logo: 'holy-cross-logo.png',
  },
  {
    name: 'Hendrix College',
    logo: 'hendrix-college-logo.png',
  },
  {
    name: 'Johnson University',
    logo: 'johnson-university-logo.png',
  },
  {
    name: 'Patriots Travel Team',
    logo: 'patriots-travel-team-logo.png',
  },
  {
    name: 'Plainview High School',
    logo: 'plainview-high-logo.png',
  },
  {
    name: 'Richmond University',
    logo: 'richmond-university-logo.png',
  },
  {
    name: 'University of Rochester',
    logo: 'university-of-rochester-logo.png',
  },
  {
    name: 'Saint Johns Prep',
    logo: 'saint-johns-prep-logo.png',
  },
  {
    name: 'Saint Lawrence University',
    logo: 'saint-lawrence-logo.png',
  },
  {
    name: 'Siena College',
    logo: 'siena-logo.png',
  },
  {
    name: 'Swathmore College',
    logo: 'swathmore-college-logo.png',
  },
  {
    name: 'Townson University',
    logo: 'towson-university-logo.png',
  },
  {
    name: 'University of Mary Washington',
    logo: 'university-mary-washington-logo.png',
  },
  {
    name: 'Walt Whitman Vikings',
    logo: 'walt-whitman-logo.png',
  },
  {
    name: 'Washington & Lee University',
    logo: 'washington-lee-university-logo.png',
  },
  {
    name: 'Widener University',
    logo: 'widener-logo.png',
  },
  {
    name: 'Worcester State University',
    logo: 'worcester-state-university-logo.png',
  },
];

const Logos = () => {
  return (
    <>
      <section id="logos" className="logos">
        <div className="container">
          <div className="title">
            <h2>Winning Teams using 6Tool</h2>
          </div>
          <div className="logo-wrapper">
            {LogoList.map((team) => (
              <>
                <img src={`/img/team-logos/${team.logo}`} alt={team.name} />
              </>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Logos;
