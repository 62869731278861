import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import TourView, { showTour } from '../../r-components/Tours/TourView';
import _ from 'lodash';
import Breadcrumbs from './Breadcrumbs';
const EventsWrapper = ({ userData, children, className }) => {
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   showTour(userData, 'players', dispatch);
  // }, [userData]);
  return (
    <>
      <Breadcrumbs />
      <div className={`container-fluid space-1 ${className}`}>
        <div className="row">
          <div className="col">
            <div className="pr-lg-4">{children}</div>
          </div>
        </div>
      </div>
      {/* <TourView name="events" /> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.UserState,
  };
};

export default EventsWrapper;
