import React from 'react';
import jsonp from 'jsonp';
import PropTypes from 'prop-types';

class AppStoreBadges extends React.Component {
  render() {
    const { height, style } = this.props;
    return (
      <div style={{ ...AppStoreBadges.defaultProps.style, ...style }}>
        <div
          className="btn-wrap"
          style={{ maxWidth: 'calc(50% - 12px)', overflow: 'hidden' }}
        >
          <a
            href="https://apps.apple.com/us/app/6-tool/id1658822392?itsct=apps_box_badge&amp;itscg=30200"
            style={{
              display: 'inline-block',
              overflow: 'hidden',
              width: 'auto',
              height: height || AppStoreBadges.defaultProps.height,
              maxWidth: '100%',
            }}
          >
            <img
              src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1671494400?h=7ad63daeb85062aee0f6c1b82ac2b827"
              alt="Download on the App Store"
              style={{
                width: 'auto',
                height: height || AppStoreBadges.defaultProps.height,
                objectFit: 'contain',
                maxWidth: '100%',
              }}
            />
          </a>
        </div>
        <div
          className="btn-wrap"
          style={{ maxWidth: 'calc(50% - 12px)', overflow: 'hidden' }}
        >
          <a
            href="https://play.google.com/store/apps/details?id=com.sixtool.sixtool&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            style={{ maxWidth: '100%' }}
          >
            <img
              alt="Get it on Google Play"
              src="/img/en_badge_web_generic.png"
              style={{
                height: height || AppStoreBadges.defaultProps.height,
                width: 'auto',
                objectFit: 'contain',
                maxWidth: '100%',
              }}
            />
          </a>
        </div>
      </div>
    );
  }
}

AppStoreBadges.defaultProps = {
  height: 83,
  style: { display: 'flex', flexDirection: 'row', gap: 10, flex: 1 },
};

AppStoreBadges.propTypes = {
  height: PropTypes.number,
};

export default AppStoreBadges;
