import React, { useEffect, useState } from 'react';
import { FaSyncAlt } from 'react-icons/fa';
import _ from 'lodash';
import { ReactComponent as LogoSVG } from '../../img/6tool-logo.svg';
// import Path, { Svg, Circle, G, Polygon, Line } from 'react-svg-path';
// import Svg, {
//     Circle,
//     G,
//     Path,
//     Polygon,
//     Line,
//   } from 'react-native-svg';

const LOADING_MESSAGES = [
  'Calling to the bullpen',
  `Looking for keys to the batter's box`,
  `Waiting for the tarp`,
  'Rounding third...headed for home',
  'Appealing the swing',
  'Trying to throw a strike in BP',
  'Waiting for a Molina to go first to third',
  'Letting a slow roller go foul',
  'Waiting for our 6th inning guy to get loose',
  'Shaking to our 6th best pitch',
  'Checking the radar',
  'Taking a pitch clock violation',
  'Taking our time to get the ball in to the cutoff',
  'Waiting for that Brad Lidge slider to land',
  `"Time blue"`,
  'Going to extras',
  'Checking the replay',
  'Scouring the transfer portal',
];

const LoadingMessage = ({ firstMessage = null, centered = true, style }) => {
  const [randomMessageIndex, setRandomMessageIndex] = useState(null);
  const [prevIndexes, setPrevIndexes] = useState([]);

  useEffect(() => {
    setRandomMessageIndex(Math.floor(LOADING_MESSAGES.length * Math.random()));
    const countdownInterval = setInterval(() => {
      let newRandomIndex = Math.floor(LOADING_MESSAGES.length * Math.random());

      while (prevIndexes.includes(newRandomIndex)) {
        newRandomIndex = Math.floor(LOADING_MESSAGES.length * Math.random());
      }
      if (prevIndexes.length > LOADING_MESSAGES.length / 2) {
        prevIndexes.pop();
      }
      prevIndexes.unshift(newRandomIndex);
      setRandomMessageIndex(newRandomIndex);
    }, 3500);
    return () => {
      clearInterval(countdownInterval);
    };
  }, []);

  return !isNaN(randomMessageIndex) &&
    randomMessageIndex >= 0 &&
    randomMessageIndex < LOADING_MESSAGES.length ? (
    <div
      style={
        centered === true
          ? {
              ...{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
              },
              ...style,
            }
          : style
      }
    >
      <LogoSVG className="spinning-logo" />
      {/* <FaSyncAlt
        style={{ marginRight: 4, marginTop: -3 }}
        className="spinning-icon"
      /> */}
      {`${
        _.isString(firstMessage) &&
        firstMessage.length > 0 &&
        prevIndexes.length === 0
          ? firstMessage
          : `${LOADING_MESSAGES[randomMessageIndex]}...`
      }`}
    </div>
  ) : null;
};
export default LoadingMessage;
