import {
  clearPreviewFirstQuestion,
  clearSelectFirstQuestion,
  viewQuestions,
  moveSelectedQuestionsRight,
  clearMoveSelectedQuestionsRight,
  addQuestion,
  selectFirstQuestion,
  expandFirstQuestion,
  previewFirstQuestion,
  toggleOnShowScoreboard,
  toggleOnShowField,
  clearExpandFirstQuestion,
} from '../../actions/questionActionCreator';

export const questions = [
  {
    content: `6-Tool quiz page.`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}`,
  },
  {
    dispatch: [viewQuestions],
    selector: '.main-content #question-list-main-content',
    content: `This list shows all the questions available to you to create quizzes in 6Tool. There are a lot of questions, so we have made it easy for you to filter through and find questions you may want to add to a custom quiz you create and send to your players.`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/questions`,
  },
  {
    selector: '.main-content input#text',
    content: `Search question text. You can type out search phrases to narrow the questions listed`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/questions`,
  },
  {
    selector: '.main-content .question-list-grid-wrapper .btn-group',
    content: `This toggle allows you to view all questions, only questions you created, or only questions created by 6Tool`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/questions`,
  },
  {
    selector: '.main-content .question-list-grid-wrapper .category-wrapper',
    content: `These categories allow you to easily filter through questions. You can choose the Cutoffs & Relays Category and then choose SS in Player Types to see all questions for Shortstops related to cutoffs, for example.`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/questions`,
  },
  {
    dispatch: [clearExpandFirstQuestion],
    selector:
      '.main-content .question-list-grid-wrapper .main-header-action-section .sort-buttons',
    content: `Use these buttons to sort questions by question text, by date created, or date updated.`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/questions`,
  },
  {
    dispatch: [expandFirstQuestion, clearPreviewFirstQuestion],
    selector:
      '.main-content .question-list-grid-wrapper .question-list-view-group .list-group-item:first-child',
    mutationObservables: [
      '.expanded.list-group-item',
      '.expanded-view-wrapper',
    ],
    content: `This view will show any images or videos associated with a question as well as showing the answer options (with the correct answer including a green check).`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/questions`,
  },
  {
    dispatch: [expandFirstQuestion, previewFirstQuestion],
    selector:
      '.main-content .question-list-grid-wrapper .question-list-view-group .list-group-item:first-child .item-action-buttons',
    content: `You can use these buttons to interact with this question. Clicking preview will show you what the question will look like to your players.`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/questions`,
  },
  {
    dispatch: [],
    selector: '.mobile-phone',
    mutationObservables: [
      '.modal',
      '.modal-dialog',
      '.modal-content',
      '.modal-body',
      '.mobile-phone',
      '.main-content',
    ],
    highlightedSelectors: ['.mobile-phone'],
    content: `This preview allows you to see what the question will look like. You can click Next or click off the phone preview and it will close.`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/questions`,
  },
  {
    dispatch: [
      clearPreviewFirstQuestion,
      expandFirstQuestion,
      clearSelectFirstQuestion,
    ],
    selector:
      '.main-content .question-list-grid-wrapper button#create-new-question-button',
    content: `Clicking Create New Question allows you to create your own question.`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/questions`,
  },
  {
    dispatch: [
      addQuestion,
      toggleOnShowScoreboard,
      toggleOnShowField,
      clearExpandFirstQuestion,
    ],
    selector: '.main-content .create-question-section-main',
    content: `This section allows you to create the situation and field diagram for the question. Simply click the elements of the scoreboard to edit them. On the field diagram, click a position and this question will be marked as for that position. Fill the form fields to uploadf images/videos and the question text. The Think About section is text you can show to your players after they have taken the quiz`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/questions`,
  },
  {
    dispatch: [addQuestion],
    selector: '.main-content .create-question-section-details',
    content: `Define the options for your question in this section. Optionally use the template buttons to create a question with four answers with the first answer being the correct answer, for example. You can also award a specific number of points for an answer (even if it is not the most correct answer).`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/questions`,
  },
  {
    dispatch: [addQuestion],
    selector: '.main-content .create-question-section-categorization',
    content: `Select categories for this question. This will not impact what your players see in their quiz, however, it will make it easier for you to find this question later on and easily add it to a future quiz.`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/questions`,
  },
  {
    dispatch: [addQuestion],
    selector:
      '.main-content .create-question-grid-wrapper .header button[type="button"]',
    content: `Click here to preview what the question will look like on a phone for your players.`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/questions`,
  },
  {
    dispatch: [addQuestion],
    selector:
      '.main-content .create-question-grid-wrapper .footer button[type="button"]',
    content: `Once you've finished, click Create Question to save your question.`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/questions`,
  },
  {
    dispatch: [viewQuestions, clearPreviewFirstQuestion],
    content: `Now that you've created some questions and in conjunction with the questions provided by 6Tool, let's create a quiz!`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/questions`,
  },
  {
    dispatch: [viewQuestions, selectFirstQuestion],
    selector:
      '.main-content .question-list-grid-wrapper .question-list-view-group .list-group-item:first-child input[type="checkbox"]',
    content: `Click the checkbox next to a question. Pro tip: you can also use the categories to filter your questions and then once you have only the questions you want in a quiz showing, use the Select All button to select questions in bulk.`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/questions`,
  },
  {
    dispatch: [
      clearPreviewFirstQuestion,
      selectFirstQuestion,
      clearMoveSelectedQuestionsRight,
    ],
    selector:
      '.main-content .question-list-grid-wrapper .question-list-arrow-move-right',
    content: `With questions selected this button will move them into the list on the right-hand side.`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/questions`,
  },
  {
    dispatch: [moveSelectedQuestionsRight],
    selector:
      '.main-content .question-list-grid-wrapper #question-list-quiz-create-column',
    content: `Here you can give a name to the quiz you are creating. If you have more than one question, you will be able to re-order them.`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/questions`,
  },
  {
    dispatch: [clearMoveSelectedQuestionsRight],
    selector:
      '.main-content .question-list-grid-wrapper button#create-new-quiz-button',
    content: `Clicking Create Quiz will make a quiz you can then send to your players!`,
    url: `/teamdetails/${window.location.pathname.split('/')[2]}/questions`,
  },
];
