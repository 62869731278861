import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CLink,
  CRow,
  CTooltip,
} from '@coreui/react';
import './EventLeaderboardStyle.scss';
import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { db, functions, storage } from '../../../../index';
import { Loading } from '../../../../r-reusable';
import _ from 'lodash';
import { Container } from 'react-bootstrap';
// import ExamPlayerResults from '../examDetails/ExamPlayerResults';
import { format } from 'date-fns';
import HeaderWithBackButton from '../../../../r-components/HeaderWithBackButton/HeaderWithBackButton';
import { FaCode } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import EventsWrapper from '../../../../pages/component/EventsWrapper';
import EventLeaderboardViewOnly, {
  EVENT_LEADERBOARD_TYPE,
} from './EventLeaderboardViewOnly';
import EventLeaderboardEmbedCodeModal from './EventLeaderboardEmbedCodeModal';

const EventLeaderboard = ({ match }) => {
  const history = useHistory();
  const isMounted = useRef(false);
  const [eventID, setEventID] = useState('a');
  const [loading, setLoading] = useState(false);
  const [
    showEventLeaderboardEmbedCodeModal,
    setShowEventLeaderboardEmbedCodeModal,
  ] = useState(false);
  useEffect(() => {
    setEventID(match.params.id || 'a');
  }, [match]);
  return (
    <EventsWrapper>
      <Container className="space-1">
        <CRow>
          <CCol xs="12">
            {loading ? (
              <Loading />
            ) : (
              <>
                <CCard>
                  <HeaderWithBackButton
                    rightSide={
                      <>
                        <CLink
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 6,
                          }}
                          className="noprint"
                          onClick={() => {
                            setShowEventLeaderboardEmbedCodeModal(true);
                          }}
                        >
                          <FaCode />
                          <span style={{ marginTop: 3, fontSize: '0.8em' }}>
                            Embed Code
                          </span>
                        </CLink>
                      </>
                    }
                  ></HeaderWithBackButton>

                  <CCol
                    className={'playerResults'}
                    style={{
                      minHeight: `calc(100vh - 500px)`,
                      flex: 1,
                    }}
                  >
                    <EventLeaderboardViewOnly eventID={eventID} />
                  </CCol>
                </CCard>
              </>
            )}
          </CCol>
        </CRow>
      </Container>
      <EventLeaderboardEmbedCodeModal
        eventID={eventID}
        show={showEventLeaderboardEmbedCodeModal}
        onClose={() => {
          setShowEventLeaderboardEmbedCodeModal(false);
        }}
      />
    </EventsWrapper>
  );
};

export default EventLeaderboard;
