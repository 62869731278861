import {
  CButton,
  CButtonGroup,
  CInput,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react';
import _, { isFunction, isString, isObject } from 'lodash';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESS_ACL,
  ACCESS_ACTIONS,
  ACCESS_LEVELS,
  canDoAction,
} from '../../helpers/access';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import {
  SUBSCRIPTION_LABEL_BY_TIER,
  SUBSCRIPTION_TIER,
  PRICING_TIERS,
  SUBSCRIPTION_PAYMENT_TERMS,
  STRIPE,
  EMAIL_ADDRESSES,
} from '../../config';
import './PricingTable.scss';
import {
  FaCheckCircle,
  FaMinus,
  FaMinusSquare,
  FaPlus,
  FaTimesCircle,
} from 'react-icons/fa';
import useAccountSelectors from '../../hooks/useAccountSelectors';
import FlexSpacer from '../FlexSpacer/FlexSpacer';
import { toast } from 'react-toastify';
import LoadingMessage from '../LoadingMessage/LoadingMessage';
import { functions } from '../..';
import { isArray } from 'lodash';
const PricingTable = ({
  loading,
  actions,
  loadingSubscriptionLinkForTierTerm,
  setLoadingSubscriptionLinkForTierTerm,
  enableAllTiers,
  preselectedTeams = 1,
  recommendedTiers,
  newSubscriber = false,
  // onToggle,
  // size = 60,
  // onColor = '#f6d30d',
  // offColor = '#162630',
}) => {
  const userEmail = useSelector(
    (state) => state?.DataOwnerState?.data?.[0]?.email || ''
  );
  const userName = useSelector(
    (state) => state?.DataOwnerState?.data?.[0]?.name || ''
  );
  const [confirmNonRecommendedTier, setConfirmNonRecommendedTier] =
    useState(false);
  const {
    subscription_quantity,
    tier,
    subscription_term,
    orgCounts,
    numTeams,
    type,
    showSubscriptionModal,
    customer_id,
    subscription_id,
    subscription_item_id,
    organizationId,
    ownerName,
    ownerEmail,
  } = useAccountSelectors();
  const [selectedNumberOfTeams, setSelectedNumberOfTeams] = useState(
    preselectedTeams > 1 ? preselectedTeams : subscription_quantity
  );

  const [paymentFrequency, setPaymentFrequency] = useState(subscription_term);
  const selectedTermStyle = {
    backgroundColor: '#f6d30d',
    cursor: 'default',
    borderRadius: '.4em',
    padding: '8px 32px',
  };

  const isRedirecting =
    isObject(loadingSubscriptionLinkForTierTerm) &&
    isString(loadingSubscriptionLinkForTierTerm.tier) &&
    isString(loadingSubscriptionLinkForTierTerm.term);

  const redirectToCheckoutPage = async ({
    _quantity,
    _tier,
    _paymentFrequency,
  }) => {
    setLoadingSubscriptionLinkForTierTerm({
      tier: _tier,
      term: _paymentFrequency,
    });
    const getCheckoutLinkCallable = functions.httpsCallable(
      'getCheckoutLinkCallable'
    );

    try {
      const price_id = STRIPE.prices[_tier][_paymentFrequency];
      const getCheckoutLinkResponse = await getCheckoutLinkCallable({
        customer_email: userEmail,
        customer_name: userName,
        price: price_id,
        customer_id,
        subscriptionTerm: subscription_term,
        // success_url,
        cancel_url: window.location.href,
        quantity: _quantity,
        client_reference_id: organizationId,
        return_url: window.location.href,
      });
      if (
        isString(getCheckoutLinkResponse?.data?.session.url) &&
        getCheckoutLinkResponse.data.session.url.length > 0
      ) {
        // if (
        //   isString(getCheckoutLinkResponse.data[price_id]) &&
        //   getCheckoutLinkResponse.data[price_id].length > 0
        // ) {
        window.location.href = getCheckoutLinkResponse.data.session.url;
        // } else {
        //   toast(
        //     `Something went wrong. Please contact ${EMAIL_ADDRESSES.SALES}`,
        //     {
        //       type: 'warning',
        //     }
        //   );
        // }
      } else {
        toast(`Something went wrong. Please contact ${EMAIL_ADDRESSES.SALES}`, {
          type: 'warning',
        });
      }
    } catch (error) {
      toast(`Something went wrong. Please contact ${EMAIL_ADDRESSES.SALES}`, {
        type: 'warning',
      });
    }
  };

  const handleClickNewSubscription = async ({
    quantity: _quantity,
    tier: _tier,
    paymentFrequency: _paymentFrequency,
  }) => {
    if (
      isArray(recommendedTiers) &&
      recommendedTiers.length > 0 &&
      !recommendedTiers.map((t) => t.key).includes(_tier)
    ) {
      setConfirmNonRecommendedTier(_tier);
    } else {
      redirectToCheckoutPage({
        _quantity: _tier === SUBSCRIPTION_TIER.FREE.key ? 1 : _quantity,
        _tier,
        _paymentFrequency,
      });
    }
  };

  const handleClickToChangeSubscription = async ({
    quantity: _quantity,
    tier: _tier,
    paymentFrequency: _paymentFrequency,
  }) => {
    setLoadingSubscriptionLinkForTierTerm({
      tier: _tier,
      term: _paymentFrequency,
    });
    let canSubscribe = true;
    if (numTeams > _quantity) {
      toast(
        `You have too many active teams for this selected quantity. Please contact ${EMAIL_ADDRESSES.SALES}`,
        { type: 'warning' }
      );
      canSubscribe = false;
    }
    const excessCoaches =
      orgCounts.countCoaches -
      ACCESS_ACL[_tier][ACCESS_ACTIONS.CREATE_COACH].threshold;
    if (excessCoaches === 0) {
      toast(
        `You have too many coaches on one or more teams for this subscription level. Please delete ${excessCoaches} coach${
          excessCoaches === 1 ? '' : 'es'
        } and try again.`,
        { type: 'warning' }
      );
      canSubscribe = false;
    }
    const excessPlayers =
      orgCounts.countPlayers -
      ACCESS_ACL[_tier][ACCESS_ACTIONS.CREATE_PLAYER].threshold;
    if (excessPlayers === 0) {
      toast(
        `You have too many players on one or more teams for this subscription level. Please delete ${excessPlayers} player${
          excessPlayers === 1 ? '' : 's'
        } and try again.`,
        { type: 'warning' }
      );
      canSubscribe = false;
    }
    if (canSubscribe) {
      const getCustomerPortalLinkCallable = functions.httpsCallable(
        'getCustomerPortalLinkCallable'
      );
      try {
        const price_id = STRIPE.prices[_tier][_paymentFrequency];
        const getCustomerPortalLinkResponse =
          await getCustomerPortalLinkCallable({
            organizationId,
            customer_id,
            subscription_id,
            subscription_item_id,
            quantity: selectedNumberOfTeams,
            price_ids: [price_id],
            return_url: window.location.href,
            customer_email: ownerEmail,
            customer_name: ownerEmail,
            subscription_term,
          });
        if (isObject(getCustomerPortalLinkResponse?.data)) {
          if (
            isString(getCustomerPortalLinkResponse.data[price_id]) &&
            getCustomerPortalLinkResponse.data[price_id].length > 0
          ) {
            window.location.href = getCustomerPortalLinkResponse.data[price_id];
          } else {
            toast(
              `Something went wrong. Please contact ${EMAIL_ADDRESSES.SALES}`,
              {
                type: 'warning',
              }
            );
          }
        } else {
          toast(
            `Something went wrong. Please contact ${EMAIL_ADDRESSES.SALES}`,
            {
              type: 'warning',
            }
          );
        }
      } catch (error) {
        toast(`Something went wrong. Please contact ${EMAIL_ADDRESSES.SALES}`, {
          type: 'warning',
        });
      }
    } else {
      setLoadingSubscriptionLinkForTierTerm(null);
    }
  };

  const lackingActions = actions.filter(
    (a) =>
      isString(a.desire) &&
      a.desire.length > 0 &&
      !canDoAction({
        action: a.action,
        type: [ACCESS_LEVELS.OWNER.key],
        tier: confirmNonRecommendedTier,
        thresholdCount: a.thresholdCount,
      }).canDo
  );

  return (
    <>
      {loading === true && (
        <div style={{ position: 'absolute', top: '50%', width: '100%' }}>
          <LoadingMessage
            centered={true}
            firstMessage={'Loading Subscription Options...'}
          />
        </div>
      )}
      <div
        className="subscription-pricing-table-fader"
        style={loading === true ? { opacity: 0 } : {}}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CButtonGroup
            style={{
              background: 'hsl(0,0%,90%)',
              borderRadius: '.4em',
              padding: 2,
              margin: '14px',
            }}
          >
            <CButton
              style={
                paymentFrequency === SUBSCRIPTION_PAYMENT_TERMS.MONTHLY.label
                  ? selectedTermStyle
                  : { padding: '8px 32px' }
              }
              onClick={() => {
                if (!isRedirecting) {
                  setPaymentFrequency(SUBSCRIPTION_PAYMENT_TERMS.MONTHLY.label);
                }
              }}
            >
              Monthly
            </CButton>
            <CButton
              color="black"
              style={
                paymentFrequency === SUBSCRIPTION_PAYMENT_TERMS.ANNUAL.label
                  ? selectedTermStyle
                  : { padding: '8px 32px' }
              }
              onClick={() => {
                setPaymentFrequency(SUBSCRIPTION_PAYMENT_TERMS.ANNUAL.label);
              }}
            >
              Yearly
            </CButton>
          </CButtonGroup>
        </div>
        <div className="subscription-pricing-table-wrapper">
          {Object.keys(ACCESS_ACL)
            // .filter(
            //   (t) =>
            //     tier === SUBSCRIPTION_TIER.FREE.key ||
            //     t !== SUBSCRIPTION_TIER.FREE.key
            // )
            .map((t, tIdx) => (
              <PricingLevel
                key={tIdx}
                tier={t}
                actions={actions}
                tierCompare={
                  tier === t
                    ? 0
                    : t === SUBSCRIPTION_TIER.FREE.key
                    ? -1
                    : t === SUBSCRIPTION_TIER.PREMIUM.key
                    ? 1
                    : tier === SUBSCRIPTION_TIER.FREE.key
                    ? 1
                    : -1
                }
                termCompare={subscription_term === paymentFrequency}
                quantityCompare={selectedNumberOfTeams - subscription_quantity}
                userEmail={userEmail}
                paymentFrequency={paymentFrequency}
                selectedNumberOfTeams={selectedNumberOfTeams}
                setSelectedNumberOfTeams={setSelectedNumberOfTeams}
                handleClickToChangeSubscription={
                  handleClickToChangeSubscription
                }
                handleClickNewSubscription={handleClickNewSubscription}
                disabled={
                  !enableAllTiers &&
                  !newSubscriber &&
                  (t === SUBSCRIPTION_TIER.FREE.key ||
                    (tier === SUBSCRIPTION_TIER.PREMIUM.key &&
                      t === SUBSCRIPTION_TIER.REGULAR.key))
                }
                loadingSubscriptionLinkForTierTerm={
                  loadingSubscriptionLinkForTierTerm
                }
                isRedirecting={isRedirecting}
                isRecommended={t === recommendedTiers?.[0]?.key}
                newSubscriber={newSubscriber}
              />
            ))}
        </div>
      </div>
      <ConfirmModal
        show={isString(confirmNonRecommendedTier)}
        alignment="center"
        onClose={() => {
          setConfirmNonRecommendedTier(null);
        }}
        title={`6Tool ${SUBSCRIPTION_LABEL_BY_TIER(
          confirmNonRecommendedTier
        )} Not Recommended`}
        message={`If you subscribe to 6Tool ${SUBSCRIPTION_LABEL_BY_TIER(
          confirmNonRecommendedTier
        )}, you will not be able to perform the following ${
          lackingActions.length === 1 ? '' : `${lackingActions.length} `
        }action${
          lackingActions.length === 1 ? '' : 's'
        } you indicated you want:`}
        onClickCancel={() => {
          setConfirmNonRecommendedTier(null);
        }}
        onClickConfirm={() => {
          setConfirmNonRecommendedTier(null);
          redirectToCheckoutPage({
            _quantity:
              confirmNonRecommendedTier === SUBSCRIPTION_TIER.FREE.key
                ? 1
                : selectedNumberOfTeams,
            _tier: confirmNonRecommendedTier,
            _paymentFrequency: paymentFrequency,
          });
        }}
        buttons={[]}
        cancelButtonText={'Go Back'}
        okButtonText={'Start Without All Abilities'}
        // submitting={ }
        // submitMessage={ }
        // children={ }
        size={'lg'}
        className="subscription-pricing-modal-not-recommended-tier"
      >
        <div className="subscription-pricing-modal-not-recommended-tier-wrapper">
          <ul className="subscription-pricing-modal-not-recommended-tier-list">
            {lackingActions.map((a, aIdx) => (
              <li key={aIdx}>{a.desire}</li>
            ))}
          </ul>
        </div>
      </ConfirmModal>
    </>
  );
};

const PricingLevel = ({
  tier,
  actions: highlightedActions,
  tierCompare,
  termCompare,
  quantityCompare,
  paymentFrequency,
  userEmail,
  selectedNumberOfTeams,
  setSelectedNumberOfTeams,
  handleClickToChangeSubscription,
  handleClickNewSubscription,
  disabled,
  loadingSubscriptionLinkForTierTerm,
  isRedirecting,
  isRecommended,
  newSubscriber,
}) => {
  // const { showGoingExternalToStripeModal } = useSubscriptionModal();
  const dollarFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const costArray =
    PRICING_TIERS[
      tier === SUBSCRIPTION_TIER.PREMIUM.key
        ? 2
        : tier === SUBSCRIPTION_TIER.REGULAR.key
        ? 1
        : 0
    ][
      paymentFrequency === SUBSCRIPTION_PAYMENT_TERMS.MONTHLY.label
        ? SUBSCRIPTION_PAYMENT_TERMS.MONTHLY.costLabel
        : SUBSCRIPTION_PAYMENT_TERMS.ANNUAL.costLabel
    ];
  const unitPrice =
    costArray[Math.min(costArray.length - 1, selectedNumberOfTeams)];
  const singlePrice = costArray?.[1];

  const needsMoreTeams = (
    (highlightedActions || []).map((a) => a.action) || []
  ).includes(ACCESS_ACTIONS.CREATE_TEAM);
  let threshold;
  let thresholdCount;
  let wouldHaveAccess;
  if (needsMoreTeams) {
    const actionMap = (highlightedActions || []).find(
      (a) => a.action === ACCESS_ACTIONS.CREATE_TEAM
    );
    if (actionMap) {
      threshold = actionMap.threshold;
      thresholdCount = actionMap.thresholdCount;
      wouldHaveAccess = thresholdCount <= selectedNumberOfTeams;
    }
  }
  return (
    <div
      className={`subscription-pricing-table-item${
        disabled === true ? ' disabled' : ''
      }${isRecommended === true ? ' recommended' : ` not-recommended`}`}
    >
      {isRecommended && (
        <div className="subscription-pricing-table-recommended">
          Recommended
        </div>
      )}
      <div className="subscription-pricing-table-title">
        6Tool {SUBSCRIPTION_LABEL_BY_TIER(tier)}
      </div>
      <div className="subscription-pricing-table-start-at">
        {tier !== SUBSCRIPTION_TIER.FREE.key && selectedNumberOfTeams === 1
          ? 'Starting at'
          : ' '}
      </div>
      <div className="subscription-pricing-table-price-wrapper">
        <div className="subscription-pricing-table-price-amount">
          {tier === SUBSCRIPTION_TIER.FREE.key
            ? PRICING_TIERS[
                tier === SUBSCRIPTION_TIER.PREMIUM.key
                  ? 2
                  : tier === SUBSCRIPTION_TIER.REGULAR.key
                  ? 1
                  : 0
              ][
                paymentFrequency === SUBSCRIPTION_PAYMENT_TERMS.MONTHLY.label
                  ? SUBSCRIPTION_PAYMENT_TERMS.MONTHLY.label
                  : SUBSCRIPTION_PAYMENT_TERMS.ANNUAL.label
              ]
            : dollarFormat.format(unitPrice * selectedNumberOfTeams)}
        </div>
        <div className="subscription-pricing-table-price-term">
          {tier !== SUBSCRIPTION_TIER.FREE.key && (
            <>
              per
              <br />
              {paymentFrequency === SUBSCRIPTION_PAYMENT_TERMS.MONTHLY.label
                ? 'month'
                : 'year'}
            </>
          )}
        </div>
        <div
          className="subscription-pricing-table-price-term"
          style={{ borderLeft: '1px solid grey' }}
        >
          {tier !== SUBSCRIPTION_TIER.FREE.key && (
            <>
              {selectedNumberOfTeams === 1
                ? 'per'
                : `for ${selectedNumberOfTeams}`}
              <br />
              team{selectedNumberOfTeams === 1 ? '' : 's'}
            </>
          )}
        </div>
      </div>
      <div className="subscription-pricing-table-num-teams-wrapper">
        <div className="subscription-pricing-table-num-teams-container">
          {tier !== SUBSCRIPTION_TIER.FREE.key && (
            <>
              <div
                className={`subscription-pricing-table-num-teams-button-group${
                  needsMoreTeams &&
                  disabled !== true &&
                  wouldHaveAccess !== true
                    ? ' no-access'
                    : ''
                }`}
              >
                <CButton
                  disabled={disabled}
                  className="subscription-pricing-table-num-teams-button"
                >
                  <FaMinus
                    color={
                      needsMoreTeams &&
                      disabled !== true &&
                      wouldHaveAccess !== true
                        ? 'red'
                        : 'grey'
                    }
                    onClick={() => {
                      if (!isRedirecting) {
                        setSelectedNumberOfTeams(
                          Math.max(1, selectedNumberOfTeams - 1)
                        );
                      }
                    }}
                  />
                </CButton>
                <div className="subscription-pricing-table-num-teams-input">
                  {`${selectedNumberOfTeams} Team${
                    selectedNumberOfTeams === 1 ? '' : 's'
                  }`}
                </div>
                <CButton
                  disabled={disabled}
                  className="subscription-pricing-table-num-teams-button"
                >
                  <FaPlus
                    color={
                      needsMoreTeams &&
                      disabled !== true &&
                      wouldHaveAccess !== true
                        ? 'red'
                        : 'grey'
                    }
                    onClick={() => {
                      if (!isRedirecting) {
                        if (selectedNumberOfTeams >= 100) {
                          toast(
                            `Please contact ${EMAIL_ADDRESSES.SALES} for enterprise pricing`,
                            { type: 'success' }
                          );
                        } else {
                          setSelectedNumberOfTeams(selectedNumberOfTeams + 1);
                        }
                      }
                    }}
                  />
                </CButton>
              </div>
              <div className="subscription-pricint-table-num-teams-team-label">
                {unitPrice < singlePrice || newSubscriber === true ? (
                  <span className="subscription-pricint-table-num-teams-unit-price-label">{` at ${dollarFormat.format(
                    unitPrice
                  )} each`}</span>
                ) : (
                  ''
                )}
              </div>
              <FlexSpacer />
            </>
          )}
        </div>
      </div>
      {isRedirecting ? (
        <div className="subscription-pricing-table-subscripe-button-loader">
          Redirecting...
        </div>
      ) : (
        <CButton
          color="primary"
          variant={
            (tierCompare === 0 && termCompare && quantityCompare === 0) ||
            (tierCompare === 0 && disabled)
              ? 'outline'
              : null
          }
          className={`subscription-pricing-table-subscribe-button${
            tierCompare === 0 ? ' current-plan' : ''
          }`}
          disabled={
            disabled === true ||
            (tierCompare === 0 &&
              termCompare &&
              quantityCompare === 0 &&
              !newSubscriber) ||
            isRedirecting
          }
          onClick={() => {
            if (newSubscriber === true) {
              handleClickNewSubscription({
                quantity: selectedNumberOfTeams,
                tier,
                paymentFrequency,
              });
            } else {
              handleClickToChangeSubscription({
                quantity: selectedNumberOfTeams,
                tier,
                paymentFrequency,
              });
            }
          }}
        >
          {newSubscriber === true && tier === SUBSCRIPTION_TIER.FREE.key
            ? `Start with ${SUBSCRIPTION_LABEL_BY_TIER(tier)}`
            : (tierCompare === 0 && termCompare && quantityCompare == 0) ||
              (tierCompare === 0 && disabled === true)
            ? 'Current Plan'
            : tierCompare === 0 && !termCompare
            ? `Switch to ${paymentFrequency
                .split(' ')
                .map((word) => {
                  return (
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                  );
                })
                .join(' ')}`
            : tierCompare === 0 && quantityCompare > 0
            ? `Add ${quantityCompare} More Team${
                quantityCompare === 1 ? '' : 's'
              }`
            : tierCompare === 0 && quantityCompare < 0
            ? `Remove ${Math.abs(quantityCompare)} Team${
                Math.abs(quantityCompare) === 1 ? '' : 's'
              }`
            : tierCompare < 0
            ? 'Downgrade'
            : newSubscriber === true
            ? `Subscribe to ${SUBSCRIPTION_LABEL_BY_TIER(tier)}`
            : 'Upgrade'}
        </CButton>
      )}

      <div className="subscription-pricing-table-features-container">
        <div className="subscription-pricing-table-features-header">
          This includes:
        </div>
        <div className="subscription-pricing-table-features-list-wrapper">
          {Object.entries(ACCESS_ACL[tier])
            .filter(([key, value]) => value.pricingTableOrder > 0)
            .sort((a, b) => a[1].pricingTableOrder - b[1].pricingTableOrder)
            .map(([key, value]) => {
              const isHighlighted = (
                (highlightedActions || []).map((a) => a.action) || []
              ).includes(key);
              let threshold;
              let thresholdCount;
              let wouldHaveAccess;
              if (isHighlighted) {
                const actionMap = (highlightedActions || []).find(
                  (a) => a.action === key
                );
                if (actionMap) {
                  threshold = actionMap.threshold;
                  thresholdCount = actionMap.thresholdCount;
                  wouldHaveAccess =
                    thresholdCount <= value.threshold || value.threshold === -1;
                }
              }

              return (
                <div
                  key={key}
                  className={`subscription-pricing-table-features-list-item${
                    isHighlighted
                      ? ` access-highlighted${
                          wouldHaveAccess === true && disabled !== true
                            ? ' would-have-access'
                            : ''
                        }`
                      : ''
                  }`}
                >
                  <div className="subscription-pricing-table-features-list-item-check">
                    <FaCheckCircle
                      size={14}
                      color={
                        isHighlighted && wouldHaveAccess && !disabled
                          ? 'green'
                          : isHighlighted && wouldHaveAccess !== true
                          ? 'red'
                          : null
                      }
                    />
                  </div>
                  <div
                    className={`subscription-pricing-table-features-list-item-text${
                      isHighlighted && wouldHaveAccess !== true
                        ? ' access-denied'
                        : isHighlighted &&
                          wouldHaveAccess === true &&
                          disabled !== true
                        ? ' would-have-access'
                        : ''
                    }`}
                  >
                    {value.description}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
export default PricingTable;
