import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CLabel,
  CLink,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
  CTooltip,
} from '@coreui/react';
import './team.scss';
import React, { useEffect, useRef, useState } from 'react';
import { FaBaseballBall, FaClipboard, FaUserMinus } from 'react-icons/fa';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { FiAlertCircle } from 'react-icons/fi';
import { FaEdit, FaInfoCircle, FaUserPlus } from 'react-icons/fa';
import { db, functions } from '../../../index';
import { useHistory } from 'react-router-dom';
import { TABS } from '../../../AppTabs';
import { orgAndTeamData } from '../../../helpers/data';
import OrgTeamImageHeader from '../../../r-components/OrgTeamImageHeader/OrgTeamImageHeader';
import TeamCodeExplanationModal from '../teams/TeamCodeExplanationModal';
import { formatPhoneNumber } from '../../../helpers/utils';
import { format } from 'date-fns';
import { handleError } from '../../../helpers/errors';
import { toast } from 'react-toastify';
import CoachWrapper from '../../../pages/component/CoachWrapper';
import { GiWhistle } from 'react-icons/gi';
import { Loading } from '../../../r-reusable';
import LoadingMessage from '../../../r-components/LoadingMessage/LoadingMessage';

const TeamCoachRequests = () => {
  const coachRequests = useSelector(
    (state) => state.DataCoachState?.teamRequests || []
  );
  const orgData = useSelector((state) => state.DataOrganizationState);
  const teamData = useSelector((state) => state.DataTeamState);
  const [tableFields, setTableFields] = useState([]);
  const [orgTeamData, setOrgTeamData] = useState({});
  const [approvingCoach, setApprovingCoach] = useState(null);
  const [loadingApproval, setLoadingApproval] = useState(false);
  const [isSubmittingRequestResponse, setIsSubmittingRequestResponse] =
    useState(null);
  useEffect(() => {
    let orgTeam = orgAndTeamData({ orgData, teamData });
    setOrgTeamData(orgTeam);
    let tableFields = [
      { key: 'name', label: 'Name', _classes: 'font-weight-bold' },
    ];
    if (orgTeam.hasTeam !== true) {
      tableFields.push({ key: 'team', label: 'Team(s)' });
    }
    tableFields = tableFields.concat([
      { key: 'email', label: 'Email' },
      { key: 'phoneNumber', label: 'Phone' },
      { key: 'created_at', label: 'Request Time' },
      { key: 'show_details', label: '', sorter: false },
    ]);
    setTableFields(tableFields);
  }, [orgData, teamData]);

  const approveCoachWithRole = async ({ coach, role }) => {
    setIsSubmittingRequestResponse(coach.user_id);
    setLoadingApproval(true);
    try {
      const approveTeamRequestsByUserIdAndTeamIdPairsCallable =
        functions.httpsCallable(
          'approveTeamRequestsByUserIdAndTeamIdPairsCallable'
        );
      let result = await approveTeamRequestsByUserIdAndTeamIdPairsCallable({
        requests: [_.pick(coach, ['user_id', 'team_id', 'organization_id'])],
        type: role,
      });
      toast('Request approved. Coach is being added to the team.', {
        type: 'success',
      });
    } catch (error) {
      handleError({
        error,
        toast: 'Error approving join request',
        internal: 'TeamCoachRequests - declining request',
      });
    } finally {
      setLoadingApproval(false);
      setIsSubmittingRequestResponse(null);
    }
  };
  return (
    <CoachWrapper className="team-coach-requests-page">
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader className="d-flex justify-content-between">
              <OrgTeamImageHeader
                orgTeamData={orgTeamData}
                title="Coach Join Requests"
                leftView={
                  <CTooltip content="These are users who have requested to be part of your team or organization. If you recognize them as coaches on your team, hover over their row in the table and approve their request. Until you approve these requests, they are not on your team, nor are them able to receive any quiz content you create.">
                    <div>
                      <FaInfoCircle /> What is this?
                    </div>
                  </CTooltip>
                }
                rightView={
                  <CLink to={TABS.OWNER_COACHES.path}>
                    <GiWhistle />
                    View Coaches
                  </CLink>
                }
              />
            </CCardHeader>

            <CCardBody>
              <CDataTable
                addTableClasses="team-coach-requests-table"
                striped
                items={coachRequests}
                itemsPerPageSelect={true}
                itemsPerPage={50}
                hover
                tableFilter={true}
                sorter
                sorterValue={{ column: 'name', asc: true }}
                fields={tableFields}
                noItemsViewSlot={
                  <p className="d-flex align-content-center justify-content-center pt-4 pb-4">
                    <FiAlertCircle size="30" color="#e55353" />
                    <span className="pl-2 font-xl">No coaches added.</span>
                  </p>
                }
                scopedSlots={{
                  name: (coach) => (
                    <td className="font-weight-bold">{coach.name}</td>
                  ),
                  team: (coach) => (
                    <td>
                      {_.isObject(teamData.map) &&
                      _.isObject(teamData.map[coach.team_id]) &&
                      _.isString(teamData?.map[coach.team_id].name)
                        ? teamData.map[coach.team_id].name
                        : ''}
                    </td>
                  ),
                  position: (coach) => (
                    <td>
                      {(coach._positions || [])
                        .map((p) =>
                          _.isString(p) ? p : _.isObject(p) ? p.label : ''
                        )
                        .join(', ')}
                    </td>
                  ),
                  email: (coach) => <td>{coach.email}</td>,
                  phoneNumber: (coach) => (
                    <td>{formatPhoneNumber(coach.phoneNumber)}</td>
                  ),
                  created_at: (coach) => (
                    <td>
                      {coach?.created_at
                        ? format(
                            new Date(
                              coach.created_at?.seconds * 1000 ||
                                coach.created_at
                            ),
                            'MMM dd'
                          )
                        : null}
                    </td>
                  ),
                  show_details: (coach) => (
                    <td className="text-right">
                      {isSubmittingRequestResponse === coach.user_id ? (
                        <LoadingMessage
                          centered={false}
                          firstMessage="Submitting response..."
                        />
                      ) : (
                        <>
                          <CButton
                            className="team-coach-requests-action-buttons requests-action-buttons team-coach-requests-action-buttons-first"
                            variant="outline"
                            size="sm"
                            color="success"
                            onClick={async () => {
                              setApprovingCoach(coach);
                            }}
                          >
                            <FaUserPlus size={20} style={{ marginRight: 4 }} />{' '}
                            Approve request
                          </CButton>
                          <CButton
                            className="team-coach-requests-action-buttons requests-action-buttons"
                            variant="outline"
                            color="danger"
                            size="sm"
                            onClick={async () => {
                              setIsSubmittingRequestResponse(coach.user_id);
                              try {
                                const declineTeamRequestsByUserIdAndTeamIdPairsCallable =
                                  functions.httpsCallable(
                                    'declineTeamRequestsByUserIdAndTeamIdPairsCallable'
                                  );
                                let result =
                                  await declineTeamRequestsByUserIdAndTeamIdPairsCallable(
                                    {
                                      type: 'coach',
                                      requests: [
                                        _.pick(coach, [
                                          'user_id',
                                          'team_id',
                                          'organization_id',
                                        ]),
                                      ],
                                    }
                                  );
                                toast(
                                  'Request declined. Coach will not have access to the team.',
                                  { type: 'success' }
                                );
                              } catch (error) {
                                handleError({
                                  error,
                                  toast: 'Error declining join request',
                                  internal:
                                    'TeamCoachRequests - declining request',
                                });
                              } finally {
                                setIsSubmittingRequestResponse(null);
                              }
                            }}
                          >
                            <FaUserMinus size={20} style={{ marginRight: 4 }} />{' '}
                            Decline request
                          </CButton>
                        </>
                      )}
                    </td>
                  ),
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CModal show={!!approvingCoach} size="lg">
        <CModalHeader>
          <h2>Approve Coach Request</h2>
        </CModalHeader>
        <CModalBody>
          Is {approvingCoach?.name || 'this coach'} a head coach or an assistant
          coach?
        </CModalBody>
        <CModalFooter>
          {loadingApproval ? (
            <Loading />
          ) : (
            <>
              <CButton
                variant="outline"
                color="danger"
                onClick={() => {
                  setApprovingCoach(null);
                }}
              >
                Cancel
              </CButton>
              <div style={{ flex: 1 }}></div>
              <CButton
                color="success"
                onClick={async () => {
                  await approveCoachWithRole({
                    coach: approvingCoach,
                    role: 'assistant',
                  });
                  setApprovingCoach(null);
                }}
              >
                <FaClipboard
                  size={24}
                  style={{ marginRight: 4, marginTop: -4 }}
                />
                Approve as Assistant Coach
              </CButton>
              <CButton
                color="success"
                onClick={async () => {
                  approveCoachWithRole({
                    coach: approvingCoach,
                    role: 'coach',
                  });
                  setApprovingCoach(null);
                }}
              >
                <GiWhistle
                  size={30}
                  style={{ marginRight: 4, marginTop: -4 }}
                />
                Approve as Head Coach
              </CButton>
            </>
          )}
        </CModalFooter>
      </CModal>
    </CoachWrapper>
  );
};

export default TeamCoachRequests;
