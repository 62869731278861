import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CImg,
  CRow,
  CSpinner,
  CTooltip,
} from '@coreui/react';
import './quizzes.scss';
import { format } from 'date-fns';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { addQuiz } from '../../../actions/quizActionCreator';
import { db, functions } from '../../../index';
import QuizActions from '../../../r-components/QuizActions/QuizActions';
import QuizUserResponse from './QuizUserResponse';
import QuizWrapper from '../../../pages/component/QuizWrapper';
import { TABS } from '../../../AppTabs';
import {
  FaCheck,
  FaCheckSquare,
  FaEnvelopeOpenText,
  FaListOl,
  FaMobile,
  FaRegClock,
} from 'react-icons/fa';
import { orgAndTeamData } from '../../../helpers/data';
import OrgTeamImageHeader from '../../../r-components/OrgTeamImageHeader/OrgTeamImageHeader';
import TeamCellInDataTable from '../../../r-components/TeamCellInDataTable/TeamCellInDataTable';
import { Button } from 'react-bootstrap';
import AutoQuizButtons from '../autoQuizButtons/AutoQuizButtons';
import { InviteUsersToExamModal } from '../../../r-components';
import { isArray } from 'lodash';
import { INVITE_USERS_TO_EXAM_MODE } from '../../../r-components/InviteUsersToExamModal/InviteUsersToExamModal';

// With implementation of 6tool, UI presents exams as quizzes. The data model contains both exams and quizzes
// having migrated over from Gamer-Metrics
const QuizzesScheduledPage = () => {
  const history = useHistory();
  const queryPage = useLocation().search.match(/page=([0-9]+)/, '');
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const orgData = useSelector((state) => state.DataOrganizationState);
  const teamData = useSelector((state) => state.DataTeamState);
  const quizzesRaw = useSelector(
    (state) => state.DataQuizState?.scheduled || []
  );
  const [quizzes, setQuizzes] = useState([]);
  const [orgTeamData, setOrgTeamData] = useState({});
  const [tableFields, setTableFields] = useState([]);

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(currentPage);
  const [editingScheduledQuiz, setEditingScheduledQuiz] = useState(null);

  useEffect(() => {
    currentPage !== page && setPage(currentPage);
  }, [currentPage, page]);

  useEffect(() => {
    if (_.isArray(quizzesRaw) && quizzesRaw.length > 0) {
      let newQuizzes = quizzesRaw
        .map((q) => {
          return {
            ...q,
            ...{
              createdFormatted: format(
                new Date(q?.created_at?.seconds * 1000 || q?.created_at),
                'MMM d'
              ),
              _created: q?.created_at?.seconds || q?.created_at || 0,
              mon: (q?.days || []).includes(1),
              tue: (q?.days || []).includes(2),
              wed: (q?.days || []).includes(3),
              thur: (q?.days || []).includes(4),
              fri: (q?.days || []).includes(5),
              sat: (q?.days || []).includes(6),
              sun: (q?.days || []).includes(0),
            },
          };
        })
        .sort((a, b) => b._created - a._created);
      setQuizzes(newQuizzes);
    } else {
      setQuizzes([]);
    }
  }, [quizzesRaw]);

  useEffect(() => {
    let orgTeam = orgAndTeamData({ orgData, teamData });
    setOrgTeamData(orgTeam);
    let tableFields = [];
    if (orgTeam.hasTeam !== true) {
      tableFields.push({ key: 'team', label: 'Team(s)' });
    }
    tableFields = tableFields.concat([
      {
        key: 'title',
        _classes: 'font-weight-bold',
        sortable: true,
        sortIcon: 'fa fa-sort',
      },
      {
        key: 'createdFormatted',
        label: 'Created',
        sortable: true,
        sortIcon: 'fa fa-sort',
      },
      {
        key: 'mon',
        label: 'MON',
        sortable: false,
      },
      {
        key: 'tue',
        label: 'TUE',
        sortable: false,
      },
      {
        key: 'wed',
        label: 'WED',
        sortable: false,
      },
      {
        key: 'thur',
        label: 'THUR',
        sortable: false,
      },
      {
        key: 'fri',
        label: 'FRI',
        sortable: false,
      },
      {
        key: 'sat',
        label: 'SAT',
        sortable: false,
      },
      {
        key: 'sun',
        label: 'SUN',
        sortable: false,
      },
    ]);
    setTableFields(tableFields);
  }, [orgData, teamData]);

  return (
    <QuizWrapper>
      <AutoQuizButtons />
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader className="d-flex align-items-center justify-content-between">
              <OrgTeamImageHeader
                title="Quizzes"
                rightView={
                  <>
                    <Link className=" mr-5" to={TABS.COACH_QUIZZES.path}>
                      <FaListOl style={{ marginTop: -5, marginRight: 5 }} />
                      Quizzes
                    </Link>
                    <Link className=" mr-5" to={TABS.COACH_QUIZ_INVITES.path}>
                      <FaEnvelopeOpenText
                        style={{ marginTop: -5, marginRight: 5 }}
                      />
                      Quiz Invites
                    </Link>
                    <Link className=" mr-5" to={TABS.COACH_QUIZ_TAKENS.path}>
                      <FaMobile style={{ marginTop: -5, marginRight: 5 }} />
                      Quizzes Taken
                    </Link>
                    <Link to={TABS.COACH_QUIZZES_CREATE.path}>
                      <CButton
                        color="primary"
                        className="d-flex justify-content-center align-items-center"
                      >
                        <span className="m-0 mr-2 h3">+</span> Create a Quiz
                      </CButton>
                    </Link>
                  </>
                }
              />
            </CCardHeader>

            <CCardBody>
              {!loading ? (
                <CDataTable
                  addTableClasses="quiz-table scheduled-quizzes"
                  items={quizzes}
                  tableFilter
                  itemsPerPageSelect
                  itemsPerPage={50}
                  hover
                  pagination
                  onRowClick={(item) => {
                    setEditingScheduledQuiz(item);
                  }}
                  fields={tableFields}
                  noItemsViewSlot={
                    <p className="d-flex align-content-center justify-content-center pt-4 pb-4">
                      <FiAlertCircle size="30" color="#e55353" />
                      <span className="pl-2 font-xl">
                        No quizzes scheduled.
                      </span>
                    </p>
                  }
                  scopedSlots={{
                    created_at: (item) => <td>{item?.createdFormatted}</td>,
                    team: (item) => (
                      <TeamCellInDataTable item={item} teamData={teamData} />
                    ),
                    average: (item) => (
                      <td>
                        <QuizUserResponse
                          quizId={item.id}
                          examId={item.examId}
                          average={item.average}
                          numTaken={item.num_taken}
                        />
                      </td>
                    ),
                    mon: (item) => (
                      <td>
                        {item.mon === true ? (
                          <FaCheck color="#21c87a" />
                        ) : (
                          <div />
                        )}
                      </td>
                    ),
                    tue: (item) => (
                      <td>
                        {item.tue === true ? (
                          <FaCheck color="#21c87a" />
                        ) : (
                          <div />
                        )}
                      </td>
                    ),
                    wed: (item) => (
                      <td>
                        {item.wed === true ? (
                          <FaCheck color="#21c87a" />
                        ) : (
                          <div />
                        )}
                      </td>
                    ),
                    thur: (item) => (
                      <td>
                        {item.thur === true ? (
                          <FaCheck color="#21c87a" />
                        ) : (
                          <div />
                        )}
                      </td>
                    ),
                    fri: (item) => (
                      <td>
                        {item.fri === true ? (
                          <FaCheck color="#21c87a" />
                        ) : (
                          <div />
                        )}
                      </td>
                    ),
                    sat: (item) => (
                      <td>
                        {item.sat === true ? (
                          <FaCheck color="#21c87a" />
                        ) : (
                          <div />
                        )}
                      </td>
                    ),
                    sun: (item) => (
                      <td>
                        {item.sun === true ? (
                          <FaCheck color="#21c87a" />
                        ) : (
                          <div />
                        )}
                      </td>
                    ),
                  }}
                />
              ) : (
                <CCol xl={12} className="text-center pt-2 pb-2">
                  <CSpinner variant="grow" size="lg" />
                </CCol>
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <InviteUsersToExamModal
        exam_id={null}
        quiz_id={null}
        isScheduledQuiz={true}
        quiz_team_id={
          teamData?.teamData?._id ||
          (isArray(editingScheduledQuiz?.teams) &&
          editingScheduledQuiz.teams.length === 1
            ? editingScheduledQuiz?.teams
            : null)
        }
        isAutoQuiz={true}
        preselectedQuizCategories={editingScheduledQuiz?.categories}
        title={editingScheduledQuiz?.title}
        show={!!editingScheduledQuiz}
        mode={INVITE_USERS_TO_EXAM_MODE.EDIT}
        onClose={() => {
          setEditingScheduledQuiz(null);
        }}
        id={editingScheduledQuiz?.id}
        editing_scheduled_quiz_id={editingScheduledQuiz?.id}
        preselectedNumQuestions={editingScheduledQuiz?.numQuestions}
        preselectedPlayersToInvite={editingScheduledQuiz?.playersToInvite}
        preselectedDays={editingScheduledQuiz?.days}
        preselectedTime={editingScheduledQuiz?.hourEST}
      />
    </QuizWrapper>
  );
};

export default QuizzesScheduledPage;
