import { SET_CURRENT_QUESTIONS, CLEAR_QUESTIONS } from './constants';

/**
 * Set current user
 */
export function setCurrentQuestions(payload) {
  return {
    type: SET_CURRENT_QUESTIONS,
    payload,
  };
}
export function clearQuestions() {
  return {
    type: CLEAR_QUESTIONS,
  };
}
