import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormGroup,
  CImg,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CLabel,
  CLink,
  CRow,
} from '@coreui/react';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import './coachUpdate.scss';
import { Form, Formik } from 'formik';
import { range } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import * as Yup from 'yup';
import { viewPlayers } from '../../../actions/playerActionCreator';
import { db, functions } from '../../../index';
import { POSITIONS } from '../../../r-reusable';
import 'yup-phone-lite';
import {
  desanitizePhoneNumber,
  extractLastName,
  sanitizePhoneNumber,
} from '../../../helpers/data';
import _ from 'lodash';
import PlayerWrapper from '../../../pages/component/PlayerWrapper';
import CoachWrapper from '../../../pages/component/CoachWrapper';
import { Button, Form as BootstrapForm } from 'react-bootstrap';
import {
  ACCESS_ACTIONS,
  ACCESS_LEVELS,
  checkAccess,
} from '../../../helpers/access';
import { useHistory } from 'react-router-dom';
import { TABS } from '../../../AppTabs';
import HeaderWithBackButton from '../../../r-components/HeaderWithBackButton/HeaderWithBackButton';
import { handleError } from '../../../helpers/errors';
import LoadingMessage from '../../../r-components/LoadingMessage/LoadingMessage';
import { toast } from 'react-toastify';
import { FaTrashAlt } from 'react-icons/fa';
import ConfirmModal from '../../../r-components/ConfirmModal/ConfirmModal';
import useAccountSelectors from '../../../hooks/useAccountSelectors';

const _initialValues = {
  email: '',
  displayName: '',
  phoneNumber: '',
  age: '',
  city: '',
  positions: [],
  groups: [],
  classYear: '',
  youtube: '',
  baseballReports: '',
  twitter: '',
  instagram: '',
  type: '',
  access: {},
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('Invalid email')
    .required('Email is required'),
  displayName: Yup.string().trim().required('Name is required'),
  access: Yup.object().test(
    'at-least-one-role',
    'Select at least one team',
    (access) => {
      return Object.values(access || {}).some(
        (entry) => Array.isArray(entry?.roles) && entry.roles.length > 0
      );
    }
  ),
});

function CoachUpdate({ match }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMounted = useRef(false);
  const [user, setUser] = useState({});
  const formikRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const users = useSelector((state) => state.DataCoachState?.data || []);
  const [teamsWithMaxCoaches, setTeamsWithMaxCoaches] = useState([]);
  const [initialValues, setInitialValues] = useState(_initialValues);
  const [maxxedOutTeams, setMaxxedOutTeams] = useState([]);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const { type, tier, goToUrl, showSubscriptionModal, teamCounts } =
    useAccountSelectors();
  const [showConfirmAgainDeleteModal, setShowConfirmAgainDeleteModal] =
    useState(false);
  const {
    UserState: currentUser,
    ThemeState: { darkMode },
    DataTeamState: teamData,
    DataOrganizationState: orgData,
  } = useSelector((state) => state);
  const teams = useSelector((state) => state.DataTeamState?.data || []);
  const [userId, setUserId] = useState('a');
  useEffect(() => {
    setUserId(match.params.id || 'a');
  }, [match]);

  useEffect(() => {
    let v = _.pick(user, ['displayName', 'email', 'phoneNumber', 'access']);
    formikRef.current?.setValues(v);
  }, [user]);

  useEffect(() => {
    if (_.isEmpty(initialValues.teams)) {
      setInitialValues({
        ..._initialValues,
        ...initialValues,
        ...user,
        ...{
          teams: user.access || {},
        },
      });
    }
    return () => {
      setInitialValues(_initialValues);
    };
  }, [user]);

  useEffect(() => {}, [orgData, teamData, initialValues]);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        isMounted.current = true;
        const userRef = doc(db, 'users', userId);
        const userResponse = await getDoc(userRef);
        const userData = userResponse.data();
        if (!!userData) {
          if (isMounted.current) {
            const { name, org_team, phoneNumber, ...rest } = userData;
            const data = {
              ...initialValues,
              ...rest,
              displayName: name,
              phoneNumber: desanitizePhoneNumber(phoneNumber),
            };
            let access = {};
            if (
              _.isString(orgData?.data?.id) &&
              orgData.data.id.length > 0 &&
              _.isObject(org_team) &&
              _.isObject(org_team[orgData.data.id])
            ) {
              let ts = Object.entries(org_team[orgData.data.id]);
              ts.forEach(([teamId, roles]) => {
                if (!teamId.startsWith('_')) {
                  access[teamId] = {
                    roles: roles.roles,
                  };
                }
              });
            }
            data.access = access;
            setLoading(false);
            setUser(data);
          }
        }
      } catch (e) {
        handleError({
          error: e,
          internal: 'CoachUpdate.js - Setting coach',
        });

        if (isMounted.current) {
          setLoading(false);
        }
      }
    };

    if (!!currentUser && !!userId && userId.length > 1) {
      loadData();
    }
    setIsCreate(!(!!userId && userId.length > 1));

    return () => {
      isMounted.current = false;
      // setUserId('ab');
    };
  }, [currentUser, userId]);

  const onSubmit = async ({ displayName, email, phoneNumber, access }) => {
    if (isCreate) {
      return createCoach({ displayName, email, phoneNumber, access });
    } else {
      return updateCoach({ displayName, email, phoneNumber, access });
    }
  };

  const createCoach = async ({ displayName, email, phoneNumber, access }) => {
    try {
      email = email.trim();
      setSubmitting(true);

      const createNewCoachUserCallable = functions.httpsCallable(
        'createNewCoachUserCallable'
      );
      let org_team = {};
      org_team[orgData.data.id] = access;
      const createNewCoachResult = await createNewCoachUserCallable({
        name: displayName,
        email,
        coachLastName: extractLastName(currentUser.data.name),
        phoneNumber,
        organization_id: orgData.data.id,
        org_team,
        orgName: orgData.data.title,
      });

      setSubmitting(false);
      history.push(TABS.OWNER_COACHES.path);
    } catch (e) {
      handleError({
        error: e,
        internal: 'CoachUpdate - createCoach',
      });
      toast(e.message, { type: 'error' });
      setSubmitting(false);
      setLoading(false);
    }
  };

  const closeAllModals = () => {
    setShowConfirmDeleteModal(false);
    setShowConfirmAgainDeleteModal(false);
  };

  const deleteCoachButton = () => (
    <>
      {!isCreate && _.isString(userId) && userId.length > 1 && (
        <CButton
          size="md"
          color="danger"
          variant="ghost"
          onClick={() => {
            setShowConfirmDeleteModal(true);
          }}
        >
          <FaTrashAlt style={{ marginRight: 6, marginTop: -2 }} size={20} />
          {`Delete Coach`}
        </CButton>
      )}
    </>
  );

  const updateCoach = async ({ displayName, email, phoneNumber, access }) => {
    try {
      email = email.trim();
      setSubmitting(true);
      const usersRef = doc(db, 'users', userId);
      let updateUserData = { displayName, email, id: userId };

      let update = {
        email,
        name: displayName,
      };
      phoneNumber = sanitizePhoneNumber(phoneNumber);
      if (!!phoneNumber) {
        update = { ...update, phoneNumber };
        updateUserData = { ...updateUserData, phoneNumber };
      }
      if (
        _.isArray(Object.entries(access)) &&
        Object.entries(access).length > 0
      ) {
        Object.entries(access).forEach(([teamId, team]) => {
          update[`org_team.${orgData.data.id}.${teamId}.roles`] = team.roles;
        });
      }
      update = _.pickBy(update || {}, (value, key) => !key.startsWith('_'));
      await updateDoc(usersRef, update);
      const updateUser = functions.httpsCallable('updateUser');
      await updateUser(updateUserData);

      setSubmitting(false);
      history.push(TABS.OWNER_COACHES.path);
    } catch (e) {
      handleError({
        error: e,
        internal: 'CoachUpdate - updateCoach',
      });
      toast(e.message, { type: 'error' });
      setSubmitting(false);
      setLoading(false);
    }
  };

  const handleChangeTeamCheckbox = ({
    checked,
    team_id,
    values,
    setFieldValue,
    coachType,
  }) => {
    const setValue = (newAccessValue, roles) => {
      if (!_.isObject(newAccessValue[team_id])) {
        newAccessValue[team_id] = {};
      }
      newAccessValue[team_id].roles = roles;
      setFieldValue('access', newAccessValue);
    };
    let newAccessValue = {
      ...values.access,
    };
    let roles =
      _.isObject(values?.access) &&
      _.isObject(values.access[team_id]) &&
      _.isArray(values?.access[team_id].roles)
        ? [...values.access[team_id].roles]
        : [];
    if (checked === true) {
      checkAccess({
        action: ACCESS_ACTIONS.CREATE_COACH,
        type,
        tier,
        goToUrl,
        showSubscriptionModal,
        thresholdCount: teamCounts?.[team_id]?.countCoaches + 1,
        threshold: teamCounts?.[team_id]?.maxCoaches,
      }).doWithAccess(() => {
        roles = _.union([...roles], [coachType]);
        setValue(newAccessValue, roles);
      });
    } else {
      roles = _.without(roles, coachType);
      setValue(newAccessValue, roles);
    }
  };

  return (
    <CoachWrapper>
      <ConfirmModal
        show={showConfirmDeleteModal && !showConfirmAgainDeleteModal}
        title="Confirm Delete"
        message="Are you sure you would like to delete this coach from this organization? THIS CANNOT BE UNDONE. If you would like to remove the coach from this team, but keep the coach on another team, please use the Teams section below to manage the coach's access instead of deleting the coach."
        okButtonColor="danger"
        cancelButtonColor="primary"
        okButtonText="Delete"
        onClickCancel={closeAllModals}
        onClickConfirm={async () => {
          setShowConfirmDeleteModal(false);
          setShowConfirmAgainDeleteModal(true);
        }}
      />
      <ConfirmModal
        show={showConfirmAgainDeleteModal}
        title="Re-Confirm Delete"
        message="Are you sure you would like to delete this coach? THIS IS YOUR LAST WARNING. The coach will be deleted and this cannot be undone."
        okButtonColor="danger"
        cancelButtonColor="primary"
        okButtonText="Delete"
        onClickCancel={closeAllModals}
        onClickConfirm={async () => {
          setShowConfirmAgainDeleteModal(false);
          toast(
            'Coach being deleted. It may take a minute for the coach to be removed.',
            { type: 'success' }
          );
          history.push(TABS.OWNER_COACHES.path);
          let deleteUserFromOrgCallable = functions.httpsCallable(
            'deleteUserFromOrgCallable'
          );
          let deleteResult = await deleteUserFromOrgCallable({
            userId,
            organizationId: orgData.data.id,
          });
        }}
      />{' '}
      <CRow className="edit-coach">
        <CCol lg={12}>
          <CCard>
            <HeaderWithBackButton
              rightSide={isCreate ? null : deleteCoachButton()}
            >{`${isCreate ? 'Create' : 'Edit'} Coach`}</HeaderWithBackButton>

            <CCardBody>
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div className="sk-bounce">
                    <div className="sk-bounce-dot"></div>
                    <div className="sk-bounce-dot"></div>
                  </div>
                </div>
              ) : (
                <Formik
                  onSubmit={onSubmit}
                  innerRef={formikRef}
                  initialValues={{ ...initialValues, ...user }}
                  validationSchema={validationSchema}
                  enableReinitialize={true}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                  }) => (
                    <BootstrapForm onSubmit={handleSubmit}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <h2>Personal details</h2>
                      </div>
                      <CFormGroup row>
                        <CCol xs="2">
                          <CLabel htmlFor="title">
                            Name <span className="text-danger">*</span>
                          </CLabel>
                          {touched.displayName === true &&
                            !!errors.displayName && (
                              <CLabel
                                style={{
                                  fontWeight: 900,
                                  color: 'red',
                                  display: 'block',
                                  marginBottom: 0,
                                  marginTop: -10,
                                }}
                              >
                                {errors.displayName}
                              </CLabel>
                            )}
                        </CCol>

                        <CCol xs="10">
                          <CInput
                            required
                            id="displayName"
                            value={values.displayName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{
                              border:
                                touched.displayName === true &&
                                !!errors.displayName
                                  ? '1px solid red'
                                  : '',
                            }}
                          />
                        </CCol>
                      </CFormGroup>

                      <CFormGroup row>
                        <CCol xs="2">
                          <CLabel htmlFor="title">
                            Email <span className="text-danger">*</span>
                          </CLabel>
                          {touched.email === true && !!errors.email && (
                            <CLabel
                              style={{
                                fontWeight: 900,
                                color: 'red',
                                display: 'block',
                                marginBottom: 0,
                                marginTop: -10,
                              }}
                            >
                              {errors.email}
                            </CLabel>
                          )}
                        </CCol>

                        <CCol xs="10">
                          <CInput
                            required
                            id="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{
                              border:
                                touched.email === true && !!errors.email
                                  ? '1px solid red'
                                  : '',
                            }}
                          />
                        </CCol>
                      </CFormGroup>

                      {/* <CFormGroup row>
                        <CCol xs="2">
                          <CLabel htmlFor="title">Phone Number</CLabel>
                          {touched.phoneNumber === true &&
                            !!errors.phoneNumber && (
                              <CLabel
                                style={{
                                  fontWeight: 900,
                                  color: 'red',
                                  display: 'block',
                                  marginBottom: 0,
                                  marginTop: -10,
                                }}
                              >
                                {errors.phoneNumber}
                              </CLabel>
                            )}
                        </CCol>

                        <CCol xs="10">
                          <CInput
                            type="tel"
                            id="phoneNumber"
                            value={values.phoneNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{
                              border:
                                touched.phoneNumber === true &&
                                !!errors.phoneNumber
                                  ? '1px solid red'
                                  : '',
                            }}
                          />
                        </CCol>
                      </CFormGroup> */}
                      <h2>Teams</h2>
                      <CFormGroup row>
                        <CCol xs="2">
                          {!!errors.access && (
                            <CLabel
                              style={{
                                fontWeight: 900,
                                color: 'red',
                                display: 'block',
                                marginBottom: 0,
                                marginTop: -10,
                              }}
                            >
                              {errors.access}
                            </CLabel>
                          )}
                        </CCol>
                        <CCol xs="10">
                          {(teams || []).map((team, index) => (
                            <div
                              key={`team-${index}`}
                              style={{ marginBottom: 20 }}
                            >
                              <div style={{ display: 'flex', gap: 8 }}>
                                {_.isString(team.imageUrl) &&
                                  team.imageUrl.length > 0 && (
                                    <img
                                      style={{
                                        height: 30,
                                        width: 30,
                                        objectFit: 'contain',
                                      }}
                                      src={team.imageUrl}
                                    ></img>
                                  )}
                                <h4>{team.name}</h4>
                              </div>

                              <BootstrapForm.Check
                                type="checkbox"
                                id={`head-coach-checkbox-${team._id}`}
                                label={`Head Coach`}
                                checked={
                                  _.isObject(values?.access) &&
                                  _.isObject(values.access[team._id]) &&
                                  _.isArray(values?.access[team._id].roles) &&
                                  values.access[team._id].roles.includes(
                                    ACCESS_LEVELS.COACH.key
                                  )
                                }
                                onChange={(e) => {
                                  handleChangeTeamCheckbox({
                                    checked: e.target.checked,
                                    team_id: team._id,
                                    values,
                                    setFieldValue,
                                    coachType: ACCESS_LEVELS.COACH.key,
                                  });
                                }}
                              />
                              <BootstrapForm.Check
                                type="checkbox"
                                id={`assistant-coach-checkbox-${team._id}`}
                                label={`Assistant Coach`}
                                checked={
                                  _.isObject(values?.access) &&
                                  _.isObject(values.access[team._id]) &&
                                  _.isArray(values?.access[team._id].roles) &&
                                  values.access[team._id].roles.includes(
                                    ACCESS_LEVELS.ASSISTANT.key
                                  )
                                }
                                onChange={(e) => {
                                  handleChangeTeamCheckbox({
                                    checked: e.target.checked,
                                    team_id: team._id,
                                    values: values,
                                    setFieldValue,
                                    coachType: ACCESS_LEVELS.ASSISTANT.key,
                                  });
                                }}
                              />
                              <div
                                style={{
                                  width: 200,
                                  maxWidth: '100%',
                                  height: 2,
                                  background: '#efefef',
                                  marginTop: 10,
                                }}
                              ></div>
                            </div>
                          ))}
                        </CCol>
                      </CFormGroup>

                      <CRow>
                        <CCol xs="12" className="text-right pt-3">
                          {submitting ? (
                            <LoadingMessage
                              centered={false}
                              firstMessage={`${
                                isCreate ? 'Creating' : 'Updating'
                              } Coach...`}
                            />
                          ) : (
                            <>
                              <CButton
                                className="mr-2"
                                size="md"
                                color="danger"
                                variant="outline"
                                onClick={() => {
                                  history.push(TABS.OWNER_COACHES.path);
                                }}
                              >
                                Cancel
                              </CButton>
                              <CButton size="md" type="submit" color="primary">
                                {`${isCreate ? 'Create' : 'Update'} Coach`}
                              </CButton>
                            </>
                          )}
                        </CCol>
                      </CRow>
                    </BootstrapForm>
                  )}
                </Formik>
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CoachWrapper>
  );
}

export default CoachUpdate;
