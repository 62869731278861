import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import './home.css';
import './FAQ.scss';
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCardHeader,
  CCollapse,
  CModal,
  CModalBody,
} from '@coreui/react';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { TABS } from '../AppTabs';
import ReadyToStart from './component/ReadyToStart/ReadyToStart';
import { teamMembers } from './teamMembers';
import {
  FaEnvelope,
  FaEnvelopeOpen,
  FaMinus,
  FaPlus,
  FaTimes,
  FaTimesCircle,
} from 'react-icons/fa';
import _ from 'lodash';
import SEOHead from '../r-components/SEOHead/SEOHead';
import { EMAIL_ADDRESSES } from '../config';

const FAQ_CONTENT = [
  // {
  //   question: 'How does 6Tool work?',
  //   content: `Very well`,
  //   imageUrl: '/img/6Tool-example.gif',
  // },
  // {
  //   question: 'As a coach do I need the mobile app?',
  //   content: `No`,
  //   videoUrl:
  //     'https://player.vimeo.com/video/845461587?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
  // },
  {
    question: 'Where do my players take the quiz?',
    content: `Players will need to download the free 6-Tool app in order to receive quizzes. Once the app is on their phone, they can access their quizzes via the app. When new quizzes are loaded, players receive a notification on their phone or mobile device.`,
    videoUrl: null,
  },
  {
    question: 'I have signed up - what do I do now?',
    content: [
      { c: `Once you have signed up for 6-Tool coaches can:` },
      {
        type: 'ul',
        c: [
          `Send the 4 digit code to their players which will allow the players to input their information to populate the roster`,
          `Coach can also input the players information onto the team page (position etc).`,
          `Enter new quizzes by accessing questions already on the platform or creating custom questions.`,
        ],
      },
    ],
    videoUrl: null,
  },
  {
    question:
      'I am an organization with multiple teams - can I share questions and quizzes with all teams in my organization?',
    content:
      'Yes, as the administrator of the organization, you have the ability to share questions with multiple teams.  If certain teams do not want to share then you have the ability to limit the visibility of their quizzes to that team.',
    videoUrl: null,
  },
  {
    question: 'How many players can I add to my roster?',
    content: 'Each team can add 40 players to their roster.',
    videoUrl: null,
  },
  {
    question: 'Does 6Tool provide quizzes too or just questions?',
    content: [
      {
        c: `6-Tool provides coaches with a variety of ways to select and send questions to their players.`,
      },
      {
        type: 'ul',
        c: [
          `The ability to select from our database of questions.`,
          `Alter questions from our database to fit your teams specific strategy.`,
          `Custom create your own questions`,
          `6-Tool will send out weekly questions to coaches.`,
          `Have access to quizzes 6-Tool creates `,
        ],
      },
    ],
    videoUrl: null,
  },
  {
    question:
      'I have my own list of questions and quizzes in a google doc or on paper. Can this be imported into 6Tool?',
    content: `Yes. 6-Tool offers this option. Please contact us at ${EMAIL_ADDRESSES.INFO} for more details.`,
    videoUrl: null,
  },
  {
    question: 'Can I customize quizzes and questions?',
    content:
      'Yes quizzes can be customized.  From sending signs to team rules, 6-Tool give coaches the ability to cover all topics.',
    videoUrl: null,
  },
  {
    question: 'Does the team who created 6Tool actually know baseball?',
    content: `6-Tool brings experience from a variety of angles within the Baseball landscape. From former Major and Minor Leaguers to College Coaches and Baseball Dads who've experienced the playing and the recruiting process first-hand.`,
    videoUrl: null,
  },
];

const renderListItems = (c) => (
  <>
    {c.map((t) => (
      <li>{t}</li>
    ))}
  </>
);

const renderContent = ({ content, className = '' }) => {
  if (_.isArray(content) && content.length > 0) {
    return (
      <div className="card-content-right">
        {content.map(({ type = 'text', c }) => {
          if (type === 'ul' && _.isArray(c) && c.length > 0) {
            return <ul>{renderListItems(c)}</ul>;
          } else if (type === 'ol' && _.isArray(c) && c.length > 0) {
            return <ol>{renderListItems(c)}</ol>;
          } else {
            return <div>{c}</div>;
          }
        })}
      </div>
    );
  } else if (_.isString(content)) {
    return <div className={className}>{content}</div>;
  }
  return null;
};

const FAQ = () => {
  // const { windowWidth, windowHeight } = useWindowDimensions();
  const [expandedQuestions, setExpandedQuestions] = useState([]);

  return (
    <>
      <SEOHead
        title="6Tool FAQ - Empowering Baseball Coaches with a Cutting-edge Player Development App"
        description="Find answers to frequently asked questions about the 6Tool baseball coaching app. Learn how our innovative platform supports baseball player development, coaching strategies, and team management to help you boost your team's performance. Explore the 6Tool FAQ today."
      />
      <section className="faq-banner">
        <div className="container">
          <h1>
            <span>FAQ</span>
          </h1>
          <p style={{ marginBottom: 40 }}>Don't See Your Question?</p>
          <a
            href={`mailto:${EMAIL_ADDRESSES.INFO}`}
            className="btn primary-btn"
          >
            <span className="material-symbols-outlined">mail</span>
            <span>Email Us</span>
          </a>
          <div className="player-image" />
        </div>
      </section>
      <section className="questions">
        <div className="container">
          <div className="question-cards">
            <CCardGroup
              style={{ columnCount: 1, width: '100%' }}
              deck={false}
              columns={true}
            >
              {FAQ_CONTENT.map((q, _qIdx) => (
                <CCard
                  className={
                    expandedQuestions.includes(_qIdx) ? 'expanded' : ''
                  }
                >
                  <CCardHeader
                    style={{}}
                    onClick={() => {
                      if (expandedQuestions.includes(_qIdx)) {
                        setExpandedQuestions(
                          _.filter(
                            expandedQuestions,
                            (element) => element !== _qIdx
                          )
                        );
                      } else {
                        setExpandedQuestions(
                          _.union(expandedQuestions, [_qIdx])
                        );
                      }
                    }}
                  >
                    <div style={{ flex: 1 }}>{q.question}</div>
                    {expandedQuestions.includes(_qIdx) ? (
                      <FaMinus />
                    ) : (
                      <FaPlus />
                    )}
                  </CCardHeader>
                  <CCollapse show={expandedQuestions.includes(_qIdx)}>
                    <CCardBody>
                      {(_.isString(q.imageUrl) && q.imageUrl.length > 0) ||
                      (_.isString(q.videoUrl) && q.videoUrl.length > 0) ? (
                        <div className="card-content">
                          <div className="card-content-left">
                            {_.isString(q.videoUrl) && q.videoUrl.length > 0 ? (
                              <>
                                {q.videoUrl.includes('vimeo.com') ? (
                                  <div
                                    style={{
                                      padding: '0 0 56.25% 0',
                                      position: 'relative',
                                      overflow: 'hidden',
                                      width: '100%',
                                    }}
                                  >
                                    <iframe
                                      src={q.videoUrl}
                                      frameBorder="0"
                                      allow="autoplay; fullscreen; picture-in-picture"
                                      allowFullScreen={true}
                                      style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                      }}
                                      title="6Tool"
                                    ></iframe>
                                  </div>
                                ) : (
                                  <video width="100%" height="auto" controls>
                                    <source src={q.videoUrl} type="video/mp4" />
                                  </video>
                                )}
                              </>
                            ) : (
                              <img
                                src={q.imageUrl}
                                className="img-fluid"
                                alt=""
                              />
                            )}
                          </div>
                          {renderContent({
                            content: q.content,
                            className: 'card-content-right',
                          })}
                        </div>
                      ) : (
                        <>
                          {renderContent({
                            content: q.content,
                          })}
                        </>
                      )}
                    </CCardBody>
                  </CCollapse>
                </CCard>
              ))}
            </CCardGroup>
          </div>
        </div>
        {FAQ_CONTENT.some((obj) => obj.videoUrl?.includes('vimeo.com')) && (
          <script src="https://player.vimeo.com/api/player.js"></script>
        )}
      </section>
      <ReadyToStart />
    </>
  );
};

export default FAQ;
