import AppStore from '../../assets/app-store.png';
import Logo from '../../assets/logo.png';
import playStore from '../../assets/play-store.png';
import React from 'react';

import facebook from '../../assets/icons/facebook.png';
import instagram from '../../assets/icons/instagram.png';
import twitter from '../../assets/icons/twitter.png';

import {
  A,
  Background,
  Content,
  CopyRight,
  FollowUsWrapper,
  H4,
  Li,
  LogoWrapper,
  Ul,
  Wrapper,
} from './FooterStyles';

import Container from '../Container/Container';
import { EMAIL_ADDRESSES } from '../../config';

const Footer = () => {
  return (
    <Wrapper>
      <Background>
        <Container>
          <Content>
            <LogoWrapper>
              <img src={Logo} alt="6Tool" />
              <span>Download Our App Now !</span>
              <div>
                <img src={AppStore} />
                <img src={playStore} />
              </div>
            </LogoWrapper>

            <div>
              <H4>Quick Links</H4>
              <Ul>
                <Li>
                  <A href="">Team</A>
                </Li>
                <Li>
                  <A href="">Why Tool6</A>
                </Li>
                <Li>
                  <A href="">Pricing</A>
                </Li>
                <Li>
                  <A href="">FAQs</A>
                </Li>
              </Ul>
            </div>
            <div>
              <H4>Support</H4>
              <Ul>
                <Li>
                  <A href="">Privacy PoLicy</A>
                </Li>
                <Li>
                  <A href="">Terms & Conditions</A>
                </Li>
              </Ul>

              <FollowUsWrapper>
                <span>Follow us:</span>
                <ol>
                  <li>
                    <a href="">
                      <img src={facebook} alt="Facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <img src={instagram} alt="Instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <img src={twitter} alt="Twitter" />
                    </a>
                  </li>
                </ol>
              </FollowUsWrapper>
            </div>
          </Content>
          <CopyRight>
            <p>
              &copy; 2024 6Tool. All rights reserved. {EMAIL_ADDRESSES.INFO}
            </p>
          </CopyRight>
        </Container>
      </Background>
    </Wrapper>
  );
};

export default Footer;
