// User constants
export const SET_CURRENT_ORGANIZATION = 'ORG/SET_CURRENT_ORGANIZATION';

// export const SET_USER_TEAMS = 'USER/SET_USER_TEAMS';
// export const UPDATE_USER_SELECTED_TEAM = 'USER/UPDATE_USER_SELECTED_TEAM';
// export const SET_USER_SELECTED_TEAM = 'USER/SET_USER_SELECTED_TEAM';

// export const SET_PLAYERS = 'USER/SET_PLAYERS';
// export const UPDATE_PLAYERS = 'USER/UPDATE_PLAYERS';

export const CLEAR_ORGANIZATION = 'ORG/CLEAR_ORGANIZATION';
