const defaultLeagueDataState = {
  createLeague: null,
  isLoading: false,
  status: 0,
};

export const createLeagueReducer = (state = defaultLeagueDataState, action) => {
  switch (action.type) {
    case 'DEFAULT_SCRIMMAGE':
      return { ...state, isLoading: false, status: 0, forgotPassStatus: null };

    case 'REQUESTED_LEAGUE':
      return { ...state, isLoading: true, status: 1 };
    case 'RECEIVED_CREATE_LEAGUE_FAILURE':
      return { ...state, isLoading: false, status: 2 };
    case 'RECEIVED_CREATE_LEAGUE_SUCCESS':
      return {
        ...state,
        createLeague: action.createLeagueData,
        isLoading: false,
        status: 3,
      };

    case 'REQUESTED_LEAGUE_DATA':
      return { ...state, isLoading: true };
    case 'RECEIVED_LEAGUE_DATA_SUCCESS':
      return {
        ...state,
        getLeagueData: action.getLeagueData,
        isLoading: false,
      };

    case 'REQUESTED_DELETE_LEAGUE_DATA':
      return { ...state, isLoading: true, status: 1 };
    case 'RECEIVED_DELETE_LEAGUE_DATA_FAILURE':
      return { ...state, isLoading: false, status: 2 };
    case 'RECEIVED_DELETE_LEAGUE_DATA_SUCCESS':
      return {
        ...state,
        deleteLeagueData: action.deleteLeagueData,
        isLoading: false,
        status: 3,
      };
    default:
  }

  return state;
};
