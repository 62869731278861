import {
  ADD_NEW_GROUP,
  CLEAR_GROUPS,
  EDIT_GROUP,
  GROUPS,
  GROUP_DETAILS,
} from '../reducers/constants';

export const viewGroups = () => {
  return {
    type: GROUPS,
  };
};

export const addGroups = () => {
  return {
    type: ADD_NEW_GROUP,
  };
};

export const editGroup = (groupId) => {
  return {
    type: EDIT_GROUP,
    payload: groupId,
  };
};

export const viewGroupDetails = (groupId) => {
  return {
    type: GROUP_DETAILS,
    payload: groupId,
  };
};

export const clearGroups = () => {
  return {
    type: CLEAR_GROUPS,
  };
};
