import React, { Component } from 'react';
import Mailchimp from '../component/MailChimpForm';
import AppStoreBadges from './AppStoreBadges';
import { TABS } from '../../AppTabs';
import { FooterCopyright, FooterImage } from './Footer';

const FunnelFooter = () => {
  return (
    <div className="footer">
      <div className="container">
        <FooterImage noLink={true} />
        <div className="copyright">
          <FooterCopyright />
        </div>
      </div>
    </div>
  );
};

export default FunnelFooter;
